import React, { useEffect, useState } from "react";
import Topbar from "../../../topbar";
import Sidebar from "../../../Sidebar";
import SpinnerLoader from "../../../../plugins/SpinnerLoader";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Image from "../../../../Images/AboutUs-2.png";
import { TiShoppingCart } from "react-icons/ti";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardMedia,
  Rating,
  Tooltip,
  Typography,
} from "@mui/material";
import Api from "../../../../service/services";
import CartIcon from "./CartIcon";
import { getQueryParam } from "../../../../plugins/getQueryParams";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PaymentIcon from "@mui/icons-material/Payment";
import RatingDisplay from "./ratingDisplay";
import Ratings from "../../../../plugins/Ratings";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HalfRatings from "../../../../plugins/HalfRatings";
import moment from "moment";

export default function TechProductId() {
  const { id } = useParams();
  const product_id = getQueryParam("product_id");
  const navigate = useNavigate();
  const [loadingData, setLoadingData] = useState(false);
  const [getProductById, setProductById] = useState({});
  const [deliveryCharge, setDeliveryCharge] = useState({});
  const [addedToCart, setAddedToCart] = useState(false);
  const [addedToCart2, setAddedToCart2] = useState(false);
  const [isAddedCartItem, setIsAddedCartItem] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showFullReview, setShowFullReview] = useState(false);
  const [userRatings, setUserRatings] = useState({});
  const [reviewComment, setReviewComment] = useState("");
  const [showFullReviews, setShowFullReviews] = useState(
    Array(userRatings?.user_ratings?.length).fill(false)
  );
  const location = useLocation();
  const [previousPath, setPreviousPath] = useState(null);

  // Update previousPath whenever the location changes
  useEffect(() => {
    setPreviousPath(location.pathname);
  }, [location]);

  // Handle click event to navigate back
  const handleClick = () => {
    // Fallback to a default path if previousPath is not set
    navigate("/techniciancategory");
  };
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}`,
    };
  }
  const addToCart = async (product) => {
    try {
      const deliveryCharges = await Api.getdeliveryCharge(product?.id, 23);
      console.log("deliveryCharges", deliveryCharges);
      setDeliveryCharge(deliveryCharges);

      const payload = {
        product_id: product?.id,
        quantity: product?.minimum_quantity,
        delivery_charge: deliveryCharges?.delivery_charge,
      };
      console.log("payload", payload);

      const addToCartResponse = await Api.addToCart(payload);
      console.log("resss", addToCartResponse);

      setAddedToCart((prev) => ({
        ...prev,
        [id]: true,
      }));
      setIsAddedCartItem(true);
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };
  const similarProductAddToCart = async (product) => {
    try {
      const deliveryCharges = await Api.getdeliveryCharge(product?.id, 23);
      console.log("deliveryCharges", deliveryCharges);
      setDeliveryCharge(deliveryCharges);

      const payload = {
        product_id: product?.id,
        quantity: product?.minimum_quantity,
        delivery_charge: deliveryCharges?.delivery_charge,
      };
      console.log("payload", payload);

      const addToCartResponse = await Api.addToCart(payload);
      console.log("resss", addToCartResponse);

      setAddedToCart2((prev) => ({
        ...prev,
        [product?.id]: true,
      }));
      setIsAddedCartItem(true);
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };
  useEffect(() => {
    Api.getTechnicianProducts()
      .then((res) => {
        console.log("getTechProducts", res);
        const filteredProductList = res?.list?.filter((item) => {
          return item?.net_qty_available > 0 && item?.status === "active";
        });
        console.log("Filtered Product List", filteredProductList);
        setFilteredProducts(filteredProductList);
      })
      .catch((err) => console.log("Error", err));
  }, []);
  useEffect(() => {
    setLoadingData(true);
    setTimeout(() => {
      setLoadingData(false);
    }, 1000);
    Api.getTechnicianProductsById(product_id)
      .then((res) => {
        console.log("Successfully", res);
        setProductById(res);
      })
      .catch((err) => console.log(err));
    Api.getdeliveryCharge(product_id, 23)
      .then((res) => {
        console.log("deliveryCharges", res);
        setDeliveryCharge(res);
      })
      .catch((err) => console.log(err));

    Api.reviewsAndRatingsList(product_id)
      .then((res) => {
        console.log("reviewsAndRatingsList", res);
        setUserRatings(res);
      })
      .catch((err) => console.log(err));
  }, []);
  const addToCartt = (id) => {
    console.log(id, "Add to cart");
    const payload = {
      product_id: id,
      quantity: getProductById?.minimum_quantity,
      delivery_charge: deliveryCharge?.delivery_charge,
    };
    console.log("payload", payload);
    Api.addToCart(payload)
      .then((res) => {
        console.log("resss", res);
        if (res) {
          toast.success("Item Added to Cart", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        setAddedToCart(true);
        setIsAddedCartItem(true);
      })
      .catch((err) => console.log(err));
  };
  const buyNow = (id) => {
    console.log(id, "Add to cart");
    const payload = {
      product_id: id,
      quantity: getProductById?.minimum_quantity,
      delivery_charge: deliveryCharge?.delivery_charge,
    };
    console.log("payload", payload);
    Api.addToCart(payload)
      .then((res) => {
        console.log("resss", res);
        setAddedToCart(true);
        setIsAddedCartItem(true);
        navigate(`/addToCart`);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (isAddedCartItem) {
      setTimeout(() => {
        setIsAddedCartItem(false);
      }, 1000);
    }
  }, [isAddedCartItem]);
  return (
    <div style={{ overflow: "hidden" }}>
      <Topbar />
      <div className="row">
        <div className="col-lg-2 col-12 border border-right">
          <Sidebar />
        </div>
        <ToastContainer style={{ marginTop: 70 }} />
        <div className="col-12 user_management" style={{ marginTop: "80px" }}>
          {loadingData ? (
            <div
              className="d-flex align-items-center"
              style={{ justifyContent: "center", height: "70vh" }}
            >
              <SpinnerLoader />
            </div>
          ) : (
            <div className="container-fluid">
              <div className="col-lg-12 ">
                <div
                  className="m-0 sticky-top bg-white"
                  style={{ position: "sticky", zIndex: "2" }}
                >
                  <i
                    className="bi bi-chevron-left me-2"
                    onClick={handleClick}
                    style={{
                      fontWeight: "600",
                      fontSize: "21px",
                      width: "20px",
                      height: "20px",
                      cursor: "pointer",
                    }}
                  ></i>
                  <text
                    className="p-0"
                    style={{
                      fontWeight: "600",
                      color: "#1E232C",
                      fontSize: "21px",
                      width: "208px",
                      height: "32px",
                    }}
                  >
                    Product Details
                  </text>
                  <div
                    style={{
                      cursor: "pointer",
                      float: "right",
                    }}
                    onClick={() => navigate("/addToCart")}
                  >
                    <CartIcon isAddedCartItem={isAddedCartItem} />
                    {/*  <TiShoppingCart
                      style={{
                        fontSize: "24px",
                        width: "40px",
                        height: "40px",

                      }}
                      isAddedCartItem={isAddedCartItem}
                    /> */}
                  </div>
                  <div className="product-details-container">
                    <div className="left-component center-image">
                      <img
                        className="ProductImage"
                        alt="product"
                        src={getProductById?.product_img}
                        style={{
                          height: "250px",
                          width: "400px",
                          objectFit: "contain",
                        }}
                      />
                      {getProductById?.productInventory?.net_qty_available >=
                      getProductById?.minimum_quantity ? (
                        <div
                          style={{
                            marginTop: "20px",
                            display: "flex",
                            // gap: "15px",
                            justifyContent: "flex-end",
                          }}
                        >
                          {!addedToCart && (
                            <Button
                              variant="contained"
                              startIcon={<ShoppingCartIcon />}
                              style={{
                                backgroundColor: "rgb(235, 129, 40)",
                                color: "white",
                                border: "1px solid rgb(235, 129, 40)",
                                fontFamily: "Arial, sans-serif",
                                // transition: "background-color 0.3s, color 0.3s",
                              }}
                              onMouseOver={(e) =>
                                (e.currentTarget.style.backgroundColor =
                                  "darkorange")
                              }
                              onMouseOut={(e) =>
                                (e.currentTarget.style.backgroundColor =
                                  "rgb(235, 129, 40)")
                              }
                              className="adtoBuynowbtn"
                              onClick={() => {
                                addToCartt(getProductById?.id);
                              }}
                            >
                              {"Add to Cart"}
                            </Button>
                          )}

                          {addedToCart && (
                            <Button
                              variant="contained"
                              startIcon={<ShoppingCartIcon />}
                              style={{
                                backgroundColor: "rgb(235, 129, 40)",

                                color: "white",
                                border: "1px solid rgb(235, 129, 40)",
                                fontFamily: "Arial, sans-serif",
                                // transition: "background-color 0.3s, color 0.3s",
                              }}
                              onMouseOver={(e) =>
                                (e.currentTarget.style.backgroundColor =
                                  "darkrgb(235, 129, 40)")
                              }
                              onMouseOut={(e) =>
                                (e.currentTarget.style.backgroundColor =
                                  "rgb(235, 129, 40)")
                              }
                              className="addtocartbtn"
                              onClick={() => {
                                navigate("/addToCart");
                              }}
                            >
                              {"Go to Cart"}
                            </Button>
                          )}

                          {/* <Button
                            variant="contained"
                            startIcon={<PaymentIcon />}
                            style={{
                              backgroundColor: "rgb(235, 129, 40)",
                              color: "white",
                            }}
                            onClick={() => {
                              buyNow(getProductById?.id);
                            }}
                            onMouseOver={(e) =>
                              (e.currentTarget.style.backgroundColor =
                                "darkrgb(235, 129, 40)")
                            }
                            onMouseOut={(e) =>
                              (e.currentTarget.style.backgroundColor = "rgb(235, 129, 40)")
                            }
                            className="adtoBuynowbtn"
                          >
                            Buy Now
                          </Button> */}
                        </div>
                      ) : (
                        <Button
                          variant="outlined"
                          disabled
                          style={{
                            color: "red",
                            border: "1px solid red",
                            marginTop: "20px",
                            cursor: "not-allowed",
                            width: "100%",
                            textTransform: "unset",
                            border: "1px solid transparent",
                          }}
                        >
                          Currently unavailable
                        </Button>
                      )}
                    </div>
                    <div className="right-component">
                      <div style={{ marginLeft: 10 }} className="ProductName">
                        <h3
                          style={{
                            fontWeight: "bold",
                            fontSize: "22px",
                            fontFamily: "Arial, sans-serif",
                            marginBottom: "1px",
                          }}
                        >
                          {getProductById?.name}
                        </h3>
                        <div
                          style={{
                            fontSize: "16px",
                            fontFamily: "Arial, sans-serif",
                          }}
                        >
                          {getProductById?.summary}
                        </div>
                        <div
                          style={{
                            fontFamily: "Arial, sans-serif",
                            marginTop: "10px",
                          }}
                        >
                          <RatingDisplay
                            averageRating={
                              userRatings?.average_rating
                                ? userRatings?.average_rating
                                : 0
                            }
                            totalRatings={userRatings?.user_ratings?.length}
                          />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                          {/* ₹{getProductById?.total_amount} */}{" "}
                          <span
                            style={{
                              fontWeight: "1000px",
                              fontSize: "1rem",
                              fontFamily: "Arial, sans-serif",
                            }}
                          >
                            <b> ₹{getProductById?.total_amount}</b>
                          </span>
                          {getProductById?.isDiscount && (
                            <span
                              className="ms-3"
                              style={{
                                textDecoration: "line-through",
                                fontFamily: "Arial, sans-serif",
                              }}
                            >
                              ₹{getProductById?.sale_price}
                            </span>
                          )}
                          {getProductById?.isDiscount && (
                            <span
                              className="ms-1"
                              style={{
                                color: "green",
                                fontFamily: "Arial, sans-serif",
                              }}
                            >
                              <b>
                                {" "}
                                {Math.round(getProductById?.discount_percent)}%
                                off
                              </b>
                            </span>
                          )}
                        </div>
                        <div className="mt-2">
                          {getProductById?.isDiscount === false && (
                            <span
                              style={{
                                color: "green",
                                fontSize: "13px",
                                position: "relative",
                                // bottom: "0.5rem",
                                fontFamily: "Arial, sans-serif",
                              }}
                            >
                              <b>
                                {" "}
                                You might save up to ₹
                                {getProductById?.discounted_price}
                              </b>
                            </span>
                          )}
                        </div>
                        <div
                          style={{
                            marginTop: "5px",
                            fontSize: "12px",
                            fontFamily: "Arial, sans-serif",
                          }}
                        >
                          Minimum Order Qty: {getProductById?.minimum_quantity}
                        </div>
                        <h6 style={{ fontFamily: "Arial, sans-serif" }}>
                          <b>Product Description</b>
                        </h6>
                        <div
                          style={{
                            marginTop: "1px",
                            fontSize: "16px",
                            fontFamily: "Arial, sans-serif",
                          }}
                        >
                          {getProductById?.description}
                        </div>
                        {/* <div className="boxElement" style={{ fontFamily: 'Arial, sans-serif' }}> */}
                        <h6 style={{ fontFamily: "Arial, sans-serif" }}>
                          <b>Specification</b>
                        </h6>
                        <table
                          style={{
                            width: "90%",
                            fontFamily: "Arial, sans-serif",
                            fontSize: "16px",
                            marginBottom: "1rem",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td>SKU Id</td>
                              <td>{getProductById?.sku_id}</td>
                            </tr>
                            {getProductById?.model_number && (
                              <tr>
                                <td>Model Number</td>
                                <td>{getProductById?.model_number}</td>
                              </tr>
                            )}
                            {getProductById?.model && (
                              <tr>
                                <td>Model Name</td>
                                <td>{getProductById?.model}</td>
                              </tr>
                            )}
                            {getProductById?.brand && (
                              <tr>
                                <td>Brand</td>
                                <td>{getProductById?.brand}</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {/* </div> */}
                        <div style={{ marginBottom: "20px" }}>
                          <b>Ratings & Reviews</b>
                          {userRatings?.user_ratings?.length > 0 ? (
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <div>
                                  <HalfRatings
                                    rating={userRatings?.average_rating}
                                    fontSize={"26px"}
                                  />
                                </div>
                                <div style={{ marginTop: "5px" }}>
                                  {userRatings?.average_rating} out of 5
                                </div>{" "}
                              </div>
                              <div style={{ marginTop: "10px" }}>
                                {userRatings?.user_ratings?.map(
                                  (item, index) => {
                                    return (
                                      <div>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                          key={index}
                                        >
                                          <Avatar
                                            className="centered-text mt-3 ms-2"
                                            src={
                                              item?.reviewer_image
                                                ? item?.reviewer_image
                                                : stringAvatar(
                                                    item?.reviewer_name
                                                  )
                                            }
                                            {...stringAvatar(
                                              item?.reviewer_name
                                            )}
                                          />
                                          <div
                                            style={{
                                              marginTop: "10px",
                                              marginLeft: "2px",
                                            }}
                                          >
                                            <div>
                                              <span style={{}}>
                                                <b>{item?.reviewer_name}</b>
                                              </span>
                                              <span
                                                style={{
                                                  fontSize: "11px",
                                                  marginLeft: "6px",
                                                }}
                                              >
                                                on{" "}
                                                {moment(
                                                  item?.created_at
                                                ).format("MMM, YYYY")}
                                              </span>
                                            </div>

                                            <Rating
                                              name="read-only"
                                              value={item?.rating_value}
                                              readOnly
                                              precision={0.5}
                                            />
                                          </div>
                                        </div>
                                        <div style={{ marginLeft: "50px" }}>
                                          <text style={{ fontSize: "13px" }}>
                                            {showFullReviews[index]
                                              ? item?.comment
                                              : `${item?.comment.slice(
                                                  0,
                                                  195
                                                )}`}
                                          </text>
                                          {item?.comment?.length > 200 && (
                                            <text
                                              style={{
                                                color: "rgb(235, 129, 40)",
                                                cursor: "pointer",
                                                fontSize: "12px",
                                              }}
                                              onClick={() => {
                                                const updatedShowFullReviews = [
                                                  ...showFullReviews,
                                                ];
                                                updatedShowFullReviews[
                                                  index
                                                ] = !updatedShowFullReviews[
                                                  index
                                                ];
                                                setShowFullReviews(
                                                  updatedShowFullReviews
                                                );
                                              }}
                                            >
                                              {showFullReviews[index]
                                                ? " Show Less"
                                                : " ...Show More"}
                                            </text>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          ) : (
                            <div>
                              <Ratings rating={0} fontSize={"30px"} />
                              <div
                                style={{
                                  color: "rgb(235, 129, 40)",
                                  marginLeft: "8px",
                                  marginTop: "10px",
                                  fontSize: "20px",
                                }}
                              >
                                No Reviews Yet !
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
