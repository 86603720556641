import React from "react";

const UserManagementHighlightedIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="2.55319"
        cy="2.55319"
        r="2.55319"
        transform="matrix(-1 0 0 1 14.2109 6)"
        stroke="#EB8128"
      />
      <path
        d="M7.19141 14.8943C7.19141 14.3451 7.53663 13.8553 8.0538 13.6706V13.6706C10.3855 12.8378 12.9335 12.8378 15.2652 13.6706V13.6706C15.7823 13.8553 16.1276 14.3451 16.1276 14.8943V15.734C16.1276 16.4919 15.4563 17.0741 14.7059 16.967L14.4558 16.9312C12.601 16.6662 10.718 16.6662 8.86319 16.9312L8.61303 16.967C7.86271 17.0741 7.19141 16.4919 7.19141 15.734V14.8943Z"
        stroke="#EB8128"
      />
      <path
        opacity="0.3"
        d="M15.49 11.1762C16.5979 11.1762 17.4961 10.2781 17.4961 9.17014C17.4961 8.06221 16.5979 7.16406 15.49 7.16406"
        stroke="#EB8128"
        stroke-linecap="round"
      />
      <path
        d="M17.6873 15.7537L17.8838 15.7817C18.4734 15.866 19.0008 15.4085 19.0008 14.813V14.1532C19.0008 13.7217 18.7296 13.3368 18.3232 13.1917C17.9179 13.047 17.5042 12.9342 17.0859 12.8535"
        stroke="#EB8128"
        stroke-linecap="round"
      />
      <path
        opacity="0.3"
        d="M7.50998 11.1762C6.40206 11.1762 5.50391 10.2781 5.50391 9.17014C5.50391 8.06221 6.40206 7.16406 7.50998 7.16406"
        stroke="#EB8128"
        stroke-linecap="round"
      />
      <path
        d="M5.31272 15.7537L5.11616 15.7817C4.52662 15.866 3.99917 15.4085 3.99917 14.813V14.1532C3.99917 13.7217 4.27042 13.3368 4.67677 13.1917C5.08211 13.047 5.49576 12.9342 5.91406 12.8535"
        stroke="#EB8128"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default UserManagementHighlightedIcon;
