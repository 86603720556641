import React from "react";
import { InputBase, Paper, Stack, Button, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

const OrderOpen = () => {
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <ul
            className="p-0 mt-lg-3 ms-lg-2"
            style={{ display: "inline-flex" }}
          >
            <li style={{ listStyle: "none" }}>
              <Paper
                elevation={0}
                className="p-lg-3 border rounded-0 custom-border-color"
                component="form"
                sx={{ display: "flex", alignItems: "center" }}
                style={{ width: 267, height: "42px" }}
              >
                <IconButton type="button" sx={{ p: "2px" }} aria-label="search">
                  <SearchIcon />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  style={{
                    fontFamily: "Arial",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "14px",
                  }}
                  placeholder="Search by Name / ID"
                  inputProps={{ "aria-label": "search google maps" }}
                />
              </Paper>
            </li>
            <li className="ms-lg-1" style={{ listStyle: "none" }}>
              <IconButton
                style={{ width: "42px", height: "42px", borderRadius: "100px" }}
                className="border rounded-0 custom-border-color"
                aria-label="delete"
              >
                <FilterAltOutlinedIcon style={{ color: "rgb(235, 129, 40)" }} />
              </IconButton>
            </li>
          </ul>
          <div className="custom-scrollbar">No Open Order</div>
        </div>
      </div>
    </>
  );
};

export default OrderOpen;
