import React from "react";

const SRHighlightedIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 9.09961C5 8.54733 5.44772 8.09961 6 8.09961H18C18.5523 8.09961 19 8.54732 19 9.09961V10.9556C19 11.3972 18.7103 11.7865 18.2873 11.9134L12.2873 13.7134C12.0999 13.7696 11.9001 13.7696 11.7127 13.7134L5.71265 11.9134C5.28967 11.7865 5 11.3972 5 10.9556V9.09961Z"
        stroke="#EB8128"
      />
      <path
        opacity="0.3"
        d="M12 11.7L12 10.5"
        stroke="#EB8128"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.58203 11.6992L5.58203 16.2992C5.58203 17.4037 6.47746 18.2992 7.58203 18.2992H16.4154C17.5199 18.2992 18.4154 17.4037 18.4154 16.2992V11.6992"
        stroke="#EB8128"
      />
      <path
        opacity="0.3"
        d="M14.3346 8.0992V7.69922C14.3346 6.59465 13.4392 5.69922 12.3346 5.69922H11.668C10.5634 5.69922 9.66797 6.59465 9.66797 7.69922L9.66797 8.0992"
        stroke="#EB8128"
      />
    </svg>
  );
};

export default SRHighlightedIcon;
