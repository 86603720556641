import axios from "axios";
import { BASE_URL } from "../../api/ApiPath";
import {
  serviceRequestCreationFail,
  serviceRequestCreationRequest,
  serviceRequestCreationSuccess,
  serviceRequestUpdateFail,
  serviceRequestUpdateRequest,
  serviceRequestUpdateSuccess,
} from "../slices/serviceRequestSlice";
import {
  appliancesFail,
  appliancesRequest,
  appliancesSuccess,
} from "../slices/appliancesSlice";
import {
  applianceDetailsFail,
  applianceDetailsRequest,
  applianceDetailsSuccess,
} from "../slices/applianceDetailsSlice";
import {
  basicChargeFail,
  basicChargeRequest,
  basicChargeSuccess,
} from "../slices/basicChargeSlice";
import { GOOGLE_API_KEY } from "../../api/ApiKey";
import { getNotificationsCount } from "./notificationsAction";
const handleUnAuthorizedError = (err) => {
  if (err.response.status === 401) {
    console.log("working");
    localStorage.clear();
    window.location.href = "/";
    window.location.reload();
  }
};
export const createServiceRequest = (payload) => async (dispatch) => {
  const token = localStorage.getItem("token");

  try {
    dispatch(serviceRequestCreationRequest());
    const response = await axios.post(`${BASE_URL}service/request`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch(serviceRequestCreationSuccess(response?.data));
    dispatch(getNotificationsCount(token));
  } catch (error) {
    handleUnAuthorizedError(error);
    dispatch(serviceRequestCreationFail(error?.response?.data));
  }
};

export const updateServiceRequest = (sr_id, payload, removeImages) => async (dispatch) => {
  const token = localStorage.getItem("token");

  try {
    dispatch(serviceRequestUpdateRequest());
    const response = await axios.patch(
      `${BASE_URL}service/request/edit`,
      payload,
      {
        params: {
          sr_id: sr_id,
          remove_file: JSON.stringify(removeImages),
        },
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    dispatch(serviceRequestUpdateSuccess(response?.data));
    dispatch(getNotificationsCount(token));
  } catch (error) {
    handleUnAuthorizedError(error);
    dispatch(serviceRequestUpdateFail(error?.response?.data));
  }
};
export const reopenServiceRequest = (sr_id, payload) => async (dispatch) => {
  const token = localStorage.getItem("token");
  console.log(payload, "<<<<<<<<<<<<<<<<<<<,,,pppp");
  try {
    dispatch(serviceRequestUpdateRequest());
    const response = await axios.patch(
      `${BASE_URL}service/request/reopen/${sr_id}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          // "Content-Type": "multipart/form-data",
        },
      }
    );
    dispatch(serviceRequestUpdateSuccess(response?.data));
    dispatch(getNotificationsCount(token));
  } catch (error) {
    handleUnAuthorizedError(error);
    dispatch(serviceRequestUpdateFail(error?.response?.data));
  }
};

export const getAppliances = () => async (dispatch) => {
  const token = localStorage.getItem("token");

  try {
    dispatch(appliancesRequest());
    const response = await axios.get(`${BASE_URL}appliance/list`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch(appliancesSuccess(response?.data));
  } catch (error) {
    handleUnAuthorizedError(error);
    dispatch(appliancesFail(error));
  }
};

export const getApplianceDetail = (payload) => async (dispatch) => {
  const token = localStorage.getItem("token");

  try {
    dispatch(applianceDetailsRequest());
    const response = await axios.get(
      `${BASE_URL}appliance/details/${payload.appliance_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    dispatch(applianceDetailsSuccess(response?.data));
  } catch (error) {
    handleUnAuthorizedError(error);
    dispatch(applianceDetailsFail(error));
  }
};

export const getBasicCharge = (payload) => async (dispatch) => {
  const token = localStorage.getItem("token");

  try {
    dispatch(basicChargeRequest());
    const response = await axios.post(
      `${BASE_URL}appliance/basic/charge`,
      payload,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    dispatch(basicChargeSuccess(response?.data));
  } catch (error) {
    handleUnAuthorizedError(error);
    dispatch(basicChargeFail(error));
  }
};

export const getAddressLatLong = () => async (dispatch) => {
  const address =
    "3/188, Perumal kovil street, Achampatti, Thirumangalam, Madurai - 625706";

  const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
    address
  )}&key=${GOOGLE_API_KEY}`;

  try {
    const response = await axios.get(apiUrl);
    const result = response.data.results[0];
    const location = result.geometry.location;
    const latitude = location.lat;
    const longitude = location.lng;
  } catch (error) {
    handleUnAuthorizedError(error);
    console.error("Error fetching data:", error);
  }
};
