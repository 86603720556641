import React from "react";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

const ToastMessage = ({ message, status, clearFunc, hideProgressBar }) => {
  const dispatch = useDispatch();
  toast(message, {
    position: toast.POSITION.TOP_RIGHT,
    type: status,
    ...(clearFunc && {
      onOpen: () => {
        dispatch(clearFunc());
      },
    }),
  });
  return (
    <ToastContainer
      theme={"light"}
      hideProgressBar={hideProgressBar ? true : false}
      style={{ width: "500px" }}
    />
  );
};

export default ToastMessage;
