import React from 'react';
import { Button } from "@mui/material";

const RatingDisplay = ({ averageRating, totalRatings }) => {
    const maxStars = 5;

    return (
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
            <Button
                variant="outlined"
                disabled
                style={{
                    color: "white",
                    cursor: "none",
                    background: "green",
                    marginRight: '10px' // Add margin for spacing
                }}
            >
                {averageRating.toFixed(1)}{" ★"}
            </Button>
            {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                {[...Array(maxStars)].map((_, index) => (
                    <span
                        key={index + 1}
                        style={{ color: index < Math.floor(averageRating) ? 'yellow' : 'gray', margin: '0 2px' }}
                    >
                        
                    </span>
                ))}
            </div> */}
            <p style={{ margin: '5px 0', marginLeft: '1px', fontFamily: 'Arial, sans-serif', }}>{totalRatings} Ratings</p>
        </div>
    );
};

export default RatingDisplay;
