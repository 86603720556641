import axios from "axios";
import { BASE_URL } from "../../api/ApiPath";
import { referralChartListRequest, referralChartListFail, referralChartListSuccess } from "../slices/referralChatSlice";
export const getAllreferralChartList = ( search, filter) => async (dispatch) => {
        let payload = search ? `search=${search}&` : '';
        payload += filter?.zone_id ? `zone_id=${filter?.zone_id}&` : '';
        payload += filter?.district ? `district=${filter?.district}&` : '';
    const token = localStorage.getItem("token");
    try {
        dispatch(referralChartListRequest())
        const response = await axios.get(`${BASE_URL}/source?${payload}`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        dispatch(referralChartListSuccess(response?.data))
    } catch (error) {
        console.log(error);
        dispatch(referralChartListFail(error?.response?.data))
    }
}
