import axios from "axios";
// import { DRAW_SERVICE_BASE_URL } from "../../api/apiBaseUrl";
import { BASE_URL } from "../../api/ApiPath";
import { RewardDistrictListRequest, RewardDistrictListFail, RewardDistrictListSuccess } from "../slices/rewardDistrictListSlice";
export const getAllRewardDistrictList = (startDate, endDate, status) => async (dispatch) => {
    const token = localStorage.getItem("token");
    // let payload = id ? `draw_id=${id}&startingNumber=0000&limit=9999&onlySoldOut=false` : '';

    try {
        dispatch(RewardDistrictListRequest())
        const response = await axios.get(`${BASE_URL}technician/reward/district-count?startDate=${startDate}&endDate=${endDate}&status=${status}`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        dispatch(RewardDistrictListSuccess(response?.data))
        console.log("RewardDistrictListLists", response?.data);
    } catch (error) {
        console.log(error);
        dispatch(RewardDistrictListFail(error?.response?.data))
    }
}
