import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { getDashboardDistrictById } from "../../redux/actions/adminDashboard";

const DashboardDataTable2 = () => {
  const role = localStorage.getItem("role");
  const { zonalAdminDashboardData } = useSelector(
    (state) => state.zonalAdminDashboardState
  );
  const zoneId = localStorage.getItem("zone_id");
  const [districtData, setDistrictData] = useState([]);
  console.log(zonalAdminDashboardData, "<<<<<<<<<<");
  const getDistrictData = (id) => {
    const payload = {
      startDate: zonalAdminDashboardData?.start_date,
      endDate: zonalAdminDashboardData?.end_date,
      zone_id: id,
    };
    getDashboardDistrictById(payload)
      .then((res) => {
        console.log("district-res: ", res);
        setDistrictData(
          res?.data?.map((value) => ({
            districtAdminId: value?.dist_admin_id,
            districtAdminName: value?.userValue?.fullname,
            district: value?.city_name,
            totalSRs:
              value?.SRCount?.srCountDetails?.accepted +
              value?.SRCount?.srCountDetails?.assigned +
              value?.SRCount?.srCountDetails?.cancelled +
              value?.SRCount?.srCountDetails?.closed +
              value?.SRCount?.srCountDetails?.notified +
              value?.SRCount?.srCountDetails?.reopened +
              value?.SRCount?.srCountDetails?.unassigned,
            totalTechnicians:
              value?.userCount?.technician +
              value?.userCount?.district_admin +
              value?.userCount?.zonal_admin +
              value?.userCount?.super_admin,
          }))
        );
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  const districtColumns = [
    {
      name: "District Admins",
      selector: (row) => row.districtAdminName,
      sortable: true,
    },
    {
      name: "District",
      selector: (row) => row.district,
      sortable: true,
    },
    {
      name: "Total SRs",
      selector: (row) => row.totalSRs,
      sortable: true,
    },
    {
      name: "Total Technicians",
      selector: (row) => row.totalTechnicians,
      sortable: true,
    },
  ];
  useEffect(() => {
    getDistrictData(zoneId);
  }, [zoneId]);
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
  };
  return (
    <div
      className={`data-table-container ${role === "ZonalAdmin" ? "max-h" : ""}`}
    >
      <DataTable
        columns={districtColumns}
        data={districtData}
        pagination
        fixedHeader
        fixedHeaderScrollHeight="300px"
        customStyles={customStyles}
      />
    </div>
  );
};

export default DashboardDataTable2;
