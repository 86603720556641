import React, { useEffect } from "react";
import Navbar from "../common/Navbar";
import Footer from "../Footer";
import HowToBook from "../services/HowToBook";
import FindUs from "../services/FindUs";
import TellMeAbout from "../services/TellMeAbout";
import Quote from "../television/Quote";
import FAQ from "../television/FAQ";
import UpcomingService from "../services/UpcomingService";
import WAServiceBanner from "./WAServiceBanner";
import WAServiceOffered from "./WAServiceOffered";

export default function WAService() {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Navbar />
      <WAServiceBanner />
      <WAServiceOffered />
      <Quote
        quote={`“Trust and satisfaction are the biggest reasons I insist on booking my washing machine service from the S3 app. They have the best Washing Machine Technicians in Chennai who procure the best-suited and affordable spare parts as well as explain the issue and solution clearly to us. To date all my appliance services have been taken up with utmost care and attention” – Sangamitra, Tambaram  `}
      />
      <FAQ
        appliance={"Washing Machine"}
        last_answer={
          "Yes, our technicians support end-to-end washing machine installation and services. This includes recommendations for stabilizers (if subject to frequent fluctuation), stand fixtures, and other value-added services to ensure an amazing experience.  "
        }
        appliance_abbreviation={"Washing Machine"}
      />
      <UpcomingService />
      <TellMeAbout />
      <FindUs />
      <HowToBook />
      <Footer />
    </div>
  );
}
