import axios from "axios";
import { BASE_URL } from "../../api/ApiPath";
import {
  customerDashboardFail,
  customerDashboardRequest,
  customerDashboardSuccess,
} from "../slices/customerDashboardSlice";

const handleUnAuthorizedError = (err) => {
  if (err.response.status === 401) {
    console.log("working");
    localStorage.clear();
    window.location.href = "/";
    window.location.reload();
  }
};
export const getCustomerDashboard = (payload) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch(customerDashboardRequest());
    const response = await axios.get(`${BASE_URL}/customer/dashboard/view`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch(customerDashboardSuccess(response?.data));
  } catch (error) {
    handleUnAuthorizedError(error);
    dispatch(customerDashboardFail(error));
  }
};
