import { createSlice } from "@reduxjs/toolkit";


const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        loading: false,
    },
    reducers: {
        notificationsRequest(state, action) {
            return {
                ...state,
                loading: true
            }
        },
        notificationsSuccess(state, action) {
            return {
                ...state,
                loading: true,
                notifications: action.payload,
            }
        },
        notificationsFail(state, action) {
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        },
        notificationsErrorClear(state, action) {
            return {
                ...state,
                loading: false,
                error:false,
            }
        }
    }
});

const { actions, reducer } = notificationsSlice;

export const { notificationsRequest, notificationsFail, notificationsSuccess, notificationsClear } = actions

export default reducer;