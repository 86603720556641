import axios from "axios";
import { BASE_URL } from "../../api/ApiPath";
import {
  technicianDashboardFail,
  technicianDashboardRequest,
  technicianDashboardSuccess,
} from "../slices/technicianDashboardSlice";

const handleUnAuthorizedError = (err) => {
  if (err.response.status === 401) {
    console.log("working");
    localStorage.clear();
    window.location.href = "/";
    window.location.reload();
  }
};
export const getTechnicianDashboard = (payload) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch(technicianDashboardRequest());
    const response = await axios.get(
      `${BASE_URL}technician/dashboard/view?startDate=${payload.startDate}&endDate=${payload.endDate}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    dispatch(technicianDashboardSuccess(response?.data));
  } catch (error) {
    handleUnAuthorizedError(error);
    dispatch(technicianDashboardFail(error));
  }
};
