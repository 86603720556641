import { createSlice } from "@reduxjs/toolkit";


const appliancesSlice = createSlice({
    name: 'appliances',
    initialState:{
        loading: false,
    },
    reducers:{
        appliancesRequest(state, action){
            return{
                ...state,
                loading: true
            }
        },
        appliancesSuccess(state, action){
            return{
                ...state,
                loading: true,
                appliancesData: action.payload
            }
        },
        appliancesFail(state, action){
            return{
                ...state,
                loading: false,
                error: action.payload
            }
        }
    }
});

const {actions, reducer} = appliancesSlice;

export const {appliancesRequest,appliancesFail,appliancesSuccess} = actions

export default reducer;