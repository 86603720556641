import { createSlice } from "@reduxjs/toolkit";

const readAllNotificationsSlice = createSlice({
  name: "readAllNotifications",
  initialState: {
    loading: false,
  },
  reducers: {
    readAllNotificationsRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    readAllNotificationsSuccess(state, action) {
      return {
        ...state,
        loading: false,
        readAllNotifications: action.payload,
      };
    },
    readAllNotificationsFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    readAllNotificationsErrorClear(state, action) {
      return {
        ...state,
        loading: false,
        error: false,
      };
    },
  },
});

const { actions, reducer } = readAllNotificationsSlice;

export const {
  readAllNotificationsRequest,
  readAllNotificationsFail,
  readAllNotificationsSuccess,
  readAllNotificationsClear,
} = actions;

export default reducer;
