import React, { useEffect, useState } from "react";
import Topbar from "../../topbar";
import Sidebar from "../../Sidebar";
import {
  InputBase,
  Paper,
  Stack,
  Button,
  TextField,
  useMediaQuery,
  Avatar,
} from "@mui/material";
import Sample from "../../../Images/sample_avatar.jpg";
import Unknown from "../../../Images/Group2.png";
import Swal from "sweetalert2";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Allcategory from "../../SuperAdmin/SuperAdminCategoryManagement/AllCategory";
import { AppBar, Tabs, Tab, Box, FormHelperText } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FormControl from "@mui/material/FormControl";
import Switch from "@mui/material/Switch";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import Api from "../../../service/services";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AWS from "aws-sdk";
import testImage from "../../../Images/t-img-1.png";
import AllAppliences from "../../Customers/AllAppliences";
import AllAppliance from "./AllAppliance";
import ActiveAppliance from "./ActiveAppliance";
import InActiveAppliance from "./InActiveAppliance";
import { useNavigate } from "react-router-dom";

const CategoryManagement = () => {
  const [selectedTab1, setSelectedTab1] = React.useState(0);
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setSelectedTab1(newValue);
  };

  const TabPanel = ({ value, index, children }) => {
    return (
      <div role="tabpanel" hidden={value !== index}>
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  };
  const [role, setRole] = useState("");

  useEffect(() => {
    const Role = localStorage.getItem("role");
    setRole(Role);
  }, []);

  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <Topbar />
        <div
          className="row container-fluid"
          style={{ paddingRight: "0px", overflow: "hidden" }}
        >
          <div className="col-2 col-12 p-0 border border-right">
            <Sidebar />
          </div>
          <div
            className="col-12 user_management"
            style={{ paddingRight: "0px", marginTop: "80px" }}
          >
            <div className="row">
              <div className="mt-lg-2">
                <i
                  className="bi bi-chevron-left "
                  style={{
                    fontWeight: "600",
                    fontSize: "21px",
                    width: "20px",
                    height: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(-1)}
                ></i>
                <text
                  className="p-0"
                  style={{
                    fontWeight: "600",
                    color: "#1E232C",
                    fontSize: "21px",
                    width: "208px",
                    height: "32px",
                  }}
                >
                  Appliance Management
                </text>
              </div>
            </div>

            <div className="container-fluid" style={{ overflow: "hidden" }}>
              <div className="row">
                <div className="col-lg-12 p-0 mt-1">
                  <div className="col-lg-4 ms-1">
                    <AppBar
                      style={{ boxShadow: "none" }}
                      position="static"
                      className="w-100 bg-white"
                      aria-label="basic tabs example"
                    >
                      <Tabs
                        value={selectedTab1}
                        onChange={handleChange}
                        variant="fullWidth"
                        className="scroll-tabs"
                      >
                        <Tab
                          style={{
                            /*  fontSize: "14px", */
                            backgroundColor: "white",
                            textTransform: "none",
                          }}
                          label="All"
                        />
                        <Tab
                          style={{
                            /*   fontSize: "14px", */
                            backgroundColor: "white",
                            textTransform: "none",
                          }}
                          label="Active"
                        />
                        <Tab
                          style={{
                            /*    fontSize: "14px", */
                            backgroundColor: "white",
                            textTransform: "none",
                          }}
                          label="Inactive"
                        />
                      </Tabs>
                    </AppBar>
                  </div>

                  <TabPanel value={selectedTab1} index={0}>
                    <AllAppliance />
                  </TabPanel>
                  <TabPanel value={selectedTab1} index={1}>
                    <ActiveAppliance />
                  </TabPanel>
                  <TabPanel value={selectedTab1} index={2}>
                    <InActiveAppliance />
                  </TabPanel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CategoryManagement;
