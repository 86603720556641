import SearchIcon from "@mui/icons-material/Search";
import { Card, InputBase, Paper, Tabs } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import img1 from "../../../Images/t-img-1.png";
import React, { useState } from "react";

const TotalProducts = () => {
  const initialText =
    "CA-266 Input-12V-28V, 22-65 inch LED TV Backlight Driver...";
  const maxLength = 50; // Number of characters to display initially

  const [displayText, setDisplayText] = useState(initialText);
  const [showFullText, setShowFullText] = useState(false);

  const toggleTextDisplay = () => {
    setShowFullText(!showFullText);
  };
  return (
    <div className="container-fluid mt-3">
      <div className="container-fluid">
        <div>
          <Paper
            elevation={0}
            className="w-100 p-lg-3 border rounded-0"
            component="form"
            sx={{ display: "flex", alignItems: "center" }}
            style={{ width: "100%", height: "42px" }}
          >
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              style={{
                fontFamily: "Arial",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "14px",
              }}
              placeholder="Search by Product name, SKU"
            />
          </Paper>
        </div>

        <div className="mt-3">
          <text
            className="p-0"
            style={{
              fontWeight: "600",
              color: "#1E232C",
              fontSize: "21px",
              width: "208px",
              height: "32px",
            }}
          >
            Total Products
          </text>
        </div>

        <div className="row mt-3">
          <div className="col-lg-3 col-12">
            <Card>
              <Link to="/productdetails">
                <img alt="demo" className="ms-lg-3 w-100" src={img1} />
              </Link>
              <div className="p-lg-2 p-2">
                <text style={{ color: "#6A707C", fontSize: "14px" }}>
                  SKU: CA266
                </text>
                <br />
                <text
                  className="TotalProductsfontsiz"
                  style={{ fontSize: "16px" }}
                >
                  {showFullText ? initialText : displayText.slice(0, maxLength)}
                  {displayText.length > maxLength && !showFullText && (
                    <span
                      onClick={toggleTextDisplay}
                      style={{ cursor: "pointer", color: "blue" }}
                    >
                      ... Read More
                    </span>
                  )}
                  {showFullText && (
                    <span
                      onClick={toggleTextDisplay}
                      style={{ cursor: "pointer", color: "blue" }}
                    >
                      Read Less
                    </span>
                  )}
                </text>
                <div className="row p-2">
                  <div className="col text-start p-0">
                    <text
                      className="TotalProductsfontsiz"
                      style={{ fontSize: "16px" }}
                    >
                      ₹1017.36 /Unit
                    </text>
                  </div>
                  <div className="col text-center p-0">
                    <Link to={"/cart"} style={{ color: "rgb(235, 129, 40)" }}>
                      <text
                        className="TotalProductsfontsiz"
                        style={{ fontSize: "16px", color: "rgb(235, 129, 40)" }}
                      >
                        Add To Cart
                      </text>
                    </Link>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TotalProducts;

// import React, { useState } from 'react';
// import SearchIcon from '@mui/icons-material/Search';
// import { Card, InputBase, Paper } from "@mui/material";
// import IconButton from '@mui/material/IconButton';
// import { Link } from 'react-router-dom';
// import img1 from "../../../Images/t-img-1.png";

// const TotalProducts = () => {
//     const initialText =
//         "CA-266 Input-12V-28V, 22-65 inch LED TV Backlight Driver...";
//     const maxLength = 50; // Number of characters to display initially

//     const [displayText, setDisplayText] = useState(initialText);
//     const [showFullText, setShowFullText] = useState(false);
//     const [products, setProducts] = useState([
//         {
//             id: 1,
//             name: "Product 1",
//             sku: "CA266",
//             price: "1017.36",
//             description: "Description of Product 1..."
//         },
//         // Add more products here
//     ]);

//     const toggleTextDisplay = (index) => {

//         setShowFullText(!showFullText);

//         const updatedProducts = products.map((product, i) => {
//             if (i === index) {
//                 return {
//                     ...product,
//                     showFullText: !product.showFullText
//                 };
//             }
//             return product;
//         });
//         setProducts(updatedProducts);
//     };

//     return (
//         <div className='container-fluid mt-3'>
//             <div className='container-fluid'>
//                 <div>
//                     <Paper elevation={0} className="w-100 p-lg-3 border rounded-0"
//                         component="form"
//                         sx={{ display: 'flex', alignItems: 'center' }}
//                         style={{ width: '100%', height: '42px' }}
//                     >
//                         <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
//                             <SearchIcon />
//                         </IconButton>
//                         <InputBase
//                             sx={{ ml: 1, flex: 1 }}
//                             style={{ fontFamily: 'Roboto', fontStyle: "normal", fontWeight: "400", fontSize: "14px", lineHeight: "14px" }}
//                             placeholder="Search by Product name, SKU"
//                         />
//                     </Paper>
//                 </div>
//                 <div className='row mt-3'>
//                     {products.map((product, index) => (
//                         <div className='col-lg-3 col-12' key={product.id}>
//                             <Card>
//                                 <img className="ms-lg-3 w-100" src={img1} alt={`Product ${index}`} />
//                                 <div className='p-lg-2 p-2'>
//                                     <text style={{ color: '#6A707C', fontSize: '14px' }}>SKU: {product.sku}</text><br />
//                                     <text className='TotalProductsfontsiz' style={{ fontSize: '16px' }}>
//                                         {product.showFullText ? product.description : product.description.slice(0, maxLength)}
//                                         {product.description.length > maxLength && !product.showFullText && (
//                                             <span onClick={() => toggleTextDisplay(index)} style={{ cursor: 'pointer', color: 'blue' }}>
//                                                 ... Read More
//                                             </span>
//                                         )}
//                                         {product.showFullText && (
//                                             <span onClick={() => toggleTextDisplay(index)} style={{ cursor: 'pointer', color: 'blue' }}>
//                                                 Read Less
//                                             </span>
//                                         )}
//                                     </text>
//                                     <div className='row p-2'>
//                                         <div className='col text-start p-0'>
//                                             <text className='TotalProductsfontsiz' style={{ fontSize: '16px' }}>₹{product.price} /Unit</text>
//                                         </div>
//                                         <div className='col text-center p-0'>
//                                             <Link to={"/cart"} style={{ color: 'rgb(235, 129, 40)' }}>
//                                                 <text className='TotalProductsfontsiz' style={{ fontSize: '16px', color: 'rgb(235, 129, 40)' }}>
//                                                     Add To Cart
//                                                 </text>
//                                             </Link>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </Card>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default TotalProducts;
