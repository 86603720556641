import { createSlice } from "@reduxjs/toolkit";

const districtAdminDashboardSlice = createSlice({
  name: "districtAdminDashboard",
  initialState: {
    loading: false,
  },
  reducers: {
    districtAdminDashboardRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    districtAdminDashboardSuccess(state, action) {
      return {
        ...state,
        loading: false,
        districtAdminDashboardData: action.payload,
      };
    },
    districtAdminDashboardFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
  },
});

const { actions, reducer } = districtAdminDashboardSlice;

export const {
  districtAdminDashboardRequest,
  districtAdminDashboardFail,
  districtAdminDashboardSuccess,
} = actions;

export default reducer;
