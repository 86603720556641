import React, { useEffect } from "react";
import Navbar from "../common/Navbar";
import Quote from "../television/Quote";
import Footer from "../Footer";
import HowToBook from "../services/HowToBook";
import FindUs from "../services/FindUs";
import TellMeAbout from "../services/TellMeAbout";
import FAQ from "../television/FAQ";
import UpcomingService from "../services/UpcomingService";
import DTHServiceBanner from "./DTHServiceBanner";
import DTHServiceOffered from "./DTHServiceOffered";
export default function DTHService() {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Navbar />
      <DTHServiceBanner />
      <DTHServiceOffered />
      <Quote
        quote={`“I am super happy to share that I am a loyal customer of S3. Anything as small as inserting the smart card in the TV slot in a right manner or clean-up of the dish placed in the terrace is done by the technicians with a smile and utmost care” – Pavan, Erode `}
      />
      <FAQ
        appliance={"DTH"}
        appliance_abbreviation={"DTH"}
        last_answer={
          "Yes, our technicians support end-to-end DTH installation and services.  "
        }
      />
      <UpcomingService />
      <TellMeAbout />
      <FindUs />
      <HowToBook />
      <Footer />
    </div>
  );
}
