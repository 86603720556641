import React, { useState, useEffect, useRef } from "react";
import "../../common.css";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Paper, Stack } from "@mui/material";
import profile from "../../Images/smile.jpg";
import Playstore from "../../Images/playstore.png";
import Appstore from "../../Images/appstore.png";
import Crown from "../../Images/crown.png";
import "../../layout.css";
import Sidebar from "../Sidebar";
import Topbar from "../topbar";
import Footer from "../Footer";
import ProgressBar from "./progress";
import Api from "../../service/services";
import { useNavigate } from "react-router-dom";
import LandingPage from "../Landingpage";
import Button from "@mui/material/Button";
import Television from "../../Images/television.png";
import Fridge from "../../Images/refrigerator.png";
import Wash from "../../Images/wash.png";
import Ac from "../../Images/cooler.png";
import Logo from "../../Images/newLogo.png";
import Mobile from "../../Images/mobile.png";
import Play from "../../Images/playstore.png";
import App from "../../Images/appstore.png";
import TechLogo from "../../Images/techlogo.png";
import SRcard from "../../Images/srcard.png";
import Header from "../Header";
import Suit from "../../Images/suitcase.png";
import Book from "../../Images/book.png";
import "../../landingpage.css";
import Logoin from "../../Images/s3Logo.png";
import ApplianceCarousel from "../ApplianceCarousel";
import { useDispatch, useSelector } from "react-redux";
import { getAppliances } from "../../redux/actions/serviceRequestAction";
import moment from "moment";
import { getCustomerDashboard } from "../../redux/actions/customerDashboardAction";
import CalendarIcon from "../../Assets/icons/CalendarIcon";
import ArrowIconHighlighted from "../../Assets/icons/ArrowIconHighlighted";
import RectIcon from "../../Assets/icons/RectIcon";
import HomeFooter from "../HomeFooter";
import { CreateReward } from "../../redux/actions/createReward";
import {
  EndToEndIcon,
  OpenDocIcon,
  UnifiedIcon,
} from "../../Assets/Home/Features";
import tv from "../../Assets/Cust/dash-tv.svg";
import rectangle from "../../Assets/Cust/Rectangle-cust-reward.svg";
import Swal from "sweetalert2";
import { getAllTodayParticipants } from "../../redux/actions/todayParticipants";
import { clearStatusCode } from "../../redux/slices/createRewardSlice";
import confetti from "../../Assets/Home/confetti.gif";
import { getAllWinningCust } from "../../redux/actions/winningCustAction";

const CustomerDashBoard = () => {
  /*   const navigate = useNavigate(); */
  /* const [selectedImage, setSelectedImage] = useState(null); */
  const [applianceData, setApplianceData] = useState([]);
  const [ServiceList, setServiceList] = useState([]);
  const [selectedApplianceId, setSelectedApplianceId] = useState("");
  const [InprogressSRList, setInprogressSRList] = useState([]);
  const [progressPercentage, setProgressPercentage] = useState("");
  const Name = localStorage.getItem("name");
  const dispatch = useDispatch();
  const { appliancesData } = useSelector((state) => state.appliancesState);
  const { customerDashboardData } = useSelector(
    (state) => state.customerDashboardState
  );

  const { statusCode, successMsg, errorMsg } = useSelector(
    (state) => state.createRewardState
  );
  const [selectedAppliance, setSelectedAppliance] = useState(null);

  const [CustomerDashboardList, setCustomerDashboardList] = useState([]);
  /*  const handleImageClick = (image, appliance_id) => {
        setSelectedImage(image);
        setSelectedApplianceId(appliance_id)
    } */
  const appliancesList = [
    { id: 1, img: tv, name: "Television 1" },
    { id: 2, img: tv, name: "Television 2" },
    { id: 3, img: tv, name: "Television 3" },
    { id: 4, img: tv, name: "Television 4" },
    { id: 5, img: tv, name: "Television 5" },
    { id: 6, img: tv, name: "Television 6" },
    { id: 7, img: tv, name: "Television 7" },
    { id: 8, img: tv, name: "Television 8" },
    { id: 9, img: tv, name: "Television 9" },
    { id: 10, img: tv, name: "Television 10" },
    { id: 11, img: tv, name: "Television 11" },
    { id: 12, img: tv, name: "Television 12" },
  ];

  const handleApplianceClick = (id) => {
    setSelectedApplianceId(id);
    navigate(`/createService?id=${id}`);
  };

  useEffect(() => {
    Api.getServiceRequestList().then((res) => {
      if (res.data) {
        setServiceList(res.data);
        setInprogressSRList(
          res.data.filter(
            (item) =>
              item.is_accepted === "1" && item.service_order_status === "open"
          )
        );
        if (res.data.filter((item) => item.status === "Accepted")) {
          setProgressPercentage("50");
        }
      }
    });
  }, []);

  useEffect(() => {
    Api.getApplianceDropdown().then((res) => {
      if (res) {
        setApplianceData(res);
      }
    });
    Api.getSRCustomerDashboard().then((res) => {
      if (res) {
        setCustomerDashboardList(res);
      }
    });
  }, []);

  const createNewService = () => {
    navigate("/createService", {
      state: {
        data: {
          applience_id: selectedApplianceId,
        },
      },
    });
  };

  const showAllAppliences = () => {
    navigate("/appliances");
  };
  const { TodayParticipantsData } = useSelector(
    (state) => state.todayParticipantsState
  );
  const { WinningCustData } = useSelector((state) => state.WinningCustState);
  const today = new Date().toISOString().split("T")[0];
  const filteredWinners = WinningCustData?.filter(
    (winner) => winner.winning_status === true
  );
  const disableParticipate = WinningCustData?.filter(
    (winner) => {
      const participatedAt = new Date(winner.participated_at);
      const ISTOffset = 5.5 * 60 * 60 * 1000;
      const ISTDate = new Date(participatedAt.getTime() + ISTOffset);
      const participatedAtIST = ISTDate.toISOString().split('T')[0];
      console.log(participatedAtIST, today);
      return winner.winning_status === true && participatedAtIST === today;
    }
  )
  useEffect(() => {
    dispatch(getAllWinningCust());
    dispatch(getAllTodayParticipants());
  }, [dispatch]);
  const [isUserIncluded, setIsUserIncluded] = useState(false);
  const handleSubmit = () => {
    dispatch(CreateReward());
  };
  useEffect(() => {
    switch (statusCode) {
      case "success": {
        dispatch(getAllTodayParticipants());
        dispatch(clearStatusCode());
        Swal.fire({
          text: `Good luck and thank you for being a valued customer of S3!`,
          confirmButtonText: "OK",
        });
        break;
      }
      case "error": {
        dispatch(clearStatusCode());
        Swal.fire({
          text: `${errorMsg}`,
          confirmButtonText: "OK",
        });
        break;
      }
      default: {
        dispatch(clearStatusCode());
        break;
        // return null;
      }
    }
  }, [statusCode]);
  useEffect(() => {
    const user_id = Number(localStorage.getItem("id")); // Convert the ID to a number
    if (TodayParticipantsData?.user_id?.includes(user_id)) {
      setIsUserIncluded(true);
    }
  }, [TodayParticipantsData]);
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();

  const handleImageClick = (image) => {
    setSelectedImage(image);
    localStorage.setItem("items", JSON.stringify(image));
  };
  useEffect(() => {
    dispatch(getAppliances());
    const user_token = localStorage.getItem("token");
    const user_role = localStorage.getItem("role");
    console.log(user_role);
    if (user_token) {
      if (user_role === "Technician") {
        navigate("/payment-wallet");
      } else if (user_role === "SuperAdmin") {
        navigate("/Dashboard");
      } else if (user_role === "ZonalAdmin") {
        navigate("/Dashboard");
      } else if (user_role === "DistrictAdmin") {
        navigate("/approve-pending-technicians");
      } else if (user_role === "Consumer") {
        navigate("/customerDashboard");
      } else {
        navigate("/");
      }
    }
  }, []);
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string?.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name?.split(" ")[0][0]}`,
    };
  }
  useEffect(() => {
    dispatch(getCustomerDashboard());
  }, []);
  console.log(customerDashboardData, "customerDashboardData");
  const handleSRDetail = (data) => {
    navigate(
      `/serviceList?sr_history_status=${data?.sr_work_history[0]?.status}&sr_status=${data?.status}&sr_id=${data?.sr_work_history[0]?.sr_id}`
    );
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // winning status

  const [activeIndex, setActiveIndex] = useState(0);
  const [winnersPerPage, setWinnersPerPage] = useState(3);
  const scrollRef = useRef(null);

  const handleScroll = () => {
    const scrollPosition = scrollRef.current.scrollLeft;
    const containerWidth = scrollRef.current.clientWidth;
    const itemWidth = containerWidth / winnersPerPage;

    let index = Math.round(scrollPosition / itemWidth);
    const totalPages = Math.ceil(WinningCustData.length / winnersPerPage);
    index = Math.min(index, totalPages - 1);

    setActiveIndex(index);
  };

  const updateWinnersPerPage = () => {
    const width = window.innerWidth;
    setWinnersPerPage(width <= 768 ? 1 : 3);
  };

  useEffect(() => {
    updateWinnersPerPage();
    window.addEventListener("resize", updateWinnersPerPage);
    const scrollElement = scrollRef?.current;
    scrollElement?.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("resize", updateWinnersPerPage);
      scrollElement?.removeEventListener("scroll", handleScroll);
    };
  }, [WinningCustData]);

  const totalPages = Math.ceil(WinningCustData?.length / winnersPerPage);
  const maxDots = 5;

  const getDots = () => {
    const dots = [];
    const start = Math.max(0, activeIndex - 2);
    const end = Math.min(totalPages, start + maxDots);

    for (let i = start; i < end; i++) {
      dots.push(
        <span
          key={i}
          className={`dot ${i === activeIndex ? "active" : ""}`}
        ></span>
      );
    }

    if (totalPages > maxDots && activeIndex >= maxDots - 1) {
      dots.shift(); // Remove the first dot if activeIndex reaches the end
    }

    return dots;
  };

  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <Topbar />
        <div
          className="row container-fluid"
          style={{
            paddingRight: "0px",
            overflow: "hidden",
            paddingLeft: "0px",
            marginLeft: "0px",
          }}
        >
          <div className="col-lg-2 col-12 p-0 border border-right">
            <Sidebar />
          </div>
          <>
            <div className="col-12 col-12 user_management_dasboard res-font">
              <div className="">
                <div className="pt-0 ">
                  <div className="bg-white">
                    <div style={{ marginTop: "90px" }}>
                      <div
                        className="mt-2 mb-2"
                        style={{
                          fontSize: "1.6rem",
                          fontWeight: "600",
                          fontFamily: "Arial",
                          padding: "10px",
                        }}
                      >
                        Welcome,{" "}
                        <span style={{ textTransform: "capitalize" }}>
                          {Name}
                        </span>{" "}
                        !
                      </div>
                      <div className="m-3 cust-container-reward">
                        <img
                          src={rectangle}
                          alt="rectangle"
                          className="cust-rectangle"
                        />
                        <div className="cust-reward-content res-576px-d-unset">
                          <div style={{ width: "70%" }}>
                            <h4 style={{ textAlign: "start" }}>
                              <b>Participate in Our Weekly Prize Contest!</b>
                            </h4>
                            {/* <p style={{ width: "90%" }}>
                              Now that you're signed in, you have the chance to
                              win exciting prize in our weekly contest. Simply
                              click the Participate button.
                            </p> */}
                            {isUserIncluded ?
                              <p style={{ width: "90%" }}>Good luck and thank you for being a valued customer of S3!</p> : <p style={{ width: "90%" }}>
                                Now that you're signed in, you have the chance to
                                win exciting prize in our weekly contest. Simply
                                click the Participate button.
                              </p>}
                          </div>
                          <button
                            className="cust-reward-submit-button"
                            style={{
                              opacity:
                                isUserIncluded || disableParticipate?.length > 0
                                  ? 0.5
                                  : 1,
                              cursor:
                                (isUserIncluded ||
                                  disableParticipate?.length > 0) &&
                                "not-allowed",
                            }}
                            disabled={
                              isUserIncluded || disableParticipate?.length > 0
                            }
                            onClick={handleSubmit}
                          >
                            {isUserIncluded
                              ? "Already Participated"
                              : "Participate"}
                          </button>
                        </div>
                      </div>
                      {filteredWinners?.length > 0 && (
                        <div className="m-3 chart-container-winners">
                          <div className="winner-content">
                            <b>Congratulations Winners!</b>
                            <p>
                              Your participation means a lot and we hope you
                              enjoy the prize!
                            </p>
                          </div>
                          <div className="winner-list" ref={scrollRef}>
                            {filteredWinners.map((winner, i) => (
                              <div className="winner-item" key={winner.id || i}>
                                <img
                                  className="winner-image"
                                  src={
                                    winner.avatar ||
                                    "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBw8ODw4NDg0QDw4ODQ0NDg0NDg8NDQ4NFREWFhURFRMYHSggGBolGxUTITEhJSkrLi4uGB8zODMtNygtLisBCgoKDQ0NDg0NDzcZFRk3KysrKy0rNysrKysrLSsrKysrKys3KysrKysrKysrKysrKysrKysrKysrKysrKysrK//AABEIAOEA4QMBIgACEQEDEQH/xAAaAAEBAAMBAQAAAAAAAAAAAAAAAwECBQQH/8QAMhABAAIAAwUFBgYDAAAAAAAAAAECAwQRITFRUpESE0FhcQUUMqHR8CJCcoGx4TOSwf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAwDAQACEQMRAD8A+4gAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAATIA0nEhrN5BUR7U8WNQXENWe1PFRYSi8toxEG4xEsgAAAAAAAAAAAAAAAADFp0StbUG1sTg0mQVAAAAAAAABvW/FoAtEsoxOilbaorYAAAAAAAAAAABi1tCZSmdQYmQFQAAAAHlxs7EbK/inj4f281s5efHT0iBXTHLjN4nN1iFsLPeFo/ePoGPcMVtExrE6x5MiAAAAK0tq2QiVqzqisgAAAAAAAA1tOkA0vZqCoAAAAOfncxrM0rujfPGeD15nE7NJnx3R6y5IoAigAK5fHmk+U74+/F1azExExunbDivf7OxNYmvDbHpIj2AKgAAzS2jAC41pOxsigAAAAACeJKiMyDACoAAAA8vtH4Y/XH8S5zp56utJ8pifvq5iLAAUAAer2d8c/pn+YeV7fZtdtreUR/0R7gFQAAABtSdqqC8IoAAAAADEoq33SkIAKAAAAMTGuxysxhdi2nh4Txh1mmLhRaNJ/uBXHF8bKWrujtRxj6IIoCmFgWtuj952QDStZmYiI1mXWwMPsVivWeMtMvl4p524/RcQAVAAAABWm6ElcPcitgAAAAAa33SktKIgAoAAAxa0RtmdI8wZHlxM9WPhibfKHnvnbzu0j0jWfmK6TW1InfWJ9Y1cqce877z10aTaZ8Z6yg7EYVY3Vj/AFbOLrPGerMYlo3WnrIY7I5Vc1ePza+u1amfn81YnzjYo94jhZmlt06TwnZKwgAAAArh7klaboRWwAAAAACErpYkbQagKgCOaxuxXXxnZHrxBrmc1FNkbbfKPVzsTEm062nVrMiNAAAAAAAAD04Gbmuy34q/OHmAdqtomImJ1ifFlzcljdm3ZndaekukrIAAtCdI2qooAAAAAA1vGsNgEBm8aSwqDne0La304RHz+4dFy87/AJLft/ECxABFAAAAAAAAAAHapbWInjES4rr5b4K/pgSqAzWNVRvhw3BFAAAAAAAAYtGqMwu1vXUEmlsOs7ZrEzxmG8ion3NOSvSDuaclekKAJ9xTkr0g7inJXpCgCfcU5K9IO4pyV6QoAl3FOSvQ93pyR0VAT7inJHQ7inJHRQBP3enJHQ93pyR0UAS93pyR0UiGQBWldGKVbooAAAAAAAAAAADW1dUpjRdiY1BEb2o0VAAAAAAAAAG1aSDWIUrTRtFdGUUAAAAAAAAAAAAAAAAYmsSyAnOHwazSVgEBdjQERbRkEYrPBtGHxUAYisQyAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAP/9k="
                                  }
                                  alt={winner.name}
                                />
                                <div className="winner-info">
                                  <img
                                    className="small-image"
                                    src={confetti}
                                    alt="popup"
                                  />
                                  <b className="winner-name ">{winner.name}</b>
                                  <img
                                    className="small-image1"
                                    src={confetti}
                                    alt="popup"
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="scroll-dots">{getDots()}</div>
                        </div>
                      )}
                      <div
                        style={{
                          padding: "10px",
                        }}
                      >
                        <div style={{ fontWeight: 600, fontSize: "22px" }}>
                          Are you looking to repair any of these appliances?
                        </div>
                        <div
                          className="pt-3"
                          style={{ color: "rgba(106, 112, 124, 1)" }}
                        >
                          We Service a Wide Range of Appliances! Our expert
                          technicians are ready to assist you with repairs and
                          maintenance for a variety of electronic appliances.
                          Here are some of the appliances we cover.
                        </div>
                        <div className="appliances-container">
                          {applianceData?.slice(0, 4)?.map((item) => {
                            return (
                              <div
                                onClick={() => handleApplianceClick(item?.id)}
                                style={{
                                  cursor: "pointer",
                                }}
                                key={item?.id}
                                className="appliance-item-dashboard"
                              >
                                <img
                                  src={item?.appliance_image}
                                  alt={item?.appliance_name}
                                />
                                <div
                                  style={{
                                    color: "rgba(235, 129, 40, 1)",
                                    paddingTop: "10px",
                                    fontWeight: 600,
                                  }}
                                >
                                  {item?.appliance_name}
                                </div>
                              </div>
                            );
                          })}

                          {appliancesData?.length > 4 && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                className="appliance-item show-more "
                                onClick={() => navigate("/cust-appliances")}
                                style={{
                                  background: "rgba(255, 249, 242, 1)",
                                  height: "100px",
                                  width: "100px",
                                  fontSize: "26px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {appliancesData?.length - 4}
                                  {appliancesData?.length - 4 >= 4 && (
                                    <span style={{ marginLeft: "4px" }}>+</span>
                                  )}
                                </div>
                              </div>

                              <div
                                style={{ fontSize: "14px", marginTop: "10px" }}
                              >
                                Other Appliances
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className="mt-3 mb-5"
                        style={{
                          padding: "10px",
                        }}
                      >
                        <div
                          className="mb-3"
                          style={{
                            fontSize: "20px",
                            fontWeight: 600,
                          }}
                        >
                          Track your Service Request
                        </div>
                        <div className="dashboard-sr-container">
                          {customerDashboardData?.length > 0 ? (
                            customerDashboardData?.map((progress, index) => {
                              const progressPercentage = () => {
                                if (
                                  progress?.status === "accepted" &&
                                  progress?.sr_work_history[0]
                                    ?.payment_status === null
                                ) {
                                  return "50";
                                } else if (
                                  progress.status === "accepted" &&
                                  progress.sr_work_history[0].payment_status ===
                                  "initiated"
                                ) {
                                  return "50";
                                } else if (
                                  progress.status === "accepted" &&
                                  progress.sr_work_history[0].payment_status ===
                                  "completed"
                                ) {
                                  return "80";
                                } else if (progress.status === "closed") {
                                  return "100";
                                } else if (progress.status === "cancelled") {
                                  return "0";
                                } else {
                                  return "30";
                                }
                              };
                              if (index === 0 || index === 1 || index === 2) {
                                return (
                                  <div
                                    className={`dashboard-sr d-flex mb-2 p-3 boder-rgb(235, 129, 40)`}
                                    style={{
                                      cursor: "pointer",
                                      borderRadius: "10px",
                                      position: "relative",
                                    }}
                                    onClick={() => handleSRDetail(progress)}
                                  >
                                    <div
                                      style={{
                                        position: "relative",
                                        right: "9px",
                                        display: "none",
                                      }}
                                    >
                                      <RectIcon />
                                    </div>
                                    <div
                                      style={{
                                        width: "100%",
                                        marginLeft: "5px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div
                                          className="d-flex"
                                          style={{ fontWeight: "600" }}
                                        >
                                          <div>SR #: </div>
                                          <div
                                            className="d-flex"
                                            style={{ marginLeft: "3px" }}
                                          >
                                            <div
                                              style={{ marginTop: "-1px" }}
                                            ></div>
                                            <div>{progress?.id?.slice(-6)}</div>
                                          </div>
                                        </div>
                                        <img
                                          src={rectangle}
                                          alt="rectangle"
                                          className="cust-rectangle-sr"
                                        />
                                        <div className="">
                                          <ProgressBar
                                            percentage={progressPercentage()}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className="d-flex"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>
                                          {/* <div className="font-12">Technician</div> */}
                                          {progressPercentage() === "30" ||
                                            progressPercentage() === "0" ? (
                                            <div style={{ fontSize: "13px" }}>
                                              <div>
                                                {progress?.appliance_name}
                                              </div>
                                              <div>
                                                {progress?.appliance_model},{" "}
                                                {progress?.appliance_size}
                                              </div>
                                            </div>
                                          ) : (
                                            <>
                                              <div className="d-flex flex-column text-center">
                                                <Avatar
                                                  {...stringAvatar(
                                                    progress.sr_work_history[0]
                                                      .technician_name
                                                  )}
                                                />
                                                <span
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  {
                                                    progress.sr_work_history[0]
                                                      ?.technician_name
                                                  }
                                                </span>
                                              </div>
                                            </>
                                          )}
                                          <div
                                            className=""
                                            style={{
                                              fontWeight: "500",
                                              fontSize: "13px",
                                            }}
                                          >
                                            {progress.fullname}
                                          </div>
                                        </div>
                                        <div>
                                          <div className="font-12">
                                            Created Date
                                          </div>
                                          <div
                                            className="d-flex gap-1"
                                            style={{
                                              fontWeight: "500",
                                              fontSize: "12px",
                                              color: "#606060",
                                            }}
                                          >
                                            <div>
                                              <CalendarIcon />
                                            </div>
                                            <div
                                              style={{
                                                paddingTop: "2px",
                                              }}
                                            >
                                              {moment(
                                                progress?.created_at
                                              ).format("DD MMM YYYY hh:mm a")}
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            position: "relative",
                                            top: "15px",
                                          }}
                                        >
                                          <ArrowIconHighlighted />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            })
                          ) : (
                            <p className="text-center">No Data found!</p>
                          )}
                        </div>
                      </div>
                      <div className="more-features-container-dash">
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            width: "33.3%",
                          }}
                          className="res-576px-mt-20px res-576px-w-fill res-576px-flex-direction-column res-576px-text-align-center res-576px-gap-4"
                        >
                          <div className="res-576px-d-flex res-576px-w-fill res-576px-justify-content-center res-576px-align-items-center">
                            <EndToEndIcon
                              style={{
                                position: "relative",
                                top: "-12px",
                                scale: "0.8",
                              }}
                              className="res-576px-scale-1-h"
                            />
                          </div>
                          <div>
                            <div
                              style={{
                                fontWeight: 600,
                                fontSize: "16px",
                                color: "white",
                              }}
                            >
                              End-to-end support
                            </div>
                            <div
                              className="mt-2"
                              style={{
                                color: "rgba(255, 255, 255, 0.7)",
                                fontSize: "12px",
                              }}
                            >
                              Rely on us for any home, shop, or office
                              appliance, our technicians will help with repair,
                              installation, and uninstallation.
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            width: "33.3%",
                          }}
                          className=" res-576px-mt-20px res-576px-w-fill res-576px-flex-direction-column res-576px-text-align-center res-576px-gap-4"
                        >
                          <div className="res-576px-d-flex res-576px-justify-content-center res-576px-align-items-center">
                            <OpenDocIcon
                              style={{
                                position: "relative",
                                top: "-14px",
                                scale: "0.7",
                              }}
                              className="res-576px-scale-1-4"
                            />
                          </div>
                          <div>
                            <div
                              style={{
                                fontWeight: 600,
                                fontSize: "16px",
                                color: "white",
                              }}
                            >
                              Open and Transparent
                            </div>
                            <div
                              className="mt-2"
                              style={{
                                color: "rgba(255, 255, 255, 0.7)",
                                fontSize: "12px",
                              }}
                            >
                              That is our policy. Whether it is service reviews,
                              service rates, or assigned technicians, we display
                              it all fair and square.
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            width: "33.3%",
                          }}
                          className="res-576px-mt-20px res-576px-w-fill res-576px-flex-direction-column res-576px-text-align-center res-576px-gap-6"
                        >
                          <div className="res-576px-d-flex res-576px-justify-content-center res-576px-align-items-center">
                            <UnifiedIcon
                              className="res-576px-scale-2"
                              style={{
                                position: "relative",
                                top: "-2px",
                                scale: "1.3",
                              }}
                            />
                          </div>
                          <div>
                            <div
                              style={{
                                fontWeight: 600,
                                fontSize: "16px",
                                color: "white",
                              }}
                            >
                              Unified Thinking
                            </div>
                            <div
                              className="mt-2"
                              style={{
                                color: "rgba(255, 255, 255, 0.7)",
                                fontSize: "12px",
                              }}
                            >
                              We are a crowdsourced group of Technicians, led by
                              Technicians!
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="p-3 res-576-text-align-center"
                  style={{
                    backgroundColor: "rgb(255 229 204 / 15%)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: "13px",
                    }}
                    className="res-576px-d-unset"
                  >
                    <div>All Rights Reserved @ S3</div>
                    <div
                      style={{ display: "flex", gap: "60px", fontSize: "13px" }}
                      className="res-576px-d-unset"
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate("/terms-of-use")}
                        className="cust-dash-hover res-576-mt-1"
                      >
                        Terms of Use
                      </div>
                      <div
                        className="cust-dash-hover res-576-mt-1"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate("/privacy-policy")}
                      >
                        Privacy Policy
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div value={value} index={1}>
                  <div style={{ overflow: "hidden" }}>
                    <div className="row-lg" style={{ display: "flex" }}>
                      <div className="col w-100">
                        {CustomerDashboardList.length > 0 ? (
                          CustomerDashboardList.map((progress, index) => {
                            const progressPercentage = () => {
                              if (
                                progress.status === "accepted" &&
                                progress.sr_work_history[0].payment_status ===
                                  null
                              ) {
                                return "50";
                              } else if (
                                progress.status === "accepted" &&
                                progress.sr_work_history[0].payment_status ===
                                  "initiated"
                              ) {
                                return "50";
                              } else if (
                                progress.status === "accepted" &&
                                progress.sr_work_history[0].payment_status ===
                                  "completed"
                              ) {
                                return "80";
                              } else if (progress.status === "closed") {
                                return "100";
                              } else if (progress.status === "cancelled") {
                                return "0";
                              } else {
                                return "0";
                              }
                            };
                            return (
                              <div
                                className={`d-flex border mb-2 w-100 p-2 ${
                                  hovered === index && "boder-rgb(235, 129, 40)"
                                }`}
                                style={{
                                  background:
                                    hovered === index
                                      ? "linear-gradient(to right,#FFE1CA , #FFF6EF00)"
                                      : undefined,
                                  cursor: "pointer",
                                  borderRadius: "10px",
                                }}
                                // onClick={() =>
                                //   handleInProgressSRById(
                                //     progress.id,
                                //     index
                                //   )
                                // }
                              >
                                <div
                                  style={{
                                    position: "relative",
                                    right: "9px",
                                    display: hovered === index ? "" : "none",
                                  }}
                                >
                                  <RectIcon />
                                </div>
                                <div
                                  style={{
                                    width: "100%",
                                    marginLeft: hovered !== index && "5px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div
                                      className="d-flex"
                                      style={{
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        color: "#1E232C",
                                      }}
                                    >
                                      <div>SR #: </div>
                                      <div
                                        className="d-flex"
                                        style={{ marginLeft: "3px" }}
                                      >
                                        <div
                                          style={{
                                            marginTop: "-1px",
                                          }}
                                        >
                                          ⁎⁎⁎
                                        </div>
                                        <div>
                                          {
                                            progress?.id?.split("-")[
                                              progress?.id?.split("-")?.length -
                                                1
                                            ]
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    <div className="">
                                      <ProgressBar
                                        percentage={progressPercentage()}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="d-flex"
                                    style={{
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>
                                      {progress.sr_work_history.map((e) => {
                                        return (
                                          <div className="d-flex flex-column text-center">
                                            <Avatar
                                              {...stringAvatar(
                                                e.technician_name
                                              )}
                                            />
                                            <span
                                              style={{
                                                fontWeight: "500",
                                                fontSize: "13px",
                                              }}
                                            >
                                              {e.technician_name}
                                            </span>
                                          </div>
                                        );
                                      })}
                                      <div
                                        className=""
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "13px",
                                        }}
                                      >
                                        {progress.fullname}
                                      </div>
                                    </div>
                                    <div>
                                      <div className="font-12 mt-3">
                                        Created date
                                      </div>
                                      <div
                                        className="d-flex gap-1"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "12px",
                                          fontWeight: "600",
                                          color: "#606060",
                                        }}
                                      >
                                        <div>
                                          <CalendarIcon />
                                        </div>
                                        <div
                                          style={{
                                            paddingTop: "2px",
                                          }}
                                        >
                                          {moment(progress?.created_at).format(
                                            "MMM DD, YYYY"
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        position: "relative",
                                        top: "15px",
                                      }}
                                    >
                                      {hovered === index ? (
                                        <ArrowIconHighlighted />
                                      ) : (
                                        <ArrowIconDefault />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <p className="text-center">No Data found!</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className="container-fluid repair_bg">
                  <div className="row look">
                    <div className="col text-center">
                   
                      <p
                        className=" m-0 mt-2 electronic_text res-font-high"
                        style={{
                          fontSize: "40px",
                          fontWeight: "700px",
                          color: "#2E2E2E",
                          paddingTop: 20,
                        }}
                      >
                        Get Your Electronic Appliances
                        <br /> Repaired Just In 3 Step!
                      </p>
                    </div>
                  </div>
                  <div className="row srcard">
                    <div className="col-lg-6 col-md-6 col-12 mt-3 d-flex justify-content-center">
                      <Paper
                        elevation={2}
                        style={{
                          width: "330px",
                          height: "320px",
                          borderRadius: "13px",
                        }}
                      >
                        <div style={{ textAlign: "center" }}>
                          <img alt="demo" className="mt-5" src={Suit}></img>
                          <p className="cardtext mt-3">Register With Us</p>
                          <text className="mt-0" style={{ fontSize: "12px" }}>
                            Unlock the Future of Appliance Care:
                            <br></br>Join Us Today and Elevate Your Electronics
                            Repairing Experience!
                          </text>
                        </div>
                      </Paper>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 bluebck mt-3 d-flex justify-content-center">
                      <Paper
                        elevation={3}
                        style={{
                          width: "330px",
                          height: "320px",
                          borderRadius: "13px",
                        }}
                      >
                        <div style={{ textAlign: "center" }}>
                          <img alt="demo" className="mt-5" src={Book}></img>
                          <p className="cardtext mt-3">Book Your Complaint</p>
                          <text className="mt-0" style={{ fontSize: "12px" }}>
                            Seamlessly create your service requests through our
                            innovative mobile application.
                          </text>
                        </div>
                      </Paper>
                    </div>
                    <div className="col-lg-6 offset-lg-3 col-md-6 offset-md-3 col-12 mt-3 d-flex justify-content-center">
                      <Paper
                        elevation={3}
                        style={{
                          width: "330px",
                          height: "320px",
                          borderRadius: "13px",
                        }}
                      >
                        <div style={{ textAlign: "center" }}>
                          <img alt="demo" className="mt-5" src={Crown}></img>
                          <p className="cardtext mt-3">Sit Back and Relax!</p>
                          <text className="mt-0" style={{ fontSize: "12px" }}>
                            With our team of expert Technicians and exceptional
                            mobile application, sit back and relax while we
                            restore your appliances to perfection.
                          </text>
                        </div>
                      </Paper>
                    </div>
                  </div>
                </div> */}
                {/* <div className="row p-lg-5 p-2">
                  <div className="col-lg-6 col-12 col-md-12  remove-img-res">
                    <img alt="demo" className="w-100" src={Logo} />
                  </div>
                  <div className="col-lg-6 col-12 col-md-12 explore-container-res">
                    <p
                      className="m-0 mt-2 mt-lg-5 look"
                      style={{
                        fontSize: "18px",
                        fontWeight: "500",
                        color: "#EB8128",
                      }}
                    >
                      Why S3?
                    </p>
                    <p
                      className="m-0 mt-2 electronic_text res-font-high"
                      style={{
                        fontSize: "40px",
                        fontWeight: "700",
                        color: "#2E2E2E",
                      }}
                    >
                      Your Electronics Appliances.
                      <br />
                      In Safe Hands.{" "}
                    </p>
                    <p
                      className="m-0 mt-2"
                      style={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#676767",
                      }}
                    >
                      Our app is a first-of-its-kind in India, developed by the
                      Electronics Technicians Association to serve the community
                      by offering trustworthy and value-added services. It gives
                      a peace of mind to the consumers that their devices are at
                      the safe hands.
                    </p>
                  
                  </div>
                </div> */}
                {/* <div className="row m-0 p-lg-5 p-2 download_bg col-md-12">
                  <div className="col-lg-6 col-12 text-center round">
                    <img
                      alt="demo"
                      className="mobile-app-section"
                      src={Mobile}
                    />
                  </div>
                  <div className="col-lg-6 col-12">
                    <p
                      className="m-0 mt-2 electronic_text"
                      style={{
                        fontSize: "40px",
                        fontWeight: "700px",
                        color: "#2E2E2E",
                      }}
                    >
                      Your S3 App is Coming Soon!
                    </p>
                    <ul className="p-0 mt-3 play">
                      <li className="mt-2" style={{ listStyle: "none" }}>
                        <img
                          alt="demo"
                          src={Play}
                          style={{
                            width: "230px",
                            height: "76px",
                            marginLeft: 3,
                          }}
                        />
                      </li>

                      <li style={{ listStyle: "none" }}>
                        <img
                          alt="demo"
                          src={App}
                          style={{
                            width: "230px",
                            height: "76px",
                            marginTop: 10,
                            marginLeft: 3,
                          }}
                        />
                      </li>
                    </ul>
                  </div>
                  <div
                    class="modal fade"
                    id="staticBackdrop"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content w-75 ms-lg-5 mb-lg-5 ">
                        <div className="row">
                          <div class="modal-body p-lg-4">
                            <div className="row">
                              <div className="col-10">
                                <img
                                  alt=""
                                  src={Logoin}
                                  className="ms-2"
                                  style={{ width: "88%" }}
                                />
                              </div>
                              <div className="col-2">
                                <button
                                  type="button"
                                  class="btn-close mt-3"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                            </div>
                            <div className="row mt-lg-4">
                              <div
                                className="mt-lg-3"
                                style={{
                                  fontSize: "15px",
                                  textAlign: "center",
                                }}
                              >
                                <text>For more details Please</text>
                                <a className="ms-2" href="/login">
                                  Login here!
                                </a>
                                <br />
                                <br />
                                <text>New User for S3 Please</text>
                                <a className="ms-2" href="/commonRegister">
                                  Signup here!
                                </a>
                              </div>
                            </div>
                            <div className="row">
                              <div
                                className="mt-3"
                                style={{
                                  display: "flex",
                                  fontSize: "19px",
                                  justifyContent: "center",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            {/* <div
              className="col-lg-12 user_management res-font"
              style={{ marginTop: 10 }}
            >
              <HomeFooter />
            </div> */}
          </>
        </div>
      </div>

      <div
        className="offcanvas offcanvas-start w-100"
        tabindex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          {/* <h5 className="offcanvas-title" id="offcanvasExampleLabel">Offcanvas</h5> */}
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body ">
          <div className="w-100 text-start h-auto d-inline-block ">
            <div
              className="nav flex-column nav-pills "
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <p
                className="mt-2 ms-lg-3"
                style={{
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "#1E232C",
                }}
              >
                Menu
              </p>
              <button
                className="nav-link active fw-bold"
                id="v-pills-existing-clients-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-existing"
                type="button"
                role="tab"
                aria-controls="v-pills-existing"
                aria-selected="true"
              >
                <i className="bi bi-box me-3"></i>Dashboard
              </button>
              <button
                className="nav-link fw-bold"
                id="v-pills-onboarding"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-onboard"
                type="button"
                role="tab"
                aria-controls="v-pills-onboard"
                aria-selected="false"
              >
                <i className="bi bi-envelope me-3"></i>Service Request
              </button>
              <button
                className="nav-link fw-bold"
                id="v-pills-billing"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-bill"
                type="button"
                role="tab"
                aria-controls="v-pills-bill"
                aria-selected="false"
              >
                <i className="bi bi-wallet me-3"></i>Payments
              </button>
              {/* <button className="nav-link fw-bold" id="v-pills-preferred-setting" data-bs-toggle="pill" data-bs-target="#v-pills-setting" type="button" role="tab" aria-controls="v-pills-setting" aria-selected="false"><i className="bi bi-gear me-3"></i>Settings</button> */}

              <button
                className="nav-link fw-bold"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <i className="bi bi-gear me-3"></i>Settings
              </button>

              <div className="collapse" id="collapseExample">
                <button
                  className="nav-link fw-bold"
                  id="v-pills-preferred-resetpassword"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-resetpassword"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-resetpassword"
                  aria-selected="false"
                >
                  Reset password
                </button>
              </div>
              <Stack
                className=" ms-xxl-5 mt-3"
                spacing={2}
                direction="row"
                alignItems="flex-start"
              >
                <Badge className="mt-2" color="secondary" variant="dot">
                  <NotificationsNoneIcon />
                </Badge>
                <Link to="/">
                  <Avatar alt="Remy Sharp" src={profile} />
                </Link>
                <FormControl
                  className="mt-2"
                  sx={{ m: 1, minWidth: 160, border: "none" }}
                >
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    label="Age"
                    variant="standard"
                    defaultValue="10"
                    InputProps={{
                      disableUnderline: true,
                    }}
                  >
                    <MenuItem value="10">testsedfghhjhj</MenuItem>
                    <MenuItem value={20}>Reset Password</MenuItem>
                    <MenuItem value={30}>Forgot Password</MenuItem>
                    <MenuItem value={40}>Log out</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CustomerDashBoard;
