// redux/slices/expandSlice.js
import { createSlice } from '@reduxjs/toolkit';

const expandSlice = createSlice({
    name: 'expand',
    initialState: {
        isExpanded: false,
    },
    reducers: {
        toggleExpand(state) {
            state.isExpanded = !state.isExpanded;
        },
        setExpand(state, action) {
            state.isExpanded = action.payload;
        },
    },
});

export const { toggleExpand, setExpand } = expandSlice.actions;
export default expandSlice.reducer;
