import axios from "axios";
import { BASE_URL } from "../../api/ApiPath";
import {
  revenueConfigFail,
  revenueConfigRequest,
  revenueConfigSuccess,
} from "../slices/revenueConfigSlice";
const handleUnAuthorizedError = (err) => {
  if (err.response.status === 401) {
    console.log("working");
    localStorage.clear();
    window.location.href = "/";
    window.location.reload();
  }
};
export const getRevenueConfig = (payload) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch(revenueConfigRequest());
    const response = await axios.get(`${BASE_URL}/revenue/config`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch(revenueConfigSuccess(response?.data));
  } catch (error) {
    handleUnAuthorizedError(error);
    dispatch(revenueConfigFail(error));
  }
};
