import React, { useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const ProgressBar = (props) => {
  const { percentage } = props;
  // The progress percentage;

  const progressbarStyles = buildStyles({
    // Customize the width of the progress bar
    pathTransitionDuration: 0.5, // Animation duration in seconds
    pathColor: `#EB8128`, // Progress bar color based on percentage
    trailColor: "#d6d6d6", // Color of the trail behind the progress bar
    textSize: "25px", // Size of the progress percentage text
    textColor: "#000000", // Color of the progress percentage text
    trailWidth: 10, // Width of the trail
    pathWidth: 10, // Width of the progress bar,
    textWeight: 600,
  });

  return (
    <div style={{ width: 70, height: 70, position: "relative" }}>
      <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        styles={progressbarStyles}
      />
    </div>
  );
};

export default ProgressBar;
