import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Topbar from "../../topbar";
import Sidebar from "../../Sidebar";
import AllAdmins from "./AllAdmins";
import AllCustomers from "./AllCustomers";
import AllTechnicians from "./AllTechnicians";
// import Api from "../../../service/services";
import { AppBar, Tabs, Tab, Box, useForkRef } from "@mui/material";
import CountContext from "../../countContext/CountContext";

const UserManagement = ({}) => {
  /*   const {
    customerCountContext,
    setCustomerCountContext,
    adminCountContext,
    setAdminCountContext,
    techCountContext,
    setTechCountContext,
  } = useContext(CountContext); */
  const { setSearch, search } = useContext(CountContext);
  // const [customerCount, setCustomerCount] = useState(0);
  // const [adminCount, setAdminCount] = useState(0);
  // const [techCount, setTechCount] = useState(0);
  // const [updateCount, setUpdateCount] = useState(false);

  const role = localStorage.getItem("role");

  // const getAllCustomer = () => {
  //   if (role == "DistrictAdmin") {
  //     Api.DistrictUserManagementAllCust().then((result) => {
  //       const customersCount =
  //         result?.userCount?.find((user) => user?.role === "1")?.count || 0;
  //       const techniciansCount =
  //         result?.userCount?.find((user) => user?.role === "2")?.count || 0;
  //       const superAdminsCount =
  //         result?.userCount?.find((user) => user?.role === "3")?.count || 0;
  //       const districtAdminsCount =
  //         result?.userCount?.find((user) => user?.role === "4")?.count || 0;
  //       const zonalAdminsCount =
  //         result?.userCount?.find((user) => user?.role === "5")?.count || 0;
  //       const totalTechniciansCount =
  //         techniciansCount +
  //         superAdminsCount +
  //         districtAdminsCount +
  //         zonalAdminsCount;
  //       setCustomerCount(customersCount);
  //       setTechCount(totalTechniciansCount);
  //     });
  //   }
  //   if (role == "ZonalAdmin") {
  //     Api.ZonalUserManagementAllCust().then((result) => {
  //       const customersCount =
  //         result?.userCount?.find((user) => user?.role === "1")?.count || 0;
  //       const techniciansCount =
  //         result?.userCount?.find((user) => user?.role === "2")?.count || 0;
  //       const superAdminsCount =
  //         result?.userCount?.find((user) => user?.role === "3")?.count || 0;
  //       const districtAdminsCount =
  //         result?.userCount?.find((user) => user?.role === "4")?.count || 0;
  //       const zonalAdminsCount =
  //         result?.userCount?.find((user) => user?.role === "5")?.count || 0;
  //       const totalTechniciansCount =
  //         techniciansCount +
  //         superAdminsCount +
  //         districtAdminsCount +
  //         zonalAdminsCount;
  //       const totalAdminsCount =
  //         superAdminsCount + districtAdminsCount + zonalAdminsCount;
  //       setCustomerCount(customersCount);
  //       setTechCount(totalTechniciansCount);
  //       setAdminCount(totalAdminsCount);
  //     });
  //   }
  //   if (role == "SuperAdmin") {
  //     Api.SuperAdminUsermanagementGetAllCust().then((result) => {
  //       const customersCount =
  //         result?.userCount?.find((user) => user?.role === "1")?.count || 0;
  //       const techniciansCount =
  //         result?.userCount?.find((user) => user?.role === "2")?.count || 0;
  //       const superAdminsCount =
  //         result?.userCount?.find((user) => user?.role === "3")?.count || 0;
  //       const districtAdminsCount =
  //         result?.userCount?.find((user) => user?.role === "4")?.count || 0;
  //       const zonalAdminsCount =
  //         result?.userCount?.find((user) => user?.role === "5")?.count || 0;
  //       const totalTechniciansCount =
  //         techniciansCount +
  //         superAdminsCount +
  //         districtAdminsCount +
  //         zonalAdminsCount;
  //       const totalAdminsCount =
  //         superAdminsCount + districtAdminsCount + zonalAdminsCount;
  //       setCustomerCount(customersCount);
  //       setAdminCount(totalAdminsCount);
  //       setTechCount(totalTechniciansCount);
  //       setCustomerCount(customersCount);
  //       setTechCount(totalTechniciansCount);
  //       setAdminCount(totalAdminsCount);
  //     });
  //   }
  // };

  // useEffect(() => {
  //   getAllCustomer();
  // }, []);

  const [selectedTab1, setSelectedTab1] = React.useState(0);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setSearch("");
    setSelectedTab1(newValue);
  };
  const technicianCount = 0;
  const TabPanel = ({ value, index, children }) => {
    return (
      <div role="tabpanel" hidden={value !== index}>
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  };

  // const handleUpdateCount = (e) => {
  //   setUpdateCount(e);
  // };

  // useEffect(() => {
  //   if (updateCount === true) {
  //     setTimeout(() => {
  //       setUpdateCount(false);
  //     }, 2000);
  //   }
  // }, [updateCount]);

  // useEffect(() => { }, [updateCount]);

  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <Topbar />
        <div
          className="row container-fluid"
          style={{ overflow: "hidden", paddingRight: "0px" }}
        >
          <div className="col-2 col-12 p-0 border border-right">
            <Sidebar />
          </div>
          <div
            className="col-12 user_management"
            style={{ paddingRight: "0px", marginTop: "80px" }}
          >
            <div className="mt-lg-2">
              <i
                className="bi bi-chevron-left "
                style={{
                  fontWeight: "600",
                  fontSize: "21px",
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                }}
                onClick={() => navigate(-1)}
              ></i>
              <text
                className="p-0"
                style={{
                  fontWeight: "600",
                  color: "#1E232C",
                  fontSize: "21px",
                  width: "208px",
                  height: "32px",
                }}
              >
                User Management
              </text>
            </div>
            <div className="container-fluid" style={{ overflow: "hidden" }}>
              <div className="row">
                <div className="col-lg-12 p-0 mt-1">
                  <div className="col-lg-4 ms-1">
                    <AppBar
                      style={{ boxShadow: "none" }}
                      position="static"
                      className="w-100 bg-white"
                      aria-label="basic tabs example"
                    >
                      <Tabs
                        value={selectedTab1}
                        onChange={handleChange}
                        variant="fullWidth"
                        // className="scroll-tabs"
                      >
                        <Tab
                          style={{
                            backgroundColor: "white",
                            textTransform: "none",
                          }}
                          label={`Technicians `}
                        />
                        <Tab
                          style={{
                            backgroundColor: "white",
                            textTransform: "none",
                            /*      textTransform: "unset", */
                          }}
                          label={`Customers `}
                        />
                        {role === "SuperAdmin" || role === "ZonalAdmin" ? (
                          <Tab
                            style={{
                              backgroundColor: "white",
                              textTransform: "none",
                              /*     textTransform: "unset", */
                            }}
                            label={`Admins`}
                          />
                        ) : (
                          <></>
                        )}
                      </Tabs>
                    </AppBar>
                  </div>
                  <TabPanel value={selectedTab1} index={0}>
                    <AllTechnicians />
                  </TabPanel>
                  <TabPanel value={selectedTab1} index={1}>
                    <AllCustomers />
                  </TabPanel>
                  <TabPanel value={selectedTab1} index={2}>
                    <AllAdmins />
                  </TabPanel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserManagement;
