import { createSlice } from "@reduxjs/toolkit";


const notificationsCountSlice = createSlice({
    name: 'notificationsCount',
    initialState: {
        loading: false,
    },
    reducers: {
        notificationsCountRequest(state, action) {
            return {
                ...state,
                loading: true
            }
        },
        notificationsCountSuccess(state, action) {
            return {
                ...state,
                loading: true,
                notificationsCount: action.payload,
            }
        },
        notificationsCountFail(state, action) {
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        },
        notificationsCountErrorClear(state, action) {
            return {
                ...state,
                loading: false,
                error:false,
            }
        }
    }
});

const { actions, reducer } = notificationsCountSlice;

export const { notificationsCountRequest, notificationsCountFail, notificationsCountSuccess, notificationsCountClear } = actions

export default reducer;