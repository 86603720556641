import axios from "axios";
import { BASE_URL } from "../../api/ApiPath";
import {
  notificationsFail,
  notificationsRequest,
  notificationsSuccess,
} from "../slices/notificationsSlice";
import {
  readNotificationFail,
  readNotificationRequest,
  readNotificationSuccess,
} from "../slices/readNotificationSlice";
import {
  readAllNotificationsFail,
  readAllNotificationsRequest,
  readAllNotificationsSuccess,
} from "../slices/readAllNotificationsSlice";
import {
  notificationsCountFail,
  notificationsCountRequest,
  notificationsCountSuccess,
} from "../slices/notificationsCountSlice";
const handleUnAuthorizedError = (err) => {
  if (err.response.status === 401) {
    console.log("yes it is 401");
    // localStorage.clear();
    // window.location.href = "/";
    // window.location.reload();
  }
};
export const getNotifications = () => async (dispatch) => {
  const token = localStorage.getItem("token");

  try {
    dispatch(notificationsRequest());
    const response = await axios.get(`${BASE_URL}/notification`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch(notificationsSuccess(response?.data?.data));
  } catch (error) {
    handleUnAuthorizedError(error);
    dispatch(notificationsFail(error));
  }
};

export const readNotification = (notificationId) => async (dispatch) => {
  const token = localStorage.getItem("token");

  try {
    dispatch(readNotificationRequest());
    const response = await axios.patch(
      `${BASE_URL}/notification/${notificationId}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    dispatch(readNotificationSuccess(response?.data?.data));
  } catch (error) {
    handleUnAuthorizedError(error);
    dispatch(readNotificationFail(error));
  }
};

export const getNotificationsCount = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch(notificationsCountRequest());
    const response = await axios.get(`${BASE_URL}/notification/count`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch(notificationsCountSuccess(response?.data?.count));
  } catch (error) {
    handleUnAuthorizedError(error);
    dispatch(notificationsCountFail(error));
  }
};

export const readAllNotifications = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  const id = localStorage.getItem("id");
  try {
    dispatch(readAllNotificationsRequest());
    const response = await axios.patch(
      `${BASE_URL}/notification/clearall/${id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    dispatch(readAllNotificationsSuccess(response?.data?.data));
    dispatch(getNotifications());
    dispatch(getNotificationsCount());
  } catch (error) {
    handleUnAuthorizedError(error);
    dispatch(readAllNotificationsFail(error));
  }
};
