// import React from 'react';
// import ApexCharts from 'react-apexcharts';

// const AreaChart1 = () => {
//   const series = {
//     monthDataSeries1: {
//       prices: [150, 170, 180, 160, 190, 200, 210], // Example price data
//       dates: [
//         new Date("2023-08-01").getTime(),
//         new Date("2023-08-02").getTime(),
//         new Date("2023-08-03").getTime(),
//         new Date("2023-08-04").getTime(),
//         new Date("2023-08-05").getTime(),
//         new Date("2023-08-06").getTime(),
//         new Date("2023-08-07").getTime(),
//       ] // Example date data in timestamp format
//     }
//   };

//   const options = {
//     series: [{
//       name: "STOCK ABC",
//       data: series.monthDataSeries1.prices
//     }],
//     chart: {
//       type: 'area',
//       height:350,
//       zoom: {
//         enabled: false
//       }
//     },
//     dataLabels: {
//       enabled: false
//     },
//     stroke: {
//       curve: 'smooth'
//     },
//     // title: {
//     //   text: 'Fundamental Analysis of Stocks',
//     //   align: 'left'
//     // },
//     subtitle: {
//       text: 'Price Movements',
//       align: 'left'
//     },
//     labels: series.monthDataSeries1.dates,
//     xaxis: {
//       type: 'datetime',
//     },
//     yaxis: {
//       opposite: true
//     },
//     legend: {
//       horizontalAlign: 'left'
//     }
//   };
//   return (
//    <>
//      <div id="chart" >
//       <ApexCharts options={options} series={options.series} type="area" height={110} width={180} />
//     </div>
//    </>
//   );
// };

// export default AreaChart1;

import React from "react";

const AreaChart1 = () => {
return(
  <>
hi
  </>
)
}
export default AreaChart1
