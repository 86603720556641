import { createSlice } from "@reduxjs/toolkit";


const productSlice = createSlice({
    name: 'product',
    initialState: {
        loading: false,
    },
    reducers: {
        productCreateRequest(state, action) {
            return {
                ...state,
                loading: true
            }
        },
        productCreateSuccess(state, action) {
            return {
                ...state,
                loading: false,
                product: action.payload,
            }
        },
        productCreateFail(state, action) {
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        },
        productCreateErrorClear(state, action) {
            return {
                ...state,
                loading: false,
                error:false,
            }
        },
        productUpdateRequest(state, action) {
            return {
                ...state,
                loading: true
            }
        },
        productUpdateSuccess(state, action) {
            return {
                ...state,
                loading: false,
                product: action.payload,
            }
        },
        productUpdateFail(state, action) {
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        },
        productUpdateErrorClear(state, action) {
            return {
                ...state,
                loading: false,
                error:false,
            }
        }
    }
});

const { actions, reducer } = productSlice;

export const { productCreateRequest, productCreateFail, productCreateSuccess, productCreateErrorClear,productUpdateRequest, productUpdateFail, productUpdateSuccess, productUpdateErrorClear } = actions

export default reducer;