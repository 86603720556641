import React from "react";

export const EndToEndIcon = ({ style, className }) => {
  return (
    <svg
      style={style}
      className={className}
      width="56"
      height="56"
      viewBox="0 0 42 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7618 50.1181L3.73921 36.9392L0 39.8347L20.7734 55.9205L41.5468 39.8347L37.7845 36.9277L20.7618 50.1181Z"
        fill="#473BF0"
        fill-opacity="0.3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.7734 44.0504L37.7729 30.9441L41.5468 28.0252L20.7734 12L0 28.0252L3.76229 30.9212L20.7734 44.0504Z"
        fill="#EB8128"
      />
    </svg>
  );
};

export const UnifiedIcon = ({ style, className }) => {
  return (
    <svg
      style={style}
      className={className}
      width="33"
      height="33"
      viewBox="0 0 43 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M25.9901 33.5547H19.3427C18.884 33.5547 18.5117 33.9535 18.5117 34.445V50.4702C18.5117 50.9616 18.884 51.3604 19.3427 51.3604H25.9901C26.4488 51.3604 26.8211 50.9616 26.8211 50.4702V34.445C26.8211 33.9535 26.4488 33.5547 25.9901 33.5547Z"
        fill="#EB8128"
      />
      <path
        opacity="0.3"
        d="M9.371 43.0508H2.72351C2.26484 43.0508 1.89258 43.423 1.89258 43.8817V50.5292C1.89258 50.9879 2.26484 51.3601 2.72351 51.3601H9.371C9.82967 51.3601 10.2019 50.9879 10.2019 50.5292V43.8817C10.2019 43.423 9.82967 43.0508 9.371 43.0508Z"
        fill="#EB8128"
      />
      <path
        opacity="0.7"
        d="M41.4218 25.2441H34.7743C34.3156 25.2441 33.9434 25.6341 33.9434 26.1146V50.4887C33.9434 50.9693 34.3156 51.3593 34.7743 51.3593H41.4218C41.8805 51.3593 42.2527 50.9693 42.2527 50.4887V26.1146C42.2527 25.6341 41.8805 25.2441 41.4218 25.2441Z"
        fill="#EB8128"
      />
      <path
        d="M22.5456 24.6884L33.3686 13.9751V19.7148C33.3686 20.1958 33.763 20.5863 34.249 20.5863C34.735 20.5863 35.1294 20.1958 35.1294 19.7148V11.8713C35.1294 11.3902 34.735 10.9998 34.249 10.9998H26.3252C25.8392 10.9998 25.4448 11.3902 25.4448 11.8713C25.4448 12.3523 25.8392 12.7428 26.3252 12.7428H32.1236L21.9231 22.84L15.5022 16.4841C15.158 16.1434 14.6016 16.1434 14.2573 16.4841L5.45312 25.1991L6.69804 26.4314L14.8798 18.3326L21.3007 24.6884C21.6449 25.0292 22.2013 25.0292 22.5456 24.6884Z"
        fill="#EB8128"
      />
    </svg>
  );
};

export const OpenDocIcon = ({ style, className }) => {
  return (
    <svg
      style={style}
      className={className}
      width="60"
      height="60"
      viewBox="0 0 45 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.6092 35.9281C26.626 35.9281 31.2927 29.2282 31.2927 23.464V21.5465C31.2927 15.7315 26.4997 11 20.6092 11C14.7188 11 9.92578 15.7315 9.92578 21.5465V23.464C9.92578 29.2282 14.5925 35.9281 20.6092 35.9281Z"
        fill="#EB8128"
      />
      <path
        opacity="0.5"
        d="M44.3516 45.1116L43.7915 38.3027L41.7433 40.6576C40.3624 39.413 38.6165 38.7245 36.7522 38.7245C32.581 38.7245 29.1676 42.2194 28.9199 46.6121H30.7694C31.0217 43.3593 33.6559 40.7001 36.7522 40.7001C38.1395 40.7001 39.4391 41.2049 40.4798 42.1117L38.1719 44.7649L44.3516 45.1116Z"
        fill="#EB8128"
      />
      <path
        opacity="0.5"
        d="M42.503 47.7988C42.2507 51.0516 39.6165 53.7108 36.5202 53.7108C35.1329 53.7108 33.8334 53.206 32.7926 52.2992L35.1005 49.646L28.9199 49.2993L29.48 56.1082L31.5282 53.7533C32.9091 54.9979 34.655 55.6864 36.5193 55.6864C40.6905 55.6864 44.1039 52.1916 44.3516 47.7988H42.503Z"
        fill="#EB8128"
      />
      <path
        d="M26.5975 48.035C26.5975 44.9668 27.9563 42.2166 30.106 40.305C27.433 39.8368 24.2909 39.4883 20.7824 39.4883C14.4314 39.4883 9.22499 40.6165 5.97533 41.5632C2.6588 42.53 0.429688 45.4577 0.429688 48.8479V52.7832C0.429688 53.3074 0.862911 53.7329 1.39887 53.7329H26.5975V48.035Z"
        fill="#EB8128"
      />
    </svg>
  );
};
