import { createSlice } from "@reduxjs/toolkit";

const technicianDashboardSlice = createSlice({
  name: "technicianDashboard",
  initialState: {
    loading: false,
  },
  reducers: {
    technicianDashboardRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    technicianDashboardSuccess(state, action) {
      return {
        ...state,
        loading: false,
        technicianDashboardData: action.payload,
      };
    },
    technicianDashboardFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
  },
});

const { actions, reducer } = technicianDashboardSlice;

export const {
  technicianDashboardRequest,
  technicianDashboardFail,
  technicianDashboardSuccess,
} = actions;

export default reducer;
