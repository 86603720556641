import React, { useEffect, useState } from "react";
import S3NewLogo from "../../Assets/s3_new_logo/S3NewLogo";
import S3NewLogo_mob from "../../Assets/Home/s3_new_logo_mobile.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Navbar.scss";
import { IoMenu } from "react-icons/io5";
import { MdClose } from "react-icons/md";
const Navbar = ({ innerWidth }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isSticky, setIsSticky] = useState(false);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const fixNavRoutes = [
    "/about",
    "/Contact-us",
    "/services",
    "/tv-service",
    "/refrigerator-service",
    "/washing-machine-service",
    "/dth-service",
    "/terms-of-use",
    "/privacy-policy",
  ];
  const scrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  const isCurrentLocation = (location) => pathname === location;

  const isFixedNavRoute = () => fixNavRoutes.includes(pathname);
  const onNavbarClick = () => {
    const body = document.querySelector("body");
    body.classList.add("res-nav-body-constructor");
    setIsNavbarOpen(!isNavbarOpen);
  };
  const onCloseNavbar = () => {
    const body = document.querySelector("body");
    body.classList.remove("res-nav-body-constructor");
    setIsNavbarOpen(!isNavbarOpen);
  };
  console.log("innerWidth: ", innerWidth);
  useEffect(() => {
    if (innerWidth <= 576) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  }, [innerWidth]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth <= 576) {
        setIsSticky(true);
      } else {
        if (window.scrollY >= 100) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    const updateWindowDimensions = () => {
      if (window.innerWidth <= 576) {
        setIsSticky(true);
      }
    };
    window.addEventListener("resize", updateWindowDimensions);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  return (
    <div className="navbar-con">
      <div
        className={`new-navbar-flex font-family-poppins ${isSticky || isFixedNavRoute() ? "sticked" : "sticky-removed"
          }`}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{ cursor: "pointer" }}
            className="res-576px-d-none "
            onClick={() => {
              scrollToTop();
              navigate("/");
            }}
          >
            <S3NewLogo
              color={
                isSticky || isFixedNavRoute()
                  ? "rgba(235, 129, 40, 1)"
                  : "white"
              }
            />
          </div>
          <div
            className="res-577px-d-none "
            style={{ cursor: "pointer" }}
            onClick={() => {
              scrollToTop();
              navigate("/");
            }}
          >
            <img src={S3NewLogo_mob} alt="S3NewLogo_mob" />
          </div>
          {/* Mobile view */}
          <div className="res-577px-d-none">
            {isNavbarOpen ? (
              <MdClose
                size={24}
                style={{ position: "relative", top: "3px" }}
                onClick={onCloseNavbar}
              />
            ) : (
              <IoMenu
                size={24}
                style={{ position: "relative", top: "3px" }}
                onClick={onNavbarClick}
              />
            )}
          </div>
        </div>
        {!localStorage.getItem("token") && (
          <div
            className={`res-576px-d-none new-navbar-flex-options ${!isSticky && !isFixedNavRoute() && "sticky-removed-links"
              }`}
          >
            <Link
              to="/about"
              className={`${isCurrentLocation("/about") && "activee"
                } nav-btn d-flex align-items-center gap-2`}
              onClick={() => scrollToTop()}
            >
              About Us
            </Link>
            <Link
              className={`${isCurrentLocation("/services") && "activee"
                } nav-btn d-flex align-items-center gap-2`}
              to="/services"
              onClick={() => scrollToTop()}
            >
              Services
            </Link>
            <Link
              to="/Contact-us"
              className={`${isCurrentLocation("/Contact-us") && "activee"
                } nav-btn d-flex align-items-center gap-2`}
              onClick={() => scrollToTop()}
            >
              Contact
            </Link>
            <button
              className={`${!isSticky && !isFixedNavRoute() && "sticky-removed-btn"
                } nav-flex-but`}
              style={{ cursor: "default" }}
            >
              <div className="d-flex align-items-center">
                <button
                  className="navbar-button"
                  onClick={() => navigate("/login")}
                >
                  Login
                </button>
                <span>/</span>
                <button
                  onClick={() => navigate("/commonRegister")}
                  className="navbar-button"
                >
                  Register
                </button>
              </div>
            </button>
            {/* <div
   onClick={() => scrollToTop()}
   className={`${
     !isSticky && !isFixedNavRoute() && "sticky-removed-btn"
   }`}
 >
   <span>Login</span>
   <span>/</span>
   <span>Register</span>
 </div> */}
          </div>
        )}
      </div>

      <div className={`popup-menu ${isNavbarOpen ? "open" : ""}`}>
        <div
          className={`cursor-pointer ${pathname === "/" ? "current-active-link-mobile" : ""
            } text-align-center`}
          onClick={() => {
            setIsNavbarOpen(false);
            navigate("/");
          }}
          style={{ color: "rgba(26, 26, 26, 0.7)" }}
        >
          <div className="font-weight-1 font-size-0-h">Home</div>
        </div>

        <div
          onClick={() => {
            setIsNavbarOpen(false);
            navigate("/about");
          }}
          className={`gap-1 cursor-pointer ${pathname === "/about" ? "current-active-link-mobile" : ""
            } text-align-center  mt-3`}
          style={{ color: "rgba(26, 26, 26, 0.7)" }}
        >
          <div className="font-weight-1 font-size-0-h">About</div>
        </div>
        <div
          onClick={() => {
            setIsNavbarOpen(false);
            navigate("/services");
          }}
          className={`cursor-pointer ${pathname === "/services" ? "current-active-link-mobile" : ""
            } text-align-center  mt-3`}
          style={{ color: "rgba(26, 26, 26, 0.7)" }}
        >
          <div className="font-weight-1 font-size-0-h">Services</div>
        </div>
        <div
          onClick={() => {
            setIsNavbarOpen(false);
            navigate("/Contact-us");
          }}
          className={`cursor-pointer ${pathname === "/Contact-us" ? "current-active-link-mobile" : ""
            } text-align-center  mt-3`}
          style={{ color: "rgba(26, 26, 26, 0.7)" }}
        >
          <div className="font-weight-1 font-size-0-h">Contact</div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
