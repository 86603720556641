import React from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export default function WAServiceBanner() {
  const navigate = useNavigate();
  return (
    <div className="appliances-service-banner-con">
      <div style={{ fontSize: "11px", color: "rgba(139, 139, 139, 1)" }}>
        <span
          style={{ color: "black", cursor: "pointer" }}
          onClick={() => navigate("/")}
          className="res-576px-d-none"
        >
          <b>Home</b>
        </span>
        <MdKeyboardArrowRight className="res-576px-d-none" size={22} />
        <span
          onClick={() => navigate("/services")}
          style={{ cursor: "pointer" }}
          className="res-576px-d-none"
        >
          Services
        </span>
        <MdKeyboardArrowRight className="res-576px-d-none" size={22} />
        <span className="res-576px-d-none">Washing Machine </span>
      </div>
      <div className="banner-content">
        <div className="heading">Washing Machine </div>

        <div className="con-text">
          Rock and Roll, dry out all your worries as we maintain your washing
          machine! We assure quick and quality services at your doorstep, with
          no middlemen!
        </div>
        <div className="con-text">
          Need a quick turnaround for washing machine repair service in Chennai?
          Schedule a visit now by logging in to be amazed by our speed and
          service!
        </div>
        <button className="download-button" onClick={() => navigate("/login")}>
          Log in
        </button>
      </div>
    </div>
  );
}
