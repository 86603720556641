import React, { useEffect, useState } from "react";
import Topbar from "../topbar";
import Sidebar from "../Sidebar";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
// import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
// import TextField from '@mui/material/TextField';
// import GPayIcon from '../../Assets/icons/GPayIcon';
// import PaytmIcon from '../../Assets/icons/PaytmIcon';
import { useDispatch, useSelector } from "react-redux";
import {
  getTransactionDetails,
  initiatePayment,
} from "../../redux/actions/paymentAction";
import { Modal } from "react-bootstrap";
import moment from "moment/moment";
import SpinnerLoader2 from "../../plugins/SpinnerLoader2";
import { BsCurrencyRupee } from "react-icons/bs";
import SpinnerLoader from "../../plugins/SpinnerLoader";
import { IconButton, InputBase, Paper, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import SearchIcon from "@mui/icons-material/Search";

const WalletOptions = () => {
  const theme = useTheme();
  const mobileMatches = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [paymentValue, setPaymentValue] = useState(0);
  // const [paymentMethod, setPaymentMethod] = useState('');
  // const [paymentMethodValue, setPaymentMethodValue] = useState('UPI');
  const [selectPaymentModal, setSelectPaymentModal] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [paymentValues, setPaymentValues] = useState("");

  const { initiatePaymentData, loading: initiatePaymentLoading } = useSelector(
    (state) => state.initiatePaymentState
  );
  const {
    transactionDetailsData,
    loading: transactionDetailsLoading,
  } = useSelector((state) => state.transactionDetailsState);
  const { walletDetailsData } = useSelector(
    (state) => state.walletDetailsState
  );
  const redirect_url = initiatePaymentData?.redirectInfo?.url;
  const dispatch = useDispatch();

  const handlePayment = () => {
    const payload = {
      amount: paymentValue * 100,
    };
    dispatch(initiatePayment(payload));
  };

  const handleSearchInput = (input) => {
    setInputValue(input);
    const searchInputParseInt = parseFloat(input); // Use parseFloat for decimal input.

    const dataFound = transactionDetailsData.filter((value) => {
      const amountParseFloat = value.amount / 100;
      const epsilon = 0.001;
      return Math.abs(amountParseFloat - searchInputParseInt) < epsilon;
    });

    if (dataFound.length > 0) {
      setTransactionDetails(dataFound);
    } else if (input && dataFound.length === 0) {
      setTransactionDetails([]);
    } else if (!input) {
      setTransactionDetails(transactionDetailsData);
    }
  };

  const getWalletBalance = () => {
    if (walletDetailsData?.wallet_balance >= 0) {
      return walletDetailsData?.wallet_balance / 100;
    } else if (walletDetailsData?.wallet_balance < 0) {
      const wallet_balance =
        walletDetailsData?.wallet_balance.toString().split("-")[1] / 100;
      return `-${wallet_balance}`;
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, "");
    setPaymentValue(inputValue);
  };

  useEffect(() => {
    if (redirect_url) {
      window.location.replace(redirect_url);
    }
  }, [redirect_url]);

  useEffect(() => {
    dispatch(getTransactionDetails());
  }, [dispatch]);

  useEffect(() => {
    setTransactionDetails(transactionDetailsData);
  }, [transactionDetailsData]);

  return (
    <div style={{ overflow: "hidden" }}>
      <Topbar />
      <div
        className="row container-fluid"
        style={{ paddingRight: "0px", overflow: "hidden" }}
      >
        <div className="col-2 col-12 p-0 border border-right">
          <Sidebar />
        </div>
        <div
          className="col-10 user_management"
          style={{ paddingRight: "0px", marginTop: "80px" }}
        >
          <div className="row">
            <div className="mt-lg-2">
              <i
                className="bi bi-chevron-left "
                style={{
                  fontWeight: "600",
                  fontSize: "21px",
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                }}
                onClick={() => navigate(-1)}
              ></i>
              <text
                className="p-0"
                style={{
                  fontWeight: "600",
                  color: "#1E232C",
                  fontSize: "21px",
                  width: "208px",
                  height: "32px",
                }}
              >
                Wallet
              </text>
            </div>
          </div>
          <div className="mt-1 mb-2 p-lg-3 p-1 pb-0">
            <div>
              <div className="row wallet-info-container m-1 p-3 pt-3 pb-0">
                <div
                  className="col-8 d-flex align-items-center heading"
                  style={{ fontSize: "22px" }}
                >
                  Credits in Your Wallet
                </div>
                <div
                  className="col-4 d-flex align-items-center amount-value"
                  style={{
                    fontSize: "22px",
                    fontWeight: "600",
                    justifyContent: "flex-end",
                  }}
                >
                  <BsCurrencyRupee />
                  {getWalletBalance()}
                </div>
                <div
                  className="d-flex align-items-center gap-1 cursor-pointer add-credits"
                  style={{ fontSize: "14px", textDecoration: "underline" }}
                >
                  <div onClick={() => setSelectPaymentModal(true)}>
                    + Add Credits
                  </div>
                </div>
              </div>
              {/* <div className='mt-3 mb-1 payment-methods p-3'>
                                <div style={{ fontWeight: '600' }}>Select Payment Method</div>
                                <RadioGroup
                                    value={paymentMethodValue}
                                    onChange={(e) => setPaymentMethodValue(e.target.value)}
                                >
                                    <div className='d-flex align-items-center mt-2 mb-2' style={{ fontSize: '20px' }}>
                                        <div><FormControlLabel value={'UPI'} control={<Radio />} onClick={() => setPaymentMethodValue(paymentMethodValue)} /></div>
                                        <div style={{ fontSize: '16px' }}>UPI</div>
                                    </div>
                                    <div style={{
                                        marginLeft: '30px',
                                        marginRight: '30px',
                                    }}>
                                        <div className='d-flex align-items-center gap-2'>
                                            <div className='payment-method-btn'><GPayIcon /></div>
                                            <div className='payment-method-btn'><PaytmIcon /></div>
                                        </div>
                                        <div className='mt-3 mb-3'>
                                            <TextField
                                                fullWidth label="Enter UPI ID"
                                                id="upi-id"
                                                type="text"
                                                placeholder="Enter UPI ID"
                                                className='upi-id-input'
                                            />
                                        </div>
                                        <div className={`${paymentValue > 0 ? 'pay-btn' : 'pay-btn-disabled'} mt-3 mb-3`}>
                                            <button className='d-flex align-items-center justify-content-center gap-1' onClick={handlePayment} disabled={paymentValue === 0}><div>Pay</div><div>{paymentValue}</div></button>
                                        </div>
                                        <div className='custom-hr' />
                                    </div>
                                    <div className='d-flex align-items-center mt-2 mb-2' style={{ fontSize: '20px' }}>
                                        <div><FormControlLabel disabled value={'debit-credit-vard'} control={<Radio />} onClick={() => setPaymentMethodValue(paymentMethodValue)} /></div>
                                        <div style={{ fontSize: '16px', color: '#bfbfbf' }}>Debit / Credit Card</div>
                                    </div>
                                </RadioGroup>
                            </div> */}
            </div>
            <div className="mt-4 mb-4">
              <div style={{ fontSize: "20px", fontWeight: "600" }}>
                Transaction History
              </div>
              {transactionDetailsLoading ? (
                <div
                  className="d-flex align-items-center"
                  style={{
                    height: "200px",
                  }}
                >
                  <SpinnerLoader />
                </div>
              ) : (
                <div>
                  {(transactionDetails?.length > 0 || inputValue) && (
                    // <div
                    //   className="d-flex align-items-center gap-2 mt-4 mb-4 col-12"
                    //   style={{
                    //     position: "sticky",
                    //     top: 0,
                    //     background: "#fff",
                    //   }}
                    // >
                    // <div className="search-input" >
                    //   <input
                    //     placeholder="Search by amount"
                    //   />
                    // </div>
                    <div className="mt-4 mb-4 col-lg-3 col-12">
                      <Paper
                        elevation={0}
                        className="border rounded-0"
                        component="form"
                        sx={{ display: "flex", alignItems: "center" }}
                        style={{ height: "42px" }}
                      >
                        <IconButton type="button" aria-label="search">
                          <SearchIcon />
                        </IconButton>
                        <InputBase
                          sx={{ flex: 1 }}
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "14px",
                            // width: mobileMatches ? "100%" : "400px",
                          }}
                          placeholder="Search by Amount"
                          inputProps={{
                            "aria-label": "search google maps",
                          }}
                          onChange={(e) => handleSearchInput(e.target.value)}
                        />
                      </Paper>
                    </div>
                  )}
                  {transactionDetails?.length > 0 &&
                    transactionDetails?.map((transactionDetail) => {
                      const getTransactionInfo = () => {
                        if (transactionDetail.comment === "CR") {
                          return "Added to the Wallet";
                        } else if (transactionDetail.comment === "DB") {
                          return "Debited from Wallet";
                        } else if (transactionDetail.comment === "P") {
                          return "Transaction Pending";
                        } else if (transactionDetail.comment === "F") {
                          return "Transaction Failed";
                        }
                      };
                      const getTransactionDateTime = () => {
                        const datetime = new Date(transactionDetail.created_at);
                        const hour = datetime.getHours();
                        const minute = datetime.getMinutes();
                        const isRecentDate = () => {
                          const currentDate = new Date();
                          const previousDate = new Date(currentDate);
                          previousDate.setDate(currentDate.getDate() - 1);
                          const formattedCurrentDate = moment(
                            currentDate
                          ).format("DD-MM-YYYY");
                          const formattedPreviousDate = moment(
                            previousDate
                          ).format("DD-MM-YYYY");
                          const formattedTransactionDate = moment(
                            transactionDetail.created_at
                          ).format("DD-MM-YYYY");
                          if (
                            formattedCurrentDate === formattedTransactionDate
                          ) {
                            return "Today, ";
                          } else if (
                            formattedPreviousDate === formattedTransactionDate
                          ) {
                            return "Yesterday, ";
                          } else {
                            return false;
                          }
                        };
                        return (
                          <span>
                            {isRecentDate()}
                            {moment(transactionDetail.created_at).format(
                              "DD MMM"
                            )}{" "}
                            at {hour}:{minute}
                          </span>
                        );
                      };
                      const getCommentFontColor = () => {
                        if (transactionDetail.comment === "CR") {
                          return "#36A93F";
                        } else if (transactionDetail.comment === "DB") {
                          return "#DC3545";
                        } else if (transactionDetail.comment === "P") {
                          return "#EB8128";
                        } else if (transactionDetail.comment === "F") {
                          return "#DC3545";
                        }
                      };
                      const getCommentBgColor = () => {
                        if (transactionDetail.comment === "CR") {
                          return "rgb(213 247 213)";
                        } else if (transactionDetail.comment === "DB") {
                          return "rgb(255 207 212)";
                        } else if (transactionDetail.comment === "P") {
                          return "#FFE5CC";
                        } else if (transactionDetail.comment === "F") {
                          return "rgb(255 207 212)";
                        }
                      };
                      return (
                        <div>
                          <div className="d-flex gap-2 mt-2 mb-2">
                            <div
                              className="transaction-initial"
                              style={{
                                color: getCommentFontColor(),
                                backgroundColor: getCommentBgColor(),
                              }}
                            >
                              <div>{transactionDetail.comment}</div>
                            </div>
                            <div className="w-100">
                              <div className="d-lg-flex align-items-lg-center justify-content-space-between">
                                <div
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "14px",
                                  }}
                                >
                                  {getTransactionInfo()}
                                </div>
                                <div
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "14px",
                                    color: getCommentFontColor(),
                                  }}
                                >
                                  <BsCurrencyRupee />
                                  <span>{transactionDetail.amount / 100}</span>
                                </div>
                              </div>
                              <div
                                className="mt-2 mb-2 transaction-date-time-responsive"
                                style={{ fontSize: "12px", color: "#6A707C" }}
                              >
                                {getTransactionDateTime()}{" "}
                                {transactionDetail?.payment_method &&
                                  `| ${transactionDetail?.payment_method}`}
                              </div>

                              <div
                                className="d-lg-flex align-items-lg-center gap-2"
                                style={{ fontSize: "12px", color: "#6A707C" }}
                              >
                                <div>Transaction ID:</div>
                                <Tooltip
                                  title={
                                    transactionDetail?.internal_transaction_id
                                  }
                                >
                                  <div
                                    className="transaction-id-responsive"
                                    style={{ wordWrap: "break-word" }}
                                  >
                                    {" "}
                                    ***
                                    {transactionDetail?.internal_transaction_id?.slice(
                                      -15
                                    )}
                                  </div>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                          <div className="custom-hr-2" />
                        </div>
                      );
                    })}
                  {transactionDetails?.length === 0 && inputValue === "" && (
                    <div
                      style={{
                        height: "100px",
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                        top: "50px",
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "#ffa5005c",
                      }}
                    >
                      <div>Oops! No transaction histories found!</div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={selectPaymentModal}
        onHide={() => {
          if (initiatePaymentLoading === false) {
            setSelectPaymentModal(false);
          }
        }}
        size="lg"
        centered
        className="payment-modal"
      >
        <div className="p-4">
          <div className="mt-1 mb-1 d-flex align-items-center justify-content-space-between">
            <div style={{ fontSize: "20px", fontWeight: "600" }}>
              Add Credits to Your Wallet
            </div>
            <div
              onClick={() => {
                if (initiatePaymentLoading === false) {
                  setSelectPaymentModal(false);
                }
              }}
            >
              <AiOutlineClose />
            </div>
          </div>
          <div className="m-1 mt-4 mb-4 add-wallet-info">
            Please enter or select the amount you would like to add to your
            wallet
          </div>
          <div className="add-amount-value">
            <input
              className="value-container"
              value={paymentValue}
              placeholder="Enter Amount"
              style={{ width: "50%" }}
              onChange={handleInputChange}
              type="text" // Use type="text" instead of "number"
            />
          </div>
          <div className="add-amount-value mt-3 mb-3">
            <div
              className={`${
                paymentValue === 500
                  ? "value-container-highlighted"
                  : "value-container"
              }`}
              onClick={() => setPaymentValue(500)}
            >
              <BsCurrencyRupee />
              500
            </div>
            <div
              className={`${
                paymentValue === 700
                  ? "value-container-highlighted"
                  : "value-container"
              }`}
              onClick={() => setPaymentValue(700)}
            >
              <BsCurrencyRupee />
              700
            </div>
            <div
              className={`${
                paymentValue === 1000
                  ? "value-container-highlighted"
                  : "value-container"
              }`}
              onClick={() => setPaymentValue(1000)}
            >
              <BsCurrencyRupee />
              1000
            </div>
            <div
              className={`${
                paymentValue === 2000
                  ? "value-container-highlighted"
                  : "value-container"
              }`}
              onClick={() => setPaymentValue(2000)}
            >
              <BsCurrencyRupee />
              2000
            </div>
          </div>
          <div
            className={`${
              paymentValue < 1 || initiatePaymentLoading
                ? "pay-btn-disabled"
                : "pay-btn-valued"
            } mt-3 mb-3`}
          >
            <button
              className="d-flex align-items-center justify-content-center gap-1"
              onClick={handlePayment}
              disabled={paymentValue < 1 || initiatePaymentLoading}
            >
              <div className="d-flex align-items-center justify-content-center gap-2">
                {initiatePaymentLoading && <SpinnerLoader2 />}Proceed
                {initiatePaymentLoading && "ing"} to Payment
              </div>
            </button>
          </div>
          <div
            className="m-1 mt-4 mb-4"
            style={{ color: "#EB8128", fontSize: "14px" }}
          >
            *Please avoid going back in your browser while making payment.
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default WalletOptions;
