import * as React from "react";
import { useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Sidebar from "../Sidebar";
import Topbar from "../topbar";
import "../../layout.css";
import Footer from "../Footer";
import Api from "../../service/services";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Newpassword from "./customerNewpassword";
import { useEffect } from "react";

const Resetpassword = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [oldpassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmpasswordError, setConfirmpasswordError] = useState("");
  // const passwordPattern =
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\d\s])[\w\d\S]{8,}$/;

  const [passwordError, setPasswordError] = useState("");
  const [password1Error, setPassword1Error] = useState("");
  const [IsEmpty, setIsEmpty] = useState(false);
  const [IsoldEmpty, setIsoldEmpty] = useState(false);
  const [IsnewEmpty, setIsnewEmpty] = useState(false);
  const [IsconfirmEmpty, setIsconfirmEmpty] = useState(false);
  const [Role, setRole] = useState("");
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  useEffect(() => {
    console.log("Enter Password Role", Role);
    setRole(localStorage.getItem("role"));
  }, [Role]);
  const handleSubmit = (e) => {
    e.preventDefault();

    setPasswordError(oldpassword === "" ? "please fill this field" : "");
    setPassword1Error(newPassword === "" ? "please fill this field" : "");
    setConfirmpasswordError(
      confirmPassword === "" ? "please fill this field" : ""
    );

    if (oldpassword === newPassword && oldpassword === confirmPassword) {
      Swal.fire({
        text: "Your new password must be different from the current password.",
        confirmButtonText: "OK",
      });
    } else if (newPassword !== confirmPassword) {
      Swal.fire({
        text: "Your New Password and Confirm Password must match.",
        confirmButtonText: "OK",
      });
    } else if (!oldpassword) {
      Swal.fire({
        text: "Your Current Password is Incorrect.",
        confirmButtonText: "OK",
      });
    } else {
      const Data = new FormData();
      Data.append("username", localStorage.getItem("email_id"));
      Data.append("password", newPassword);
      Data.append("confirmpassword", confirmPassword);
      Data.append("old_password", oldpassword);

      if (
        oldpassword &&
        newPassword &&
        confirmPassword &&
        password1Error === "" &&
        passwordError === "" &&
        confirmpasswordError === ""
      ) {
        Api.customerResetPassword(Data).then((response) => {
          if (response.status === 200) {
            Swal.fire({
              text: "Password Changed...",
            }).then((result) => {
              if (result.isConfirmed) {
                if (Role === "Consumer") {
                  window.location.replace("/customerDashboard");
                } else if (Role === "Technician") {
                  window.location.replace("/technicianDashboard");
                } else if (Role === "SuperAdmin") {
                  window.location.replace("/super-admin-dashboard");
                } else if (Role === "DistrictAdmin") {
                  window.location.replace("/district-admin-dashboard");
                } else if (Role === "ZonalAdmin") {
                  window.location.replace("/zonal-admin-dashboard");
                }
              }
            });
          } else {
            Swal.fire({
              text: "Your Current Password is Incorrect.",
            });
          }
        });
      }
    }
  };

  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <Topbar />
        <div
          className="row container-fluid"
          style={{ paddingRight: "0px", overflow: "hidden" }}
        >
          <div className="col-2 col-12 p-0 border border-right">
            <Sidebar />
          </div>
          {/* Main Content */}

          <div
            className="col-12 user_management"
            style={{ paddingRight: "0px", marginTop: "80px" }}
          >
            <div className="row">
              <div className="mt-lg-2">
                <i
                  className="bi bi-chevron-left "
                  style={{
                    fontWeight: "600",
                    fontSize: "21px",
                    width: "20px",
                    height: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(-1)}
                ></i>
                <text
                  className="p-0"
                  style={{
                    fontWeight: "600",
                    color: "#1E232C",
                    fontSize: "21px",
                    width: "208px",
                    height: "32px",
                  }}
                >
                  Reset Password
                </text>
              </div>

              <form onSubmit={handleSubmit} style={{ alignItems: "center" }}>
                <div className="col-lg-6 mt-lg-0 mt-3 ms-4 me-4">
                  <text
                    className=""
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#6A707C",
                    }}
                  >
                    Your new password must be different from the current
                    password.
                  </text>
                  {/* old password */}
                  <TextField
                    className="w-100 mt-4 mt-lg-4"
                    style={{ height: "56px" }}
                    id="password"
                    label="Current password"
                    value={oldpassword}
                    onChange={(e) => {
                      setOldPassword(e.target.value);
                      setPasswordError("");
                    }}
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your password"
                    error={!!passwordError}
                    helperText={passwordError}
                    FormHelperTextProps={{
                      style: {
                        // position: 'absolute',
                        bottom: "-20px",
                        whiteSpace: "nowrap",
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                            tabIndex={-1}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  {/* new password */}
                  <TextField
                    className="w-100 mt-4 mt-lg-4"
                    style={{ height: "56px" }}
                    id="password"
                    label="New password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    type={showPassword1 ? "text" : "password"}
                    placeholder="Enter your password"
                    error={!!password1Error}
                    helperText={password1Error}
                    FormHelperTextProps={{
                      style: {
                        // position: 'absolute',
                        bottom: "-20px",
                        whiteSpace: "nowrap",
                      },
                    }}
                    onKeyUp={() => {
                      if (newPassword && !confirmPassword) {
                        setConfirmpasswordError(
                          "Please confirm your password."
                        );
                        setIsEmpty(false);
                      } else {
                        setConfirmpasswordError("");
                        // setIsEmpty(true);
                      }
                      if (newPassword.length > 0) {
                        if (oldpassword === newPassword) {
                          setPassword1Error(
                            "Your new password must be different from the current password."
                          );
                          setIsEmpty(false);
                        } else if (confirmPassword !== newPassword) {
                          setPassword1Error("Passwords do not match.");
                          setIsEmpty(false);
                        } else if (!passwordPattern.test(confirmPassword)) {
                          setPassword1Error(
                            "Password must contain at least 8 characters, one digit, and one special character."
                          );
                          setIsEmpty(false);
                        } else {
                          setPassword1Error("");
                          setIsEmpty(true);
                        }
                        if (!newPassword) {
                          setPassword1Error("Please enter your password.");
                          setIsEmpty(false);
                        } else if (!passwordPattern.test(newPassword)) {
                          setPassword1Error(
                            "Password must contain at least 8 characters, one digit,one uppercase and one special character"
                          );
                          setIsEmpty(false);
                        } else {
                          setPassword1Error("");
                          setIsEmpty(true);
                        }
                      } else {
                        setPassword1Error("");
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword1}
                            edge="end"
                            tabIndex={-1}
                          >
                            {showPassword1 ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  {/* confirm new password */}
                  <TextField
                    className="w-100 mt-4 mt-lg-4"
                    style={{ height: "56px" }}
                    id="confirm-password"
                    label="Confirm new password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    type={showPassword2 ? "text" : "password"}
                    placeholder="Confirm your password"
                    error={!!confirmpasswordError}
                    helperText={confirmpasswordError}
                    FormHelperTextProps={{
                      style: {
                        // position: 'absolute',
                        bottom: "-20px",
                        whiteSpace: "nowrap",
                      },
                    }}
                    onKeyUp={() => {
                      if (newPassword && !confirmPassword) {
                        setConfirmpasswordError(
                          "Please confirm your password."
                        );
                        setIsEmpty(false);
                      } else {
                        setConfirmpasswordError("");
                      }
                      if (confirmPassword.length > 0) {
                        if (oldpassword === confirmPassword) {
                          setConfirmpasswordError(
                            "Your new password must be different from the current password."
                          );
                          setIsEmpty(false);
                        } else if (
                          confirmPassword.length > 0 &&
                          confirmPassword !== newPassword
                        ) {
                          setConfirmpasswordError("Passwords do not match.");
                          setIsEmpty(false);
                        } else if (!passwordPattern.test(confirmPassword)) {
                          setPassword1Error(
                            "Password must contain at least 8 characters, one digit, and one special character."
                          );
                          setIsEmpty(false);
                        } else {
                          setConfirmpasswordError("");
                          setIsEmpty(true);
                        }
                        if (!newPassword) {
                          setPassword1Error("Please enter your password.");
                          setIsEmpty(false);
                        } else if (!passwordPattern.test(newPassword)) {
                          setPassword1Error(
                            "Password must contain at least 8 characters, one digit,one uppercase and one special character"
                          );
                          setIsEmpty(false);
                        } else {
                          setPassword1Error("");
                          setIsEmpty(true);
                        }
                      } else {
                        setConfirmpasswordError("");
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword2}
                            edge="end"
                            tabIndex={-1}
                          >
                            {showPassword2 ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <div>
                    <button
                      className="btn"
                      type="submit"
                      style={{
                        height: "56px",
                        width: "100%",
                        color: "white",
                        backgroundColor: "#EB8128",
                        marginTop: 30,
                      }}
                      disabled={
                        passwordPattern.test(newPassword) &&
                        passwordPattern.test(confirmPassword) &&
                        confirmPassword === newPassword &&
                        oldpassword !== newPassword &&
                        oldpassword !== confirmPassword &&
                        oldpassword
                          ? false
                          : true
                      }
                    >
                      Reset Password
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="p-0 col-lg-12">{/* <Footer/> */}</div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Resetpassword;
