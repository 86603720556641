import React, { useEffect } from "react";
import Navbar from "../common/Navbar";
import Footer from "../Footer";
import Quote from "../television/Quote";
import FAQ from "../television/FAQ";
import UpcomingService from "../services/UpcomingService";
import TellMeAbout from "../services/TellMeAbout";
import HowToBook from "../services/HowToBook";
import FindUs from "../services/FindUs";
import FridgeServiceBanner from "./FridgeServiceBanner";
import FridgeServiceOffered from "./FridgeServiceOffered";

export default function FridgeService() {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Navbar />
      <FridgeServiceBanner />
      <FridgeServiceOffered />
      <Quote
        quote={`“I had the best experience when I booked services for Fridge Repairing in Chennai using the S3 app. They ensure the service is delivered such that I don’t have to monitor them during the service or bother to call for a technician at least for the next 1 year.” – Paramesh, Santhome. `}
      />
      <FAQ
        appliance={"Refrigerator"}
        appliance_abbreviation={"Refrigerator"}
        last_answer={
          "Yes, our technicians support end-to-end refrigerator installation and services. This includes recommendations for stabilizers (if subject to frequent fluctuation), stand fixtures, and other value-added services to ensure an amazing experience.  "
        }
      />
      <UpcomingService />
      <TellMeAbout />
      <FindUs />
      <HowToBook />
      <Footer />
    </div>
  );
}
