import { createSlice } from "@reduxjs/toolkit";

const productListSlice = createSlice({
  name: "productList",
  initialState: {
    loading: false,
  },
  reducers: {
    productListRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    productListSuccess(state, action) {
      return {
        ...state,
        loading: false,
        productList: action.payload,
      };
    },
    productListFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    productListErrorClear(state, action) {
      return {
        ...state,
        loading: false,
        error: false,
      };
    },
  },
});

const { actions, reducer } = productListSlice;

export const {
  productListRequest,
  productListFail,
  productListSuccess,
  productListClear,
} = actions;

export default reducer;
