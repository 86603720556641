import { createSlice } from "@reduxjs/toolkit";


const transactionDetailsSlice = createSlice({
    name: 'transactionDetails',
    initialState:{
        loading: false,
    },
    reducers:{
        transactionDetailsRequest(state, action){
            return{
                ...state,
                loading: true
            }
        },
        transactionDetailsSuccess(state, action){
            return{
                ...state,
                loading: false,
                transactionDetailsData: action.payload
            }
        },
        transactionDetailsFail(state, action){
            return{
                ...state,
                loading: false,
                error: action.payload
            }
        }
    }
});

const {actions, reducer} = transactionDetailsSlice;

export const {transactionDetailsRequest,transactionDetailsFail,transactionDetailsSuccess} = actions

export default reducer;