import React from "react";
import ErrorPage from "../components/Error";
import { Navigate } from "react-router-dom";

const ProtectedRouteConsumer = ({ children }) => {
  const Role = localStorage.getItem("role");

  if (!Role) {
    return <Navigate to={"/login"} />;
  }

  if (Role !== "Consumer") {
    return <ErrorPage />;
  }

  if (Role === "Consumer") {
    return children;
  }
};

export default ProtectedRouteConsumer;
