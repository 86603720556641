import { createSlice } from "@reduxjs/toolkit";

const revenueShareListSlice = createSlice({
  name: "revenueShareList",
  initialState: {
    loading: false,
  },
  reducers: {
    revenueShareListRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    revenueShareListSuccess(state, action) {
      return {
        ...state,
        loading: false,
        revenueShareListData: action.payload.data,
        revenueShareCount: action.payload.count,
      };
    },
    revenueShareListFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
  },
});

const { actions, reducer } = revenueShareListSlice;

export const {
  revenueShareListRequest,
  revenueShareListFail,
  revenueShareListSuccess,
} = actions;

export default reducer;
