import { createSlice } from "@reduxjs/toolkit";

const customerDashboardSlice = createSlice({
  name: "customerDashboard",
  initialState: {
    loading: false,
  },
  reducers: {
    customerDashboardRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    customerDashboardSuccess(state, action) {
      return {
        ...state,
        loading: true,
        customerDashboardData: action.payload,
      };
    },
    customerDashboardFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    customerDashboardClear(state, action) {
      return {
        ...state,
        loading: false,
        customerDashboardData: null,
      };
    },
  },
});

const { actions, reducer } = customerDashboardSlice;

export const {
  customerDashboardRequest,
  customerDashboardFail,
  customerDashboardSuccess,
  customerDashboardClear,
} = actions;

export default reducer;
