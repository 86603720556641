import { createSlice } from "@reduxjs/toolkit";


const  referralChartListSlice = createSlice({
    name: 'referralChartList',
    initialState: {
        loading: false,
        referralChartListData: [],
        error: null,
        statusCode: null,
        successMsg: null,
        errorMsg: null,
    },
    reducers: {
        referralChartListRequest(state, action) {
            return {
                ...state,
                loading: true
            }
        },
        referralChartListSuccess(state, action) {
            return {
                ...state,
                loading: true,
                referralChartListData: action?.payload,
                // successMsg: action.payload.data.message,
                // totalCount: action.payload.data?.totalCount,
                errorMsg: null,
                statusCode: 'success'
            }
        },
        referralChartListFail(state, action) {
            return {
                ...state,
                loading: false,
                error: action.payload,
                statusCode: 'error',
                errorMsg: action?.payload?.error?.message,
                referralChartListData: [],
                successMsg: null
            }
        },
        clearStatusCode(state, action) {
            return {
                ...state,
                statusCode: null
            }
        }

    }
});

const { actions, reducer } =  referralChartListSlice;

export const { referralChartListRequest, referralChartListFail, referralChartListSuccess, clearStatusCode } = actions

export default reducer;
