import React from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export default function TVServiceBanner() {
  const navigate = useNavigate();
  return (
    <div className="appliances-service-banner-con">
      <div style={{ fontSize: "11px", color: "rgba(139, 139, 139, 1)" }}>
        <span
          style={{ color: "black", cursor: "pointer" }}
          onClick={() => navigate("/")}
          className="res-576px-d-none"
        >
          <b>Home</b>
        </span>
        <MdKeyboardArrowRight className="res-576px-d-none" size={22} />
        <span
          className="res-576px-d-none"
          onClick={() => navigate("/services")}
          style={{ cursor: "pointer" }}
        >
          Services
        </span>
        <MdKeyboardArrowRight className="res-576px-d-none" size={22} />
        <span className="res-576px-d-none">Television</span>
      </div>
      <div className="banner-content">
        <div className="heading">Television</div>

        <div className="con-text">
          Get your picture-perfect television service only at S3! We assure
          quick and quality services at your doorstep, with no middlemen! 
        </div>
        <div className="con-text">
          Looking for Top TV Technician in Chennai? We ensure ratings and
          reviews are recorded for every service, shared transparently, and
          acted upon. Log in now to book our top technicians!{" "}
        </div>
        <button onClick={() => navigate("/login")} className="download-button">
          Log in
        </button>
      </div>
    </div>
  );
}
