import {
  Avatar,
  Box,
  InputBase,
  Tab,
  Tabs,
  TextareaAutosize,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { isNil, isArray } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import React, { useCallback, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";
import { Paper } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DoneIcon from "@mui/icons-material/Done";
import "../../layout.css";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ProgressBar from "../Customers/progress";
import { Link } from "react-router-dom";
import Topbar from "../topbar";
import Sidebar from "../Sidebar";
import Api from "../../service/services";
import { format, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import RectIcon from "../../Assets/icons/RectIcon";
import ArrowIconHighlighted from "../../Assets/icons/ArrowIconHighlighted";
import ArrowIconDefault from "../../Assets/icons/ArrowIconDefault";
import CalendarIcon from "../../Assets/icons/CalendarIcon";
import ToastMessage from "../../plugins/ToastMessage";
import { Modal } from "react-bootstrap";
import { Popover } from "react-tiny-popover";
import SpinnerLoader2 from "../../plugins/SpinnerLoader2";
import { getNotificationsCount } from "../../redux/actions/notificationsAction";
import { useDispatch } from "react-redux";
import SpinnerLoader from "../../plugins/SpinnerLoader";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  MdClose,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from "react-icons/md";
import { getQueryParam } from "../../plugins/getQueryParams";
import FilterModule from "../../plugins/FilterModule";
import { IoMdPhotos } from "react-icons/io";
import Ratings from "../../plugins/Ratings";
import { Scrollbars } from "react-custom-scrollbars";
import { FaRegStar } from "react-icons/fa";
const debounce = (callback, delay) => {
  let timerId;
  return (...args) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};
const ServiceRequestList = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [hovered, setHovered] = useState(null);
  const [isService, setIsService] = useState(false);
  const [isVisit, setIsVisit] = useState(false);
  const [isReopenSR, setIsReopenSR] = useState(false);
  const [isClosure, setIsClosure] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [srDetailArray, setSRDetailArray] = useState([]);
  const [openSRList, setOpenSRList] = useState([]);
  const [inprogressSRList, setInprogressSRList] = useState([]);
  const [completedSRList, setCompletedSRList] = useState([]);
  const [serviceRequestId, setServiceRequestId] = useState("");
  const [serviceCreatedTime, setServiceCreatedTime] = useState("");
  const status = getQueryParam("sr_status");
  const srParamsId = getQueryParam("sr_id");
  const [serviceStatus, setServiceStatus] = useState("");
  const [IsOpenEdit, setIsOpenEdit] = useState(false);
  const [value, setValue] = React.useState(0);

  const [open, setOpen] = useState(true);
  const [inProgress, setInprogress] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [cancelError, setCancelError] = useState(null);
  const [cancelSuccess, setCancelSuccess] = useState(null);
  const [isAlertCancelSRModal, setIsAlertCancelSRModal] = useState(false);
  const [viewDetailsPopover, setViewDetailsPopover] = useState(false);
  const [serviceListLoader, setServiceListLoader] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const dispatch = useDispatch();
  const [imageModal, setImageModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filterData, setFilterData] = useState({});
  const [ratingModal, setRatingModal] = useState(false);
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const [rating, setRating] = useState(4);
  const [srId, setSrId] = useState("");
  const [techName, setTechName] = useState("");
  const [comment, setComment] = useState("");
  const [techUserId, setTechUserId] = useState("");

  /* // Infinity Scroll Start*/
  const [loadingDataTest, setLoadingDataTest] = useState(false);
  const totalPages = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const nextPage = currentPage + 1;
  // const [openSRListDefault, setOpenSRListDefault] = useState([]);
  // const [inProgressSRListDefault, setInProgressSRListDefault] = useState([]);
  // const [completedSRListDefault, setCompletedSRListDefault] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const userId = localStorage.getItem("id");
  const handleImageClick = (index) => {
    setCurrentIndex(index);
  };
  const showNextImage = () => {
    const nextIndex = (currentIndex + 1) % srDetailArray[0].attachments.length;
    setCurrentIndex(nextIndex);
  };
  const showPreviousImage = () => {
    const previousIndex =
      (currentIndex - 1 + srDetailArray[0].attachments.length) %
      srDetailArray[0].attachments.length;
    setCurrentIndex(previousIndex);
  };

  useEffect(() => {
    setOpenSRList([]);
    const scrollPayload = {
      page: 1,
    };
    setLoadingDataTest(true);
    setLoadingData(true);
    setServiceListLoader(true);
    Api.getServiceRequestUnassignedList(
      searchInput,
      filterData,
      scrollPayload
    ).then((res) => {
      Api.getServiceRequestAcceptedList(
        searchInput,
        filterData,
        scrollPayload
      ).then((res) => {
        Api.getServiceRequestClosedList(
          searchInput,
          filterData,
          scrollPayload
        ).then((res) => {
          setLoadingData(false);
          if (res) {
            setCompletedSRList(res);
            // setCompletedSRListDefault(res);
          }
        });
        if (res) {
          setInprogressSRList(res);
          // setInProgressSRListDefault(res);
        }
      });
      setServiceListLoader(false);
      setCurrentPage(currentPage + 1);
      setOpenSRList(res);
      // setOpenSRListDefault(res);
      setLoadingDataTest(false);
      setSRDetailArray(res.length > 0 && [res[0]]);
      const sr = res.length > 0 && [res[0]];
      if (res.length > 0) {
        formatDate(sr[0]?.created_at);
        setServiceStatus(sr[0]?.status);
      }
      if (res && !status && !srParamsId) {
        setHovered(0);
        setServiceRequestId(res.length > 0 && res[0]?.id);
      }
    });
  }, []);

  useEffect(() => {
    if (open) {
      if (parseInt(currentPage) !== nextPage) {
        currentPage == 1
          ? setOpenSRList(openSRList)
          : setOpenSRList((prev) => [...prev, ...openSRList]);
      } else {
        setOpenSRList([]);
      }
    }
    if (inProgress) {
      if (parseInt(currentPage) !== nextPage) {
        currentPage == 1
          ? setInprogressSRList(inprogressSRList)
          : setInprogressSRList((prev) => [...prev, ...inprogressSRList]);
      } else {
        setInprogressSRList([]);
      }
    }
    if (completed) {
      if (parseInt(currentPage) !== nextPage) {
        currentPage == 1
          ? setCompletedSRList(completedSRList)
          : setCompletedSRList((prev) => [...prev, ...completedSRList]);
      } else {
        setCompletedSRList([]);
      }
    }
  }, [currentPage]);
  const debouncedHandleScroll = useCallback(
    debounce((e) => {
      const { scrollTop, scrollHeight, clientHeight } = e?.target;
      if (
        scrollTop + clientHeight + 500 >= scrollHeight - 0 &&
        nextPage <= totalPages
      ) {
        try {
          setLoadingDataTest(true);
          const scrollPayload = {
            page: nextPage,
          };
          if (open) {
            Api.getServiceRequestUnassignedList(
              searchInput,
              filterData,
              scrollPayload
            ).then((res) => {
              setCurrentPage(currentPage + 1);
              setOpenSRList(res);
              setLoadingDataTest(false);
            });
          }
          if (inProgress) {
            Api.getServiceRequestAcceptedList(
              searchInput,
              filterData,
              scrollPayload
            ).then((res) => {
              setCurrentPage(currentPage + 1);
              setInprogressSRList(res);
              setLoadingDataTest(false);
            });
          }
          if (completed) {
            Api.getServiceRequestClosedList(
              searchInput,
              filterData,
              scrollPayload
            ).then((res) => {
              setCurrentPage(currentPage + 1);
              setCompletedSRList(res);
              setLoadingDataTest(false);
            });
          }
        } catch (err) {
          console.log(err);
          setLoadingDataTest(false);
        }
      }
    }, 500),
    [openSRList, nextPage, dispatch, open, inProgress, completed]
  );
  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, [loadingDataTest]);
  /* Infinity Scroll End // */
  const handleChangeRating = (e) => {
    console.log(e, "recieved change rating");
    setRating(e);
  };
  //   setLoadingData(true);
  //   setServiceListLoader(true);
  //   Api.getServiceRequestUnassignedList(searchInput, filterData).then((res) => {
  //     Api.getServiceRequestAcceptedList(searchInput, filterData).then((res) => {
  //       Api.getServiceRequestClosedList(searchInput, filterData).then((res) => {
  //         setLoadingData(false);
  //         if (res) {
  //           setCompletedSRList(res);
  //         }
  //       });
  //       if (res) {
  //         setInprogressSRList(res);
  //       }
  //     });
  //     setServiceListLoader(false);
  //     setOpenSRList(res);
  //     setSRDetailArray(res.length > 0 && [res[0]]);
  //     console.log("new", res);
  //     const sr = res.length > 0 && [res[0]];
  //     if (res.length > 0) {
  //       formatDate(sr[0]?.created_at);
  //       setServiceStatus(sr[0]?.status);
  //     }
  //     if (res && !status && !srParamsId) {
  //       setHovered(0);
  //       setServiceRequestId(res.length > 0 && res[0]?.id);
  //     }
  //   });
  //   const role = localStorage.getItem("role");
  // }, []);
  const handlePostReview = () => {
    if (rating < 1) {
      toast.error("Please select the rating!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    const payload = {
      review_type: "technician",
      rating_value: rating,
      comment: comment,
      sr_id: srId,
      user_id: techUserId,
    };
    Api.postReview(payload)
      .then((res) => {
        console.log("Review", res);
        setRatingModal(false);
        setComment("");
        setRating(4);
        Swal.fire({
          text: res,
          confirmButtonText: "OK",
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (status && srParamsId) {
      if (
        status === "unassigned" ||
        status === "assigned" ||
        status === "notified"
      ) {
        setValue(0);
        setOpen(true);
        setInprogress(false);
        setCompleted(false);
        const foundIndex = openSRList?.findIndex(
          (data) => data?.id === srParamsId
        );
        handleOpenSRById(srParamsId, foundIndex);
      }
      if (status === "accepted") {
        setValue(1);
        setOpen(false);
        setInprogress(true);
        setCompleted(false);
        const foundIndex = inprogressSRList?.findIndex(
          (data) => data?.id === srParamsId
        );
        handleInProgressSRById(srParamsId, foundIndex);
      }
      if (status === "closed" || status === "cancelled") {
        setValue(2);
        setOpen(false);
        setInprogress(false);
        setCompleted(true);
        const foundIndex = completedSRList?.findIndex(
          (data) => data?.id === srParamsId
        );
        handleCompletedSRById(srParamsId, foundIndex);
      }
    }
  }, [status, srParamsId, loadingData]);
  const handleOpenSR = () => {
    // setInprogressSRList(inProgressSRListDefault);
    // setCompletedSRList(completedSRListDefault);
    setSearchInput("");
    setFilterData({});
    setServiceRequestId("");
    Api.getServiceRequestUnassignedList().then((res) => {
      if (res) {
        setOpenSRList(res);
        handleOpenSRById(res[0]?.id, 0, res[0]);
      }
    });
  };

  const handleInProgressSR = async () => {
    // setOpenSRList(openSRListDefault);
    // setCompletedSRList(completedSRListDefault);
    setSearchInput("");
    setFilterData({});
    setServiceRequestId("");
    await Api.getServiceRequestAcceptedList().then((res) => {
      if (res) {
        setInprogressSRList(res);
        handleInProgressSRById(res[0]?.id, 0, res[0]);
      }
    });
  };

  const handleCompletedSR = () => {
    // setOpenSRList(openSRListDefault);
    // setInprogressSRList(inProgressSRListDefault);
    setSearchInput("");
    setFilterData({});
    setServiceRequestId("");
    Api.getServiceRequestClosedList().then((res) => {
      if (res) {
        console.log(res);
        setCompletedSRList(res);
        handleCompletedSRById(res[0]?.id, 0, res[0]);
      }
    });
  };

  const handleOpenSRById = (req_id, index, srDetails) => {
    setHovered(index);
    setIsOpenEdit(true);
    /*  setHovered(null); */
    setIsService(false);
    setIsVisit(false);
    setIsClosure(false);
    setIsPayment(false);
    setIsReopenSR(false);
    let sr;
    if (!srDetails) sr = openSRList?.find((item) => item?.id === req_id);
    else sr = srDetails;
    setSRDetailArray([sr]);

    setServiceRequestId(req_id);
    if (sr) {
      formatDate(sr.created_at);
      setServiceStatus(sr.status);
    }
    if (isMobile && sr) {
      window.scrollTo(0, document.body.scrollHeight);
    }
    setIsPayment(false);
  };

  const handleInProgressSRById = (req_id, index, srDetails) => {
    setIsOpenEdit(true);
    /*  setHovered(null); */
    setIsReopenSR(false);
    setIsService(false);
    setIsVisit(false);
    setIsClosure(false);
    setIsPayment(false);
    setHovered(index);
    setIsOpenEdit(true);
    let sr;
    if (!srDetails) sr = inprogressSRList?.find((item) => item?.id === req_id);
    else sr = srDetails;
    setSRDetailArray([sr]);
    setServiceRequestId(req_id);
    if (sr) {
      formatDate(sr?.created_at);
      setServiceStatus(sr.status);
    }
    if (isMobile && sr) {
      window.scrollTo(0, document.body.scrollHeight);
    }
    setIsPayment(false);
  };

  const handleCompletedSRById = (req_id, index, srDetails) => {
    setIsOpenEdit(true);
    setIsReopenSR(false);
    /* setHovered(null); */
    setIsService(false);
    setIsVisit(false);
    setIsClosure(false);
    setIsPayment(false);
    setHovered(index);
    setIsOpenEdit(true);
    let sr;
    if (!srDetails) sr = completedSRList?.find((item) => item?.id === req_id);
    else sr = srDetails;
    setSRDetailArray([sr]);
    setServiceRequestId(req_id);
    if (sr) {
      formatDate(sr.created_at);
      setServiceStatus(sr.status);
    }
    if (isMobile && sr) {
      window.scrollTo(0, document.body.scrollHeight);
    }
    setIsPayment(false);
  };

  const formatDate = (timestamp) => {
    console.log(timestamp, "<<<<<<<<<<<<<<<<<<timestamp<<<<<<<<<<<<<<<<<<");
    const dateObj = parseISO(timestamp);
    // return format(dateObj, 'MMM dd yyyy');
    setServiceCreatedTime(format(dateObj, "dd MMM yyyy, hh:mm a"));
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // edit SR
  const editSRDetails = () => {
    navigate(`/editService?edit=true&sr_id=${serviceRequestId}`);
  };
  const reopenSRDetails = () => {
    navigate(`/editService?edit=true&reopen=true&sr_id=${serviceRequestId}`);
  };

  //cancel SR
  const handleCancelSR = () => {
    Api.cancelServiceRequest(serviceRequestId).then((res) => {
      setCancelError(res?.response?.data);
      setCancelSuccess(res);
      const token = localStorage.getItem("token");
      dispatch(getNotificationsCount(token));
      if (res === "Service request has been successfully canceled.") {
        setCompleted(true);
        setOpen(false);
        setInprogress(false);
        setValue(2);
        Api.getServiceRequestUnassignedList(searchInput, filterData).then(
          (res) => {
            if (res) {
              setOpenSRList(res);
            }
          }
        );
        Api.getServiceRequestAcceptedList(searchInput, filterData).then(
          (res) => {
            if (res) {
              setInprogressSRList(res);
            }
          }
        );
        Api.getServiceRequestClosedList(searchInput, filterData).then((res) => {
          if (res) {
            const foundIndex = res?.findIndex(
              (data) => data?.id === serviceRequestId
            );
            setHovered(foundIndex);
            setCompletedSRList(res);
            setServiceRequestId(serviceRequestId);
            setSRDetailArray(res.length > 0 && [res[foundIndex]]);
            const sr = res.length > 0 && [res[foundIndex]];
            if (res.length > 0) {
              formatDate(sr[0]?.created_at);
              setServiceStatus(sr[0]?.status);
            }
          }
        });
      }
    });
  };
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: name ? `${name.split(" ")[0][0]}` : "",
    };
  }

  useEffect(() => {
    if (cancelError) {
      setCancelError(null);
    } else if (cancelSuccess) {
      setCancelSuccess(null);
    }
    const role = localStorage.getItem("role");
  }, [cancelError, cancelSuccess]);

  const getPaymentStatus = () => {
    const service_request_details = srDetailArray?.find(
      (val) => val?.id === serviceRequestId
    );
    if (
      service_request_details.sr_work_history[0]?.payment_status === "initiated"
    ) {
      return "initiated";
    } else if (
      service_request_details.sr_work_history[0]?.payment_status === "completed"
    ) {
      return "completed";
    } else {
      return null;
    }
  };

  const handlePayAndAcknowledge = () => {
    const payload = {
      sr_id: serviceRequestId,
    };
    setInprogressSRList([]);
    setServiceRequestId("");
    setSRDetailArray([]);
    Api.completePaymentServiceRequest(payload).then((res) => {
      const token = localStorage.getItem("token");
      dispatch(getNotificationsCount(token));
      Api.getServiceRequestUnassignedList(searchInput, filterData).then(
        (res) => {
          if (res) {
            setOpenSRList(res);
          }
        }
      );
      Api.getServiceRequestAcceptedList(searchInput, filterData).then((res) => {
        if (res) {
          window.scrollTo({ top: 0, behavior: "smooth" });
          setInprogressSRList(res);
          setHovered(0);
          setServiceRequestId(res.length > 0 && res[0]?.id);
          setSRDetailArray(res.length > 0 && [res[0]]);
          const sr = res.length > 0 && [res[0]];
          if (res.length > 0) {
            formatDate(sr[0]?.created_at);
            setServiceStatus(sr[0]?.status);
          }
        }
      });
      Api.getServiceRequestClosedList(searchInput, filterData).then((res) => {
        if (res) {
          setCompletedSRList(res);
        }
      });
    });
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string?.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name?.split(" ")[0][0]}`,
    };
  }
  const showEditCancel = () => {
    const foundSR = inprogressSRList.find((sr) => sr?.id === serviceRequestId);
    if (open) {
      // console.log("working");
      return true;
    } else if (
      foundSR?.sr_work_history[0]?.payment_status === "initiated" ||
      foundSR?.sr_work_history[0]?.payment_status === "completed"
    ) {
      return false;
    } else if (inProgress) {
      return true;
    } else {
      return false;
    }
  };
  const showReopen = () => {
    const foundSR = completedSRList.find((sr) => sr?.id === serviceRequestId);
    if (foundSR?.status === "closed") {
      return true;
    } else {
      return false;
    }
  };
  const handleSearchInput = useCallback(
    (e) => {
      setSearchInput(e);
      if (open) {
        Api.getServiceRequestUnassignedList(e, filterData).then((res) => {
          setOpenSRList(res);
          setSRDetailArray(res.length > 0 && [res[0]]);
          const sr = res.length > 0 && [res[0]];
          if (res.length > 0) {
            formatDate(sr[0]?.created_at);
            setServiceStatus(sr[0]?.status);
            setServiceRequestId(res.length > 0 && res[0]?.id);
            setHovered(0);
          } else {
            setServiceRequestId("");
          }
        });
      } else if (inProgress) {
        Api.getServiceRequestAcceptedList(e, filterData).then((res) => {
          setInprogressSRList(res);
          setSRDetailArray(res.length > 0 && [res[0]]);
          const sr = res.length > 0 && [res[0]];
          if (res.length > 0) {
            formatDate(sr[0]?.created_at);
            setServiceStatus(sr[0]?.status);
            setServiceRequestId(res.length > 0 && res[0]?.id);
            setHovered(0);
          } else {
            setServiceRequestId("");
          }
        });
      } else if (completed) {
        Api.getServiceRequestClosedList(e, filterData).then((res) => {
          setCompletedSRList(res);
          setSRDetailArray(res.length > 0 && [res[0]]);
          const sr = res.length > 0 && [res[0]];
          if (res.length > 0) {
            setHovered(0);
            formatDate(sr[0]?.created_at);
            setServiceStatus(sr[0]?.status);
            setServiceRequestId(res.length > 0 && res[0]?.id);
          } else {
            setServiceRequestId("");
          }
        });
      }
    },
    [
      setSearchInput,
      setOpenSRList,
      setSRDetailArray,
      filterData,
      formatDate,
      setServiceStatus,
      setServiceRequestId,
      setHovered,
    ]
  );
  const getReviewbyId = (revieweeId, entityId) => {
    Api.getReview(revieweeId, entityId).then((res) => {
      setRating(res[0]?.rating_value ? res[0]?.rating_value : 4);
      setComment(res[0]?.comment ? res[0]?.comment : "");
    });
  };
  // const handleSearchInput = (e) => {
  //   setSearchInput(e);
  //   if (open) {
  //     Api.getServiceRequestUnassignedList(e, filterData).then((res) => {
  //       setOpenSRList(res);
  //       setSRDetailArray(res.length > 0 && [res[0]]);
  //       const sr = res.length > 0 && [res[0]];
  //       if (res.length > 0) {
  //         formatDate(sr[0]?.created_at);
  //         setServiceStatus(sr[0]?.status);
  //         setServiceRequestId(res.length > 0 && res[0]?.id);
  //         setHovered(0);
  //       } else {
  //         setServiceRequestId("");
  //       }
  //     });
  //   } else if (inProgress) {
  //     Api.getServiceRequestAcceptedList(e, filterData).then((res) => {
  //       setInprogressSRList(res);
  //       setSRDetailArray(res.length > 0 && [res[0]]);
  //       const sr = res.length > 0 && [res[0]];
  //       if (res.length > 0) {
  //         formatDate(sr[0]?.created_at);
  //         setServiceStatus(sr[0]?.status);
  //         setServiceRequestId(res.length > 0 && res[0]?.id);
  //         setHovered(0);
  //       } else {
  //         setServiceRequestId("");
  //       }
  //     });
  //   } else if (completed) {
  //     Api.getServiceRequestClosedList(e, filterData).then((res) => {
  //       setCompletedSRList(res);
  //       setSRDetailArray(res.length > 0 && [res[0]]);
  //       const sr = res.length > 0 && [res[0]];
  //       if (res.length > 0) {
  //         setHovered(0);
  //         formatDate(sr[0]?.created_at);
  //         setServiceStatus(sr[0]?.status);
  //         setServiceRequestId(res.length > 0 && res[0]?.id);
  //       } else {
  //         setServiceRequestId("");
  //       }
  //     });
  //   }
  // };
  const handleFilterData = (e) => {
    setFilterData(e);
    if (open) {
      Api.getServiceRequestUnassignedList(searchInput, e).then((res) => {
        setOpenSRList(res);
        setSRDetailArray(res.length > 0 && [res[0]]);
        const sr = res.length > 0 && [res[0]];
        if (res.length > 0) {
          formatDate(sr[0]?.created_at);
          setServiceStatus(sr[0]?.status);
          setServiceRequestId(res.length > 0 && res[0]?.id);
          setHovered(0);
        } else {
          setServiceRequestId("");
        }
      });
    } else if (inProgress) {
      Api.getServiceRequestAcceptedList(searchInput, e).then((res) => {
        setInprogressSRList(res);
        setSRDetailArray(res.length > 0 && [res[0]]);
        const sr = res.length > 0 && [res[0]];
        if (res.length > 0) {
          formatDate(sr[0]?.created_at);
          setServiceStatus(sr[0]?.status);
          setServiceRequestId(res.length > 0 && res[0]?.id);
          setHovered(0);
        } else {
          setServiceRequestId("");
        }
      });
    } else if (completed) {
      Api.getServiceRequestClosedList(searchInput, e).then((res) => {
        setCompletedSRList(res);
        setSRDetailArray(res.length > 0 && [res[0]]);
        const sr = res.length > 0 && [res[0]];
        if (res.length > 0) {
          formatDate(sr[0]?.created_at);
          setServiceStatus(sr[0]?.status);
          setServiceRequestId(res.length > 0 && res[0]?.id);
          setHovered(0);
        } else {
          setServiceRequestId("");
        }
      });
    }
  };
  const mobileView = useMediaQuery("(min-width: 200px) and (max-width: 799px)");
  return (
    <>
      <Modal
        show={ratingModal}
        onHide={() => setRatingModal(false)}
        centered
        size="md"
        className="filter-modal"
      >
        <div className="p-3">
          <div
            style={{
              fontWeight: "600",
              fontSize: "25px",
            }}
          >
            Ratings and Reviews
          </div>
          <div
            style={{
              marginLeft: "2px",
              marginTop: "10px",
            }}
          >
            <text>
              <b>Technician Name: </b>{" "}
            </text>
            <text>{techName}</text>
          </div>
          <div
            style={{
              marginLeft: "2px",
            }}
          >
            <text>
              <b>SR #:</b>{" "}
            </text>
            <text>{srId?.slice(-6)}</text>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Ratings
              rating={rating}
              onChangeRating={handleChangeRating}
              fontSize={"40px"}
              isEditable
            />
          </div>
          <TextareaAutosize
            className="col-12"
            aria-label="Comment"
            placeholder="Write your review here..."
            minRows={3}
            style={{
              width: "100%",
              marginTop: "10px",
              padding: "8px",
            }}
            value={comment}
            onChange={(e) => {
              console.log("comment changed", e.target.value);
              setComment(e.target.value);
            }}
          />
          <div
            className="d-flex align-items-center mt-3 gap-2"
            style={{
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <button
              className="filter-apply-btn"
              onClick={() => handlePostReview()}
            >
              Post
            </button>
          </div>
        </div>
      </Modal>
      <div style={{ overflow: "hidden" }}>
        <Topbar />
        <div
          className="row container-fluid"
          style={{ paddingRight: "0px", overflow: "hidden" }}
        >
          <div className="col-2 col-12 p-0 border border-right">
            <Sidebar />
          </div>

          <div
            className="col-12 user_management"
            style={{ paddingRight: "0px", marginTop: "70px" }}
          >
            {loadingData ? (
              <div
                className="d-flex align-items-center"
                style={{ justifyContent: "center", height: "70vh" }}
              >
                <SpinnerLoader />
              </div>
            ) : (
              <div className="row" style={{ paddingRight: "0px" }}>
                <div className="col-12 mt-2 service_listing">
                  <div
                    className="m-0 sticky-top bg-white mt-2"
                    style={{ position: "sticky", zIndex: "2" }}
                  >
                    <i
                      className="bi bi-chevron-left"
                      style={{
                        fontWeight: "600",
                        fontSize: "21px",
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate(-1)}
                    ></i>
                    <text
                      className="p-0"
                      style={{
                        fontWeight: "600",
                        color: "#1E232C",
                        fontSize: "21px",
                        width: "208px",
                        height: "32px",
                        fontFamily: "Arial",
                      }}
                    >
                      My SRs
                    </text>
                    <Tabs
                      className="w-100 bg-white scroll-tabs"
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        /*  className="font-12" */
                        style={{
                          /*  fontSize: "12px",
                          fontWeight: "400", */
                          color: open ? "rgb(235, 129, 40)" : "#6A707C",
                        }}
                        label={`Open`}
                        {...a11yProps(0)}
                        onClick={() => {
                          setServiceStatus("Open");
                          setOpen(true);
                          setInprogress(false);
                          setCompleted(false);
                          handleOpenSR();
                        }}
                      />
                      <Tab
                        /*   className="font-12" */
                        style={{
                          /*   fontSize: "12px",
                          fontWeight: "400", */
                          color: inProgress ? "rgb(235, 129, 40)" : "#6A707C",
                        }}
                        label={`In Progress`}
                        {...a11yProps(1)}
                        onClick={() => {
                          setServiceStatus("Open");
                          setOpen(false);
                          setInprogress(true);
                          setCompleted(false);
                          handleInProgressSR();
                        }}
                      />
                      <Tab
                        /*  className="font-12" */
                        style={{
                          /*   fontSize: "12px",
                          fontWeight: "400", */
                          color: completed ? "rgb(235, 129, 40)" : "#6A707C",
                        }}
                        label={`Completed`}
                        {...a11yProps(2)}
                        onClick={() => {
                          setServiceStatus("Open");
                          setOpen(false);
                          setInprogress(false);
                          setCompleted(true);
                          handleCompletedSR();
                        }}
                      />
                    </Tabs>
                    {/* {console.log(completed, ">>>>>>>>>>completed")} */}
                    <FilterModule
                      inputPlaceholder={"Search by SR ID"}
                      onSearchInputChange={handleSearchInput}
                      onChangeFilterData={handleFilterData}
                      showDateMethod={completed === true ? true : false}
                      tabValue={value}
                    />
                    <div
                      className="d-flex align-items-center pt-3 res-open"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <ul>
                        <li className="" style={{ listStyle: "none" }}>
                          <Link to="/createService">
                            <Button
                              className="res-sr-btns"
                              variant="contained"
                              style={{
                                color: "white",
                                width: "110px",
                                height: "40px",
                                border: "1px solid rgb(235, 129, 40)",
                                textTransform: "none",
                                backgroundColor: "rgb(235, 129, 40)",
                              }}
                            >
                              + New SR
                            </Button>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div>
                      {/* {console.log("dataisloading:", openSRList)} */}
                      {/* <Scrollbars
                        onScroll={debouncedHandleScroll}
                        style={{ height: "calc(100vh - 312px)" }}
                      > */}
                      {/* Open */}
                      <div
                        style={{
                          maxHeight: "50vh",
                          overflowX: "scroll",
                          overflowY: "hidden",
                          overflow: "auto",
                        }}
                      >
                        <CustomTabPanel value={value} index={0}>
                          {serviceListLoader ? (
                            <SpinnerLoader2 />
                          ) : (
                            <>
                              {openSRList?.length > 0
                                ? openSRList?.map((open, index) => {
                                  // console.log("open", open);
                                  const progressPercentage = () => {
                                    if (
                                      open?.status === "accepted" &&
                                      open?.sr_work_history[0]
                                        .payment_status === null
                                    ) {
                                      return "50";
                                    } else if (
                                      open?.status === "accepted" &&
                                      open?.sr_work_history[0]
                                        ?.payment_status === "initiated"
                                    ) {
                                      return "50";
                                    } else if (
                                      open?.status === "accepted" &&
                                      open?.sr_work_history[0]
                                        ?.payment_status === "completed"
                                    ) {
                                      return "80";
                                    } else if (open?.status === "closed") {
                                      return "100";
                                    } else if (open?.status === "cancelled") {
                                      return "0";
                                    } else if (
                                      open?.sr_work_history[0]?.status ===
                                      "open"
                                    ) {
                                      return "30";
                                    } else {
                                      return "0";
                                    }
                                  };
                                  return (
                                    <div
                                      className={`d-flex border mb-2 w-100 p-2 ${hovered === index &&
                                        "boder-rgb(235, 129, 40)"
                                        }`}
                                      style={{
                                        background:
                                          hovered === index
                                            ? "linear-gradient(to right,#FFE1CA , #FFF6EF00)"
                                            : undefined,
                                        cursor: "pointer",
                                        borderRadius: "10px",
                                      }}
                                      onClick={() =>
                                        handleOpenSRById(open?.id, index)
                                      }
                                    >
                                      <div
                                        style={{
                                          position: "relative",
                                          right: "9px",
                                          display:
                                            hovered === index ? "" : "none",
                                        }}
                                      >
                                        <RectIcon />
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          marginLeft:
                                            hovered !== index && "5px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div
                                            className="d-flex"
                                            style={{ fontWeight: "600" }}
                                          >
                                            <div>SR #: </div>
                                            <div
                                              className="d-flex"
                                              style={{ marginLeft: "3px" }}
                                            >
                                              <div
                                                style={{ marginTop: "-1px" }}
                                              ></div>
                                              <div>{open?.id?.slice(-6)}</div>
                                            </div>
                                          </div>
                                          <div className="">
                                            <ProgressBar
                                              percentage={progressPercentage()}
                                            />
                                          </div>
                                        </div>
                                        <div
                                          className="d-flex"
                                          style={{
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div>
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              {/* <span
                                                style={{
                                                  fontWeight: "500",
                                                  fontSize: "13px",
                                                  marginLeft: 0,
                                                }}
                                              >
                                                {open?.appliance_name},{" "}
                                                {open?.appliance_model},{" "}
                                                {open?.appliance_size}

                                              </span> */}

                                              {open?.appliance_name &&
                                                open?.appliance_name?.length >
                                                15 ? (
                                                <Tooltip
                                                  title={open?.appliance_name}
                                                >
                                                  <span
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                      marginLeft: 0,
                                                      color: "#1E232C",
                                                      overflow: "hidden",
                                                      textOverflow:
                                                        "ellipsis",
                                                      whiteSpace: "nowrap",
                                                    }}
                                                  >
                                                    {open?.appliance_name?.substring(
                                                      0,
                                                      15
                                                    )}
                                                    ...,
                                                  </span>
                                                  <br></br>
                                                  <span>
                                                    {open?.appliance_model},{" "}
                                                    {open?.appliance_size}
                                                  </span>
                                                </Tooltip>
                                              ) : (
                                                <div>
                                                  <div
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                      marginLeft: 0,
                                                    }}
                                                  >
                                                    {open?.appliance_name}
                                                  </div>
                                                  <div
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                      marginLeft: 0,
                                                    }}
                                                  >
                                                    {open?.appliance_model},{" "}
                                                    {open?.appliance_size}
                                                  </div>
                                                </div>
                                              )}
                                            </div>

                                            <div
                                              className=""
                                              style={{
                                                fontWeight: "500",
                                                fontSize: "13px",
                                              }}
                                            >
                                              {open.fullname}
                                            </div>
                                          </div>
                                          <div>
                                            <div className="font-12">
                                              Created Date
                                            </div>
                                            <div
                                              className="d-flex gap-1"
                                              style={{
                                                fontWeight: "500",
                                                fontSize: "12px",
                                                fontWeight: "600",
                                                color: "#606060",
                                              }}
                                            >
                                              <div>
                                                <CalendarIcon />
                                              </div>
                                              <div
                                                style={{
                                                  paddingTop: "2px",
                                                }}
                                              >
                                                {moment(
                                                  open?.created_at
                                                ).format(
                                                  "DD MMM YYYY, hh:mm a"
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              position: "relative",
                                              top: "15px",
                                            }}
                                          >
                                            {hovered === index ? (
                                              <ArrowIconHighlighted />
                                            ) : (
                                              <ArrowIconDefault />
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                                : // <p className="text-center">No Data found!</p>
                                ""}
                            </>
                          )}
                        </CustomTabPanel>

                        {/* Inprogress */}
                        <CustomTabPanel value={value} index={1}>
                          <div className="mt-2">
                            <div className="row-lg" style={{ display: "flex" }}>
                              <div className="col">
                                {inprogressSRList?.length > 0
                                  ? inprogressSRList?.map((progress, index) => {
                                    // console.log("inprogess", progress);
                                    const progressPercentage = () => {
                                      if (
                                        progress?.status === "accepted" &&
                                        progress?.sr_work_history[0]
                                          ?.payment_status === null
                                      ) {
                                        return "50";
                                      } else if (
                                        progress?.status === "accepted" &&
                                        progress?.sr_work_history[0]
                                          ?.payment_status === "initiated"
                                      ) {
                                        return "50";
                                      } else if (
                                        progress?.status === "accepted" &&
                                        progress?.sr_work_history[0]
                                          ?.payment_status === "completed"
                                      ) {
                                        return "80";
                                      } else if (
                                        progress?.status === "closed"
                                      ) {
                                        return "100";
                                      } else if (
                                        progress?.status === "cancelled"
                                      ) {
                                        return "0";
                                      } else {
                                        return "0";
                                      }
                                    };
                                    return (
                                      <div
                                        className={`d-flex border mb-2 w-100 p-2 ${hovered === index &&
                                          "boder-rgb(235, 129, 40)"
                                          }`}
                                        style={{
                                          background:
                                            hovered === index
                                              ? "linear-gradient(to right,#FFE1CA , #FFF6EF00)"
                                              : undefined,
                                          cursor: "pointer",
                                          borderRadius: "10px",
                                        }}
                                        onClick={() =>
                                          handleInProgressSRById(
                                            progress?.id,
                                            index
                                          )
                                        }
                                      >
                                        <div
                                          style={{
                                            position: "relative",
                                            right: "9px",
                                            display:
                                              hovered === index ? "" : "none",
                                          }}
                                        >
                                          <RectIcon />
                                        </div>
                                        <div
                                          style={{
                                            width: "100%",
                                            marginLeft:
                                              hovered !== index && "5px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div
                                              className="d-flex"
                                              style={{ fontWeight: "600" }}
                                            >
                                              <div>SR #: </div>
                                              <div
                                                className="d-flex"
                                                style={{ marginLeft: "3px" }}
                                              >
                                                <div
                                                  style={{
                                                    marginTop: "-1px",
                                                  }}
                                                ></div>
                                                <div>
                                                  {progress?.id?.slice(-6)}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="">
                                              <ProgressBar
                                                percentage={progressPercentage()}
                                              />
                                            </div>
                                          </div>
                                          {progress?.sr_work_history
                                            ?.length === 1 && (
                                              <p>Technician</p>
                                            )}
                                          {progress?.sr_work_history?.length >
                                            1 && <p>Technician's</p>}
                                          <div
                                            className="d-flex"
                                            style={{
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                              }}
                                            >
                                              {progress &&
                                                progress.sr_work_history.slice(0, 2).map((e, index) => (
                                                  <div
                                                    key={index}
                                                    className="d-flex flex-column text-center ml--1"
                                                    style={{
                                                      width: "25%",
                                                      position: "relative"
                                                    }}
                                                  >
                                                    {e?.avatar ? (
                                                      <Avatar
                                                        className="centered-text mt-3 ms-2"
                                                        src={e?.avatar}
                                                      />
                                                    ) : (
                                                      <Avatar
                                                        {...stringAvatar(e.technician_name)}
                                                      />
                                                    )}
                                                    {index === 1 && progress.sr_work_history.length > 2 && (
                                                      <div
                                                        style={{
                                                          position: "absolute",
                                                          top: "0",
                                                          right: "0",
                                                          backgroundColor: "red",
                                                          color: "white",
                                                          borderRadius: "50%",
                                                          padding: "0.25em 0.5em",
                                                          fontSize: "0.75em",
                                                          fontWeight: "bold"
                                                        }}
                                                      >
                                                        +{progress.sr_work_history.length - 2}
                                                      </div>
                                                    )}
                                                  </div>
                                                ))}
                                            </div>
                                            {/* {progress &&
                                              progress.sr_work_history.map(
                                                (e) => {
                                                  console.log(
                                                    "eeeeeeeerrrr",
                                                    e
                                                  );
                                                  return (
                                                    <div className="d-flex flex-column text-center">
                                                      <Avatar
                                                        {...stringAvatar(
                                                          e.technician_name
                                                        )}
                                                      />
                                                      <span
                                                        style={{
                                                          fontWeight: "500",
                                                          fontSize: "13px",
                                                        }}
                                                      >
                                                        {e.technician_name}
                                                      </span>
                                                    </div>
                                                  );
                                                }
                                              )} */}
                                            <div>
                                              <div className="font-12">
                                                Created Date
                                              </div>
                                              <div
                                                className="d-flex gap-1"
                                                style={{
                                                  fontWeight: "500",
                                                  fontSize: "12px",
                                                  fontWeight: "600",
                                                  color: "#606060",
                                                }}
                                              >
                                                <div>
                                                  <CalendarIcon />
                                                </div>
                                                <div
                                                  style={{
                                                    paddingTop: "2px",
                                                  }}
                                                >
                                                  {moment(
                                                    progress?.created_at
                                                  ).format(
                                                    "DD MMM YYYY, hh:mm a"
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              style={{
                                                position: "relative",
                                                top: "15px",
                                              }}
                                            >
                                              {hovered === index ? (
                                                <ArrowIconHighlighted />
                                              ) : (
                                                <ArrowIconDefault />
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                  : // <p className="text-center">No Data found!</p>
                                  ""}
                              </div>
                            </div>
                          </div>
                        </CustomTabPanel>

                        {/* Completed */}
                        <CustomTabPanel value={value} index={2}>
                          <div className="mt-2">
                            <div className="row-lg" style={{ display: "flex" }}>
                              <div className="col">
                                {completedSRList?.length > 0
                                  ? completedSRList?.map((progress, index) => {
                                    // console.log("completed", progress);
                                    const progressPercentage = () => {
                                      if (
                                        progress.status === "accepted" &&
                                        progress.sr_work_history[0]
                                          .payment_status === null
                                      ) {
                                        return "50";
                                      } else if (
                                        progress.status === "accepted" &&
                                        progress.sr_work_history[0]
                                          .payment_status === "initiated"
                                      ) {
                                        return "50";
                                      } else if (
                                        progress.status === "accepted" &&
                                        progress.sr_work_history[0]
                                          .payment_status === "completed"
                                      ) {
                                        return "80";
                                      } else if (
                                        progress.status === "closed"
                                      ) {
                                        return "100";
                                      } else if (
                                        progress.status === "cancelled"
                                      ) {
                                        return "0";
                                      } else {
                                        return "0";
                                      }
                                    };
                                    return (
                                      <div
                                        className={`d-flex border mb-2 w-100 p-2 ${hovered === index &&
                                          "boder-rgb(235, 129, 40)"
                                          }`}
                                        style={{
                                          background:
                                            hovered === index
                                              ? "linear-gradient(to right,#FFE1CA , #FFF6EF00)"
                                              : undefined,
                                          cursor: "pointer",
                                          borderRadius: "10px",
                                        }}
                                        onClick={() =>
                                          handleCompletedSRById(
                                            progress?.id,
                                            index
                                          )
                                        }
                                      >
                                        <div
                                          style={{
                                            position: "relative",
                                            right: "9px",
                                            display:
                                              hovered === index ? "" : "none",
                                          }}
                                        >
                                          <RectIcon />
                                        </div>
                                        <div
                                          style={{
                                            width: "100%",
                                            marginLeft:
                                              hovered !== index && "5px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div
                                              className="d-flex"
                                              style={{ fontWeight: "600" }}
                                            >
                                              <div>SR #: </div>
                                              <div
                                                className="d-flex"
                                                style={{ marginLeft: "3px" }}
                                              >
                                                <div
                                                  style={{
                                                    marginTop: "-1px",
                                                  }}
                                                ></div>
                                                <div>
                                                  {progress?.id?.slice(-6)}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="">
                                              <ProgressBar
                                                percentage={progressPercentage()}
                                              />
                                            </div>
                                          </div>
                                          {progress?.sr_work_history
                                            ?.length === 1 && (
                                              <p>Technician</p>
                                            )}
                                          {progress?.sr_work_history?.length >
                                            1 && <p>Technician's</p>}
                                          <div
                                            className="d-flex"
                                            style={{
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                {progress &&
                                                  progress.sr_work_history.slice(0, 2).map((e, index) => (
                                                    <div
                                                      key={index}
                                                      className="d-flex flex-column text-center ml--1"
                                                      style={{
                                                        width: "25%",
                                                        position: "relative"
                                                      }}
                                                    >
                                                      {e?.avatar ? (
                                                        <Avatar
                                                          className="centered-text mt-3 ms-2"
                                                          src={e?.avatar}
                                                        />
                                                      ) : (
                                                        <Avatar
                                                          {...stringAvatar(e.technician_name)}
                                                        />
                                                      )}
                                                      {index === 1 && progress.sr_work_history.length > 2 && (
                                                        <div
                                                          style={{
                                                            position: "absolute",
                                                            top: "0",
                                                            right: "0",
                                                            backgroundColor: "red",
                                                            color: "white",
                                                            borderRadius: "50%",
                                                            padding: "0.25em 0.5em",
                                                            fontSize: "0.75em",
                                                            fontWeight: "bold"
                                                          }}
                                                        >
                                                          +{progress.sr_work_history.length - 2}
                                                        </div>
                                                      )}
                                                    </div>
                                                  ))}
                                                {/* {progress &&
                                                  progress.sr_work_history.map(
                                                    (e) => {
                                                      if (
                                                        progressPercentage() !==
                                                        "0"
                                                      )
                                                        return (
                                                          <div className="d-flex flex-column text-center">
                                                            <Avatar
                                                              {...stringAvatar(
                                                                e.technician_name
                                                              )}
                                                            />
                                                            <span
                                                              style={{
                                                                fontWeight:
                                                                  "500",
                                                                fontSize:
                                                                  "13px",
                                                              }}
                                                            >
                                                              {
                                                                e.technician_name
                                                              }
                                                            </span>
                                                          </div>
                                                        );
                                                    }
                                                  )} */}
                                                <div
                                                // style={{
                                                //   width: "40px",
                                                //   height: "40px",
                                                //   borderRadius: "50%",
                                                //   backgroundColor: "#ffa805",
                                                //   display: "flex",
                                                //   justifyContent: "center",
                                                //   alignItems: "center",
                                                //   paddingLeft: 2,
                                                //   marginRight: -10,
                                                // }}
                                                >
                                                  {/* {
                                                    <img
                                                      src={`https://dummyimage.com/180x180/ffa805/000000&text=${
                                                        progress?.sr_work_history[0]?.technician_name
                                                          ?.split("")[0]
                                                          ?.toUpperCase()
                                                          ? progress?.sr_work_history[0]?.technician_name
                                                              ?.split("")[0]
                                                              ?.toUpperCase()
                                                          : "U"
                                                      }`}
                                                      alt="Profile"
                                                      style={{
                                                        width: "30px",
                                                        height: "25px",
                                                        borderRadius: "50%",
                                                        backgroundColor:
                                                          "#ffedcc",
                                                        color: "#ffffff",
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        alignItems: "center",
                                                        fontSize: "14px",
                                                        fontFamily: "Roboto",
                                                        cursor: "pointer",
                                                      }}
                                                    />
}{" "}
                                                </div>
                                                      <span
                                                        style={{
                                                          fontWeight: "500",
                                                          fontSize: "13px",
                                                        marginLeft: 18,
                                                  }}
                                                >
                                                  <div className="d-flex flex-column text-center">
                                                    {progress.status ===
                                                    "cancelled" ? (
                                                      <p>
                                                        {progress.appliance_name +
                                                          ", " +
                                                          progress.appliance_model +
                                                          ", " +
                                                          progress.appliance_size}
                                                      </p>
                                                    ) : (
                                                      <>
                                                        <Avatar
                                                          {...stringAvatar(
                                                            progress
                                                              .sr_work_history[0]
                                                              .technician_name
                                                          )}
                                                        />
                                                        <span
                                                          style={{
                                                            fontWeight: "500",
                                                            fontSize: "13px",
                                                          }}
                                                        >
                                                          {
                                                            progress
                                                              .sr_work_history[0]
                                                              .technician_name
                                                          }
                                                        </span>
                                                      </>
                                                    )}
                                                  </div>
                                                  {/* {progress.status==='cancelled'
                                                    ?  ' ' :progress
                                                        ?.sr_work_history[0]
                                                        ?.technician_name
                                                    }{" "} */}
                                                </div>
                                              </div>

                                              <div
                                                className=""
                                                style={{
                                                  fontWeight: "500",
                                                  fontSize: "13px",
                                                }}
                                              >
                                                {progress?.fullname}
                                              </div>
                                            </div>
                                            {progress?.sr_work_history[0]
                                              ?.closed_at ? (
                                              <div>
                                                <div className="font-12">
                                                  {/* Closed Date */}Created
                                                  date
                                                </div>
                                                <div
                                                  className="d-flex gap-1"
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "12px",
                                                    fontWeight: "600",
                                                    color: "#606060",
                                                  }}
                                                >
                                                  <div>
                                                    <CalendarIcon />
                                                  </div>
                                                  <div
                                                    style={{
                                                      paddingTop: "2px",
                                                    }}
                                                  >
                                                    {/* {moment(
                                                      progress
                                                        ?.sr_work_history[0]
                                                        ?.closed_at
                                                    ).format(
                                                      "DD MMM YYYY, hh:mm a"
                                                    )} */}
                                                    {moment(
                                                      progress?.created_at
                                                    ).format(
                                                      "DD MMM YYYY, hh:mm a"
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <div>
                                                <div className="font-12">
                                                  Created Date
                                                </div>
                                                <div
                                                  className="d-flex gap-1"
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "12px",
                                                    fontWeight: "600",
                                                    color: "#606060",
                                                  }}
                                                >
                                                  <div>
                                                    <CalendarIcon />
                                                  </div>
                                                  <div
                                                    style={{
                                                      paddingTop: "2px",
                                                    }}
                                                  >
                                                    {moment(
                                                      progress?.created_at
                                                    ).format(
                                                      "DD MMM YYYY, hh:mm a"
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                            <div
                                              style={{
                                                position: "relative",
                                                top: "15px",
                                              }}
                                            >
                                              {hovered === index ? (
                                                <ArrowIconHighlighted />
                                              ) : (
                                                <ArrowIconDefault />
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                  : // <p className="text-center">No Data found!</p>
                                  ""}
                              </div>
                            </div>
                          </div>
                        </CustomTabPanel>
                      </div>
                      {/* </Scrollbars> */}
                    </div>
                  </div>
                </div>
                {/* Check - Service Request Id */}
                {serviceRequestId &&
                  srDetailArray?.length > 0 &&
                  srDetailArray?.find((val) => val?.id === serviceRequestId)
                    ?.status !== "cancelled" ? (
                  <div className="col-7 col-12 service_details">
                    <div
                      className="d-flex align-items-center pt-3"
                      style={{ justifyContent: "flex-end" }}
                    ></div>
                    <div className="p-lg-2">
                      <div className="row">
                        <div className="col text-start">
                          {serviceRequestId ? (
                            <div>
                              <p
                                className="m-0 res-font"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  color: "#1E232C",
                                }}
                              >
                                <div className="d-flex align-items-center">
                                  <div>SR #:</div>
                                  <div className="d-flex align-items-center">
                                    <div
                                      style={{
                                        marginTop: "-3px",
                                        marginLeft: "3px",
                                      }}
                                    ></div>
                                    <div>{serviceRequestId?.slice(-6)}</div>
                                  </div>
                                </div>
                              </p>
                              {srDetailArray[0]?.status === "closed" ? (
                                <>
                                  <p
                                    className="m-0"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "gray",
                                    }}
                                  >
                                    Closed On:{" "}
                                    <b style={{ color: "#000" }}>
                                      {moment(
                                        srDetailArray[0]?.sr_work_history[0]
                                          ?.closed_at
                                      ).format("DD MMM yyyy, hh:mm a")}
                                    </b>
                                  </p>
                                  <div
                                    style={{
                                      textDecoration: "underline",
                                      textDecorationColor: "rgb(235, 129, 40)",
                                      cursor: "pointer",
                                      color: "rgb(235, 129, 40)",
                                    }}
                                    onClick={() => {
                                      getReviewbyId(
                                        srDetailArray[0]?.sr_work_history[0]
                                          ?.tech_user_id,
                                        srDetailArray[0]?.id
                                      );
                                      setSrId(srDetailArray[0]?.id);
                                      setTechName(
                                        srDetailArray[0]?.sr_work_history[0]
                                          ?.technician_name
                                      );
                                      setTechUserId(
                                        srDetailArray[0]?.sr_work_history[0]
                                          ?.tech_user_id
                                      );
                                      setRatingModal(true);
                                    }}
                                    onMouseOver={(e) =>
                                    (e.currentTarget.style.color =
                                      "darkorange")
                                    }
                                    onMouseOut={(e) =>
                                    (e.currentTarget.style.color =
                                      "rgb(235, 129, 40)")
                                    }
                                  >
                                    <div>
                                      <span>
                                        <FaRegStar size={18} />
                                      </span>{" "}
                                      <span>Rate and Review</span>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "gray",
                                  }}
                                >
                                  Created On:{" "}
                                  <b style={{ color: "#000" }}>
                                    {serviceCreatedTime}
                                  </b>
                                </p>
                              )}
                            </div>
                          ) : null}
                        </div>
                        <div className="col text-end mt-2">
                          <ul
                            className="p-0"
                            style={{ display: "inline-flex" }}
                          >
                            <li style={{ listStyle: "none" }}>
                              {showEditCancel() ? (
                                <Button
                                  variant="outlined"
                                  onClick={editSRDetails}
                                  className="me-2 res-sr-btns sr-edit"
                                  style={{
                                    color: "rgb(235, 129, 40)",
                                    width: "100px",
                                    height: "40px",
                                    border: "1px solid rgb(235, 129, 40)",
                                    textTransform: "none",
                                  }}
                                >
                                  Edit
                                </Button>
                              ) : null}
                            </li>
                            <li style={{ listStyle: "none" }}>
                              {showEditCancel() ? (
                                <Button
                                  variant="outlined"
                                  className="res-sr-btns me-2 sr-edit"
                                  onClick={() => setIsAlertCancelSRModal(true)}
                                  style={{
                                    color: "rgb(235, 129, 40)",
                                    width: "100px",
                                    height: "40px",
                                    border: "1px solid rgb(235, 129, 40)",
                                    textTransform: "none",
                                  }}
                                >
                                  Cancel
                                </Button>
                              ) : null}
                              {/* {completed && (
                                <Button
                                  variant="outlined"
                                  className="res-sr-btns"
                                  onClick={editSRDetails}
                                  // onClick={() => reOpenSRbyId(serviceRequestId)}
                                  style={{
                                    color: "rgb(235, 129, 40)",
                                    width: "100px",
                                    height: "40px",
                                    border: "1px solid rgb(235, 129, 40)",
                                    textTransform: "none",
                                  }}
                                >
                                  Re-Open
                                </Button>
                              )} */}
                            </li>
                            <li style={{ listStyle: "none" }}>
                              {showReopen() ? (
                                <Button
                                  variant="outlined"
                                  className="res-sr-btns"
                                  onClick={() => reopenSRDetails()}
                                  style={{
                                    color: "rgb(235, 129, 40)",
                                    width: "100px",
                                    height: "40px",
                                    border: "1px solid rgb(235, 129, 40)",
                                    textTransform: "none",
                                  }}
                                >
                                  Reopen
                                </Button>
                              ) : null}
                              {/* {completed && (
                                <Button
                                  variant="outlined"
                                  className="res-sr-btns"
                                  onClick={editSRDetails}
                                  // onClick={() => reOpenSRbyId(serviceRequestId)}
                                  style={{
                                    color: "rgb(235, 129, 40)",
                                    width: "100px",
                                    height: "40px",
                                    border: "1px solid rgb(235, 129, 40)",
                                    textTransform: "none",
                                  }}
                                >
                                  Re-Open
                                </Button>
                              )} */}
                            </li>
                          </ul>
                        </div>
                      </div>

                      {/* service request creation */}
                      <div className="p-0 mt-3 d-flex">
                        <div style={{ marginTop: "12px" }}>
                          <div
                            className="w-20 rounded-circle"
                            style={{
                              backgroundColor: "#36A93F",
                              height: "13px",
                              width: "13px",
                            }}
                          ></div>
                        </div>
                        <div
                          className={`${isService ? "is-service-b-shadow-unset" : ""
                            }`}
                          style={{ width: "100%" }}
                        >
                          <Accordion
                            elevation={1}
                            style={{
                              marginLeft: "10px",
                            }}
                          >
                            <AccordionSummary
                              // expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              onClick={() => {
                                setIsService(!isService);
                              }}
                            >
                              <Typography>
                                Service Request Creation
                                {isService ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <ExpandMoreIcon />
                                )}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails className="accordian_details">
                              <div className="row p-2 pb-0 ps-0">
                                <div className="col-1 text-end p-0">
                                  <DoneIcon
                                    style={{
                                      color: "#36A93F",
                                      fontSize: "12px",
                                      // marginRight: "6px",
                                    }}
                                  />
                                </div>
                                <div className="col p-0 text-start">
                                  <p
                                    className=" p-0"
                                    style={{
                                      fontSize: "14px",
                                      color: "#36A93F",
                                      marginLeft: "6px",
                                    }}
                                  >
                                    SR Details
                                  </p>
                                </div>
                                {/* <div className="col p-0 text-end">
                                                                <p className="m-0 p-0 ms-2" style={{ fontSize: '12px', color: '#6B6D70' }}>15 mins ago</p>
                                                            </div> */}
                              </div>

                              <div className="row p-2 ps-0">
                                <div className="col-1 text-center p-0 with-vertical-line"></div>
                                <div className="col p-0 text-start">
                                  <table className="w-100 border-0">
                                    {srDetailArray?.length > 0 ? (
                                      srDetailArray?.map((srDetail, index) => (
                                        <tbody>
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Device:
                                          </p>
                                          <p
                                            className="m-0 pe-2"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail?.appliance_name
                                              ? srDetail?.appliance_name
                                              : ""}
                                          </p>
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Brand:
                                          </p>
                                          <p
                                            className="m-0 pe-2"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail?.appliance_brand
                                              ? srDetail?.appliance_brand
                                              : ""}
                                          </p>

                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Model:
                                          </p>

                                          <p
                                            className="m-0 pe-2"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail?.appliance_model
                                              ? srDetail?.appliance_model
                                              : ""}
                                          </p>

                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Size:
                                          </p>

                                          <p
                                            className="m-0 pe-2"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail?.appliance_size &&
                                              srDetail?.appliance_size +
                                              " " +
                                              srDetail?.appliance_unit_of_size}
                                          </p>

                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Warranty Status:
                                          </p>

                                          <p
                                            className="m-0 pe-2"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail?.warranty_status
                                              ? srDetail?.warranty_status ===
                                                "inactive"
                                                ? "InActive"
                                                : "Active"
                                              : ""}
                                          </p>

                                          {srDetail?.warranty_status ===
                                            "active" && (
                                              <div>
                                                <p
                                                  className="m-0"
                                                  style={{
                                                    color: "black",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  Warranty Untill:
                                                </p>

                                                <p
                                                  className="m-0 pe-2"
                                                  style={{
                                                    color: "black",
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                  }}
                                                >
                                                  {srDetail?.warranty_date
                                                    ? moment(
                                                      srDetail?.warranty_date
                                                    ).format("DD-MM-YYYY")
                                                    : ""}
                                                </p>
                                              </div>
                                            )}
                                        </tbody>
                                      ))
                                    ) : (
                                      <p>No data</p>
                                    )}
                                  </table>
                                </div>
                              </div>
                              <div className="row p-2 pb-0 ps-0">
                                <div className="col-1 text-end p-0">
                                  <DoneIcon
                                    style={{
                                      color: "#36A93F",
                                      fontSize: "12px",
                                      // marginRight: "6px",
                                    }}
                                  />
                                </div>
                                <div className="col p-0 text-start">
                                  <p
                                    className="p-0"
                                    style={{
                                      fontSize: "14px",
                                      color: "#36A93F",
                                      marginLeft: "6px",
                                    }}
                                  >
                                    Contact Details
                                  </p>
                                </div>
                              </div>
                              <div className="row p-2 ps-0">
                                <div className="col-1 text-center p-0 with-vertical-line"></div>
                                <div className="col p-0 text-start">
                                  <table className="w-100 border-0">
                                    {srDetailArray?.length > 0 ? (
                                      srDetailArray?.map((srDetail, index) => (
                                        <tbody>
                                          <tr style={{ height: "35px" }}>
                                            {/* <td className="text-start"> */}
                                            <p
                                              className="m-0"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              <b>Name:</b>
                                            </p>
                                            {/* </td> */}
                                            {/* <td className="text-start"> */}
                                            <p
                                              className="m-0 pe-2"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {srDetail?.serviceLocation
                                                ? srDetail?.serviceLocation.name
                                                : ""}
                                            </p>
                                            {/* </td> */}
                                          </tr>
                                          <tr
                                            style={{
                                              height: "35px",
                                            }}
                                          >
                                            {/* <td className="text-start"> */}
                                            <p
                                              className="m-0"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                marginTop: 20,
                                              }}
                                            >
                                              <b>Address:</b>
                                            </p>
                                            {/* </td> */}
                                            {/* <td className="text-end"> */}
                                            <p
                                              className="m-0 pe-2"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {srDetail?.serviceLocation
                                                ? srDetail?.serviceLocation
                                                  .address
                                                : ""}
                                            </p>
                                            {/* </td> */}
                                          </tr>
                                          <tr style={{ height: "35px" }}>
                                            {/* <td className="text-start"> */}
                                            <p
                                              className="m-0"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              <b>District:</b>
                                            </p>
                                            {/* </td> */}
                                            {/* <td className="text-end"> */}
                                            <p
                                              className="m-0 pe-2"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {srDetail?.serviceLocation
                                                ? srDetail?.serviceLocation
                                                  .city_name
                                                : ""}
                                            </p>
                                            {/* </td> */}
                                          </tr>
                                          <tr style={{ height: "35px" }}>
                                            {/* <td className="text-start"> */}
                                            <p
                                              className="m-0"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              <b>State:</b>
                                            </p>
                                            {/* </td> */}
                                            {/* <td className="text-end"> */}
                                            <p
                                              className="m-0 pe-2"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {srDetail?.serviceLocation
                                                ? srDetail?.serviceLocation
                                                  .state_name
                                                : ""}
                                            </p>
                                            {/* </td> */}
                                          </tr>
                                          <tr style={{ height: "35px" }}>
                                            {/* <td className="text-start"> */}
                                            <p
                                              className="m-0"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              <b>Postal Code:</b>
                                            </p>
                                            {/* </td> */}
                                            {/* <td className="text-end"> */}
                                            <p
                                              className="m-0 pe-2"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {srDetail?.serviceLocation
                                                ? srDetail?.serviceLocation
                                                  .postalcode
                                                : ""}
                                            </p>
                                            {/* </td> */}
                                          </tr>
                                          <tr style={{ height: "35px" }}>
                                            {/* <td className="text-start"> */}
                                            <p
                                              className="m-0"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              <b>Phone Number:</b>
                                            </p>
                                            {/* </td> */}
                                            {/* <td className="text-end"> */}
                                            <p
                                              className="m-0 pe-2"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {srDetail?.serviceLocation
                                                ? srDetail?.serviceLocation
                                                  .mobile_no
                                                : ""}
                                            </p>
                                            {/* </td> */}
                                          </tr>
                                        </tbody>
                                      ))
                                    ) : (
                                      <p>No data</p>
                                    )}
                                  </table>
                                </div>
                              </div>
                              {(srDetailArray?.find(
                                (val) => val?.id === serviceRequestId
                              )?.problem_desc ||
                                srDetailArray?.find(
                                  (val) => val?.id === serviceRequestId
                                )?.attachments?.length > 0) && (
                                  <div className="row p-2 pb-0 ps-0">
                                    <div className="col-1 text-end p-0">
                                      <DoneIcon
                                        style={{
                                          color: "#36A93F",
                                          fontSize: "12px",
                                          marginRight: "6px",
                                        }}
                                      />
                                    </div>
                                    <div className="col p-0 text-start">
                                      <p
                                        className="m-0 p-0 ms-2"
                                        style={{
                                          fontSize: "14px",
                                          color: "#36A93F",
                                        }}
                                      >
                                        Complaint Details
                                      </p>
                                    </div>
                                    {/* <div className="col p-0 text-end">
                                                                <p className="m-0 p-0 ms-2" style={{ fontSize: '12px', color: '#6B6D70' }}>15 mins ago</p>
                                                            </div> */}
                                  </div>
                                )}
                              {(srDetailArray?.find(
                                (val) => val?.id === serviceRequestId
                              )?.problem_desc ||
                                srDetailArray?.find(
                                  (val) => val?.id === serviceRequestId
                                )?.attachments?.length > 0) && (
                                  <div className="row p-2 pb-0 ps-0 mb-2">
                                    <div className="col-1 text-center p-0 with-vertical-line"></div>
                                    <div className="col p-0 text-start">
                                      <table className="w-100 border-0">
                                        {srDetailArray?.length > 0 ? (
                                          srDetailArray?.map(
                                            (srDetail, index) => (
                                              <tbody>
                                                {srDetailArray?.find(
                                                  (val) =>
                                                    val?.id === serviceRequestId
                                                )?.problem_desc && (
                                                    <tr style={{ height: "35px" }}>
                                                      {/* <td className="text-start"> */}
                                                      <p
                                                        className="m-0"
                                                        style={{
                                                          color: "black",
                                                          fontSize: "14px",
                                                          fontWeight: "400",
                                                        }}
                                                      >
                                                        <b>Description:</b>
                                                      </p>
                                                      {/* </td> */}
                                                      {/* <td className="text-end"> */}
                                                      <p
                                                        className="m-0 pe-2"
                                                        style={{
                                                          color: "black",
                                                          fontSize: "14px",
                                                          fontWeight: "400",
                                                        }}
                                                      >
                                                        {srDetail?.problem_desc
                                                          ? srDetail?.problem_desc
                                                          : ""}
                                                      </p>
                                                      {/* </td> */}
                                                    </tr>
                                                  )}
                                                {srDetailArray?.find(
                                                  (val) =>
                                                    val?.id === serviceRequestId
                                                )?.attachments.length > 0 && (
                                                    <tr style={{ height: "35px" }}>
                                                      <p
                                                        className="m-0"
                                                        style={{
                                                          color: "black",
                                                          fontSize: "14px",
                                                          fontWeight: "400",
                                                        }}
                                                      >
                                                        <b>Attachments:</b>
                                                      </p>
                                                      <button
                                                        onClick={() =>
                                                          setImageModal(true)
                                                        }
                                                        className="m-0 pe-2 items-btn"
                                                        style={{
                                                          color: "black",
                                                          fontSize: "12px",
                                                          fontWeight: "400",
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        {srDetail?.attachments
                                                          ? srDetail?.attachments
                                                            .length > 0
                                                          : ""}{" "}
                                                        <IoMdPhotos className="img-icon" />{" "}
                                                        Item(s)
                                                      </button>
                                                    </tr>
                                                  )}
                                              </tbody>
                                            )
                                          )
                                        ) : (
                                          <p>No data</p>
                                        )}
                                      </table>
                                    </div>
                                  </div>
                                )}
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </div>
                      {srDetailArray[0]?.sr_work_history?.length > 1 && (
                        <div className="p-0 mt-3 d-flex">
                          <div style={{ marginTop: "12px" }}>
                            <div
                              className="w-20 rounded-circle"
                              style={{
                                backgroundColor: "#36A93F",
                                height: "13px",
                                width: "13px",
                              }}
                            ></div>
                          </div>
                          <div
                            className={`${isReopenSR ? "is-visit-b-shadow-unset" : ""
                              }`}
                            style={{ width: "100%" }}
                          >
                            <Accordion
                              elevation={1}
                              style={{
                                marginLeft: "10px",
                              }}
                            >
                              <AccordionSummary
                                // expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                onClick={() => setIsReopenSR(!isReopenSR)}
                              >
                                <Typography>
                                  <Typography>
                                    History{" "}
                                    {isReopenSR ? (
                                      <KeyboardArrowUpIcon />
                                    ) : (
                                      <ExpandMoreIcon />
                                    )}
                                  </Typography>
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails className="accordian_details">
                                <div className="p-3 pt-0">
                                  {srDetailArray[0]?.sr_work_history?.map((history, index) => (
                                    <div
                                      key={index}
                                      className="mt-2 mb-2"
                                      style={{ fontSize: "13px" }}
                                    >
                                      <div
                                        className="align-items-center"
                                        style={{ width: "100%" }}
                                      >
                                        <div style={{ fontWeight: 600 }}>
                                          Requested visit date:
                                        </div>
                                        <div style={{ fontSize: "12px" }}>
                                          {moment(history?.requested_visit_date).format("DD MMM YYYY")}
                                        </div>
                                      </div>
                                      <div
                                        className="align-items-center"
                                        style={{ width: "100%" }}
                                      >
                                        <div style={{ fontWeight: 600 }}>
                                          Requested visit time:
                                        </div>
                                        <div style={{ fontSize: "12px" }}>
                                          {history?.requested_visit_time}
                                        </div>
                                      </div>
                                      <div
                                        className="align-items-center"
                                        style={{ width: "100%" }}
                                      >
                                        <div style={{ fontWeight: 600 }}>
                                          Technician Name:
                                        </div>
                                        <div style={{ fontSize: "12px" }}>
                                          {history?.technician_name}
                                        </div>
                                      </div>
                                      <div
                                        className="align-items-center"
                                        style={{ width: "100%" }}
                                      >
                                        <div style={{ fontWeight: 600 }}>
                                          Technician Mobile No:
                                        </div>
                                        <div style={{ fontSize: "12px" }}>
                                          {history?.mobile_no}
                                        </div>
                                      </div>
                                      <div
                                        className="align-items-center"
                                        style={{ width: "100%" }}
                                      >
                                        <div style={{ fontWeight: 600 }}>
                                          Technician Email Id:
                                        </div>
                                        <div style={{ fontSize: "12px" }}>
                                          {history?.tech_email_id}
                                        </div>
                                      </div>
                                      <div
                                        className="align-items-center"
                                        style={{ width: "100%" }}
                                      >
                                        <div style={{ fontWeight: 600 }}>
                                          Closing Notes:
                                        </div>
                                        <div style={{ fontSize: "12px" }}>
                                          {history?.closing_notes}
                                        </div>
                                      </div>
                                      <div
                                        className="align-items-center"
                                        style={{ width: "100%" }}
                                      >
                                        <div style={{ fontWeight: 600 }}>
                                          Closed On:
                                        </div>
                                        <div style={{ fontSize: "12px" }}>
                                          {moment(history?.closed_at).format("DD MMM YYYY, HH:mm A")}
                                        </div>
                                      </div>
                                      <div
                                        className="align-items-center"
                                        style={{ width: "100%" }}
                                      >
                                        <div style={{ fontWeight: 600 }}>
                                          Total amount paid:
                                        </div>
                                        <div style={{ fontSize: "12px" }}>
                                          {history?.spare_service_charge
                                            ? history?.spare_service_charge
                                            : history?.basic_charge}
                                        </div>
                                      </div>
                                      {srDetailArray[0]?.sr_work_history?.length !== index + 1 && (
                                        <div
                                          className="mt-2 mb-2"
                                          style={{
                                            height: "1px",
                                            width: "100%",
                                            backgroundColor: "#cbcbcb",
                                            borderRadius: "50px",
                                          }}
                                        />
                                      )}
                                    </div>
                                  ))}

                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        </div>
                      )}
                      {/* technician visit */}
                      <div className="p-0 mt-3 d-flex">
                        <div style={{ marginTop: "12px" }}>
                          <div
                            className="w-20 rounded-circle"
                            style={{
                              backgroundColor:
                                serviceStatus === "unassigned" ||
                                  serviceStatus === "assigned" ||
                                  serviceStatus === "notified"
                                  ? "#FCB61A"
                                  : "#36A93F",
                              height: "13px",
                              width: "13px",
                            }}
                          ></div>
                        </div>
                        <div
                          className={`${isVisit ? "is-visit-b-shadow-unset" : ""
                            }`}
                          style={{ width: "100%" }}
                        >
                          <Accordion
                            elevation={1}
                            style={{
                              marginLeft: "10px",
                            }}
                          >
                            <AccordionSummary
                              // expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              onClick={() => setIsVisit(!isVisit)}
                            >
                              <Typography>
                                {role == "Consumer" ? (
                                  <Typography>
                                    Technician Visit{" "}
                                    {isVisit ? (
                                      <ExpandMoreIcon />
                                    ) : (
                                      <KeyboardArrowUpIcon />
                                    )}
                                  </Typography>
                                ) : (
                                  <Typography>
                                    Technician Visit{" "}
                                    {isVisit ? (
                                      <KeyboardArrowUpIcon />
                                    ) : (
                                      <ExpandMoreIcon />
                                    )}
                                  </Typography>
                                )}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails className="accordian_details">
                              <div className="row p-2 pb-0 ps-0">
                                <div className="col-1 text-end p-0">
                                  <DoneIcon
                                    style={{
                                      color: "#36A93F",
                                      fontSize: "12px",
                                      // marginRight: "6px",
                                    }}
                                  />
                                </div>
                                <div className="col p-0 text-start">
                                  <p
                                    className="p-0 ms-2"
                                    style={{
                                      fontSize: "14px",
                                      color: "#36A93F",
                                      marginLeft: "6px",
                                    }}
                                  >
                                    Visit Slot
                                  </p>
                                </div>
                              </div>
                              <div className="row p-2 m-0 ps-0">
                                <div
                                  className="col-1 text-center p-0 with-vertical-line"
                                  style={{ transform: "translatex(-20%)" }}
                                ></div>
                                <div className="col p-0 text-start">
                                  <table className="w-100 border-0">
                                    <tbody>
                                      <tr style={{ height: "35px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {open
                                              ? "Requested Slot"
                                              : "Confirmed Slot"}
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {moment(
                                              srDetailArray[0]
                                                ?.sr_work_history[0]
                                                ?.requested_visit_date
                                            ).format("DD MMM YYYY")}
                                            {", "}
                                            {srDetailArray.length > 0 &&
                                              srDetailArray[0]?.sr_work_history[0]?.requested_visit_time?.toLowerCase()}
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>

                              {/* Accepted tech */}
                              {serviceStatus === "accepted" ||
                                serviceStatus === "closed" ? (
                                <div>
                                  <div className="row p-2 pb-0 ps-0">
                                    <div className="col-1 text-end p-0">
                                      <DoneIcon
                                        style={{
                                          color: "#36A93F",
                                          fontSize: "12px",
                                          // marginRight: "6px",
                                        }}
                                      />
                                    </div>
                                    <div className="col p-0 text-start">
                                      <p
                                        className=" p-0 ms-2"
                                        style={{
                                          fontSize: "14px",
                                          color: "#36A93F",
                                          marginLeft: "6px",
                                        }}
                                      >
                                        Technician Accepted
                                      </p>
                                    </div>
                                    <div className="col p-0 text-end">
                                      {/* <p className="m-0 p-0 ms-2" style={{ fontSize: '12px', color: '#6B6D70' }}>11 mins ago</p> */}
                                    </div>
                                  </div>
                                  <div className="row p-2 ps-0 m-0">
                                    <div
                                      className="col-1 text-center  p-0 with-vertical-line"
                                      style={{ transform: "translateX(-20%)" }}
                                    ></div>
                                    <div className="col p-0 text-start">
                                      <table className="w-100 border-0">
                                        <tbody>
                                          <>
                                            {/*  <div style={{ fontSize: '16px', fontWeight: '600', color: 'rgb(235, 129, 40)' }}>Technician Details:</div> */}
                                            <div
                                              className="d-flex align-items-center gap-2 mt-2"
                                              style={{ fontSize: "14px" }}
                                            >
                                              <div
                                                style={{ fontWeight: "600" }}
                                              >
                                                Technician:
                                              </div>
                                              <div>
                                                {srDetailArray?.length > 0 &&
                                                  srDetailArray[0]
                                                    ?.sr_work_history[0]
                                                    ?.technician_name}
                                              </div>
                                            </div>
                                            <div
                                              className="d-flex align-items-center gap-2"
                                              style={{ fontSize: "14px" }}
                                            >
                                              <div
                                                style={{ fontWeight: "600" }}
                                              >
                                                Mobile No:
                                              </div>
                                              <div>
                                                {srDetailArray?.length > 0 &&
                                                  srDetailArray[0]
                                                    ?.sr_work_history[0]
                                                    ?.mobile_no}
                                              </div>
                                            </div>
                                            <div
                                              className="d-flex align-items-center gap-2"
                                              style={{ fontSize: "14px" }}
                                            >
                                              <div
                                                style={{ fontWeight: "600" }}
                                              >
                                                Email:
                                              </div>
                                              <div>
                                                {srDetailArray?.length > 0 &&
                                                  srDetailArray[0]
                                                    ?.sr_work_history[0]
                                                    ?.tech_email_id}
                                              </div>
                                            </div>
                                          </>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <div className="row p-2 pb-0 ps-0">
                                    <div className="col-1 text-end p-0">
                                      <DoneIcon
                                        style={{
                                          color: "rgb(235, 129, 40)",
                                          fontSize: "12px",
                                          // marginRight: "6px",
                                        }}
                                      />
                                    </div>
                                    <div className="col p-0 text-start">
                                      <p
                                        className="m-0 p-0 ms-2"
                                        style={{
                                          fontSize: "14px",
                                          color: "rgb(235, 129, 40)",
                                          marginLeft: "6px",
                                        }}
                                      >
                                        Technician Confirmation Pending
                                      </p>
                                    </div>
                                    <div className="col p-0 text-end">
                                      <p
                                        className="m-0 p-0 ms-2"
                                        style={{
                                          fontSize: "12px",
                                          color: "#6B6D70",
                                        }}
                                      ></p>
                                    </div>
                                  </div>
                                  <div className="row p-2 ps-0 m-0">
                                    <div
                                      className="col-1 text-center  p-0 with-vertical-line-rgb(235, 129, 40)"
                                      style={{ transform: "translateX(-20%)" }}
                                    ></div>
                                    <div className="col p-0 text-start">
                                      <table className="w-100 border-0">
                                        <tbody>
                                          <tr style={{ height: "35px" }}>
                                            <td className="text-start">
                                              <p
                                                className="m-0"
                                                style={{
                                                  color: "black",
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                Please wait! A technician will
                                                get to your request shortly.
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </div>

                      {/* Closure Details */}
                      {(serviceStatus === "accepted" ||
                        serviceStatus === "closed") && (
                          <div className="p-0 mt-3 d-flex">
                            <div style={{ marginTop: "14px" }}>
                              <div
                                className="w-20 rounded-circle"
                                style={{
                                  backgroundColor: srDetailArray[0]
                                    ?.sr_work_history[0]?.payment_status
                                    ? "#36A93F"
                                    : "rgb(235, 129, 40)",
                                  height: "13px",
                                  width: "13px",
                                }}
                              ></div>
                            </div>
                            <div
                              className={`${isClosure ? "is-visit-b-shadow-unset" : ""
                                }`}
                              style={{ width: "100%" }}
                            >
                              <Accordion
                                elevation={1}
                                style={{
                                  marginLeft: "10px",
                                }}
                              >
                                <AccordionSummary
                                  // expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  onClick={() => setIsClosure(!isClosure)}
                                >
                                  <Typography>
                                    Closure Details{" "}
                                    {isClosure ? (
                                      <KeyboardArrowUpIcon />
                                    ) : (
                                      <ExpandMoreIcon />
                                    )}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails className="accordian_details">
                                  {srDetailArray?.length > 0 &&
                                    srDetailArray?.map((srDetail, index) => {
                                      return (
                                        <div>
                                          <div className="row p-2 pb-0 ps-0">
                                            <div className="col-1 text-end p-0">
                                              <DoneIcon
                                                style={{
                                                  color: srDetail
                                                    ?.sr_work_history[0]
                                                    ?.payment_status
                                                    ? "#36A93F"
                                                    : "rgb(235, 129, 40)",
                                                  fontSize: "12px",
                                                  // marginRight: "6px",
                                                }}
                                              />
                                            </div>
                                            <div className="col p-0 text-start">
                                              <p
                                                className={`p-0 ms-2 ${srDetail?.sr_work_history[0]
                                                  ?.payment_status
                                                  ? ""
                                                  : "mb-2"
                                                  }`}
                                                style={{
                                                  fontSize: "15px",
                                                  color: srDetail
                                                    ?.sr_work_history[0]
                                                    ?.payment_status
                                                    ? "#36A93F"
                                                    : "rgb(235, 129, 40)",
                                                  marginLeft: "6px",
                                                }}
                                              >
                                                {srDetail?.sr_work_history[0]
                                                  ?.payment_status
                                                  ? "Closure Details"
                                                  : "Awaiting for closure details"}
                                              </p>
                                            </div>
                                          </div>
                                          {srDetail?.sr_work_history[0]
                                            ?.payment_status && (
                                              <div className="row p-2 m-0 ps-0">
                                                <div
                                                  className={`col-1 text-center p-0 ${"with-vertical-line"}`}
                                                  style={{
                                                    transform: "translatex(-20%)",
                                                  }}
                                                ></div>
                                                <div className="col p-0 text-start">
                                                  <table className="w-100 border-0">
                                                    <tbody>
                                                      <tr
                                                        style={{ height: "35px" }}
                                                      >
                                                        <td className="text-start">
                                                          <div
                                                            style={{
                                                              fontSize: "14px",
                                                            }}
                                                          >
                                                            {
                                                              srDetail
                                                                ?.sr_work_history[0]
                                                                ?.closing_notes
                                                            }
                                                          </div>
                                                        </td>
                                                        <td className="text-end">
                                                          <p
                                                            className="m-0"
                                                            style={{
                                                              color: "black",
                                                              fontSize: "14px",
                                                              fontWeight: "400",
                                                            }}
                                                          ></p>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            )}
                                        </div>
                                      );
                                    })}
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          </div>
                        )}

                      {/* Payment Details */}
                      {(serviceStatus === "accepted" ||
                        serviceStatus === "closed") && (
                          <div className="p-0 mt-3 d-flex mb-4">
                            <div style={{ marginTop: "14px" }}>
                              <div
                                className="w-20 rounded-circle"
                                style={{
                                  backgroundColor:
                                    getPaymentStatus() === "initiated" ||
                                      getPaymentStatus() === null
                                      ? "rgb(235, 129, 40)"
                                      : "#36A93F",
                                  height: "13px",
                                  width: "13px",
                                }}
                              ></div>
                            </div>
                            <div
                              className={`${isPayment ? "is-visit-b-shadow-unset" : ""
                                }`}
                              style={{ width: "100%" }}
                            >
                              <Accordion
                                elevation={1}
                                style={{
                                  marginLeft: "10px",
                                }}
                              >
                                <AccordionSummary
                                  // expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  onClick={() => setIsPayment(!isPayment)}
                                >
                                  <Typography>
                                    Payment Details
                                    {isPayment ? (
                                      <KeyboardArrowUpIcon />
                                    ) : (
                                      <ExpandMoreIcon />
                                    )}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                  className="accordian_details"
                                  style={{ width: "100%" }}
                                >
                                  {srDetailArray?.length > 0 ? (
                                    srDetailArray?.map((srDetail, index) => {
                                      return (
                                        <div>
                                          {!srDetail?.sr_work_history[0]
                                            ?.spare_service_charge && (
                                              <div className="row p-2">
                                                <div className="col-1 text-end p-0">
                                                  <DoneIcon
                                                    style={{
                                                      color: srDetail
                                                        ?.sr_work_history[0]
                                                        ?.spare_service_charge
                                                        ? "#e3e3e3"
                                                        : "#36A93F",
                                                      fontSize: "12px",
                                                      marginRight: "6px",
                                                    }}
                                                  />
                                                </div>
                                                <div className="col ps-0 text-start">
                                                  <p
                                                    className="m-0 ps-0 ms-2"
                                                    style={{
                                                      fontSize: "14px",
                                                      color: srDetail
                                                        ?.sr_work_history[0]
                                                        ?.spare_service_charge
                                                        ? "#e3e3e3"
                                                        : "#36A93F",
                                                    }}
                                                  >
                                                    Visiting Charge (₹)
                                                  </p>
                                                </div>
                                                <div className="col p-0 text-end">
                                                  <div
                                                    className="m-0 ms-2 pe-2"
                                                    style={{
                                                      fontSize: "14px",
                                                      color: srDetail
                                                        ?.sr_work_history[0]
                                                        ?.spare_service_charge
                                                        ? "#e3e3e3"
                                                        : "#6B6D70",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        color: srDetail
                                                          ?.sr_work_history[0]
                                                          ?.spare_service_charge
                                                          ? "#e3e3e3"
                                                          : "#000",
                                                      }}
                                                    >
                                                      ₹
                                                      {
                                                        srDetail?.sr_work_history[0]
                                                          ?.basic_charge
                                                      }
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          {srDetail?.sr_work_history[0]
                                            ?.spare_service_charge && (
                                              <div className="row p-2">
                                                <div className="col-1 text-end p-0">
                                                  <DoneIcon
                                                    style={{
                                                      color: "#36A93F",
                                                      fontSize: "12px",
                                                      marginRight: "6px",
                                                    }}
                                                  />
                                                </div>
                                                <div className="col ps-0 text-start">
                                                  <p
                                                    className="m-0 ps-0 ms-2"
                                                    style={{
                                                      fontSize: "14px",
                                                      color: "#36A93F",
                                                    }}
                                                  >
                                                    Spare and Service Charges (₹)
                                                  </p>
                                                </div>
                                                <div className="col p-0 text-end">
                                                  <div
                                                    className="m-0 ms-2 pe-2"
                                                    style={{
                                                      fontSize: "14px",
                                                      color: "#6B6D70",
                                                    }}
                                                  >
                                                    <div>
                                                      ₹
                                                      {
                                                        srDetail?.sr_work_history[0]
                                                          ?.spare_service_charge
                                                      }
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          <div className="row p-2">
                                            <div className="col-1 text-end p-0"></div>
                                            <div className="col p-0 text-start">
                                              <p
                                                className="m-0 p-0 ms-2"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "500",
                                                  color: "#1E232C",
                                                }}
                                              >
                                                Total Charges (₹)
                                              </p>
                                            </div>
                                            <div
                                              className="col text-end d-flex align-items-center"
                                              style={{
                                                justifyContent: "flex-end",
                                              }}
                                            >
                                              <div
                                                className=""
                                                style={{ fontSize: "14px" }}
                                              >
                                                ₹
                                                {srDetail?.sr_work_history[0]
                                                  ?.spare_service_charge
                                                  ? srDetail?.sr_work_history[0]
                                                    ?.spare_service_charge
                                                  : srDetail?.sr_work_history[0]
                                                    ?.basic_charge}
                                              </div>
                                            </div>
                                          </div>
                                          {srDetail?.sr_work_history[0]
                                            ?.payment_status === "initiated" && (
                                              <div className="row p-2">
                                                <div
                                                  className="col pe-2 d-flex align-items-center"
                                                  style={{
                                                    justifyContent: "flex-end",
                                                  }}
                                                >
                                                  <button
                                                    className="pay-btn"
                                                    style={{
                                                      fontSize: "14px",
                                                      textAlign: "center",
                                                    }}
                                                    onClick={() =>
                                                      handlePayAndAcknowledge()
                                                    }
                                                  >
                                                    Pay and Acknowledge
                                                  </button>
                                                </div>
                                              </div>
                                            )}
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <p>No data</p>
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          </div>
                        )}

                      {/* Reopen sr */}
                    </div>
                  </div>
                ) : srDetailArray?.length > 0 &&
                  srDetailArray?.find((val) => val?.id === serviceRequestId)
                    ?.status === "cancelled" ? (
                  <div className="col-7 col-12 service_details">
                    <div className="row mt-3 p-3 mb-3">
                      <div className="col text-start">
                        {serviceRequestId ? (
                          <div>
                            <p
                              className="m-0"
                              style={{
                                fontSize: "16px",
                                fontWeight: "600",
                                color: "#1E232C",
                              }}
                            >
                              <div className=" d-flex align-items-center">
                                <div>SR #:</div>
                                <div className="d-flex align-items-center">
                                  <div
                                    style={{
                                      marginTop: "-3px",
                                      marginLeft: "3px",
                                    }}
                                  ></div>
                                  <div>{serviceRequestId?.slice(-6)}</div>
                                </div>
                              </div>
                            </p>
                            <p
                              className="m-0"
                              style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "gray",
                              }}
                            >
                              Created On:{" "}
                              <b style={{ color: "#000" }}>
                                {serviceCreatedTime}
                              </b>
                            </p>
                          </div>
                        ) : null}
                      </div>

                      <div className="p-0 mt-3 d-flex mb-3">
                        <div style={{ marginTop: "12px" }}>
                          <div
                            className="w-20 rounded-circle"
                            style={{
                              backgroundColor: "#36A93F",
                              height: "13px",
                              width: "13px",
                            }}
                          ></div>
                        </div>
                        <div
                          className={`${isService ? "is-service-b-shadow-unset" : ""
                            }`}
                          style={{ width: "100%" }}
                        >
                          <Accordion
                            elevation={1}
                            style={{
                              marginLeft: "10px",
                            }}
                          >
                            <AccordionSummary
                              // expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              onClick={() => {
                                setIsService(!isService);
                              }}
                            >
                              <Typography>
                                Service Request Creation
                                {isService ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <ExpandMoreIcon />
                                )}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails className="accordian_details">
                              <div className="row p-2 pb-0 ps-0">
                                <div className="col-1 text-end p-0">
                                  <DoneIcon
                                    style={{
                                      color: "#36A93F",
                                      fontSize: "12px",
                                      // marginRight: "6px",
                                    }}
                                  />
                                </div>
                                <div className="col p-0 text-start">
                                  <p
                                    className=" p-0"
                                    style={{
                                      fontSize: "14px",
                                      color: "#36A93F",
                                      marginLeft: "6px",
                                    }}
                                  >
                                    SR Details
                                  </p>
                                </div>
                                {/* <div className="col p-0 text-end">
                                                                <p className="m-0 p-0 ms-2" style={{ fontSize: '12px', color: '#6B6D70' }}>15 mins ago</p>
                                                            </div> */}
                              </div>
                              <div className="row p-2 ps-0">
                                <div className="col-1 text-center p-0 with-vertical-line"></div>
                                <div className="col p-0 text-start">
                                  <table className="w-100 border-0">
                                    {srDetailArray?.length > 0 ? (
                                      srDetailArray?.map((srDetail, index) => (
                                        <tbody>
                                          <tr style={{ height: "35px" }}>
                                            <td className="text-start">
                                              <p
                                                className="m-0"
                                                style={{
                                                  color: "black",
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                Device
                                              </p>
                                            </td>
                                            <td className="text-end">
                                              <p
                                                className="m-0 pe-2"
                                                style={{
                                                  color: "black",
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                {srDetail?.appliance_name
                                                  ? srDetail.appliance_name
                                                  : ""}
                                              </p>
                                            </td>
                                          </tr>
                                          <tr style={{ height: "35px" }}>
                                            <td className="text-start">
                                              <p
                                                className="m-0"
                                                style={{
                                                  color: "black",
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                Brand
                                              </p>
                                            </td>
                                            <td className="text-end">
                                              <p
                                                className="m-0 pe-2"
                                                style={{
                                                  color: "black",
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                {srDetail?.appliance_brand
                                                  ? srDetail?.appliance_brand
                                                  : ""}
                                              </p>
                                            </td>
                                          </tr>
                                          <tr style={{ height: "35px" }}>
                                            <td className="text-start">
                                              <p
                                                className="m-0"
                                                style={{
                                                  color: "black",
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                Model
                                              </p>
                                            </td>
                                            <td className="text-end">
                                              <p
                                                className="m-0 pe-2"
                                                style={{
                                                  color: "black",
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                {srDetail?.appliance_model
                                                  ? srDetail?.appliance_model
                                                  : ""}
                                              </p>
                                            </td>
                                          </tr>
                                          <tr style={{ height: "35px" }}>
                                            <td className="text-start">
                                              <p
                                                className="m-0"
                                                style={{
                                                  color: "black",
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                Size
                                              </p>
                                            </td>
                                            <td className="text-end">
                                              <p
                                                className="m-0 pe-2"
                                                style={{
                                                  color: "black",
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                {srDetail?.appliance_size &&
                                                  srDetail?.appliance_size +
                                                  " " +
                                                  srDetail?.appliance_unit_of_size}
                                              </p>
                                            </td>
                                          </tr>
                                          <tr style={{ height: "35px" }}>
                                            <td className="text-start">
                                              <p
                                                className="m-0"
                                                style={{
                                                  color: "black",
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                Warranty Status
                                              </p>
                                            </td>
                                            <td className="text-end">
                                              <p
                                                className="m-0 pe-2"
                                                style={{
                                                  color: "black",
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                {srDetail?.warranty_status
                                                  ? srDetail?.warranty_status ===
                                                    "inactive"
                                                    ? "InActive"
                                                    : "Active"
                                                  : ""}
                                              </p>
                                            </td>
                                          </tr>
                                          {srDetail?.warranty_status ===
                                            "active" && (
                                              <tr style={{ height: "35px" }}>
                                                <td className="text-start">
                                                  <p
                                                    className="m-0"
                                                    style={{
                                                      color: "black",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                    }}
                                                  >
                                                    Warranty Untill
                                                  </p>
                                                </td>
                                                <td className="text-end">
                                                  <p
                                                    className="m-0 pe-2"
                                                    style={{
                                                      color: "black",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                    }}
                                                  >
                                                    {srDetail?.warranty_date
                                                      ? srDetail?.warranty_date
                                                      : ""}
                                                  </p>
                                                </td>
                                              </tr>
                                            )}
                                        </tbody>
                                      ))
                                    ) : (
                                      <p>No data</p>
                                    )}
                                  </table>
                                </div>
                              </div>
                              <div className="row p-2 pb-0 ps-0">
                                <div className="col-1 text-end p-0">
                                  <DoneIcon
                                    style={{
                                      color: "#36A93F",
                                      fontSize: "12px",
                                      // marginRight: "6px",
                                    }}
                                  />
                                </div>
                                <div className="col p-0 text-start">
                                  <p
                                    className="p-0"
                                    style={{
                                      fontSize: "14px",
                                      color: "#36A93F",
                                      marginLeft: "6px",
                                    }}
                                  >
                                    Contact Details
                                  </p>
                                </div>
                              </div>
                              <div className="row p-2 ps-0">
                                <div className="col-1 text-center p-0 with-vertical-line"></div>
                                <div className="col p-0 text-start">
                                  <table className="w-100 border-0">
                                    {srDetailArray?.length > 0 ? (
                                      srDetailArray?.map((srDetail, index) => (
                                        <tbody>
                                          <tr style={{ height: "35px" }}>
                                            {/* <td className="text-start"> */}
                                            <p
                                              className="m-0"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              <b>Name:</b>
                                            </p>
                                            {/* </td> */}
                                            {/* <td className="text-start"> */}
                                            <p
                                              className="m-0 pe-2"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {srDetail?.serviceLocation
                                                ? srDetail?.serviceLocation
                                                  ?.name
                                                : ""}
                                            </p>
                                            {/* </td> */}
                                          </tr>
                                          <tr
                                            style={{
                                              height: "35px",
                                            }}
                                          >
                                            {/* <td className="text-start"> */}
                                            <p
                                              className="m-0"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                marginTop: 20,
                                              }}
                                            >
                                              <b>Address:</b>
                                            </p>
                                            {/* </td> */}
                                            {/* <td className="text-end"> */}
                                            <p
                                              className="m-0 pe-2"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {srDetail?.serviceLocation
                                                ? srDetail?.serviceLocation
                                                  .address
                                                : ""}
                                            </p>
                                            {/* </td> */}
                                          </tr>
                                          <tr style={{ height: "35px" }}>
                                            {/* <td className="text-start"> */}
                                            <p
                                              className="m-0"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              <b>District:</b>
                                            </p>
                                            {/* </td> */}
                                            {/* <td className="text-end"> */}
                                            <p
                                              className="m-0 pe-2"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {srDetail?.serviceLocation
                                                ? srDetail?.serviceLocation
                                                  .city_name
                                                : ""}
                                            </p>
                                            {/* </td> */}
                                          </tr>
                                          <tr style={{ height: "35px" }}>
                                            {/* <td className="text-start"> */}
                                            <p
                                              className="m-0"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              <b>State:</b>
                                            </p>
                                            {/* </td> */}
                                            {/* <td className="text-end"> */}
                                            <p
                                              className="m-0 pe-2"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {srDetail?.serviceLocation
                                                ? srDetail?.serviceLocation
                                                  .state_name
                                                : ""}
                                            </p>
                                            {/* </td> */}
                                          </tr>
                                          <tr style={{ height: "35px" }}>
                                            {/* <td className="text-start"> */}
                                            <p
                                              className="m-0"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              <b>Postal Code:</b>
                                            </p>
                                            {/* </td> */}
                                            {/* <td className="text-end"> */}
                                            <p
                                              className="m-0 pe-2"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {srDetail?.serviceLocation
                                                ? srDetail?.serviceLocation
                                                  ?.postalcode
                                                : ""}
                                            </p>
                                            {/* </td> */}
                                          </tr>
                                          <tr style={{ height: "35px" }}>
                                            {/* <td className="text-start"> */}
                                            <p
                                              className="m-0"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              <b>Phone Number:</b>
                                            </p>
                                            {/* </td> */}
                                            {/* <td className="text-end"> */}
                                            <p
                                              className="m-0 pe-2"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {srDetail?.serviceLocation
                                                ? srDetail?.serviceLocation
                                                  ?.mobile_no
                                                : ""}
                                            </p>
                                            {/* </td> */}
                                          </tr>
                                        </tbody>
                                      ))
                                    ) : (
                                      <p>No data</p>
                                    )}
                                  </table>
                                </div>
                              </div>
                              {srDetailArray?.find(
                                (val) => val?.id === serviceRequestId
                              )?.problem_desc ||
                                (srDetailArray?.find(
                                  (val) => val?.id === serviceRequestId
                                )?.attachments?.length > 0 && (
                                    <div className="row p-2 pb-0 ps-0">
                                      <div className="col-1 text-end p-0">
                                        <DoneIcon
                                          style={{
                                            color: "#36A93F",
                                            fontSize: "12px",
                                            // marginRight: "6px",
                                          }}
                                        />
                                      </div>
                                      <div className="col p-0 text-start">
                                        <p
                                          className="p-0 ms-2"
                                          style={{
                                            fontSize: "14px",
                                            color: "#36A93F",
                                            marginLeft: "6px",
                                          }}
                                        >
                                          Complaint Details
                                        </p>
                                      </div>
                                      {/* <div className="col p-0 text-end">
                                                                <p className="m-0 p-0 ms-2" style={{ fontSize: '12px', color: '#6B6D70' }}>15 mins ago</p>
                                                            </div> */}
                                    </div>
                                  ))}
                              {srDetailArray?.find(
                                (val) => val?.id === serviceRequestId
                              )?.problem_desc ||
                                (srDetailArray?.find(
                                  (val) => val?.id === serviceRequestId
                                )?.attachments?.length > 0 && (
                                    <div className="row p-2 pb-0 ps-0 mb-2">
                                      <div className="col-1 text-center p-0 with-vertical-line"></div>
                                      <div className="col p-0 text-start">
                                        <table className="w-100 border-0">
                                          {srDetailArray?.length > 0 ? (
                                            srDetailArray?.map(
                                              (srDetail, index) => (
                                                <tbody>
                                                  {srDetailArray?.find(
                                                    (val) =>
                                                      val?.id === serviceRequestId
                                                  )?.problem_desc && (
                                                      <tr
                                                        style={{ height: "35px" }}
                                                      >
                                                        {/* <td className="text-start"> */}
                                                        <p
                                                          className="m-0"
                                                          style={{
                                                            color: "black",
                                                            fontSize: "14px",
                                                            fontWeight: "400",
                                                          }}
                                                        >
                                                          <b>Description:</b>
                                                        </p>
                                                        {/* </td> */}
                                                        {/* <td className="text-end"> */}
                                                        <p
                                                          className="m-0 pe-2"
                                                          style={{
                                                            color: "black",
                                                            fontSize: "14px",
                                                            fontWeight: "400",
                                                          }}
                                                        >
                                                          {srDetail?.problem_desc
                                                            ? srDetail?.problem_desc
                                                            : ""}
                                                        </p>
                                                        {/* </td> */}
                                                      </tr>
                                                    )}
                                                  {srDetail?.attachments
                                                    ?.length != null ||
                                                    srDetail?.attachments
                                                      ?.length != undefined ||
                                                    srDetail.attachments.length >
                                                    0(
                                                      <tr
                                                        style={{
                                                          height: "35px",
                                                        }}
                                                      >
                                                        <p
                                                          className="m-0"
                                                          style={{
                                                            color: "black",
                                                            fontSize: "14px",
                                                            fontWeight: "400",
                                                          }}
                                                        >
                                                          <b>Attachments:</b>
                                                        </p>
                                                        <button
                                                          onClick={() =>
                                                            setImageModal(true)
                                                          }
                                                          className="m-0 pe-2 items-btn"
                                                          style={{
                                                            color: "black",
                                                            fontSize: "12px",
                                                            fontWeight: "400",
                                                            cursor: "pointer",
                                                          }}
                                                        >
                                                          {srDetail?.attachments
                                                            ? srDetail
                                                              ?.attachments
                                                              ?.length
                                                            : ""}{" "}
                                                          <IoMdPhotos className="img-icon" />{" "}
                                                          Item(s)
                                                        </button>
                                                      </tr>
                                                    )}
                                                </tbody>
                                              )
                                            )
                                          ) : (
                                            <p>No data</p>
                                          )}
                                        </table>
                                      </div>
                                    </div>
                                  ))}
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        height: "200px",
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                        top: "150px",
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "#ffa5005c",
                      }}
                    >
                      This Service Request has been cancelled!
                    </div>
                  </div>
                ) : (
                  <div className="col-7 col-12 service_details">
                    <div className="row mt-3 p-3 mb-3">
                      <div className="col text-start">
                        {serviceRequestId ? (
                          <div>
                            <p
                              className="m-0"
                              style={{
                                fontSize: "16px",
                                fontWeight: "600",
                                color: "#1E232C",
                              }}
                            >
                              <div className="d-flex align-items-center">
                                <div>SR #:</div>
                                <div className="d-flex align-items-center">
                                  <div
                                    style={{
                                      marginTop: "-3px",
                                      marginLeft: "3px",
                                    }}
                                  ></div>
                                  <div>{serviceRequestId?.slice(-6)}</div>
                                </div>
                              </div>
                            </p>
                            <p
                              className="m-0"
                              style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "gray",
                              }}
                            >
                              Created On:{" "}
                              <b style={{ color: "#000" }}>
                                {serviceCreatedTime}
                              </b>
                            </p>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div
                      style={{
                        height: "200px",
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                        top: "150px",
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "#ffa5005c",
                      }}
                    >
                      No SRs found!
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={isAlertCancelSRModal}
        onHide={() => setIsAlertCancelSRModal(false)}
        centered
        className="p-1"
      >
        <div className="p-2">
          <h4 className="pt-2 text-align-center">Cancel Service Request?</h4>
          <div className="pt-2 text-align-center" style={{ fontWeight: "400" }}>
            Are you sure you want to cancel this service request?
          </div>
          <div className="text-align-center" style={{ fontWeight: "400" }}>
            This action cannot be undone.
          </div>
          <div className="button-class pb-4">
            <button
              onClick={() => setIsAlertCancelSRModal(false)}
              className="cancel"
            >
              No
            </button>
            <button
              onClick={() => {
                handleCancelSR();
                setIsAlertCancelSRModal(false);
              }}
              className="reject"
            >
              Yes, Cancel
            </button>
          </div>
        </div>
      </Modal>

      <ToastMessage
        message={cancelError}
        status={"error"}
        hideProgressBar={true}
      />
      <ToastMessage
        message={cancelSuccess}
        status={"success"}
        hideProgressBar={true}
      />
      {imageModal && (
        <Modal
          className="imageModal"
          show={imageModal}
          onHide={() => setImageModal(false)}
        >
          <div
            style={{
              backgroundColor: "white",
              width: "100%",
              maxWidth: "600px",
              overflow: "hidden",
              margin: "auto",
              borderRadius: "15px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "20px",
            }}
          >
            <div className="p-3">
              <div className="d-flex align-items-center justify-content-between">
                <div style={{ fontSize: "20px" }}>
                  {/* <div>Attached Image</div> */}
                </div>
                <div onClick={() => setImageModal(false)}>
                  <MdClose
                    style={{
                      cursor: "pointer",
                      color: "rgb(235, 129, 40)",
                      position: "relative",
                      right: "20px",
                    }}
                  />
                </div>
              </div>
              <div
                className="image-container"
                style={{
                  overflowX: "hidden",
                  maxWidth: "80vw",
                  margin: "auto",
                  whiteSpace: "nowrap",
                  position: "relative",
                }}
              >
                {srDetailArray[0]?.attachments?.length > 0 &&
                  srDetailArray[0]?.attachments?.map((imageSrc, index) => (
                    <img
                      key={index}
                      src={imageSrc}
                      alt={`Attachment ${index}`}
                      style={{
                        cursor: "pointer",
                        width: "300px",
                        height: "300px",
                        display: currentIndex === index ? "block" : "none",
                      }}
                      onClick={() => handleImageClick(index)}
                    />
                  ))}
                <Button
                  variant="link"
                  className="arrow-button left"
                  onClick={showPreviousImage}
                  style={{
                    position: "absolute",
                    top: "40%",
                    left: "10px",
                    color: "rgb(235, 129, 40)",
                    fontSize: "30px",
                  }}
                >
                  <MdKeyboardArrowLeft />
                </Button>
                <Button
                  variant="link"
                  className="arrow-button right"
                  onClick={showNextImage}
                  style={{
                    position: "absolute",
                    top: "40%",
                    right: "10px",
                    color: "rgb(235, 129, 40)",
                    fontSize: "30px",
                  }}
                >
                  <MdKeyboardArrowRight />
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
export default ServiceRequestList;
