import React from "react";

const RectIcon = () => {
  return (
    <svg
      width="5"
      height="22"
      viewBox="0 0 5 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0C2.32212 0 4.20457 1.88245 4.20457 4.20457V24.7954C4.20457 27.1175 2.32212 29 0 29V0Z"
        fill="#EB8128"
      />
    </svg>
  );
};

export default RectIcon;
