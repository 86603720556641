import React, { useEffect, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { getAllreferralChartList } from "../../../redux/actions/referralChartAction";

const ReferralChart = () => {
  const dispatch = useDispatch();
  const { referralChartListData } = useSelector(
    (state) => state.referralChartState
  );

  useEffect(() => {
    dispatch(getAllreferralChartList());
  }, [dispatch]);

  const sortedData = useMemo(() => {
    return [...referralChartListData].sort((a, b) => {
      const order = [
        "Social Media",
        "Search Engine",
        "Advertisement",
        "Referral",
        "Others",
      ];
      return order.indexOf(a.source) - order.indexOf(b.source);
    });
  }, [referralChartListData]);

  const chartOptions = useMemo(
    () => ({
      chart: {
        id: "referral-chart",
      },
      xaxis: {
        categories: sortedData.map((item) => item.source),
      },
      yaxis: {
        title: {
          text: "Count",
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      // title: {
      //   text: "Referral Chart",
      //   align: "left",
      // },
      colors: ["#eb8128", "#00E396", "#008FFB", "#775DD0", "#00E396"],
      legend: {
        position: "bottom",
        horizontalAlign: "center",
      },
    }),
    [sortedData]
  );

  const chartSeries = useMemo(
    () => [
      {
        name: "Count",
        data: sortedData.map((item) => item.count),
      },
    ],
    [sortedData]
  );

  return (
    <div id="chart">
      {referralChartListData.length ? (
        <ReactApexChart
          options={chartOptions}
          series={chartSeries}
          type="bar"
          height={310}
        />
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default ReferralChart;
