import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const rewardDateRangeSlice = createSlice({
    name: "rewardDateRange",
    initialState: {
        dateRange: [
            {
                startDate: moment().subtract(6, "days").startOf("day").toDate(),
                endDate: moment().endOf("day").toDate(),
                key: "selection",
            },
        ],
    },
    reducers: {
        setDateRange(state, action) {
            state.dateRange = action.payload;
        },
        clearDateRange(state) {
            state.dateRange = [
                {
                    startDate: moment().subtract(6, "days").startOf("day").toDate(),
                    endDate: moment().endOf("day").toDate(),
                    key: "selection",
                },
            ];
        },
    },
});

const { actions, reducer } = rewardDateRangeSlice;

export const { setDateRange, clearDateRange } = actions;
export default reducer;
