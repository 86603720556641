import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Avatar from "@mui/material";
import Badge from "@mui/material/Badge";
import S3 from "../Assets/s3_new_logo/s3_new_logo.svg";
import S3_res from "../Assets/Home/s3_new_logo_mobile.svg";
import profile from "../Images/smile.jpg";
import { Stack } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import MenuIcon from "@mui/icons-material/Menu";
import "../layout.css";
import { Paper } from "@mui/material";
import { Popper, ClickAwayListener } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Api from "../service/services";
import { BsBell } from "react-icons/bs";
import {
  getNotifications,
  getNotificationsCount,
  readAllNotifications,
  readNotification,
} from "../redux/actions/notificationsAction";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import moment from "moment";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Swal from "sweetalert2";

const Topbar = ({ isProfileUpdate }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [notificationPoper, setNotificationPoper] = React.useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [notificationsData, setNotificationsData] = useState([]);
  const { notifications } = useSelector((state) => state.notificationsState);
  const [Username, setUsername] = useState(() => {
    return localStorage.getItem("name");
  });

  const { notificationsCount } = useSelector(
    (state) => state.notificationsCountState
  );
  const dispatch = useDispatch();
  const [notificationId, setNotificationId] = useState("");
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const handleNotification = () => {
    setNotificationPoper(true);
    dispatch(getNotifications());
  };

  const handleCloseNotification = () => {
    setNotificationPoper(false);
    const token = localStorage.getItem("token");
    dispatch(getNotificationsCount(token));
    dispatch(getNotifications());
  };

  const handleCancelClick = () => {
    setNotificationPoper(false);
    setIsChecked(false);
    handleCloseNotification();
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const onClickNavigate = () => {
    navigate("/checkout");
  };

  useEffect(() => {
    setUsername(localStorage.getItem("name"));
    console.log("Profile Update Working", isProfileUpdate);
  }, [isProfileUpdate]);

  useEffect(() => {
    setNotificationsData(notifications);
  }, [notifications]);

  const getUsernameInitials = () => {
    if (!Username) return "";

    const nameParts = Username.split(" ");
    if (nameParts.length === 1) {
      return nameParts[0].slice(0, 2).toUpperCase();
    } else {
      const initials = nameParts.map((part) => part.charAt(0));
      return initials.join("").toUpperCase();
    }
  };
  //   const userInitials = getUsernameInitials(Username);
  //   useEffect(() => {
  //     loadImageFromLocalStorage();
  //   });
  const loadImageFromLocalStorage = () => {
    if (localStorage.getItem("userImage") === null) {
      return false;
    } else if (localStorage.getItem("userImage") === "null") {
      return false;
    } else if (localStorage.getItem("userImage") === "") {
      return false;
    } else {
      return localStorage.getItem("userImage");
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    // window.location.reload();
    setShowLogoutModal(false);
    navigate("/");
  };
  const homePageNavigate = () => {
    if (localStorage.getItem("role") === "DistrictAdmin") {
      navigate("/district-admin-dashboard");
    } else if (localStorage.getItem("role") === "SuperAdmin") {
      navigate("/super-admin-dashboard");
    } else if (localStorage.getItem("role") === "ZonalAdmin") {
      navigate("/zonal-admin-dashboard");
    } else if (localStorage.getItem("role") === "Technician") {
      navigate("/technicianDashboard");
    } else {
      navigate("/customerDashboard");
    }
  };
  const userImageURL =
    loadImageFromLocalStorage() ||
    `https://dummyimage.com/200x200/ffedcc/000&text=${getUsernameInitials()}`;

  return (
    <>
      <div className="sticky-top">
        <div
          className="row topbar bg-white  p-2 d-flex"
          style={{ justifyContent: "space-between" }}
        >
          <div
            className="col-lg-4 col-md-3 col-sm-4  col-5 p-lg-1"
            onClick={() => homePageNavigate()}
            style={{ cursor: "pointer" }}
          >
            <img
              className=" ms-lg-2 mt-lg-1 mt-2 h-10 w-70 res-hide"
              src={S3}
              alt="demo"
            />
            <img
              className=" ms-lg-2 mt-lg-1 mt-2 h-10 w-70 res-vis"
              src={S3_res}
              alt="demo"
            />
          </div>

          <div
            className="col-lg-3 col-md-3 col-sm-4 col-5 text-end d-flex align-items-center"
            style={{ justifyContent: "flex-end" }}
          >
            <Stack
              className="stack"
              spacing={2}
              direction="row"
              alignItems="center"
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    maxWidth: "40px",
                    maxHeight: "40px",
                    borderRadius: "50%",
                    backgroundColor: "#ffedcc",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingLeft: 2,
                    marginRight: -10,
                  }}
                >
                  {loadImageFromLocalStorage() ? (
                    <img
                      onClick={() => navigate("/profile")}
                      src={userImageURL}
                      alt="Profile"
                      style={{
                        width: "40px", // Ensuring both width and height are the same
                        height: "40px",
                        borderRadius: "50%",
                        backgroundColor: "#ffedcc",
                        color: "#ffffff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "14px",
                        fontFamily: "Arial",
                        cursor: "pointer",
                        border: "1px solid gray",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <img
                      onClick={() => navigate("/profile")}
                      src={userImageURL}
                      alt="Profile"
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        backgroundColor: "#ffedcc",
                        color: "#ffffff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "14px",
                        fontFamily: "Arial",
                        cursor: "pointer",
                      }}
                    />
                  )}{" "}
                </div>
              </div>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/profile")}
              >
                {Username}
              </span>
              {/*      <Nav className="ms-0">
                <NavDropdown
                  id=" navbar-expand"
                  title={Username}
                  menuVariant="light"
                >
                  <NavDropdown.Item
                    onClick={() => {
                      navigate("/profile");
                    }}
                  >
                    Profile
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => {
                      navigate("/resetpassword");
                    }}
                  >
                    Reset Password
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => setShowLogoutModal(true)}>
                    Log out
                  </NavDropdown.Item>
                </NavDropdown>
                <Modal
                  show={showLogoutModal}
                  onHide={() => setShowLogoutModal(false)}
                  centered
                >
                  <div className="p-3">
                   
                    <div className="pt-3 pb-4" style={{ textAlign: "center" }}>
                      Are you sure,you want to Log out?
                    </div>
                    <div
                      className="d-flex align-items-center pb-2"
                      style={{ justifyContent: "center", gap: "10px" }}
                    >
                      <Button
                        variant="secondary"
                        onClick={() => setShowLogoutModal(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={handleLogout}
                        style={{
                          backgroundColor: "rgb(235, 129, 40)",
                          color: "white",
                          border: "1px solid rgb(235, 129, 40)",
                        }}
                      >
                        Logout
                      </Button>
                    </div>
                  </div>
                </Modal>
              </Nav> */}
              <Badge color="secondary" ref={anchorRef}>
                <div className="d-flex">
                  <BsBell
                    style={
                      isMobile
                        ? {
                            maxHeight: "20px",
                            maxWidth: "20px",
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }
                        : {
                            height: "20px",
                            width: "20px",
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }
                    }
                    onClick={handleNotification}
                  />
                  {notificationsCount > 0 ? (
                    <div
                      style={{
                        backgroundColor: notificationsCount > 0 && "red",
                        height: "10px",
                        width: "10px",
                        borderRadius: notificationsCount > 0 && "100%",
                        position: "relative",
                        right: "10px",
                        border:
                          notificationsCount > 0 &&
                          "2px solid rgb(255, 255, 255)",
                      }}
                    ></div>
                  ) : (
                    <></>
                  )}
                </div>
                <Popper
                  open={notificationPoper}
                  anchorEl={anchorRef.current}
                  placement="bottom-start"
                  transition
                  disablePortal
                  style={{ zIndex: "1", marginTop: "10px" }}
                >
                  {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleCloseNotification}>
                      <Paper
                        className="custom-scrollbar"
                        elevation={6}
                        style={
                          isMobile
                            ? {
                                minWidth: "245px",
                                maxWidth: "345px",
                                maxHeight: "425px",
                                overflow: "auto",
                              }
                            : {
                                width: "345px",
                                height: "425px",
                                overflow: "auto",
                              }
                        } // Add overflow: 'auto' for scrolling
                      >
                        <div className="popper text-start">
                          <div className="row ">
                            <div className="col-lg-7 col-10 text-start">
                              <div
                                className="m-0 technicianDashbofont"
                                style={{ fontSize: "16px", fontWeight: "600" }}
                              >
                                Notifications
                              </div>
                            </div>
                            <div className="col-lg-5 col-2">
                              <div className="text-end ">
                                <button
                                  type="button"
                                  style={{
                                    outline: "none",
                                    border: "none",
                                    backgroundColor: "white",
                                  }}
                                  onClick={handleCancelClick}
                                >
                                  <CloseIcon style={{ cursor: "pointer" }} />
                                </button>
                              </div>
                            </div>
                          </div>
                          {notificationsData?.length > 0 && (
                            <div
                              className="d-flex align-items-center"
                              style={{ justifyContent: "flex-end" }}
                            >
                              <div
                                style={{
                                  marginTop: "4px",
                                  color: "#EB8128",
                                  fontSize: "14px",
                                  cursor: "pointer",
                                }}
                                onClick={() => dispatch(readAllNotifications())}
                              >
                                Clear All
                              </div>
                            </div>
                          )}
                          <div className="triangle"></div>
                          <div className="notification-content">
                            {notificationsData?.length > 0 ? (
                              notificationsData?.map((notification, index) => (
                                <Accordion
                                  elevation={1}
                                  style={{
                                    marginBottom: "10px",
                                  }}
                                  className="notifications-accordion"
                                >
                                  <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    onClick={() => {
                                      dispatch(
                                        readNotification(notification.id)
                                      );
                                      if (notificationId === notification.id) {
                                        setNotificationId("");
                                      } else {
                                        setNotificationId(notification.id);
                                      }
                                    }}
                                    className="w-full"
                                  >
                                    <div
                                      className="d-flex align-items-center"
                                      style={{
                                        justifyContent: "space-between",
                                        width: "-webkit-fill-available",
                                      }}
                                    >
                                      <div
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {notification?.header}
                                      </div>
                                      <div className="d-flex align-items-center gap-2">
                                        <div
                                          style={{
                                            fontSize: "10px",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {moment(
                                            notification?.created_at
                                          ).format("DD-MM-YYYY, hh:mm a")}
                                        </div>
                                        <div>
                                          {notificationId ===
                                          notification.id ? (
                                            <KeyboardArrowUpIcon className="d-flex align-items-center" />
                                          ) : (
                                            <ExpandMoreIcon className="d-flex align-items-center" />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </AccordionSummary>
                                  <AccordionDetails
                                    className="accordian_details"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {notification.message}
                                  </AccordionDetails>
                                </Accordion>
                              ))
                            ) : (
                              <div
                                className="d-flex align-items-center"
                                style={{ justifyContent: "center" }}
                              >
                                <div>No notifications found!</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </Paper>
                    </ClickAwayListener>
                  )}
                </Popper>
              </Badge>
            </Stack>
          </div>
        </div>
      </div>
    </>
  );
};
export default Topbar;
