import { createSlice } from "@reduxjs/toolkit";

const revenueTransactionsSlice = createSlice({
  name: "revenueTransactions",
  initialState: {
    loading: false,
  },
  reducers: {
    revenueTransactionsRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    revenueTransactionsSuccess(state, action) {
      return {
        ...state,
        loading: false,
        revenueTransactionsData: action.payload,
      };
    },
    revenueTransactionsFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
  },
});

const { actions, reducer } = revenueTransactionsSlice;

export const {
  revenueTransactionsRequest,
  revenueTransactionsFail,
  revenueTransactionsSuccess,
} = actions;

export default reducer;
