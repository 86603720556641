import React from "react";

const CmacCardContainer = ({
  children,
  id,
  className,
  style,
  bg,
  position,
  zIndex = 1,
}) => {
  return (
    <div
      className={`${className} cmac-card-container`}
      id={id}
      style={{ ...style }}
    >
      <div
        className="cmac-card-item"
        style={{
          top: position?.top,
          left: position?.left,
          background: bg,
          zIndex,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default CmacCardContainer;
