import axios from "axios";
import { BASE_URL } from "../../api/ApiPath";
import {
  revenueShareListFail,
  revenueShareListRequest,
  revenueShareListSuccess,
} from "../slices/revenueShareListSlice";
import {
  initiateRevenueShareFail,
  initiateRevenueShareRequest,
  initiateRevenueShareSuccess,
} from "../slices/initiateRevenueShareSlice";
import {
  revenueShareDetailFail,
  revenueShareDetailRequest,
  revenueShareDetailSuccess,
} from "../slices/revenueShareDetailSlice";
import {
  revenueTransactionsFail,
  revenueTransactionsRequest,
  revenueTransactionsSuccess,
} from "../slices/revenueTransactionsSlice";
const handleUnAuthorizedError = (err) => {
  if (err.response.status === 401) {
    console.log("working");
    localStorage.clear();
    window.location.href = "/";
    window.location.reload();
  }
};
export const getRevenueShareList = (payload) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch(revenueShareListRequest());
    const response = await axios.get(
      `${BASE_URL}get/admin/revenue?adminType=${payload.admin}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    dispatch(revenueShareListSuccess(response?.data));
  } catch (error) {
    handleUnAuthorizedError(error);
    dispatch(revenueShareListFail(error));
  }
};

export const initiateRevenueShare =
  (payload, subPayload) => async (dispatch) => {
    const token = localStorage.getItem("token");
    try {
      dispatch(initiateRevenueShareRequest());
      const response = await axios.post(`${BASE_URL}revenue/settle`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch(initiateRevenueShareSuccess(response?.data));
      const payloadData = {
        user_id: payload.user_id,
      };
      dispatch(getRevenueShareList(subPayload));
      dispatch(getRevenueShareDetail(payloadData));
      dispatch(getRevenueTransactionsDetail(payloadData));
    } catch (error) {
      handleUnAuthorizedError(error);
      dispatch(initiateRevenueShareFail(error));
    }
  };

export const getRevenueShareDetail = (payload) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  try {
    dispatch(revenueShareDetailRequest());
    const response = await axios.get(
      `${
        role === "SuperAdmin"
          ? `${BASE_URL}get/revenue?user_id=${payload?.user_id}`
          : `${BASE_URL}get/revenue`
      }`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    dispatch(revenueShareDetailSuccess(response?.data));
  } catch (error) {
    handleUnAuthorizedError(error);
    dispatch(revenueShareDetailFail(error));
  }
};

export const getRevenueTransactionsDetail = (payload) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  try {
    dispatch(revenueTransactionsRequest());
    const response = await axios.get(
      `${
        role === "SuperAdmin"
          ? `${BASE_URL}revenue/transaction?user_id=${payload?.user_id}`
          : `${BASE_URL}revenue/transaction`
      }`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    dispatch(revenueTransactionsSuccess(response?.data));
  } catch (error) {
    handleUnAuthorizedError(error);
    dispatch(revenueTransactionsFail(error));
  }
};
