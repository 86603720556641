import React, { useEffect } from "react";
import Navbar from "../common/Navbar";
import TellMeAbout from "../services/TellMeAbout";
import FindUs from "../services/FindUs";
import HowToBook from "../services/HowToBook";
import Footer from "../Footer";
import UpcomingService from "../services/UpcomingService";
import TVServiceBanner from "./TVServiceBanner";
import TVServicesOffered from "./TVServicesOffered";
import FAQ from "./FAQ";
import Quote from "./Quote";

export default function TelevisionService() {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Navbar />
      <TVServiceBanner />
      <TVServicesOffered />
      <Quote
        quote={`“S3 is my go-to app for LED TV Repairing Services in Chennai. Instant
      service booking and technician assignment is guaranteed!” – Nisha,
      Velachery.`}
      />
      <FAQ
        appliance={"Television"}
        appliance_abbreviation="TV"
        last_answer="Yes, our technicians support end-to-end TV installation and services. This includes recommendations for stabilizer (if subject to frequent fluctuation), home theatre installation, and other value-added services to ensure an amazing viewing experience.  "
      />
      <UpcomingService />
      <TellMeAbout />
      <FindUs />
      <HowToBook />
      <Footer />
    </div>
  );
}
