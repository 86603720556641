import { createSlice } from "@reduxjs/toolkit";


const readNotificationSlice = createSlice({
    name: 'readNotification',
    initialState: {
        loading: false,
    },
    reducers: {
        readNotificationRequest(state, action) {
            return {
                ...state,
                loading: true
            }
        },
        readNotificationSuccess(state, action) {
            return {
                ...state,
                loading: false,
                readNotification: action.payload,
            }
        },
        readNotificationFail(state, action) {
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        },
        readNotificationErrorClear(state, action) {
            return {
                ...state,
                loading: false,
                error:false,
            }
        }
    }
});

const { actions, reducer } = readNotificationSlice;

export const { readNotificationRequest, readNotificationFail, readNotificationSuccess, readNotificationClear } = actions

export default reducer;