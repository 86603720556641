import React from "react";

export default function HowToBook() {
  return (
    <div className="how-to-book-con">
      <div className="how-to-book">
        <div className="flex-box-con">
          <div className="tell-me-about-flex">
            <div className="content-part">
              <div
                style={{ fontWeight: 600, fontSize: "17px" }}
                className="res-576-mt-1"
              >
                How can you book a service?
              </div>
              <div
                style={{
                  color: "rgba(26, 26, 26, 0.7)",
                  fontSize: "15px",
                  marginTop: "1rem",
                  lineHeight: "26px",
                }}
              >
                Download the S3 app or book on our website today
              </div>
              <button className="download-button">Download</button>
            </div>
            <div className="tell-me-line"></div>
            <div className="content-part">
              <div
                style={{
                  fontWeight: 600,
                  fontSize: "17px",
                  textAlign: "center",
                }}
              >
                When can I avail of a service, and when will the technician be
                assigned?
              </div>
              <div
                style={{
                  color: "rgba(26, 26, 26, 0.7)",
                  fontSize: "15px",
                  marginTop: "1rem",
                  lineHeight: "26px",
                }}
              >
                Book the service for any date and time at your convenience! The
                technician <b>will be assigned within </b>
                <span
                  style={{ color: "rgba(235, 129, 40, 1)", fontWeight: 600 }}
                >
                  30 minutes
                </span>{" "}
                <b>or you will be updated on the status.</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
