import React from "react";
import play_store from "../../Assets/Home/Play-store.svg";

export default function Quote({ quote }) {
  return (
    <div className="quote-container">
      <div className="quote">{quote}</div>
      <div className="mail">
        <div>
          <div className="heading">S3-Fy Your Appliance Now!</div>
          <div style={{ textAlign: "center" }}>
            <img
              alt="play_store"
              src={play_store}
              style={{ height: "50px", marginTop: "10px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
