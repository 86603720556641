import React, { useEffect, useState, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";

const ZoneChart = () => {
  const dispatch = useDispatch();
  const { RewardZoneListData } = useSelector(
    (state) => state.RewardZoneListState
  );

  const [zoneData, setZoneData] = useState({
    zoneNames: [],
    claimCounts: [],
  });

  useEffect(() => {
    if (RewardZoneListData) {
      const zoneNames = RewardZoneListData.map((item) => item.zone_name);
      const claimCounts = RewardZoneListData.map((item) => item.count);
      setZoneData({ zoneNames, claimCounts });
    }
  }, [RewardZoneListData]);

  const generateShadesOfOrange = useMemo(
    () => (count) => {
      const startColor = "#FFD54F"; // Lightest shade
      const endColor = "#E64A19"; // Darkest shade

      const blendColors = (color1, color2, percent) => {
        const color1RGB = hexToRgb(color1);
        const color2RGB = hexToRgb(color2);

        const blendedRGB = {
          r: Math.round(color1RGB.r + (color2RGB.r - color1RGB.r) * percent),
          g: Math.round(color1RGB.g + (color2RGB.g - color1RGB.g) * percent),
          b: Math.round(color1RGB.b + (color2RGB.b - color1RGB.b) * percent),
        };

        return rgbToHex(blendedRGB.r, blendedRGB.g, blendedRGB.b);
      };

      const hexToRgb = (hex) => {
        const bigint = parseInt(hex.substring(1), 16);
        return {
          r: (bigint >> 16) & 255,
          g: (bigint >> 8) & 255,
          b: bigint & 255,
        };
      };

      const rgbToHex = (r, g, b) => {
        return (
          "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
        );
      };

      const colors = [];
      for (let i = 0; i < count; i++) {
        const percent = (i + 1) / count;
        const color = blendColors(startColor, endColor, percent);
        colors.push(color);
      }
      return colors;
    },
    []
  );

  const colors = generateShadesOfOrange(zoneData.claimCounts.length);

  const state = useMemo(
    () => ({
      series: [
        {
          name: "technician_count",
          data: zoneData.claimCounts,
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 310,
        },
        plotOptions: {
          bar: {
            barHeight: "37%",
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: "bottom",
            },
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            colors: ["transparent"],
          },
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
          },
          offsetX: 0,
          dropShadow: {
            enabled: false,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: zoneData.zoneNames,
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        colors: colors,
      },
    }),
    [zoneData, colors]
  );

  return (
    <div id="chart" style={{ overflowY: "auto" }}>
      {RewardZoneListData?.length > 0 &&
      zoneData.claimCounts.length &&
      zoneData.zoneNames.length ? (
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="bar"
          height={310}
        />
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default ZoneChart;
