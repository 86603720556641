import React, { useEffect, useState } from "react";
import Logo from "../../Assets/Cust/reg-with-us.svg";
import logo_1 from "../../Images/s3Logo.png";
import { Paper } from "@mui/material";
import "../../common.css";
import { Link, useNavigate } from "react-router-dom";
import S3NewLogo from "../../Assets/s3_new_logo/S3NewLogo";
import as_cust from "../../Assets/icons/as-customer-icon.svg";
import as_tech from "../../Assets/icons/as-technician-icon.svg";
import Footer from "../Footer";
const Common = () => {
  const navigate = useNavigate();

  const REGISTRATION_ITEMS = [
    {
      title: "Customer",
      description: "Schedule a service request in just a few clicks.",
      icon: <img src={as_cust} alt="as_cust" />,
      onClick: () => navigate("/customerRegister"),
    },
    {
      title: "Technician",
      description: "Get notified of service requests in your area.",
      icon: <img src={as_tech} alt="as_tech" />,
      onClick: () => navigate("/technicianRegister"),
    },
  ];
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const renderItem = ({ title, description, onClick, icon }, index) => (
    <Paper
      key={index}
      className="mt-lg-4 mt-3 row p-4 hover-theme paper-item res-576px-w-fill"
      elevation={2}
      style={{
        width: "100%",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <div className="col-10 p-0 d-flex ">
        {icon}
        <div className="ms-2" style={{ listStyle: "none" }}>
          <text style={{ fontWeight: "600" }}>{title}</text>
          <br />
          <text style={{ color: "#6A707C" }}>{description}</text>
        </div>
      </div>
      <div
        className="col-2 d-flex align-items-center arrow-container"
        style={{ justifyContent: "flex-end" }}
      >
        <i
          style={{ color: "rgb(235, 129, 40)" }}
          className="bi bi-arrow-right fs-5 arrow-iconn"
        ></i>
      </div>
    </Paper>
  );

  return (
    <div>
      <div className="container-fluid ">
        <div
          className="res-576px-d-unset mt--30px"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            className="col-lg-5 col-12 p-0 res-576px-d-none"
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              className="logo_container"
              style={{
                // position: "fixed",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "20px",
                marginTop: "60px",
                paddingLeft: "50px",
              }}
            >
              <div onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
                <S3NewLogo
                  color={"rgba(235, 129, 40, 1)"}
                // style={{ scale: "1.2" }}
                />
              </div>
              <img alt="demo" src={Logo} className="logo" />
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                  textAlign: "center",
                  width: "80%",
                }}
              >
                Secure Sales Service: Your One-Stop Solution!
              </div>
              <div
                style={{
                  fontSize: "10px",
                  textAlign: "center",
                  width: "78%",
                  color: "rgba(0, 0, 0, 1)",
                }}
                className="mt--10px"
              >
                Whether you're booking or receiving service requests, our
                platform has you covered.
              </div>
            </div>
          </div>
          <div className="col-lg-1 border-register-right res-576px-d-none"></div>
          <div className="col-lg-6 col-12 pt-0">
            <div className="container p-lg-5">
              <div
                onClick={() => navigate("/")}
                className="res-577px-d-none mb-4"
              >
                <S3NewLogo
                  color={"rgba(235, 129, 40, 1)"}
                  style={{ position: "relative", left: "38px", scale: "1.4" }}
                />
              </div>

              <p
                className="m-0 mt-lg-5 mt-2"
                style={{
                  fontSize: "22px",
                  fontWeight: "700",
                  color: "#1E232C",
                }}
              >
                Register with us!
              </p>
              <p
                className="m-0 mt-lg-3 mt-2"
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#6A707C",
                }}
              >
                Tell us who you are
              </p>
              {REGISTRATION_ITEMS.map(renderItem)}

              <p
                className="m-0 mt-lg-5 mt-3 "
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#6A707C",
                }}
              >
                Already have an account?{" "}
                <Link
                  to="/login"
                  style={{ color: "rgb(235, 129, 40)", textDecoration: "none" }}
                >
                  Login Now
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "100px" }} className="res-576px-mt-30px">
        <Footer topscroller />
      </div>
    </div>
  );
};

export default Common;
