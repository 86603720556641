import { createSlice } from "@reduxjs/toolkit";


const basicChargeSlice = createSlice({
    name: 'basicCharge',
    initialState:{
        loading: false,
    },
    reducers:{
        basicChargeRequest(state, action){
            return{
                ...state,
                loading: true
            }
        },
        basicChargeSuccess(state, action){
            return{
                ...state,
                loading: true,
                basicChargeData: action.payload
            }
        },
        basicChargeFail(state, action){
            return{
                ...state,
                loading: false,
                error: action.payload
            }
        },
        basicChargeClear(state, action){
            return{
                ...state,
                loading: false,
                basicChargeData: null
            }
        }
    }
});

const {actions, reducer} = basicChargeSlice;

export const {basicChargeRequest,basicChargeFail,basicChargeSuccess, basicChargeClear} = actions

export default reducer;