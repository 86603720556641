import { createSlice } from "@reduxjs/toolkit";


const serviceRequestSlice = createSlice({
    name: 'serviceRequest',
    initialState: {
        loading: false,
        serviceRequestCreated: false
    },
    reducers: {
        serviceRequestCreationRequest(state, action) {
            return {
                ...state,
                loading: true
            }
        },
        serviceRequestCreationSuccess(state, action) {
            return {
                ...state,
                loading: true,
                serviceRequestData: action.payload.data,
                serviceRequestSuccessMsg: action.payload.message,
                serviceRequestCreated: true
            }
        },
        serviceRequestCreationFail(state, action) {
            return {
                ...state,
                loading: false,
                error: action.payload,
                serviceRequestCreated: false
            }
        },
        serviceRequestUpdateRequest(state, action) {
            return {
                ...state,
                loading: true
            }
        },
        serviceRequestUpdateSuccess(state, action) {
            return {
                ...state,
                loading: true,
                serviceRequestUpdateSuccessMsg: action.payload.message,
                serviceRequestUpdated: true
            }
        },
        serviceRequestUpdateFail(state, action) {
            return {
                ...state,
                loading: false,
                error: action.payload,
                serviceRequestUpdated: false
            }
        },
        serviceRequestUpdateClear(state, action) {
            return {
                ...state,
                loading: false,
                error:false,
                serviceRequestUpdateSuccessMsg: null,
                serviceRequestUpdated: false
            }
        },
        serviceRequestCreationClear(state, action) {
            return {
                ...state,
                loading: false,
                error:false,
                serviceRequestData: null,
                serviceRequestSuccessMsg: null,
                serviceRequestCreated: false
            }
        }
    }
});

const { actions, reducer } = serviceRequestSlice;

export const { serviceRequestCreationRequest, serviceRequestCreationFail, serviceRequestCreationSuccess, serviceRequestCreationClear, serviceRequestUpdateRequest, serviceRequestUpdateFail, serviceRequestUpdateSuccess, serviceRequestUpdateClear } = actions

export default reducer;