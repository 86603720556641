import React, { useEffect, useState } from "react";
import Topbar from "../topbar";
import Sidebar from "../Sidebar";
import { useLocation } from "react-router-dom";
import "./deleteAccount.css";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import Api from "../../service/services";

export default function DeleteAccountConfirmation() {
  const navigate = useNavigate();
  const location = useLocation();
  const [alertModal, setAlertModal] = useState(false);

  const { payload } = location.state || {};

  useEffect(() => {
    if (!payload?.success) {
      navigate(-1);
    }
  }, [payload, navigate]);

  if (!payload?.success) {
    return null;
  }

  const handleDelete = (e) => {
    e.preventDefault();
    let data = {
      purpose: "delete_account",
    };
    Swal.fire({
      text: "To proceed with deleting your account, please click the Send Code button. You will receive an email with a verification code sent to your registered email address.",
      showCancelButton: true,
      confirmButtonText: "Cancel",
      cancelButtonText: "Send Code",
    }).then((result) => {
      if (!result?.isConfirmed) {
        Api.accountDeleteSendOtpApi(data)?.then((response) => {
          if (response?.error?.message) {
            Swal.fire({
              text: response?.error?.message,
              timer: 2000,
              timerProgressBar: true,
            });
          } else {
            navigate("/account-delete-otp", {
              state: { deleteOtpResponse: response },
            });
          }
        });
      }
    });
  };

  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <Topbar />
        <div
          className="row container-fluid"
          style={{ overflow: "hidden", paddingRight: "0px" }}
        >
          <div className="col-2 col-12 p-0 border border-right">
            <Sidebar />
          </div>
          <div
            className="col-12 user_management"
            style={{ paddingRight: "0px", marginTop: "80px" }}
          >
            <div className="mt-lg-2">
              <i
                className="bi bi-chevron-left "
                style={{
                  fontWeight: "600",
                  fontSize: "21px",
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                }}
                onClick={() => navigate(-1)}
              ></i>
              <text
                className="p-0 "
                style={{
                  fontWeight: "600",
                  color: "#1E232C",
                  fontSize: "21px",
                  height: "32px",
                }}
              >
                Delete Account and Data
              </text>
              <p className="sub-head ">
                Deleting your account will permanently remove all associated
                data from the application. This action cannot be undone.
                <br />
                You need verify your Email for delete your account{" "}
              </p>
              <div style={{ marginLeft: "15px" }}>
                <button
                  type="submit"
                  disabled={!payload?.success}
                  style={{
                    height: "40px",
                    width: "150px",
                  }}
                  className={` ${
                    payload?.success ? "del-acc" : "disable-del-acc"
                  }`}
                  onClick={handleDelete}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
        <Modal show={alertModal} onHide={() => setAlertModal(false)} centered>
          <div className="p-3">
            <div className="pt-3 pb-4" style={{ textAlign: "center" }}>
              Are you sure,you want to delete your account?
            </div>
            <div
              className="d-flex align-items-center pb-2"
              style={{ justifyContent: "center", gap: "10px" }}
            >
              <Button variant="secondary" onClick={() => setAlertModal(false)}>
                Cancel
              </Button>
              <Button
                onClick={handleDelete}
                style={{
                  backgroundColor: "rgb(235, 129, 40)",
                  color: "white",
                  border: "1px solid rgb(235, 129, 40)",
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}
