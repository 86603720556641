import { createSlice } from "@reduxjs/toolkit";


const  referralManagementListSlice = createSlice({
    name: 'referralManagementList',
    initialState: {
        loading: false,
        referralManagementListData: [],
        error: null,
        statusCode: null,
        successMsg: null,
        errorMsg: null,
    },
    reducers: {
        referralManagementListRequest(state, action) {
            return {
                ...state,
                loading: true
            }
        },
        referralManagementListSuccess(state, action) {
            return {
                ...state,
                loading: true,
                referralManagementListData: action?.payload,
                // successMsg: action.payload.data.message,
                // totalCount: action.payload.data?.totalCount,
                errorMsg: null,
                statusCode: 'success'
            }
        },
        referralManagementListFail(state, action) {
            return {
                ...state,
                loading: false,
                error: action.payload,
                statusCode: 'error',
                errorMsg: action?.payload?.error?.message,
                referralManagementListData: [],
                successMsg: null
            }
        },
        clearStatusCode(state, action) {
            return {
                ...state,
                statusCode: null
            }
        }

    }
});

const { actions, reducer } =  referralManagementListSlice;

export const { referralManagementListRequest, referralManagementListFail, referralManagementListSuccess, clearStatusCode } = actions

export default reducer;
