import {
  Autocomplete,
  TextField,
  MenuItem,
  Tooltip,
  FormControl,
  ListItemIcon,
  ListItem,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info"; // Import the InfoIcon or any other icon
import { useState } from "react";

const SearchableMultiSelect = ({
  options,
  label,
  handleValue,
  selectedItem,
}) => {
  const handleChange = (event, value) => {
    handleValue(value);
  };

  const filterOptions = (options, { inputValue }) => {
    return options.filter(
      (option) =>
        option[label]?.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.city_name?.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  return (
    <FormControl
      className="mt-3"
      sx={{
        minWidth: "140px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Autocomplete
        multiple
        id="searchable-multi-select"
        options={options}
        value={selectedItem}
        onChange={handleChange}
        getOptionLabel={(option) => `${option.name || option.city_name}`}
        placeholder="District"
        renderInput={(params) => (
          <TextField
            {...params}
            label="District"
            placeholder="Type to search..."
            variant="outlined"
            value={selectedItem}
          />
        )}
        renderOption={(props, option) => {
          console.log(option, "option");
          /*   if (selectedItem.some((item) => item.city_name !== option.name)) */
          return (
            <MenuItem
              {...props}
              key={option[label]}
              style={{ position: "relative" }}
            >
              {option.name || option.city_name}
              {option.is_maped && (
                <ListItemIcon style={{ marginLeft: "auto" }}>
                  <Tooltip
                    title={`This district is already associated with zone, ${option?.zone_name}.`}
                    arrow
                  >
                    <InfoIcon style={{ color: "rgb(235, 129, 40)" }} />
                  </Tooltip>
                </ListItemIcon>
              )}
            </MenuItem>
          );
        }}
        isOptionEqualToValue={(option, value) =>
          option[label] === value["city_name"] ||
          option[label] === value["name"]
        }
        filterOptions={filterOptions}
      />
    </FormControl>
  );
};

export default SearchableMultiSelect;
