import {
  Avatar,
  Box,
  Button,
  InputBase,
  Tab,
  Tabs,
  TextareaAutosize,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Modal } from "react-bootstrap";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Popover } from "react-tiny-popover";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";
import { Paper } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DoneIcon from "@mui/icons-material/Done";
import "../../../layout.css";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ProgressBar from "../../Customers/progress";
import Topbar from "../../topbar";
import Sidebar from "../../Sidebar";
import Api from "../../../service/services";
import { format, parseISO } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import RectIcon from "../../../Assets/icons/RectIcon";
import ArrowIconHighlighted from "../../../Assets/icons/ArrowIconHighlighted";
import ArrowIconDefault from "../../../Assets/icons/ArrowIconDefault";
import CalendarIcon from "../../../Assets/icons/CalendarIcon";
import ToastMessage from "../../../plugins/ToastMessage";
import { useDispatch } from "react-redux";
import { getNotificationsCount } from "../../../redux/actions/notificationsAction";
import SpinnerLoader from "../../../plugins/SpinnerLoader";
import {
  MdClose,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from "react-icons/md";
import FilterModule from "../../../plugins/FilterModule";
import Ratings from "../../../plugins/Ratings";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { Scrollbars } from "react-custom-scrollbars";
import { getQueryParam } from "../../../plugins/getQueryParams";
import CountContext from "../../countContext/CountContext";
import { IoMdPhotos } from "react-icons/io";
import { useTheme } from "@mui/material/styles";
import { FaStar } from "react-icons/fa";

const debounce = (callback, delay) => {
  let timerId;
  return (...args) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};
const ServiceRequestmanagementTechnician = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [hovered, setHovered] = useState(null);
  const [isService, setIsService] = useState(false);
  const [isVisit, setIsVisit] = useState(false);
  const [isClosure, setIsClosure] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [IsRepair, setIsRepair] = useState(false);
  const [isReopenSR, setIsReopenSR] = useState(false);
  const [SrDetailArray, setSRDetailArray] = useState([]);
  const [openSRList, setOpenSRList] = useState([]);
  const [inprogressSRList, setInprogressSRList] = useState([]);
  const [completeSRList, setCompleteSRList] = useState([]);
  const [serviceRequestId, setServiceRequestId] = useState("");
  const [serviceCreatedTime, setServiceCreatedTime] = useState("");
  const [open, setOpen] = useState(true);
  const [inProgress, setInProgress] = useState(false);
  const [completed, setCompleted] = useState(false);
  const navigate = useNavigate();
  const [serviceStatus, setServiceStatus] = useState("");
  const [IsOpenEdit, setIsOpenEdit] = useState(false);
  const [commentInput, setCommentInput] = useState("");
  const [sparePartsCharge, setSparePartsCharge] = useState("");
  const [commentInputError, setCommentInputError] = useState("");
  const [toastMessageCommentError, setToastMessageCommentError] = useState(
    null
  );
  const [viewDetailsPopover, setViewDetailsPopover] = useState(false);
  // const [loadingData, setLodingData] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filterData, setFilterData] = useState({});
  const [currentId, setCurrentId] = useState(0);
  const status = getQueryParam("sr_status");
  const srParamsId = getQueryParam("sr_id");
  const [rating, setRating] = useState(4);
  const [srId, setSrId] = useState("");
  const [comment, setComment] = useState("");
  const [ratingModal, setRatingModal] = useState(false);
  const [custName, setCustName] = useState("");
  const [userId, setUserId] = useState("");

  const [serviceListLoader, setServiceListLoader] = useState(false);

  const dispatch = useDispatch();
  /* // Infinity Scroll Start*/
  const [loadingDataTest, setLoadingDataTest] = useState(false);
  const totalPages = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const nextPage = currentPage + 1;
  // const [openSRListDefault, setOpenSRListDefault] = useState([]);
  // const [inProgressSRListDefault, setInProgressSRListDefault] = useState([]);
  // const [completedSRListDefault, setCompletedSRListDefault] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const { setSearch, search } = useContext(CountContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleImageClick = (index) => {
    setCurrentIndex(index);
  };
  const showNextImage = () => {
    const nextIndex = (currentIndex + 1) % SrDetailArray[0]?.attachments.length;
    setCurrentIndex(nextIndex);
  };
  const showPreviousImage = () => {
    const previousIndex =
      (currentIndex - 1 + SrDetailArray[0]?.attachments.length) %
      SrDetailArray[0]?.attachments.length;
    setCurrentIndex(previousIndex);
  };

  useEffect(() => {
    setOpenSRList([]);
    const scrollPayload = {
      page: 1,
    };
    setLoadingDataTest(true);
    setLoadingData(true);
    setServiceListLoader(true);
    Api.getServiceRequestOpenList(searchInput, filterData, scrollPayload).then(
      (res) => {
        Api.getServiceRequestAcceptedList(
          searchInput,
          filterData,
          scrollPayload
        ).then((res) => {
          Api.getServiceRequestClosedList(
            searchInput,
            filterData,
            scrollPayload
          ).then((res) => {
            setLoadingData(false);
            if (res) {
              setCompleteSRList(res);
              // setCompletedSRListDefault(res);
            }
          });
          if (res) {
            setInprogressSRList(res);
            // setInProgressSRListDefault(res);
          }
        });
        setServiceListLoader(false);
        setCurrentPage(currentPage + 1);
        setOpenSRList(res);
        // setOpenSRListDefault(res);
        setLoadingDataTest(false);
        setSRDetailArray(res.length > 0 && [res[0]]);
        const sr = res.length > 0 && [res[0]];
        if (res.length > 0) {
          formatDate(sr[0]?.created_at);
          setServiceStatus(sr[0]?.status);
        }
        if (res && !status && !srParamsId) {
          setHovered(0);
          setServiceRequestId(res.length > 0 && res[0]?.id);
        }
      }
    );
  }, []);

  useEffect(() => {
    if (open) {
      if (parseInt(currentPage) !== nextPage) {
        currentPage == 1
          ? setOpenSRList(openSRList)
          : setOpenSRList((prev) => [...prev, ...openSRList]);
      } else {
        setOpenSRList([]);
      }
    }
    if (inProgress) {
      if (parseInt(currentPage) !== nextPage) {
        currentPage == 1
          ? setInprogressSRList(inprogressSRList)
          : setInprogressSRList((prev) => [...prev, ...inprogressSRList]);
      } else {
        setInprogressSRList([]);
      }
    }
    if (completed) {
      if (parseInt(currentPage) !== nextPage) {
        currentPage == 1
          ? setCompleteSRList(completeSRList)
          : setCompleteSRList((prev) => [...prev, ...completeSRList]);
      } else {
        setCompleteSRList([]);
      }
    }
  }, [currentPage]);
  const debouncedHandleScroll = useCallback(
    debounce((e) => {
      const { scrollTop, scrollHeight, clientHeight } = e?.target;
      if (
        scrollTop + clientHeight + 500 >= scrollHeight - 0 &&
        nextPage <= totalPages
      ) {
        try {
          setLoadingDataTest(true);
          const scrollPayload = {
            page: nextPage,
          };
          if (open) {
            Api.getServiceRequestUnassignedList(
              searchInput,
              filterData,
              scrollPayload
            ).then((res) => {
              setCurrentPage(currentPage + 1);
              setOpenSRList(res);
              setLoadingDataTest(false);
            });
          }
          if (inProgress) {
            Api.getServiceRequestAcceptedList(
              searchInput,
              filterData,
              scrollPayload
            ).then((res) => {
              setCurrentPage(currentPage + 1);
              setInprogressSRList(res);
              setLoadingDataTest(false);
            });
          }
          if (completed) {
            Api.getServiceRequestClosedList(
              searchInput,
              filterData,
              scrollPayload
            ).then((res) => {
              setCurrentPage(currentPage + 1);
              setCompleteSRList(res);
              setLoadingDataTest(false);
            });
          }
        } catch (err) {
          console.log(err);
          setLoadingDataTest(false);
        }
      }
    }, 500),
    [openSRList, nextPage, dispatch, open, inProgress, completed]
  );
  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, [loadingDataTest]);
  /* Infinity Scroll End // */
  const handlePostReview = () => {
    if (rating < 1) {
      toast.error("Please select the rating!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    const payload = {
      review_type: "customer",
      rating_value: rating,
      comment: comment,
      sr_id: srId,
      user_id: userId,
    };
    Api.postReview(payload)
      .then((res) => {
        console.log("Review", res);
        setRatingModal(false);
        setComment("");
        setRating(4);
        Swal.fire({
          text: res,
          confirmButtonText: "OK",
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    setLoadingData(true);
    setCommentInputError("");
    Api.getServiceRequestOpenList(searchInput, filterData).then((res) => {
      Api.getServiceRequestAcceptedList(searchInput, filterData).then((res) => {
        Api.getServiceRequestClosedList(searchInput, filterData).then((res) => {
          setLoadingData(false);
          if (res) {
            setCompleteSRList(res);
          }
        });
        if (res) {
          setInprogressSRList(res);
        }
      });
      if (res) {
        setHovered(0);
        setOpenSRList(res);
        setServiceRequestId(res.length > 0 && res[0]?.id);
        setSRDetailArray(res.length > 0 && [res[0]]);
        const sr = res.length > 0 && [res[0]];
        if (res.length > 0) {
          formatDate(sr[0]?.created_at);
          setServiceStatus(sr[0]?.status);
        } else {
          setServiceStatus("");
        }
      }
    });
  }, []);

  const handleOpenSR = () => {
    // setInprogressSRList(inProgressSRListDefault);
    // setCompleteSRList(completedSRListDefault);
    setSearchInput("");
    setFilterData({});
    setServiceRequestId("");
    setOpenSRList("");
    Api.getServiceRequestOpenList().then((res) => {
      if (res) {
        setOpenSRList(res);
        handleOpenSRById(res[0]?.id, 0);
      }
    });
  };

  const handleInProgressSR = () => {
    // setOpenSRList(openSRListDefault);
    // setCompleteSRList(completedSRListDefault);
    setInprogressSRList("");
    setSearchInput("");
    setFilterData({});
    setServiceRequestId("");
    Api.getServiceRequestAcceptedList().then((res) => {
      if (res) {
        setInprogressSRList(res);
        handleInProgressSRById(res[0]?.id, 0);
      }
    });
  };

  const handleCompletedSR = () => {
    // setOpenSRList(openSRListDefault);
    // setInprogressSRList(inProgressSRListDefault);
    setSearchInput("");
    setFilterData({});
    setServiceRequestId("");
    setCompleteSRList("");
    Api.getServiceRequestClosedList().then((res) => {
      if (res) {
        setCompleteSRList(res);
        handleCompletedSRById(res[0]?.id, 0);
      }
    });
  };

  const handleOpenSRById = (req_id, index) => {
    setHovered(index);
    setIsOpenEdit(true);
    const sr = openSRList && openSRList.filter((item) => item.id === req_id);
    setSRDetailArray(
      openSRList && openSRList.filter((item) => item.id === req_id)
    );
    setServiceRequestId(req_id);
    setInProgress(false);
    if (isMobile && sr.length > 0) {
      window.scrollTo(0, document.body.scrollHeight);
    }
    if (sr.length > 0) {
      formatDate(sr[0].created_at);
      setServiceStatus(sr[0]?.status);
    } else {
      setServiceStatus("");
    }
  };

  const handleInProgressSRById = (req_id, index) => {
    setServiceRequestId("");
    setHovered(index);
    setIsOpenEdit(true);
    const sr = inprogressSRList?.filter((item) => item?.id === req_id);
    setSRDetailArray(inprogressSRList?.filter((item) => item?.id === req_id));
    setServiceRequestId(req_id);
    /*   if (isMobile && sr?.length > 0) {
        window.scrollTo(0, document.body.scrollHeight);
      } */
    if (sr?.length > 0) {
      formatDate(sr[0]?.created_at);
      setServiceStatus(sr[0]?.status);
    } else {
      setServiceStatus("");
    }
    setInProgress(true);
  };

  const handleCompletedSRById = (req_id, index) => {
    setServiceRequestId("");
    setHovered(index);
    setIsOpenEdit(true);
    const sr = completeSRList?.filter((item) => item.id === req_id);
    setSRDetailArray(completeSRList?.filter((item) => item.id === req_id));
    if (sr[0]?.status == "cancelled") {
      setIsRepair(false);
      setIsReopenSR(false);
      setHovered(null);
      setIsService(false);
      setIsVisit(false);
      setIsClosure(false);
      setIsPayment(false);
    }
    setServiceRequestId(req_id);
    if (isMobile && sr.length > 0) {
      window.scrollTo(0, document.body.scrollHeight);
    }
    if (sr.length > 0) {
      formatDate(sr[0].created_at);
      setServiceStatus(sr[0]?.status);
    } else {
      setServiceStatus("");
    }
    setInProgress(false);
  };

  const formatDate = (timestamp) => {
    const dateObj = parseISO(timestamp);
    setServiceCreatedTime(format(dateObj, "MMM dd yyyy, hh:mm a"));
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);
  const [tabSwitch, setTabSwitch] = useState(0);
  const handleChange = (event, newValue) => {
    setTabSwitch(newValue);
    setSearch("");
    setValue(newValue);
    setIsRepair(false);
    setIsReopenSR(false);
    setHovered(null);
    setIsService(false);
    setIsVisit(false);
    setIsClosure(false);
    setIsPayment(false);
  };

  const handleClosureComment = (e) => {
    setCommentInput(e.target.value);
  };

  const handleSparePartsCharge = (e) => {
    setSparePartsCharge(e.target.value);
  };

  const handleAssignToMe = () => {
    const payload = {
      sr_id: serviceRequestId,
      status: "accepted",
    };

    Api.acceptServiceRequest(payload).then((res) => {
      setServiceRequestId("");
      const token = localStorage.getItem("token");
      dispatch(getNotificationsCount(token));
      Api.getServiceRequestOpenList(searchInput, filterData).then((res) => {
        if (res) {
          setHovered(0);
          setOpenSRList(res);
          setServiceRequestId(res.length > 0 && res[0]?.id);
          setSRDetailArray(res.length > 0 && [res[0]]);
          const sr = res.length > 0 && [res[0]];
          if (res.length > 0) {
            formatDate(sr[0]?.created_at);
            setServiceStatus(sr[0]?.status);
          } else {
            setServiceStatus("");
          }
        }
      });
      Api.getServiceRequestAcceptedList(searchInput, filterData).then((res) => {
        if (res) {
          setInprogressSRList(res);
        }
      });
      Api.getServiceRequestClosedList(searchInput, filterData).then((res) => {
        if (res) {
          setCompleteSRList(res);
        }
      });
    });
  };

  const handleRejectServiceRequest = () => {
    const payload = {
      sr_id: serviceRequestId,
      status: "denied",
    };
    Api.rejectServiceRequest(payload).then((res) => {
      setServiceRequestId("");
      const token = localStorage.getItem("token");
      dispatch(getNotificationsCount(token));
      Api.getServiceRequestOpenList(searchInput, filterData).then((res) => {
        if (res) {
          setHovered(0);
          setOpenSRList(res);
          setServiceRequestId(res.length > 0 && res[0]?.id);
          setSRDetailArray(res.length > 0 && [res[0]]);
          const sr = res.length > 0 && [res[0]];
          if (res.length > 0) {
            formatDate(sr[0]?.created_at);
            setServiceStatus(sr[0]?.status);
          } else {
            setServiceStatus("");
          }
        }
      });
      Api.getServiceRequestAcceptedList(searchInput, filterData).then((res) => {
        if (res) {
          setInprogressSRList(res);
        }
      });
      Api.getServiceRequestClosedList(searchInput, filterData).then((res) => {
        if (res) {
          setCompleteSRList(res);
        }
      });
    });
  };

  const handleSubmitSr = () => {
    let insert_data = {
      spare_service_charge: sparePartsCharge ? sparePartsCharge : null,
      sr_id: serviceRequestId,
      closing_notes: commentInput,
    };
    if (!commentInput) {
      setCommentInputError("*Please enter the close notes.");
      setToastMessageCommentError("Please enter the close notes.");
    } else {
      setCommentInputError("");
      Api.initiatePaymentServiceRequest(insert_data).then((res) => {
        const token = localStorage.getItem("token");
        dispatch(getNotificationsCount(token));
        setServiceRequestId("");
        Api.getServiceRequestOpenList(searchInput, filterData).then((res) => {
          if (res) {
            setOpenSRList(res);
          }
        });
        Api.getServiceRequestAcceptedList(searchInput, filterData).then(
          (res) => {
            if (res) {
              setHovered(0);
              setServiceRequestId(res.length > 0 && res[0]?.id);
              setSRDetailArray(res.length > 0 && [res[0]]);
              setInprogressSRList(res);
              const sr = res.length > 0 && [res[0]];
              if (res.length > 0) {
                formatDate(sr[0]?.created_at);
                setServiceStatus(sr[0]?.status);
              } else {
                setServiceStatus("");
              }
            }
          }
        );
        Api.getServiceRequestClosedList(searchInput, filterData).then((res) => {
          if (res) {
            setCompleteSRList(res);
          }
        });
      });
    }
  };

  const handleCloseSR = () => {
    let payload = {
      sr_id: serviceRequestId,
    };
    Api.closeServiceRequest(payload).then((res) => {
      setServiceRequestId("");
      const token = localStorage.getItem("token");
      dispatch(getNotificationsCount(token));
      Api.getServiceRequestOpenList(searchInput, filterData).then((res) => {
        if (res) {
          setOpenSRList(res);
        }
      });
      Api.getServiceRequestAcceptedList(searchInput, filterData).then((res) => {
        if (res) {
          setHovered(0);
          setServiceRequestId(res.length > 0 && res[0]?.id);
          setSRDetailArray(res.length > 0 && [res[0]]);
          setInprogressSRList(res);
          const sr = res.length > 0 && [res[0]];
          if (res.length > 0) {
            formatDate(sr[0]?.created_at);
            setServiceStatus(sr[0]?.status);
          } else {
            setServiceStatus("");
          }
        }
      });
      Api.getServiceRequestClosedList(searchInput, filterData).then((res) => {
        if (res) {
          setCompleteSRList(res);
        }
      });
    });
  };

  const getPaymentStatus = () => {
    const service_request_details = SrDetailArray.find(
      (val) => val.id === serviceRequestId
    );
    if (
      service_request_details.sr_work_history[0]?.payment_status === "initiated"
    ) {
      return "initiated";
    } else if (service_request_details?.status === "closed") {
      return "closed";
    } else if (
      service_request_details.sr_work_history[0]?.payment_status === "completed"
    ) {
      return "completed";
    } else {
      return null;
    }
  };

  /* profile */
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}`,
    };
  }
  useEffect(() => {
    if (toastMessageCommentError) {
      setToastMessageCommentError(null);
    }
  }, [toastMessageCommentError]);
  const mobileMatches = useMediaQuery("(min-width:690px)");
  const [imageModal, setImageModal] = useState(false);

  const handleSearchInput = useCallback(
    (e) => {
      setSearchInput(e);
      if (open) {
        Api.getServiceRequestOpenList(e, filterData).then((res) => {
          setOpenSRList(res);
          setHovered(0);
          setServiceRequestId(res.length > 0 && res[0]?.id);
          setSRDetailArray(res.length > 0 && [res[0]]);
          const sr = res.length > 0 && [res[0]];
          if (res.length > 0) {
            formatDate(sr[0]?.created_at);
            setServiceStatus(sr[0]?.status);
          } else {
            setServiceStatus("");
            setServiceRequestId("");
            setSRDetailArray([]);
          }
        });
      } else if (inProgress) {
        Api.getServiceRequestAcceptedList(e, filterData).then((res) => {
          setInprogressSRList(res);
          const sr = res.length > 0 && [res[0]];
          if (res.length > 0) {
            formatDate(sr[0]?.created_at);
            setServiceStatus(sr[0]?.status);
            setHovered(0);
            setServiceRequestId(res.length > 0 && res[0]?.id);
            setSRDetailArray(res.length > 0 && [res[0]]);
          } else {
            setServiceStatus("");
            setServiceRequestId("");
            setSRDetailArray([]);
          }
        });
      } else if (completed) {
        Api.getServiceRequestClosedList(e, filterData).then((res) => {
          setCompleteSRList(res);
          const sr = res.length > 0 && [res[0]];
          if (res.length > 0) {
            formatDate(sr[0]?.created_at);
            setServiceStatus(sr[0]?.status);
            setHovered(0);
            setServiceRequestId(res.length > 0 && res[0]?.id);
            setSRDetailArray(res.length > 0 && [res[0]]);
          } else {
            setServiceStatus("");
            setServiceRequestId("");
            setSRDetailArray([]);
          }
        });
      }
    },
    [
      filterData,
      setOpenSRList,
      formatDate,
      setServiceStatus,
      setServiceRequestId,
      setSRDetailArray,
      setHovered,
      setInprogressSRList,
    ]
  );

  // const handleSearchInput = (e) => {
  //   setSearchInput(e);
  //   if (open) {
  //     Api.getServiceRequestOpenList(e, filterData).then((res) => {
  //       setOpenSRList(res);
  //       setHovered(0);
  //       setServiceRequestId(res.length > 0 && res[0]?.id);
  //       setSRDetailArray(res.length > 0 && [res[0]]);
  //       const sr = res.length > 0 && [res[0]];
  //       if (res.length > 0) {
  //         formatDate(sr[0]?.created_at);
  //         setServiceStatus(sr[0]?.status);
  //       } else {
  //         setServiceStatus("");
  //         setServiceRequestId("");
  //         setSRDetailArray([]);
  //       }
  //     });
  //   } else if (inProgress) {
  //     Api.getServiceRequestAcceptedList(e, filterData).then((res) => {
  //       setInprogressSRList(res);
  //       const sr = res.length > 0 && [res[0]];
  //       if (res.length > 0) {
  //         formatDate(sr[0]?.created_at);
  //         setServiceStatus(sr[0]?.status);
  //         setHovered(0);
  //         setServiceRequestId(res.length > 0 && res[0]?.id);
  //         setSRDetailArray(res.length > 0 && [res[0]]);
  //       } else {
  //         setServiceStatus("");
  //         setServiceRequestId("");
  //         setSRDetailArray([]);
  //       }
  //     });
  //   } else if (completed) {
  //     Api.getServiceRequestClosedList(e, filterData).then((res) => {
  //       setCompleteSRList(res);
  //       const sr = res.length > 0 && [res[0]];
  //       if (res.length > 0) {
  //         formatDate(sr[0]?.created_at);
  //         setServiceStatus(sr[0]?.status);
  //         setHovered(0);
  //         setServiceRequestId(res.length > 0 && res[0]?.id);
  //         setSRDetailArray(res.length > 0 && [res[0]]);
  //       } else {
  //         setServiceStatus("");
  //         setServiceRequestId("");
  //         setSRDetailArray([]);
  //       }
  //     });
  //   }
  // };
  const handleFilterData = (e) => {
    setFilterData(e);
    if (open) {
      Api.getServiceRequestOpenList(searchInput, e).then((res) => {
        setOpenSRList(res);
        const sr = res.length > 0 && [res[0]];
        if (res.length > 0) {
          formatDate(sr[0]?.created_at);
          setServiceStatus(sr[0]?.status);
          setHovered(0);
          setServiceRequestId(res.length > 0 && res[0]?.id);
          setSRDetailArray(res.length > 0 && [res[0]]);
        } else {
          setServiceStatus("");
          setServiceRequestId("");
          setSRDetailArray([]);
        }
      });
    } else if (inProgress) {
      Api.getServiceRequestAcceptedList(searchInput, e).then((res) => {
        setInprogressSRList(res);
        const sr = res.length > 0 && [res[0]];
        if (res.length > 0) {
          formatDate(sr[0]?.created_at);
          setServiceStatus(sr[0]?.status);
          setHovered(0);
          setServiceRequestId(res.length > 0 && res[0]?.id);
          setSRDetailArray(res.length > 0 && [res[0]]);
        } else {
          setServiceStatus("");
          setServiceRequestId("");
          setSRDetailArray([]);
        }
      });
    } else if (completed) {
      Api.getServiceRequestClosedList(searchInput, e).then((res) => {
        setCompleteSRList(res);
        const sr = res.length > 0 && [res[0]];
        if (res.length > 0) {
          formatDate(sr[0]?.created_at);
          setServiceStatus(sr[0]?.status);
          setHovered(0);
          setServiceRequestId(res.length > 0 && res[0]?.id);
          setSRDetailArray(res.length > 0 && [res[0]]);
        } else {
          setServiceStatus("");
          setServiceRequestId("");
          setSRDetailArray([]);
        }
      });
    }
  };
  const handleChangeRating = (e) => {
    console.log(e, "recieved change rating");
    setRating(e);
  };
  const getReviewbyId = (revieweeId, entityId) => {
    Api.getReview(revieweeId, entityId).then((res) => {
      setRating(res[0]?.rating_value ? res[0]?.rating_value : 4);
      setComment(res[0]?.comment ? res[0]?.comment : "");
    });
  };
  return (
    <>
      <Modal
        show={ratingModal}
        onHide={() => setRatingModal(false)}
        centered
        size="md"
        className="filter-modal"
      >
        <div className="p-3">
          <div
            style={{
              fontWeight: "600",
              fontSize: "25px",
            }}
          >
            Ratings and Reviews
          </div>
          <div
            style={{
              marginLeft: "2px",
              marginTop: "10px",
            }}
          >
            <text>
              <b>Customer Name: </b>{" "}
            </text>
            <text>{custName}</text>
          </div>
          <div
            style={{
              marginLeft: "2px",
            }}
          >
            <text>
              <b>SR #:</b>{" "}
            </text>
            <text>{srId?.slice(-6)}</text>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Ratings
              rating={rating || 4}
              onChangeRating={handleChangeRating}
              fontSize={"40px"}
              isEditable
            />
          </div>
          <TextareaAutosize
            className="col-12"
            aria-label="Comment"
            placeholder="Write your review here..."
            minRows={3}
            style={{
              width: "100%",
              marginTop: "10px",
              padding: "8px",
            }}
            value={comment}
            onChange={(e) => {
              console.log("comment changed", e.target.value);
              setComment(e.target.value);
            }}
          />
          <div
            className="d-flex align-items-center mt-3 gap-2"
            style={{
              justifyContent: "flex-end",
              width: "100%",
              cursor: "pointer",
            }}
          >
            <button
              className="filter-apply-btn"
              onClick={() => handlePostReview()}
            >
              Post
            </button>
          </div>
        </div>
      </Modal>
      <div style={{ overflow: "hidden" }}>
        <Topbar />
        <div
          className="row container-fluid"
          style={{ paddingRight: "0px", overflow: "hidden" }}
        >
          <div className="col-lg-2 col-12 p-0 border border-right">
            <Sidebar />
          </div>
          <div
            className="col-12 user_management"
            style={{ paddingRight: "0px", marginTop: "80px" }}
          >
            {loadingData ? (
              <div
                className="d-flex align-items-center"
                style={{ justifyContent: "center", height: "70vh" }}
              >
                <SpinnerLoader />
              </div>
            ) : (
              <div
                className="container-fluid"
                style={{ overflow: "hidden", padding: "0px" }}
              >
                <div className="row">
                  <div className="col-lg-5 mt-2 service_listing">
                    <div className="col-5 col-12 ">
                      <i
                        className="bi bi-chevron-left"
                        onClick={() => navigate(-1)}
                        style={{
                          fontWeight: "600",
                          fontSize: "21px",
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                        }}
                      ></i>
                      <text
                        className="p-0"
                        style={{
                          fontWeight: "600",
                          color: "#1E232C",
                          fontSize: "21px",
                          width: "208px",
                          height: "32px",
                        }}
                      >
                        My SRs
                      </text>
                      <Tabs
                        className="w-100 bg-white"
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                      >
                        <Tab
                          // className="font-12"
                          style={{
                            fontSize: "12px",
                            fontWeight: "400",
                            color: open ? "rgb(235, 129, 40)" : "#6A707C",
                          }}
                          label={`Open `}
                          {...a11yProps(0)}
                          onClick={() => {
                            setOpen(true);
                            setCompleted(false);
                            setInProgress(false);
                            handleOpenSR();
                          }}
                        />
                        <Tab
                          // className="font-12 "
                          style={{
                            fontSize: "12px",
                            fontWeight: "400",
                            color: inProgress ? "rgb(235, 129, 40)" : "#6A707C",
                          }}
                          label={`In Progress`}
                          {...a11yProps(1)}
                          onClick={() => {
                            setOpen(false);
                            setInProgress(true);
                            setCompleted(false);
                            handleInProgressSR();
                          }}
                        />
                        <Tab
                          // className="font-12"
                          style={{
                            fontSize: "12px",
                            fontWeight: "400",
                            color: completed ? "rgb(235, 129, 40)" : "#6A707C",
                          }}
                          label={`Completed`}
                          {...a11yProps(2)}
                          onClick={() => {
                            setOpen(false);
                            setInProgress(false);
                            setCompleted(true);
                            handleCompletedSR();
                          }}
                        />
                      </Tabs>
                      <FilterModule
                        inputPlaceholder={"Search by SR ID"}
                        onSearchInputChange={handleSearchInput}
                        onChangeFilterData={handleFilterData}
                        showDateMethod={completed === true ? true : false}
                        tabValue={tabSwitch}
                      />

                      <div>
                        {/* Open */}
                        {/* <CustomTabPanel value={value} index={0}>
                            <div style={{ overflow: "hidden" }}>
                              {openSRList.length > 0 ? (
                                openSRList.map((open, index) => {
                                  return (
                                    <div
                                      key={index}
                                      style={{
                                        height: "65px",
                                        cursor: "pointer",
                                        background:
                                          hovered === index
                                            ? "linear-gradient(to right,#FFE1CA , #FFF6EF00)"
                                            : undefined,
                                        color:
                                          hovered === index
                                            ? "rgb(235, 129, 40)"
                                            : "black",
                                      }}
                                      className={`border rounded-3 mt-2 d-flex ${hovered === index && "boder-rgb(235, 129, 40)"
                                        }`}
                                      onClick={() =>
                                        handleOpenSRById(open.id, index)
                                      }
                                    >
                                      <div
                                        style={{
                                          position: "relative",
                                          top: "8px",
                                          right: "1px",
                                          display: hovered !== index && "none",
                                        }}
                                      >
                                        <RectIcon />
                                      </div>
                                      <div
                                        className="p-2"
                                        style={{
                                          width: "-webkit-fill-available",
                                          marginLeft:
                                            hovered !== index && "5px",
                                        }}
                                      >
                                        <div
                                          className="d-flex"
                                          style={{
                                            fontWeight: "600",
                                            fontSize: "14px",
                                            color: "#1E232C",
                                          }}
                                        >
                                          <div>SR #: </div>
                                          <div
                                            className="d-flex align-items-center"
                                            style={{ marginLeft: "3px" }}
                                          >
                                            <div style={{ marginTop: "-3px" }}>
                                              ⁎⁎⁎
                                            </div>
                                            <div>
                                              {
                                                open?.id?.split("-")[
                                                open?.id?.split("-")?.length -
                                                1
                                                ]
                                              }
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-6 text-start">
                                            <text
                                              style={{
                                                color: "#1E232C",
                                                fontWeight: "400",
                                                fontSize: "12px",
                                              }}
                                            >
                                              {open.appliance_name}
                                            </text>
                                          </div>
                                          <div className="col-6 text-end">
                                            <i className="ms-lg-4 ms-2 bi bi-arrow-right fs-6"></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <p className="text-center">No Data found!</p>
                              )}
                            </div>
                          </CustomTabPanel> */}
                        <div
                          className="custom-scrollbar-technician"
                          style={{
                            width: "100%",
                            // height: "70vh",
                            overflowY: "scroll",
                          }}
                        >
                          {/* <Scrollbars
                            onScroll={debouncedHandleScroll}
                            style={{ height: "calc(100vh - 312px)" }}
                          > */}
                          <CustomTabPanel value={value} index={0}>
                            <div className="row-lg" style={{ display: "flex" }}>
                              <div className="col w-100">
                                {openSRList.length > 0
                                  ? openSRList.map((progress, index) => {
                                    const progressPercentage = () => {
                                      if (
                                        progress?.status === "accepted" &&
                                        progress.sr_work_history[0]
                                          .payment_status === null
                                      ) {
                                        return "50";
                                      } else if (
                                        progress?.status === "accepted" &&
                                        progress.sr_work_history[0]
                                          .payment_status === "initiated"
                                      ) {
                                        return "50";
                                      } else if (
                                        progress?.status === "assigned"
                                      ) {
                                        return "30";
                                      } else if (
                                        progress?.status === "accepted" &&
                                        progress.sr_work_history[0]
                                          .payment_status === "completed"
                                      ) {
                                        return "80";
                                      } else if (
                                        progress?.status === "closed"
                                      ) {
                                        return "100";
                                      } else if (
                                        progress?.status === "cancelled"
                                      ) {
                                        return "0";
                                      } else {
                                        return "0";
                                      }
                                    };
                                    return (
                                      <div
                                        className={`d-flex border mb-2 w-100 p-2 ${hovered === index &&
                                          "boder-rgb(235, 129, 40)"
                                          }`}
                                        style={{
                                          background:
                                            hovered === index
                                              ? "linear-gradient(to right,#FFE1CA , #FFF6EF00)"
                                              : undefined,
                                          cursor: "pointer",
                                          borderRadius: "10px",
                                        }}
                                        onClick={() =>
                                          handleOpenSRById(progress.id, index)
                                        }
                                      >
                                        <div
                                          style={{
                                            position: "relative",
                                            right: "9px",
                                            display:
                                              hovered === index ? "" : "none",
                                          }}
                                        >
                                          <RectIcon />
                                        </div>
                                        <div
                                          style={{
                                            width: "100%",
                                            marginLeft:
                                              hovered !== index && "5px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div
                                              className="d-flex"
                                              style={{
                                                fontWeight: "600",
                                                fontSize: "14px",
                                                color: "#1E232C",
                                              }}
                                            >
                                              <div>SR #: </div>
                                              <div
                                                className="d-flex"
                                                style={{
                                                  marginLeft: "3px",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    marginTop: "-1px",
                                                  }}
                                                ></div>
                                                <div>
                                                  {progress?.id?.slice(-6)}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="">
                                              <ProgressBar
                                                percentage={progressPercentage()}
                                              />
                                            </div>
                                          </div>
                                          <div
                                            className="d-flex"
                                            style={{
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div>
                                              <div>
                                                <div
                                                  style={{
                                                    marginTop: 13,
                                                    // display: "flex",
                                                    // alignItems: "center",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                      marginLeft: 0,
                                                      // marginTop: 20,
                                                    }}
                                                  >
                                                    {progress?.appliance_name}
                                                  </div>
                                                  <div
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                      marginLeft: 0,
                                                      // marginTop: 20,
                                                    }}
                                                  >
                                                    {
                                                      progress?.appliance_model
                                                    }
                                                    ,{" "}
                                                    {progress?.appliance_size}
                                                  </div>
                                                </div>
                                              </div>

                                              <div
                                                className=""
                                                style={{
                                                  fontWeight: "500",
                                                  fontSize: "13px",
                                                }}
                                              >
                                                {progress.fullname}
                                              </div>
                                            </div>
                                            <div>
                                              <div className="font-12 mt-3">
                                                Created date
                                              </div>
                                              <div
                                                className="d-flex gap-1"
                                                style={{
                                                  fontWeight: "500",
                                                  fontSize: "12px",
                                                  fontWeight: "600",
                                                  color: "#606060",
                                                }}
                                              >
                                                <div>
                                                  <CalendarIcon />
                                                </div>
                                                <div
                                                  style={{
                                                    paddingTop: "2px",
                                                  }}
                                                >
                                                  {moment(
                                                    progress?.created_at
                                                  ).format(
                                                    "MMM DD YYYY, hh:mm a"
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              style={{
                                                position: "relative",
                                                top: "15px",
                                              }}
                                            >
                                              {hovered === index ? (
                                                <ArrowIconHighlighted />
                                              ) : (
                                                <ArrowIconDefault />
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                  : // <p className="text-center">No Data found!</p>
                                  ""}
                              </div>
                            </div>
                          </CustomTabPanel>
                          {/* Inprogress */}

                          <CustomTabPanel value={value} index={1}>
                            <div className="row-lg" style={{ display: "flex" }}>
                              <div className="col w-100">
                                {inprogressSRList?.length > 0
                                  ? inprogressSRList?.map((progress, index) => {
                                    const progressPercentage = () => {
                                      if (
                                        progress?.status === "accepted" &&
                                        progress?.sr_work_history[0]
                                          .payment_status === null
                                      ) {
                                        return "50";
                                      } else if (
                                        progress?.status === "accepted" &&
                                        progress?.sr_work_history[0]
                                          .payment_status === "initiated"
                                      ) {
                                        return "50";
                                      } else if (
                                        progress?.status === "accepted" &&
                                        progress?.sr_work_history[0]
                                          .payment_status === "completed"
                                      ) {
                                        return "80";
                                      } else if (
                                        progress?.status === "closed"
                                      ) {
                                        return "100";
                                      } else if (
                                        progress?.status === "cancelled"
                                      ) {
                                        return "0";
                                      } else {
                                        return "0";
                                      }
                                    };
                                    return (
                                      <div
                                        className={`d-flex border mb-2 w-100 p-2 ${hovered === index &&
                                          "boder-rgb(235, 129, 40)"
                                          }`}
                                        style={{
                                          background:
                                            hovered === index
                                              ? "linear-gradient(to right,#FFE1CA , #FFF6EF00)"
                                              : undefined,
                                          cursor: "pointer",
                                          borderRadius: "10px",
                                        }}
                                        onClick={() =>
                                          handleInProgressSRById(
                                            progress?.id,
                                            index
                                          )
                                        }
                                      >
                                        <div
                                          style={{
                                            position: "relative",
                                            right: "9px",
                                            display:
                                              hovered === index ? "" : "none",
                                          }}
                                        >
                                          <RectIcon />
                                        </div>
                                        <div
                                          style={{
                                            width: "100%",
                                            marginLeft:
                                              hovered !== index && "5px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div
                                              className="d-flex"
                                              style={{
                                                fontWeight: "600",
                                                fontSize: "14px",
                                                color: "#1E232C",
                                              }}
                                            >
                                              <div>SR #: </div>
                                              <div
                                                className="d-flex"
                                                style={{
                                                  marginLeft: "3px",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    marginTop: "-1px",
                                                  }}
                                                ></div>
                                                <div>
                                                  {progress?.id?.slice(-6)}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="">
                                              <ProgressBar
                                                percentage={progressPercentage()}
                                              />
                                            </div>
                                          </div>
                                          <div
                                            className="d-flex"
                                            style={{
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div>
                                              <div
                                              // style={{
                                              //   display: "flex",
                                              //   alignItems: "center",
                                              // }}
                                              >
                                                <div
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "13px",
                                                    marginLeft: 0,
                                                    marginTop: 12,
                                                  }}
                                                >
                                                  {progress?.appliance_name}
                                                  {/* {progress?.appliance_model},{" "}
                                                  {progress?.appliance_size} */}
                                                </div>
                                                <div
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "13px",
                                                    marginLeft: 0,
                                                    // marginTop: 20,
                                                  }}
                                                >
                                                  {progress?.appliance_model},{" "}
                                                  {progress?.appliance_size}
                                                </div>
                                              </div>

                                              <div
                                                className=""
                                                style={{
                                                  fontWeight: "500",
                                                  fontSize: "13px",
                                                }}
                                              >
                                                {progress.fullname}
                                              </div>
                                            </div>
                                            <div>
                                              <div className="font-12 mt-3">
                                                Created date
                                              </div>
                                              <div
                                                className="d-flex gap-1"
                                                style={{
                                                  fontWeight: "500",
                                                  fontSize: "12px",
                                                  fontWeight: "600",
                                                  color: "#606060",
                                                }}
                                              >
                                                <div>
                                                  <CalendarIcon />
                                                </div>
                                                <div
                                                  style={{
                                                    paddingTop: "2px",
                                                  }}
                                                >
                                                  {moment(
                                                    progress?.created_at
                                                  ).format(
                                                    "MMM DD YYYY, hh:mm a"
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              style={{
                                                position: "relative",
                                                top: "15px",
                                              }}
                                            >
                                              {hovered === index ? (
                                                <ArrowIconHighlighted />
                                              ) : (
                                                <ArrowIconDefault />
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                  : // <p className="text-center">No Data found!</p>
                                  ""}
                              </div>
                            </div>
                          </CustomTabPanel>

                          {/* Completed */}

                          <CustomTabPanel value={value} index={2}>
                            <div style={{ overflow: "hidden" }}>
                              <div
                                className="row-lg"
                                style={{ display: "flex" }}
                              >
                                <div className="col w-100">
                                  {completeSRList.length > 0
                                    ? completeSRList.map((progress, index) => {
                                      const progressPercentage = () => {
                                        if (
                                          progress?.status === "accepted" &&
                                          progress.sr_work_history[0]
                                            .payment_status === null
                                        ) {
                                          return "50";
                                        } else if (
                                          progress?.status === "accepted" &&
                                          progress.sr_work_history[0]
                                            .payment_status === "initiated"
                                        ) {
                                          return "50";
                                        } else if (
                                          progress?.status === "assigned"
                                        ) {
                                          return "30";
                                        } else if (
                                          progress?.status === "accepted" &&
                                          progress.sr_work_history[0]
                                            .payment_status === "completed"
                                        ) {
                                          return "80";
                                        } else if (
                                          progress?.status === "closed"
                                        ) {
                                          return "100";
                                        } else if (
                                          progress?.status === "cancelled"
                                        ) {
                                          return "0";
                                        } else {
                                          return "0";
                                        }
                                      };
                                      return (
                                        <div
                                          className={`d-flex border mb-2 w-100 p-2 ${hovered === index &&
                                            "boder-rgb(235, 129, 40)"
                                            }`}
                                          style={{
                                            background:
                                              hovered === index
                                                ? "linear-gradient(to right,#FFE1CA , #FFF6EF00)"
                                                : undefined,
                                            cursor: "pointer",
                                            borderRadius: "10px",
                                          }}
                                          onClick={() =>
                                            handleCompletedSRById(
                                              progress?.id,
                                              index
                                            )
                                          }
                                        >
                                          <div
                                            style={{
                                              position: "relative",
                                              right: "9px",
                                              display:
                                                hovered === index
                                                  ? ""
                                                  : "none",
                                            }}
                                          >
                                            <RectIcon />
                                          </div>
                                          <div
                                            style={{
                                              width: "100%",
                                              marginLeft:
                                                hovered !== index && "5px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent:
                                                  "space-between",
                                              }}
                                            >
                                              <div
                                                className="d-flex"
                                                style={{
                                                  fontWeight: "600",
                                                  fontSize: "14px",
                                                  color: "#1E232C",
                                                }}
                                              >
                                                <div>SR #: </div>
                                                <div
                                                  className="d-flex"
                                                  style={{
                                                    marginLeft: "3px",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      marginTop: "-1px",
                                                    }}
                                                  ></div>
                                                  <div>
                                                    {progress?.id?.slice(-6)}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="">
                                                <ProgressBar
                                                  percentage={progressPercentage()}
                                                />
                                              </div>
                                            </div>
                                            <div
                                              className="d-flex"
                                              style={{
                                                justifyContent:
                                                  "space-between",
                                              }}
                                            >
                                              <div>
                                                <div>
                                                  <div
                                                  // style={{
                                                  //   display: "flex",
                                                  //   alignItems: "center",
                                                  // }}
                                                  >
                                                    <div
                                                      style={{
                                                        fontWeight: "500",
                                                        fontSize: "13px",
                                                        marginLeft: 0,
                                                        // marginTop: 20,
                                                      }}
                                                    >
                                                      {
                                                        progress?.appliance_name
                                                      }

                                                      {/* {
                                                        progress?.appliance_model
                                                      }
                                                      ,{" "}
                                                      {
                                                        progress?.appliance_size
                                                      } */}
                                                    </div>
                                                    <div
                                                      style={{
                                                        fontWeight: "500",
                                                        fontSize: "13px",
                                                        marginLeft: 0,
                                                        // marginTop: 20,
                                                      }}
                                                    >
                                                      {
                                                        progress?.appliance_model
                                                      }
                                                      ,{" "}
                                                      {
                                                        progress?.appliance_size
                                                      }
                                                    </div>
                                                  </div>
                                                </div>

                                                <div
                                                  className=""
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  {progress?.fullname}
                                                </div>
                                              </div>
                                              {progress?.sr_work_history[0]
                                                ?.closed_at ? (
                                                <div>
                                                  <div className="font-12">
                                                    {/* Closed Date */}
                                                    Created date
                                                  </div>
                                                  <div
                                                    className="d-flex gap-1"
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "12px",
                                                      fontWeight: "600",
                                                      color: "#606060",
                                                    }}
                                                  >
                                                    <div>
                                                      <CalendarIcon />
                                                    </div>
                                                    <div
                                                      style={{
                                                        paddingTop: "2px",
                                                      }}
                                                    >
                                                      {/* {moment(
                                                          progress
                                                            ?.sr_work_history[0]
                                                            ?.closed_at
                                                        ).format(
                                                          "DD MMM YYYY, hh:mm a"
                                                        )} */}
                                                      {moment(
                                                        progress?.created_at
                                                      ).format(
                                                        "DD MMM YYYY, hh:mm a"
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : (
                                                <div>
                                                  <div className="font-12">
                                                    Created date
                                                  </div>
                                                  <div
                                                    className="d-flex gap-1"
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "12px",
                                                      fontWeight: "600",
                                                      color: "#606060",
                                                    }}
                                                  >
                                                    <div>
                                                      <CalendarIcon />
                                                    </div>
                                                    <div
                                                      style={{
                                                        paddingTop: "2px",
                                                      }}
                                                    >
                                                      {moment(
                                                        progress?.created_at
                                                      ).format(
                                                        "DD MMM YYYY, hh:mm a"
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                              <div
                                                style={{
                                                  position: "relative",
                                                  top: "15px",
                                                }}
                                              >
                                                {hovered === index ? (
                                                  <ArrowIconHighlighted />
                                                ) : (
                                                  <ArrowIconDefault />
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                    : // <p className="text-center">
                                    //   No Data found!
                                    // </p>
                                    ""}
                                </div>
                              </div>
                            </div>
                          </CustomTabPanel>
                          {/* </Scrollbars> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-7 service_details"
                    style={{
                      overflow: "hidden",
                      // margin: mobileMatches ? "2rem auto" : "",
                    }}
                  >
                    {serviceRequestId &&
                      SrDetailArray?.find((val) => val.id === serviceRequestId)
                        ?.status !== "cancelled" ? (
                      <div className="mb-5">
                        <div className="p-lg-2">
                          <div className="row mt-3">
                            <div className="col text-start">
                              {serviceRequestId ? (
                                <div>
                                  <p
                                    className="m-0"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "#1E232C",
                                    }}
                                  >
                                    <div className="d-flex align-items-center">
                                      <div>SR #:</div>
                                      <div className="d-flex align-items-center">
                                        <div
                                          style={{
                                            marginTop: "-3px",
                                            marginLeft: "3px",
                                          }}
                                        ></div>
                                        <div>{serviceRequestId?.slice(-6)}</div>
                                      </div>
                                    </div>
                                  </p>
                                  {SrDetailArray[0]?.status === "closed" ? (
                                    <p
                                      className="m-0"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        color: "gray",
                                      }}
                                    >
                                      Closed On:{" "}
                                      <b style={{ color: "#000" }}>
                                        {moment(
                                          SrDetailArray[0]?.sr_work_history[0]
                                            ?.closed_at
                                        ).format("DD MMM yyyy, hh:mm a")}
                                      </b>
                                      <div
                                        style={{
                                          textDecoration: "underline",
                                          textDecorationColor:
                                            "rgb(235, 129, 40)",
                                          cursor: "pointer",
                                          color: "rgb(235, 129, 40)",
                                        }}
                                        onClick={() => {
                                          getReviewbyId(
                                            SrDetailArray[0]?.user_id,
                                            SrDetailArray[0]?.id
                                          );
                                          setSrId(SrDetailArray[0]?.id);
                                          setCustName(
                                            SrDetailArray[0]?.full_name
                                          );
                                          setUserId(SrDetailArray[0]?.user_id);
                                          setRatingModal(true);
                                        }}
                                        onMouseOver={(e) =>
                                        (e.currentTarget.style.color =
                                          "darkorange")
                                        }
                                        onMouseOut={(e) =>
                                        (e.currentTarget.style.color =
                                          "rgb(235, 129, 40)")
                                        }
                                      >
                                        <span>
                                          <FaStar />
                                        </span>{" "}
                                        <span>Rate and Review</span>
                                      </div>
                                    </p>
                                  ) : (
                                    <p
                                      className="m-0"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        color: "gray",
                                      }}
                                    >
                                      Created On:{" "}
                                      <b style={{ color: "#000" }}>
                                        {serviceCreatedTime}
                                      </b>
                                    </p>
                                  )}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          {/* service request creation */}
                          <div className="p-0 mt-4 d-flex">
                            <div style={{ marginTop: "14px" }}>
                              <div
                                className="w-20 rounded-circle"
                                style={{
                                  backgroundColor: "#36A93F",
                                  height: "13px",
                                  width: "13px",
                                }}
                              ></div>
                            </div>
                            <div
                              className={`${isService ? "is-service-b-shadow-unset" : ""
                                }`}
                              style={{ width: "100%" }}
                            >
                              <Accordion
                                elevation={1}
                                style={{
                                  marginLeft: "10px",
                                }}
                              >
                                <AccordionSummary
                                  // expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  onClick={() => {
                                    setIsService(!isService);
                                  }}
                                >
                                  <Typography>
                                    Service Request Creation
                                    {isService ? (
                                      <KeyboardArrowUpIcon />
                                    ) : (
                                      <ExpandMoreIcon />
                                    )}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails className="accordian_details">
                                  <div className="row p-2 pb-0 ps-0">
                                    <div className="col-1 text-end p-0">
                                      <DoneIcon
                                        style={{
                                          color: "#36A93F",
                                          fontSize: "12px",
                                          // marginRight: "6px",
                                        }}
                                      />
                                    </div>
                                    <div className="col p-0 text-start">
                                      <p
                                        className=" p-0"
                                        style={{
                                          fontSize: "14px",
                                          color: "#36A93F",
                                          marginLeft: "6px",
                                        }}
                                      >
                                        SR Details
                                      </p>
                                    </div>
                                    {/* <div className="col p-0 text-end">
                                                     <p className="m-0 p-0 ms-2" style={{ fontSize: '12px', color: '#6B6D70' }}>15 mins ago</p>
                                                 </div> */}
                                  </div>

                                  <div className="row p-2 ps-0">
                                    <div className="col-1 text-center p-0 with-vertical-line"></div>
                                    <div className="col p-0 text-start">
                                      <table className="w-100 border-0">
                                        {SrDetailArray?.length > 0 ? (
                                          SrDetailArray?.map(
                                            (srDetail, index) => (
                                              <tbody>
                                                <p
                                                  className="m-0"
                                                  style={{
                                                    color: "black",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  Device:
                                                </p>

                                                <p
                                                  className="m-0 pe-2"
                                                  style={{
                                                    color: "black",
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                  }}
                                                >
                                                  {srDetail?.appliance_name
                                                    ? srDetail?.appliance_name
                                                    : ""}
                                                </p>

                                                <p
                                                  className="m-0"
                                                  style={{
                                                    color: "black",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  Brand:
                                                </p>

                                                <p
                                                  className="m-0 pe-2"
                                                  style={{
                                                    color: "black",
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                  }}
                                                >
                                                  {srDetail?.appliance_brand
                                                    ? srDetail?.appliance_brand
                                                    : ""}
                                                </p>

                                                <p
                                                  className="m-0"
                                                  style={{
                                                    color: "black",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  Model:
                                                </p>

                                                <p
                                                  className="m-0 pe-2"
                                                  style={{
                                                    color: "black",
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                  }}
                                                >
                                                  {srDetail?.appliance_model
                                                    ? srDetail?.appliance_model
                                                    : ""}
                                                </p>

                                                <p
                                                  className="m-0"
                                                  style={{
                                                    color: "black",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  Size:
                                                </p>

                                                <p
                                                  className="m-0 pe-2"
                                                  style={{
                                                    color: "black",
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                  }}
                                                >
                                                  {srDetail?.appliance_size
                                                    ? srDetail?.appliance_size +
                                                    '"'
                                                    : ""}
                                                </p>

                                                <p
                                                  className="m-0"
                                                  style={{
                                                    color: "black",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  Warranty Status:
                                                </p>

                                                <p
                                                  className="m-0 pe-2"
                                                  style={{
                                                    color: "black",
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                  }}
                                                >
                                                  {srDetail?.warranty_status
                                                    ? srDetail?.warranty_status
                                                    : ""}
                                                </p>

                                                {srDetail?.warranty_status ===
                                                  "active" && (
                                                    <div>
                                                      <p
                                                        className="m-0"
                                                        style={{
                                                          color: "black",
                                                          fontSize: "14px",
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        Warranty Until:
                                                      </p>

                                                      <p
                                                        className="m-0 pe-2"
                                                        style={{
                                                          color: "black",
                                                          fontSize: "14px",
                                                          fontWeight: "400",
                                                        }}
                                                      >
                                                        {srDetail?.warranty_date
                                                          ? moment(
                                                            srDetail?.warranty_date
                                                          ).format(
                                                            "DD MMM YYYY"
                                                          )
                                                          : ""}
                                                      </p>
                                                    </div>
                                                  )}
                                              </tbody>
                                            )
                                          )
                                        ) : (
                                          /*   <p>No data</p> */ <></>
                                        )}
                                      </table>
                                    </div>
                                  </div>
                                  <div className="row p-2 pb-0 ps-0">
                                    <div className="col-1 text-end p-0">
                                      <DoneIcon
                                        style={{
                                          color: "#36A93F",
                                          fontSize: "12px",
                                          // marginRight: "6px",
                                        }}
                                      />
                                    </div>
                                    <div className="col p-0 text-start">
                                      <p
                                        className=" p-0"
                                        style={{
                                          fontSize: "14px",
                                          color: "#36A93F",
                                          marginLeft: "6px",
                                        }}
                                      >
                                        Contact Details
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row p-2 ps-0">
                                    <div className="col-1 text-center p-0 with-vertical-line"></div>
                                    <div className="col p-0 text-start">
                                      <table className="w-100 border-0">
                                        {SrDetailArray.length > 0 ? (
                                          SrDetailArray.map(
                                            (srDetail, index) => (
                                              <tbody>
                                                <tr style={{ height: "35px" }}>
                                                  {/* <td className="text-start"> */}
                                                  <p
                                                    className="m-0"
                                                    style={{
                                                      color: "black",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                    }}
                                                  >
                                                    <b>Name:</b>
                                                  </p>
                                                  {/* </td> */}
                                                  {/* <td className="text-start"> */}
                                                  <p
                                                    className="m-0 pe-2"
                                                    style={{
                                                      color: "black",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                    }}
                                                  >
                                                    {srDetail?.serviceLocation
                                                      ? srDetail
                                                        ?.serviceLocation.name
                                                      : ""}
                                                  </p>
                                                  {/* </td> */}
                                                </tr>
                                                <tr
                                                  style={{
                                                    height: "35px",
                                                  }}
                                                >
                                                  {/* <td className="text-start"> */}
                                                  <p
                                                    className="m-0"
                                                    style={{
                                                      color: "black",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                      marginTop: 20,
                                                    }}
                                                  >
                                                    <b>Address:</b>
                                                  </p>
                                                  {/* </td> */}
                                                  {/* <td className="text-end"> */}
                                                  <p
                                                    className="m-0 pe-2"
                                                    style={{
                                                      color: "black",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                    }}
                                                  >
                                                    {srDetail?.serviceLocation
                                                      ? srDetail
                                                        ?.serviceLocation
                                                        .address
                                                      : ""}
                                                  </p>
                                                  {/* </td> */}
                                                </tr>
                                                <tr style={{ height: "35px" }}>
                                                  {/* <td className="text-start"> */}
                                                  <p
                                                    className="m-0"
                                                    style={{
                                                      color: "black",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                    }}
                                                  >
                                                    <b>District:</b>
                                                  </p>
                                                  {/* </td> */}
                                                  {/* <td className="text-end"> */}
                                                  <p
                                                    className="m-0 pe-2"
                                                    style={{
                                                      color: "black",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                    }}
                                                  >
                                                    {srDetail?.serviceLocation
                                                      ? srDetail
                                                        ?.serviceLocation
                                                        .city_name
                                                      : ""}
                                                  </p>
                                                  {/* </td> */}
                                                </tr>
                                                <tr style={{ height: "35px" }}>
                                                  {/* <td className="text-start"> */}
                                                  <p
                                                    className="m-0"
                                                    style={{
                                                      color: "black",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                    }}
                                                  >
                                                    <b>State:</b>
                                                  </p>
                                                  {/* </td> */}
                                                  {/* <td className="text-end"> */}
                                                  <p
                                                    className="m-0 pe-2"
                                                    style={{
                                                      color: "black",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                    }}
                                                  >
                                                    {srDetail?.serviceLocation
                                                      ? srDetail
                                                        ?.serviceLocation
                                                        .state_name
                                                      : ""}
                                                  </p>
                                                  {/* </td> */}
                                                </tr>
                                                <tr style={{ height: "35px" }}>
                                                  {/* <td className="text-start"> */}
                                                  <p
                                                    className="m-0"
                                                    style={{
                                                      color: "black",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                    }}
                                                  >
                                                    <b>Postal Code:</b>
                                                  </p>
                                                  {/* </td> */}
                                                  {/* <td className="text-end"> */}
                                                  <p
                                                    className="m-0 pe-2"
                                                    style={{
                                                      color: "black",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                    }}
                                                  >
                                                    {srDetail?.serviceLocation
                                                      ? srDetail
                                                        ?.serviceLocation
                                                        .postalcode
                                                      : ""}
                                                  </p>
                                                  {/* </td> */}
                                                </tr>
                                                <tr style={{ height: "35px" }}>
                                                  {/* <td className="text-start"> */}
                                                  <p
                                                    className="m-0"
                                                    style={{
                                                      color: "black",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                    }}
                                                  >
                                                    <b>Phone Number:</b>
                                                  </p>
                                                  {/* </td> */}
                                                  {/* <td className="text-end"> */}
                                                  <p
                                                    className="m-0 pe-2"
                                                    style={{
                                                      color: "black",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                    }}
                                                  >
                                                    {srDetail?.serviceLocation
                                                      ? srDetail
                                                        ?.serviceLocation
                                                        .mobile_no
                                                      : ""}
                                                  </p>
                                                  {/* </td> */}
                                                </tr>
                                              </tbody>
                                            )
                                          )
                                        ) : (
                                          <p>No data</p>
                                        )}
                                      </table>
                                    </div>
                                  </div>
                                  {SrDetailArray?.find(
                                    (val) => val?.id === serviceRequestId
                                  )?.problem_desc && (
                                      <div className="row p-2 pb-0 ps-0">
                                        <div className="col-1 text-end p-0">
                                          <DoneIcon
                                            style={{
                                              color: "#36A93F",
                                              fontSize: "12px",
                                              // marginRight: "6px",
                                            }}
                                          />
                                        </div>
                                        <div className="col p-0 text-start">
                                          <p
                                            className="m-0 p-0 ms-2"
                                            style={{
                                              fontSize: "14px",
                                              color: "#36A93F",
                                              marginLeft: "6px",
                                            }}
                                          >
                                            Complaint Details
                                          </p>
                                        </div>
                                        {/* <div className="col p-0 text-end">
                                                                <p className="m-0 p-0 ms-2" style={{ fontSize: '12px', color: '#6B6D70' }}>15 mins ago</p>
                                                            </div> */}
                                      </div>
                                    )}
                                  {SrDetailArray?.find(
                                    (val) => val?.id === serviceRequestId
                                  )?.problem_desc && (
                                      <div className="row p-2 pb-0 ps-0 mb-2">
                                        <div className="col-1 text-center p-0 with-vertical-line"></div>
                                        <div className="col p-0 text-start">
                                          <table className="w-100 border-0">
                                            {SrDetailArray.length > 0 ? (
                                              SrDetailArray.map(
                                                (srDetail, index) => (
                                                  <tbody>
                                                    <>
                                                      {srDetail.problem_desc
                                                        .length > 0 && (
                                                          <>
                                                            <tr
                                                              style={{
                                                                height: "35px",
                                                              }}
                                                            >
                                                              <p
                                                                className="m-0"
                                                                style={{
                                                                  color: "black",
                                                                  fontSize: "14px",
                                                                  fontWeight: "400",
                                                                }}
                                                              >
                                                                <b>Description:</b>
                                                              </p>
                                                              <p
                                                                className="m-0 pe-2"
                                                                style={{
                                                                  color: "black",
                                                                  fontSize: "14px",
                                                                  fontWeight: "400",
                                                                }}
                                                              >
                                                                {srDetail.problem_desc
                                                                  ? srDetail.problem_desc
                                                                  : ""}
                                                              </p>
                                                            </tr>
                                                          </>
                                                        )}
                                                      {srDetail.attachments
                                                        .length > 0 && (
                                                          <tr
                                                            style={{
                                                              height: "35px",
                                                            }}
                                                          >
                                                            <p
                                                              className="m-0"
                                                              style={{
                                                                color: "black",
                                                                fontSize: "14px",
                                                                fontWeight: "400",
                                                              }}
                                                            >
                                                              <b>Attachments:</b>
                                                            </p>
                                                            <button
                                                              onClick={() =>
                                                                setImageModal(true)
                                                              }
                                                              className="m-0 pe-2 items-btn"
                                                              style={{
                                                                color: "black",
                                                                fontSize: "12px",
                                                                fontWeight: "400",
                                                                cursor: "pointer",
                                                              }}
                                                            >
                                                              <IoMdPhotos className="img-icon" />{" "}
                                                              Item(s)
                                                            </button>
                                                            {/* <p
                                                                onClick={() =>
                                                                  setImageModal(
                                                                    true
                                                                  )
                                                                }
                                                                className="m-0 pe-2"
                                                                style={{
                                                                  color: "black",
                                                                  fontSize: "14px",
                                                                  fontWeight: "400",
                                                                  cursor: "pointer",
                                                                  textDecoration:
                                                                    "underline",
                                                                }}
                                                              >
                                                                {srDetail.attachments
                                                                  ? srDetail
                                                                    .attachments
                                                                    .length
                                                                  : ""}{" "}
                                                                Item(s)
                                                              </p> */}
                                                          </tr>
                                                        )}
                                                    </>
                                                  </tbody>
                                                )
                                              )
                                            ) : (
                                              <p>No data</p>
                                            )}
                                          </table>
                                        </div>
                                      </div>
                                    )}
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          </div>
                          {SrDetailArray[0]?.sr_work_history?.length > 1 && (
                            <div className="p-0 mt-3 d-flex">
                              <div style={{ marginTop: "12px" }}>
                                <div
                                  className="w-20 rounded-circle"
                                  style={{
                                    backgroundColor: "#36A93F",
                                    height: "13px",
                                    width: "13px",
                                  }}
                                ></div>
                              </div>
                              <div
                                className={`${isReopenSR ? "is-visit-b-shadow-unset" : ""
                                  }`}
                                style={{ width: "100%" }}
                              >
                                <Accordion
                                  elevation={1}
                                  style={{
                                    marginLeft: "10px",
                                  }}
                                >
                                  <AccordionSummary
                                    // expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    onClick={() => setIsReopenSR(!isReopenSR)}
                                  >
                                    <Typography>
                                      <Typography>
                                        History{" "}
                                        {!isReopenSR ? (
                                          <ExpandMoreIcon />
                                        ) : (
                                          <KeyboardArrowUpIcon />
                                        )}
                                      </Typography>
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails className="accordian_details">
                                    <div className="p-3 pt-0">
                                      {SrDetailArray[0]?.sr_work_history?.map((history, index) => (
                                        <div
                                          key={index}
                                          className="mt-2 mb-2"
                                          style={{ fontSize: "13px" }}
                                        >
                                          <div
                                            className="align-items-center"
                                            style={{ width: "100%" }}
                                          >
                                            <div style={{ fontWeight: 600 }}>
                                              Requested visit date:
                                            </div>
                                            <div style={{ fontSize: "12px" }}>
                                              {moment(history?.requested_visit_date).format("DD MMM YYYY")}
                                            </div>
                                          </div>
                                          <div
                                            className="align-items-center"
                                            style={{ width: "100%" }}
                                          >
                                            <div style={{ fontWeight: 600 }}>
                                              Requested visit time:
                                            </div>
                                            <div style={{ fontSize: "12px" }}>
                                              {history?.requested_visit_time}
                                            </div>
                                          </div>
                                          <div
                                            className="align-items-center"
                                            style={{ width: "100%" }}
                                          >
                                            <div style={{ fontWeight: 600 }}>
                                              Technician Name:
                                            </div>
                                            <div style={{ fontSize: "12px" }}>
                                              {history?.technician_name}
                                            </div>
                                          </div>
                                          <div
                                            className="align-items-center"
                                            style={{ width: "100%" }}
                                          >
                                            <div style={{ fontWeight: 600 }}>
                                              Technician Mobile No:
                                            </div>
                                            <div style={{ fontSize: "12px" }}>
                                              {history?.mobile_no}
                                            </div>
                                          </div>
                                          <div
                                            className="align-items-center"
                                            style={{ width: "100%" }}
                                          >
                                            <div style={{ fontWeight: 600 }}>
                                              Technician Email Id:
                                            </div>
                                            <div style={{ fontSize: "12px" }}>
                                              {history?.tech_email_id}
                                            </div>
                                          </div>
                                          <div
                                            className="align-items-center"
                                            style={{ width: "100%" }}
                                          >
                                            <div style={{ fontWeight: 600 }}>
                                              Closing Notes:
                                            </div>
                                            <div style={{ fontSize: "12px" }}>
                                              {history?.closing_notes}
                                            </div>
                                          </div>
                                          <div
                                            className="align-items-center"
                                            style={{ width: "100%" }}
                                          >
                                            <div style={{ fontWeight: 600 }}>
                                              Closed On:
                                            </div>
                                            <div style={{ fontSize: "12px" }}>
                                              {moment(history?.closed_at).format("DD MMM YYYY, HH:mm A")}
                                            </div>
                                          </div>
                                          <div
                                            className="align-items-center"
                                            style={{ width: "100%" }}
                                          >
                                            <div style={{ fontWeight: 600 }}>
                                              Total amount paid:
                                            </div>
                                            <div style={{ fontSize: "12px" }}>
                                              {history?.spare_service_charge
                                                ? history?.spare_service_charge
                                                : history?.basic_charge}
                                            </div>
                                          </div>
                                          {SrDetailArray[0]?.sr_work_history?.length !== index + 1 && (
                                            <div
                                              className="mt-2 mb-2"
                                              style={{
                                                height: "1px",
                                                width: "100%",
                                                backgroundColor: "#cbcbcb",
                                                borderRadius: "50px",
                                              }}
                                            />
                                          )}
                                        </div>
                                      ))}

                                    </div>
                                  </AccordionDetails>
                                </Accordion>
                              </div>
                            </div>
                          )}
                          {/* technician visit */}
                          <div className="p-0 mt-3 d-flex">
                            <div style={{ marginTop: "14px" }}>
                              <div
                                className="w-20 rounded-circle"
                                style={{
                                  backgroundColor:
                                    serviceStatus === "accepted" ||
                                      serviceStatus === "closed"
                                      ? "#36A93F"
                                      : "#FCB61A",
                                  height: "13px",
                                  width: "13px",
                                }}
                              ></div>
                            </div>
                            <div
                              className={`${isVisit ? "is-visit-b-shadow-unset" : ""
                                }`}
                              style={{ width: "100%" }}
                            >
                              <Accordion
                                elevation={1}
                                style={{
                                  marginLeft: "10px",
                                }}
                              >
                                <AccordionSummary
                                  // expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  onClick={() => setIsVisit(!isVisit)}
                                >
                                  <Typography>
                                    Technician visit{" "}
                                    {isVisit ? (
                                      <KeyboardArrowUpIcon />
                                    ) : (
                                      <ExpandMoreIcon />
                                    )}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails className="accordian_details">
                                  <div className="row p-2 pb-0 ps-0">
                                    <div className="col-1 text-end p-0">
                                      <DoneIcon
                                        style={{
                                          color: "#36A93F",
                                          fontSize: "12px",
                                          // marginRight: "6px",
                                        }}
                                      />
                                    </div>
                                    <div className="col p-0 text-start">
                                      <p
                                        className="p-0 ms-2"
                                        style={{
                                          fontSize: "15px",
                                          color: "#36A93F",
                                          marginLeft: "6px",
                                        }}
                                      >
                                        Visit Slot
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row p-2 m-0 ps-0">
                                    <div
                                      className="col-1 text-center p-0 with-vertical-line"
                                      style={{ transform: "translatex(-20%)" }}
                                    ></div>
                                    <div className="col p-0 text-start">
                                      <table className="w-100 border-0">
                                        <tbody>
                                          <tr style={{ height: "35px" }}>
                                            <td className="text-start">
                                              <p
                                                className="m-0"
                                                style={{
                                                  color: "black",
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                Confirmed Slot
                                              </p>
                                            </td>
                                            <td className="text-end">
                                              <p
                                                className="m-0"
                                                style={{
                                                  color: "black",
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                {SrDetailArray.length > 0 &&
                                                  SrDetailArray[0].sr_work_history[0]?.requested_visit_time?.toLowerCase() +
                                                  ", " +
                                                  moment(
                                                    SrDetailArray[0]
                                                      ?.sr_work_history[0]
                                                      ?.requested_visit_date
                                                  ).format("DD MMM YYYY")}
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>

                                  {/* Accepted tech */}
                                  {serviceStatus === "accepted" ||
                                    serviceStatus === "closed" ? (
                                    <div>
                                      <div className="row p-2 pb-0 ps-0">
                                        <div className="col-1 text-end p-0">
                                          <DoneIcon
                                            style={{
                                              color: "#36A93F",
                                              fontSize: "12px",
                                              // marginRight: "6px",
                                            }}
                                          />
                                        </div>
                                        <div className="col p-0 text-start">
                                          <p
                                            className="m-0 p-0 ms-2"
                                            style={{
                                              fontSize: "14px",
                                              color: "#36A93F",
                                              marginLeft: "6px",
                                            }}
                                          >
                                            {inProgress
                                              ? "You have accepted the SR"
                                              : completed
                                                ? "You have completed the SR"
                                                : "You have accepted the SR"}
                                          </p>
                                        </div>
                                      </div>
                                      {/* <div className="row p-2 ps-0 m-0">
                                        <div
                                          className="col-1 text-center  p-0 with-vertical-line"
                                          style={{
                                            transform: "translateX(-20%)",
                                          }}
                                        ></div>
                                        <div className="col p-0 text-start">
                                          <table className="w-100 border-0">
                                            <tbody>
                                              <tr style={{ height: "35px" }}>
                                                <td className="text-start">
                                                  <p
                                                    className="m-0"
                                                    style={{
                                                      color: "black",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                    }}
                                                  >
                                                    You have accepted this SR
                                                  </p>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div> */}
                                    </div>
                                  ) : (
                                    <div>
                                      <div className="row p-2 pb-0 ps-0">
                                        <div className="col-1 text-end p-0">
                                          <DoneIcon
                                            style={{
                                              color: "rgb(235, 129, 40)",
                                              fontSize: "12px",
                                              // marginRight: "6px",
                                            }}
                                          />
                                        </div>
                                        <div className="col p-0 text-start">
                                          <p
                                            className=" p-0 ms-2"
                                            style={{
                                              fontSize: "14px",
                                              color: "rgb(235, 129, 40)",
                                              marginLeft: "6px",
                                            }}
                                          >
                                            Your Confirmation
                                          </p>
                                        </div>
                                        {/* <div className="col p-0 text-end">
                                                 <p className="m-0 p-0 ms-2" style={{ fontSize: '12px', color: '#6B6D70' }}>11 mins ago</p>
                                             </div> */}
                                      </div>
                                      <div className="row p-2 ps-0 m-0">
                                        <div
                                          className="col-1 text-center  p-0 with-vertical-line-rgb(235, 129, 40)"
                                          style={{
                                            transform: "translateX(-20%)",
                                          }}
                                        ></div>
                                        <div className="ps-3 mt-4">
                                          <button
                                            className="ps-4 pe-4"
                                            style={{
                                              padding: "5px",
                                              backgroundColor:
                                                "rgb(235, 129, 40)",
                                              color: "#fff",
                                              borderRadius: "50px",
                                              fontSize: "12px",
                                              border: "none",
                                            }}
                                            onClick={() => handleAssignToMe()}
                                          >
                                            Accept SR
                                          </button>
                                          <button
                                            className="ps-4 pe-4 ms-2"
                                            style={{
                                              padding: "5px",
                                              backgroundColor: "gray",
                                              color: "#fff",
                                              borderRadius: "50px",
                                              fontSize: "12px",
                                              border: "none",
                                            }}
                                            onClick={() =>
                                              handleRejectServiceRequest()
                                            }
                                          >
                                            Reject SR
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          </div>

                          {/* Repair Estimated SLA */}
                          {serviceStatus === "nill" ? (
                            <ul className="p-0 mt-3">
                              <li
                                className="mt-2"
                                style={{ listStyle: "none" }}
                              >
                                <div
                                  className="w-20 rounded-circle"
                                  style={{
                                    backgroundColor: IsRepair
                                      ? "#FCB61A"
                                      : "#36A93F",
                                    height: "20px",
                                    width: "20px",
                                  }}
                                ></div>
                              </li>
                              <li
                                className="ms-2"
                                style={{ listStyle: "none" }}
                              >
                                <Accordion elevation={1}>
                                  <AccordionSummary
                                    // expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    onClick={() => setIsRepair(!IsRepair)}
                                  >
                                    <Typography>
                                      Repair Estimated SLA{" "}
                                      {IsRepair ? (
                                        <ExpandMoreIcon />
                                      ) : (
                                        <KeyboardArrowUpIcon />
                                      )}
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails className="accordian_details">
                                    <div className="row p-2">
                                      <div className="col-1 text-end p-0">
                                        <DoneIcon
                                          style={{
                                            color: "#36A93F",
                                            fontSize: "12px",
                                            marginRight: "6px",
                                          }}
                                        />
                                      </div>
                                      <div className="col p-0 text-start">
                                        <p
                                          className="m-0 p-0 ms-2"
                                          style={{
                                            fontSize: "15px",
                                            color: "rgb(235, 129, 40)",
                                          }}
                                        >
                                          Repair Excepted SLA
                                        </p>
                                      </div>
                                      {/* <div className="col p-0 text-end">
                                                         <p className="m-0 p-0 ms-2" style={{ fontSize: '13px', color: '#6B6D70' }}>45 secs ago</p>
                                                     </div> */}
                                    </div>
                                    <div className="row p-2">
                                      <div className="col-1 text-center p-0 with-vertical-line"></div>
                                      <div className="col p-0 text-start">
                                        <table className="w-100 border-0">
                                          <tbody>
                                            <tr style={{ height: "35px" }}>
                                              <td className="text-start">
                                                <p
                                                  className="m-0"
                                                  style={{
                                                    color: "black",
                                                    fontSize: "16px",
                                                    fontWeight: "400",
                                                  }}
                                                >
                                                  Your device will be ready
                                                </p>
                                              </td>
                                              <td className="text-end">
                                                <p
                                                  className="m-0"
                                                  style={{
                                                    color: "black",
                                                    fontSize: "16px",
                                                    fontWeight: "400",
                                                  }}
                                                >
                                                  in 48 hours
                                                </p>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </AccordionDetails>
                                </Accordion>
                              </li>
                            </ul>
                          ) : null}

                          {/* Closure Details */}
                          {(serviceStatus === "accepted" ||
                            serviceStatus === "closed") &&
                            (inProgress || completed) && (
                              <div className="p-0 mt-3 d-flex">
                                <div style={{ marginTop: "14px" }}>
                                  <div
                                    className="w-20 rounded-circle"
                                    style={{
                                      backgroundColor:
                                        getPaymentStatus() === null
                                          ? "#FCB61A"
                                          : (getPaymentStatus() ===
                                            "completed" ||
                                            getPaymentStatus() ===
                                            "initiated" ||
                                            getPaymentStatus() ===
                                            "closed") &&
                                          "#36A93F",
                                      height: "13px",
                                      width: "13px",
                                    }}
                                  ></div>
                                </div>
                                <div
                                  className={`${isClosure ? "is-visit-b-shadow-unset" : ""
                                    }`}
                                  style={{ width: "100%" }}
                                >
                                  <Accordion
                                    elevation={1}
                                    style={{
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <AccordionSummary
                                      // expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                      onClick={() => setIsClosure(!isClosure)}
                                    >
                                      <Typography>
                                        Closure Details{" "}
                                        {isClosure ? (
                                          <KeyboardArrowUpIcon />
                                        ) : (
                                          <ExpandMoreIcon />
                                        )}
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="accordian_details">
                                      {SrDetailArray.length > 0 &&
                                        SrDetailArray.map((srDetail, index) => {
                                          if (srDetail?.id != currentId) {
                                            setCommentInput("");
                                            setSparePartsCharge("");
                                            setCurrentId(srDetail?.id); // Update currentIndex state if you are using it
                                          }

                                          return (
                                            <div>
                                              <div className="row p-2 pb-0 ps-0">
                                                <div className="col-1 text-end p-0">
                                                  <DoneIcon
                                                    style={{
                                                      color:
                                                        srDetail
                                                          ?.sr_work_history[0]
                                                          ?.payment_status ===
                                                          "initiated" ||
                                                          srDetail
                                                            ?.sr_work_history[0]
                                                            ?.payment_status ===
                                                          "completed"
                                                          ? "#36A93F"
                                                          : "rgb(235, 129, 40)",
                                                      fontSize: "12px",
                                                      // marginRight: "6px",
                                                    }}
                                                  />
                                                </div>
                                                <div className="col p-0 text-start">
                                                  <p
                                                    className="m-0 p-0 ms-2"
                                                    style={{
                                                      fontSize: "15px",
                                                      marginLeft: "6px",
                                                      color:
                                                        srDetail
                                                          ?.sr_work_history[0]
                                                          ?.payment_status ===
                                                          "initiated" ||
                                                          srDetail
                                                            ?.sr_work_history[0]
                                                            ?.payment_status ===
                                                          "completed"
                                                          ? "#36A93F"
                                                          : "rgb(235, 129, 40)",
                                                    }}
                                                  >
                                                    Your Closure Details
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="row p-2 m-0 ps-0">
                                                <div
                                                  className={`col-1 text-center p-0 ${srDetail?.sr_work_history[0]
                                                    ?.payment_status ===
                                                    "initiated" ||
                                                    srDetail?.sr_work_history[0]
                                                      ?.payment_status ===
                                                    "completed"
                                                    ? "with-vertical-line"
                                                    : "with-vertical-line-rgb(235, 129, 40)"
                                                    }`}
                                                  style={{
                                                    transform:
                                                      "translatex(-20%)",
                                                  }}
                                                ></div>
                                                <div className="col p-0 text-start">
                                                  <table className="w-100 border-0">
                                                    <tbody>
                                                      <tr
                                                        style={{
                                                          height: "35px",
                                                        }}
                                                      >
                                                        <td className="text-start">
                                                          {srDetail
                                                            ?.sr_work_history[0]
                                                            ?.payment_status ===
                                                            "initiated" ||
                                                            srDetail
                                                              ?.sr_work_history[0]
                                                              ?.payment_status ===
                                                            "completed" ? (
                                                            <div
                                                              style={{
                                                                fontSize:
                                                                  "14px",
                                                              }}
                                                            >
                                                              {
                                                                srDetail
                                                                  ?.sr_work_history[0]
                                                                  ?.closing_notes
                                                              }
                                                            </div>
                                                          ) : (
                                                            <>
                                                              <textarea
                                                                onChange={(e) =>
                                                                  handleClosureComment(
                                                                    e
                                                                  )
                                                                }
                                                                style={{
                                                                  width: "100%",
                                                                  fontSize:
                                                                    "12px",
                                                                  outline:
                                                                    "none",
                                                                  padding:
                                                                    "8px",
                                                                  border: `1px solid ${commentInput.length ===
                                                                    0 &&
                                                                    commentInputError
                                                                    ? "#f00"
                                                                    : "gray"
                                                                    }`,
                                                                  borderRadius:
                                                                    "7px",
                                                                }}
                                                                placeholder="Type your notes on closure of this SR"
                                                                value={
                                                                  commentInput
                                                                }
                                                              />
                                                              {commentInput.length ===
                                                                0 &&
                                                                commentInputError && (
                                                                  <div
                                                                    style={{
                                                                      color:
                                                                        "red",
                                                                      fontSize:
                                                                        "12px",
                                                                    }}
                                                                  >
                                                                    {
                                                                      commentInputError
                                                                    }
                                                                  </div>
                                                                )}
                                                            </>
                                                          )}
                                                        </td>
                                                        <td className="text-end">
                                                          <p
                                                            className="m-0"
                                                            style={{
                                                              color: "black",
                                                              fontSize: "14px",
                                                              fontWeight: "400",
                                                            }}
                                                          ></p>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </AccordionDetails>
                                  </Accordion>
                                </div>
                              </div>
                            )}
                          {/* Payment Details */}
                          {(serviceStatus === "accepted" ||
                            serviceStatus === "closed") &&
                            (inProgress || completed) && (
                              <div className="p-0 mt-3 d-flex">
                                <div style={{ marginTop: "14px" }}>
                                  <div
                                    className="w-20 rounded-circle"
                                    style={{
                                      backgroundColor:
                                        getPaymentStatus() === "initiated" ||
                                          getPaymentStatus() === null ||
                                          getPaymentStatus() === "completed"
                                          ? "#FCB61A"
                                          : getPaymentStatus() === "closed" &&
                                          "#36A93F",
                                      height: "13px",
                                      width: "13px",
                                    }}
                                  ></div>
                                </div>
                                <div
                                  className={`${isPayment ? "is-visit-b-shadow-unset" : ""
                                    }`}
                                  style={{ width: "100%" }}
                                >
                                  <Accordion
                                    elevation={1}
                                    style={{
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <AccordionSummary
                                      // expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                      onClick={() => setIsPayment(!isPayment)}
                                    >
                                      <Typography>
                                        Payment Details
                                        {isPayment ? (
                                          <KeyboardArrowUpIcon />
                                        ) : (
                                          <ExpandMoreIcon />
                                        )}
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                      className="accordian_details mb-2"
                                      style={{ width: "100%" }}
                                    >
                                      {SrDetailArray.length > 0
                                        ? SrDetailArray.map(
                                          (srDetail, index) => {
                                            return (
                                              <div>
                                                <div className="row p-2">
                                                  <div className="col-1 text-end p-0">
                                                    <DoneIcon
                                                      style={{
                                                        color: sparePartsCharge
                                                          ? "#e3e3e3"
                                                          : "#36A93F",
                                                        fontSize: "12px",
                                                        marginRight: "6px",
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="col p-0 text-start">
                                                    <p
                                                      className="m-0 p-0 ms-2"
                                                      style={{
                                                        fontSize: "14px",
                                                        color: sparePartsCharge
                                                          ? "#e3e3e3"
                                                          : "#36A93F",
                                                      }}
                                                    >
                                                      Visiting Charge (₹)
                                                    </p>
                                                  </div>
                                                  <div className="col p-0 text-end">
                                                    <div
                                                      className="m-0 p-0 pe-2 ms-2"
                                                      style={{
                                                        fontSize: "14px",
                                                        color: sparePartsCharge
                                                          ? "#e3e3e3"
                                                          : "#6B6D70",
                                                      }}
                                                    >
                                                      {inProgress ? (
                                                        <input
                                                          value={
                                                            srDetail
                                                              ?.sr_work_history[0]
                                                              ?.basic_charge
                                                          }
                                                          style={{
                                                            height: "30px",
                                                            width: "70px",
                                                            textAlign:
                                                              "right",
                                                            color: sparePartsCharge
                                                              ? "#e3e3e3"
                                                              : "#000",
                                                            borderWidth:
                                                              "1px",
                                                            borderStyle:
                                                              "solid",
                                                            borderColor: sparePartsCharge
                                                              ? "#e3e3e3"
                                                              : "gray",
                                                            borderRadius:
                                                              "6px",
                                                            outline: "none",
                                                          }}
                                                          disabled={true}
                                                        />
                                                      ) : (
                                                        <div
                                                          style={{
                                                            color:
                                                              srDetail
                                                                ?.sr_work_history[0]
                                                                ?.spare_service_charge >
                                                                0
                                                                ? "#e3e3e3"
                                                                : "#000",
                                                          }}
                                                        >
                                                          {
                                                            srDetail
                                                              ?.sr_work_history[0]
                                                              ?.basic_charge
                                                          }
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row p-2">
                                                  <div className="col-1 text-end p-0">
                                                    <DoneIcon
                                                      style={{
                                                        color: "#36A93F",
                                                        fontSize: "12px",
                                                        marginRight: "6px",
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="col p-0 text-start">
                                                    <p
                                                      className="m-0 p-0 ms-2"
                                                      style={{
                                                        fontSize: "14px",
                                                        color: "#36A93F",
                                                      }}
                                                    >
                                                      Spare and Service
                                                      Charges (₹)
                                                    </p>
                                                  </div>
                                                  <div className="col p-0 text-end">
                                                    <div
                                                      className="m-0 p-0 pe-2 ms-2"
                                                      style={{
                                                        fontSize: "14px",
                                                        color: "#6B6D70",
                                                      }}
                                                    >
                                                      {inProgress &&
                                                        srDetail
                                                          ?.sr_work_history[0]
                                                          ?.payment_status !==
                                                        "initiated" ? (
                                                        <input
                                                          value={
                                                            srDetail
                                                              ?.sr_work_history[0]
                                                              ?.payment_status ===
                                                              "completed" &&
                                                              srDetail
                                                                ?.sr_work_history[0]
                                                                ?.spare_service_charge
                                                              ? srDetail
                                                                ?.sr_work_history[0]
                                                                ?.spare_service_charge
                                                              : sparePartsCharge
                                                          }
                                                          disabled={
                                                            srDetail
                                                              ?.sr_work_history[0]
                                                              ?.payment_status ===
                                                              "initiated" ||
                                                              srDetail
                                                                ?.sr_work_history[0]
                                                                ?.payment_status ===
                                                              "completed"
                                                              ? true
                                                              : false
                                                          }
                                                          style={{
                                                            height: "30px",
                                                            width: "70px",
                                                            textAlign:
                                                              "right",
                                                            borderWidth:
                                                              "1px",
                                                            borderStyle:
                                                              "solid",
                                                            borderColor:
                                                              "gray",
                                                            borderRadius:
                                                              "6px",
                                                            outline: "none",
                                                          }}
                                                          onChange={(e) => {
                                                            handleSparePartsCharge(
                                                              e
                                                            );
                                                          }}
                                                          onInput={(e) => {
                                                            // Remove non-numeric characters
                                                            e.target.value = e.target.value.replace(
                                                              /[^0-9]/g,
                                                              ""
                                                            );
                                                          }}
                                                          pattern="[0-9]*" // Allows only numeric input
                                                        />
                                                      ) : (
                                                        <div>
                                                          {
                                                            srDetail
                                                              ?.sr_work_history[0]
                                                              ?.spare_service_charge
                                                          }
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row p-2">
                                                  <div className="col-1 text-end p-0">
                                                    <DoneIcon
                                                      style={{
                                                        color: "#000",
                                                        fontSize: "12px",
                                                        marginRight: "6px",
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="col p-0 text-start">
                                                    <p
                                                      className="m-0 p-0 ms-2"
                                                      style={{
                                                        fontSize: "16px",
                                                        fontWeight: "500",
                                                        color: "#1E232C",
                                                      }}
                                                    >
                                                      Total Charges (₹)
                                                    </p>
                                                  </div>
                                                  <div className="col p-0 pe-2 text-end">
                                                    {srDetail
                                                      ?.sr_work_history[0]
                                                      ?.payment_status ===
                                                      "initiated" ||
                                                      srDetail
                                                        ?.sr_work_history[0]
                                                        ?.payment_status ===
                                                      "completed" ? (
                                                      <div
                                                        style={{
                                                          fontSize: "16px",
                                                          fontWeight: "600",
                                                        }}
                                                      >
                                                        {srDetail
                                                          ?.sr_work_history[0]
                                                          ?.spare_service_charge
                                                          ? srDetail
                                                            ?.sr_work_history[0]
                                                            ?.spare_service_charge
                                                          : srDetail
                                                            ?.sr_work_history[0]
                                                            ?.basic_charge}
                                                      </div>
                                                    ) : (
                                                      <div
                                                        style={{
                                                          fontSize: "16px",
                                                          fontWeight: "600",
                                                        }}
                                                      >
                                                        {sparePartsCharge > 0
                                                          ? sparePartsCharge
                                                          : srDetail
                                                            ?.sr_work_history[0]
                                                            ?.basic_charge}
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                                {inProgress && (
                                                  <div className="ps-3 pe-5 mt-4 d-flex align-items-center gap-2">
                                                    {srDetail
                                                      ?.sr_work_history[0]
                                                      ?.payment_status ===
                                                      "initiated" ||
                                                      srDetail
                                                        ?.sr_work_history[0]
                                                        ?.payment_status ===
                                                      "completed" ? (
                                                      <button
                                                        className="ps-4 pe-4"
                                                        style={{
                                                          padding: "5px",
                                                          backgroundColor:
                                                            srDetail
                                                              ?.sr_work_history[0]
                                                              ?.payment_status ===
                                                              "initiated"
                                                              ? "rgb(0 0 0 / 17%)"
                                                              : "#000",
                                                          color: "#fff",
                                                          borderRadius:
                                                            "50px",
                                                          fontSize: "12px",
                                                          border: "none",
                                                        }}
                                                        onClick={() =>
                                                          handleCloseSR()
                                                        }
                                                        disabled={
                                                          srDetail
                                                            ?.sr_work_history[0]
                                                            ?.payment_status ===
                                                            "initiated"
                                                            ? true
                                                            : false
                                                        }
                                                      >
                                                        Close SR
                                                      </button>
                                                    ) : (
                                                      <button
                                                        className="ps-4 pe-4"
                                                        style={{
                                                          padding: "5px",
                                                          backgroundColor:
                                                            srDetail
                                                              ?.sr_work_history[0]
                                                              ?.payment_status ===
                                                              "initiated"
                                                              ? "rgb(0 0 0 / 17%)"
                                                              : "#000",
                                                          color: "#fff",
                                                          borderRadius:
                                                            "50px",
                                                          fontSize: "12px",
                                                          border: "none",
                                                        }}
                                                        onClick={() =>
                                                          handleSubmitSr()
                                                        }
                                                        disabled={
                                                          srDetail
                                                            ?.sr_work_history[0]
                                                            ?.payment_status ===
                                                            "initiated"
                                                            ? true
                                                            : false
                                                        }
                                                      >
                                                        Submit
                                                      </button>
                                                    )}
                                                  </div>
                                                )}
                                              </div>
                                            );
                                          }
                                        )
                                        : {
                                          /* <p>No data</p> */
                                        }}
                                    </AccordionDetails>
                                  </Accordion>
                                </div>
                              </div>
                            )}
                          {/* Reopen sr */}
                        </div>
                      </div>
                    ) : SrDetailArray?.length > 0 &&
                      SrDetailArray?.find((val) => val?.id === serviceRequestId)
                        ?.status === "cancelled" ? (
                      <div className="mb-5">
                        <div className="row mt-3 p-3">
                          <div className="col text-start">
                            {serviceRequestId ? (
                              <div>
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    color: "#1E232C",
                                  }}
                                >
                                  <div className="d-flex align-items-center">
                                    <div>SR #:</div>
                                    <div className="d-flex align-items-center">
                                      <div
                                        style={{
                                          marginTop: "-3px",
                                          marginLeft: "3px",
                                        }}
                                      ></div>
                                      <div>{serviceRequestId?.slice(-6)}</div>
                                    </div>
                                  </div>
                                </p>
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "gray",
                                  }}
                                >
                                  Created On:{" "}
                                  <b style={{ color: "#000" }}>
                                    {serviceCreatedTime}
                                  </b>
                                </p>
                              </div>
                            ) : null}
                          </div>
                          <div className="p-0 mt-3 d-flex">
                            <div style={{ marginTop: "12px" }}>
                              <div
                                className="w-20 rounded-circle"
                                style={{
                                  backgroundColor: "#36A93F",
                                  height: "13px",
                                  width: "13px",
                                }}
                              ></div>
                            </div>
                            <div
                              className={`${isService ? "is-service-b-shadow-unset" : ""
                                }`}
                              style={{ width: "100%" }}
                            >
                              <Accordion
                                elevation={1}
                                style={{
                                  marginLeft: "10px",
                                }}
                              >
                                <AccordionSummary
                                  // expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  onClick={() => {
                                    setIsService(!isService);
                                  }}
                                >
                                  <Typography>
                                    Service Request Creation
                                    {isService ? (
                                      <KeyboardArrowUpIcon />
                                    ) : (
                                      <ExpandMoreIcon />
                                    )}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails className="accordian_details">
                                  <div className="row p-2 pb-0 ps-0">
                                    <div className="col-1 text-end p-0">
                                      <DoneIcon
                                        style={{
                                          color: "#36A93F",
                                          fontSize: "12px",
                                          // marginRight: "6px",
                                        }}
                                      />
                                    </div>
                                    <div className="col p-0 text-start">
                                      <p
                                        className=" p-0"
                                        style={{
                                          fontSize: "14px",
                                          color: "#36A93F",
                                          marginLeft: "6px",
                                        }}
                                      >
                                        SR Details
                                      </p>
                                    </div>
                                    {/* <div className="col p-0 text-end">
                                                     <p className="m-0 p-0 ms-2" style={{ fontSize: '12px', color: '#6B6D70' }}>15 mins ago</p>
                                                 </div> */}
                                  </div>

                                  <div className="row p-2 ps-0">
                                    <div className="col-1 text-center p-0 with-vertical-line"></div>
                                    <div className="col p-0 text-start">
                                      <table className="w-100 border-0">
                                        {SrDetailArray?.length > 0 ? (
                                          SrDetailArray?.map(
                                            (srDetail, index) => (
                                              <tbody>
                                                <p
                                                  className="m-0"
                                                  style={{
                                                    color: "black",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  Device:
                                                </p>

                                                <p
                                                  className="m-0 pe-2"
                                                  style={{
                                                    color: "black",
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                  }}
                                                >
                                                  {srDetail?.appliance_name
                                                    ? srDetail?.appliance_name
                                                    : ""}
                                                </p>

                                                <p
                                                  className="m-0"
                                                  style={{
                                                    color: "black",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  Brand:
                                                </p>
                                                <p
                                                  className="m-0 pe-2"
                                                  style={{
                                                    color: "black",
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                  }}
                                                >
                                                  {srDetail?.appliance_brand
                                                    ? srDetail?.appliance_brand
                                                    : ""}
                                                </p>

                                                <p
                                                  className="m-0"
                                                  style={{
                                                    color: "black",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  Model:
                                                </p>

                                                <p
                                                  className="m-0 pe-2"
                                                  style={{
                                                    color: "black",
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                  }}
                                                >
                                                  {srDetail?.appliance_model
                                                    ? srDetail?.appliance_model
                                                    : ""}
                                                </p>

                                                <p
                                                  className="m-0"
                                                  style={{
                                                    color: "black",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  Size:
                                                </p>

                                                <p
                                                  className="m-0 pe-2"
                                                  style={{
                                                    color: "black",
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                  }}
                                                >
                                                  {srDetail?.appliance_size
                                                    ? srDetail?.appliance_size +
                                                    '"'
                                                    : ""}
                                                </p>

                                                <p
                                                  className="m-0"
                                                  style={{
                                                    color: "black",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  Warranty Status:
                                                </p>

                                                <p
                                                  className="m-0 pe-2"
                                                  style={{
                                                    color: "black",
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                  }}
                                                >
                                                  {srDetail?.warranty_status
                                                    ? srDetail?.warranty_status ===
                                                      "inactive"
                                                      ? "InActive"
                                                      : "Active"
                                                    : ""}
                                                </p>

                                                {srDetail?.warranty_status ===
                                                  "active" && (
                                                    <div>
                                                      <p
                                                        className="m-0"
                                                        style={{
                                                          color: "black",
                                                          fontSize: "14px",
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        Warranty Untill:
                                                      </p>

                                                      <p
                                                        className="m-0 pe-2"
                                                        style={{
                                                          color: "black",
                                                          fontSize: "14px",
                                                          fontWeight: "400",
                                                        }}
                                                      >
                                                        {srDetail?.warranty_date
                                                          ? srDetail?.warranty_date
                                                          : ""}
                                                      </p>
                                                    </div>
                                                  )}
                                              </tbody>
                                            )
                                          )
                                        ) : (
                                          /*  <p>No data</p> */ <></>
                                        )}
                                      </table>
                                    </div>
                                  </div>
                                  <div className="row p-2 pb-0 ps-0">
                                    <div className="col-1 text-end p-0">
                                      <DoneIcon
                                        style={{
                                          color: "#36A93F",
                                          fontSize: "12px",
                                          // marginRight: "6px",
                                        }}
                                      />
                                    </div>
                                    <div className="col p-0 text-start">
                                      <p
                                        className="p-0"
                                        style={{
                                          fontSize: "14px",
                                          color: "#36A93F",
                                          marginLeft: "6px",
                                        }}
                                      >
                                        Contact Details
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row p-2 ps-0">
                                    <div className="col-1 text-center p-0 with-vertical-line"></div>
                                    <div className="col p-0 text-start">
                                      <table className="w-100 border-0">
                                        {SrDetailArray?.length > 0 ? (
                                          SrDetailArray?.map(
                                            (srDetail, index) => (
                                              <tbody>
                                                <tr style={{ height: "35px" }}>
                                                  {/* <td className="text-start"> */}
                                                  <p
                                                    className="m-0"
                                                    style={{
                                                      color: "black",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                    }}
                                                  >
                                                    <b>Name:</b>
                                                  </p>
                                                  {/* </td> */}
                                                  {/* <td className="text-start"> */}
                                                  <p
                                                    className="m-0 pe-2"
                                                    style={{
                                                      color: "black",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                    }}
                                                  >
                                                    {srDetail?.serviceLocation
                                                      ? srDetail
                                                        ?.serviceLocation.name
                                                      : ""}
                                                  </p>
                                                  {/* </td> */}
                                                </tr>
                                                <tr
                                                  style={{
                                                    height: "35px",
                                                  }}
                                                >
                                                  {/* <td className="text-start"> */}
                                                  <p
                                                    className="m-0"
                                                    style={{
                                                      color: "black",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                      marginTop: 20,
                                                    }}
                                                  >
                                                    <b>Address:</b>
                                                  </p>
                                                  {/* </td> */}
                                                  {/* <td className="text-end"> */}
                                                  <p
                                                    className="m-0 pe-2"
                                                    style={{
                                                      color: "black",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                    }}
                                                  >
                                                    {srDetail?.serviceLocation
                                                      ? srDetail
                                                        ?.serviceLocation
                                                        .address
                                                      : ""}
                                                  </p>
                                                  {/* </td> */}
                                                </tr>
                                                <tr style={{ height: "35px" }}>
                                                  {/* <td className="text-start"> */}
                                                  <p
                                                    className="m-0"
                                                    style={{
                                                      color: "black",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                    }}
                                                  >
                                                    <b>District:</b>
                                                  </p>
                                                  {/* </td> */}
                                                  {/* <td className="text-end"> */}
                                                  <p
                                                    className="m-0 pe-2"
                                                    style={{
                                                      color: "black",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                    }}
                                                  >
                                                    {srDetail?.serviceLocation
                                                      ? srDetail
                                                        ?.serviceLocation
                                                        .city_name
                                                      : ""}
                                                  </p>
                                                  {/* </td> */}
                                                </tr>
                                                <tr style={{ height: "35px" }}>
                                                  {/* <td className="text-start"> */}
                                                  <p
                                                    className="m-0"
                                                    style={{
                                                      color: "black",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                    }}
                                                  >
                                                    <b>State:</b>
                                                  </p>
                                                  {/* </td> */}
                                                  {/* <td className="text-end"> */}
                                                  <p
                                                    className="m-0 pe-2"
                                                    style={{
                                                      color: "black",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                    }}
                                                  >
                                                    {srDetail?.serviceLocation
                                                      ? srDetail
                                                        ?.serviceLocation
                                                        .state_name
                                                      : ""}
                                                  </p>
                                                  {/* </td> */}
                                                </tr>
                                                <tr style={{ height: "35px" }}>
                                                  {/* <td className="text-start"> */}
                                                  <p
                                                    className="m-0"
                                                    style={{
                                                      color: "black",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                    }}
                                                  >
                                                    <b>Postal Code:</b>
                                                  </p>
                                                  {/* </td> */}
                                                  {/* <td className="text-end"> */}
                                                  <p
                                                    className="m-0 pe-2"
                                                    style={{
                                                      color: "black",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                    }}
                                                  >
                                                    {srDetail?.serviceLocation
                                                      ? srDetail
                                                        ?.serviceLocation
                                                        .postalcode
                                                      : ""}
                                                  </p>
                                                  {/* </td> */}
                                                </tr>
                                                <tr style={{ height: "35px" }}>
                                                  {/* <td className="text-start"> */}
                                                  <p
                                                    className="m-0"
                                                    style={{
                                                      color: "black",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                    }}
                                                  >
                                                    <b>Phone Number:</b>
                                                  </p>
                                                  {/* </td> */}
                                                  {/* <td className="text-end"> */}
                                                  <p
                                                    className="m-0 pe-2"
                                                    style={{
                                                      color: "black",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                    }}
                                                  >
                                                    {srDetail?.serviceLocation
                                                      ? srDetail
                                                        ?.serviceLocation
                                                        .mobile_no
                                                      : ""}
                                                  </p>
                                                  {/* </td> */}
                                                </tr>
                                              </tbody>
                                            )
                                          )
                                        ) : (
                                          <p>No data</p>
                                        )}
                                      </table>
                                    </div>
                                  </div>
                                  {SrDetailArray?.find(
                                    (val) => val?.id === serviceRequestId
                                  )?.problem_desc && (
                                      <div className="row p-2 pb-0 ps-0">
                                        <div className="col-1 text-end p-0">
                                          <DoneIcon
                                            style={{
                                              color: "#36A93F",
                                              fontSize: "12px",
                                              // marginRight: "6px",
                                            }}
                                          />
                                        </div>
                                        <div className="col p-0 text-start">
                                          <p
                                            className="m-0 p-0 ms-2"
                                            style={{
                                              fontSize: "14px",
                                              color: "#36A93F",
                                              marginLeft: "6px",
                                            }}
                                          >
                                            Complaint Details
                                          </p>
                                        </div>
                                        {/* <div className="col p-0 text-end">
                                                                <p className="m-0 p-0 ms-2" style={{ fontSize: '12px', color: '#6B6D70' }}>15 mins ago</p>
                                                            </div> */}
                                      </div>
                                    )}
                                  {SrDetailArray?.find(
                                    (val) => val?.id === serviceRequestId
                                  )?.problem_desc && (
                                      <div className="row p-2 pb-0 ps-0 mb-2">
                                        <div className="col-1 text-center p-0 with-vertical-line"></div>
                                        <div className="col p-0 text-start">
                                          <table className="w-100 border-0">
                                            {SrDetailArray?.length > 0 ? (
                                              SrDetailArray?.map(
                                                (srDetail, index) => (
                                                  <tbody>
                                                    <tr
                                                      style={{ height: "35px" }}
                                                    >
                                                      {/* <td className="text-start"> */}
                                                      <p
                                                        className="m-0"
                                                        style={{
                                                          color: "black",
                                                          fontSize: "14px",
                                                          fontWeight: "400",
                                                        }}
                                                      >
                                                        <b>Description:</b>
                                                      </p>
                                                      {/* </td> */}
                                                      {/* <td className="text-end"> */}
                                                      <p
                                                        className="m-0 pe-2"
                                                        style={{
                                                          color: "black",
                                                          fontSize: "14px",
                                                          fontWeight: "400",
                                                        }}
                                                      >
                                                        {srDetail?.problem_desc
                                                          ? srDetail?.problem_desc
                                                          : ""}
                                                      </p>
                                                      {/* </td> */}
                                                    </tr>
                                                    <tr
                                                      style={{ height: "35px" }}
                                                    >
                                                      <p
                                                        className="m-0"
                                                        style={{
                                                          color: "black",
                                                          fontSize: "14px",
                                                          fontWeight: "400",
                                                        }}
                                                      >
                                                        <b>Attachments:</b>
                                                      </p>
                                                      <button
                                                        onClick={() =>
                                                          setImageModal(true)
                                                        }
                                                        className="m-0 pe-2 items-btn"
                                                        style={{
                                                          color: "black",
                                                          fontSize: "12px",
                                                          fontWeight: "400",
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        {srDetail?.attachments
                                                          ? srDetail?.attachments
                                                            .length
                                                          : ""}{" "}
                                                        <IoMdPhotos className="img-icon" />{" "}
                                                        Item(s)
                                                      </button>
                                                      {/* <p
                                                        onClick={() =>
                                                          setImageModal(true)
                                                        }
                                                        className="m-0 pe-2"
                                                        style={{
                                                          color: "black",
                                                          fontSize: "14px",
                                                          fontWeight: "400",
                                                          cursor: "pointer",
                                                          textDecoration:
                                                            "underline",
                                                        }}
                                                      >
                                                        {srDetail.attachments
                                                          ? srDetail.attachments
                                                            .length
                                                          : ""}{" "}
                                                        Item(s)
                                                      </p> */}
                                                    </tr>
                                                  </tbody>
                                                )
                                              )
                                            ) : (
                                              <p>No data</p>
                                            )}
                                          </table>
                                        </div>
                                      </div>
                                    )}
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            height: "200px",
                            display: "flex",
                            justifyContent: "center",
                            position: "relative",
                            top: "150px",
                            fontSize: "20px",
                            fontWeight: "600",
                            color: "#ffa5005c",
                          }}
                        >
                          This Service Request has been cancelled!
                        </div>
                      </div>
                    ) : (
                      <div className="mb-5">
                        <div className="row mt-3 p-3">
                          <div className="col text-start">
                            {serviceRequestId ? (
                              <div>
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    color: "#1E232C",
                                  }}
                                >
                                  <div className="d-flex align-items-center">
                                    <div>SR #:</div>
                                    <div className="d-flex align-items-center">
                                      <div
                                        style={{
                                          marginTop: "-3px",
                                          marginLeft: "3px",
                                        }}
                                      ></div>
                                      <div>{serviceRequestId?.slice(-6)}</div>
                                    </div>
                                  </div>
                                </p>
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "gray",
                                  }}
                                >
                                  Created On:{" "}
                                  <b style={{ color: "#000" }}>
                                    {serviceCreatedTime}
                                  </b>
                                </p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div
                          style={{
                            height: "200px",
                            display: "flex",
                            justifyContent: "center",
                            position: "relative",
                            top: "150px",
                            fontSize: "2rem",
                            fontWeight: "600",
                            color: "#ffa5005c",
                          }}
                        >
                          No SRs found!
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {imageModal && (
        <Modal
          className="imageModal"
          show={imageModal}
          onHide={() => setImageModal(false)}
        >
          <div
            style={{
              backgroundColor: "white",
              width: "100%",
              maxWidth: "600px",
              overflow: "hidden",
              margin: "auto",
              borderRadius: "15px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "20px",
            }}
          >
            <div className="p-3">
              <div
                className="d-flex align-items-center justify-content-between"
                style={{
                  fontSize: "20px",
                }}
              >
                <div style={{ fontSize: "20px" }}>
                  {/* <div>Attached Image</div> */}
                </div>
                <div onClick={() => setImageModal(false)}>
                  <MdClose
                    style={{
                      cursor: "pointer",
                      color: "rgb(235, 129, 40)",
                      position: "relative",
                      lright: "20px",
                    }}
                  />
                </div>
              </div>
              {SrDetailArray[0]?.attachments.length > 0 && (
                <div className="image-container">
                  {SrDetailArray[0]?.attachments.map((imageSrc, index) => (
                    <img
                      key={index}
                      src={imageSrc}
                      alt={`Attachment ${index}`}
                      style={{
                        cursor: "pointer",
                        width: "300px",
                        height: "300px",
                        objectFit: "inherit",
                        display: currentIndex === index ? "block" : "none",
                      }}
                      onClick={() => handleImageClick(index)}
                    />
                  ))}
                  <Button
                    variant="link"
                    className="arrow-button left"
                    onClick={showPreviousImage}
                    style={{
                      position: "absolute",
                      top: "40%",
                      left: "10px",
                      color: "rgb(235, 129, 40)",
                      fontSize: "30px",
                    }}
                  >
                    <MdKeyboardArrowLeft />
                  </Button>
                  <Button
                    variant="link"
                    className="arrow-button right"
                    onClick={showNextImage}
                    style={{
                      position: "absolute",
                      top: "40%",
                      right: "10px",
                      color: "rgb(235, 129, 40)",
                      fontSize: "30px",
                    }}
                  >
                    <MdKeyboardArrowRight />
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}

      {/* <ToastMessage message= */}

      <ToastMessage
        message={toastMessageCommentError}
        status={"error"}
        hideProgressBar={true}
      />
    </>
  );
};

export default ServiceRequestmanagementTechnician;
