import axios from "axios";
import Swal from "sweetalert2";
// const token = localStorage.getItem('token');
// const instanceV2 = axios.create({
//   baseURL: process.env.REACT_APP_BACKEND_API_V2,
//   headers: { 'Authorization': 'Bearer ' + token }
// });

// const token = localStorage.getItem('token');
// const instanceV1 = axios.create({
//   baseURL: process.env.REACT_APP_BACKEND_API_V1,
//   headers: { 'Authorization': 'Bearer ' + token }
// });
// const apiManager = () => {
//   const apiInstance = axios.create({baseURL: process.env.REACT_APP_BACKEND_API_V2})
//   apiInstance.interceptors.request.use((config) => {
//     const token = localStorage.getItem('token');
//     if(token) {
//       config.headers.Authorization = `Bearer ${token}`
//     }

//     return config
//   })
// }
const handleUnAuthorizedError = (err) => {
  if (err?.response?.status === 401) {
    localStorage.clear();
    window.location.href = "/";
    // window.location.reload();
  }
};
const Login = async (data) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_API_V2 + "/login",
      data
    );
    return response?.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};
const DeleteUser = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.delete(`/delete/account`, {
      data: payload,
    });
    return response?.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const customerRegister = async (data) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_API_V2 + "/signup",
      data
    );
    return response?.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const technicianRegister = async (data) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_API_V2 + "/signup",
      data
    );
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const forgetPassword = async (data) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_API_V2 + "/forgot-password",
      data
    );
    return response?.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const verifyOTP = async (data) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_API_V2 + "/forgot-password",
      data
    );
    return response?.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const otpverify = async (data) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_API_V2 + "/otpVerify",
      data
    );
    return response?.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const Resendotp = async (data) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_API_V2 + "/reSendOtp",
      data
    );
    return response?.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const changepassword = async (data) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_API_V2 + "/change-password",
      data
    );
    return response?.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

//getAppliances
const getAppliances = async () => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get("/appliances");
    return response?.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

/* applianceManagemnt dropdown  */
const getApplianceDropdown = async () => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get("/appliance/list");
    return response?.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

/* applianceManagemnt dropdown  */
const getModelDropdown = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(`/appliance/details/${id}`);
    return response?.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const getSRCustomerDashboard = async () => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get("/customer/dashboard/view");
    console.log("getSRCustomerDashboard", response.data);
    return response?.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

//getNotifications
const getNotifications = async () => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get("/notification");
    return response?.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

// const Api = { Login, customerRegister, forgetPassword, verifyOTP, otpverify, Resendotp, changepassword, Profile }

//Appliance details
const getApplianceDetail = async (applianceId) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(`/servicerequest/${applianceId}`);
    return response?.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};
const getReferrerName = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(`/username/${id}`);
    return response?.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

//countryList
const getCountry = async () => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_BACKEND_API_V1 + "/country"
    );
    return response?.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

//stateList
const getStatesByCountryId = async (countryId) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_BACKEND_API_V1 + `/states/${countryId}`
    );
    return response?.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

//cityList
const getCityByStateId = async (cityId) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_BACKEND_API_V2 + `/city/${cityId}`
    );
    return response?.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const getLocationByPincode = async (pincode) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_BACKEND_API_V2 + `/location/${pincode}`
    );
    return response?.data;
  } catch (error) {
    return { error: error.response.data };
    console.log("error=?", error.response.data);
  }
};
//create ServiceRequest
const createNewServiceRequest = async (requestJson) => {
  const data = new FormData();
  data.append("user_id", requestJson.user_id);
  data.append("service_id", requestJson.service_id);
  data.append("brand_id", requestJson.brand_id);
  data.append("model_id", requestJson.model_id);
  data.append("variant_id", requestJson.variants_id);
  data.append("warranty_status", requestJson.warranty_status);
  data.append("warranty_limit", requestJson.warranty_limit);
  data.append("damage_id", requestJson.damage_id);
  data.append("damage_description", requestJson.damage_description);
  data.append("tech_visit", requestJson.tech_visit);
  data.append("slots", requestJson.slots);
  data.append("address_id", requestJson.address);
  data.append("latitude", requestJson.latitude);
  data.append("longitude", requestJson.longitude);
  data.append("images", requestJson.images);
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.post("/serviceRequest", data);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

//update ServiceRequest
const updateServiceRequest = async (requestJson) => {
  const data = new FormData();
  data.append("user_id", requestJson.user_id);
  data.append("service_id", requestJson.service_id);
  data.append("brand", JSON.stringify(requestJson.brand));
  data.append("model_number", JSON.stringify(requestJson.model_number));
  data.append("variants", JSON.stringify(requestJson.variants));
  data.append("warranty_status", requestJson.warranty_status);
  data.append("warranty_limit", requestJson.warranty_limit);
  data.append("damage_id", requestJson.damage_id);
  data.append("damage_description", requestJson.damage_description);
  data.append("tech_visit", requestJson.tech_visit);
  data.append("slots", requestJson.slots);
  data.append("labour_fee", requestJson.labour_fee);
  data.append("country", requestJson.country);
  data.append("state", requestJson.state);
  data.append("city", requestJson.city);
  data.append("address", requestJson.address);
  data.append("postalcode", requestJson.postalcode);
  data.append("is_assigned", requestJson.is_assigned);
  // data.append("city", requestJson.city);

  data.append("images", requestJson.images);
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.patch("/servicerequest", data);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

//cancel ServiceRequest
const cancelServiceRequest = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.delete(`/cancel/service/request/${id}`);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

//get profile details
const getProfile = async () => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get("/profileDetails");
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};
const getProfileImage = async (token) => {
  try {
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get("/profileDetails");
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

//add or update profile
const Profile = async (requestJson, removeAvatar, removeshop, shopremove) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.post(
      `/profileDetails?removeAvatar=${removeAvatar}&removeShop=false&shopremove=[]`,
      requestJson
    );
    console.log("response", requestJson);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

//getServiceRequest
const getServiceRequest = async (id) => {
  if (id) {
    try {
      const token = localStorage.getItem("token");
      const instanceV2 = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_API_V2,
        headers: { Authorization: "Bearer " + token },
      });
      const response = await instanceV2.get(`/get/service/request/${id}`);
      return response.data;
    } catch (error) {
      handleUnAuthorizedError(error);
      return error;
    }
  }
};

//getAllServiceRequests
const getServiceRequestList = async () => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get("serviceOrders/all");
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

/* Consumer SR */

const getServiceRequestUnassignedList = async (searchInput, filterData) => {
  const filterDataPayload = `${searchInput ? `&search=${searchInput}` : ""}${
    filterData?.startDate ? `&startDate=${filterData?.startDate}` : ""
  }${filterData?.endDate ? `&endDate=${filterData?.endDate}` : ""}${
    filterData?.maxWallet ? `&max_wallet=${filterData?.maxWallet}` : ""
  }${filterData?.minWallet ? `&min_wallet=${filterData?.minWallet}` : ""}${
    filterData?.district ? `&district=${filterData?.district}` : ""
  }${filterData?.zone_id ? `&zone_id=${filterData?.zone_id}` : ""}`;
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(
      `get/service/request?status=unassigned${filterDataPayload}`
    );
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const getServiceRequestAcceptedList = async (searchInput, filterData) => {
  const filterDataPayload = `${searchInput ? `&search=${searchInput}` : ""}${
    filterData?.startDate ? `&startDate=${filterData?.startDate}` : ""
  }${filterData?.endDate ? `&endDate=${filterData?.endDate}` : ""}${
    filterData?.maxWallet ? `&max_wallet=${filterData?.maxWallet}` : ""
  }${filterData?.minWallet ? `&min_wallet=${filterData?.minWallet}` : ""}${
    filterData?.district ? `&district=${filterData?.district}` : ""
  }${filterData?.zone_id ? `&zone_id=${filterData?.zone_id}` : ""}`;
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(
      `get/service/request?status=accepted${filterDataPayload}`
    );
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

/* Consumer - Technician SR */

const getServiceRequestClosedList = async (searchInput, filterData) => {
  const filterDataPayload = `${searchInput ? `&search=${searchInput}` : ""}${
    filterData?.startDate ? `&startDate=${filterData?.startDate}` : ""
  }${filterData?.endDate ? `&endDate=${filterData?.endDate}` : ""}${
    filterData?.maxWallet ? `&max_wallet=${filterData?.maxWallet}` : ""
  }${filterData?.minWallet ? `&min_wallet=${filterData?.minWallet}` : ""}${
    filterData?.district ? `&district=${filterData?.district}` : ""
  }${filterData?.zone_id ? `&zone_id=${filterData?.zone_id}` : ""}${
    filterData?.filterBy ? `&filterBy=${filterData?.filterBy}` : ""
  }`;
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(
      `get/service/request?status=closed${filterDataPayload}`
    );
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

/* Technician SR */

const getServiceRequestOpenList = async (searchInput, filterData) => {
  const filterDataPayload = `${searchInput ? `&search=${searchInput}` : ""}${
    filterData?.startDate ? `&startDate=${filterData?.startDate}` : ""
  }${filterData?.endDate ? `&endDate=${filterData?.endDate}` : ""}${
    filterData?.maxWallet ? `&max_wallet=${filterData?.maxWallet}` : ""
  }${filterData?.minWallet ? `&min_wallet=${filterData?.minWallet}` : ""}${
    filterData?.district ? `&district=${filterData?.district}` : ""
  }${filterData?.zone_id ? `&zone_id=${filterData?.zone_id}` : ""}`;
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(
      `get/service/request?status=open${filterDataPayload}`
    );
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const getServiceRequestAssignedList = async (searchInput, filterData) => {
  const filterDataPayload = `${searchInput ? `&search=${searchInput}` : ""}${
    filterData?.startDate ? `&startDate=${filterData?.startDate}` : ""
  }${filterData?.endDate ? `&endDate=${filterData?.endDate}` : ""}${
    filterData?.maxWallet ? `&max_wallet=${filterData?.maxWallet}` : ""
  }${filterData?.minWallet ? `&min_wallet=${filterData?.minWallet}` : ""}${
    filterData?.district ? `&district=${filterData?.district}` : ""
  }${filterData?.zone_id ? `&zone_id=${filterData?.zone_id}` : ""}`;
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(
      `get/service/request?status=assigned${filterDataPayload}`
    );
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const getServiceRequestNotAcceptedList = async (searchInput, filterData) => {
  const filterDataPayload = `${searchInput ? `&search=${searchInput}` : ""}${
    filterData?.startDate ? `&startDate=${filterData?.startDate}` : ""
  }${filterData?.endDate ? `&endDate=${filterData?.endDate}` : ""}${
    filterData?.maxWallet ? `&max_wallet=${filterData?.maxWallet}` : ""
  }${filterData?.minWallet ? `&min_wallet=${filterData?.minWallet}` : ""}${
    filterData?.district ? `&district=${filterData?.district}` : ""
  }${filterData?.zone_id ? `&zone_id=${filterData?.zone_id}` : ""}`;
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(
      `serviceOrders/not_accepted${filterDataPayload}`
    );
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

/* District Admin SR */

const getDistrictServiceRequestClosedList = async (
  searchInput,
  filterData,
  page,
  limit = 10
) => {
  const filterDataPayload = `${searchInput ? `&search=${searchInput}` : ""}${
    filterData?.startDate ? `&startDate=${filterData?.startDate}` : ""
  }${filterData?.endDate ? `&endDate=${filterData?.endDate}` : ""}${
    filterData?.maxWallet ? `&max_wallet=${filterData?.maxWallet}` : ""
  }${filterData?.minWallet ? `&min_wallet=${filterData?.minWallet}` : ""}${
    filterData?.district ? `&district=${filterData?.district}` : ""
  }${filterData?.zone_id ? `&zone_id=${filterData?.zone_id}` : ""}${
    filterData?.filterBy ? `&filterBy=${filterData?.filterBy}` : ""
  }`;
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(
      `district/service/request?status=closed${filterDataPayload}&page=${page}&limit=${limit}`
    );
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const getDistrictServiceRequestOpenList = async (
  searchInput,
  filterData,
  page,
  limit = 10
) => {
  const filterDataPayload = `${searchInput ? `&search=${searchInput}` : ""}${
    filterData?.startDate ? `&startDate=${filterData?.startDate}` : ""
  }${filterData?.endDate ? `&endDate=${filterData?.endDate}` : ""}${
    filterData?.maxWallet ? `&max_wallet=${filterData?.maxWallet}` : ""
  }${filterData?.minWallet ? `&min_wallet=${filterData?.minWallet}` : ""}${
    filterData?.district ? `&district=${filterData?.district}` : ""
  }${filterData?.zone_id ? `&zone_id=${filterData?.zone_id}` : ""}`;
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(
      `district/service/request?status=open${filterDataPayload}&page=${page}&limit=${limit}`
    );
    console.log("getDistrictServiceRequestOpenList response: ", response.data);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const getDistrictServiceRequestAcceptedList = async (
  searchInput,
  filterData,
  page,
  limit = 10
) => {
  const filterDataPayload = `${searchInput ? `&search=${searchInput}` : ""}${
    filterData?.startDate ? `&startDate=${filterData?.startDate}` : ""
  }${filterData?.endDate ? `&endDate=${filterData?.endDate}` : ""}${
    filterData?.maxWallet ? `&max_wallet=${filterData?.maxWallet}` : ""
  }${filterData?.minWallet ? `&min_wallet=${filterData?.minWallet}` : ""}${
    filterData?.district ? `&district=${filterData?.district}` : ""
  }${filterData?.zone_id ? `&zone_id=${filterData?.zone_id}` : ""}`;
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(
      `district/service/request?status=accepted${filterDataPayload}&page=${page}&limit=${limit}`
    );
    console.log("getDistrictServiceRequestAcceptedList", response.data);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const getServiceRequestSummary = async () => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get("serviceOrders/summary");
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

//Customer Reset Password
const customerResetPassword = async (data) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_API_V2 + "/change-password",
      data
    );
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

//Dashboard details
const getDashboardDetails = async () => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get("/dashboard");
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

//Dashboard details
const getPendingTechnicians = async (searchInput, filterData) => {
  const filterDataPayload = `${searchInput ? `search=${searchInput}` : ""}${
    filterData?.startDate ? `&startDate=${filterData?.startDate}` : ""
  }${filterData?.endDate ? `&endDate=${filterData?.endDate}` : ""}${
    filterData?.maxWallet ? `&max_wallet=${filterData?.maxWallet}` : ""
  }${filterData?.minWallet ? `&min_wallet=${filterData?.minWallet}` : ""}${
    filterData?.district ? `&district=${filterData?.district}` : ""
  }${filterData?.zone_id ? `&zone_id=${filterData?.zone_id}` : ""}`;
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    let response;
    if (filterDataPayload) {
      response = await instanceV2.get(`/users/pending?${filterDataPayload}`);
    } else {
      response = await instanceV2.get(`users/pending`);
    }
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const getTechnicians = async () => {
  const city_id = localStorage.getItem("district_id");
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(`/users/technician/${city_id}`);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const approveTechnician = async (id, payload) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.post(`/users/${id}/approve`, payload);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const rejectTechnician = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.post(`/users/${id}/reject`, {
      approve_status: "1",
    });
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const getTechnicianDetails = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(`/users/${id}/details`, {
      approve_status: "1",
    });
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const updateTechnician = async (id, payload) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.post(`/users/${id}/update`, payload);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const getUserAddress = async () => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(`/user/address`);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const adduserAddress = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.post(`/user/address`, payload);
    return response.data;
  } catch (error) {
    console.log("adduserAddressError", error);

    handleUnAuthorizedError(error);
    return error;
  }
};

const deleteuserAddress = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.delete(`/user/address/${id}`);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const edituserAddress = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.patch(`/user/address`, payload);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

// District Admin

//assign ServiceRequest
const assignServiceRequest = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.patch(
      "/manual/assign/service/request",
      payload
    );
    Swal.fire({
      text: response.data,
      showConfirmButton: false,
      timer: 3000,
      iconSize: "1.5em",
    });
    return response.data;
  } catch (error) {
    Swal.fire({
      text: error,
      showConfirmButton: false,
      timer: 3000,
      iconSize: "1.5em",
    });
    handleUnAuthorizedError(error);
    return error;
  }
};

// Technician

//assign ServiceRequest
const assignToMeRequest = async (payload) => {
  const data = {
    user_id: payload.user_id,
    service_req_id: payload.service_req_id,
    srorderstatus: payload.srorderstatus,
  };
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.post("/serviceRequest", data);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

//accept ServiceRequest
const acceptServiceRequest = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.patch(
      "/service/request/respond",
      payload
    );
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

//reject ServiceRequest
const rejectServiceRequest = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.patch(
      "/service/request/respond",
      payload
    );
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

//initiate payment ServiceRequest
const initiatePaymentServiceRequest = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.patch(
      "/service/request/payment/initiate",
      payload
    );
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};
const completePaymentServiceRequest = async (payload) => {
  console.log("payload", payload);
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.patch(
      `/service/request/payment/completed/${payload.sr_id}`
    );
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};
const closeServiceRequest = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.patch(
      `/service/request/close/${payload.sr_id}`
    );
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const getEligibleTechnicians = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(
      `/district/tech/list/${payload.sr_id}`
    );
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};
const getSelectableAppliances = async (payload) => {
  try {
    // const { status } = payload;
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(`/get/appliance/related/list`);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};
const getApplianceRelatedList = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(
      `/get/appliance/related/list?is_map=true&user_id=${payload.user_id}`
    );
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const createAppliance = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.post(`appliance`, payload);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const getAppliance = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(`appliance/${id}`);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};
const updateAppliance = async (payload, id) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.patch(`/appliance/update/${id}`, payload);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};
//usermanagement
const DistrictUserManagementAllCust = async (searchInput, filterData) => {
  const filterDataPayload = `${searchInput ? `&search=${searchInput}` : ""}${
    filterData?.startDate ? `&startDate=${filterData?.startDate}` : ""
  }${filterData?.endDate ? `&endDate=${filterData?.endDate}` : ""}${
    filterData?.maxWallet ? `&max_wallet=${filterData?.maxWallet}` : ""
  }${filterData?.minWallet ? `&min_wallet=${filterData?.minWallet}` : ""}${
    filterData?.district ? `&district=${filterData?.district}` : ""
  }${filterData?.zone_id ? `&zone_id=${filterData?.zone_id}` : ""}
  ${filterData?.status ? `&status=${filterData?.status}` : ""}`;

  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(
      `/usermanagement/districtadmin?type=customer${filterDataPayload}`
    );
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const DistrictUserManagementGetOneUser = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(`/usermanagement/${id}`);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};
const DistrictUserManagementAllTech = async (searchInput, filterData) => {
  const filterDataPayload = `${searchInput ? `&search=${searchInput}` : ""}${
    filterData?.startDate ? `&startDate=${filterData?.startDate}` : ""
  }${filterData?.endDate ? `&endDate=${filterData?.endDate}` : ""}${
    filterData?.maxWallet ? `&max_wallet=${filterData?.maxWallet}` : ""
  }${filterData?.minWallet ? `&min_wallet=${filterData?.minWallet}` : ""}${
    filterData?.district ? `&district=${filterData?.district}` : ""
  }${filterData?.zone_id ? `&zone_id=${filterData?.zone_id}` : ""}
  ${filterData?.status ? `&status=${filterData?.status}` : ""}`;
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(
      `/usermanagement/districtadmin?type=technician${filterDataPayload}`
    );
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};
const ZonalUserManagementAllCust = async (searchInput, filterData) => {
  const filterDataPayload = `${searchInput ? `&search=${searchInput}` : ""}${
    filterData?.startDate ? `&startDate=${filterData?.startDate}` : ""
  }${filterData?.endDate ? `&endDate=${filterData?.endDate}` : ""}${
    filterData?.district ? `&district=${filterData?.district}` : ""
  }${filterData?.status ? `&status=${filterData?.status}` : ""}${
    filterData?.zone_id ? `&zone_id=${filterData?.zone_id}` : ""
  }`;
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(
      `/usermanagement/zonaladmin?type=customer${filterDataPayload}`
    );
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};
const ZonalUserManagementAllTech = async (searchInput, filterData) => {
  const filterDataPayload = `${searchInput ? `&search=${searchInput}` : ""}${
    filterData?.startDate ? `&startDate=${filterData?.startDate}` : ""
  }${filterData?.endDate ? `&endDate=${filterData?.endDate}` : ""}${
    filterData?.maxWallet ? `&max_wallet=${filterData?.maxWallet}` : ""
  }${filterData?.minWallet ? `&min_wallet=${filterData?.minWallet}` : ""}${
    filterData?.district ? `&district=${filterData?.district}` : ""
  }${filterData?.status ? `&status=${filterData?.status}` : ""}${
    filterData?.zone_id ? `&zone_id=${filterData?.zone_id}` : ""
  }`;

  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(
      `/usermanagement/zonaladmin?type=technician${filterDataPayload}`
    );
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};
const UserManagementAllZonalAdmin = async (searchInput, filterData) => {
  const filterDataPayload = `${searchInput ? `&search=${searchInput}` : ""}${
    filterData?.startDate ? `&startDate=${filterData?.startDate}` : ""
  }${filterData?.endDate ? `&endDate=${filterData?.endDate}` : ""}${
    filterData?.maxWallet ? `&max_wallet=${filterData?.maxWallet}` : ""
  }${filterData?.minWallet ? `&min_wallet=${filterData?.minWallet}` : ""}${
    filterData?.district ? `&district=${filterData?.district}` : ""
  }${filterData?.status ? `&status=${filterData?.status}` : ""}${
    filterData?.zone_id ? `&zone_id=${filterData?.zone_id}` : ""
  }`;
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(
      `/usermanagement/zonaladmin?type=alladmin${filterDataPayload}`
    );
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};
const SuperAdminUsermanagementGetAllCust = async (searchInput, filterData) => {
  const filterDataPayload = `${searchInput ? `&search=${searchInput}` : ""}${
    filterData?.startDate ? `&startDate=${filterData?.startDate}` : ""
  }${filterData?.endDate ? `&endDate=${filterData?.endDate}` : ""}${
    filterData?.maxWallet ? `&max_wallet=${filterData?.maxWallet}` : ""
  }${filterData?.minWallet ? `&min_wallet=${filterData?.minWallet}` : ""}${
    filterData?.district ? `&district=${filterData?.district}` : ""
  }${filterData?.status ? `&status=${filterData?.status}` : ""}${
    filterData?.zone_id ? `&zone_id=${filterData?.zone_id}` : ""
  }`;
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(
      `/usermanagement/superadmin?type=customer${filterDataPayload}`
    );
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};
const SuperAdminUsermanagementGetAllTech = async (searchInput, filterData) => {
  const filterDataPayload = `${searchInput ? `&search=${searchInput}` : ""}${
    filterData?.startDate ? `&startDate=${filterData?.startDate}` : ""
  }${filterData?.endDate ? `&endDate=${filterData?.endDate}` : ""}${
    filterData?.maxWallet ? `&max_wallet=${filterData?.maxWallet}` : ""
  }${filterData?.minWallet ? `&min_wallet=${filterData?.minWallet}` : ""}${
    filterData?.district ? `&district=${filterData?.district}` : ""
  }${filterData?.zone_id ? `&zone_id=${filterData?.zone_id}` : ""}${
    filterData?.status ? `&status=${filterData?.status}` : ""
  }`;
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(
      `/usermanagement/superadmin?type=technician${filterDataPayload}`
    );
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};
const SuperAdminUsermanagementGetAllAdmin = async (searchInput, filterData) => {
  const filterDataPayload = `${searchInput ? `&search=${searchInput}` : ""}${
    filterData?.startDate ? `&startDate=${filterData?.startDate}` : ""
  }${filterData?.endDate ? `&endDate=${filterData?.endDate}` : ""}${
    filterData?.maxWallet ? `&max_wallet=${filterData?.maxWallet}` : ""
  }${filterData?.minWallet ? `&min_wallet=${filterData?.minWallet}` : ""}${
    filterData?.district ? `&district=${filterData?.district}` : ""
  }${filterData?.zone_id ? `&zone_id=${filterData?.zone_id}` : ""}
  ${filterData?.status ? `&status=${filterData?.status}` : ""}`;
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(
      `/usermanagement/superadmin?type=alladmin${filterDataPayload}`
    );
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const ParticularUserDelete = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.post(`/profile`, payload);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const ActiveProducts = async (data) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(`all/appliance?status=${data}`);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};
const GetAllProducts = async (status) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(
      `all/appliance?status=${status}&isMgmt=true`
    );
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

//auto-assign
const autoAssignConfig = async () => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(`auto/assign/config`);
    console.log("autoAssignConfig", response?.data);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const updateAutoAssign = async (id, payload) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.patch(
      `auto/assign/config/${id}`,
      payload
    );
    console.log("updateAutoAssign", response?.data);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

//update revenue config
const updateRevenueConfig = async (id, payload) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.patch(`revenue/config/${id}`, payload);
    console.log("updateAutoAssign", response?.data);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

//area management
const getAllZones = async (searchInput, filterData) => {
  const filterDataPayload = `${searchInput ? `&search=${searchInput}` : ""}${
    filterData?.startDate ? `&startDate=${filterData?.startDate}` : ""
  }${filterData?.endDate ? `&endDate=${filterData?.endDate}` : ""}${
    filterData?.maxWallet ? `&max_wallet=${filterData?.maxWallet}` : ""
  }${filterData?.minWallet ? `&min_wallet=${filterData?.minWallet}` : ""}${
    filterData?.district ? `&district=${filterData?.district}` : ""
  }${filterData?.zone_id ? `&zone_id=${filterData?.zone_id}` : ""}`;
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    let response;
    if (filterDataPayload)
      response = await instanceV2.get(`all/zones?${filterDataPayload}`);
    else response = await instanceV2.get(`all/zones`);
    console.log("getAllZones", response?.data);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const getZoneById = async (id) => {
  console.log(process.env.REACT_APP_BACKEND_API_V2, `zone/${id}`);
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(`zone/${id}`);
    console.log("getZoneById", response?.data);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const updateZone = async (city_id, update, payload) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.patch(
      `/zone/${city_id}?forceUpdate=${update}`,
      payload
    );
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};
const getAllDistricts = async (searchInput, filterData) => {
  const filterDataPayload = `${searchInput ? `&search=${searchInput}` : ""}${
    filterData?.startDate ? `&startDate=${filterData?.startDate}` : ""
  }${filterData?.endDate ? `&endDate=${filterData?.endDate}` : ""}${
    filterData?.maxWallet ? `&max_wallet=${filterData?.maxWallet}` : ""
  }${filterData?.minWallet ? `&min_wallet=${filterData?.minWallet}` : ""}${
    filterData?.district ? `&district=${filterData?.district}` : ""
  }${filterData?.zone_id ? `&zone_id=${filterData?.zone_id}` : ""}`;
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(`all/districts?${filterDataPayload}`);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};
const getDistrictsByZone = async (zone_id) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(
      `/district${zone_id ? `?zone_id=${zone_id}` : ""}`
    );
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};
const getDistrictWiseTechnician = async (ids) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(
      `/district/technician?districtIds=${JSON.stringify(ids)}`
    );
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const AreaManagementCreateZone = async (data, forceUpdate) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.post(
      process.env.REACT_APP_BACKEND_API_V2 + `zone?forceUpdate=${forceUpdate}`,
      data
    );
    return response.data;
    /*  */
  } catch (error) {
    handleUnAuthorizedError(error);

    return { error: error?.response?.data };
  }
};

const AreaManagementUpdateZone = async (data, zoneId, forceUpdate) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.patch(
      process.env.REACT_APP_BACKEND_API_V2 +
        `zone/${zoneId}?forceUpdate=${forceUpdate}`,
      data
    );
    return response.data;
    /*  */
  } catch (error) {
    handleUnAuthorizedError(error);

    return { error: error?.response?.data };
  }
};

const AreaManagementUpdateCity = async (district_id, data) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.patch(
      process.env.REACT_APP_BACKEND_API_V2 + `/district/${district_id}`,
      data
    );
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const reOpenSR = async (sr_id, payload) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.patch(
      `/service/request/reopen/${sr_id}`,
      payload
    );
    console.log("Reopen", response);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const getTechnicianProducts = async (
  min_amount,
  max_amount,
  appliance,
  page = 1,
  limit = 20000
) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });

    let url = `/product?page=${page}&limit=${limit}&status=active`;

    if (min_amount !== undefined && max_amount !== undefined) {
      url += `&price_min=${min_amount}&price_max=${max_amount}`;

      // Check if appliance is defined, not null, and not an empty string
      if (appliance !== undefined && appliance !== null && appliance !== "") {
        url += `&appliance=${appliance}`;
      }
    }
    const response = await instanceV2.get(url);

    console.log("getTechnicianProducts", response?.data);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const getTechnicianProductsById = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(`/product/${id}`);
    console.log("getTechnicianProductsById", response?.data);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const addToCart = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.post(`/cart`, payload);
    console.log("response: ", response);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const getdeliveryCharge = async (product_id, address_id) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(
      `/delivery/charge?product_id=${product_id}&address_id=${address_id}`
    );
    console.log("getTechnicianProductsById", response?.data);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};
const RemoveFromCart = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.delete(`/remove/cart/${id}`);
    return response?.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const cartLists = async (address_id) => {
  try {
    console.log("address_id", address_id);
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });

    let url = `/cart?status=added`;

    if (address_id) {
      url += `&address_id=${address_id}`;
    }

    const response = await instanceV2.get(url);

    console.log("getTechnicianCartProductsById", response?.data);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};
const myOrdersList = async (payload, orderStatus) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    let url = `/order/list?`;
    if (payload && payload.startDate && payload.endDate) {
      url += `startDate=${payload.startDate}&endDate=${payload.endDate}&`;
    }
    if (orderStatus) {
      url += `orderStatus=${orderStatus}`;
    }

    const response = await instanceV2.get(url);
    console.log("myOrdersList", response?.data);

    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};
const savedForLaterList = async (address_id) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(`/cart?status=saved_for_later`);
    console.log("savedForLaterList", response?.data);

    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const updateCart = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.patch(`/cart`, payload);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};
const saveForLater = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.patch(
      `/cart/${id}?status=saved_for_later`
    );
    console.log("save saved_for_later", response);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};
const moveToCart = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.patch(`/cart/${id}?status=added`);
    console.log("moveToCart", response);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};
const editOrderStatus = async (id, status) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.patch(
      `/order/status/${id}?status=${status}`
    );
    console.log("editOrderStatus: ", response);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};
//Ratings and reviews

const reviewsAndRatingsList = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.get(`/review/${id}`);

    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};
const postReview = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.post(`/review`, payload);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

//area management
const DeleteZone = async (Options, payload) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.patch(
      `/deletezone/${payload}?deletezone=${true}`
    );
    /* /deletezone/:zone_id?deletezone=true/false */
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const createCategoryManagement = async (data) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_API_V2 + "/appliance",
      data
    );
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);

    return { error: error.response.data };
  }
};

const DeleteApplianceInApplianceManagement = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.delete(`appliance/${id}`);
    return response?.data;
  } catch (error) {
    handleUnAuthorizedError(error);

    return { error: error.response.data };
  }
};

const editApplianceManagement = async (payload, id) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.patch(`appliance/update/${id}`, payload);
    return response?.data;
  } catch (error) {
    handleUnAuthorizedError(error);

    return { error: error?.response?.data };
  }
};

const getReview = async (revieweeId, entityId) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    // Use parameters in the URL
    const response = await instanceV2.get(`/review`, {
      params: {
        reviewee_id: revieweeId,
        entity_id: entityId,
      },
    });

    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};
const formEnquiry = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.post(`/inquiry`, payload);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const accountDeleteConfirmationApi = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.post("/user/deletion-status", payload);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return error;
  }
};

const accountDeleteSendOtpApi = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.post(`/user/send-otp`, payload);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return { error: error?.response?.data };
  }
};

const accountDeleteSendOtpVerificationApi = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.post(`/user/verify-otp`, payload);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return { error: error?.response?.data };
  }
};

const accountDeleteApi = async (payload) => {
  try {
    const token = localStorage.getItem("token");
    const instanceV2 = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_API_V2,
      headers: { Authorization: "Bearer " + token },
    });
    const response = await instanceV2.delete(`/delete/account`, payload);
    return response.data;
  } catch (error) {
    handleUnAuthorizedError(error);
    return { error: error?.response?.data };
  }
};

const Api = {
  Login,
  customerRegister,
  forgetPassword,
  verifyOTP,
  otpverify,
  Resendotp,
  changepassword,
  getAppliances,
  getApplianceDetail,
  createNewServiceRequest,
  getCountry,
  getStatesByCountryId,
  getCityByStateId,
  getServiceRequest,
  getServiceRequestList,
  customerResetPassword,
  cancelServiceRequest,
  technicianRegister,
  updateServiceRequest,
  Profile,
  getProfile,
  getProfileImage,
  getNotifications,
  getDashboardDetails,
  getPendingTechnicians,
  approveTechnician,
  rejectTechnician,
  getTechnicianDetails,
  DeleteUser,
  getServiceRequestOpenList,
  getTechnicians,
  updateTechnician,
  getUserAddress,
  adduserAddress,
  deleteuserAddress,
  edituserAddress,
  getServiceRequestUnassignedList,
  getServiceRequestNotAcceptedList,
  getServiceRequestAcceptedList,
  getServiceRequestAssignedList,
  getServiceRequestClosedList,
  getDistrictServiceRequestClosedList,
  getDistrictServiceRequestOpenList,
  getDistrictServiceRequestAcceptedList,
  getServiceRequestSummary,
  assignToMeRequest,
  assignServiceRequest,
  initiatePaymentServiceRequest,
  completePaymentServiceRequest,
  acceptServiceRequest,
  rejectServiceRequest,
  getServiceRequestOpenList,
  closeServiceRequest,
  getEligibleTechnicians,
  getSelectableAppliances,
  getApplianceRelatedList,
  createAppliance,
  getAppliance,
  updateAppliance,
  DistrictUserManagementAllCust,
  DistrictUserManagementGetOneUser,
  DistrictUserManagementAllTech,
  ZonalUserManagementAllCust,
  ZonalUserManagementAllTech,
  UserManagementAllZonalAdmin,
  SuperAdminUsermanagementGetAllCust,
  SuperAdminUsermanagementGetAllTech,
  SuperAdminUsermanagementGetAllAdmin,
  ParticularUserDelete,
  ActiveProducts,
  GetAllProducts,
  autoAssignConfig,
  updateAutoAssign,
  updateRevenueConfig,
  getAllZones,
  getZoneById,
  updateZone,
  getAllDistricts,
  getDistrictWiseTechnician,
  AreaManagementCreateZone,
  AreaManagementUpdateZone,
  AreaManagementUpdateCity,
  getSRCustomerDashboard,
  getLocationByPincode,
  reOpenSR,
  getTechnicianProducts,
  getTechnicianProductsById,
  addToCart,
  getdeliveryCharge,
  RemoveFromCart,
  cartLists,
  DeleteZone,
  getReferrerName,
  updateCart,
  createCategoryManagement,
  DeleteApplianceInApplianceManagement,
  editApplianceManagement,
  saveForLater,
  getDistrictsByZone,
  myOrdersList,
  savedForLaterList,
  moveToCart,
  editOrderStatus,
  reviewsAndRatingsList,
  postReview,
  getApplianceDropdown,
  getModelDropdown,
  getReview,
  formEnquiry,
  accountDeleteConfirmationApi,
  accountDeleteSendOtpApi,
  accountDeleteSendOtpVerificationApi,
  accountDeleteApi,
};
export default Api;
