import React from "react";
import tv_service from "../../Assets/Home/tv-service.svg";
import tv_service_1 from "../../Assets/Home/tv-service-2.svg";

export default function TVServicesOffered() {
  return (
    <div>
      <div>
        <div className="appliances-services-container-vision">
          <div className="first-flex-service">
            <div
              style={{ fontWeight: 600, fontSize: "34px" }}
              className="res-576px-font-25px res-576px-text-align-center"
            >
              Services offered:{" "}
            </div>
            <div
              style={{
                fontSize: "15px",
                color: "rgba(26, 26, 26, 0.7)",
                marginTop: "30px",
                lineHeight: "26px",
              }}
            >
              <ul style={{ color: "black" }}>
                <li>
                  <b> Repair: </b>{" "}
                  <span style={{ color: "rgba(26, 26, 26, 0.7)" }}>
                    To book a repair service, log in to our website or mobile
                    application and search for television service. You can enter
                    the specifications including TV display size and the nature
                    of the issue faced. Be it LED, LCD, Curved, or Smart
                    Television, our technicians can handle it all, covering
                    various issues,
                  </span>{" "}
                  <b>including:</b>
                </li>
                <div
                  style={{
                    fontSize: "12px",
                    marginLeft: "40px",
                    marginTop: "10px",
                  }}
                  className="res-576px-ml-0px"
                >
                  {" "}
                  1. Blank screen{" "}
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    marginLeft: "40px",
                    marginTop: "4px",
                  }}
                  className="res-576px-ml-0px"
                >
                  {" "}
                  2. TV does not switch on
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    marginLeft: "40px",
                    marginTop: "4px",
                  }}
                  className="res-576px-ml-0px"
                >
                  3. Issues with connecting external systems or devices
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    marginLeft: "40px",
                    marginTop: "4px",
                  }}
                  className="res-576px-ml-0px"
                >
                  4. Horizontal line in the middle of the TV screen or distorted
                  images
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    marginLeft: "40px",
                    marginTop: "4px",
                  }}
                  className="res-576px-ml-0px"
                >
                  5. No picture, only audio, or No audio, only picture{" "}
                </div>
                <li className="mt-3">
                  <b>Installation/Uninstallation:</b>{" "}
                  <span style={{ color: "rgba(26, 26, 26, 0.7)" }}>
                    This is the day and age of constant change. Be it migration
                    to a new city or an interior revamp, TV installation,
                    reinstallation, or uninstallation is a constant ask. Be it
                    wall mount, or installation on the universal or standard TV
                    stand, along with home theatre, we are equipped to support
                    it all.
                  </span>
                </li>
                <li className="mt-3">
                  <b> Maintenance Service: </b>
                  <span style={{ color: "rgba(26, 26, 26, 0.7)" }}>
                    To ensure the long-lasting life of any appliance, it is
                    important to invest in annual maintenance and cleaning. This
                    includes recommendations for better viewing or display
                    maintenance, and more. Book your service now at minimal cost
                    to ensure huge savings later. 
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div
            className="second-flex-img res-576px-w-fill"
            style={{ scale: "0.8" }}
          >
            <img
              className="res-576px-w-fill"
              src={tv_service_1}
              alt="phone_img"
            />
          </div>
        </div>
      </div>
      <div className="appliances-services-container-mission">
        <div
          className="second-flex-img"
          style={{ scale: "0.8", marginTop: "-80px" }}
        >
          <img src={tv_service} alt="tv_service" />
        </div>
        <div className="first-flex-service">
          <div
            style={{ fontWeight: 600, fontSize: "34px" }}
            className="res-576px-font-25px res-576px-text-align-center"
          >
            Why S3 for TV Repair and Service?{" "}
          </div>
          <div
            style={{
              fontSize: "15px",
              color: "rgba(26, 26, 26, 0.7)",
              marginTop: "30px",
              lineHeight: "26px",
            }}
          >
            <ul style={{ color: "black", textAlign: "justify" }}>
              <li>
                <span style={{ fontWeight: 600 }}>
                  Trained and experienced technicians:
                </span>{" "}
                <span style={{ color: "rgba(26, 26, 26, 0.7)" }}>
                  Our administrators and supervisors guarantee that each S3
                  technician is trained in professional and personal etiquette.
                  We set a high level of quality as our standard and ensure it
                  only increases year on year!{" "}
                </span>
              </li>
              <li className="mt-2">
                <span style={{ fontWeight: 600 }}> One-stop solution:</span>{" "}
                <span style={{ color: "rgba(26, 26, 26, 0.7)" }}>
                  With S3, you get a one-stop solution to avail the best
                  television services for any brand – Samsung, Micromax, LG,
                  TCL, etc.{" "}
                </span>{" "}
              </li>
              <li className="mt-2">
                <span style={{ fontWeight: 600 }}> Convenient Time Slots:</span>{" "}
                <span style={{ color: "rgba(26, 26, 26, 0.7)" }}>
                  There are multiple time slots available based on your choice.
                  Until the technician is assigned, you also have the option to
                  reschedule the appointment. Our administrators are on the
                  constant lookout to ensure the best match technician is
                  assigned to any TV repair, service, or installation.{" "}
                </span>{" "}
              </li>
              <li className="mt-2">
                <span style={{ fontWeight: 600 }}>30-day-warranty:</span>{" "}
                <span style={{ color: "rgba(26, 26, 26, 0.7)" }}>
                  We guarantee to fix any issue that recurs post-service
                  delivery within 30 days!{" "}
                </span>{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
