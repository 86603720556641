import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "./LoginTechnician.css";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import confiti from "../../../Assets/Home/confitii.svg";
import { getAllrewardLoginList } from "../../../redux/actions/rewardLoginTechnician";
import FilterModule from "../../../plugins/FilterModule";

const AllTechHistoryDataTable = () => {
  const dispatch = useDispatch();
  const role = localStorage.getItem("role");
  const [searchInput, setSearchInput] = useState(null);
  const [filterData, setFilterData] = useState({});
  const [loginTechnicianData, setLoginTechnicianData] = useState([]);

  const { rewardLoginListData } = useSelector(
    (state) => state.rewardLoginListState
  );
  const dateRange = useSelector(
    (state) => state.rewardDateRangeState.dateRange
  );

  const payload = {
    startDate: moment(dateRange[0].startDate).format("YYYY-MM-DD"),
    endDate: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
  };

  useEffect(() => {
    // Fetch data when date range or filters change
    dispatch(
      getAllrewardLoginList(
        payload.startDate,
        payload.endDate,
        "technician",
        searchInput,
        filterData
      )
    );
  }, [searchInput, filterData, dispatch, payload.startDate, payload.endDate]);

  useEffect(() => {
    if (rewardLoginListData && rewardLoginListData.length > 0) {
      const transformedData = rewardLoginListData.map((tech) => ({
        user_id: tech.user_id,
        name: tech.name,
        avatar: tech.avatar,
        zone_name: tech.zone_name,
        city_name: tech.city_name,
        participated_at: tech.participated_at,
        winning_status: tech.winning_status,
        phone: tech.mobile_no,
      }));
      setLoginTechnicianData(transformedData);
    } else {
      setLoginTechnicianData([]); // Ensure loginTechnicianData is empty when no data
    }
  }, [rewardLoginListData]);

  const formattedDateTime = (isoString) => {
    const date = new Date(isoString);
    return date
      .toLocaleString("en-IN", {
        dateStyle: "short",
        timeStyle: "short",
        hour12: true,
      })
      .toUpperCase();
  };

  const loginTechnicianColumns = [
    {
      name: "Name",
      selector: (row) => row.name,
      grow: 1.5,
      cell: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={
              row.avatar ||
              "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBw8ODw4NDg0QDw4ODQ0NDg0NDg8NDQ4NFREWFhURFRMYHSggGBolGxUTITEhJSkrLi4uGB8zODMtNygtLisBCgoKDQ0NDg0NDzcZFRk3KysrKy0rNysrKysrLSsrKysrKys3KysrKysrKysrKysrKysrKysrKysrKysrKysrK//AABEIAOEA4QMBIgACEQEDEQH/xAAaAAEBAAMBAQAAAAAAAAAAAAAAAwECBQQH/8QAMhABAAIAAwUFBgYDAAAAAAAAAAECAwQRITFRUpESE0FhcQUUMqHR8CJCcoGx4TOSwf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAwDAQACEQMRAD8A+4gAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAATIA0nEhrN5BUR7U8WNQXENWe1PFRYSi8toxEG4xEsgAAAAAAAAAAAAAAAADFp0StbUG1sTg0mQVAAAAAAAABvW/FoAtEsoxOilbaorYAAAAAAAAAAABi1tCZSmdQYmQFQAAAAHlxs7EbK/inj4f281s5efHT0iBXTHLjN4nN1iFsLPeFo/ePoGPcMVtExrE6x5MiAAAAK0tq2QiVqzqisgAAAAAAAA1tOkA0vZqCoAAAAOfncxrM0rujfPGeD15nE7NJnx3R6y5IoAigAK5fHmk+U74+/F1azExExunbDivf7OxNYmvDbHpIj2AKgAAzS2jAC41pOxsigAAAAACeJKiMyDACoAAAA8vtH4Y/XH8S5zp56utJ8pifvq5iLAAUAAer2d8c/pn+YeV7fZtdtreUR/0R7gFQAAABtSdqqC8IoAAAAADEoq33SkIAKAAAAMTGuxysxhdi2nh4Txh1mmLhRaNJ/uBXHF8bKWrujtRxj6IIoCmFgWtuj952QDStZmYiI1mXWwMPsVivWeMtMvl4p524/RcQAVAAAABWm6ElcPcitgAAAAAa33SktKIgAoAAAxa0RtmdI8wZHlxM9WPhibfKHnvnbzu0j0jWfmK6TW1InfWJ9Y1cqce877z10aTaZ8Z6yg7EYVY3Vj/AFbOLrPGerMYlo3WnrIY7I5Vc1ePza+u1amfn81YnzjYo94jhZmlt06TwnZKwgAAAArh7klaboRWwAAAAACErpYkbQagKgCOaxuxXXxnZHrxBrmc1FNkbbfKPVzsTEm062nVrMiNAAAAAAAAD04Gbmuy34q/OHmAdqtomImJ1ifFlzcljdm3ZndaekukrIAAtCdI2qooAAAAAA1vGsNgEBm8aSwqDne0La304RHz+4dFy87/AJLft/ECxABFAAAAAAAAAAHapbWInjES4rr5b4K/pgSqAzWNVRvhw3BFAAAAAAAAYtGqMwu1vXUEmlsOs7ZrEzxmG8ion3NOSvSDuaclekKAJ9xTkr0g7inJXpCgCfcU5K9IO4pyV6QoAl3FOSvQ93pyR0VAT7inJHQ7inJHRQBP3enJHQ93pyR0UAS93pyR0UiGQBWldGKVbooAAAAAAAAAAADW1dUpjRdiY1BEb2o0VAAAAAAAAAG1aSDWIUrTRtFdGUUAAAAAAAAAAAAAAAAYmsSyAnOHwazSVgEBdjQERbRkEYrPBtGHxUAYisQyAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAP/9k="
            }
            alt="avatar"
            style={{
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              marginRight: "5px",
            }}
          />
          <span>{row.name}</span>
          {row.winning_status === true && (
            <img
              src={confiti}
              alt="avatar"
              style={{
                width: "25px",
                height: "25px",
                borderRadius: "50%",
                marginRight: "5px",
                transform: "scaleX(-1)",
              }}
            />
          )}
        </div>
      ),
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
    },
    {
      name: "Zone Name",
      selector: (row) => row.zone_name || "-",
    },
    {
      name: "District",
      selector: (row) => row.city_name,
    },
    {
      name: "Participated Date",
      selector: (row) => row.participated_at,
      cell: (row) => formattedDateTime(row.participated_at),
      sortable: true,
    },
  ];

  const handleSearchInput = useCallback((e) => {
    setSearchInput(e);
  }, []);

  const handleFilterData = (e) => {
    setFilterData(e);
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
  };

  return (
    <div
      className={`data-table-container ${role === "ZonalAdmin" ? "max-h" : ""}`}
    >
      <div className="header-container">
        <p className="left-text">
          Total Participations ({loginTechnicianData?.length})
        </p>
        <div className="right-section">
          <FilterModule
            inputPlaceholder={"Search by Name"}
            onSearchInputChange={handleSearchInput}
            onChangeFilterData={handleFilterData}
            // showDatePicker={true}
            showWinningStatus={true}
          />
        </div>
      </div>
      <DataTable
        columns={loginTechnicianColumns}
        data={loginTechnicianData}
        pagination
        fixedHeader
        fixedHeaderScrollHeight="300px"
        customStyles={customStyles}
      />
    </div>
  );
};

export default AllTechHistoryDataTable;
