import { createSlice } from "@reduxjs/toolkit";


const applianceDetailsSlice = createSlice({
    name: 'applianceDetails',
    initialState:{
        loading: false,
    },
    reducers:{
        applianceDetailsRequest(state, action){
            return{
                ...state,
                loading: true
            }
        },
        applianceDetailsSuccess(state, action){
            return{
                ...state,
                loading: true,
                applianceDetailsData: action.payload
            }
        },
        applianceDetailsFail(state, action){
            return{
                ...state,
                loading: false,
                error: action.payload
            }
        }
    }
});

const {actions, reducer} = applianceDetailsSlice;

export const {applianceDetailsRequest,applianceDetailsFail,applianceDetailsSuccess} = actions

export default reducer;