import React from "react";

const ArrowIconHighlighted = () => {
  return (
    <svg
      width="9"
      height="13"
      viewBox="0 0 9 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.16472 8.70711C8.55524 8.31658 8.55524 7.68342 8.16472 7.29289L1.80076 0.928932C1.41023 0.538407 0.777067 0.538407 0.386542 0.928932C-0.00398193 1.31946 -0.00398199 1.95262 0.386542 2.34315L6.0434 8L0.386541 13.6569C-0.0039831 14.0474 -0.00398316 14.6805 0.386541 15.0711C0.777066 15.4616 1.41023 15.4616 1.80075 15.0711L8.16472 8.70711ZM6.40625 9L7.45761 9L7.45761 7L6.40625 7L6.40625 9Z"
        fill="#EB8128"
      />
    </svg>
  );
};

export default ArrowIconHighlighted;
