import { useEffect, useState, useMemo, Fragment } from "react";
import Api from "../../../service/services";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import {
  Checkbox,
  IconButton,
  Input,
  InputAdornment,
  InputBase,
  InputLabel,
  Paper,
  TextField,
  useMediaQuery,
  Modal,
  Tooltip,
  alertClasses,
  FormControlLabel,
  Switch,
  Box,
  MenuItem,
  Select,
} from "@mui/material";
import {
  AddCircleOutline,
  Delete,
  DeleteOutlineOutlined,
  FilterAltOutlined,
  Search,
} from "@mui/icons-material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconDelete from "@mui/icons-material/Delete";
import IconEdit from "@mui/icons-material/Edit";
import IconChecked from "@mui/icons-material/CheckCircle";
import IconCancel from "@mui/icons-material/Cancel";
import { Button, Card, FormControl, Stack, Table } from "react-bootstrap";
import ProductModel from "./product-model";
import "../../../../src/styles/Appliancemanagement.css";
import Swal from "sweetalert2";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import shadows from "@mui/material/styles/shadows";
import { FaFontAwesome, FaLess } from "react-icons/fa";
import s3Logo from "../../../Images/s3Logo_resposive.png";
import SearchableDropdown from "../../common/searchableDropDown";
import "./product-model/style.css";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "@mui/material/styles";

const TABLE_HEADERS = [
  { rowKey: "appliance_model", text: "Model" },
  { rowKey: "appliance_from_size", text: "From" },
  { rowKey: "appliance_to_size", text: "To" },
  { rowKey: "appliance_basic_charge", text: "Basic" },
  { rowKey: "appliance_commission_charge", text: "Commission" },
  /*   { rowKey: "status", text: "Status" }, */
  { rowKey: "action", text: "Action" },
];

function ActiveAppliance() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [applianceList, setApplianceList] = useState([]);
  const [errorName, setErrorName] = useState(false);
  const [errorSize, setErrorSize] = useState(true);
  const [applianceId, setApplianceId] = useState("");
  const [brandList, setBrandList] = useState([]);
  const [brandValue, setBrandValue] = useState("");
  const [modelData, setModelData] = useState(null);
  const [modelName, setModelName] = useState("");
  const [modelList, setModelList] = useState([]);
  const [productModels, setProductModels] = useState([]);
  const [applianceName, setApplianceName] = useState("");
  const [submitButtonEnable, setSubmitButtonEnable] = useState(false);
  const [editButtonEnable, setEditButtonEnable] = useState(false);
  const [image, setImage] = useState(null);
  const [images, setImages] = useState("");
  const [createEnable, setCreateEnable] = useState(false);
  const [sizeList, setSizeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sizeValue, setSizeValue] = useState([]);
  const handleChange = () => {
    Api.GetAllProducts("active").then((res) => {
      setApplianceList(res);
      console.log("res", res);
    });
  };

  useEffect(() => {
    Api.GetAllProducts("active").then((res) => {
      setErrorName(false);
      setErrorSize(false);
      setApplianceList(res);
      setApplianceId(res[0]?.id);
      res[0]?.id && handelSingleUser(res[0]);
      setIsCreateVisble(true);
      setIsDeleteVisble(true);
      setIsEditVisble(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth" // Optional smooth scrolling effect
      });
    });
  }, []);

  const rowKeys = useMemo(() => TABLE_HEADERS.map(({ rowKey }) => rowKey), []);

  const handelSingleUser = (payload) => {
    /*  {
      appliance_name,
      id,
      brand,
      model,
      size,
      appliance_image,
      default_charges_configuration,
      status,
      unit_of_size,
    } */
    setUnitOfSize({});
    setErrorName(false);
    setErrorSize(false);
    setUnitOfSize({ id: 999, fullname: payload?.unit_of_size });
    payload?.status === "active" ? setChecked(true) : setChecked(false);
    setImage(payload?.appliance_image);
    payload?.appliance_name && setApplianceName(payload?.appliance_name);
    setApplianceId(payload?.id);
    setCreateEnable(false);
    setSubmitButtonEnable(false);
    setEditButtonEnable(false);
    setIsCreateVisble(true);
    setIsDeleteVisble(true);
    setIsEditVisble(true);
    if (isMobile) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }
    /*  */
    let modelListData = [{ ...payload?.default_charges_configuration }];
    payload?.model?.map((data) => {
      data.charges_configuration.map((configs) => {
        modelListData = [...modelListData, configs];
      });
    });
    const loopedModel = modelListData.map((list) => {
      return {
        ...list,
        appliance_basic_charge: list?.basic_charge,
        appliance_commission_charge: list?.commission_charge,
        status: list?.status === "active" ? true : false,
        appliance_model:
          payload?.model.find(
            (modelData) => modelData?.id === list?.appliance_model_id
          )?.appliance_model || list?.appliance_model_id,
      };
    });

    payload?.brand.length > 0
      ? setBrandList(
        payload?.brand?.map(({ brand_name }) => ({
          label: brand_name,
          isChecked: true,
        }))
      )
      : setBrandList([]);
    payload?.size?.size.length > 0
      ? setSizeList(
        payload?.size?.size?.map((size) => {
          return { label: size, isChecked: true };
        })
      )
      : setSizeList([]);
    // model.length > 0 ? setModelList(loopedModel) : setModelList([]);
    setModelList(loopedModel);
  };

  const handleBrandvalue = (e) => {
    setBrandValue(e.target.value);
  };
  const handleAddBrandList = () => {
    if (brandValue) {
      setBrandList([...brandList, { label: brandValue, isChecked: true }]);
      setBrandValue("");
    }
  };

  const handleSizevalue = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9.]/g, "");
    setSizeValue(numericValue);
  };
  const handleAddSizeList = () => {
    if (sizeValue) {
      setSizeList([...sizeList, { label: sizeValue, isChecked: true }]);
      setSizeValue("");
    }
  };

  const mobileMatches = useMediaQuery("(min-width:1200px)");
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const onToggleBrand = (index, event) => {
    const brandsArr = [...brandList];
    brandsArr[index].isChecked = event.target.checked;
    setBrandList(brandsArr);
  };

  const onToggleSize = (index, event) => {
    const sizesArr = [...sizeList];
    sizesArr[index].isChecked = event.target.checked;
    setSizeList(sizesArr);
  };

  const [unitOfSize, setUnitOfSize] = useState({});

  const unitOfSizeList = [
    { id: 8, fullname: "BTUs" },
    { id: 7, fullname: "Cubic feet" },
    { id: 1, fullname: "Inch", key: "IN" },
    { id: 2, fullname: "Kilogram", key: "KG" },
    { id: 4, fullname: "Liter", key: "L" },
    { id: 3, fullname: "Meter", key: "M" },
    { id: 5, fullname: "Ton", key: "TON" },
    { id: 6, fullname: "Watt", key: "W" },
  ];
  const handleChangeUnitOfSize = (e) => {
    setErrorSize(false);
    setUnitOfSize(e);
  };

  const onCompleteModel = (data) => {
    const { modelIndex } = data;
    if (modelIndex >= 0) {
      const modelsArr = [...modelList];
      modelsArr[modelIndex] = data;
      setModelList(modelsArr);
    } else setModelList((prev) => [...prev, data]);
    setModelData(null);
    setModelName("");
  };
  const [addButtonEnable, setAddButtonEnable] = useState(false);
  const onAddModel = () => {
    if (modelName && !addButtonEnable) {
      setModelData({
        productModels: [modelName],
        appliance_model: modelName,
        appliance_from_size: "Default",
        appliance_to_size: "Default",
        appliance_basic_charge: "",
        appliance_commission_charge: "",
        status: true,
        disabled: true,
        reload: false,
      });

      setProductModels((prev) =>
        prev.includes(modelName) ? prev : [...prev, modelName]
      );
    }
  };

  const onChangeModel = (e) => {
    const isEnableAddButton =
      modelList &&
      modelList.some(
        (ele) =>
          ele?.appliance_model.toLowerCase() === e.target.value?.toLowerCase()
      );
    setAddButtonEnable(isEnableAddButton);
    setModelName(e.target.value);
  };

  const renderBrand = ({ label, isChecked }, index) => (
    <label htmlFor={`${label}-${index}`}>
      <Checkbox
        style={{ cursor: createEnable ? "pointer" : "not-allowed" }}
        id={`${label}-${index}`}
        label={label}
        checked={isChecked}
        key={index}
        onChange={createEnable && onToggleBrand.bind(null, index)}
      />
      {label}
    </label>
  );

  const renderSize = ({ label, isChecked }, index) => (
    <label htmlFor={`${label}-${index}`}>
      <Checkbox
        style={{ cursor: createEnable ? "pointer" : "not-allowed" }}
        id={`${label}-${index}`}
        label={label}
        checked={isChecked}
        key={index}
        onChange={createEnable && onToggleSize.bind(null, index)}
      />
      {label}
    </label>
  );

  let globalList = [
    ...new Set(
      modelList &&
      modelList?.map((data) => {
        if (data.appliance_model?.toLowerCase() !== "default")
          return data.appliance_model;
      })
    ),
  ];

  const onAddMore = () => {
    /*    productModels[0] */
    setModelData({
      productModels: globalList,
      appliance_model: productModels[0],
      appliance_from_size: "",
      appliance_to_size: "",
      appliance_basic_charge: "",
      appliance_commission_charge: "",
      status: true,
      disabled: false,
      reload: false,
    });
  };

  const renderTableHeader = ({ text }, index) => (
    <th className="fs-12" key={index}>
      {text}
    </th>
  );

  const onDeleteModel = (rowIndex) =>
    setModelList((prev) => prev.filter((_, index) => index !== rowIndex));

  const onEditModel = (rowIndex) => {
    const {
      appliance_model,
      appliance_from_size,
      appliance_to_size,
      appliance_basic_charge,
      appliance_commission_charge,
      status,
    } = modelList[rowIndex];

    setModelData({
      productModels: [appliance_model],
      appliance_model,
      appliance_from_size,
      appliance_to_size,
      appliance_basic_charge,
      appliance_commission_charge,
      status,
      reload: false,
      disabled: appliance_from_size === "Default",
      modelIndex: rowIndex,
    });
  };

  const renderCell = (item, rowIndex, key, index) => (
    <td className="fs-12" key={index}>
      {key === "action" ? (
        <>
          <>
            <IconButton
              onClick={onEditModel.bind(null, rowIndex)}
              disabled={!createEnable}
            >
              <IconEdit fontSize="small" />
            </IconButton>
            <IconButton
              onClick={onDeleteModel.bind(null, rowIndex)}
              disabled={
                item?.appliance_model.toLowerCase() == "default" ||
                !createEnable
              }
            >
              <IconDelete fontSize="small" />
            </IconButton>
          </>
        </>
      ) : key === "status" ? (
        item[key] ? (
          <IconChecked className="active" />
        ) : (
          <IconCancel className="in-active" />
        )
      ) : (
        item[key]
      )}
    </td>
  );

  const renderTableRow = (model, index) => (
    <tr key={index}>{rowKeys.map(renderCell.bind(null, model, index))}</tr>
  );

  const onImageChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const fileList = Array.from(event.target.files); // Convert FileList to an array
      const imageUrls = fileList.map((file) => URL.createObjectURL(file));
      setImage(imageUrls);
      setImages(fileList[0]);
    }
  };

  const handleRemoveAllFields = () => {
    setUnitOfSize({});
    setSubmitButtonEnable(true);
    setEditButtonEnable(false);
    setCreateEnable(true);
    setImage("");
    setApplianceName("");
    setBrandValue("");
    setSizeValue("");
    setBrandList([]);
    setSizeList([]);
    setModelList([]);
    setApplianceId("");
    setModelData({
      productModels: ["Default"],
      appliance_model: "Default",
      appliance_from_size: "Default",
      appliance_to_size: "Default",
      appliance_basic_charge: "",
      appliance_commission_charge: "",
      status: true,
      disabled: false,
      reload: true,
    });
  };

  const handelApplianceName = (e) => {
    if (e.target.value === "" || !applianceName) {
      setErrorName(true);
    } else if (e.target.value.length > 20) {
      setErrorName(true);
    } else {
      setErrorName(false);
    }
    setApplianceName(e.target.value);
  };

  const handleSubmit = () => {
    let array = [];
    let brand = [];
    brandList.map((data) => {
      if (data?.isChecked) {
        brand.push(data.label);
      }
    });
    let size = [];
    sizeList.map((data) => {
      console.log(data);
      if (data?.isChecked) {
        size.push(data.label);
      }
    });

    /*  */
    if (unitOfSize?.fullname === undefined) {
      setErrorSize(true);
    }
    if (!applianceName) {
      setErrorName(true);
    }

    setBrandValue("");
    setSizeValue("");
    setModelName("");

    const default_charge =
      modelList &&
      modelList.find((ele) => ele?.appliance_model.toLowerCase() === "default");
    const charge =
      modelList &&
      modelList.filter(
        (ele) => ele?.appliance_model.toLowerCase() !== "default"
      );
    const inputData = modelList;
    const modelsName = [
      ...new Set(
        charge &&
        charge.map((ele) => {
          return ele.appliance_model;
        })
      ),
    ];

    const models =
      modelsName &&
      modelsName?.map((data) => {
        const charge_configuration =
          charge &&
          charge.map((ele) => {
            if (
              ele.appliance_model === data &&
              ele.appliance_model.toLowerCase() !== "default"
            ) {
              return {
                from:
                  ele?.appliance_from_size.toLowerCase() === "default"
                    ? "default"
                    : ele?.appliance_from_size,
                to:
                  ele?.appliance_to_size.toLowerCase() === "default"
                    ? "default"
                    : ele?.appliance_to_size,
                commission_charge: ele?.appliance_commission_charge,
                basic_charge: ele?.appliance_basic_charge,
              };
            }
          });

        return {
          name: data,
          charge_configuration:
            charge_configuration && charge_configuration.filter(Boolean),
        };
      });

    array = {
      appliance_name: applianceName,
      brand: brand,
      model: models,
      size: size,
      unit_of_size: unitOfSize?.fullname,
      status: checked == true ? "active" : "inactive",
      default_charge: {
        basic_charge:
          default_charge?.appliance_basic_charge ||
          default_charge?.basic_charge,
        commission_charge:
          default_charge?.appliance_commission_charge ||
          default_charge?.commission_charge,
      },
    };
    const fieldData = new FormData();
    fieldData.append("fields", JSON.stringify(array));
    fieldData.append("files", images);
    if (image) {
      if (applianceName !== "" && unitOfSize?.fullname) {
        submitButtonEnable
          ? createAppliance(fieldData)
          : EditApplianceSubmit(fieldData);
      } else {
        Swal.fire({
          text: !applianceName
            ? "Appliance name required."
            : "Unit of size required.",
          showConfirmButton: false,
          timer: 3000,
          iconSize: "1.5em",
        });
      }
    } else {
      Swal.fire({
        text: "Appliance image required",
        showConfirmButton: false,
        timer: 3000,
        iconSize: "1.5em",
      });
    }
  };

  const EditApplianceSubmit = (fieldData) => {
    Api.editApplianceManagement(fieldData, applianceId).then((result) => {
      if (result?.error) {
        Swal.fire({
          text: result?.error,
          showConfirmButton: false,
          timer: 3000,
          iconSize: "1.5em",
        });
      } else {
        Swal.fire({
          text: result,
          showConfirmButton: false,
          timer: 3000,
          iconSize: "1.5em",
        });
        Api.GetAllProducts("active").then((res) => {
          setApplianceList(res);
          setApplianceId(res[0]?.id);
          res[0]?.id && handelSingleUser(res[0]);
        });
      }
    });
  };

  const createAppliance = (fieldData) => {
    Api.createAppliance(fieldData).then((result) => {
      if (result?.error) {
        Swal.fire({
          text: result?.error,
          showConfirmButton: false,
          timer: 3000,
          iconSize: "1.5em",
        });
      } else {
        Swal.fire({
          text: result,
          showConfirmButton: false,
          timer: 3000,
          iconSize: "1.5em",
        });
        Api.GetAllProducts("active").then((res) => {
          setApplianceList(res);
          setApplianceId(res[0]?.id);
          res[0]?.id && handelSingleUser(res[0]);
        });
        handleChange();
      }
    });
  };
  const EditAppliance = () => {
    setCreateEnable(true);
    setEditButtonEnable(true);
    setSubmitButtonEnable(false);
  };
  const [name, setName] = useState("");
  const handleFilterByName = (event) => {
    setName(event.target.value);
    if (String(name) === "") {
      Api.GetAllProducts("active").then((res) => {
        setApplianceList(res);
      });
    } else {
      Api.GetAllProducts("active").then(async (res) => {
        const filteredTests = await res?.filter((service) => {
          const datas = String(service?.appliance_name).toLowerCase();
          return datas?.includes(name?.toLowerCase());
        });
        setApplianceList(filteredTests);
      });
    }
  };

  const DeleteAppliance = () => {
    Swal.fire({
      text: `Are you sure, you want to delete ${applianceName}?`,
      confirmButtonText: "OK",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Api.DeleteApplianceInApplianceManagement(applianceId).then((result) => {
          if (result?.error) {
            Swal.fire({
              text: result?.error,
              showConfirmButton: false,
              timer: 3000,
              iconSize: "1.5em",
            });
            window.location.reload();
          } else {
            Swal.fire({
              text: result,
              showConfirmButton: false,
              timer: 3000,
              iconSize: "1.5em",
            });
            handleChange();
            window.location.reload();
          }
        });
      }
    });

    setIsEditVisble(true);
  };

  const [checked, setChecked] = useState(true);

  const handleToggle = (event) => {
    setChecked(event.target.checked);
  };
  const [isCreateVisble, setIsCreateVisble] = useState(true);
  const [isEditVisble, setIsEditVisble] = useState(true);
  const [isDeleteVisble, setIsDeleteVisble] = useState(true);

  const handleCancel = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="row" style={{ overflow: "hidden" }}>
        <div className="col-lg-4">
          <div
            className="p-0 mt-2 ms-2"
            style={{ display: "flex", width: "-webkit-fill-available" }}
          >
            <Paper
              elevation={0}
              className="w-100 p-lg-3 border rounded-0"
              component="form"
              sx={{ display: "flex", alignItems: "center" }}
              style={{ height: "42px" }}
            >
              <IconButton type="button" sx={{ p: "2px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                style={{
                  fontFamily: "Arial",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "14px",
                }}
                placeholder="Search by Name"
                inputProps={{ "aria-label": "search google maps" }}
                onChange={handleFilterByName}
              />
            </Paper>
            <div className="ms-2" style={{ listStyle: "none" }}>
              {/* <IconButton
                style={{
                  width: "42px",
                  height: "42px",
                  
                  borderRadius: "100px",
                }}
                className="border rounded-0"
                aria-label="delete"
              >
                <FilterAltOutlined style={{ color: "rgb(235, 129, 40)" }} />
              </IconButton> */}
            </div>
          </div>
          <div
            className="custom-scrollbar-appliance appliancelist-grid-container"
            style={{ width: "100%", height: "120vh", overflowY: "scroll" }}
          >
            {applianceList.length > 0 ? (
              applianceList?.map((value) => (
                <Card
                  onClick={() => handelSingleUser(value)}
                  style={{
                    background: value.id === applianceId ? "#FFE5CC" : "white",
                    cursor: "pointer",
                    border: "2px soild rgb(235, 129, 40)",
                    width: "9rem",
                    height: "10rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div style={{ flex: 1 }}>
                    {value?.appliance_image && (
                      <img
                        src={value?.appliance_image || s3Logo}
                        alt=""
                        className="appliance-image-list"
                        style={{
                          marginBottom: "auto",
                          width: "100%",
                          aspectRatio: "3/2",
                          objectFit: "contain",
                          marginTop: "10px",
                        }}
                      />
                    )}
                  </div>
                  <CardContent>
                    <dec
                      id="product-description"
                      variant="body2"
                      color="text.secondary"
                      style={{ fontSize: "12px" }}
                    >
                      {value.appliance_name.length < 8
                        ? value.appliance_name
                        : value.appliance_name.slice(0, 8) + "..."}
                    </dec>
                  </CardContent>
                </Card>
              ))
            ) : (
              <p className="mt-3 text-center">No Data found!</p>
            )}
          </div>
        </div>
        <div className="col-lg-8 p-0 custom-appliance-input-scrollbar">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              float: "right",
              className: "me-2",
              justifyContent: "space-between",
            }}
          >
            <>
              {isEditVisble ? (
                <>
                  <Button
                    id="appliance-buttons-color-change"
                    className="res-sr-btns me-4"
                    variant="contained"
                    style={{
                      color: "rgb(235, 129, 40)",
                      width: "5rem",
                      height: "40px",
                      border: "1px solid rgb(235, 129, 40)",
                      ...(!isEditVisble && { cursor: "not-allowed" }),
                      textTransform: "none",
                      backgroundColor: "white",
                    }}
                    onClick={() => {
                      EditAppliance();
                      /*  setIsCreateVisble(false);
                        setIsDeleteVisble(false); */
                      setIsEditVisble(false);
                    }}
                  >
                    Edit
                  </Button>
                  {/*    <Button
                    id="appliance-buttons-color-change"
                    className="res-sr-btns me-4 "
                    variant="contained"
                    style={{
                      color: "rgb(235, 129, 40)",
                      width: "5rem",
                      height: "40px",
                      ...(!isDeleteVisble && { cursor: "not-allowed" }),
                      border: "1px solid rgb(235, 129, 40)",
                      textTransform: "none",
                      backgroundColor: "white",
                    }}
                    onClick={() => {
                      DeleteAppliance();
                    }}
                  >
                    Delete
                  </Button> */}
                </>
              ) : (
                <></>
              )}
            </>

            {/*  <i
                className="fa fa-edit me-4"
                style={{ fontSize: "2.5rem", color: "gray", cursor: "pointer" }}
                onClick={EditAppliance}
              ></i> */}
          </div>
          <div className="row ms-3">
            {image ? (
              <>
                <span
                  className="appliance-image"
                  style={{ display: "inline-flex" }}
                >
                  <IconButton
                    color="primary"
                    component="label"
                    style={{
                      color: "rgb(235, 129, 40)",
                    }}
                  >
                    <AddCircleOutline
                      fontSize="large"
                      className="rounded-circle circlebtn w-100"
                      style={{
                        backgroundColor: "#E2EEFF",
                        position: "absolute",
                        margin: mobileMatches ? "100px auto 0px 1px" : "",
                      }}
                    />
                    <img
                      src={image}
                      width="80px"
                      height="80px"
                      style={{ objectFit: "contain" }}
                      alt=""
                    />

                    {createEnable && (
                      <input
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={createEnable && onImageChange}
                      />
                    )}
                  </IconButton>
                  <Stack direction="row" spacing={2} className="ms-5 mt-4">
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                      className="mt-3"
                    >
                      <FormControlLabel
                        sx={{
                          display: "block",
                        }}
                        control={
                          <Switch
                            {...label}
                            defaultChecked
                            color="warning"
                            name="active"
                            checked={checked}
                            onChange={createEnable && handleToggle}
                            style={{
                              cursor: createEnable ? "pointer" : "not-allowed",
                            }}
                          />
                          // <Switch
                          //   name="active"
                          //   checked={checked}
                          //   onChange={createEnable && handleToggle}
                          //   style={{
                          //     color: "rgb(235, 129, 40)",
                          //     cursor: createEnable ? "pointer" : "not-allowed",
                          //   }}
                          // />
                        }
                      />
                      {checked ? (
                        <p className="mt-2" style={{ color: "rgb(235, 129, 40)" }}>
                          {checked}ACTIVE
                        </p>
                      ) : (
                        <p className="mt-2" style={{ color: "rgb(235, 129, 40)" }}>
                          {checked}INACTIVE
                        </p>
                      )}
                    </Box>
                  </Stack>
                </span>
              </>
            ) : (
              <>
                <span
                  className="appliance-image"
                  style={{ display: "inline-flex" }}
                >
                  <IconButton
                    color="primary"
                    component="label"
                    style={{
                      color: "rgb(235, 129, 40)",
                    }}
                  >
                    <AddCircleOutline
                      fontSize="large"
                      className="rounded-circle circlebtn w-100"
                      style={{
                        backgroundColor: "#E2EEFF",
                        position: "absolute",
                        margin: mobileMatches ? "100px auto 0px 1px" : "",
                      }}
                    />
                    <img src={s3Logo} width="80px" />
                    {createEnable && (
                      <input
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={createEnable && onImageChange}
                      />
                    )}
                  </IconButton>
                  <Stack direction="row" spacing={2} className="ms-5 mt-4">
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                      className="mt-3"
                    >
                      <FormControlLabel
                        sx={{
                          display: "block",
                        }}
                        control={
                          <Switch
                            {...label}
                            defaultChecked
                            color="warning"
                            name="active"
                            checked={checked}
                            onChange={createEnable && handleToggle}
                          />
                          // <Switch
                          //   name="active"
                          //   checked={checked}
                          //   onChange={createEnable && handleToggle}
                          //   style={{
                          //     color: "rgb(235, 129, 40)",
                          //   }}
                          // />
                        }
                      />
                      {checked ? (
                        <p className="mt-2" style={{ color: "rgb(235, 129, 40)" }}>
                          {checked}ACTIVE
                        </p>
                      ) : (
                        <p className="mt-2" style={{ color: "rgb(235, 129, 40)" }}>
                          {checked}INACTIVE
                        </p>
                      )}
                    </Box>
                  </Stack>
                </span>
              </>
            )}
          </div>
          <div className="ms-3 mt-4">
            <section className="mt-2">
              <div className="d-flex">
                <div className="col-lg-6">
                  <>
                    <TextField
                      label="Appliance name*"
                      value={applianceName}
                      disabled={!createEnable}
                      /* onChange={
                        applianceName?.length < 32 && handelApplianceName
                      } */
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (inputValue.length <= 32) {
                          setApplianceName(inputValue); // Update the state with the input value
                          if (inputValue.length === 0) {
                            setErrorName(true); // Set error if input is empty
                          } else {
                            setErrorName(false);
                          }
                        } else {
                          setApplianceName(inputValue.slice(0, 32)); // Truncate the input if it exceeds 32 characters
                          setErrorName(false);
                        }
                      }}
                      className="w-100"
                      error={errorName}
                    />
                    {errorName ? (
                      <small style={{ color: "red" }}>
                        Please enter a appliance name.
                      </small>
                    ) : (
                      ""
                    )}
                    <div style={{ fontSize: "12px", height: "0" }}>
                      {applianceName?.length > 0 ? applianceName?.length : 0} /
                      32
                    </div>
                  </>
                </div>
              </div>
            </section>
            <section className="mt-2">
              <div className="d-flex">
                <div className="col-lg-6 mt-4">
                  <TextField
                    label="Brand Name"
                    onChange={handleBrandvalue}
                    disabled={!createEnable}
                    value={brandValue}
                    className="w-100"
                  />
                  {createEnable && (
                    <small
                      style={{ color: "gray", fontSize: "0.7rem" }}
                      className="ms-2"
                    >
                      *If you deselect any brand,it will be removed from the
                      list.
                    </small>
                  )}
                </div>
                {brandValue && (
                  <div className="col-lg-6 mt-1">
                    <button
                      className="btn mt-4 ms-2"
                      variant="contained"
                      type="click"
                      onClick={handleAddBrandList}
                      style={{
                        height: "45px",
                        color: "white",
                        backgroundColor: "#EB8128",
                        textTransform: "none",
                      }}
                    >
                      Add
                    </button>
                  </div>
                )}
              </div>
              <div className="brand-list mt-2">
                {brandList.map(renderBrand)}
              </div>
            </section>

            <section className="mt-2">
              <div className="d-flex row">
                <div
                  className="col-lg-12"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div className="col-4 col-lg-6">
                    <TextField
                      label="Size"
                      value={sizeValue}
                      onChange={handleSizevalue}
                      disabled={!createEnable}
                      className="w-100 col-lg-4 mt-3 "
                    />
                    {createEnable && (
                      <small
                        style={{ color: "gray", fontSize: "0.7rem" }}
                        className="ms-2"
                      >
                        *If you deselect any brand,it will be removed from the
                        list.
                      </small>
                    )}
                  </div>
                  <div className="col-lg-2">
                    {sizeValue.length > 0 && (
                      <div className="col-lg-2 mt-1">
                        <Button
                          className="btn mt-3 ms-2"
                          variant="contained"
                          type="click"
                          style={{
                            height: "45px",
                            color: "white",
                            backgroundColor: "#EB8128",
                            textTransform: "none",
                          }}
                          onClick={handleAddSizeList}
                        >
                          Add
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-3 me-4">
                    {createEnable ? (
                      <>
                        <SearchableDropdown
                          options={unitOfSizeList}
                          label="fullname"
                          id="admin-list"
                          selectedVal={unitOfSize?.fullname}
                          handleChange={handleChangeUnitOfSize}
                          type="Unit of size *"
                        />
                        {errorSize && (
                          <small style={{ color: "red" }}>
                            Please select a valid unit of size.
                          </small>
                        )}
                      </>
                    ) : (
                      <>
                        <TextField
                          disabled={!createEnable}
                          label="Unit of size*"
                          className="mt-3"
                          value={unitOfSize?.fullname}
                          errorSize={errorSize}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {errorSize ? (
                          <small>Please select the unit of size</small>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="brand-list mt-2">{sizeList?.map(renderSize)}</div>
            </section>
            <section className="mt-2">
              <div className="d-flex">
                <div className="col-lg-6 mt-4">
                  <TextField
                    label="Model Name"
                    value={modelName}
                    disabled={!createEnable}
                    onChange={onChangeModel}
                    className="w-100"
                  />
                </div>
                {modelName && (
                  <div className="col-lg-6 mt-1">
                    <Tooltip
                      title={
                        addButtonEnable
                          ? "This model name already exist!"
                          : "Click here to add basic and commission charges."
                      }
                    >
                      <Button
                        className="btn mt-4 ms-2"
                        variant="contained"
                        type="submit"
                        style={{
                          height: "45px",
                          color: "white",
                          backgroundColor: "rgb(235, 129, 40)",
                          textTransform: "none",
                        }}
                        onClick={onAddModel}
                      >
                        Add
                      </Button>
                    </Tooltip>
                  </div>
                )}
              </div>
              <div className="mt-4">
                <text
                  className="mt-5"
                  style={{
                    fontWeight: "bold",
                    color: "#1E232C",
                    fontSize: "21px",
                    width: "208px",
                    height: "16px",
                    // textDecoration: "underline",
                  }}
                >
                  Charges Configuration :
                </text>
                <p className="mt-0" style={{ fontSize: "14px" }}>
                  You can configure the Basic and Commission charges for your
                  appliance
                </p>
              </div>
              {!!modelList?.length && (
                <>
                  {createEnable && (
                    <Button
                      type="button"
                      className="mt-1 me-5 mb-2"
                      style={{
                        height: "45px",
                        color: "white",
                        backgroundColor: "#EB8128",
                        textTransform: "none",
                        float: "right",
                      }}
                      variant="contained"
                      disabled={!createEnable}
                      onClick={onAddMore}
                    >
                      + Configuration
                    </Button>
                  )}

                  <div
                    className="me-4 mt-3"
                    style={{
                      width: "96%",
                      height: "40vh",
                      overflowY: "scroll",
                    }}
                  >
                    <Table
                      className="table table-striped"
                      style={{ border: "1px solid #ddd" }}
                    >
                      <thead
                        style={{
                          position: "sticky",
                          top: 0,
                          backgroundColor: "rgb(235, 129, 40)",
                          zIndex: 1,
                          background: "rgb(235, 129, 40)",
                          color: "white",
                        }}
                      >
                        <tr>{TABLE_HEADERS.map(renderTableHeader)}</tr>
                      </thead>
                      <tbody>{modelList?.map(renderTableRow)}</tbody>
                    </Table>
                  </div>
                </>
              )}
            </section>
            <section className="mt-3">
              {submitButtonEnable && (
                <>
                  <Button
                    type="button"
                    onClick={handleSubmit}
                    style={{
                      height: "45px",
                      color: "white",
                      backgroundColor: "#EB8128",
                      textTransform: "none",
                    }}
                    variant="contained"
                  >
                    Submit
                  </Button>
                  <Button
                    className="btn ms-2"
                    variant="contained"
                    type="submit"
                    style={{
                      height: "45px",
                      color: "white",
                      backgroundColor: "gray",
                      textTransform: "none",
                    }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </>
              )}

              {editButtonEnable && (
                <>
                  <Button
                    type="button"
                    onClick={handleSubmit}
                    /*  disabled={isLoading} */
                    style={{
                      height: "45px",
                      color: "white",
                      backgroundColor: "#EB8128",
                      textTransform: "none",
                    }}
                    variant="contained"
                  >
                    Update
                  </Button>
                  <Button
                    className="btn ms-2"
                    variant="contained"
                    type="submit"
                    style={{
                      height: "45px",
                      color: "white",
                      backgroundColor: "gray",
                      textTransform: "none",
                    }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </section>
          </div>
        </div>
        <ProductModel
          showModal={!!modelData}
          modelData={modelData}
          onComplete={onCompleteModel}
          onCancel={setModelData.bind(null, null)}
        />
      </div>
    </>
  );
}

export default ActiveAppliance;
