import axios from "axios";
// import { DRAW_SERVICE_BASE_URL } from "../../api/apiBaseUrl";
import { BASE_URL } from "../../api/ApiPath";
import { rewardLoginCustListRequest, rewardLoginCustListFail, rewardLoginCustListSuccess } from "../slices/rewardLoginCustomer";
export const getAllrewardLoginCustList = (startDate, endDate) => async (dispatch) => {
    const token = localStorage.getItem("token");
    // let payload = id ? `draw_id=${id}&startingNumber=0000&limit=9999&onlySoldOut=false` : '';

    try {
        dispatch(rewardLoginCustListRequest())
        const response = await axios.get(`${BASE_URL}/customer/reward?givenDate=${startDate}`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        dispatch(rewardLoginCustListSuccess(response?.data))
        console.log("rewardLoginCustListLists", response?.data);
    } catch (error) {
        console.log(error);
        dispatch(rewardLoginCustListFail(error?.response?.data))
    }
}
