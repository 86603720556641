import React, { useEffect, useState } from "react";
import { IoMdStarOutline, IoMdStarHalf, IoMdStar } from "react-icons/io";

export default function HalfRatings({
  rating,
  onChangeRating,
  fontSize,
  isEditable,
}) {
  const [receivedRating, setReceivedRating] = useState(rating);

  //   const handleStarClick = (index) => {
  //     if (isEditable) {
  //       setReceivedRating(index + 1);
  //       onChangeRating(index + 1);
  //     }
  //   };

  useEffect(() => {
    setReceivedRating(rating);
  }, [rating]);

  const stars = Array.from({ length: 5 }, (_, index) => {
    if (index < Math.floor(receivedRating)) {
      // Full star
      return (
        <IoMdStar
          key={index}
          className="filled d-flex align-items-center"
          style={{
            color: "rgb(235, 129, 40)",
            cursor: isEditable ? "pointer" : "default",
          }}
          //   onClick={() => handleStarClick(index)}
        />
      );
    } else if (
      index === Math.floor(receivedRating) &&
      receivedRating % 1 !== 0
    ) {
      // Half star
      return (
        <IoMdStarHalf
          key={index}
          className="filled d-flex align-items-center"
          style={{
            color: "rgb(235, 129, 40)",
            cursor: isEditable ? "pointer" : "default",
          }}
          //   onClick={() => handleStarClick(index)}
        />
      );
    } else {
      // Empty star
      return (
        <IoMdStarOutline
          key={index}
          style={{ color: "gray", cursor: isEditable ? "pointer" : "default" }}
          //   onClick={() => handleStarClick(index)}
        />
      );
    }
  });

  return (
    <div
      style={{ fontSize: fontSize }}
      className="d-flex align-items-center gap-1"
    >
      {stars}
    </div>
  );
}
