import React from "react";
import { useEffect, useState } from "react";
import { Box, InputBase, Stack, Tab, Tabs, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";
import { Paper } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DoneIcon from "@mui/icons-material/Done";
import "../../layout.css";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ProgressBar from "../../components/Customers/progress";
import Topbar from "../topbar";
import Sidebar from "../Sidebar";
import Api from "../../service/services";
import FilterModule from "../../plugins/FilterModule";

const AdminSRlist = () => {
  const [hovered, setHovered] = useState(null);
  const [IsService, setIsService] = useState(false);
  const [IsVisit, setIsVisit] = useState(false);
  const [IsSLA, setIsSLA] = useState(false);
  const [IsClosureDetails, setIsClosureDetails] = useState(false);
  const [IsPaymentDetails, setIsPaymentDetails] = useState(false);
  const [IsServiceRequestCreation, setIsServiceRequestCreation] = useState(
    false
  );
  const [IsTechnicianvisit, setIsTechnicianvisit] = useState(false);
  const [IsRepairEstimatedSLA, setIsRepairEstimatedSLA] = useState(false);
  const [SRList, setSRList] = useState([]);
  const [SrDetailArray, setSRDetailArray] = useState([]);
  const [openSRList, setOpenSRList] = useState([]);
  const [InprogressSRList, setInprogressSRList] = useState([]);
  const [completeSRList, setCompleteSRList] = useState([]);
  const [progressPercentage, setProgressPercentage] = useState("");
  const [serviceRequestId, setServiceRequestId] = useState("");
  const [serviceCreatedTime, setServiceCreatedTime] = useState("");

  const [serviceStatus, setServiceStatus] = useState("");
  const [IsOpen, setIsOpen] = useState(true);
  const [IsOpenEdit, setIsOpenEdit] = useState(false);

  const searchFilter = (event) => {
    const searchTerm = event.target.value;
    if (searchTerm === "") {
      Api.getServiceRequestList().then((res) => {
        setSRList(res.data);
        setOpenSRList(res.data);
        setInprogressSRList(res.data);
        setCompleteSRList(res.data);
      });
    } else {
      const filteredTests = SRList.filter((service) => {
        const SR_number = String(service.service_req_id);
        return SR_number.includes(searchTerm);
      });
      setOpenSRList(filteredTests);
      setInprogressSRList(filteredTests);
      setCompleteSRList(filteredTests);
    }
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMouseEnter = (index) => {
    // setHovered(index);
  };
  const handleMouseLeave = () => {
    // setHovered(null);
  };
  const handleSRClick = (req_id, index) => {
    setHovered(index);
    setIsOpenEdit(true);
    const sr = SRList.filter((item) => item.service_req_id === req_id);
    setSRDetailArray(SRList.filter((item) => item.service_req_id === req_id));
    setServiceRequestId(req_id);
    // setServiceCreatedTime(sr[0].service_created_time);
  };

  return (
    <>
      <div className="container-fluid">
        <Topbar />
        <div className="row">
          <div className="col-lg-2 col-12 p-0 border border-right">
            <Sidebar />
          </div>
          <div className="col-lg-10 col-12 Main_menu">
            <div className="row">
              <div className="col-lg-4 col-12 p-0 ">
                <div className="custom-scrollbar">
                  <div
                    className="m-0 sticky-top bg-white"
                    style={{ position: "sticky", zIndex: "2" }}
                  >
                    <i
                      className="bi bi-chevron-left me-2"
                      style={{
                        fontWeight: "600",
                        fontSize: "21px",
                        width: "20px",
                        height: "20px",
                      }}
                    ></i>
                    <text
                      className="p-0"
                      style={{
                        fontWeight: "600",
                        color: "#1E232C",
                        fontSize: "21px",
                        width: "208px",
                        height: "32px",
                      }}
                    >
                      Service Request List
                    </text>

                    <FilterModule />

                    <Tabs
                      className="w-100 bg-white scroll-tabs"
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          color: "#6A707C",
                          right: "16px",
                        }}
                        label="Open"
                        {...a11yProps(0)}
                        onClick={() => setIsOpen(true)}
                      />
                      <Tab
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "#6A707C",
                        }}
                        label="In Progress"
                        {...a11yProps(1)}
                        onClick={() => setIsOpen(false)}
                      />
                      <Tab
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "#6A707C",
                        }}
                        label="Completed"
                        {...a11yProps(2)}
                        onClick={() => setIsOpen(false)}
                      />
                    </Tabs>
                  </div>
                  {/* Open */}
                  <div>
                    <CustomTabPanel value={value} index={0}>
                      {openSRList.length > 0 ? (
                        openSRList.map((open, index) => (
                          <div
                            key={index}
                            className="border rounded-3 p-2 mt-3"
                            style={{
                              height: "75px",
                              cursor: "pointer",
                              background:
                                hovered === index
                                  ? "linear-gradient(to right,#FFE1CA , #FFF6EF00)"
                                  : undefined,
                              color:
                                hovered === index
                                  ? "rgb(235, 129, 40)"
                                  : "black",
                            }}
                            onClick={() =>
                              handleSRClick(open.service_req_id, index)
                            }
                          >
                            <text
                              style={{
                                fontWeight: "600",
                                fontSize: "16px",
                                color: "#1E232C",
                              }}
                            >
                              SR #:{open.service_req_id}
                            </text>
                            <br />
                            <div className="row">
                              <div className="col-6 text-start">
                                <text
                                  style={{
                                    color: "#1E232C",
                                    fontWeight: "400",
                                    fontSize: "15px",
                                  }}
                                >
                                  {open.appliance_name}
                                </text>
                              </div>
                              <div className="col-6 text-end">
                                <i className="ms-lg-4 ms-2 bi bi-arrow-right fs-6"></i>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>No Data </p>
                      )}
                    </CustomTabPanel>
                    {/* Inprogress */}
                    <CustomTabPanel value={value} index={1}>
                      <div className="mt-lg-3 mt-2">
                        <div className="row-lg" style={{ display: "flex" }}>
                          <div className="col-lg">
                            {InprogressSRList.length > 0 ? (
                              InprogressSRList.map((progress, index) => (
                                <Paper
                                  elevation={3}
                                  className="w-100 p-lg-4 p-2 mt-3"
                                  style={{
                                    height: "203px",
                                    background:
                                      hovered === index
                                        ? "linear-gradient(to right,#FFE1CA , #FFF6EF00)"
                                        : undefined,
                                    color:
                                      hovered === index
                                        ? "rgb(235, 129, 40)"
                                        : "black",
                                    cursor: "pointer",
                                  }}
                                  onMouseLeave={handleMouseLeave}
                                  // onClick={handleSRClick}
                                  onClick={() =>
                                    handleSRClick(
                                      progress.service_req_id,
                                      index
                                    )
                                  }
                                  onMouseEnter={() => handleMouseEnter(index)}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div>
                                      <p className="margin-end h6 mb-3">
                                        <b>SR #:{progress.service_req_id}</b>
                                      </p>
                                      <span
                                        style={{
                                          fontWeight: "400",
                                          fontSize: "15px",
                                        }}
                                      >
                                        {progress.appliance_name} <br />
                                        {progress.model_no}
                                      </span>
                                    </div>
                                    <div className="ms-lg-4 mt-lg-2">
                                      <ProgressBar
                                        percentage={progressPercentage}
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    <p
                                      className=""
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {progress.fullname}
                                    </p>
                                    <p
                                      className=""
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {progress.tech_visit}
                                    </p>
                                  </div>
                                </Paper>
                              ))
                            ) : (
                              <p>No data </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </CustomTabPanel>

                    {/* Completed */}
                    <CustomTabPanel value={value} index={2}>
                      <div
                        className="border rounded-3 p-2"
                        style={{
                          height: "75px",
                          background: hovered
                            ? "linear-gradient(to right,#FFE1CA , #FFF6EF00)"
                            : undefined,
                          color: hovered ? "rgb(235, 129, 40)" : "black",
                        }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        {/* onClick={() => handleSRClick(progress.service_req_id, index)} */}
                        <text
                          style={{
                            fontWeight: "600",
                            fontSize: "16px",
                            color: "#1E232C",
                          }}
                        >
                          #SR_63534
                        </text>
                        <ul
                          className="p-0 m-0"
                          style={{ display: "inline-flex" }}
                        >
                          <li className="m" style={{ listStyle: "none" }}>
                            <text
                              style={{
                                color: "#1E232C",
                                fontWeight: "400",
                                fontSize: "15px",
                              }}
                            >
                              Sony TV - AUE60 Crystal 4K UH...
                            </text>
                          </li>
                          <li className="m-0" style={{ listStyle: "none" }}>
                            <i className="ms-lg-4 ms-2 bi bi-arrow-right fs-6"></i>
                          </li>
                        </ul>
                      </div>

                      <Paper
                        elevation={3}
                        className="w-100 p-lg-4 p-2 mt-3"
                        style={{
                          height: "203px",
                          background: hovered
                            ? "linear-gradient(to right,#FFE1CA , #FFF6EF00)"
                            : undefined,
                          color: hovered ? "rgb(235, 129, 40)" : "black",
                          cursor: "pointer",
                        }}
                        onMouseLeave={handleMouseLeave}
                        // onClick={handleSRClick}
                        // onClick={() => handleSRClick(progress.service_req_id, index)}
                        // onMouseEnter={() => handleMouseEnter(index)}
                      >
                        <div style={{ display: "flex" }}>
                          <div>
                            <p className="margin-end h6 mb-3">
                              <b>SR #:345</b>
                            </p>
                            <span
                              style={{ fontWeight: "400", fontSize: "15px" }}
                            >
                              Television
                              <br />
                              1234
                            </span>
                          </div>
                          <div className="ms-lg-4 mt-lg-2">
                            <ProgressBar percentage={progressPercentage} />
                          </div>
                        </div>
                        <div>
                          <p
                            className=""
                            style={{ fontWeight: "500", fontSize: "13px" }}
                          >
                            test
                          </p>
                          <p
                            className=""
                            style={{ fontWeight: "500", fontSize: "13px" }}
                          >
                            test
                          </p>
                        </div>
                      </Paper>
                    </CustomTabPanel>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-12 ">
                <div className="p-lg-2">
                  <div className="row mt-3">
                    <div className="col-lg col-12 text-start">
                      {serviceRequestId ? (
                        <div>
                          <p
                            className="m-0"
                            style={{
                              fontSize: "21px",
                              fontWeight: "500",
                              color: "#1E232C",
                            }}
                          >
                            #SR_{serviceRequestId}
                          </p>
                          <p
                            className="m-0"
                            style={{
                              fontSize: "16px",
                              fontWeight: "400",
                              color: "#1E232C",
                            }}
                          >
                            Created On:<b>{serviceCreatedTime}</b>
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-lg col-12 text-end">
                      <ul className="p-0" style={{ display: "inline-flex" }}>
                        <li style={{ listStyle: "none" }}>
                          {IsOpen && IsOpenEdit ? (
                            <Button
                              variant="outlined"
                              className="me-2"
                              style={{
                                color: "rgb(235, 129, 40)",
                                width: "120px",
                                height: "45px",
                                border: "1px solid rgb(235, 129, 40)",
                                textTransform: "none",
                              }}
                            >
                              Edit
                            </Button>
                          ) : null}
                        </li>
                        <li style={{ listStyle: "none" }}>
                          {IsOpen && IsOpenEdit ? (
                            <Button
                              variant="outlined"
                              style={{
                                color: "rgb(235, 129, 40)",
                                width: "120px",
                                height: "45px",
                                border: "1px solid rgb(235, 129, 40)",
                                textTransform: "none",
                              }}
                            >
                              Cancel
                            </Button>
                          ) : null}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <Stack direction="row" spacing={31}>
                    <p style={{ fontSize: "25px", fontWeight: "550" }}>
                      #SR_63534 - 1
                    </p>
                    <Stack direction="row" spacing={0}>
                      <p style={{ fontWeight: "550", color: "#6A707C" }}>
                        Created On:
                      </p>
                      <p style={{ fontWeight: "550" }}> May 06 2023</p>
                    </Stack>
                  </Stack>
                  {/* service request creation */}
                  <ul className="p-0 mt-3" style={{ display: "inline-flex" }}>
                    <li className="mt-2" style={{ listStyle: "none" }}>
                      <div
                        className="w-20 rounded-circle"
                        style={{
                          backgroundColor: IsService ? "#FCB61A" : "#36A93F",
                          height: "20px",
                          width: "20px",
                        }}
                      ></div>
                    </li>
                    <li className="ms-2" style={{ listStyle: "none" }}>
                      <Accordion elevation={1}>
                        <AccordionSummary
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          onClick={() => {
                            setIsService(!IsService);
                          }}
                        >
                          <Typography>
                            Service Request Creation
                            {IsService ? (
                              <ExpandMoreIcon />
                            ) : (
                              <KeyboardArrowUpIcon />
                            )}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          className="accordian_details"
                          style={{ width: "600px" }}
                        >
                          <div className="row p-2">
                            <div className="col-lg-1 text-end p-0">
                              <DoneIcon style={{ color: "#36A93F" }} />
                            </div>
                            <div className="col-lg p-0 text-start">
                              <p
                                className="m-0 p-0 ms-2"
                                style={{ fontSize: "15px", color: "#36A93F" }}
                              >
                                SR Details
                              </p>
                            </div>
                            <div className="col p-0 text-end">
                              <p
                                className="m-0 p-0 ms-2"
                                style={{ fontSize: "13px", color: "#6B6D70" }}
                              >
                                15 mins ago
                              </p>
                            </div>
                          </div>

                          <div className="row p-2">
                            <div className="col-lg-1 text-center p-0 with-vertical-line"></div>
                            <div className="col-lg p-0 text-start">
                              <table className="w-100 border-0">
                                {SrDetailArray.length > 0 ? (
                                  SrDetailArray.map((srDetail, index) => (
                                    <tbody>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Device
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.appliance_name
                                              ? srDetail.appliance_name
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Brand
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.brand
                                              ? srDetail.brand
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Model
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.model_no
                                              ? srDetail.model_no
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Size
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.variants
                                              ? srDetail.variants
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Warranty Status
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.warranty_status
                                              ? srDetail.warranty_status
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Warranty Untill
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.warranty_limit
                                              ? srDetail.warranty_limit
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  ))
                                ) : (
                                  <p>No data</p>
                                )}
                              </table>
                            </div>
                          </div>

                          <div className="row p-2">
                            <div className="col-lg-1 text-end p-0">
                              <DoneIcon style={{ color: "#36A93F" }} />
                            </div>
                            <div className="col-lg p-0 text-start">
                              <p
                                className="m-0 p-0 ms-2"
                                style={{ fontSize: "15px", color: "#36A93F" }}
                              >
                                Complaint Details
                              </p>
                            </div>
                            <div className="col p-0 text-end">
                              <p
                                className="m-0 p-0 ms-2"
                                style={{ fontSize: "13px", color: "#6B6D70" }}
                              >
                                15 mins ago
                              </p>
                            </div>
                          </div>

                          <div className="row p-2">
                            <div className="col-lg-1 text-center p-0 with-vertical-line"></div>
                            <div className="col-lg p-0 text-start">
                              <table className="w-100 border-0">
                                {SrDetailArray.length > 0 ? (
                                  SrDetailArray.map((srDetail, index) => (
                                    <tbody>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Type of Damage
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.damage
                                              ? srDetail.damage
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Description
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.damage_description
                                              ? srDetail.damage_description
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Attachments
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            2 items attached
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  ))
                                ) : (
                                  <p>No data</p>
                                )}
                              </table>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </li>
                  </ul>

                  {/* technician visit */}
                  <ul className="p-0 mt-3" style={{ display: "inline-flex" }}>
                    <li className="mt-2" style={{ listStyle: "none" }}>
                      <div
                        className="w-20 rounded-circle"
                        style={{
                          backgroundColor: IsVisit ? "#FCB61A" : "#36A93F",
                          height: "20px",
                          width: "20px",
                        }}
                      ></div>
                    </li>
                    <li className="ms-2" style={{ listStyle: "none" }}>
                      <Accordion elevation={1}>
                        <AccordionSummary
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          onClick={() => setIsVisit(!IsVisit)}
                        >
                          <Typography>
                            Technician visit{" "}
                            {IsVisit ? (
                              <ExpandMoreIcon />
                            ) : (
                              <KeyboardArrowUpIcon />
                            )}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          className="accordian_details"
                          style={{ width: "600px" }}
                        >
                          <div className="row p-2">
                            <div className="col-lg-1 text-end p-0">
                              <DoneIcon style={{ color: "#36A93F" }} />
                            </div>
                            <div className="col-lg p-0 text-start">
                              <p
                                className="m-0 p-0 ms-2"
                                style={{ fontSize: "15px", color: "#36A93F" }}
                              >
                                Visit Slot
                              </p>
                            </div>
                            <div className="col p-0 text-end">
                              {/* <p className="m-0 p-0 ms-2" style={{ fontSize: '13px', color: '#6B6D70' }}>15 mins ago</p> */}
                            </div>
                          </div>
                          <div className="row p-2 m-0">
                            <div
                              className="col-lg-1 text-center p-0 with-vertical-line"
                              style={{ transform: "translatex(-20%)" }}
                            ></div>
                            <div className="col-lg p-0 text-start">
                              <table className="w-100 border-0">
                                <tbody>
                                  <tr style={{ height: "40px" }}>
                                    <td className="text-start">
                                      <p
                                        className="m-0"
                                        style={{
                                          color: "black",
                                          fontSize: "16px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        Confirmed Slot
                                      </p>
                                    </td>
                                    <td className="text-end">
                                      <p
                                        className="m-0"
                                        style={{
                                          color: "black",
                                          fontSize: "16px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        11am-12pm,10 May 2023
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>

                          {/* Accepted tech */}
                          {serviceStatus === "Accepted" ? (
                            <div>
                              <div className="row p-2">
                                <div className="col-lg-1 text-end p-0">
                                  <DoneIcon style={{ color: "#36A93F" }} />
                                </div>
                                <div className="col-lg p-0 text-start">
                                  <p
                                    className="m-0 p-0 ms-2"
                                    style={{
                                      fontSize: "15px",
                                      color: "#36A93F",
                                    }}
                                  >
                                    Technician Accepted
                                  </p>
                                </div>
                                <div className="col p-0 text-end">
                                  <p
                                    className="m-0 p-0 ms-2"
                                    style={{
                                      fontSize: "13px",
                                      color: "#6B6D70",
                                    }}
                                  >
                                    11 mins ago
                                  </p>
                                </div>
                              </div>
                              <div className="row p-2 m-0">
                                <div className="col-lg-1 text-center p-0 with-vertical-line"></div>
                                <div className="col-lg p-0 text-start">
                                  <table className="w-100 border-0">
                                    <tbody>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Technician accepted your request
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            <a href="#">View Details</a>
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="row p-2">
                              <div className="col-lg-1 text-end p-0">
                                <DoneIcon style={{ color: "#36A93F" }} />
                              </div>
                              <div className="col-lg p-0 text-start">
                                <p
                                  className="m-0 p-0 ms-2"
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(235, 129, 40)",
                                  }}
                                >
                                  Technician Confirmation Pending
                                </p>
                                <p
                                  className="m-0 p-0 ms-2 mt-2"
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                  }}
                                >
                                  Please wait! your request has been set and
                                  technician yet to confirm it
                                </p>
                              </div>
                            </div>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </li>
                  </ul>

                  {/* Repair Estimated SLA */}
                  <ul className="p-0 mt-3" style={{ display: "inline-flex" }}>
                    <li className="mt-2" style={{ listStyle: "none" }}>
                      <div
                        className="w-20 rounded-circle"
                        style={{
                          backgroundColor: IsSLA ? "#FCB61A" : "#36A93F",
                          height: "20px",
                          width: "20px",
                        }}
                      ></div>
                    </li>
                    <li className="ms-2" style={{ listStyle: "none" }}>
                      <Accordion elevation={1}>
                        <AccordionSummary
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          onClick={() => {
                            setIsSLA(!IsSLA);
                          }}
                        >
                          <Typography>
                            Repair Estimated SLA
                            {IsService ? (
                              <ExpandMoreIcon />
                            ) : (
                              <KeyboardArrowUpIcon />
                            )}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          className="accordian_details"
                          style={{ width: "600px" }}
                        >
                          <div className="row p-2">
                            <div className="col-lg-1 text-end p-0">
                              <DoneIcon style={{ color: "#36A93F" }} />
                            </div>
                            <div className="col-lg p-0 text-start">
                              <p
                                className="m-0 p-0 ms-2"
                                style={{ fontSize: "15px", color: "#36A93F" }}
                              >
                                SR Details
                              </p>
                            </div>
                            <div className="col p-0 text-end">
                              <p
                                className="m-0 p-0 ms-2"
                                style={{ fontSize: "13px", color: "#6B6D70" }}
                              >
                                15 mins ago
                              </p>
                            </div>
                          </div>

                          <div className="row p-2">
                            <div className="col-lg-1 text-center p-0 with-vertical-line"></div>
                            <div className="col-lg p-0 text-start">
                              <table className="w-100 border-0">
                                {SrDetailArray.length > 0 ? (
                                  SrDetailArray.map((srDetail, index) => (
                                    <tbody>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Device
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.appliance_name
                                              ? srDetail.appliance_name
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Brand
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.brand
                                              ? srDetail.brand
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Model
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.model_no
                                              ? srDetail.model_no
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Size
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.variants
                                              ? srDetail.variants
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Warranty Status
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.warranty_status
                                              ? srDetail.warranty_status
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Warranty Untill
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.warranty_limit
                                              ? srDetail.warranty_limit
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  ))
                                ) : (
                                  <p>No data</p>
                                )}
                              </table>
                            </div>
                          </div>

                          <div className="row p-2">
                            <div className="col-lg-1 text-end p-0">
                              <DoneIcon style={{ color: "#36A93F" }} />
                            </div>
                            <div className="col-lg p-0 text-start">
                              <p
                                className="m-0 p-0 ms-2"
                                style={{ fontSize: "15px", color: "#36A93F" }}
                              >
                                Complaint Details
                              </p>
                            </div>
                            <div className="col p-0 text-end">
                              <p
                                className="m-0 p-0 ms-2"
                                style={{ fontSize: "13px", color: "#6B6D70" }}
                              >
                                15 mins ago
                              </p>
                            </div>
                          </div>

                          <div className="row p-2">
                            <div className="col-lg-1 text-center p-0 with-vertical-line"></div>
                            <div className="col-lg p-0 text-start">
                              <table className="w-100 border-0">
                                {SrDetailArray.length > 0 ? (
                                  SrDetailArray.map((srDetail, index) => (
                                    <tbody>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Type of Damage
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.damage
                                              ? srDetail.damage
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Description
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.damage_description
                                              ? srDetail.damage_description
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Attachments
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            2 items attached
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  ))
                                ) : (
                                  <p>No data</p>
                                )}
                              </table>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </li>
                  </ul>

                  {/* Closure Details */}
                  <ul className="p-0 mt-3" style={{ display: "inline-flex" }}>
                    <li className="mt-2" style={{ listStyle: "none" }}>
                      <div
                        className="w-20 rounded-circle"
                        style={{
                          backgroundColor: IsClosureDetails
                            ? "#FCB61A"
                            : "#36A93F",
                          height: "20px",
                          width: "20px",
                        }}
                      ></div>
                    </li>
                    <li className="ms-2" style={{ listStyle: "none" }}>
                      <Accordion elevation={1}>
                        <AccordionSummary
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          onClick={() => {
                            setIsClosureDetails(!IsClosureDetails);
                          }}
                        >
                          <Typography>
                            Closure Details
                            {IsClosureDetails ? (
                              <ExpandMoreIcon />
                            ) : (
                              <KeyboardArrowUpIcon />
                            )}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          className="accordian_details"
                          style={{ width: "600px" }}
                        >
                          <div className="row p-2">
                            <div className="col-lg-1 text-end p-0">
                              <DoneIcon style={{ color: "#36A93F" }} />
                            </div>
                            <div className="col-lg p-0 text-start">
                              <p
                                className="m-0 p-0 ms-2"
                                style={{ fontSize: "15px", color: "#36A93F" }}
                              >
                                SR Details
                              </p>
                            </div>
                            <div className="col p-0 text-end">
                              <p
                                className="m-0 p-0 ms-2"
                                style={{ fontSize: "13px", color: "#6B6D70" }}
                              >
                                15 mins ago
                              </p>
                            </div>
                          </div>

                          <div className="row p-2">
                            <div className="col-lg-1 text-center p-0 with-vertical-line"></div>
                            <div className="col-lg p-0 text-start">
                              <table className="w-100 border-0">
                                {SrDetailArray.length > 0 ? (
                                  SrDetailArray.map((srDetail, index) => (
                                    <tbody>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Device
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.appliance_name
                                              ? srDetail.appliance_name
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Brand
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.brand
                                              ? srDetail.brand
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Model
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.model_no
                                              ? srDetail.model_no
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Size
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.variants
                                              ? srDetail.variants
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Warranty Status
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.warranty_status
                                              ? srDetail.warranty_status
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Warranty Untill
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.warranty_limit
                                              ? srDetail.warranty_limit
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  ))
                                ) : (
                                  <p>No data</p>
                                )}
                              </table>
                            </div>
                          </div>

                          <div className="row p-2">
                            <div className="col-lg-1 text-end p-0">
                              <DoneIcon style={{ color: "#36A93F" }} />
                            </div>
                            <div className="col-lg p-0 text-start">
                              <p
                                className="m-0 p-0 ms-2"
                                style={{ fontSize: "15px", color: "#36A93F" }}
                              >
                                Complaint Details
                              </p>
                            </div>
                            <div className="col p-0 text-end">
                              <p
                                className="m-0 p-0 ms-2"
                                style={{ fontSize: "13px", color: "#6B6D70" }}
                              >
                                15 mins ago
                              </p>
                            </div>
                          </div>

                          <div className="row p-2">
                            <div className="col-lg-1 text-center p-0 with-vertical-line"></div>
                            <div className="col-lg p-0 text-start">
                              <table className="w-100 border-0">
                                {SrDetailArray.length > 0 ? (
                                  SrDetailArray.map((srDetail, index) => (
                                    <tbody>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Type of Damage
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.damage
                                              ? srDetail.damage
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Description
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.damage_description
                                              ? srDetail.damage_description
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Attachments
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            2 items attached
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  ))
                                ) : (
                                  <p>No data</p>
                                )}
                              </table>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </li>
                  </ul>

                  {/* Payment Details */}
                  <ul className="p-0 mt-3" style={{ display: "inline-flex" }}>
                    <li className="mt-2" style={{ listStyle: "none" }}>
                      <div
                        className="w-20 rounded-circle"
                        style={{
                          backgroundColor: IsPaymentDetails
                            ? "#FCB61A"
                            : "#36A93F",
                          height: "20px",
                          width: "20px",
                        }}
                      ></div>
                    </li>
                    <li className="ms-2" style={{ listStyle: "none" }}>
                      <Accordion elevation={1}>
                        <AccordionSummary
                          // expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          onClick={() => {
                            setIsPaymentDetails(!IsPaymentDetails);
                          }}
                        >
                          <Typography>
                            Payment Details
                            {IsPaymentDetails ? (
                              <ExpandMoreIcon />
                            ) : (
                              <KeyboardArrowUpIcon />
                            )}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          className="accordian_details"
                          style={{ width: "600px" }}
                        >
                          <div className="row p-2">
                            <div className="col-lg-1 text-end p-0">
                              <DoneIcon style={{ color: "#36A93F" }} />
                            </div>
                            <div className="col-lg p-0 text-start">
                              <p
                                className="m-0 p-0 ms-2"
                                style={{ fontSize: "15px", color: "#36A93F" }}
                              >
                                SR Details
                              </p>
                            </div>
                            <div className="col p-0 text-end">
                              <p
                                className="m-0 p-0 ms-2"
                                style={{ fontSize: "13px", color: "#6B6D70" }}
                              >
                                15 mins ago
                              </p>
                            </div>
                          </div>

                          <div className="row p-2">
                            <div className="col-lg-1 text-center p-0 with-vertical-line"></div>
                            <div className="col-lg p-0 text-start">
                              <table className="w-100 border-0">
                                {SrDetailArray.length > 0 ? (
                                  SrDetailArray.map((srDetail, index) => (
                                    <tbody>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Device
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.appliance_name
                                              ? srDetail.appliance_name
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Brand
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.brand
                                              ? srDetail.brand
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Model
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.model_no
                                              ? srDetail.model_no
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Size
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.variants
                                              ? srDetail.variants
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Warranty Status
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.warranty_status
                                              ? srDetail.warranty_status
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Warranty Untill
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.warranty_limit
                                              ? srDetail.warranty_limit
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  ))
                                ) : (
                                  <p>No data</p>
                                )}
                              </table>
                            </div>
                          </div>

                          <div className="row p-2">
                            <div className="col-lg-1 text-end p-0">
                              <DoneIcon style={{ color: "#36A93F" }} />
                            </div>
                            <div className="col-lg p-0 text-start">
                              <p
                                className="m-0 p-0 ms-2"
                                style={{ fontSize: "15px", color: "#36A93F" }}
                              >
                                Complaint Details
                              </p>
                            </div>
                            <div className="col p-0 text-end">
                              <p
                                className="m-0 p-0 ms-2"
                                style={{ fontSize: "13px", color: "#6B6D70" }}
                              >
                                15 mins ago
                              </p>
                            </div>
                          </div>

                          <div className="row p-2">
                            <div className="col-lg-1 text-center p-0 with-vertical-line"></div>
                            <div className="col-lg p-0 text-start">
                              <table className="w-100 border-0">
                                {SrDetailArray.length > 0 ? (
                                  SrDetailArray.map((srDetail, index) => (
                                    <tbody>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Type of Damage
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.damage
                                              ? srDetail.damage
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Description
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.damage_description
                                              ? srDetail.damage_description
                                              : ""}
                                          </p>
                                        </td>
                                      </tr>
                                      <tr style={{ height: "40px" }}>
                                        <td className="text-start">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Attachments
                                          </p>
                                        </td>
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            2 items attached
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  ))
                                ) : (
                                  <p>No data</p>
                                )}
                              </table>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </li>
                  </ul>
                </div>
                <div className="row mt-3">
                  <div className="col text-start">
                    {serviceRequestId ? (
                      <div>
                        <p
                          className="m-0"
                          style={{
                            fontSize: "21px",
                            fontWeight: "500",
                            color: "#1E232C",
                          }}
                        >
                          #SR_{serviceRequestId}
                        </p>
                        <p
                          className="m-0"
                          style={{
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "#1E232C",
                          }}
                        >
                          Created On:<b>{serviceCreatedTime}</b>
                        </p>
                      </div>
                    ) : null}
                  </div>
                  <div className="col text-end">
                    <ul className="p-0" style={{ display: "inline-flex" }}>
                      <li style={{ listStyle: "none" }}>
                        {IsOpen && IsOpenEdit ? (
                          <Button
                            variant="outlined"
                            className="me-2"
                            style={{
                              color: "rgb(235, 129, 40)",
                              width: "120px",
                              height: "45px",
                              border: "1px solid rgb(235, 129, 40)",
                              textTransform: "none",
                            }}
                          >
                            Edit
                          </Button>
                        ) : null}
                      </li>
                      <li style={{ listStyle: "none" }}>
                        {IsOpen && IsOpenEdit ? (
                          <Button
                            variant="outlined"
                            style={{
                              color: "rgb(235, 129, 40)",
                              width: "120px",
                              height: "45px",
                              border: "1px solid rgb(235, 129, 40)",
                              textTransform: "none",
                            }}
                          >
                            Cancel
                          </Button>
                        ) : null}
                      </li>
                    </ul>
                  </div>
                </div>
                <Stack direction="row" spacing={35}>
                  <p style={{ fontSize: "25px", fontWeight: "550" }}>
                    #SR_63534
                  </p>
                  <Stack direction="row" spacing={0}>
                    <p style={{ fontWeight: "550", color: "#6A707C" }}>
                      Created On:
                    </p>
                    <p style={{ fontWeight: "550" }}> May 03 2023</p>
                  </Stack>
                </Stack>
                {/* service request creation */}
                <ul className="p-0 mt-3" style={{ display: "inline-flex" }}>
                  <li className="mt-2" style={{ listStyle: "none" }}>
                    <div
                      className="w-20 rounded-circle"
                      style={{
                        backgroundColor: IsServiceRequestCreation
                          ? "#FCB61A"
                          : "#36A93F",
                        height: "20px",
                        width: "20px",
                      }}
                    ></div>
                  </li>
                  <li className="ms-2" style={{ listStyle: "none" }}>
                    <Accordion elevation={1}>
                      <AccordionSummary
                        // expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        onClick={() => {
                          setIsServiceRequestCreation(
                            !IsServiceRequestCreation
                          );
                        }}
                      >
                        <Typography>
                          Service Request Creation
                          {IsService ? (
                            <ExpandMoreIcon />
                          ) : (
                            <KeyboardArrowUpIcon />
                          )}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        className="accordian_details"
                        style={{ width: "600px" }}
                      >
                        <div className="row p-2">
                          <div className="col-lg-1 text-end p-0">
                            <DoneIcon style={{ color: "#36A93F" }} />
                          </div>
                          <div className="col-lg p-0 text-start">
                            <p
                              className="m-0 p-0 ms-2"
                              style={{ fontSize: "15px", color: "#36A93F" }}
                            >
                              SR Details
                            </p>
                          </div>
                          <div className="col p-0 text-end">
                            <p
                              className="m-0 p-0 ms-2"
                              style={{ fontSize: "13px", color: "#6B6D70" }}
                            >
                              15 mins ago
                            </p>
                          </div>
                        </div>

                        <div className="row p-2">
                          <div className="col-lg-1 text-center p-0 with-vertical-line"></div>
                          <div className="col-lg p-0 text-start">
                            <table className="w-100 border-0">
                              {SrDetailArray.length > 0 ? (
                                SrDetailArray.map((srDetail, index) => (
                                  <tbody>
                                    <tr style={{ height: "40px" }}>
                                      <td className="text-start">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Device
                                        </p>
                                      </td>
                                      <td className="text-end">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {srDetail.appliance_name
                                            ? srDetail.appliance_name
                                            : ""}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr style={{ height: "40px" }}>
                                      <td className="text-start">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Brand
                                        </p>
                                      </td>
                                      <td className="text-end">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {srDetail.brand ? srDetail.brand : ""}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr style={{ height: "40px" }}>
                                      <td className="text-start">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Model
                                        </p>
                                      </td>
                                      <td className="text-end">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {srDetail.model_no
                                            ? srDetail.model_no
                                            : ""}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr style={{ height: "40px" }}>
                                      <td className="text-start">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Size
                                        </p>
                                      </td>
                                      <td className="text-end">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {srDetail.variants
                                            ? srDetail.variants
                                            : ""}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr style={{ height: "40px" }}>
                                      <td className="text-start">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Warranty Status
                                        </p>
                                      </td>
                                      <td className="text-end">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {srDetail.warranty_status
                                            ? srDetail.warranty_status
                                            : ""}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr style={{ height: "40px" }}>
                                      <td className="text-start">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Warranty Untill
                                        </p>
                                      </td>
                                      <td className="text-end">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {srDetail.warranty_limit
                                            ? srDetail.warranty_limit
                                            : ""}
                                        </p>
                                      </td>
                                    </tr>
                                  </tbody>
                                ))
                              ) : (
                                <p>No data</p>
                              )}
                            </table>
                          </div>
                        </div>

                        <div className="row p-2">
                          <div className="col-lg-1 text-end p-0">
                            <DoneIcon style={{ color: "#36A93F" }} />
                          </div>
                          <div className="col-lg p-0 text-start">
                            <p
                              className="m-0 p-0 ms-2"
                              style={{ fontSize: "15px", color: "#36A93F" }}
                            >
                              Complaint Details
                            </p>
                          </div>
                          <div className="col p-0 text-end">
                            <p
                              className="m-0 p-0 ms-2"
                              style={{ fontSize: "13px", color: "#6B6D70" }}
                            >
                              15 mins ago
                            </p>
                          </div>
                        </div>

                        <div className="row p-2">
                          <div className="col-lg-1 text-center p-0 with-vertical-line"></div>
                          <div className="col-lg p-0 text-start">
                            <table className="w-100 border-0">
                              {SrDetailArray.length > 0 ? (
                                SrDetailArray.map((srDetail, index) => (
                                  <tbody>
                                    <tr style={{ height: "40px" }}>
                                      <td className="text-start">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Type of Damage
                                        </p>
                                      </td>
                                      <td className="text-end">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {srDetail.damage
                                            ? srDetail.damage
                                            : ""}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr style={{ height: "40px" }}>
                                      <td className="text-start">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Description
                                        </p>
                                      </td>
                                      <td className="text-end">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {srDetail.damage_description
                                            ? srDetail.damage_description
                                            : ""}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr style={{ height: "40px" }}>
                                      <td className="text-start">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Attachments
                                        </p>
                                      </td>
                                      <td className="text-end">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          2 items attached
                                        </p>
                                      </td>
                                    </tr>
                                  </tbody>
                                ))
                              ) : (
                                <p>No data</p>
                              )}
                            </table>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </li>
                </ul>

                {/* technician visit */}
                <ul className="p-0 mt-3" style={{ display: "inline-flex" }}>
                  <li className="mt-2" style={{ listStyle: "none" }}>
                    <div
                      className="w-20 rounded-circle"
                      style={{
                        backgroundColor: IsTechnicianvisit
                          ? "#FCB61A"
                          : "#36A93F",
                        height: "20px",
                        width: "20px",
                      }}
                    ></div>
                  </li>
                  <li className="ms-2" style={{ listStyle: "none" }}>
                    <Accordion elevation={1}>
                      <AccordionSummary
                        // expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        onClick={() => setIsTechnicianvisit(!IsTechnicianvisit)}
                      >
                        <Typography>
                          Technician visit{" "}
                          {IsTechnicianvisit ? (
                            <ExpandMoreIcon />
                          ) : (
                            <KeyboardArrowUpIcon />
                          )}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        className="accordian_details"
                        style={{ width: "600px" }}
                      >
                        <div className="row p-2">
                          <div className="col-lg-1 text-end p-0">
                            <DoneIcon style={{ color: "#36A93F" }} />
                          </div>
                          <div className="col-lg p-0 text-start">
                            <p
                              className="m-0 p-0 ms-2"
                              style={{ fontSize: "15px", color: "#36A93F" }}
                            >
                              Visit Slot
                            </p>
                          </div>
                          <div className="col p-0 text-end">
                            {/* <p className="m-0 p-0 ms-2" style={{ fontSize: '13px', color: '#6B6D70' }}>15 mins ago</p> */}
                          </div>
                        </div>
                        <div className="row p-2 m-0">
                          <div
                            className="col-lg-1 text-center p-0 with-vertical-line"
                            style={{ transform: "translatex(-20%)" }}
                          ></div>
                          <div className="col-lg p-0 text-start">
                            <table className="w-100 border-0">
                              <tbody>
                                <tr style={{ height: "40px" }}>
                                  <td className="text-start">
                                    <p
                                      className="m-0"
                                      style={{
                                        color: "black",
                                        fontSize: "16px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      Confirmed Slot
                                    </p>
                                  </td>
                                  <td className="text-end">
                                    <p
                                      className="m-0"
                                      style={{
                                        color: "black",
                                        fontSize: "16px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      11am-12pm,10 May 2023
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        {/* Accepted tech */}
                        {serviceStatus === "Accepted" ? (
                          <div>
                            <div className="row p-2">
                              <div className="col-lg-1 text-end p-0">
                                <DoneIcon style={{ color: "#36A93F" }} />
                              </div>
                              <div className="col-lg p-0 text-start">
                                <p
                                  className="m-0 p-0 ms-2"
                                  style={{ fontSize: "15px", color: "#36A93F" }}
                                >
                                  Technician Accepted
                                </p>
                              </div>
                              <div className="col p-0 text-end">
                                <p
                                  className="m-0 p-0 ms-2"
                                  style={{ fontSize: "13px", color: "#6B6D70" }}
                                >
                                  11 mins ago
                                </p>
                              </div>
                            </div>
                            <div className="row p-2 m-0">
                              <div className="col-lg-1 text-center p-0 with-vertical-line"></div>
                              <div className="col-lg p-0 text-start">
                                <table className="w-100 border-0">
                                  <tbody>
                                    <tr style={{ height: "40px" }}>
                                      <td className="text-start">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Technician accepted your request
                                        </p>
                                      </td>
                                      <td className="text-end">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          <a href="#">View Details</a>
                                        </p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row p-2">
                            <div className="col-lg-1 text-end p-0">
                              <DoneIcon style={{ color: "#36A93F" }} />
                            </div>
                            <div className="col-lg p-0 text-start">
                              <p
                                className="m-0 p-0 ms-2"
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(235, 129, 40)",
                                }}
                              >
                                Technician Confirmation Pending
                              </p>
                              <p
                                className="m-0 p-0 ms-2 mt-2"
                                style={{ fontSize: "16px", fontWeight: "400" }}
                              >
                                Please wait! your request has been set and
                                technician yet to confirm it
                              </p>
                            </div>
                          </div>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </li>
                </ul>

                {/* Repair Estimated SLA */}
                <ul className="p-0 mt-3" style={{ display: "inline-flex" }}>
                  <li className="mt-2" style={{ listStyle: "none" }}>
                    <div
                      className="w-20 rounded-circle"
                      style={{
                        backgroundColor: IsRepairEstimatedSLA
                          ? "#FCB61A"
                          : "#36A93F",
                        height: "20px",
                        width: "20px",
                      }}
                    ></div>
                  </li>
                  <li className="ms-2" style={{ listStyle: "none" }}>
                    <Accordion elevation={1}>
                      <AccordionSummary
                        // expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        onClick={() => {
                          setIsRepairEstimatedSLA(!IsRepairEstimatedSLA);
                        }}
                      >
                        <Typography>
                          RepairEstimatedSLA
                          {IsRepairEstimatedSLA ? (
                            <ExpandMoreIcon />
                          ) : (
                            <KeyboardArrowUpIcon />
                          )}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        className="accordian_details"
                        style={{ width: "600px" }}
                      >
                        <div className="row p-2">
                          <div className="col-lg-1 text-end p-0">
                            <DoneIcon style={{ color: "#36A93F" }} />
                          </div>
                          <div className="col-lg p-0 text-start">
                            <p
                              className="m-0 p-0 ms-2"
                              style={{ fontSize: "15px", color: "#36A93F" }}
                            >
                              SR Details
                            </p>
                          </div>
                          <div className="col p-0 text-end">
                            <p
                              className="m-0 p-0 ms-2"
                              style={{ fontSize: "13px", color: "#6B6D70" }}
                            >
                              15 mins ago
                            </p>
                          </div>
                        </div>

                        <div className="row p-2">
                          <div className="col-lg-1 text-center p-0 with-vertical-line"></div>
                          <div className="col-lg p-0 text-start">
                            <table className="w-100 border-0">
                              {SrDetailArray.length > 0 ? (
                                SrDetailArray.map((srDetail, index) => (
                                  <tbody>
                                    <tr style={{ height: "40px" }}>
                                      <td className="text-start">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Device
                                        </p>
                                      </td>
                                      <td className="text-end">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {srDetail.appliance_name
                                            ? srDetail.appliance_name
                                            : ""}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr style={{ height: "40px" }}>
                                      <td className="text-start">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Brand
                                        </p>
                                      </td>
                                      <td className="text-end">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {srDetail.brand ? srDetail.brand : ""}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr style={{ height: "40px" }}>
                                      <td className="text-start">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Model
                                        </p>
                                      </td>
                                      <td className="text-end">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {srDetail.model_no
                                            ? srDetail.model_no
                                            : ""}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr style={{ height: "40px" }}>
                                      <td className="text-start">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Size
                                        </p>
                                      </td>
                                      <td className="text-end">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {srDetail.variants
                                            ? srDetail.variants
                                            : ""}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr style={{ height: "40px" }}>
                                      <td className="text-start">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Warranty Status
                                        </p>
                                      </td>
                                      <td className="text-end">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {srDetail.warranty_status
                                            ? srDetail.warranty_status
                                            : ""}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr style={{ height: "40px" }}>
                                      <td className="text-start">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Warranty Untill
                                        </p>
                                      </td>
                                      <td className="text-end">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {srDetail.warranty_limit
                                            ? srDetail.warranty_limit
                                            : ""}
                                        </p>
                                      </td>
                                    </tr>
                                  </tbody>
                                ))
                              ) : (
                                <p>No data</p>
                              )}
                            </table>
                          </div>
                        </div>

                        <div className="row p-2">
                          <div className="col-lg-1 text-end p-0">
                            <DoneIcon style={{ color: "#36A93F" }} />
                          </div>
                          <div className="col-lg p-0 text-start">
                            <p
                              className="m-0 p-0 ms-2"
                              style={{ fontSize: "15px", color: "#36A93F" }}
                            >
                              Complaint Details
                            </p>
                          </div>
                          <div className="col p-0 text-end">
                            <p
                              className="m-0 p-0 ms-2"
                              style={{ fontSize: "13px", color: "#6B6D70" }}
                            >
                              15 mins ago
                            </p>
                          </div>
                        </div>

                        <div className="row p-2">
                          <div className="col-lg-1 text-center p-0 with-vertical-line"></div>
                          <div className="col-lg p-0 text-start">
                            <table className="w-100 border-0">
                              {SrDetailArray.length > 0 ? (
                                SrDetailArray.map((srDetail, index) => (
                                  <tbody>
                                    <tr style={{ height: "40px" }}>
                                      <td className="text-start">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Type of Damage
                                        </p>
                                      </td>
                                      <td className="text-end">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {srDetail.damage
                                            ? srDetail.damage
                                            : ""}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr style={{ height: "40px" }}>
                                      <td className="text-start">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Description
                                        </p>
                                      </td>
                                      <td className="text-end">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {srDetail.damage_description
                                            ? srDetail.damage_description
                                            : ""}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr style={{ height: "40px" }}>
                                      <td className="text-start">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          Attachments
                                        </p>
                                      </td>
                                      <td className="text-end">
                                        <p
                                          className="m-0"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          2 items attached
                                        </p>
                                      </td>
                                    </tr>
                                  </tbody>
                                ))
                              ) : (
                                <p>No data</p>
                              )}
                            </table>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminSRlist;
