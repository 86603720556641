import React from "react";
import guarantee from "../../Assets/Home/guarantee.svg";

export default function Guarantee() {
  return (
    <div className="guarantee-services-container">
      <div className="second-flex-img">
        <img src={guarantee} alt="phone_img" />
      </div>
      <div className="first-flex-service">
        <div
          style={{ fontWeight: 600, fontSize: "34px" }}
          className="res-576px-text-align-center res-576px-font-25px"
        >
          Snap out of the "Unknown" Snag with S3!
        </div>
        <div
          style={{
            fontSize: "14px",
            color: "rgba(26, 26, 26, 0.7)",
            marginTop: "10px",
          }}
          className="res-576px-text-align-center "
        >
          100% background-verified technicians. Every enrolled technician is a
          member of the association, and an extensive background check is
          performed to ensure the utmost security.
        </div>
        <div
          style={{ fontWeight: 600, fontSize: "19px", marginTop: "40px" }}
          className="res-576px-text-align-center "
        >
          Reviews and Ratings for any service!
        </div>
        <div
          style={{
            fontSize: "14px",
            color: "rgba(26, 26, 26, 0.7)",
            marginTop: "10px",
          }}
          className="res-576px-text-align-center "
        >
          We value your feedback, and we assure you to have it incorporated into
          our process.
        </div>
        <div
          style={{ fontWeight: 600, fontSize: "19px", marginTop: "40px" }}
          className="res-576px-text-align-center "
        >
          30-day guarantee!
        </div>
        <div
          style={{
            fontSize: "14px",
            color: "rgba(26, 26, 26, 0.7)",
            marginTop: "10px",
          }}
          className="res-576px-text-align-center "
        >
          If any issue recurs, we will fix and repair it for free!
        </div>
      </div>
    </div>
  );
}
