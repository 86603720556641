import React from 'react'

const ErrorPage = () => {
  return (
    <div style={{
      height: '100vh',
      width: '100%',
      color: '#fba724',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '20px',
      fontWeight: '600'
    }}>
      <div>
        404 | PAGE NOT FOUND
      </div>
    </div>
  )
}

export default ErrorPage