import React from "react";
import why_us from "../../Assets/Home/why-us.svg";

export default function WhyUs() {
  return (
    <div className="about-services-container-mission">
      <div
        className="second-flex-img"
        style={{ scale: "0.8", position: "relative", top: "-70px" }}
      >
        <img
          style={{ width: "-webkit-fill-available" }}
          src={why_us}
          alt="phone_img"
        />
      </div>
      <div className="first-flex-service">
        <div
          style={{ fontWeight: 600, fontSize: "34px" }}
          className="res-576px-font-25px res-576px-text-align-center res-576-mt--3"
        >
          "Why Our Clients Choose Us Every Time"
        </div>
        <div
          style={{
            fontSize: "15px",
            color: "rgba(26, 26, 26, 0.7)",
            marginTop: "30px",
            lineHeight: "24px",
          }}
        >
          By 2025, India's Consumer Electronics and Appliances Industry is
          predicted to be the fifth-largest in the world. In line with the
          demand for purchase, the allied installation, and repair services will
          steadily increase. Among white goods, air conditioners, refrigerators,
          washing and laundry machines lead the growth percentages with the
          dishwasher market expected to surpass US$ 90 million by 2025-26.
        </div>
      </div>
    </div>
  );
}
