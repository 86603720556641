import React from "react";

export function DashedLine({ width }) {
  return (
    <svg
      width={width ? width : "343"}
      height="3"
      viewBox="0 0 343 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 1.5H347.5"
        stroke="#D5D7DD"
        stroke-width="3"
        stroke-linecap="square"
        stroke-dasharray="0 13"
      />
    </svg>
  );
}

export function DashedLineVr({ height }) {
  return (
    <svg
      width={"2"}
      height={height ? height : "34"}
      viewBox="0 0 2 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 33.0604L1 -0.841635"
        stroke="#D5D7DD"
        stroke-width="0.703125"
        stroke-linecap="square"
        stroke-dasharray="0 3.05"
      />
    </svg>
  );
}
