import React, { useEffect, useState } from "react";
import Api from "../../service/services";
import Topbar from "../topbar";
import Sidebar from "../Sidebar";
import { useNavigate } from "react-router-dom";

export default function CustAppliances() {
  const navigate = useNavigate();
  const [selectedApplianceId, setSelectedApplianceId] = useState("");
  const [applianceData, setApplianceData] = useState([]);
  const [showAll, setShowAll] = useState(false);

  const handleApplianceClick = (id) => {
    setSelectedApplianceId(id);
    navigate(`/createService?id=${id}`);
  };

  useEffect(() => {
    Api.getApplianceDropdown().then((res) => {
      if (res) {
        setApplianceData(res);
      }
    });
  }, []);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <Topbar />
        <div
          className="row container-fluid"
          style={{
            paddingRight: "0px",
            overflow: "hidden",
            paddingLeft: "0px",
            marginLeft: "0px",
          }}
        >
          <div className="col-lg-2 col-12 p-0 border border-right">
            <Sidebar />
          </div>
          <>
            <div className="col-12 col-12 user_management_dasboard res-font">
              <div className="">
                <div
                  className="pt-0 "
                  style={{
                    minHeight: "calc(100vh - 142px)",
                  }}
                >
                  <div className="bg-white">
                    <div style={{ marginTop: "90px" }}>
                      <div
                        style={{
                          padding: "10px",
                        }}
                      >
                        <div style={{ fontWeight: 600, fontSize: "22px" }}>
                          Are you looking to repair any of these appliances?
                        </div>
                        <div
                          className="pt-3"
                          style={{ color: "rgba(106, 112, 124, 1)" }}
                        >
                          We Service a Wide Range of Appliances! Our expert
                          technicians are ready to assist you with repairs and
                          maintenance for a variety of electronic appliances.
                          Here are some of the appliances we cover.
                        </div>
                        <div className="appliances-container">
                          {applianceData?.map((item, i) => {
                            if (true) {
                              return (
                                <div
                                  onClick={() => handleApplianceClick(item.id)}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  key={item.id}
                                  className="appliance-item-dashboard"
                                >
                                  <img
                                    src={item.appliance_image}
                                    alt={item.appliance_name}
                                  />
                                  <div
                                    style={{
                                      color: "rgba(235, 129, 40, 1)",
                                      paddingTop: "10px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {item.appliance_name}
                                  </div>
                                </div>
                              );
                            } else if (showAll) {
                              return (
                                <div
                                  onClick={() => handleApplianceClick(item?.id)}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  key={item?.id}
                                  className="appliance-item-dashboard"
                                >
                                  <img
                                    src={item?.appliance_image}
                                    alt={item?.appliance_name}
                                  />
                                  <div
                                    style={{
                                      color: "rgba(235, 129, 40, 1)",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    {item?.appliance_name}
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="p-3 res-576-text-align-center"
                  style={{
                    backgroundColor: "rgb(255 229 204 / 15%)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: "13px",
                    }}
                    className="res-576px-d-unset"
                  >
                    <div>All Rights Reserved @ S3</div>
                    <div
                      style={{ display: "flex", gap: "60px", fontSize: "13px" }}
                      className="res-576px-d-unset"
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate("/terms-of-use")}
                        className="cust-dash-hover res-576-mt-1"
                      >
                        Terms of Use
                      </div>
                      <div
                        className="cust-dash-hover res-576-mt-1"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate("/privacy-policy")}
                      >
                        Privacy Policy
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>

      <div
        className="offcanvas offcanvas-start w-100"
        tabindex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      ></div>
    </>
  );
}
