import React from "react";

export default function TellMeAbout() {
  return (
    <div className="tell-me-about-container">
      <div className="tell-me-heading">Tell me about S3 in one minute!</div>
      <div className="flex-box-con">
        <div className="tell-me-about-flex">
          <div className="content-part">
            <div style={{ fontWeight: 600, fontSize: "17px" }}>
              Who are we?{" "}
            </div>
            <div
              style={{
                color: "rgba(26, 26, 26, 0.7)",
                fontSize: "15px",
                marginTop: "1rem",
                lineHeight: "26px",
              }}
            >
              Crowd-sourced experts in Appliance Service, Repair, and
              Installation.{" "}
            </div>
          </div>
          <div className="tell-me-line"></div>
          <div className="content-part">
            <div style={{ fontWeight: 600, fontSize: "17px" }}>
              What do we do?{" "}
            </div>
            <div
              style={{
                color: "rgba(26, 26, 26, 0.7)",
                fontSize: "15px",
                marginTop: "1rem",
                lineHeight: "26px",
              }}
            >
              We specialize in televisions, refrigerators, Washing machines, and
              DTH today. Soon, we will expand our services to many more
              appliances, per our customers’ feedback!{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
