import { createSlice } from "@reduxjs/toolkit";

const revenueConfigSlice = createSlice({
  name: "revenueConfig",
  initialState: {
    loading: false,
  },
  reducers: {
    revenueConfigRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    revenueConfigSuccess(state, action) {
      return {
        ...state,
        loading: false,
        revenueConfig: action.payload,
      };
    },
    revenueConfigFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
  },
});

const { actions, reducer } = revenueConfigSlice;

export const {
  revenueConfigRequest,
  revenueConfigFail,
  revenueConfigSuccess,
} = actions;

export default reducer;
