import { createSlice } from "@reduxjs/toolkit";


const CreateRewardSlice = createSlice({
    name: 'CreateReward',
    initialState: {
        loading: false,
        CreateRewardData: [],
        error: null,
        statusCode: null,
        successMsg: null,
        errorMsg: null,
        totalCount: 0,

    },
    reducers: {
        CreateRewardRequest(state, action) {
            return {
                ...state,
                loading: true
            }
        },
        CreateRewardSuccess(state, action) {
            console.log(action.payload, "CreateReward........................")
            return {
                ...state,
                loading: true,
                CreateRewardData: action?.payload,
                successMsg: action?.payload?.message,
                // totalCount: action.payload.data?.totalCount,
                errorMsg: null,
                statusCode: 'success'
            }
        },
        CreateRewardFail(state, action) {
            return {
                ...state,
                loading: false,
                error: action.payload,
                statusCode: 'error',
                errorMsg: action?.payload?.message,
                // totalCount: 0,
                CreateRewardData: [],
                successMsg: null
            }
        },
        clearStatusCode(state, action) {
            return {
                ...state,
                statusCode: null,
                successMsg: null,
                CreateRewardData: [],
                errorMsg: null,
            }
        }

    }
});

const { actions, reducer } = CreateRewardSlice;

export const { CreateRewardRequest, CreateRewardFail, CreateRewardSuccess, clearStatusCode } = actions

export default reducer;
