// import { TextField } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import { Modal } from "react-bootstrap";
// import Api from "../service/services";
// import { useLocation, useNavigate } from "react-router-dom";
// import { AiOutlineClose } from "react-icons/ai";

// const Footer = () => {
//   const location = useLocation();
//   const navigate = useNavigate();

//   const [termsModal, setTermsModal] = useState(false);
//   const [privacyModal, setPrivacyModal] = useState(false);
//   const [refundsCancellationModal, setRefundsCancellationModal] =
//     useState(false);
//   const [tatModal, setTATModal] = useState(false);
//   const [productPricingModal, setProductPricingModal] = useState(false);
//   const [contactUsModal, setContactUsModal] = useState(false);
//   const [deleteModal, setDeleteModal] = useState(false);
//   const [alertModal, setalertModal] = useState(false);

//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");

//   const handleDeleteModal = (e) => {
//     e.preventDefault();
//     setDeleteModal(false);
//     setalertModal(true);
//   };

//   const handleDelete = () => {
//     const payload = {
//       email,
//       password,
//     };
//     Api.DeleteUser(payload).then((res) => {
//       localStorage.clear();
//       navigate("/");
//     });
//   };

//   useEffect(() => {
//     if (location.pathname === "/home-terms-use") {
//       setTermsModal(true);
//     } else if (location.pathname === "/home-privacy-policy") {
//       setPrivacyModal(true);
//     }
//   }, []);

//   return (
//     <>
//       <div className="p-4" style={{ backgroundColor: "white" }}>
//         <div
//           className="mt-3 "
//           style={{ display: "flex", justifyContent: "center" }}
//         >
//           <div
//             className="p-0 footer-links-group-1"
//             style={{ display: "flex", gap: "10px" }}
//           >
//             <div
//               className="p-0 footer-links-group-2 footer-hover"
//               style={{ display: "flex", gap: "10px" }}
//             >
//               <div
//                 style={{
//                   display: "flex",
//                   gap: "10px",
//                   justifyContent: "center",
//                 }}
//               >
//                 <div>
//                   <div style={{ display: "flex" }}>
//                     <p
//                       className="m-0"
//                       style={{
//                         fontSize: "11px",
//                         fontWeight: "400",
//                         color: "#1E232C",
//                         cursor: "pointer",
//                         textAlign: "center",
//                       }}
//                       onClick={() => navigate("/about")}
//                     >
//                       About S3
//                     </p>
//                     <p style={{ pointerEvents: "none", fontSize: "11px" }}>
//                       &nbsp; &nbsp;|
//                     </p>
//                   </div>
//                 </div>
//                 <div>
//                   <div style={{ display: "flex" }}>
//                     <p
//                       className="m-0"
//                       style={{
//                         fontSize: "11px",
//                         fontWeight: "400",
//                         color: "#1E232C",
//                         cursor: "pointer",
//                         textAlign: "center",
//                       }}
//                       onClick={() => navigate("/TermsAndConditions")}
//                     >
//                       Terms and Conditions
//                     </p>
//                     <p style={{ pointerEvents: "none", fontSize: "11px" }}>
//                       &nbsp; &nbsp;|
//                     </p>
//                   </div>
//                 </div>
//                 <div className="" style={{ listStyle: "none" }}>
//                   <div style={{ display: "flex" }}>
//                     <p
//                       className="m-0"
//                       style={{
//                         fontSize: "11px",
//                         fontWeight: "400",
//                         color: "#1E232C",
//                         cursor: "pointer",
//                         textAlign: "center",
//                       }}
//                       onClick={() => navigate("/privacy-policy")}
//                     >
//                       Privacy Policy
//                     </p>
//                     <p style={{ pointerEvents: "none", fontSize: "11px" }}>
//                       &nbsp; &nbsp;|
//                     </p>
//                   </div>
//                 </div>
//               </div>
//               <div
//                 className="footer-links-group-3"
//                 style={{ display: "flex", gap: "10px" }}
//               >
//                 <div className="" style={{ listStyle: "none" }}>
//                   <div style={{ display: "flex" }}>
//                     <p
//                       className="m-0"
//                       style={{
//                         fontWeight: "400",
//                         color: "#1E232C",
//                         cursor: "pointer",
//                         textAlign: "center",
//                         fontSize: "11px",
//                       }}
//                       onClick={() => navigate("/ProductPricing")}
//                     >
//                       Product Pricing, Refunds and Cancellation Policy
//                     </p>
//                     <p style={{ pointerEvents: "none", fontSize: "11px" }}>
//                       &nbsp; &nbsp;|
//                     </p>
//                   </div>
//                 </div>
//                 <div className="footer-link-res" style={{ listStyle: "none" }}>
//                   <div style={{ display: "flex" }}>
//                     <p
//                       className="m-0"
//                       style={{
//                         fontSize: "11px",
//                         fontWeight: "400",
//                         color: "#1E232C",
//                         cursor: "pointer",
//                         textAlign: "center",
//                       }}
//                       onClick={() => navigate("/TurnAroundTime")}
//                     >
//                       Service Availability and Response Time Guidelines
//                     </p>
//                     <p style={{ pointerEvents: "none", fontSize: "11px" }}>
//                       &nbsp; &nbsp;|
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div
//               className="p-0 footer-links-group-2"
//               style={{ display: "flex", gap: "10px" }}
//             >
//               <div
//                 className="footer-link-res-vis"
//                 style={{ listStyle: "none" }}
//               >
//                 <div style={{ display: "flex" }}>
//                   <p
//                     className="m-0"
//                     style={{
//                       fontSize: "11px",
//                       fontWeight: "400",
//                       color: "#1E232C",
//                       cursor: "pointer",
//                       textAlign: "center",
//                     }}
//                     onClick={() => navigate("/TurnAroundTime")}
//                   >
//                     Service Availability and Response Time Guidelines
//                   </p>
//                   <p style={{ pointerEvents: "none", fontSize: "11px" }}>
//                     &nbsp; &nbsp;|
//                   </p>
//                 </div>
//               </div>
//               <div style={{ display: "flex", gap: "10px" }}>
//                 <div className="footer-hover" style={{ listStyle: "none" }}>
//                   <div style={{ display: "flex" }}>
//                     <p
//                       className="m-0"
//                       style={{
//                         fontSize: "11px",
//                         fontWeight: "400",
//                         color: "#1E232C",
//                         cursor: "pointer",
//                         textAlign: "center",
//                       }}
//                       onClick={() => navigate("/ContactUs")}
//                     >
//                       Contact Us
//                     </p>
//                     <p style={{ pointerEvents: "none", fontSize: "11px" }}>
//                       &nbsp; &nbsp;|
//                     </p>
//                   </div>
//                 </div>
//                 <div className="footer-hover" style={{ listStyle: "none" }}>
//                   <p
//                     className="m-0"
//                     style={{
//                       fontSize: "11px",
//                       fontWeight: "400",
//                       color: "#1E232C",
//                       cursor: "pointer",
//                       textAlign: "center",
//                     }}
//                     onClick={() => navigate("/delete-account")}
//                   >
//                     Delete Account
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="">
//           <p
//             style={{
//               fontSize: "11px",
//               color: "gray",
//               textAlign: "center",
//               marginTop: "20px",
//             }}
//           >
//             © Copyright 2024 S3- Secure Sales Service. All rights reserved.
//           </p>
//         </div>
//       </div>

//       <Modal
//         show={termsModal}
//         onHide={() => setTermsModal(false)}
//         size="xl"
//         centered
//       >
//         <div
//           className="pt-3 pb-3 ps-5 pe-5"
//           style={{ background: "#EDEDED", borderRadius: "50px 50px 0 0" }}
//         >
//           <div className="d-flex align-items-center justify-content-space-between">
//             <div style={{ fontSize: "25px", fontWeight: "600" }}>
//               Terms of Use
//             </div>
//             <div
//               style={{ fontSize: "20px", cursor: "pointer" }}
//               onClick={() => setTermsModal(false)}
//             >
//               <AiOutlineClose />
//             </div>
//           </div>
//         </div>
//         <div className="mt-0 m-5 privacy-policy">
//           <div>
//             <div className="mt-4">
//               This document is an electronic record in terms of Information
//               Technology Act, 2000 and rules there under as applicable and the
//               amended provisions pertaining to electronic records in various
//               statutes as amended by the Information Technology Act, 2000.
//             </div>
//             <div className="mt-2">
//               Your use of the domain name s3-app (“s3-app.com”), including the
//               related mobile App and applications (hereinafter referred to as
//               “App”, “S3”, “Company”) are governed by the present terms and
//               conditions ("Terms of Use") and all policies of the App including
//               Privacy Policy.
//             </div>
//             <div className="mt-2">
//               This App is owned by Mr. Sasikumar Jayabal, proprietor of Vinayaka
//               Electronics and GPS, a company incorporated under the Companies
//               Act, 2013 having its registered office No 20/2 Vaiyapuri Nagar,
//               2nd cross, Karur, Tamil Nadu, India-639002.
//             </div>
//             <div className="mt-2">
//               For the purpose of these Terms of Use, wherever the context so
//               requires "You" or "User" shall mean any natural or legal person
//               using the App.
//             </div>
//             <div className="mt-2">
//               When you use the App, you will be subject to the rules,
//               guidelines, policies, terms, and conditions applicable to the App,
//               and they shall be deemed to be incorporated into this Terms of Use
//               and shall be considered as part and parcel of this Terms of Use.
//               We reserve the right, at our sole discretion, to change, modify,
//               add or remove portions of these Terms of Use, at any time without
//               any prior written notice to you. It is your responsibility to
//               review these Terms of Use periodically for updates / changes. Your
//               continued use of the App following the posting of changes will
//               mean that you accept and agree to the revisions. As long as you
//               comply with these Terms of Use, we grant you a personal,
//               non-exclusive, non-transferable, limited privilege to enter and
//               use the App, which can be revoked by S3 at any time without
//               assigning any reason.
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 1. SUMMARY OF SERVICE
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>1.1</span> S3 operates the
//                 App with three types of users: Customers, Technicians and
//                 Admins. Customers request electronic appliances repair service
//                 and make payment for the same. Technicians service the products,
//                 collect payments, order spare parts if needed. Admins use the
//                 application to monitor and control the services and share the
//                 revenue to the fellow admins.
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 2. REGISTRATION
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>2.1</span> You may not have
//                 access to the contents on the App without registering on the App
//                 as a Registered User.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>2.2</span> The App may also
//                 allow you to use your Facebook / Google profile as your login
//                 preference and use your Facebook profile picture as the profile
//                 picture of their account. By choosing the Facebook / Google
//                 login, the User agrees to give the App access to his/her
//                 Facebook / Google account's profile pictures, email id and other
//                 details. The App does not publish any content on the User’s
//                 Facebook profiles without the permission of the User.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>2.3</span> Your User ID and
//                 Password shall be non-transferable; you hereby undertake to keep
//                 the user id and password confidential.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>2.4</span> Wherever
//                 required, you shall provide accurate, complete, and updated
//                 registration information, including your name, address, phone
//                 number etc. Failure to do so shall constitute a breach of the
//                 Terms of Use, which may result in immediate termination of your
//                 account. You shall not use as a User ID or any name or term
//                 that:
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>(i)</span> Is the name of
//                 another person; or
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>(ii)</span> Is subject to
//                 any rights of another person, without appropriate authorization;
//                 or
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>(iii)</span> Is offensive,
//                 vulgar, or obscene; or
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>(iv)</span> Is in violation
//                 of any applicable law or contractual obligation.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>2.5</span> S3 reserves the
//                 right in its sole discretion to refuse registration of or cancel
//                 a User ID. You are solely responsible for activity that occurs
//                 on your account and shall be responsible for maintaining the
//                 confidentiality of your password for the App. You shall never
//                 use another User account without the other User's expressed
//                 permission. You will immediately notify S3 in writing (email:
//                 admin@s3-app.com) of any unauthorized use of your account, or
//                 other known account-related security breach.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>2.6</span> You also
//                 understand and agree that though you may delete your account or
//                 some information from your account, the same may still appear in
//                 some online searches. S3 and App has no control over the search
//                 results that show up in such third-party engines or websites.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>2.7</span> Users should use
//                 the App only if they are legally eligible to enter contracts
//                 under the Indian laws. If users are under 18 years of age, they
//                 may use the App only with the consent of a parent or legal
//                 guardian who agrees to be bound by these Terms. In case any
//                 minor is using the App and doing transaction on the same, it
//                 would be presumed that same is being done with the express
//                 consent of his/her parents / guardian. Further any transaction
//                 done through the account of a minor shall be presumed to be done
//                 by his/her parents/ legal guardian.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>2.8</span> If as a User, you
//                 are accessing the App on behalf of a company or other legal
//                 entity, then you should have the legal authority to represent
//                 and bind such company or entity to these Terms of Use. In such a
//                 case, the terms "You" or "Your" or “Member” or “User” shall
//                 refer to the corporate or other legal entity represented by you.
//                 If you do not have the legal authority to bind the corporate or
//                 legal entity represented by you, then you will be personally
//                 liable for all the obligations contained in these Terms of Use.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>2.9</span> S3 reserves the
//                 right, in its sole discretion, to refuse registration or cancel
//                 registration at any time without assigning any reason for the
//                 same.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>2.10</span> You are
//                 responsible for maintaining the confidentiality of your username
//                 and password as well as for all activities that occur under your
//                 username and password. S3 shall not be responsible for the loss
//                 of your password. The App may assist you in resetting your
//                 password if you forgot it.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>2.11</span> If any user
//                 provides any information that is untrue, inaccurate, not current
//                 or incomplete, or S3 has reasonable grounds to believe that such
//                 information is untrue, inaccurate, not current or incomplete, or
//                 not in accordance with the Terms, S3 has the right to
//                 indefinitely suspend or terminate your account and refuse to
//                 provide you with access to the App.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>2.12</span> You should not
//                 create more than one personal profile. If you have created more
//                 than one login, the S3 reserves the right to delete or disable
//                 such additional, or all accounts, at its sole discretion.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>2.13</span> The user
//                 profile, user ID and password created by you is personal,
//                 exclusive, non-transferable and non-assignable.
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 3. CONTENTS OF THE APP
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>3.1</span> The content
//                 regarding any Organization published on the App is provided by
//                 the said Organization itself to S3.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>3.2</span> The entire
//                 responsibility of the contents provided by the Organization to
//                 S3 for the App is that of the said Organization which is solely
//                 responsible for any false, misleading, incorrect, illegal,
//                 unauthorised or prohibited content.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>3.3</span> S3 cannot
//                 practically verify, validate or authenticate the contents
//                 provided by Organization and is in no way responsible for
//                 correctness or legality of the same.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>3.4</span> The Organization
//                 whose contents have been published on the App shall be solely
//                 liable to for any legal action initiated with regard to the
//                 content provided by it for the App.
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 4. TRANSACTIONS AND PAYMENTS
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>4.1</span> S3 is not
//                 charging any money to the customers to register themselves as
//                 users.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>4.2</span> S3 is charging
//                 Rs.500 from the technicians as a non-refundable deposit and
//                 instructs them to recharge their wallets to get the Job Service
//                 Requests.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>4.3</span> For each Service
//                 Request the technician attends, a certain amount of money is
//                 deducted from the technician's wallet as a commission to S3. S3
//                 would then share a percentage of commission among the
//                 administrators.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>4.5</span> Payment Details
//               </div>
//               <div className="mt-2">
//                 By doing the payment, the Users agree to provide their name,
//                 email address, phone number, billing and shipping addresses.
//                 They also agree to provide the authorized online payment gateway
//                 of the App, necessary & required details of their credit and
//                 debit cards for making an online contribution. Users agree to
//                 have sufficient funds or credit during making a payment. Users
//                 are informed that their credit, debit card and UPI related
//                 details are not provided to the App or S3 by the online payment
//                 gateway & remain confidential. S3 shall be under no liability
//                 whatsoever in respect of any loss or damage arising directly or
//                 indirectly out of the decline of authorization for any
//                 Transaction for any reason whatsoever.
//               </div>
//               <div className="mt-2">
//                 If any stolen debit or credit card is used to make payment, the
//                 S3 shall have the right to pursue criminal charges against the
//                 user making the payment.
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 5. REFUND
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>5.1</span> All payments made
//                 through the App shall be strictly non-refundable. All payments
//                 are final and cannot be refunded.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>5.2</span> The user shall
//                 have to contact the Bank and/or the Payment Gateway directly for
//                 refund of the payment made and the Bank and/or the Payment
//                 Gateway shall deal with the request of refund directly at its
//                 sole discretion and policy.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>5.3</span> S3 shall not have
//                 any liability for refund in any circumstances.
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 6. ACCOUNTABILITY
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>6.1</span> S3 and the App
//                 shall not be held responsible for the payments made. Any payment
//                 made by the User through the app shall be at his own risk.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>6.2</span> S3 and the App
//                 only offers payment gateways to facilitate transaction of
//                 payments.
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 7. THIRD PARTY SITES
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>7.1</span> The App may
//                 permit Organizations and users to link to other websites or
//                 resources on the Internet, and other websites or resources may
//                 contain links to the App. When you access third-party websites,
//                 you do so at your own risk. Those other websites are not under
//                 the control of S3 or App, and you acknowledge that S3 is not
//                 liable for the content, functions, accuracy, legality,
//                 appropriateness, or any other aspect of those other websites or
//                 resources. The inclusion on another webApp or any link to the
//                 App does not imply endorsement by or affiliation with S3 or App.
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 8. DISPUTE BETWEEN ORGANIZATIONS AND USERS
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>8.1</span> S3 is under no
//                 obligation to become involved in disputes between any Users, or
//                 between Users and any third party or Organizations arising in
//                 connection with the use of the App. This includes, but is not
//                 limited to, delivery of goods and services, payments, and any
//                 other terms, conditions, warranties, or representations
//                 associated such third parties. You hereby unconditionally
//                 release the S3, its officers, employees, agents, and successors
//                 in rights from claims, damages, and demands of every kind, known
//                 or unknown, suspected or unsuspected, disclosed or undisclosed,
//                 arising out of or in any way related to such disputes and the
//                 services.
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 9. COVENANTS
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>9.1</span> No user or person
//                 shall copy or utilise, in any manner, designs, content or any
//                 other data generated in consultation with S3, without the prior
//                 written consent of the S3.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>9.2</span> No person shall,
//                 unless S3, its applicable affiliate, and/or authorised
//                 representative give written permission, sell, license, rent,
//                 modify, print, collect, copy, reproduce, download, upload,
//                 transmit, disclose, distribute, disseminate, publicly display,
//                 publicly perform, publish, edit, adapt, electronically extract
//                 or scrub from any content or materials (including, without
//                 limitation, through framing or systematic retrieval to create
//                 collections, compilations, databases or directories) or
//                 otherwise transfer any of the content to any third person
//                 (including, without limitation, others in your company or
//                 organization).
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>9.3</span> You agree not to
//                 decompile, reverse-engineer or disassemble any materials,
//                 information or other content available through this App and not
//                 to insert any code or product to manipulate the content in any
//                 way that affects the User’s experience. Unless we give you prior
//                 written permission, use of any Web browsers (other than
//                 generally available third-party browsers), engines, software,
//                 spiders, robots, avatars, agents, tools or other devices or
//                 mechanisms to navigate, search or determine this App is strictly
//                 prohibited. You agree not to challenge (or assist any third
//                 party to challenge) the validity or enforceability of any
//                 intellectual property owned by S3 or its applicable affiliates
//                 relating to this App or Website, its content or otherwise
//                 referenced therein, including but not limited to the patents and
//                 trademarks listed in this Terms of Use.
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 10. GENERAL TERMS AND CONDITION
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>10.1</span> As a condition
//                 of use, you promise not to use the App for any purpose that is
//                 prohibited by the Terms of Use or law. You are responsible for
//                 all of your activity in connection with the App and its
//                 Services. You shall not, and shall not permit any third party
//                 using your account to, take any action, or submit content, that:
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>(i)</span> Infringes any
//                 patent, trademark, trade secret, copyright, right of publicity,
//                 or other right of any other person or entity, or violates any
//                 law or contract;
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>(ii)</span> You know is
//                 false, misleading, or inaccurate;
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>(iii)</span> Is unlawful,
//                 threatening, abusive, harassing, defamatory, libellous,
//                 deceptive, fraudulent, tortious, obscene, offensive, profane, or
//                 invasive of another's privacy;
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>(iv)</span> Constitutes
//                 unsolicited or unauthorized advertising or promotional material
//                 or any junk mail, spam, or chain letters;
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>(v)</span> Contains software
//                 viruses or any other computer codes, files, or programs that are
//                 designed or intended to disrupt, damage, limit, or interfere
//                 with the proper function of any software, hardware, or
//                 telecommunications equipment or to damage or obtain unauthorized
//                 access to any system, data, password, or other information of S3
//                 or any third party; or
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>(vi)</span> Impersonates any
//                 person or entity, including any employee or representative of
//                 S3.
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 11. Warranty Disclaimer
//               </div>
//               <div className="mt-2">
//                 11.1 S3 has no special relationship with or fiduciary duty to
//                 you.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>11.2</span> You acknowledge
//                 that S3 any has no duty to take any action regarding any of the
//                 following: which Users gain access to the App; what content
//                 Users access through the App; what effects the Content may have
//                 on Users; how Users may interpret or use the Content; or what
//                 actions Users may take as a result of having been exposed to the
//                 Content.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>11.3</span> S3 cannot
//                 guarantee the authenticity of any data or information that
//                 Organizations or any Third Parties provide about themselves or
//                 their campaigns and projects.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>11.4</span> You release S3
//                 from all liability for having your acquired or not acquired
//                 Content through the App.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>11.5</span> The Company
//                 reserves the right to, at any time, for any reason, and without
//                 notice cancel, reject, interrupt, remove, or suspend any content
//                 published on the App and remove, edit, or modify any content,
//                 including, but not limited to and remove or block any user and
//                 his/her submissions.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>11.6</span> The Company
//                 reserves the right not to comment on the reasons for any of
//                 these actions.
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 12. CONTENT AND LICENSE
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>12.1</span> You agree that
//                 the App contains content provided by S3, its partners,
//                 Organizations and Users and that the content may be protected by
//                 copyrights, trademarks, service marks, patents, trade secrets,
//                 or other rights and laws. You shall abide by and maintain all
//                 copyright and other legal notices, information, and restrictions
//                 contained in any content accessed through the App. S3 grants to
//                 each user of the App a worldwide, non-exclusive,
//                 non-sub-licensable and non-transferable license to use and
//                 reproduce the content, solely for personal, non-commercial use.
//                 Use, reproduction, modification, distribution, or storage of any
//                 content for other than personal, non-commercial use is
//                 prohibited without prior written permission from S3, or from the
//                 copyright holder. You shall not sell, license, rent, or
//                 otherwise use or exploit any content for commercial use or in
//                 any way that violates any third-party right.
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 13. INDEMNIFICATION
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>13.1</span> Notwithstanding
//                 anything herein, Users of the App, will not hold S3 or any of
//                 its directors, employees, agents, partners, vendors, suppliers
//                 responsible for any act or conduct.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>13.2</span> The users will
//                 also not hold S3 or any of its directors, employees, agents,
//                 partners, vendors, suppliers, or content providers responsible
//                 for any unforeseen technical glitches such as but not limited to
//                 App down for maintenance, server errors, speed of mobile app or
//                 website, any viruses in the App, etc., including but not limited
//                 to such technical glitches affecting transaction activity or
//                 payment being made by users on the App.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>13.3</span> Additionally,
//                 you shall defend, indemnify and hold harmless S3 and its
//                 directors, employees, agents, partners, vendors, suppliers, it's
//                 affiliates, employees, contractors, directors, suppliers, and
//                 representatives from all direct and indirect liabilities,
//                 claims, and expenses, including reasonable attorneys' fees, that
//                 arise from or relate to your breach or non-compliance of any of
//                 the Terms of Use herein, misrepresentation, use or misuse of, or
//                 access to, the service and content, or otherwise from your user
//                 submissions, violation of the Terms of Use, or infringement by
//                 you, or any third party using your account, of any intellectual
//                 property or other right of any person or entity. S3 reserves the
//                 right to assume the exclusive defence and control of any matter
//                 otherwise subject to indemnification by you, in which event you
//                 will assist and cooperate with S3 in asserting any available
//                 defences.
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 14. LIMITATION OF LIABILITY
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>14.1</span> In no event
//                 shall S3, or its directors, employees, agents, partners,
//                 vendors, suppliers, be liable under contract, tort, strict
//                 liability, negligence, or any other legal or equitable theory
//                 with respect to the service:
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>a)</span> for any lost
//                 profits, data loss, or special, indirect, incidental, punitive,
//                 or consequential damages of any kind whatsoever, however arising
//                 and
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>b)</span> or any bugs,
//                 viruses, trojan horses, or the like (regardless of the source of
//                 origination).
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>14.2</span> S3 shall not be
//                 liable for any failure to perform its obligations hereunder
//                 where the failure results from any cause beyond the S3
//                 reasonable control, including, without limitation, third party
//                 intervention, change in law or economic circumstances,
//                 mechanical, electronic, or communications failure or
//                 degradation. In no event will the S3 total liability to you for
//                 all damages, losses or causes of action exceed Rs. 1000 (Rupees
//                 One Thousand Only).
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 15. DISPUTE RESOLUTION
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>15.1</span> If any dispute,
//                 controversy or claim arises out of or in connection with these
//                 Terms of Use, use of the App, its Privacy Policy or any other
//                 related terms in which S3 is a party (a Dispute), the disputing
//                 parties shall use all reasonable endeavours to negotiate with a
//                 view to resolving the Dispute amicably. If a party gives the
//                 other party notice that a Dispute has arisen (a Dispute Notice)
//                 and the parties are unable to resolve the Dispute amicably
//                 within 30 (thirty) days of service of the Dispute Notice (or
//                 such longer period as the parties may mutually agree), then the
//                 Dispute shall be referred to Arbitration in accordance with the
//                 below terms. Subject to the above clause, all Disputes or
//                 differences regarding this Agreement shall be submitted to final
//                 and binding arbitration. In the event of such arbitration:
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>a)</span> All proceedings of
//                 such arbitration shall be in the Tamil language.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>b)</span> The place / seat
//                 of the arbitration shall be Karur, Tamil Nadu and the courts of
//                 Karur, Tamil Nadu shall have exclusive jurisdiction over the
//                 arbitration proceedings;
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>c)</span> The Law governing
//                 the conduct of arbitration and the arbitration proceedings shall
//                 be the laws of India;
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>d)</span> The arbitration
//                 shall be conducted before a sole arbitrator appointed by mutual
//                 consent of the parties;
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>e)</span> Arbitration awards
//                 shall be reasoned awards and shall be final and binding on the
//                 disputing parties; and
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>f)</span> The existence or
//                 subsistence of a dispute between the parties, or the
//                 commencement or continuation of arbitration proceedings, shall
//                 not, in any manner, prevent or postpone the performance of those
//                 obligations of the parties under the Agreement which are not in
//                 dispute, and the arbitrators shall give due consideration to
//                 such performance, if any, in making a final award.
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 16. MODIFICATION OF TERMS OF USE
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>16.1 S3</span> reserves the
//                 right, at its sole discretion, to modify or replace these Terms
//                 of Use, or change, suspend, or discontinue the App and its
//                 services (including, but not limited to, the availability of any
//                 feature, database, or content) at any time for any reason. S3
//                 may also impose limits on certain features and services or
//                 restrict your access to parts of or the whole App without notice
//                 or liability. It is your responsibility to check the Terms of
//                 Use periodically for changes. Your continued use of the App
//                 following the posting of any changes to the Terms of Use
//                 constitutes acceptance of those changes.
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 17. INTEGRATION, SEVERABILITY AND WAIVER
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>17.1</span> These Terms of
//                 Use, the Privacy Policy, herein or on the App, are the entire
//                 agreement between you and the Company with respect to the
//                 service and supersede all prior or contemporaneous
//                 communications and proposals (whether oral, written or
//                 electronic) between you and the Company with respect to the
//                 service and govern the future relationship. If any provision of
//                 the Terms of Use is found to be unenforceable or invalid, that
//                 provision will be limited or eliminated to the minimum extent
//                 necessary so that the Terms of Use will otherwise remain in full
//                 force and effect and enforceable. The failure of either party to
//                 exercise in any respect any right provided for herein shall not
//                 be deemed a waiver of any further rights hereunder.
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 18. EFFECTIVE DATE
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>18.1</span> This Terms of
//                 Use was updated and became immediately effective on{" "}
//                 <span style={{ fontWeight: "600" }}>20/08/2023.</span>
//               </div>
//             </div>
//           </div>
//         </div>
//       </Modal>
//       <Modal
//         show={privacyModal}
//         onHide={() => setPrivacyModal(false)}
//         size="xl"
//         centered
//       >
//         <div
//           className="pt-3 pb-3 ps-5 pe-5"
//           style={{ background: "#EDEDED", borderRadius: "50px 50px 0 0" }}
//         >
//           <div className="d-flex align-items-center justify-content-space-between">
//             <div style={{ fontSize: "25px", fontWeight: "600" }}>
//               Privacy Policy
//             </div>
//             <div
//               style={{ fontSize: "20px", cursor: "pointer" }}
//               onClick={() => setPrivacyModal(false)}
//             >
//               <AiOutlineClose />
//             </div>
//           </div>
//         </div>
//         <div className="mt-0 m-5 privacy-policy">
//           <div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 I. INTRODUCTION
//               </div>
//               <div className="mt-2">
//                 At S3, we collect and manage user data and information according
//                 to this Privacy Policy. By using any software, mobile
//                 application, or website of S3 (the “App”), you agree to the
//                 terms of this Privacy Policy.
//               </div>
//               <div className="mt-2">
//                 S3 respects your privacy and is committed to protecting your
//                 personal data. This Privacy Policy will inform you as to how we
//                 look after your personal data when you visit or register with
//                 our App and tell you about your privacy rights and how the law
//                 protects you. This Privacy Policy applies to all/any
//                 user/consumer/technician/administrator (“Users”) registering,
//                 accessing or viewing the App.
//               </div>
//               <div className="mt-2">
//                 Privacy choices that you may have made on the third-party site
//                 or app will not apply to the information we have collected
//                 directly through our applications. Please also keep in mind that
//                 our sites and applications may contain links to other sites/apps
//                 (for example, payments through UPI, etc.) not owned or
//                 controlled by us and we are not responsible for the privacy
//                 practices of those sites/apps. We encourage you to be aware when
//                 you leave our sites or applications and to read the privacy
//                 policies of other sites that may collect your personal
//                 information.
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 II. THE DATA WE COLLECT ABOUT YOU
//               </div>
//               <div className="mt-2">
//                 Personal data is defined as any information that identifies
//                 (whether directly or indirectly) to a particular individual or
//                 natural person, such as the individual’s name, postal address,
//                 email address, mobile number and any other specific identifier
//                 indicating to that particular person.
//               </div>
//               <div className="mt-2">
//                 We shall not collect sensitive personal data or information
//                 unless the information is collected for a lawful purpose
//                 connected with our function or activity and the collection of
//                 the sensitive personal data or information is necessary for that
//                 purpose.
//               </div>
//               <div className="mt-2">
//                 However, we shall not be responsible for the authenticity of the
//                 personal information or sensitive personal data or information
//                 supplied by the User to us.
//               </div>
//               <div className="mt-2">
//                 The User has an option of not providing data or information we
//                 seek to collect in which case the User shall not be able to not
//                 to use the services on the App for which the said information
//                 was sought.
//               </div>
//               <div className="mt-2">
//                 We may collect, use, store, and transfer different kinds of
//                 personal data or personal information about you which may
//                 include but shall not be limited to:
//               </div>
//               <div className="mt-2 d-flex gap-2">
//                 <span style={{ fontSize: "20px", marginTop: "-4px" }}>•</span>
//                 <span>
//                   <span style={{ fontWeight: "600" }}>Identity Data</span>{" "}
//                   includes first name, maiden name, last name, username or
//                   similar identifier, date of birth, the language you use, and
//                   gender.
//                 </span>
//               </div>
//               <div className="mt-2 d-flex gap-2">
//                 <span style={{ fontSize: "20px", marginTop: "-4px" }}>•</span>
//                 <span>
//                   <span style={{ fontWeight: "600" }}>Contact Data</span>
//                   includes your address, email, mobile number etc
//                 </span>
//               </div>
//               <div className="mt-2 d-flex gap-2">
//                 <span style={{ fontSize: "20px", marginTop: "-4px" }}>•</span>
//                 <span>
//                   <span style={{ fontWeight: "600" }}>Financial Data</span>
//                   includes your payment card and bank details and the same might
//                   include sensitive personal data or information. The financial
//                   data shall be collected only for the purposes of processing
//                   transactions / payments on the App.
//                 </span>
//               </div>
//               <div className="mt-2 d-flex gap-2">
//                 <span style={{ fontSize: "20px", marginTop: "-4px" }}>•</span>
//                 <span>
//                   <span style={{ fontWeight: "600" }}>Transaction Data</span>{" "}
//                   details about payments to and from you and other details of
//                   services you may have availed from us.
//                 </span>
//               </div>
//               <div className="mt-2 d-flex gap-2">
//                 <span style={{ fontSize: "20px", marginTop: "-4px" }}>•</span>
//                 <span>
//                   <span style={{ fontWeight: "600" }}>Technical Data</span> your
//                   geo location, IP address, your login data, cookie data, log
//                   filed information, browser type and version, Android
//                   Advertiser ID/ iDFA ID, time zone setting and location,
//                   browser plug-in types and versions, operating system and app
//                   and other technology on the devices you use to access the App
//                 </span>
//               </div>
//               <div className="mt-2 d-flex gap-2">
//                 <span style={{ fontSize: "20px", marginTop: "-4px" }}>•</span>
//                 <span>
//                   <span style={{ fontWeight: "600" }}>Profile Data</span> your
//                   username and password, purchases or orders made by you, your
//                   preferences, feedback and survey responses.
//                 </span>
//               </div>
//               <div className="mt-2 d-flex gap-2">
//                 <span style={{ fontSize: "20px", marginTop: "-4px" }}>•</span>
//                 <span>
//                   <span style={{ fontWeight: "600" }}>Usage Data</span>{" "}
//                   information about how you use the App
//                 </span>
//               </div>
//               <div className="mt-2 d-flex gap-2">
//                 <span style={{ fontSize: "20px", marginTop: "-4px" }}>•</span>
//                 <span>
//                   <span style={{ fontWeight: "600" }}>
//                     Marketing and Communications Data
//                   </span>{" "}
//                   your preferences in receiving marketing from us and our third
//                   parties and your communication preferences.
//                 </span>
//               </div>
//               <div className="mt-2 d-flex gap-2">
//                 <span style={{ fontSize: "20px", marginTop: "-4px" }}>•</span>
//                 <span>
//                   <span style={{ fontWeight: "600" }}>
//                     Information shared by Our partners and advertisers
//                   </span>{" "}
//                   information about you and your activity outside App from our
//                   affiliates, advertisers, partners and other third parties we
//                   work with.
//                 </span>
//               </div>
//               <div className="mt-2">
//                 We also collect, use and share data such as statistical or
//                 demographic data for any purpose. Such data may be derived from
//                 your personal data but is not considered personal data in law as
//                 this data does not directly or indirectly reveal your identity.
//               </div>
//               <div className="mt-2">
//                 We do not collect any special categories of personal data about
//                 you (this includes details about your race or ethnicity,
//                 religious or philosophical beliefs, sex life, sexual
//                 orientation, political opinions, trade union membership,
//                 password, medical records and history, physical, physiological
//                 and mental health condition and biometric data), nor do we
//                 collect any information about criminal convictions and offences.
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 III. HOW WE USE YOUR PERSONAL DATA
//               </div>
//               <div className="mt-2">
//                 We will only use your personal data in accordance with law. Most
//                 commonly, we will use your personal data in the following
//                 circumstances
//               </div>
//               <div className="mt-2 d-flex gap-2">
//                 <span style={{ fontSize: "20px", marginTop: "-4px" }}>•</span>
//                 <span>Where we need to perform the services we offer.</span>
//               </div>
//               <div className="mt-2 d-flex gap-2">
//                 <span style={{ fontSize: "20px", marginTop: "-4px" }}>•</span>
//                 <span>
//                   Where it is necessary for our legitimate interests (or those
//                   of a third party)
//                 </span>
//               </div>
//               <div className="mt-2 d-flex gap-2">
//                 <span style={{ fontSize: "20px", marginTop: "-4px" }}>•</span>
//                 <span>
//                   Where we need to comply with a legal or regulatory obligation.
//                 </span>
//               </div>
//               <div className="mt-2">
//                 In any event, the information collected by us shall be used for
//                 the purpose for which it has been collected.
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 IV. PURPOSES FOR WHICH WE WILL USE YOUR PERSONAL DATA
//               </div>
//               <div className="mt-2 sub-heading" style={{ fontWeight: "600" }}>
//                 (A) SENSITIVE PERSONAL DATA OR INFORMATION
//               </div>
//               <div className="mt-2">
//                 We shall be using your sensitive personal data or information
//                 like bank account and debit / credit card details, UPI ID, etc.
//                 (Financial Information) only for the purposes of processing the
//                 transaction / payment made on the App. By using the App and
//                 making transaction on the App you hereby agree to provide us
//                 with your aforesaid sensitive personal data or information for
//                 the purposes mentioned above.
//               </div>
//               <div className="mt-2">
//                 The user of the app shall have an option of saving Financial
//                 Information with us for future use. If the user has not opted to
//                 save Financial Information with us, it will not be stored in any
//                 form on our system. We shall not hold the Financial Information
//                 longer than required.
//               </div>
//               <div className="mt-2">
//                 Personal information might be shared and used by our Payment
//                 Gateway Partners - Razorpay, Paytm, CCAvenue, Instamojo, or any
//                 other service providers we partnered with for the purposes of
//                 processing the payment made through the App and further issuance
//                 of receipt for the payment made. By using the App, the User
//                 agrees and grants his/her consent to us to share his/her
//                 sensitive personal data or information like Financial
//                 Information with our Payment Gateway Partners for the aforesaid
//                 purposes
//               </div>
//               <div className="mt-2 sub-heading" style={{ fontWeight: "600" }}>
//                 (B) USE OF INFORMATION BY S3
//               </div>
//               <div className="mt-2">
//                 The information supplied by you enables us to improve our
//                 services and provide you the most user-friendly experience(s)
//                 and to maintain, protect, and improve the services and for
//                 developing new services. Further, any personally identifiable
//                 information provided by you will not be considered as sensitive
//                 if it is freely available and / or accessible in the public
//                 domain like any comments, messages, blogs, scribbles available
//                 on social apps like Facebook, Twitter, etc
//               </div>
//               <div className="mt-2">
//                 Any posted/uploaded/conveyed communication by users on the App
//                 becomes published content and is not considered personally
//                 identifiable information subject to this Privacy Policy. In case
//                 you choose to decline to submit personal information on the App,
//                 we may not be able to provide certain services on the App to
//                 you. We will make reasonable efforts to notify you of the same
//                 at the time of registering your account. In any case, we will
//                 not be liable and/or responsible for the denial of certain
//                 services to you for lack of you providing the necessary personal
//                 information or consent.
//               </div>
//               <div className="mt-2">
//                 When you register with the App, we may contact you from time to
//                 time about updating your personal information to provide you
//                 features that we believe may benefit / interest you.
//               </div>
//               <div className="mt-2">
//                 We may further use your contact details like phone number, email
//                 etc to contact you regarding marketing of S3 and its associates,
//                 and share the same with third parties
//               </div>
//               <div className="mt-2 sub-heading" style={{ fontWeight: "600" }}>
//                 (C) SHARING WITH PAYMENT GATEWAY SERVICE PROVIDERS
//               </div>
//               <div className="mt-2">
//                 S3 might also share personal information with the Payment
//                 Gateway Service Providers for enabling payment being made and
//                 further for issuance of receipt qua the payment made.
//               </div>
//               <div className="mt-2 sub-heading" style={{ fontWeight: "600" }}>
//                 (D) SHARING WITH THIRD PARTIES
//               </div>
//               <div className="mt-2">
//                 S3 may share your information with any third parties without
//                 obtaining your prior consent in the following limited
//                 circumstances:
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>a)</span> When it is
//                 requested or required by law or by any court or governmental
//                 agency or authority to disclose, for the purpose of verification
//                 of identity, or for the prevention, detection, investigation
//                 including cyber incidents, or for prosecution and punishment of
//                 offences. These disclosures are made in good faith and belief
//                 that such disclosure is reasonably necessary for enforcing these
//                 Terms or for complying with the applicable laws and regulations.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>b)</span> S3 proposes to
//                 share such information with third parties for the purpose of
//                 processing personal information on its behalf. We also ensure
//                 that these recipients of such information agree to process such
//                 information based on our instructions and in compliance with
//                 this Privacy Policy and any other appropriate confidentiality
//                 and security measures.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>c)</span> S3 may share your
//                 information regarding your activities on App with third party
//                 comments sites, social websites to populate your social wall
//                 that is visible to other people; however, you will have an
//                 option to set or inform us your privacy settings, where you can
//                 decide what you would like to share or not to share with others.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontWeight: "600" }}>d)</span> S3 may share your
//                 information with third parties, at your direction, to enable you
//                 to sign up for or log in to the App, or when you decide to link
//                 your App account to those services, like Facebook or Google, or
//                 when you publish your content on App to them. For example, if
//                 you choose to publish your App content on to Facebook or
//                 Twitter, then information about that content will be shared with
//                 Facebook or Twitter.
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 V. CHANGES TO S3'S PRIVACY POLICY
//               </div>
//               <div className="mt-2">
//                 Our business may change from time to time. As a result, at times
//                 it may be necessary for S3 to make changes to this Privacy
//                 Policy. S3 reserves the right to update or modify this Privacy
//                 Policy at any time and from time to time. If you disagree with
//                 our revisions to the Privacy Policy, you may discontinue the use
//                 of our services. Please review this policy periodically, and
//                 especially before you provide us with any information through
//                 the services. This Privacy Policy was last updated on the date
//                 indicated on top of this page. Your continued use of the
//                 Services after any changes or revisions to this Privacy Policy
//                 shall indicate your agreement with the terms of such revised
//                 Privacy Policy
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 VI. DATA RETENTION
//               </div>
//               <div className="mt-2">
//                 We will only retain your personal data for as long as necessary
//                 to fulfil the purposes we collected it for, including for the
//                 purposes of satisfying any legal, accounting, or reporting
//                 requirements
//               </div>
//               <div className="mt-2">
//                 We retain the information which we have collected no longer than
//                 is necessary. This is determined on a case by case basis.
//                 Normally, we will retain the information as long as you maintain
//                 an account with us and till the time you continue to access and
//                 use the App and its services. We will delete your information
//                 whenever you request us to do so. However, we may archive and/or
//                 retain some information for legal purposes.
//               </div>
//               <div className="mt-2">
//                 However, at the express written request of the user, we shall
//                 erase information of the user in strict accordance with the law
//                 in force. Further, at the request of the user we shall delete
//                 any information which is misleading, embarrassing and
//                 irrelevant.
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 VII. ACCESSING AND RECTIFYING YOUR PERSONAL INFORMATION
//               </div>
//               <div className="mt-2">
//                 When you use the App, we make good faith efforts to provide you,
//                 as and when requested by you, with access to your personal
//                 information and shall further ensure that any personal
//                 information provided by you shall be corrected or amended as
//                 feasible. We shall permit the Users, as and when requested by
//                 them, to review the information they had provided and ensure
//                 that any personal information or sensitive personal data or
//                 information found to be inaccurate or deficient shall be
//                 corrected or amended as feasible.
//               </div>
//               <div className="mt-2">
//                 This version of the Privacy Policy was last updated as on the
//                 date provided above. It is important that the personal data we
//                 hold about you is accurate and current. Please keep us informed
//                 if your personal data changes during your relationship with us.
//               </div>
//               <div className="mt-2">
//                 You can make requests to S3 for correcting the personal
//                 information while there’s a provision for you to do it by
//                 yourself through the app. You can also ask us to correct
//                 inaccurate or incomplete personal information by sending an
//                 e-mail to our support team to perform the rectification.
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 VIII. COMPLAINTS AND GRIEVANCE REDRESSAL
//               </div>
//               <div className="mt-2">
//                 You can write to us at the details mentioned below in case of
//                 any grievance or compliant. If you have any complaints regarding
//                 processing of your personal information or data, you may contact
//                 our data protection officer as under:
//               </div>
//               <div className="mt-2">Grievance-cum-Data Protection Officer</div>
//               <div>S3 – Secure Sales Service,</div>
//               <div>Vinayaka Electronics and GPS,</div>
//               <div>20/2, VAIYAPURI NAGAR,</div>
//               <div>SECOND CROSS, KARUR,</div>
//               <div>Tamil Nadu, 639002</div>
//               <div>
//                 <span style={{ fontWeight: "600" }}>Ph:</span> +91 9600300459
//               </div>
//               <div>
//                 <span style={{ fontWeight: "600" }}>E-mail:</span>{" "}
//                 support@s3-app.com
//               </div>
//             </div>
//           </div>
//         </div>
//       </Modal>
//       <Modal
//         show={refundsCancellationModal}
//         onHide={() => setRefundsCancellationModal(false)}
//         size="xl"
//         centered
//       >
//         <div
//           className="pt-3 pb-3 ps-5 pe-5"
//           style={{ background: "#EDEDED", borderRadius: "50px 50px 0 0" }}
//         >
//           <div className="d-flex align-items-center justify-content-space-between">
//             <div style={{ fontSize: "25px", fontWeight: "600" }}>
//               Refunds and Cancellation
//             </div>
//             <div
//               style={{ fontSize: "20px", cursor: "pointer" }}
//               onClick={() => setRefundsCancellationModal(false)}
//             >
//               <AiOutlineClose />
//             </div>
//           </div>
//         </div>
//         <div className="mt-0 m-5 privacy-policy">
//           <div>
//             <div className="mt-4">
//               <div>
//                 At S3, your satisfaction is our top priority, and we strive to
//                 provide the best possible service for your electronic devices.
//                 However, we understand that there may be situations where you
//                 need to cancel a Service Request (SR) or request a refund.
//                 Please review our Refunds and Cancellation policy below:
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 Cancellation Policy:
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 Service Request Cancellation
//               </div>
//               <div className="mt-2">
//                 The Service Request can be edited or cancelled until the day
//                 before the technician's scheduled visit using the cancel button
//                 on the Service Request page. If the Service Request is scheduled
//                 for the same day, cancellations can be made until the technician
//                 accepts the Service Request. Once the Technician accepts the
//                 Service Request scheduled for the same day, the customer cannot
//                 cancel it. However, the customer can mutually agree with the
//                 technician to edit the time slot.
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 Cancellation Charges
//               </div>
//               <div className="mt-2">
//                 We understand that circumstances can change, and we want to
//                 provide flexibility to our customers. Therefore, we have revised
//                 our cancellation charges as follows:
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontSize: "18px", fontWeight: "600" }}>•</span>{" "}
//                 Cancellation made 24 hours or more before the scheduled visit:
//                 No cancellation fee.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontSize: "18px", fontWeight: "600" }}>•</span>{" "}
//                 Cancellation made less than 24 hours of the scheduled visit (a
//                 technician has not been assigned to a Service Request): No
//                 cancellation fee.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontSize: "18px", fontWeight: "600" }}>•</span>{" "}
//                 Cancellation made less than 24 hours of the scheduled visit (a
//                 technician has already been assigned to a Service Request): A
//                 nominal cancellation fee may apply based on the stage of the
//                 technician's journey to your location. The specific fee will be
//                 communicated to you at the time of cancellation.
//               </div>
//               <div className="mt-2">
//                 <span style={{ fontSize: "18px", fontWeight: "600" }}>•</span>{" "}
//                 Cancellation made after the technician accepts the Service
//                 Request (for same-day requests): In this case, the customer
//                 cannot cancel the Service Request. If the customer still wishes
//                 to cancel the Service Request, he/she will be required to pay
//                 the basic visit charge.
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 Refund Policy:
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 Quality Guarantee
//               </div>
//               <div className="mt-2">
//                 If the same problem, for which the Service Request was raised
//                 and addressed, occurs again within 3 months of the closure of
//                 the Service Request, it can be taken care of at no additional
//                 cost. We are committed to ensuring that your electronic device
//                 issues are resolved to your satisfaction.
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 Refund Process
//               </div>
//               <div className="mt-2">
//                 Refunds, if applicable, will be processed within 7-10 business
//                 days from the date of approval. We will refund the amount using
//                 the same payment method you used to make the initial payment.
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 Refund Exemptions
//               </div>
//               <div className="mt-2">
//                 Refunds will not be issued for services that were not performed
//                 by the technicians or for any damage caused by the consumer.
//                 Please note that refunds are subject to the terms and conditions
//                 outlined in our Refunds and Cancellation policy.
//               </div>
//             </div>
//             <div>
//               <div className="mt-2">
//                 To request a refund, please contact our customer support team at
//                 support@s3-app.com. We are committed to resolving any issues and
//                 ensuring your satisfaction with our services.
//               </div>
//             </div>
//           </div>
//         </div>
//       </Modal>
//       <Modal
//         show={tatModal}
//         onHide={() => setTATModal(false)}
//         size="xl"
//         centered
//       >
//         <div
//           className="pt-3 pb-3 ps-5 pe-5"
//           style={{ background: "#EDEDED", borderRadius: "50px 50px 0 0" }}
//         >
//           <div className="d-flex align-items-center justify-content-space-between">
//             <div style={{ fontSize: "25px", fontWeight: "600" }}>
//               Delivery and Shipping Policy with Logistic Partner Details and TAT
//               (Turn Around Time)
//             </div>
//             <div
//               style={{ fontSize: "20px", cursor: "pointer" }}
//               onClick={() => setTATModal(false)}
//             >
//               <AiOutlineClose />
//             </div>
//           </div>
//         </div>
//         <div className="mt-0 m-5 privacy-policy">
//           <div>
//             <div className="mt-4">
//               <div>
//                 At S3, we offer expert technician services right at your
//                 doorstep to take care of your valuable electronic devices. To
//                 provide you with transparency regarding our delivery and
//                 shipping policies, as well as our turnaround time (TAT), we've
//                 outlined the details below:
//               </div>
//             </div>
//           </div>
//           <div className="mt-4">
//             <div
//               className="heading"
//               style={{ fontSize: "20px", fontWeight: "900" }}
//             >
//               Service Delivery and Shipping:
//             </div>
//           </div>
//           <div className="mt-4">
//             <div
//               className="heading"
//               style={{ fontSize: "20px", fontWeight: "900" }}
//             >
//               Technician Home Visit
//             </div>
//             <div className="mt-2">
//               When you raise a service request for a specific time slot, our
//               technician will be sent to your home to attend to the complaint.
//               We aim to provide prompt and reliable service to ensure the timely
//               resolution of your electronic device issues.
//             </div>
//           </div>
//           <div className="mt-4">
//             <div
//               className="heading"
//               style={{ fontSize: "20px", fontWeight: "900" }}
//             >
//               Basic Visit Charge
//             </div>
//             <div className="mt-2">
//               A basic visit charge will be applied for the technician's visit to
//               your location. The charge may vary depending on the size and type
//               of the appliance being serviced. You will be informed of this
//               charge when you schedule your service request.
//             </div>
//           </div>
//           <div className="mt-4">
//             <div
//               className="heading"
//               style={{ fontSize: "20px", fontWeight: "900" }}
//             >
//               Spare Parts and Service Charges
//             </div>
//             <div className="mt-2">
//               If our technician identifies the need to replace spare parts or
//               perform additional efforts to fix your electronic device, you will
//               receive a detailed quote for these services. The cost will be
//               determined based on the complexity of the task and the cost of the
//               required parts.
//             </div>
//           </div>
//           <div className="mt-4">
//             <div
//               className="heading"
//               style={{ fontSize: "20px", fontWeight: "900" }}
//             >
//               Turn Around Time (TAT):
//             </div>
//           </div>
//           <div className="mt-4">
//             <div
//               className="heading"
//               style={{ fontSize: "20px", fontWeight: "900" }}
//             >
//               Service Timeframe
//             </div>
//             <div className="mt-2">
//               We strive to complete most service requests during the
//               technician's visit to your location. However, if spare parts need
//               to be ordered or additional efforts are required, the turnaround
//               time (TAT) may vary.
//             </div>
//           </div>
//           <div className="mt-4">
//             <div
//               className="heading"
//               style={{ fontSize: "20px", fontWeight: "900" }}
//             >
//               TAT Communication
//             </div>
//             <div className="mt-2">
//               Our technician will provide you with an estimated TAT when
//               presenting the quote for spare parts or additional efforts. We
//               will keep you informed of the progress and any changes to the TAT
//               throughout the service process.
//             </div>
//           </div>
//           <div className="mt-4">
//             <div
//               className="heading"
//               style={{ fontSize: "20px", fontWeight: "900" }}
//             >
//               Logistic Partner Details:
//             </div>
//             <div className="mt-2">
//               We collaborate with trusted logistics partners to ensure the
//               efficient delivery of spare parts and other necessary components.
//               Our logistics partners are chosen for their reliability and
//               commitment to timely deliveries.
//             </div>
//             <div className="mt-2">
//               For specific logistic partner details or to track your spare parts
//               delivery, please contact our customer support team at
//               support@s3-app.com.
//             </div>
//             <div className="mt-2">
//               We understand the importance of your electronics devices, and we
//               are dedicated to providing you with a hassle-free and efficient
//               service experience. If you have any questions or concerns
//               regarding our delivery, shipping policies, or TAT, please don't
//               hesitate to reach out to us. Your satisfaction is our priority,
//               and we are here to assist you in every way possible.
//             </div>
//           </div>
//         </div>
//       </Modal>
//       <Modal
//         show={productPricingModal}
//         onHide={() => setProductPricingModal(false)}
//         size="xl"
//         centered
//       >
//         <div
//           className="pt-3 pb-3 ps-5 pe-5"
//           style={{ background: "#EDEDED", borderRadius: "50px 50px 0 0" }}
//         >
//           <div className="d-flex align-items-center justify-content-space-between">
//             <div style={{ fontSize: "25px", fontWeight: "600" }}>
//               Product Pricing
//             </div>
//             <div
//               style={{ fontSize: "20px", cursor: "pointer" }}
//               onClick={() => setProductPricingModal(false)}
//             >
//               <AiOutlineClose />
//             </div>
//           </div>
//         </div>
//         <div className="mt-0 m-5 privacy-policy">
//           <div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 Service Charges
//               </div>
//             </div>
//             <div className="mt-2">
//               <div>
//                 Our pricing is transparent and competitive, ensuring you receive
//                 the best value for our expert services. Charges for devices are
//                 based on the size of the appliance. For example:
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 LED TV Service Charge:
//               </div>
//               <div>
//                 <span style={{ fontSize: "18px" }}>•</span> 24" = ₹349/-
//               </div>
//               <div>
//                 <span style={{ fontSize: "18px" }}>•</span> 32" = ₹499/-
//               </div>
//               <div>
//                 <span style={{ fontSize: "18px" }}>•</span> 40" To 43" = ₹799/-
//               </div>
//               <div>
//                 <span style={{ fontSize: "18px" }}>•</span> 50" To 55" = ₹999/-
//               </div>
//               <div>
//                 <span style={{ fontSize: "18px" }}>•</span> Above 55" = ₹1499/-
//               </div>
//             </div>
//             <div className="mt-4">
//               <div
//                 className="heading"
//                 style={{ fontSize: "20px", fontWeight: "900" }}
//               >
//                 CRT TV Service Charge:
//               </div>
//               <div>
//                 <span style={{ fontSize: "18px" }}>•</span> 14" CRT Tv = ₹199/-
//               </div>
//               <div>
//                 <span style={{ fontSize: "18px" }}>•</span> 20"&21" CRT Tv =
//                 ₹299/-
//               </div>
//               <div>
//                 <span style={{ fontSize: "18px" }}>•</span> 29" CRT Tv = ₹399/-
//               </div>
//             </div>
//           </div>
//         </div>
//       </Modal>
//       <Modal
//         show={contactUsModal}
//         onHide={() => setContactUsModal(false)}
//         size="xl"
//         centered
//       >
//         <div
//           className="pt-3 pb-3 ps-5 pe-5"
//           style={{ background: "#EDEDED", borderRadius: "50px 50px 0 0" }}
//         >
//           <div className="d-flex align-items-center justify-content-space-between">
//             <div style={{ fontSize: "25px", fontWeight: "600" }}>
//               Contact Us
//             </div>
//             <div
//               style={{ fontSize: "20px", cursor: "pointer" }}
//               onClick={() => setContactUsModal(false)}
//             >
//               <AiOutlineClose />
//             </div>
//           </div>
//         </div>
//         <div className="mt-0 m-5 privacy-policy">
//           <div>
//             <div className="mt-5">
//               <div>
//                 Your feedback and inquiries are vital to us. Reach out to us
//                 through:
//               </div>
//               <div className="mt-4">
//                 <span>•</span> <span style={{ fontWeight: "600" }}>Email:</span>{" "}
//                 support@s3-app.com
//               </div>
//               <div className="mt-2">
//                 <span>•</span> <span style={{ fontWeight: "600" }}>Phone:</span>{" "}
//                 +91-96003 00459
//               </div>
//               <div className="mt-2">
//                 <span>•</span>{" "}
//                 <span style={{ fontWeight: "600" }}>Address:</span> 20/2,
//                 Vaiyapuri Nagar,Second Cross, Karur, Karur, Tamilnadu, 639002
//               </div>
//             </div>
//           </div>
//         </div>
//       </Modal>
//       <Modal
//         show={deleteModal}
//         onHide={() => setDeleteModal(false)}
//         size="md"
//         centered
//       >
//         <div
//           className="mt-3"
//           style={{ textAlign: "center", fontSize: "21px", fontWeight: "600" }}
//         >
//           Delete Account?
//         </div>
//         <form className="p-4 pt-3" onChange={handleDeleteModal}>
//           <div className="mt-3">
//             <TextField
//               label="Enter your email"
//               style={{ width: "100%" }}
//               onChange={(e) => setEmail(e.target.value)}
//               type="email"
//             />
//           </div>
//           <div className="mt-3">
//             <TextField
//               label="Enter your password"
//               style={{ width: "100%" }}
//               onChange={(e) => setPassword(e.target.value)}
//               type="password"
//             />
//           </div>
//           <div className="mt-4">
//             <div style={{ width: "100%" }}>
//               <button
//                 type="submit"
//                 style={{ height: "40px", width: "100%" }}
//                 className={` ${
//                   email && password ? "del-acc" : "disable-del-acc"
//                 }`}
//               >
//                 Delete Accoount
//               </button>
//             </div>
//             <div style={{ width: "100%" }} className="mt-2">
//               <button
//                 type="button"
//                 style={{ height: "40px", width: "100%" }}
//                 onClick={() => setDeleteModal(false)}
//                 className="can-del-acc"
//               >
//                 Cancel
//               </button>
//             </div>
//           </div>
//         </form>
//       </Modal>
//       <Modal
//         show={alertModal}
//         onHide={() => setalertModal(false)}
//         centered
//         className="p-1"
//       >
//         <div className="text-1">
//           Are you sure you want to delete your account from S3?
//         </div>
//         <div className="text-2">This action cannot be undone.</div>
//         <div className="button-class">
//           <button onClick={() => setalertModal(false)} className="cancel">
//             Cancel
//           </button>
//           <button
//             onClick={() => {
//               handleDelete();
//               setalertModal(false);
//             }}
//             className="reject"
//           >
//             Delete
//           </button>
//         </div>
//       </Modal>
//     </>
//   );
// };
// export default Footer;

import React from "react";
import { FaTwitter } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";

import { useLocation, useNavigate } from "react-router-dom";
import S3NewLogo from "../Assets/s3_new_logo/S3NewLogo";
import app_store from "../Assets/Home/App-store.svg";
import play_store from "../Assets/Home/Play-store.svg";
import TopScroller from "./home/TopScroller";

const Footer = ({ topscroller }) => {
  // const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleScrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  const NotShowNewsLetter = [];

  const isNotShowFooter = () => {
    if (NotShowNewsLetter.some((route) => route === pathname)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {!topscroller && (
        <div className={`${isNotShowFooter() ? "d-none" : ""}  container`}>
          <TopScroller />
        </div>
      )}

      <div className="footer">
        <div className="footer-container res-849px-d-block">
          <div className="brand">
            <div className="footer-logo-mobile">
              <S3NewLogo color="white" />
            </div>
            <div className="details">
              A friendly neighbourhood handyman and customers satisfied with
              instant service.
            </div>
            <div
              style={{
                display: "flex",
                gap: "16px",
                color: "rgba(125, 129, 141, 1)",
                marginTop: "30px",
              }}
              className="links-flex"
            >
              <FaTwitter />
              <FaFacebookSquare />
              <FaInstagram />
              <FaLinkedin />
            </div>
          </div>
          <div className="quick-links">
            <div className="heading">Company</div>
            <div className="links-container">
              <div
                className="links"
                onClick={() => {
                  handleScrollToTop();
                  navigate("/about");
                }}
              >
                <span>About us</span>
              </div>
              <div
                className="links"
                onClick={() => {
                  handleScrollToTop();
                  navigate("/Contact-us");
                }}
              >
                <span>Contact us</span>
              </div>
              {/* <div className="links">
                <span>Careers</span>
              </div>
              <div className="links">
                <span>Press</span>
              </div> */}
            </div>
          </div>
          <div className="support">
            <div className="heading">Services</div>
            <div className="links-container">
              <div
                className="links"
                onClick={() => {
                  handleScrollToTop();
                  navigate("/tv-service");
                }}
              >
                <span>Television/TV</span>
              </div>
              <div
                className="links"
                onClick={() => {
                  handleScrollToTop();
                  navigate("/refrigerator-service");
                }}
              >
                <span>Refrigerator</span>
              </div>
              <div
                className="links"
                onClick={() => {
                  handleScrollToTop();
                  navigate("/washing-machine-service");
                }}
              >
                <span>Washing Machine</span>
              </div>
              <div
                className="links"
                onClick={() => {
                  handleScrollToTop();
                  navigate("/dth-service");
                }}
              >
                <span>DTH</span>
              </div>
            </div>
          </div>
          <div className="support">
            <div className="heading">Legal</div>
            <div className="links-container">
              <div
                className="links"
                onClick={() => {
                  handleScrollToTop();
                  navigate("/privacy-policy");
                }}
              >
                <span>Privacy Policy</span>
              </div>
              <div
                className="links"
                onClick={() => {
                  handleScrollToTop();
                  navigate("/terms-of-use");
                }}
              >
                <span>Terms of Use</span>
              </div>
              <div
                className="links"
                onClick={() => {
                  handleScrollToTop();
                  navigate("/delete-account");
                }}
              >
                <span>Delete Account</span>
              </div>
            </div>
          </div>
          <div className="mail">
            <div className="heading">Download Our App</div>
            <div
              style={{
                display: "flex",
                // marginTop: "16px",
                flexDirection: "column",
              }}
              className="app-play-store res-576px-r-0"
            >
              {/* <img
                alt="app_store"
                src={app_store}
                style={{ height: "50px", width: "130px" }}
              /> */}
              <img
                className="footer-play-store"
                alt="play_store"
                src={play_store}
                style={{ height: "50px", width: "130px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
