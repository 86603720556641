import React from "react";
import {
  EndToEndIcon,
  OpenDocIcon,
  UnifiedIcon,
} from "../../Assets/Home/Features";
const MoreFeatures = () => {
  return (
    <div className="more-features-container">
      <div
        style={{ display: "flex", gap: "20px", width: "33.3%" }}
        className="res-576px-mt-20px res-576px-w-fill res-576px-flex-direction-column res-576px-text-align-center res-576px-gap-4"
      >
        <div className="res-576px-d-flex res-576px-w-fill res-576px-justify-content-center res-576px-align-items-center">
          <EndToEndIcon
            style={{ position: "relative", top: "-12px", scale: "0.8" }}
            className="res-576px-scale-1-h"
          />
        </div>
        <div>
          <div style={{ fontWeight: 600, fontSize: "16px" }}>
            End-to-end support
          </div>
          <div
            className="mt-2"
            style={{ color: "rgba(26, 26, 26, 0.7)", fontSize: "14px" }}
          >
            Rely on us for any home, shop, or office appliance, our technicians
            will help with repair, installation, and uninstallation.
          </div>
        </div>
      </div>
      <div
        style={{ display: "flex", gap: "20px", width: "33.3%" }}
        className=" res-576px-mt-20px res-576px-w-fill res-576px-flex-direction-column res-576px-text-align-center res-576px-gap-4"
      >
        <div className="res-576px-d-flex res-576px-justify-content-center res-576px-align-items-center">
          <OpenDocIcon
            style={{ position: "relative", top: "-14px", scale: "0.7" }}
            className="res-576px-scale-1-4"
          />
        </div>
        <div>
          <div style={{ fontWeight: 600, fontSize: "16px" }}>
            Open and Transparent
          </div>
          <div
            className="mt-2"
            style={{ color: "rgba(26, 26, 26, 0.7)", fontSize: "14px" }}
          >
            That is our policy. Whether it is service reviews, service rates, or
            assigned technicians, we display it all fair and square.
          </div>
        </div>
      </div>
      <div
        style={{ display: "flex", gap: "20px", width: "33.3%" }}
        className="res-576px-mt-20px res-576px-w-fill res-576px-flex-direction-column res-576px-text-align-center res-576px-gap-6"
      >
        <div className="res-576px-d-flex res-576px-justify-content-center res-576px-align-items-center">
          <UnifiedIcon
            className="res-576px-scale-2"
            style={{ position: "relative", top: "-2px", scale: "1.3" }}
          />
        </div>
        <div>
          <div style={{ fontWeight: 600, fontSize: "16px" }}>
            Unified Thinking
          </div>
          <div
            className="mt-2"
            style={{ color: "rgba(26, 26, 26, 0.7)", fontSize: "14px" }}
          >
            We are a crowdsourced group of Technicians, led by Technicians!
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreFeatures;
