import React, { useEffect } from "react";
import Header from "./Header";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";

export default function ProductPricing() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);
  return (
    <div>
      <div className="sticky-top">
        <Header />
      </div>
      <div
        style={{
          width: "100%",
          backgroundColor: "#F8F8F8",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "30px",
          paddingBottom: "30px",
        }}
      >
        <div
          style={{
            padding: 50,
            width: "90%",
            backgroundColor: "white",
            boxShadow: "0 0 10px 1px #00000036",
          }}
        >
          <h5 style={{ marginTop: 0, textAlign: "center" }}>
            <b>PRODUCT PRICING, REFUNDS, AND CANCELLATION POLICY</b>
          </h5>
          <div style={{ fontSize: 18, marginTop: 30 }}>
            <b>Product Pricing</b>
          </div>
          <div style={{ fontSize: 17, marginTop: 20 }}>
            <b>Service Charges</b>
          </div>
          <div style={{ marginTop: 20 }}>
            At S3, we are committed to delivering transparent and competitive
            pricing, ensuring that our valued customers receive the utmost value
            for our expert services. Our service charges for devices are based
            on the size of the appliance, with a current focus on Televisions.
            Please find the detailed visiting charges below, recognizing that these
            rates may be subject to periodic adjustments{" "}
          </div>
          <div style={{ marginTop: 30 }}>
            <b>LED and LCD TV Service Charge</b>
          </div>
          <ul style={{ marginTop: 20 }}>
            <li style={{ marginTop: 10 }}>24" = ₹349/- </li>
            <li style={{ marginTop: 10 }}>32" = ₹499/- </li>
            <li style={{ marginTop: 10 }}>40" To 43" = ₹799/- </li>
            <li style={{ marginTop: 10 }}>50" To 55" = ₹999/- </li>
            <li style={{ marginTop: 10 }}>Above 55" = ₹1499/- </li>
          </ul>
          <div style={{ marginTop: 30 }}>
            <b>CRT TV Service Charge</b>
          </div>
          <ul style={{ marginTop: 20 }}>
            <li style={{ marginTop: 10 }}>14" CRT TV = ₹199/- </li>
            <li style={{ marginTop: 10 }}>20" & 21" CRT TV = ₹299/- </li>
            <li style={{ marginTop: 10 }}>40" To 43" = ₹799/- </li>
            <li style={{ marginTop: 10 }}>50" To 55" = ₹999/- </li>
            <li style={{ marginTop: 10 }}>29" CRT TV = ₹399/- </li>
          </ul>
          <div style={{ marginTop: 30 }}>
            <b>Refunds and Cancellation</b>
          </div>
          <div style={{ marginTop: 20 }}>
            At S3, customer satisfaction is our paramount concern. While we
            strive to provide impeccable service for your electronic devices, we
            recognize that there may be instances necessitating Service Request
            (SR) cancellations or refunds. Please acquaint yourself with our
            comprehensive Refunds and Cancellation policy outlined below:{" "}
          </div>
          <div style={{ marginTop: 30 }}>
            <b>Cancellation Policy</b>
          </div>
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            Service Request Cancellation: Customers have the flexibility to edit
            or cancel a Service Request until the day preceding the scheduled
            visit by utilizing the cancel button on the Service Request page.
            For same-day Service Requests, cancellations can be made until the
            technician accepts the request. Once accepted, mutual agreement with
            the technician is required for any changes to the time slot.{" "}
          </div>
          <span>
            <b>Cancellation Charges:</b>{" "}
          </span>
          <span>
            Understanding that circumstances may change, we have revised our
            cancellation charges to provide flexibility to our customers:
          </span>
          <ul style={{ marginTop: 20 }}>
            <li style={{ marginTop: 10 }}>
              If the customer Cancels the Service Request until 2 hours before
              the scheduled visit, No cancellation fee is applied.{" "}
            </li>
            <li style={{ marginTop: 10 }}>
              If no technician is assigned until the requested time slot, No
              cancellation fee is applied, the customer is free to cancel the
              Service Request at any time.{" "}
            </li>
            <li style={{ marginTop: 10 }}>
              If the customer attempts to cancel the service request within 2
              hours of the scheduled visit after the technician has accepted,
              the cancellation will not be permitted, and the customer must pay
              the visiting charge.{" "}
            </li>
          </ul>
          <div style={{ fontSize: 18, marginTop: 30 }}>
            <b>Refund Policy</b>
          </div>
          <div style={{ marginTop: 20 }}>
            <b>Quality Guarantee:</b> Should the same issue addressed in a
            Service Request recur within 30 days of closure, we will address it
            at no additional cost, demonstrating our commitment to resolving
            electronic device issues to your satisfaction.{" "}
          </div>
          <div style={{ marginTop: 20 }}>
            <b>Refund Process:</b> Refunds, if applicable, will be processed
            within 7-10 business days from approval. The refund amount will be
            credited to the same payment method used for the initial payment.{" "}
          </div>
          <div style={{ marginTop: 20 }}>
            <b>Refund Exemptions: </b>Refunds will not be issued for services
            not performed by technicians or for any damage caused by the
            consumer. Please note that refunds are subject to the terms and
            conditions outlined in our Refunds and Cancellation policy.{" "}
          </div>
          <div style={{ marginTop: 20 }}>
            To initiate a refund request, kindly reach out to our customer
            support team at support@S3-app.com. We are dedicated to promptly
            addressing any concerns and ensuring your satisfaction with our
            services.{" "}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
