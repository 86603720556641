import { Card, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import InputMask from "react-input-mask";
import InputAdornment from "@mui/material/InputAdornment";
import visa from "../../Images/visa.png";
import Topbar from "../topbar";
import Sidebar from "../Sidebar";

const CustomerSaveCard = () => {
  return (
    <div className="container-fluid" style={{ overflow: "hidden" }}>
      <Topbar />
      <div className="row">
        {/* Sidebar */}
        <div className="col-lg-2 col-12 p-0 ">
          <Sidebar open={true} />
        </div>

        {/* Main Content */}
        <div className="col-lg-10 p-0 Main_menu" style={{ paddingTop: "15px" }}>
          <div className="container-fluid">
            <ul style={{ display: "inline-flex" }}>
              <li style={{ listStyle: "none" }}>
                <i
                  className="bi bi-chevron-left me-2"
                  style={{ fontWeight: "500", fontSize: "12px" }}
                ></i>
              </li>
              <li style={{ listStyle: "none" }}>
                <p style={{ fontWeight: "600", fontSize: "21px" }}>
                  Saved Cards
                </p>
              </li>
            </ul>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 col-12">
                  <Card>
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                          <div
                            id="carouselExampleIndicators2"
                            className="carousel slide"
                          >
                            <div className="carousel-inner">
                              <div className="carousel-item active">
                                <div className="card ">
                                  <div
                                    className="w-100 p-lg-3 p-2"
                                    style={{
                                      height: "120px",
                                      backgroundColor: "#EB8128",
                                      color: "white",
                                    }}
                                  >
                                    <div className="text-end">
                                      <i className="bi bi-trash fs-5 me-2"></i>
                                    </div>
                                    <div className="text-start">
                                      <p
                                        className="m-0"
                                        style={{ fontSize: "16px" }}
                                      >
                                        **** **** ****3090
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    className="w-100 p-lg-3 p-2"
                                    style={{
                                      height: "90px",
                                      backgroundColor: "#FFE5CC",
                                      color: "#EB8128",
                                    }}
                                  >
                                    <div className="text-start">
                                      <p
                                        className="m-0"
                                        style={{ fontSize: "16px" }}
                                      >
                                        Customer Name
                                        <br />
                                        09/24
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="carousel-item">
                                <div className="card ">
                                  <div
                                    className="w-100 p-lg-3 p-2"
                                    style={{
                                      height: "120px",
                                      backgroundColor: "#5493FB",
                                      color: "white",
                                    }}
                                  >
                                    <div className="text-end">
                                      <i className="bi bi-trash fs-5 me-2"></i>
                                    </div>
                                    <div className="text-start">
                                      <p
                                        className="m-0"
                                        style={{ fontSize: "16px" }}
                                      >
                                        **** **** ****3090
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    className="w-100 p-lg-3 p-2"
                                    style={{
                                      height: "90px",
                                      backgroundColor: "#FFE5CC",
                                      color: "#5493FB",
                                    }}
                                  >
                                    <div className="text-start">
                                      <p
                                        className="m-0"
                                        style={{ fontSize: "16px" }}
                                      >
                                        Customer Name
                                        <br />
                                        09/24
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-10"></div>
                        <div className="col-2">
                          <Stack className="" direction="row" spacing={1}>
                            <IconButton
                              aria-label="fingerprint"
                              href="#carouselExampleIndicators2"
                              role="button"
                              color="secondary"
                              data-bs-slide="prev"
                            >
                              <ChevronLeftIcon />
                            </IconButton>
                            <IconButton
                              aria-label="fingerprint"
                              color="success"
                              href="#carouselExampleIndicators2"
                              role="button"
                              data-bs-slide="next"
                            >
                              <ChevronRightIcon />
                            </IconButton>
                          </Stack>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col text-start">
                          <p style={{ fontWeight: "600", fontSize: "16px" }}>
                            Card Info
                          </p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col text-start">
                          <p
                            style={{
                              fontWeight: "600",
                              fontSize: "16px",
                              color: "#6A707C",
                            }}
                          >
                            Card Number
                          </p>
                        </div>
                        <div className="col text-end">
                          <p style={{ fontWeight: "600", fontSize: "16px" }}>
                            **** **** **** 3090
                          </p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col text-start">
                          <p
                            style={{
                              fontWeight: "600",
                              fontSize: "16px",
                              color: "#6A707C",
                            }}
                          >
                            Status
                          </p>
                        </div>
                        <div className="col text-end">
                          <p style={{ fontWeight: "600", fontSize: "16px" }}>
                            Active
                          </p>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>

                <div className="col-lg-6 col-12 mt-lg-0 mt-3">
                  <Card className="pb-lg-3 pb-2 mt-lg-1">
                    <div className="container">
                      <text
                        className=""
                        style={{ fontSize: "15px", fontWeight: "500" }}
                      >
                        Add Card Details
                      </text>

                      <InputMask
                        mask="9999 - 9999 - 9999 - 9999"
                        maskChar="X"
                        formatChars={{ "9": "[0-9]" }}
                      >
                        {() => (
                          <TextField
                            fullWidth
                            className=" mt-lg-4 mt-3"
                            id="outlined-basic"
                            defaultValue="4731 - "
                            label="Card Number"
                            variant="outlined"
                            placeholder="XXXX - XXXX - XXXX - XXXX"
                            // error={Iscard ? true : false}
                            // helperText={Iscard ? "Please Type Your Card Details" : null}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  style={{ color: "red" }}
                                  position="start"
                                  className="text-primary"
                                >
                                  <img src={visa} alt="Visa Card" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      </InputMask>

                      <div className="row mt-3 mt-lg-4">
                        <div className="col-6">
                          <TextField
                            fullWidth
                            label="Expiry Date"
                            id="fullWidth"
                            placeholder="MM / YYYY"
                          />
                        </div>
                        <div className="col-6">
                          <TextField
                            fullWidth
                            label="CVV Number"
                            id="fullWidth"
                            placeholder="XXX"
                          />
                        </div>
                      </div>

                      <TextField
                        className="mt-3 mt-lg-4"
                        fullWidth
                        label="Card Holder Name"
                        placeholder="Enter card holder name"
                      />

                      <div className="mt-3 mt-lg-4">
                        <input
                          style={{ borderRadius: "10px" }}
                          class="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox1"
                          value="option1"
                        />
                        <text
                          className="ms-3"
                          style={{
                            fontSize: "15px",
                            fontWeight: "400",
                            color: "#6A707C",
                          }}
                        >
                          Accept to save card as per the RBI Regulations
                        </text>
                      </div>

                      <button
                        class="btn mt-lg-4 mt-3"
                        type="button"
                        style={{
                          width: "138px",
                          height: "45px",
                          color: "white",
                          backgroundColor: "#EB8128",
                          borderColor: "#EB8128",
                        }}
                      >
                        Save Card
                      </button>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CustomerSaveCard;
