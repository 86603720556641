import { MenuItem, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";

const SearchableDropdown = ({
  options,
  label,
  id,
  selectedVal,
  handleChange,
  type,
}) => {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, []);

  const selectOption = (option) => {
    const selectedAdmin = {
      id: option.id,
      zone_name: `${option[label]}`,
    };
    setQuery("");
    handleChange(selectedAdmin);
    setIsOpen(false);
  };

  function toggle(e) {
    setIsOpen(e && e.target === inputRef.current);
  }

  const getDisplayValue = () => {
    if (query) return query;
    if (selectedVal) return selectedVal;
    return "";
  };

  const filter = (options) => {
    return options.filter(
      (option) =>
        option[label]?.toLowerCase().indexOf(query?.toLowerCase()) > -1
    );
  };

  return (
    <div className="dropdown-input">
      <div className="control">
        <div className="selected-value">
          <div className="custom-label">
            <div className="label">{type}</div>
          </div>
          <input
            style={{
              height: "52px",
              borderRadius: "5px",
              paddingLeft: "13px",
              paddingRight: "13px",
            }}
            ref={inputRef}
            type="text"
            value={getDisplayValue()}
            name="searchTerm"
            readOnly
            onChange={(e) => {
              handleChange(null);
              setQuery(e.target.value);
            }}
            onClick={toggle}
          />
        </div>
        <div className={`arrow ${isOpen ? "open" : ""}`}></div>
      </div>

      <div className={`options ${isOpen ? "open" : ""}`}>
        {filter(options).map((option, index) => (
          <div
            onClick={() => selectOption(option)}
            className={`option ${option[label] === selectedVal ? "selected" : ""
              }`}
            key={`${id}-${index}`}
          >
            <Tooltip title={`${option[label]}`} arrow>
              <span className="text-start text-content-elipsis">
                {`${option[label]}`}
              </span>
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchableDropdown;
