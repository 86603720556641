import axios from "axios";
// import { DRAW_SERVICE_BASE_URL } from "../../api/apiBaseUrl";
import { BASE_URL } from "../../api/ApiPath";
import { UpdateRewardRequest, UpdateRewardFail, UpdateRewardSuccess } from "../slices/updateRewardSlice";
export const UpdateReward = (payload) => async (dispatch) => {
    const token = localStorage.getItem("token");
    // let payload = id ? `draw_id=${id}&startingNumber=0000&limit=9999&onlySoldOut=false` : '';

    try {
        dispatch(UpdateRewardRequest())
        const response = await axios.patch(`${BASE_URL}/reward`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        dispatch(UpdateRewardSuccess(response?.data))
        console.log("UpdateRewardLists", response?.data);
    } catch (error) {
        console.log(error);
        dispatch(UpdateRewardFail(error?.response?.data))
    }
}
