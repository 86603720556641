import React from 'react'
import Topbar from '../topbar'
import Sidebar from '../Sidebar'

const TechnicianLandingPage = () => {
    return (
        <div className="container-fluid">
            <Topbar />
            <div className="row">
                <div className="col-lg-2 col-12 p-0 border border-right">
                    <Sidebar />
                </div>
                <div className="col-lg-10 col-12 technician_landing  d-flex align-items-center justify-content-center welcome-greet">
                    <div className=''>
                        <div className='text-align-center'>Welcome to S3, a platform that helps you grow.</div>
                        <div className='text-align-center'>We wish you success! </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TechnicianLandingPage