import React from "react";
import about_mission from "../../Assets/Home/about-mission.svg";
import about_mission_mob from "../../Assets/Home/mission-mob.svg";
export default function AboutMission() {
  return (
    <div className="about-services-container-mission">
      <div
        className="second-flex-img res-576px-d-none"
        style={{ scale: "0.8", marginTop: "-80px" }}
      >
        <img src={about_mission} alt="phone_img" />
      </div>
      <div
        className="second-flex-img res-577px-d-none"
        style={{ scale: "1.1", marginleft: "10%" }}
      >
        <img
          className="res-577px-d-none "
          src={about_mission_mob}
          alt="phone_img"
        />
      </div>
      <div className="first-flex-service">
        <div
          style={{ fontWeight: 600, fontSize: "34px" }}
          className="res-576px-font-25px our-vision-heading "
        >
          Our Mission
        </div>
        <div
          style={{
            fontSize: "15px",
            color: "rgba(26, 26, 26, 0.7)",
            marginTop: "30px",
            lineHeight: "24px",
          }}
        >
          Keep it secure, simple, and efficient – Connect skilled services,
          solutions, and product providers to consumers directly and
          transparently.
        </div>
        <div
          style={{
            fontSize: "14px",
            color: "rgba(26, 26, 26, 0.7)",
            marginTop: "10px",
          }}
        >
          The crux of our existence is to ensure accessibility without
          overheads. Consumers should have access to all relevant information to
          make informed decisions. Service providers and manufacturers should be
          able to interact with customers directly. This facilitates quick and
          seamless feedback, leading to ingrained continuous improvement.
        </div>
      </div>
    </div>
  );
}
