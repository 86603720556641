import React, { useEffect, useState } from "react";
import Api from "../../../../service/services";
import { CiShoppingCart } from "react-icons/ci";

export default function CartIcon({ isAddedCartItem }) {
  const [count, setCount] = useState(0);
  useEffect(() => {
    console.log(isAddedCartItem, "isAddedCartItem");
    Api.cartLists()
      .then((res) => {
        console.log("cartlistsss", res);
        // setCount(res?.cart?.length);
        const total = res.cart.reduce((acc, item) => {
          return acc + item?.quantity;
        }, 0);
        setCount(total);
      })
      .catch((err) => console.log(err));
  }, [isAddedCartItem]);
  return (
    <div>
      <div style={{ position: "relative" }}>
        <CiShoppingCart size={32} />
      </div>
      <div
        style={{
          position: "absolute",
          right: "-5px",
          top: "1px",
          width: "20px",
          height: "20px",
          borderRadius: "50%",
          backgroundColor: "rgb(235, 129, 40) ",
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid rgb(235, 129, 40)",
        }}
      >
        {count}
      </div>
    </div>
  );
}
