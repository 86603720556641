import * as React from 'react';
import { AppBar, Tabs, Tab, Typography, Box, MenuItem, Menu, Stack } from '@mui/material';
const InventoryAlertdashboard = () => {
    const [selectedTab1, setSelectedTab1] = React.useState(0);
    const [anchorEl, setAnchorEl1] = React.useState(null);

    const handleChange = (event, newValue) => {
        setSelectedTab1(newValue);
    };

    const TabPanel = ({ value, index, children }) => {
        return (
            <div role="tabpanel" hidden={value !== index}>
                {value === index && <Box p={3}>{children}</Box>}
            </div>
        );
    };
    const handleDropdownClick = (event) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleDropdownClose = () => {
        setAnchorEl1(null);
    };
    return (
        <div className='container-fluid' style={{ overflow: 'hidden' }}>

            <div className='row'>

                <div className='col-lg-12 p-0 Main_menu' style={{ paddingTop: '15px' }}>
                    <div className="container-fluid" style={{ padding: '1px' }} >
                        <div className='mt-lg-3 ms-lg-4'>
                            <text className="p-0" style={{ fontWeight: '600', fontSize: '16px', width: '208px', height: '32px' }}>Inventory Alert</text>
                        </div>

                        <div className='mt-lg-3'  >
                            <AppBar style={{ boxShadow: 'none' }} position="static">
                                <Tabs value={selectedTab1} onChange={handleChange} variant="fullWidth" style={{}}>
                                    <Tab style={{ fontSize: '13px', backgroundColor: 'white' }} label="Shortage" />
                                    <Tab style={{ fontSize: '13px', backgroundColor: 'white' }} label="Below 5" />
                                    <Tab style={{ fontSize: '13px', backgroundColor: 'white' }} label="Below 20" />

                                </Tabs>
                            </AppBar>
                            <TabPanel value={selectedTab1} index={0}>
                                <Stack className='ms-lg-4 mb-lg-4 mt-lg-1' direction='row' spacing={34}>
                                    <p style={{ fontSize: '13px', color: '#6A707C' }}><b>SKU</b></p>
                                    <p style={{ fontSize: '13px', color: '#6A707C' }}><b>Quantity</b></p>
                                </Stack>

                                <Stack className='ms-lg-4 mb-lg-4' direction='row' spacing={30}>
                                    <p style={{ fontSize: '13px' }}>#SKU56976</p>
                                    <p style={{ fontSize: '13px' }}><i class="bi bi-arrow-down" style={{ color: '#DC2626' }}></i>  120</p>
                                </Stack>

                                <Stack className='ms-lg-4 mb-lg-4' direction='row' spacing={30}>
                                    <p style={{ fontSize: '13px' }}>#SKU56976</p>
                                    <p style={{ fontSize: '13px' }}><i class="bi bi-arrow-down" style={{ color: '#DC2626' }}></i>  89</p>
                                </Stack>

                                <Stack className='ms-lg-4 mb-lg-4' direction='row' spacing={30}>
                                    <p style={{ fontSize: '13px' }}>#SKU56976</p>
                                    <p style={{ fontSize: '13px' }}><i class="bi bi-arrow-down" style={{ color: '#DC2626' }}></i>  89</p>
                                </Stack>

                                <Stack className='ms-lg-4 mb-lg-4' direction='row' spacing={30}>
                                    <p style={{ fontSize: '13px' }}>#SKU56976</p>
                                    <p style={{ fontSize: '13px' }}><i class="bi bi-arrow-down" style={{ color: '#DC2626' }}></i>  89</p>
                                </Stack>

                                <Stack className='ms-lg-4 mb-lg-4' direction='row' spacing={30}>
                                    <p style={{ fontSize: '13px' }}>#SKU56976</p>
                                    <p style={{ fontSize: '13px' }}><i class="bi bi-arrow-down" style={{ color: '#DC2626' }}></i>  49</p>
                                </Stack>

                                <Stack className='ms-lg-4 mb-lg-5' direction='row' spacing={30}>
                                    <p style={{ fontSize: '13px' }}>#SKU56976</p>
                                    <p style={{ fontSize: '13px' }}><i class="bi bi-arrow-down" style={{ color: '#DC2626' }}></i>  16</p>
                                </Stack>

                            </TabPanel>
                            <TabPanel value={selectedTab1} index={1}>
                                <div className='h3'>Inventory Alert</div>
                            </TabPanel>
                            <TabPanel value={selectedTab1} index={2}>
                                <div className='h3'>Inventory Alert</div>
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default InventoryAlertdashboard;