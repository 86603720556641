import React, { useRef } from "react";
import tv_card from "../../Assets/Home/tv-card.svg";
import fridge_card from "../../Assets/Home/fridge-card.svg";
import dth_card from "../../Assets/Home/dth-card.svg";
import wm_card from "../../Assets/Home/washing-mac-card.svg";
import { FaArrowRightLong } from "react-icons/fa6";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

export default function Exclusive() {
  const navigate = useNavigate();
  const cardContainerRef = useRef(null);

  const cards = [
    {
      id: 0,
      img: tv_card,
      name: "Television",
      description:
        "Get your picture-perfect television service only at S3! We assure quick and quality services at your doorstep, with no middlemen! Log in now and book your “Top TV Technician in Chennai” ....",
    },
    {
      id: 1,
      img: fridge_card,
      name: "Refrigerator ",
      description:
        "All you need to do is Chill, and we will get your Chiller ready in no time! We assure quick and quality services at your doorstep, with no middlemen! Log in now and book your “Fridge ....",
    },
    {
      id: 2,
      img: wm_card,
      name: "Washing Machine ",
      description:
        "Rock and Roll, dry out all your worries as we maintain your washing machine! We assure quick and quality services at your doorstep, with no middlemen! Log in now and book your “Washing.... ",
    },
    {
      id: 3,
      img: dth_card,
      name: "DTH",
      description:
        "Direct to Home fixed directly at home! Enjoy an uninterrupted amazing viewing experience with S3! We assure quick and quality services at your doorstep, with no middlemen! Log in now and ....",
    },
  ];

  const handleSwipeClick = () => {
    if (cardContainerRef.current) {
      cardContainerRef.current.scrollBy({
        left: 390, // Adjust this value as needed
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="exclusive-container">
      <div className="heading-container">
        <div className="heading">
          Feel the S3 Difference with our Exclusive Services
        </div>
        <BsArrowRight
          className="arrow-right res-576px-d-none"
          style={{ color: "rgba(31, 31, 31, 0.6)" }}
          size={40}
        />
      </div>
      <div className="services-cards-container" ref={cardContainerRef}>
        {cards.map((item, i) => {
          return (
            <div className="services-card" key={i}>
              <img src={item.img} alt={item.img} />
              <div
                style={{
                  marginTop: "-20px",
                  fontWeight: 600,
                  color: "rgba(235, 129, 40, 1)",
                }}
              >
                {item.name}{" "}
              </div>
              <div
                className="mt-2"
                style={{ fontSize: "13px", color: "rgba(26, 26, 26, 0.7)" }}
              >
                {item.description}
              </div>
              <div style={{ display: "flex", gap: "20px", marginTop: "26px" }}>
                <div
                  style={{
                    fontSize: "13px",
                    color: "rgba(26, 26, 26, 0.7)",
                  }}
                >
                  See Details
                </div>
                <FaArrowRightLong
                  style={{ color: "rgba(250, 155, 9, 1)", cursor: "pointer" }}
                  size={20}
                  onClick={() => {
                    if (item.id === 0) {
                      navigate("/tv-service");
                    } else if (item.id === 1) {
                      navigate("/refrigerator-service");
                    } else if (item.id === 2) {
                      navigate("/washing-machine-service");
                    } else if (item.id === 3) {
                      navigate("/dth-service");
                    }
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div
        className="arrow-rightt res-577px-d-none align-items-center d-flex gap-1"
        onClick={handleSwipeClick}
      >
        <div>Swipe</div>
        <BsArrowRight
          className="swipe-arrow"
          style={{ color: "rgba(250, 155, 9, 1)" }}
          size={34}
        />
      </div>
    </div>
  );
}
