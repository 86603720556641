import {
  Avatar,
  Box,
  InputBase,
  InputLabel,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";
import { Paper } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DoneIcon from "@mui/icons-material/Done";
import "../../../layout.css";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ProgressBar from "../../Customers/progress";
import { Link } from "react-router-dom";
import Topbar from "../../topbar";
import Sidebar from "../../Sidebar";
import Api from "../../../service/services";
import { format, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import { Modal } from "react-bootstrap";
import RectIcon from "../../../Assets/icons/RectIcon";
import CalendarIcon from "../../../Assets/icons/CalendarIcon";
import ArrowIconHighlighted from "../../../Assets/icons/ArrowIconHighlighted";
import ArrowIconDefault from "../../../Assets/icons/ArrowIconDefault";
import { Popover } from "react-tiny-popover";
import { orange } from "@mui/material/colors";
import { getNotificationsCount } from "../../../redux/actions/notificationsAction";
import { useDispatch } from "react-redux";
import SpinnerLoader from "../../../plugins/SpinnerLoader";
import {
  MdClose,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from "react-icons/md";
import { IoIosInformationCircleOutline, IoMdPhotos } from "react-icons/io";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FilterModule from "../../../plugins/FilterModule";
import Scrollbars from "react-custom-scrollbars";
import { getQueryParam } from "../../../plugins/getQueryParams";
import SpinnerLoader2 from "../../../plugins/SpinnerLoader2";
import CountContext from "../../countContext/CountContext";
import { useTheme } from "@mui/material/styles";

const debounce = (callback, delay) => {
  let timerId;
  return (...args) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};
const ServiceListManagement = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [hovered, setHovered] = useState(null);
  const [isService, setIsService] = useState(false);
  const [isVisit, setIsVisit] = useState(false);
  /*  const [isInProgressVisit, setisInProgressVisit] = useState(false);
   const [isClosedVisit, setisClosedVisit] = useState(false); */
  const [isClosure, setIsClosure] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [isReopenSR, setIsReopenSR] = useState(true);
  const [SrDetailArray, setSRDetailArray] = useState([]);
  const [openSRList, setOpenSRList] = useState([]);
  const [inprogressSRList, setInprogressSRList] = useState([]);
  const [completeSRList, setCompleteSRList] = useState([]);
  const [serviceRequestId, setServiceRequestId] = useState("");
  const [serviceCreatedTime, setServiceCreatedTime] = useState("");

  const [serviceStatus, setServiceStatus] = useState("");
  const [IsOpenEdit, setIsOpenEdit] = useState(false);
  const navigate = useNavigate();
  const [loadingData, setLoadingData] = useState(false);
  const [IsRepair, setIsRepair] = useState(false);
  const [assignTechnician, setAssignTechnician] = useState("");
  const [eligibleTechniciansList, setEligibleTechniciansList] = useState([]);
  const district_id = localStorage.getItem("district_id");
  const status = getQueryParam("sr_status");
  const srParamsId = getQueryParam("sr_id");
  const [open, setOpen] = useState(true);
  const [inProgress, setInProgress] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [openIndex, setOpenIndex] = useState(0);
  const [serviceListLoader, setServiceListLoader] = useState(false);

  const [viewDetailsPopoverTech, setViewDetailsPopoverTech] = useState(false);
  const [viewDetailsPopoverTechAssign, setViewDetailsPopoverTechAssign] =
    useState(false);
  const [viewDetailsPopoverCustomer, setViewDetailsPopoverCustomer] =
    useState(false);
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [filterData, setFilterData] = useState({});

  ///start of infinity scroll
  const [loadingDataTest, setLoadingDataTest] = useState(false);
  const totalPages = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const nextPage = currentPage + 1;
  const [openSRListDefault, setOpenSRListDefault] = useState([]);
  const [inProgressSRListDefault, setInProgressSRListDefault] = useState([]);
  const [completedSRListDefault, setCompletedSRListDefault] = useState([]);

  const { setSearch, search } = useContext(CountContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleImageClick = (index) => {
    setCurrentIndex(index);
  };
  const showNextImage = () => {
    const nextIndex = (currentIndex + 1) % SrDetailArray[0]?.attachments.length;
    setCurrentIndex(nextIndex);
  };
  const showPreviousImage = () => {
    const previousIndex =
      (currentIndex - 1 + SrDetailArray[0]?.attachments.length) %
      SrDetailArray[0]?.attachments.length;
    setCurrentIndex(previousIndex);
  };

  ///End of infinity scroll

  useEffect(() => {
    setLoadingData(true);
    Api.getDistrictServiceRequestOpenList(searchInput, filterData).then(
      (res) => {
        setLoadingData(false);
        if (res) {
          setHovered(0);
          setOpenSRList(res);
          setServiceRequestId(res.length > 0 && res[0]?.id);
          setSRDetailArray(res.length > 0 && [res[0]]);
          handleEligibleTechnicians(res.length > 0 && res[0]?.id);
          const sr = res.length > 0 && [res[0]];
          if (res.length > 0) {
            formatDate(sr[0]?.created_at);
            setServiceStatus(sr[0]?.status);
          }
        }
      }
    );
  }, []);

  const handleOpenSR = () => {
    setInprogressSRList(inProgressSRListDefault);
    setCompleteSRList(completedSRListDefault);
    setSearchInput("");
    setFilterData({});
    setServiceRequestId("");
    Api.getDistrictServiceRequestOpenList().then((res) => {
      if (res) {
        console.log("open sr", res);
        setOpenSRList(res);
        handleOpenSRById(res[0]?.id, 0, res);
      }
    });
  };

  const handleInProgressSR = () => {
    setOpenSRList(openSRListDefault);
    setCompleteSRList(completedSRListDefault);
    setSearchInput("");
    setFilterData({});
    setServiceRequestId("");
    Api.getDistrictServiceRequestAcceptedList().then((res) => {
      if (res) {
        setInprogressSRList(res);
        handleInProgressSRById(res[0]?.id, 0, res);
      }
    });
  };

  const handleCompletedSR = () => {
    setOpenSRList(openSRListDefault);
    setInprogressSRList(inProgressSRListDefault);
    setSearchInput("");
    setFilterData({});
    setServiceRequestId("");
    Api.getDistrictServiceRequestClosedList().then((res) => {
      if (res) {
        setCompleteSRList(res);
        handleCompletedSRById(res[0]?.id, 0, res);
      }
    });
  };

  const handleOpenSRById = (req_id, index, response) => {
    setOpenIndex(index);
    handleEligibleTechnicians(req_id);
    setHovered(index);
    setIsOpenEdit(true);
    let sr = response.filter((item) => item?.id === req_id);
    console.log("response: ", response);
    setSRDetailArray(response?.filter((item) => item?.id === req_id));
    console.log(
      "response-1: ",
      response?.filter((item) => item?.id === req_id)
    );
    setServiceRequestId(req_id);
    if (isMobile && sr?.length > 0) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }
    if (sr?.length > 0) {
      formatDate(sr[0]?.created_at);
      setServiceStatus(sr[0]?.status);
    }
    setIsPayment(false);
  };

  const handleInProgressSRById = (req_id, index, response) => {
    setHovered(index);
    setIsOpenEdit(true);
    const sr = response.filter((item) => item.id === req_id);
    setSRDetailArray(response.filter((item) => item.id === req_id));
    setServiceRequestId(req_id);
    if (sr.length > 0) {
      formatDate(sr[0].created_at);
      setServiceStatus(sr[0].status);
    }
    setIsPayment(false);
    if (isMobile && sr?.length > 0) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const handleCompletedSRById = (req_id, index, response) => {
    setHovered(index);
    setIsOpenEdit(true);
    const sr = response.filter((item) => item.id === req_id);
    setSRDetailArray(response.filter((item) => item.id === req_id));
    setServiceRequestId(req_id);
    if (sr.length > 0) {
      formatDate(sr[0].created_at);
      setServiceStatus(sr[0].status);
    }
    setIsPayment(false);
    if (isMobile && sr?.length > 0) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const formatDate = (timestamp) => {
    console.log(timestamp, "timestamp");
    const dateObj = parseISO(timestamp);
    // return format(dateObj, 'MMM dd yyyy');
    setServiceCreatedTime(format(dateObj, "MMM dd yyyy, hh:mm a"));
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    // setSearch("");
    setHovered(null);
    setIsService(false);
    setIsVisit(false);
    setValue(newValue);
    setIsClosure(false);
    setIsPayment(false);
    setIsReopenSR(true);
  };

  const handleAssignTechnician = () => {
    const payload = {
      sr_id: serviceRequestId,
      technician_id: assignTechnician,
    };

    Api.assignServiceRequest(payload).then(() => {
      const token = localStorage.getItem("token");
      dispatch(getNotificationsCount(token));
      Api.getDistrictServiceRequestOpenList(searchInput, filterData).then(
        (res) => {
          console.log(res);
          if (res) {
            const payload = {
              sr_id: serviceRequestId,
            };
            setAssignTechnician("");
            setEligibleTechniciansList([]);
            Api.getEligibleTechnicians(payload).then((res) => {
              if (res) setEligibleTechniciansList(res);
            });
            setHovered(openIndex);
            setOpenSRList(res);
            setServiceRequestId(res.length > 0 && res[openIndex]?.id);
            setSRDetailArray(res.length > 0 && [res[openIndex]]);
            const sr = res.length > 0 && [res[openIndex]];
            if (res.length > 0) {
              console.log(sr[0], "sr[0]");
              formatDate(sr[0]?.created_at);

              setServiceStatus(sr[0]?.status);
            }
          }
        }
      );
      Api.getDistrictServiceRequestAcceptedList(searchInput, filterData).then(
        (res) => {
          if (res) {
            setInprogressSRList(res);
          }
        }
      );
      Api.getDistrictServiceRequestClosedList(searchInput, filterData).then(
        (res) => {
          if (res) {
            setCompleteSRList(res);
          }
        }
      );
    });
  };

  const handleEligibleTechnicians = (id) => {
    const payload = {
      sr_id: id,
    };
    setAssignTechnician("");
    setEligibleTechniciansList([]);
    Api.getEligibleTechnicians(payload).then((res) => {
      if (res) setEligibleTechniciansList(res);
    });
  };

  const getPaymentStatus = () => {
    const service_request_details = SrDetailArray.find(
      (val) => val.id === serviceRequestId
    );
    if (
      service_request_details.sr_work_history[0]?.payment_status === "initiated"
    ) {
      return "initiated";
    } else if (
      service_request_details.sr_work_history[0]?.payment_status === "completed"
    ) {
      return "completed";
    } else {
      return null;
    }
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string?.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }
  const [imageModal, setImageModal] = useState(false);
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name?.split(" ")[0][0]}`,
    };
  }

  const handleSearchInput = (e) => {
    setSearchInput(e);
    if (open) {
      Api.getDistrictServiceRequestOpenList(e, filterData).then((res) => {
        setOpenSRList(res);

        const sr = res.length > 0 && [res[0]];
        if (res.length > 0) {
          formatDate(sr[0]?.created_at);
          setServiceStatus(sr[0]?.status);
          setServiceRequestId(res.length > 0 && res[0]?.id);
          setSRDetailArray(res.length > 0 && [res[0]]);
          handleEligibleTechnicians(res.length > 0 && res[0]?.id);
        } else {
          setServiceRequestId("");
          setSRDetailArray([]);
        }
      });
    } else if (inProgress) {
      Api.getDistrictServiceRequestAcceptedList(e, filterData).then((res) => {
        setInprogressSRList(res);

        const sr = res.length > 0 && [res[0]];
        if (res.length > 0) {
          formatDate(sr[0]?.created_at);
          setServiceStatus(sr[0]?.status);
          setServiceRequestId(res.length > 0 && res[0]?.id);
          setSRDetailArray(res.length > 0 && [res[0]]);
          handleEligibleTechnicians(res.length > 0 && res[0]?.id);
        } else {
          setServiceRequestId("");
          setSRDetailArray([]);
        }
      });
    } else if (completed) {
      Api.getDistrictServiceRequestClosedList(e, filterData).then((res) => {
        setCompleteSRList(res);
        setServiceRequestId(res.length > 0 && res[0]?.id);
        setSRDetailArray(res.length > 0 && [res[0]]);
        handleEligibleTechnicians(res.length > 0 && res[0]?.id);
        const sr = res.length > 0 && [res[0]];
        if (res.length > 0) {
          formatDate(sr[0]?.created_at);
          setServiceStatus(sr[0]?.status);
        } else {
          setServiceRequestId("");
          setSRDetailArray([]);
        }
      });
    }
  };

  // const handleSearchInput = (e) => {
  //   setSearchInput(e);
  //   if (open) {
  //     Api.getDistrictServiceRequestOpenList(e, filterData).then((res) => {
  //       setOpenSRList(res);

  //       const sr = res.length > 0 && [res[0]];
  //       if (res.length > 0) {
  //         formatDate(sr[0]?.created_at);
  //         setServiceStatus(sr[0]?.status);
  //         setServiceRequestId(res.length > 0 && res[0]?.id);
  //         setSRDetailArray(res.length > 0 && [res[0]]);
  //         handleEligibleTechnicians(res.length > 0 && res[0]?.id);
  //       } else {
  //         setServiceRequestId("");
  //         setSRDetailArray([]);
  //       }
  //     });
  //   } else if (inProgress) {
  //     Api.getDistrictServiceRequestAcceptedList(e, filterData).then((res) => {
  //       setInprogressSRList(res);

  //       const sr = res.length > 0 && [res[0]];
  //       if (res.length > 0) {
  //         formatDate(sr[0]?.created_at);
  //         setServiceStatus(sr[0]?.status);
  //         setServiceRequestId(res.length > 0 && res[0]?.id);
  //         setSRDetailArray(res.length > 0 && [res[0]]);
  //         handleEligibleTechnicians(res.length > 0 && res[0]?.id);
  //       } else {
  //         setServiceRequestId("");
  //         setSRDetailArray([]);
  //       }
  //     });
  //   } else if (completed) {
  //     Api.getDistrictServiceRequestClosedList(e, filterData).then((res) => {
  //       setCompleteSRList(res);
  //       setServiceRequestId(res.length > 0 && res[0]?.id);
  //       setSRDetailArray(res.length > 0 && [res[0]]);
  //       handleEligibleTechnicians(res.length > 0 && res[0]?.id);
  //       const sr = res.length > 0 && [res[0]];
  //       if (res.length > 0) {
  //         formatDate(sr[0]?.created_at);
  //         setServiceStatus(sr[0]?.status);
  //       } else {
  //         setServiceRequestId("");
  //         setSRDetailArray([]);
  //       }
  //     });
  //   }
  // };
  const handleFilterData = (e) => {
    setFilterData(e);
    if (open) {
      Api.getDistrictServiceRequestOpenList(searchInput, e).then((res) => {
        setOpenSRList(res);

        const sr = res.length > 0 && [res[0]];
        if (res.length > 0) {
          formatDate(sr[0]?.created_at);
          setServiceStatus(sr[0]?.status);
          setServiceRequestId(res.length > 0 && res[0]?.id);
          setSRDetailArray(res.length > 0 && [res[0]]);
          handleEligibleTechnicians(res.length > 0 && res[0]?.id);
        } else {
          setServiceRequestId("");
          setSRDetailArray([]);
        }
      });
    } else if (inProgress) {
      Api.getDistrictServiceRequestAcceptedList(searchInput, e).then((res) => {
        setInprogressSRList(res);
        const sr = res.length > 0 && [res[0]];
        if (res.length > 0) {
          formatDate(sr[0]?.created_at);
          setServiceRequestId(res.length > 0 && res[0]?.id);
          setSRDetailArray(res.length > 0 && [res[0]]);
          handleEligibleTechnicians(res.length > 0 && res[0]?.id);
          setServiceStatus(sr[0]?.status);
        } else {
          setServiceRequestId("");
          setSRDetailArray([]);
        }
      });
    } else if (completed) {
      Api.getDistrictServiceRequestClosedList(searchInput, e).then((res) => {
        setCompleteSRList(res);

        const sr = res.length > 0 && [res[0]];
        if (res.length > 0) {
          formatDate(sr[0]?.created_at);
          setServiceStatus(sr[0]?.status);
          setServiceRequestId(res.length > 0 && res[0]?.id);
          setSRDetailArray(res.length > 0 && [res[0]]);
          handleEligibleTechnicians(res.length > 0 && res[0]?.id);
        } else {
          setServiceRequestId("");
          setSRDetailArray([]);
        }
      });
    }
  };
  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <Topbar />
        <div className="row container-fluid pe-0">
          <div className="col-2 col-12 p-0 border border-right">
            <Sidebar />
          </div>
          <div
            className="col-12 service_list pe-0"
            style={{ marginTop: "80px" }}
          >
            {loadingData ? (
              <div
                className="d-flex align-items-center"
                style={{ justifyContent: "center", height: "70vh" }}
              >
                <SpinnerLoader />
              </div>
            ) : (
              <div className="row" style={{ paddingRight: "0px" }}>
                <div
                  className="col-5 col-12 mt-2 service_listing"
                  style={{ paddingRight: "0px" }}
                >
                  <div className="">
                    <div
                      className="m-0 sticky-top bg-white"
                      style={{ position: "sticky", zIndex: "2" }}
                    >
                      <i
                        className="bi bi-chevron-left  res-font"
                        style={{
                          fontWeight: "600",
                          fontSize: "21px",
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate(-1)}
                      ></i>
                      <text
                        className="p-0 res-font"
                        style={{
                          fontWeight: "600",
                          color: "#1E232C",
                          fontSize: "21px",
                          width: "208px",
                          height: "32px",
                        }}
                      >
                        SR Management
                      </text>
                      <Tabs
                        className="w-100 bg-white scroll-tabs"
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                      >
                        <Tab
                          style={{
                            color: open ? "rgb(235, 129, 40)" : "#6A707C",
                          }}
                          label={`Open`}
                          {...a11yProps(0)}
                          onClick={() => {
                            setServiceStatus("Open");
                            setOpen(true);
                            setInProgress(false);
                            setCompleted(false);
                            handleOpenSR();
                          }}
                        />
                        <Tab
                          style={{
                            color: inProgress ? "rgb(235, 129, 40)" : "#6A707C",
                          }}
                          label={`In Progress`}
                          {...a11yProps(1)}
                          onClick={() => {
                            setServiceStatus("");
                            setOpen(false);
                            setInProgress(true);
                            setCompleted(false);
                            handleInProgressSR();
                          }}
                        />
                        <Tab
                          /*  className="font-12" */
                          style={{
                            /*  fontSize: "12px",
                            fontWeight: "400", */
                            color: completed ? "rgb(235, 129, 40)" : "#6A707C",
                          }}
                          label={`Completed`}
                          {...a11yProps(2)}
                          onClick={() => {
                            setServiceStatus("");
                            setOpen(false);
                            setInProgress(false);
                            setCompleted(true);
                            handleCompletedSR();
                          }}
                        />
                      </Tabs>
                      <FilterModule
                        inputPlaceholder={"Search by SR ID"}
                        onSearchInputChange={handleSearchInput}
                        onChangeFilterData={handleFilterData}
                        showDateMethod={completed === true ? true : false}
                        tabValue={value}
                      />
                    </div>
                    <div>
                      <div
                        className="custom-scrollbar-management"
                        id="scrollableDiv"
                      >
                        <CustomTabPanel value={value} index={0}>
                          {serviceListLoader ? (
                            <SpinnerLoader2 />
                          ) : (
                            <div className="mt-2">
                              <div
                                className="row-lg"
                                style={{ display: "flex" }}
                              >
                                <div
                                  className="col-12"
                                // style={{ height: "170vh" }}
                                >
                                  {openSRList?.length > 0
                                    ? openSRList?.map((progress, index) => {
                                      const progressPercentage = () => {
                                        if (
                                          progress?.status === "accepted" &&
                                          progress?.sr_work_history[0]
                                            .payment_status === null
                                        ) {
                                          return "50";
                                        } else if (
                                          progress?.status === "accepted" &&
                                          progress?.sr_work_history[0]
                                            .payment_status === "initiated"
                                        ) {
                                          return "50";
                                        } else if (
                                          progress?.status === "unassigned" ||
                                          progress?.status === "notified" ||
                                          progress?.status === "assigned"
                                        ) {
                                          return "30";
                                        } else if (
                                          progress?.status === "accepted" &&
                                          progress?.sr_work_history[0]
                                            .payment_status === "completed"
                                        ) {
                                          return "80";
                                        } else if (
                                          progress?.status === "closed"
                                        ) {
                                          return "100";
                                        } else if (
                                          progress?.status === "cancelled"
                                        ) {
                                          return "0";
                                        } else {
                                          return "0";
                                        }
                                      };
                                      return (
                                        <div
                                          className={`d-flex border mb-2 w-100 p-2 ${hovered === index &&
                                            "boder-rgb(235, 129, 40)"
                                            }`}
                                          style={{
                                            background:
                                              hovered === index
                                                ? "linear-gradient(to right,#FFE1CA , #FFF6EF00)"
                                                : undefined,
                                            cursor: "pointer",
                                            borderRadius: "10px",
                                          }}
                                          onClick={() =>
                                            handleOpenSRById(
                                              progress.id,
                                              index,
                                              openSRList
                                            )
                                          }
                                        >
                                          <div
                                            style={{
                                              position: "relative",
                                              right: "9px",
                                              display:
                                                hovered === index
                                                  ? ""
                                                  : "none",
                                            }}
                                          >
                                            <RectIcon />
                                          </div>
                                          <div
                                            style={{
                                              width: "100%",
                                              marginLeft:
                                                hovered !== index && "5px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent:
                                                  "space-between",
                                              }}
                                            >
                                              <div
                                                className="d-flex"
                                                style={{
                                                  fontWeight: "600",
                                                  fontSize: "14px",
                                                  color: "#1E232C",
                                                }}
                                              >
                                                <div>SR #: </div>
                                                <div
                                                  className="d-flex"
                                                  style={{
                                                    marginLeft: "3px",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      marginTop: "-1px",
                                                    }}
                                                  ></div>
                                                  <div>
                                                    {progress?.id?.slice(-6)}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="">
                                                <ProgressBar
                                                  percentage={progressPercentage()}
                                                />
                                              </div>
                                            </div>
                                            <div
                                              className="d-flex"
                                              style={{
                                                justifyContent:
                                                  "space-between",
                                              }}
                                            >
                                              <div>
                                                <div>
                                                  <div
                                                    style={{
                                                      marginTop: 14,
                                                      // display: "flex",
                                                      // alignItems: "center",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontWeight: "500",
                                                        fontSize: "13px",
                                                        marginLeft: 0,
                                                      }}
                                                    >
                                                      {
                                                        progress?.appliance_name
                                                      }
                                                      <div
                                                        style={{
                                                          fontWeight: "500",
                                                          fontSize: "13px",
                                                          marginLeft: 0,
                                                          // marginTop: 20,
                                                        }}
                                                      >
                                                        {
                                                          progress?.appliance_model
                                                        }
                                                        ,{" "}
                                                        {
                                                          progress?.appliance_size
                                                        }
                                                      </div>
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                              <div>
                                                <div className="font-12 mt-3">
                                                  Created date
                                                </div>
                                                <div
                                                  className="d-flex gap-1"
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "12px",
                                                    fontWeight: "600",
                                                    color: "#606060",
                                                  }}
                                                >
                                                  <div>
                                                    <CalendarIcon />
                                                  </div>
                                                  <div
                                                    style={{
                                                      paddingTop: "2px",
                                                    }}
                                                  >
                                                    {moment(
                                                      progress?.created_at
                                                    ).format(
                                                      "MMM DD YYYY, hh:mm a"
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                style={{
                                                  position: "relative",
                                                  top: "15px",
                                                }}
                                              >
                                                {hovered === index ? (
                                                  <ArrowIconHighlighted />
                                                ) : (
                                                  <ArrowIconDefault />
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                    : // <p className="text-center">No data found!</p>
                                    ""}
                                </div>
                              </div>
                            </div>
                          )}
                        </CustomTabPanel>
                        {/* Inprogress */}
                        <CustomTabPanel value={value} index={1}>
                          <div className="mt-2">
                            <div className="row-lg" style={{ display: "flex" }}>
                              <div className="col-12">
                                {inprogressSRList?.length > 0
                                  ? inprogressSRList?.map((progress, index) => {
                                    const progressPercentage = () => {
                                      if (
                                        progress.status === "accepted" &&
                                        progress.sr_work_history[0]
                                          .payment_status === null
                                      ) {
                                        return "50";
                                      } else if (
                                        progress.status === "accepted" &&
                                        progress.sr_work_history[0]
                                          .payment_status === "initiated"
                                      ) {
                                        return "50";
                                      } else if (
                                        progress.status === "accepted" &&
                                        progress.sr_work_history[0]
                                          .payment_status === "completed"
                                      ) {
                                        return "80";
                                      } else if (
                                        progress.status === "closed"
                                      ) {
                                        return "100";
                                      } else if (
                                        progress.status === "cancelled"
                                      ) {
                                        return "0";
                                      } else {
                                        return "0";
                                      }
                                    };
                                    return (
                                      <div
                                        className={`d-flex border mb-2 w-100 p-2 ${hovered === index &&
                                          "boder-rgb(235, 129, 40)"
                                          }`}
                                        style={{
                                          background:
                                            hovered === index
                                              ? "linear-gradient(to right,#FFE1CA , #FFF6EF00)"
                                              : undefined,
                                          cursor: "pointer",
                                          borderRadius: "10px",
                                        }}
                                        onClick={() =>
                                          handleInProgressSRById(
                                            progress.id,
                                            index,
                                            inprogressSRList
                                          )
                                        }
                                      >
                                        <div
                                          style={{
                                            position: "relative",
                                            right: "9px",
                                            display:
                                              hovered === index ? "" : "none",
                                          }}
                                        >
                                          <RectIcon />
                                        </div>
                                        <div
                                          style={{
                                            width: "100%",
                                            marginLeft:
                                              hovered !== index && "5px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div
                                              className="d-flex"
                                              style={{
                                                fontWeight: "600",
                                                fontSize: "14px",
                                                color: "#1E232C",
                                              }}
                                            >
                                              <div>SR #: </div>
                                              <div
                                                className="d-flex"
                                                style={{
                                                  marginLeft: "3px",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    marginTop: "-1px",
                                                  }}
                                                ></div>
                                                <div>
                                                  {progress?.id?.slice(-6)}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="">
                                              <ProgressBar
                                                percentage={progressPercentage()}
                                              />
                                            </div>
                                          </div>
                                          {progress.sr_work_history.length ===
                                            1 && <p>Technician</p>}
                                          {progress.sr_work_history.length >
                                            1 && <p>Technicians</p>}
                                          <div
                                            className="d-flex"
                                            style={{
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                              }}
                                            >
                                              {progress.sr_work_history.slice(0, 2).map((e, index) => (
                                                <div
                                                  key={index}
                                                  className="d-flex flex-column text-center ml--1"
                                                  style={{
                                                    width: "25%",
                                                    position: "relative"
                                                  }}
                                                >
                                                  {e?.avatar ? (
                                                    <Avatar
                                                      className="centered-text mt-3 ms-2"
                                                      src={e?.avatar}
                                                    />
                                                  ) : (
                                                    <Avatar
                                                      {...stringAvatar(e.technician_name)}
                                                    />
                                                  )}
                                                  {index === 1 && progress.sr_work_history.length > 2 && (
                                                    <div
                                                      style={{
                                                        position: "absolute",
                                                        top: "0",
                                                        right: "0",
                                                        backgroundColor: "red",
                                                        color: "white",
                                                        borderRadius: "50%",
                                                        padding: "0.25em 0.5em",
                                                        fontSize: "0.75em",
                                                        fontWeight: "bold"
                                                      }}
                                                    >
                                                      +{progress.sr_work_history.length - 2}
                                                    </div>
                                                  )}
                                                </div>
                                              ))}
                                            </div>
                                            <div>
                                              <div className="font-12 mt-3">
                                                Created date
                                              </div>
                                              <div
                                                className="d-flex gap-1"
                                                style={{
                                                  fontWeight: "500",
                                                  fontSize: "12px",
                                                  fontWeight: "600",
                                                  color: "#606060",
                                                }}
                                              >
                                                <div>
                                                  <CalendarIcon />
                                                </div>
                                                <div
                                                  style={{
                                                    paddingTop: "2px",
                                                  }}
                                                >
                                                  {moment(
                                                    progress?.created_at
                                                  ).format(
                                                    "MMM DD YYYY, hh:mm a"
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              style={{
                                                position: "relative",
                                                top: "15px",
                                              }}
                                            >
                                              {hovered === index ? (
                                                <ArrowIconHighlighted />
                                              ) : (
                                                <ArrowIconDefault />
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                  : // <p className="text-center">No data found!</p>
                                  ""}
                              </div>
                            </div>
                          </div>
                        </CustomTabPanel>

                        {/* Completed */}

                        <CustomTabPanel value={value} index={2}>
                          <div className="mt-2">
                            <div className="row-lg" style={{ display: "flex" }}>
                              <div className="col-12">
                                {completeSRList.length > 0
                                  ? completeSRList.map((progress, index) => {
                                    const progressPercentage = () => {
                                      if (
                                        progress.status === "accepted" &&
                                        progress.sr_work_history[0]
                                          .payment_status === null
                                      ) {
                                        return "50";
                                      } else if (
                                        progress.status === "accepted" &&
                                        progress.sr_work_history[0]
                                          .payment_status === "initiated"
                                      ) {
                                        return "50";
                                      } else if (
                                        progress.status === "accepted" &&
                                        progress.sr_work_history[0]
                                          .payment_status === "completed"
                                      ) {
                                        return "80";
                                      } else if (
                                        progress.status === "closed"
                                      ) {
                                        return "100";
                                      } else if (
                                        progress.status === "cancelled"
                                      ) {
                                        return "0";
                                      } else {
                                        return "0";
                                      }
                                    };
                                    return (
                                      <div
                                        className={`d-flex border mb-2 w-100 p-2 ${hovered === index &&
                                          "boder-rgb(235, 129, 40)"
                                          }`}
                                        style={{
                                          background:
                                            hovered === index
                                              ? "linear-gradient(to right,#FFE1CA , #FFF6EF00)"
                                              : undefined,
                                          cursor: "pointer",
                                          borderRadius: "10px",
                                        }}
                                        onClick={() =>
                                          handleCompletedSRById(
                                            progress.id,
                                            index,
                                            completeSRList
                                          )
                                        }
                                      >
                                        <div
                                          style={{
                                            position: "relative",
                                            right: "9px",
                                            display:
                                              hovered === index ? "" : "none",
                                          }}
                                        >
                                          <RectIcon />
                                        </div>
                                        <div
                                          style={{
                                            width: "100%",
                                            marginLeft:
                                              hovered !== index && "5px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div
                                              className="d-flex"
                                              style={{
                                                fontWeight: "600",
                                                fontSize: "14px",
                                                color: "#1E232C",
                                              }}
                                            >
                                              <div>SR #: </div>
                                              <div
                                                className="d-flex"
                                                style={{
                                                  marginLeft: "3px",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    marginTop: "-1px",
                                                  }}
                                                ></div>
                                                <div>
                                                  {progress?.id?.slice(-6)}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="">
                                              <ProgressBar
                                                percentage={progressPercentage()}
                                              />
                                            </div>
                                          </div>
                                          {progress.sr_work_history.length ===
                                            1 && <p>Technician</p>}
                                          {progress.sr_work_history.length >
                                            1 && <p>Technician's</p>}
                                          <div
                                            className="d-flex"
                                            style={{
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                              }}
                                            >
                                              {progress.sr_work_history.slice(0, 2).map((e, index) => (
                                                <div
                                                  key={index}
                                                  className="d-flex flex-column text-center ml--1"
                                                  style={{
                                                    width: "25%",
                                                    position: "relative"
                                                  }}
                                                >
                                                  {e?.avatar ? (
                                                    <Avatar
                                                      className="centered-text mt-3 ms-2"
                                                      src={e?.avatar}
                                                    />
                                                  ) : (
                                                    <Avatar
                                                      {...stringAvatar(e.technician_name)}
                                                    />
                                                  )}
                                                  {index === 1 && progress.sr_work_history.length > 2 && (
                                                    <div
                                                      style={{
                                                        position: "absolute",
                                                        top: "0",
                                                        right: "0",
                                                        backgroundColor: "red",
                                                        color: "white",
                                                        borderRadius: "50%",
                                                        padding: "0.25em 0.5em",
                                                        fontSize: "0.75em",
                                                        fontWeight: "bold"
                                                      }}
                                                    >
                                                      +{progress.sr_work_history.length - 2}
                                                    </div>
                                                  )}
                                                </div>
                                              ))}
                                            </div>
                                            {progress?.sr_work_history[0]
                                              ?.closed_at ? (
                                              <div>
                                                <div className="font-12">
                                                  {/* Closed Date */}Created
                                                  date
                                                </div>
                                                <div
                                                  className="d-flex gap-1"
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "12px",
                                                    fontWeight: "600",
                                                    color: "#606060",
                                                  }}
                                                >
                                                  <div>
                                                    <CalendarIcon />
                                                  </div>
                                                  <div
                                                    style={{
                                                      paddingTop: "2px",
                                                    }}
                                                  >
                                                    {/* {moment(
                                                    progress?.sr_work_history[0]
                                                      ?.closed_at
                                                  ).format(
                                                    "DD MMM YYYY, hh:mm a"
                                                  )} */}
                                                    {moment(
                                                      progress?.created_at
                                                    ).format(
                                                      "DD MMM YYYY, hh:mm a"
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <div>
                                                <div className="font-12">
                                                  Created date
                                                </div>
                                                <div
                                                  className="d-flex gap-1"
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "12px",
                                                    fontWeight: "600",
                                                    color: "#606060",
                                                  }}
                                                >
                                                  <div>
                                                    <CalendarIcon />
                                                  </div>
                                                  <div
                                                    style={{
                                                      paddingTop: "2px",
                                                    }}
                                                  >
                                                    {moment(
                                                      progress?.created_at
                                                    ).format(
                                                      "DD MMM YYYY, hh:mm a"
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                            <div
                                              style={{
                                                position: "relative",
                                                top: "15px",
                                              }}
                                            >
                                              {hovered === index ? (
                                                <ArrowIconHighlighted />
                                              ) : (
                                                <ArrowIconDefault />
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                  : // <p className="text-center">No data found!</p>
                                  ""}
                              </div>
                            </div>
                          </div>
                        </CustomTabPanel>
                      </div>
                    </div>
                  </div>
                </div>
                {serviceRequestId &&
                  SrDetailArray.length > 0 &&
                  SrDetailArray?.find((val) => val?.id === serviceRequestId)
                    ?.status !== "cancelled" ? (
                  <div className="col-7 col-12 service_details">
                    <div className="p-lg-2">
                      <div className="row mt-3">
                        <div className="col text-start">
                          {serviceRequestId ? (
                            <div>
                              <p
                                className="m-0"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  color: "#1E232C",
                                }}
                              >
                                <div className="d-flex align-items-center">
                                  <div>SR #:</div>
                                  <div className="d-flex align-items-center">
                                    <div
                                      style={{
                                        marginTop: "-3px",
                                        marginLeft: "3px",
                                      }}
                                    ></div>
                                    <div>{serviceRequestId?.slice(-6)}</div>
                                  </div>
                                </div>
                              </p>
                              {SrDetailArray[0].status === "closed" ? (
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "gray",
                                  }}
                                >
                                  Closed On:{" "}
                                  <b style={{ color: "#000" }}>
                                    {moment(
                                      SrDetailArray[0].sr_work_history[0]
                                        .closed_at
                                    ).format("DD MMM yyyy, hh:mm a")}
                                  </b>
                                </p>
                              ) : (
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "gray",
                                  }}
                                >
                                  Created On:{" "}
                                  <b style={{ color: "#000" }}>
                                    {serviceCreatedTime}
                                  </b>
                                </p>
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* service request creation */}
                      <div className="p-0 mt-3 d-flex">
                        <div style={{ marginTop: "12px" }}>
                          <div
                            className="w-20 rounded-circle"
                            style={{
                              backgroundColor: "#36A93F",
                              height: "13px",
                              width: "13px",
                            }}
                          ></div>
                        </div>
                        <div
                          className={`${isService ? "is-service-b-shadow-unset" : ""
                            }`}
                          style={{ width: "100%" }}
                        >
                          <Accordion
                            elevation={1}
                            style={{
                              marginLeft: "10px",
                            }}
                          >
                            <AccordionSummary
                              // expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              onClick={() => {
                                setIsService(!isService);
                              }}
                            >
                              <Typography>
                                Service Request Creation
                                {isService ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <ExpandMoreIcon />
                                )}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails className="accordian_details">
                              <div className="row p-2 pb-0 ps-0">
                                <div className="col-1 text-end p-0">
                                  <DoneIcon
                                    style={{
                                      color: "#36A93F",
                                      fontSize: "12px",
                                      // marginRight: "6px",
                                    }}
                                  />
                                </div>
                                <div className="col p-0 text-start">
                                  <p
                                    className=" p-0"
                                    style={{
                                      fontSize: "14px",
                                      color: "#36A93F",
                                      marginLeft: "6px",
                                    }}
                                  >
                                    SR Details
                                  </p>
                                </div>
                                {/* <div className="col p-0 text-end">
                                                                <p className="m-0 p-0 ms-2" style={{ fontSize: '12px', color: '#6B6D70' }}>15 mins ago</p>
                                                            </div> */}
                              </div>
                              <div className="row p-2 ps-0">
                                <div className="col-1 text-center p-0 with-vertical-line"></div>
                                <div className="col p-0 text-start">
                                  <table className="w-100 border-0">
                                    {SrDetailArray.length > 0
                                      ? SrDetailArray.map((srDetail, index) => (
                                        <tbody>
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Device:
                                          </p>

                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.appliance_name
                                              ? srDetail.appliance_name
                                              : ""}
                                          </p>

                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Brand:
                                          </p>

                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.appliance_brand
                                              ? srDetail.appliance_brand
                                              : ""}
                                          </p>

                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Model:
                                          </p>
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.appliance_model
                                              ? srDetail.appliance_model
                                              : ""}
                                          </p>

                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Size:
                                          </p>
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.appliance_size
                                              ? srDetail.appliance_size + '"'
                                              : ""}
                                          </p>

                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Warranty Status:
                                          </p>
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.warranty_status
                                              ? srDetail.warranty_status
                                              : ""}
                                          </p>

                                          {srDetail.warranty_status ===
                                            "active" && (
                                              <div>
                                                <p
                                                  className="m-0"
                                                  style={{
                                                    color: "black",
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  Warranty Untill:
                                                </p>

                                                <p
                                                  className="m-0"
                                                  style={{
                                                    color: "black",
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                  }}
                                                >
                                                  {srDetail.warranty_date
                                                    ? moment(
                                                      srDetail.warranty_date
                                                    ).format(
                                                      "DD MMM YYYY, hh:mm A"
                                                    )
                                                    : ""}
                                                </p>
                                              </div>
                                            )}
                                        </tbody>
                                      ))
                                      : // <p className="text-center">
                                      //   No data found!
                                      // </p>
                                      ""}
                                  </table>
                                </div>
                              </div>
                              <div className="row p-2 pb-0 ps-0">
                                <div className="col-1 text-end p-0">
                                  <DoneIcon
                                    style={{
                                      color: "#36A93F",
                                      fontSize: "12px",
                                      // marginRight: "6px",
                                    }}
                                  />
                                </div>
                                <div className="col p-0 text-start">
                                  <p
                                    className="p-0"
                                    style={{
                                      fontSize: "14px",
                                      color: "#36A93F",
                                      marginLeft: "6px",
                                    }}
                                  >
                                    Contact Details
                                  </p>
                                </div>
                              </div>
                              <div className="row p-2 ps-0">
                                <div className="col-1 text-center p-0 with-vertical-line"></div>
                                <div className="col p-0 text-start">
                                  <table className="w-100 border-0">
                                    {SrDetailArray.length > 0 ? (
                                      SrDetailArray.map((srDetail, index) => (
                                        <tbody>
                                          <tr style={{ height: "35px" }}>
                                            {/* <td className="text-start"> */}
                                            <p
                                              className="m-0"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              <b>Name:</b>
                                            </p>
                                            {/* </td> */}
                                            {/* <td className="text-start"> */}
                                            <p
                                              className="m-0 pe-2"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {srDetail
                                                ? srDetail?.address_name
                                                : ""}
                                            </p>
                                            {/* </td> */}
                                          </tr>
                                          <tr
                                            style={{
                                              height: "35px",
                                            }}
                                          >
                                            {/* <td className="text-start"> */}
                                            <p
                                              className="m-0"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                marginTop: 20,
                                              }}
                                            >
                                              <b>Address:</b>
                                            </p>
                                            {/* </td> */}
                                            {/* <td className="text-end"> */}
                                            <p
                                              className="m-0 pe-2"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {srDetail
                                                ? srDetail?.address
                                                : ""}
                                            </p>
                                            {/* </td> */}
                                          </tr>
                                          <tr style={{ height: "35px" }}>
                                            {/* <td className="text-start"> */}
                                            <p
                                              className="m-0"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              <b>District:</b>
                                            </p>
                                            {/* </td> */}
                                            {/* <td className="text-end"> */}
                                            <p
                                              className="m-0 pe-2"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {srDetail
                                                ? srDetail?.district
                                                : ""}
                                            </p>
                                            {/* </td> */}
                                          </tr>
                                          <tr style={{ height: "35px" }}>
                                            {/* <td className="text-start"> */}
                                            <p
                                              className="m-0"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              <b>State:</b>
                                            </p>
                                            {/* </td> */}
                                            {/* <td className="text-end"> */}
                                            <p
                                              className="m-0 pe-2"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {srDetail ? srDetail?.state : ""}
                                            </p>
                                            {/* </td> */}
                                          </tr>
                                          <tr style={{ height: "35px" }}>
                                            {/* <td className="text-start"> */}
                                            <p
                                              className="m-0"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              <b>Postal Code:</b>
                                            </p>
                                            {/* </td> */}
                                            {/* <td className="text-end"> */}
                                            <p
                                              className="m-0 pe-2"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {srDetail
                                                ? srDetail?.postalcode
                                                : ""}
                                            </p>
                                            {/* </td> */}
                                          </tr>
                                          <tr style={{ height: "35px" }}>
                                            {/* <td className="text-start"> */}
                                            <p
                                              className="m-0"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              <b>Phone Number:</b>
                                            </p>
                                            {/* </td> */}
                                            {/* <td className="text-end"> */}
                                            <p
                                              className="m-0 pe-2"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {srDetail
                                                ? srDetail?.customer_mobile_no
                                                : ""}
                                            </p>
                                            {/* </td> */}
                                          </tr>
                                        </tbody>
                                      ))
                                    ) : (
                                      <p>No data</p>
                                    )}
                                  </table>
                                </div>
                              </div>
                              {SrDetailArray?.find(
                                (val) => val?.id === serviceRequestId
                              )?.problem_desc && (
                                  <div className="row p-2 pb-0 ps-0">
                                    <div className="col-1 text-end p-0">
                                      <DoneIcon
                                        style={{
                                          color: "#36A93F",
                                          fontSize: "12px",
                                          // marginRight: "6px",
                                        }}
                                      />
                                    </div>
                                    <div className="col p-0 text-start">
                                      <p
                                        className="p-0 ms-2"
                                        style={{
                                          fontSize: "14px",
                                          color: "#36A93F",
                                          marginLeft: "6px",
                                        }}
                                      >
                                        Complaint Details
                                      </p>
                                    </div>
                                    {/* <div className="col p-0 text-end">
                                                                <p className="m-0 p-0 ms-2" style={{ fontSize: '12px', color: '#6B6D70' }}>15 mins ago</p>
                                                            </div> */}
                                  </div>
                                )}
                              {SrDetailArray?.find(
                                (val) => val?.id === serviceRequestId
                              )?.problem_desc && (
                                  <div className="row p-2 pb-0 ps-0 mb-2">
                                    <div className="col-1 text-center p-0 with-vertical-line"></div>
                                    <div className="col p-0 text-start">
                                      <table className="w-100 border-0">
                                        {SrDetailArray.map((srDetail, index) => (
                                          <tbody>
                                            <tr style={{ height: "35px" }}>
                                              {/* <td className="text-start"> */}
                                              <p
                                                className="m-0"
                                                style={{
                                                  color: "black",
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                <b>Description:</b>
                                              </p>
                                              {/* </td> */}
                                              {/* <td className="text-end"> */}
                                              <p
                                                className="m-0 pe-2"
                                                style={{
                                                  color: "black",
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                {srDetail.problem_desc
                                                  ? srDetail.problem_desc
                                                  : ""}
                                              </p>
                                              {/* </td> */}
                                            </tr>
                                            {(srDetail?.attachments?.length > 0) && (
                                              <tr style={{ height: "35px" }}>
                                                <p
                                                  className="m-0"
                                                  style={{
                                                    color: "black",
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                  }}
                                                >
                                                  <b>Attachment:</b>
                                                </p>
                                                <button
                                                  onClick={() =>
                                                    setImageModal(true)
                                                  }
                                                  className="m-0 pe-2 items-btn"
                                                  style={{
                                                    color: "black",
                                                    fontSize: "12px",
                                                    fontWeight: "400",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {(srDetail?.attachments
                                                    ?.length != null ||
                                                    srDetail?.attachments
                                                      ?.length != undefined) &&
                                                    "items"}
                                                  {/* <IoMdPhotos className="img-icon" />{" "} */}
                                                </button>
                                                {/* <p
                                                    onClick={() =>
                                                      setImageModal(true)
                                                    }
                                                    className="m-0 pe-2"
                                                    style={{
                                                      color: "black",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                      cursor: "pointer",
                                                      textDecoration: "underline",
                                                    }}
                                                  >
                                                    {(srDetail?.attachments
                                                      ?.length != null ||
                                                      srDetail?.attachments
                                                        ?.length != undefined) &&
                                                      "View Image"}
                                                  </p> */}
                                              </tr>
                                            )}
                                          </tbody>
                                        ))}
                                      </table>
                                    </div>
                                  </div>
                                )}
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </div>
                      {/* Reopen sr */}
                      {SrDetailArray[0]?.sr_work_history?.length > 1 && (
                        <div className="p-0 mt-3 d-flex">
                          <div style={{ marginTop: "12px" }}>
                            <div
                              className="w-20 rounded-circle"
                              style={{
                                backgroundColor: "#36A93F",
                                height: "13px",
                                width: "13px",
                              }}
                            ></div>
                          </div>
                          <div
                            className={`${isReopenSR ? "is-visit-b-shadow-unset" : ""
                              }`}
                            style={{ width: "100%" }}
                          >
                            <Accordion
                              elevation={1}
                              style={{
                                marginLeft: "10px",
                              }}
                            >
                              <AccordionSummary
                                // expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                onClick={() => setIsReopenSR(!isReopenSR)}
                              >
                                <Typography>
                                  <Typography>
                                    History{" "}
                                    {isReopenSR ? (
                                      <ExpandMoreIcon />
                                    ) : (
                                      <KeyboardArrowUpIcon />
                                    )}
                                  </Typography>
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails className="accordian_details">
                                <div className="p-3 pt-0">
                                  {SrDetailArray[0]?.sr_work_history?.map((history, index) => (
                                    <div
                                      key={index}
                                      className="mt-2 mb-2"
                                      style={{ fontSize: "13px" }}
                                    >
                                      <div
                                        className="align-items-center"
                                        style={{ width: "100%" }}
                                      >
                                        <div style={{ fontWeight: 600 }}>
                                          Requested visit date:
                                        </div>
                                        <div style={{ fontSize: "12px" }}>
                                          {moment(history?.requested_visit_date).format("DD MMM YYYY")}
                                        </div>
                                      </div>
                                      <div
                                        className="align-items-center"
                                        style={{ width: "100%" }}
                                      >
                                        <div style={{ fontWeight: 600 }}>
                                          Requested visit time:
                                        </div>
                                        <div style={{ fontSize: "12px" }}>
                                          {history?.requested_visit_time}
                                        </div>
                                      </div>
                                      <div
                                        className="align-items-center"
                                        style={{ width: "100%" }}
                                      >
                                        <div style={{ fontWeight: 600 }}>
                                          Technician Name:
                                        </div>
                                        <div style={{ fontSize: "12px" }}>
                                          {history?.technician_name}
                                        </div>
                                      </div>
                                      <div
                                        className="align-items-center"
                                        style={{ width: "100%" }}
                                      >
                                        <div style={{ fontWeight: 600 }}>
                                          Technician Mobile No:
                                        </div>
                                        <div style={{ fontSize: "12px" }}>
                                          {history?.mobile_no}
                                        </div>
                                      </div>
                                      <div
                                        className="align-items-center"
                                        style={{ width: "100%" }}
                                      >
                                        <div style={{ fontWeight: 600 }}>
                                          Technician Email Id:
                                        </div>
                                        <div style={{ fontSize: "12px" }}>
                                          {history?.tech_email_id}
                                        </div>
                                      </div>
                                      <div
                                        className="align-items-center"
                                        style={{ width: "100%" }}
                                      >
                                        <div style={{ fontWeight: 600 }}>
                                          Closing Notes:
                                        </div>
                                        <div style={{ fontSize: "12px" }}>
                                          {history?.closing_notes}
                                        </div>
                                      </div>
                                      <div
                                        className="align-items-center"
                                        style={{ width: "100%" }}
                                      >
                                        <div style={{ fontWeight: 600 }}>
                                          Closed On:
                                        </div>
                                        <div style={{ fontSize: "12px" }}>
                                          {moment(history?.closed_at).format("DD MMM YYYY, HH:mm A")}
                                        </div>
                                      </div>
                                      <div
                                        className="align-items-center"
                                        style={{ width: "100%" }}
                                      >
                                        <div style={{ fontWeight: 600 }}>
                                          Total amount paid:
                                        </div>
                                        <div style={{ fontSize: "12px" }}>
                                          {history?.spare_service_charge
                                            ? history?.spare_service_charge
                                            : history?.basic_charge}
                                        </div>
                                      </div>
                                      {SrDetailArray[0]?.sr_work_history?.length !== index + 1 && (
                                        <div
                                          className="mt-2 mb-2"
                                          style={{
                                            height: "1px",
                                            width: "100%",
                                            backgroundColor: "#cbcbcb",
                                            borderRadius: "50px",
                                          }}
                                        />
                                      )}
                                    </div>
                                  ))}

                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        </div>
                      )}
                      {/* technician visit */}
                      <div className="p-0 mt-3 d-flex mb-3">
                        <div style={{ marginTop: "12px" }}>
                          <div
                            className="w-20 rounded-circle"
                            style={{
                              backgroundColor: open ? "#FCB61A" : "#36A93F",
                              height: "13px",
                              width: "13px",
                            }}
                          ></div>
                        </div>
                        <div
                          className={`${isVisit ? "is-visit-b-shadow-unset" : ""
                            }`}
                          style={{ width: "100%" }}
                        >
                          <Accordion
                            elevation={1}
                            style={{
                              marginLeft: "10px",
                            }}
                          >
                            <AccordionSummary
                              // expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              onClick={() => setIsVisit(!isVisit)}
                            >
                              <Typography>
                                Technician visit{" "}
                                {isVisit ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <ExpandMoreIcon />
                                )}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails className="accordian_details">
                              <div className="row p-2 pb-0 ps-0">
                                <div className="col-1 text-end p-0">
                                  <DoneIcon
                                    style={{
                                      color: "#36A93F",
                                      fontSize: "12px",
                                      // marginRight: "6px",
                                    }}
                                  />
                                </div>
                                <div className="col p-0 text-start">
                                  <p
                                    className="p-0 ms-2"
                                    style={{
                                      fontSize: "15px",
                                      color: "#36A93F",
                                      marginLeft: "6px",
                                    }}
                                  >
                                    Visit Slot
                                  </p>
                                </div>
                              </div>
                              <div className="row p-2 m-0 ps-0">
                                <div
                                  className="col-1 text-center p-0 with-vertical-line"
                                  style={{ transform: "translatex(-20%)" }}
                                ></div>
                                <div className="col p-0 text-start">
                                  <table className="w-100 border-0">
                                    <tbody>
                                      <tr style={{ height: "35px" }}>
                                        {serviceStatus == "accepted" ||
                                          serviceStatus === "closed" ? (
                                          <td className="text-start">
                                            <p
                                              className="m-0"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              Confirmed Slot
                                            </p>
                                          </td>
                                        ) : (
                                          <td className="text-start">
                                            <p
                                              className="m-0"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              Requested Slot
                                            </p>
                                          </td>
                                        )}
                                        <td className="text-end">
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {SrDetailArray.length > 0 &&
                                              moment(
                                                SrDetailArray[0]
                                                  ?.sr_work_history[0]
                                                  ?.requested_visit_date
                                              ).format("DD MMM YYYY") +
                                              " " +
                                              SrDetailArray[0]?.sr_work_history[0]?.requested_visit_time?.toLowerCase()}
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>

                              {/* Accepted tech */}
                              {serviceStatus === "accepted" ||
                                serviceStatus === "closed" ? (
                                <div>
                                  <div className="row p-2 pb-0 ps-0">
                                    <div className="col-1 text-end p-0">
                                      <DoneIcon
                                        style={{
                                          color: "#36A93F",
                                          fontSize: "12px",
                                          // marginRight: "6px",
                                        }}
                                      />
                                    </div>
                                    <div className="col p-0 text-start">
                                      <p
                                        className="m-0 p-0 ms-2"
                                        style={{
                                          fontSize: "14px",
                                          color: "#36A93F",
                                        }}
                                      >
                                        Technician Accepted
                                      </p>
                                    </div>
                                    {/* <div className="col p-0 text-end">
                                                                        <p className="m-0 p-0 ms-2" style={{ fontSize: '12px', color: '#6B6D70' }}>11 mins ago</p>
                                                                    </div> */}
                                  </div>
                                  <div className="row p-2 ps-0 m-0">
                                    <div
                                      className="col-1 text-center  p-0 with-vertical-line"
                                      style={{ transform: "translateX(-20%)" }}
                                    ></div>
                                    <div className="col p-0 text-start">
                                      <table className="w-100 border-0">
                                        <tbody>
                                          <>
                                            <div
                                              className="d-flex align-items-center gap-2 mt-2"
                                              style={{ fontSize: "14px" }}
                                            >
                                              <div
                                                style={{ fontWeight: "600" }}
                                              >
                                                Technician:
                                              </div>
                                              <div>
                                                {SrDetailArray.length > 0 &&
                                                  SrDetailArray[0]
                                                    ?.sr_work_history[0]
                                                    ?.technician_name}
                                              </div>
                                            </div>
                                            <div
                                              className="d-flex align-items-center gap-2"
                                              style={{ fontSize: "14px" }}
                                            >
                                              <div
                                                style={{ fontWeight: "600" }}
                                              >
                                                Mobile No:
                                              </div>
                                              <div>
                                                {SrDetailArray.length > 0 &&
                                                  SrDetailArray[0]
                                                    ?.sr_work_history[0]
                                                    ?.mobile_no}
                                              </div>
                                            </div>
                                            <div
                                              className="d-flex align-items-center gap-2"
                                              style={{ fontSize: "14px" }}
                                            >
                                              <div
                                                style={{ fontWeight: "600" }}
                                              >
                                                Email:
                                              </div>
                                              <div>
                                                {SrDetailArray.length > 0 &&
                                                  SrDetailArray[0]
                                                    ?.sr_work_history[0]
                                                    ?.tech_email_id}
                                              </div>
                                            </div>
                                          </>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              ) : serviceStatus === "assigned" ? (
                                <div>
                                  <div className="row p-2 pb-0 ps-0">
                                    <div className="col-1 text-end p-0">
                                      <DoneIcon
                                        style={{
                                          color: "rgb(235, 129, 40)",
                                          fontSize: "12px",
                                          // marginRight: "6px",
                                        }}
                                      />
                                    </div>
                                    <div className="col p-0 text-start">
                                      <p
                                        className="m-0 p-0 ms-2"
                                        style={{
                                          fontSize: "14px",
                                          color: "rgb(235, 129, 40)",
                                        }}
                                      >
                                        Technician not Accepted
                                      </p>
                                    </div>
                                  </div>
                                  <div className="row p-2 ps-0 m-0">
                                    <div
                                      className="col-1 text-center  p-0 with-vertical-line-rgb(235, 129, 40)"
                                      style={{ transform: "translateX(-20%)" }}
                                    ></div>
                                    <div className="col p-0 text-start">
                                      <table className="w-100 border-0">
                                        <tbody>
                                          <>
                                            <div
                                              style={{
                                                fontSize: "16px",
                                                fontWeight: "600",
                                                color: "rgb(235, 129, 40)",
                                              }}
                                            >
                                              Technician Details:
                                            </div>
                                            <div
                                              className="d-flex align-items-center gap-2 mt-2"
                                              style={{ fontSize: "14px" }}
                                            >
                                              <div
                                                style={{ fontWeight: "600" }}
                                              >
                                                Technician:
                                              </div>
                                              <div>
                                                {SrDetailArray.length > 0 &&
                                                  SrDetailArray[0]
                                                    ?.sr_work_history[0]
                                                    ?.technician_name}
                                              </div>
                                            </div>
                                            <div
                                              className="d-flex align-items-center gap-2"
                                              style={{ fontSize: "14px" }}
                                            >
                                              <div
                                                style={{ fontWeight: "600" }}
                                              >
                                                Mobile No:
                                              </div>
                                              <div>
                                                {SrDetailArray.length > 0 &&
                                                  SrDetailArray[0]
                                                    ?.sr_work_history[0]
                                                    ?.mobile_no}
                                              </div>
                                            </div>
                                            <div
                                              className="d-flex align-items-center gap-2"
                                              style={{ fontSize: "14px" }}
                                            >
                                              <div
                                                style={{ fontWeight: "600" }}
                                              >
                                                Email:
                                              </div>
                                              <div>
                                                {SrDetailArray.length > 0 &&
                                                  SrDetailArray[0]
                                                    ?.sr_work_history[0]
                                                    ?.tech_email_id}
                                              </div>
                                            </div>
                                          </>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="row p-2 pb-2 ps-0">
                                  <div className="col-1 text-end p-0">
                                    <DoneIcon
                                      style={{
                                        color: "rgb(235, 129, 40)",
                                        fontSize: "12px",
                                        // marginRight: "6px",
                                      }}
                                    />
                                  </div>
                                  <div className="col p-0  text-start">
                                    <p
                                      className="p-0 ms-2"
                                      style={{
                                        fontSize: "15px",
                                        color: "rgb(235, 129, 40)",
                                        marginLeft: "6px",
                                      }}
                                    >
                                      Not assigned
                                    </p>
                                    {/* <p className="m-0 p-0 ms-2 mt-2" style={{ fontSize: '14px', fontWeight: '400' }}>
                                                                    Please wait! your request has been set and technician yet to confirm it
                                                                </p> */}
                                    <div>
                                      <FormControl

                                      // style={{ marginTop: "20px", }}
                                      >
                                        {eligibleTechniciansList.length > 0 && (
                                          <InputLabel id="demo-simple-select-label">
                                            Select Technician
                                          </InputLabel>
                                        )}
                                        {/*   <MenuItem>Select Technician</MenuItem> */}
                                        {eligibleTechniciansList.length > 0 ? (
                                          <Select
                                            renderValue={() => {
                                              const technicianData =
                                                eligibleTechniciansList?.find(
                                                  (tech) =>
                                                    tech?.id ===
                                                    assignTechnician
                                                );
                                              return (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "10px",
                                                  }}
                                                >
                                                  <div>
                                                    {technicianData?.fullname}
                                                  </div>
                                                  {technicianData && (
                                                    <div
                                                      style={{
                                                        fontSize: "12px",
                                                        color: "gray",
                                                      }}
                                                    >
                                                      {" "}
                                                      &nbsp; (
                                                      {technicianData?.distance_in_km
                                                        ? technicianData?.distance_in_km
                                                        : 0}{" "}
                                                      Kms)
                                                    </div>
                                                  )}
                                                </div>
                                              );
                                            }}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Select Technician"
                                            placeholder="Select Technician"
                                            onChange={(e) =>
                                              setAssignTechnician(
                                                e.target.value
                                              )
                                            }
                                          >
                                            {eligibleTechniciansList.length >
                                              0 &&
                                              eligibleTechniciansList?.map(
                                                (technician) => (
                                                  <MenuItem
                                                    value={technician?.id}
                                                  >
                                                    <div>
                                                      {technician?.fullname}
                                                    </div>
                                                    <div
                                                      style={{
                                                        fontSize: "12px",
                                                        color: "gray",
                                                      }}
                                                    >
                                                      {" "}
                                                      &nbsp; (
                                                      {technician?.distance_in_km
                                                        ? technician?.distance_in_km
                                                        : 0}{" "}
                                                      Kms)
                                                    </div>{" "}
                                                    <Tooltip
                                                      title={
                                                        technician?.assignmentStatus ===
                                                          "denied"
                                                          ? `${technician?.fullname} has denied the Assigned SR.`
                                                          : `${technician?.fullname} has not responded to the Assigned SR.`
                                                      }
                                                    >
                                                      {technician?.assignmentStatus && (
                                                        <div
                                                          style={{
                                                            color:
                                                              technician?.assignmentStatus ===
                                                                "denied"
                                                                ? "red"
                                                                : "rgb(235, 129, 40)",
                                                            marginLeft: 10,
                                                          }}
                                                        >
                                                          <IoIosInformationCircleOutline />
                                                        </div>
                                                      )}
                                                    </Tooltip>
                                                  </MenuItem>
                                                )
                                              )}
                                          </Select>
                                        ) : (
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Technician Not Available
                                          </p>
                                        )}
                                      </FormControl>
                                    </div>
                                  </div>
                                  <div className="ps-5 pe-5 mt-4">
                                    {assignTechnician && (
                                      <button
                                        className="ps-4 pe-4"
                                        style={{
                                          padding: "5px",
                                          backgroundColor: "rgb(235, 129, 40)",
                                          color: "#fff",
                                          borderRadius: "50px",
                                          fontSize: "12px",
                                          border: "none",
                                        }}
                                        onClick={() => handleAssignTechnician()}
                                      >
                                        Assign
                                      </button>
                                    )}
                                  </div>
                                </div>
                              )}
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </div>

                      {/* Repair Estimated SLA */}
                      {serviceStatus === "nill" ? (
                        <ul className="p-0 mt-3">
                          <li className="mt-2" style={{ listStyle: "none" }}>
                            <div
                              className="w-20 rounded-circle"
                              style={{
                                backgroundColor: IsRepair
                                  ? "#FCB61A"
                                  : "#36A93F",
                                height: "20px",
                                width: "20px",
                              }}
                            ></div>
                          </li>
                          <li className="ms-2" style={{ listStyle: "none" }}>
                            <Accordion elevation={1}>
                              <AccordionSummary
                                // expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                onClick={() => setIsRepair(!IsRepair)}
                              >
                                <Typography>
                                  Repair Estimated SLA{" "}
                                  {IsRepair ? (
                                    <ExpandMoreIcon />
                                  ) : (
                                    <KeyboardArrowUpIcon />
                                  )}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails className="accordian_details">
                                <div className="row p-2">
                                  <div className="col-1 text-end p-0">
                                    <DoneIcon
                                      style={{
                                        color: "#36A93F",
                                        fontSize: "12px",
                                        marginRight: "6px",
                                      }}
                                    />
                                  </div>
                                  <div className="col p-0 text-start">
                                    <p
                                      className="m-0 p-0 ms-2"
                                      style={{
                                        fontSize: "15px",
                                        color: "rgb(235, 129, 40)",
                                      }}
                                    >
                                      Repair Excepted SLA
                                    </p>
                                  </div>
                                  {/* <div className="col p-0 text-end">
                                                                    <p className="m-0 p-0 ms-2" style={{ fontSize: '13px', color: '#6B6D70' }}>45 secs ago</p>
                                                                </div> */}
                                </div>

                                <div className="row p-2">
                                  <div className="col-1 text-center p-0 with-vertical-line"></div>
                                  <div className="col p-0 text-start">
                                    <table className="w-100 border-0">
                                      <tbody>
                                        <tr style={{ height: "35px" }}>
                                          <td className="text-start">
                                            <p
                                              className="m-0"
                                              style={{
                                                color: "black",
                                                fontSize: "16px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              Your device will be ready
                                            </p>
                                          </td>
                                          <td className="text-end">
                                            <p
                                              className="m-0"
                                              style={{
                                                color: "black",
                                                fontSize: "16px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              in 48 hours
                                            </p>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </li>
                        </ul>
                      ) : null}

                      {/* Closure Details */}
                      {serviceStatus === "closed" && completed && (
                        <div className="p-0 mt-3 d-flex">
                          <div style={{ marginTop: "14px" }}>
                            <div
                              className="w-20 rounded-circle"
                              style={{
                                backgroundColor:
                                  getPaymentStatus() === "initiated" ||
                                    getPaymentStatus() === null
                                    ? "#FCB61A"
                                    : getPaymentStatus() === "completed" &&
                                    "#36A93F",
                                height: "13px",
                                width: "13px",
                              }}
                            ></div>
                          </div>
                          <div
                            className={`${isClosure ? "is-visit-b-shadow-unset" : ""
                              }`}
                            style={{ width: "100%" }}
                          >
                            <Accordion
                              elevation={1}
                              style={{
                                marginLeft: "10px",
                              }}
                            >
                              <AccordionSummary
                                // expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                onClick={() => setIsClosure(!isClosure)}
                              >
                                <Typography>
                                  Closure Details{" "}
                                  {isClosure ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <ExpandMoreIcon />
                                  )}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails className="accordian_details">
                                {SrDetailArray.length > 0 &&
                                  SrDetailArray.map((srDetail, index) => {
                                    return (
                                      <div>
                                        <div className="row p-2 pb-0 ps-0">
                                          <div className="col-1 text-end p-0">
                                            <DoneIcon
                                              style={{
                                                color:
                                                  srDetail?.sr_work_history[0]
                                                    ?.payment_status ===
                                                    "initiated" ||
                                                    srDetail?.sr_work_history[0]
                                                      ?.payment_status ===
                                                    "completed"
                                                    ? "#36A93F"
                                                    : "rgb(235, 129, 40)",
                                                fontSize: "12px",
                                                // marginRight: "6px",
                                              }}
                                            />
                                          </div>
                                          <div className="col p-0 text-start">
                                            <p
                                              className="p-0 ms-2"
                                              style={{
                                                fontSize: "15px",
                                                color:
                                                  srDetail?.sr_work_history[0]
                                                    ?.payment_status ===
                                                    "initiated" ||
                                                    srDetail?.sr_work_history[0]
                                                      ?.payment_status ===
                                                    "completed"
                                                    ? "#36A93F"
                                                    : "rgb(235, 129, 40)",
                                                marginLeft: "6px",
                                              }}
                                            >
                                              Your Closure Details
                                            </p>
                                          </div>
                                        </div>
                                        <div className="row p-2 m-0 ps-0">
                                          <div
                                            className={`col-1 text-center p-0 ${srDetail?.sr_work_history[0]
                                              ?.payment_status ===
                                              "initiated" ||
                                              srDetail?.sr_work_history[0]
                                                ?.payment_status === "completed"
                                              ? "with-vertical-line"
                                              : "with-vertical-line-rgb(235, 129, 40)"
                                              }`}
                                            style={{
                                              transform: "translatex(-20%)",
                                            }}
                                          ></div>
                                          <div className="col p-0 text-start">
                                            <table className="w-100 border-0">
                                              <tbody>
                                                <tr style={{ height: "35px" }}>
                                                  <td className="text-start">
                                                    <div
                                                      style={{
                                                        fontSize: "14px",
                                                      }}
                                                    >
                                                      {
                                                        srDetail
                                                          ?.sr_work_history[0]
                                                          ?.closing_notes
                                                      }
                                                    </div>
                                                  </td>
                                                  <td className="text-end">
                                                    <p
                                                      className="m-0"
                                                      style={{
                                                        color: "black",
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                      }}
                                                    ></p>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        </div>
                      )}
                      {/* Payment Details */}
                      {serviceStatus === "closed" && completed && (
                        <div className="p-0 mt-3 d-flex">
                          <div style={{ marginTop: "14px" }}>
                            <div
                              className="w-20 rounded-circle"
                              style={{
                                backgroundColor:
                                  getPaymentStatus() === "initiated" ||
                                    getPaymentStatus() === null
                                    ? "#FCB61A"
                                    : getPaymentStatus() === "completed" &&
                                    "#36A93F",
                                height: "13px",
                                width: "13px",
                              }}
                            ></div>
                          </div>
                          <div
                            className={`${isPayment ? "is-visit-b-shadow-unset" : ""
                              }`}
                            style={{ width: "100%" }}
                          >
                            <Accordion
                              elevation={1}
                              style={{
                                marginLeft: "10px",
                              }}
                            >
                              <AccordionSummary
                                // expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                onClick={() => setIsPayment(!isPayment)}
                              >
                                <Typography>
                                  Payment Details
                                  {isPayment ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <ExpandMoreIcon />
                                  )}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails
                                className="accordian_details mb-2"
                                style={{ width: "100%" }}
                              >
                                {SrDetailArray.length > 0
                                  ? SrDetailArray.map((srDetail, index) => {
                                    return (
                                      <div>
                                        <div className="row p-2">
                                          <div className="col-1 text-end p-0">
                                            <DoneIcon
                                              style={{
                                                color: "#36A93F",
                                                fontSize: "12px",
                                                marginRight: "6px",
                                              }}
                                            />
                                          </div>
                                          <div className="col p-0 text-start">
                                            <p
                                              className="m-0 p-0 ms-2"
                                              style={{
                                                fontSize: "14px",
                                                color: "#36A93F",
                                              }}
                                            >
                                              Visiting Charge (₹)
                                            </p>
                                          </div>
                                          <div className="col p-0 text-end">
                                            <div
                                              className="m-0 p-0 pe-2 ms-2"
                                              style={{
                                                fontSize: "14px",
                                                color: "#6B6D70",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  color:
                                                    srDetail
                                                      ?.sr_work_history[0]
                                                      ?.spare_service_charge >
                                                      0
                                                      ? "#e3e3e3"
                                                      : "#000",
                                                }}
                                              >
                                                {
                                                  srDetail?.sr_work_history[0]
                                                    ?.basic_charge
                                                }
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row p-2">
                                          <div className="col-1 text-end p-0">
                                            <DoneIcon
                                              style={{
                                                color: "#36A93F",
                                                fontSize: "12px",
                                                marginRight: "6px",
                                              }}
                                            />
                                          </div>
                                          <div className="col p-0 text-start">
                                            <p
                                              className="m-0 p-0 ms-2"
                                              style={{
                                                fontSize: "14px",
                                                color: "#36A93F",
                                              }}
                                            >
                                              Spare and Service Charges (₹)
                                            </p>
                                          </div>
                                          <div className="col p-0 text-end">
                                            <div
                                              className="m-0 p-0 pe-2 ms-2"
                                              style={{
                                                fontSize: "14px",
                                                color: "#6B6D70",
                                              }}
                                            >
                                              <div>
                                                {
                                                  srDetail?.sr_work_history[0]
                                                    ?.spare_service_charge
                                                }
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row p-2">
                                          <div className="col-1 text-end p-0">
                                            <DoneIcon
                                              style={{
                                                color: "#000",
                                                fontSize: "12px",
                                                marginRight: "6px",
                                              }}
                                            />
                                          </div>
                                          <div className="col p-0 text-start">
                                            <p
                                              className="m-0 p-0 ms-2"
                                              style={{
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                color: "#1E232C",
                                              }}
                                            >
                                              Total Charges (₹)
                                            </p>
                                          </div>
                                          <div className="col p-0 pe-2 text-end">
                                            <div
                                              style={{
                                                fontSize: "16px",
                                                fontWeight: "600",
                                              }}
                                            >
                                              {srDetail?.sr_work_history[0]
                                                ?.spare_service_charge
                                                ? srDetail?.sr_work_history[0]
                                                  ?.spare_service_charge
                                                : srDetail?.sr_work_history[0]
                                                  ?.basic_charge}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                  : // <p className="text-center">No data found!</p>
                                  ""}
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : SrDetailArray.length > 0 &&
                  SrDetailArray?.find((val) => val?.id === serviceRequestId)
                    ?.status === "cancelled" ? (
                  <div className="col-7 col-12 service_details">
                    <div className="row mt-3 p-3">
                      <div className="col text-start">
                        {serviceRequestId ? (
                          <div>
                            <p
                              className="m-0"
                              style={{
                                fontSize: "16px",
                                fontWeight: "600",
                                color: "#1E232C",
                              }}
                            >
                              <div className="d-flex align-items-center">
                                <div>SR #:</div>
                                <div className="d-flex align-items-center">
                                  <div
                                    style={{
                                      marginTop: "-3px",
                                      marginLeft: "3px",
                                    }}
                                  ></div>
                                  <div>{serviceRequestId?.slice(-6)}</div>
                                </div>
                              </div>
                            </p>
                            <p
                              className="m-0"
                              style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "gray",
                              }}
                            >
                              Created On:{" "}
                              <b style={{ color: "#000" }}>
                                {serviceCreatedTime}
                              </b>
                            </p>
                          </div>
                        ) : null}
                      </div>
                      <div className="p-0 mt-3 d-flex">
                        <div style={{ marginTop: "12px" }}>
                          <div
                            className="w-20 rounded-circle"
                            style={{
                              backgroundColor: "#36A93F",
                              height: "13px",
                              width: "13px",
                            }}
                          ></div>
                        </div>
                        <div
                          className={`${isService ? "is-service-b-shadow-unset" : ""
                            }`}
                          style={{ width: "100%" }}
                        >
                          <Accordion
                            elevation={1}
                            style={{
                              marginLeft: "10px",
                            }}
                          >
                            <AccordionSummary
                              // expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              onClick={() => {
                                setIsService(!isService);
                              }}
                            >
                              <Typography>
                                Service Request Creation
                                {isService ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <ExpandMoreIcon />
                                )}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails className="accordian_details">
                              <div className="row p-2 pb-0 ps-0">
                                <div className="col-1 text-end p-0">
                                  <DoneIcon
                                    style={{
                                      color: "#36A93F",
                                      fontSize: "12px",
                                      marginRight: "6px",
                                    }}
                                  />
                                </div>
                                <div className="col p-0 text-start">
                                  <p
                                    className="m-0 p-0"
                                    style={{
                                      fontSize: "14px",
                                      color: "#36A93F",
                                    }}
                                  >
                                    SR Details
                                  </p>
                                </div>
                                {/* <div className="col p-0 text-end">
                                                                <p className="m-0 p-0 ms-2" style={{ fontSize: '12px', color: '#6B6D70' }}>15 mins ago</p>
                                                            </div> */}
                              </div>

                              <div className="row p-2 ps-0">
                                <div className="col-1 text-center p-0 with-vertical-line"></div>
                                <div className="col p-0 text-start">
                                  <table className="w-100 border-0">
                                    {SrDetailArray.length > 0
                                      ? SrDetailArray.map((srDetail, index) => (
                                        <tbody>
                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Device:
                                          </p>

                                          <p
                                            className="m-0 pe-2"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.appliance_name
                                              ? srDetail.appliance_name
                                              : ""}
                                          </p>

                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Brand:
                                          </p>

                                          <p
                                            className="m-0 pe-2"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.appliance_brand
                                              ? srDetail.appliance_brand
                                              : ""}
                                          </p>

                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Model:
                                          </p>

                                          <p
                                            className="m-0 pe-2"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.appliance_model
                                              ? srDetail.appliance_model
                                              : ""}
                                          </p>

                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Size:
                                          </p>

                                          <p
                                            className="m-0 pe-2"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.appliance_size
                                              ? srDetail.appliance_size + '"'
                                              : ""}
                                          </p>

                                          <p
                                            className="m-0"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            Warranty Status:
                                          </p>

                                          <p
                                            className="m-0 pe-2"
                                            style={{
                                              color: "black",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {srDetail.warranty_status
                                              ? srDetail.warranty_status ===
                                                "inactive"
                                                ? "InActive"
                                                : "Active"
                                              : ""}
                                          </p>

                                          {srDetail.warranty_status ===
                                            "active" && (
                                              <div>
                                                <p
                                                  className="m-0"
                                                  style={{
                                                    color: "black",
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                  }}
                                                >
                                                  Warranty Untill:
                                                </p>

                                                <p
                                                  className="m-0 pe-2"
                                                  style={{
                                                    color: "black",
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                  }}
                                                >
                                                  {srDetail.warranty_date
                                                    ? srDetail.warranty_date
                                                    : ""}
                                                </p>
                                              </div>
                                            )}
                                        </tbody>
                                      ))
                                      : // <p className="text-center">
                                      //   No data found!
                                      // </p>
                                      ""}
                                  </table>
                                </div>
                              </div>
                              <div className="row p-2 pb-0 ps-0">
                                <div className="col-1 text-end p-0">
                                  <DoneIcon
                                    style={{
                                      color: "#36A93F",
                                      fontSize: "12px",
                                      marginRight: "6px",
                                    }}
                                  />
                                </div>
                                <div className="col p-0 text-start">
                                  <p
                                    className=" p-0"
                                    style={{
                                      fontSize: "14px",
                                      color: "#36A93F",
                                      marginLeft: "6px",
                                    }}
                                  >
                                    Contact Details
                                  </p>
                                </div>
                              </div>
                              <div className="row p-2 ps-0">
                                <div className="col-1 text-center p-0 with-vertical-line"></div>
                                <div className="col p-0 text-start">
                                  <table className="w-100 border-0">
                                    {SrDetailArray.length > 0 ? (
                                      SrDetailArray.map((srDetail, index) => (
                                        <tbody>
                                          <tr style={{ height: "35px" }}>
                                            {/* <td className="text-start"> */}
                                            <p
                                              className="m-0"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              <b>Name:</b>
                                            </p>
                                            {/* </td> */}
                                            {/* <td className="text-start"> */}
                                            <p
                                              className="m-0 pe-2"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {srDetail
                                                ? srDetail?.address_name
                                                : ""}
                                            </p>
                                            {/* </td> */}
                                          </tr>
                                          <tr
                                            style={{
                                              height: "35px",
                                            }}
                                          >
                                            {/* <td className="text-start"> */}
                                            <p
                                              className="m-0"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                marginTop: 20,
                                              }}
                                            >
                                              <b>Address:</b>
                                            </p>
                                            {/* </td> */}
                                            {/* <td className="text-end"> */}
                                            <p
                                              className="m-0 pe-2"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {srDetail
                                                ? srDetail?.address
                                                : ""}
                                            </p>
                                            {/* </td> */}
                                          </tr>
                                          <tr style={{ height: "35px" }}>
                                            {/* <td className="text-start"> */}
                                            <p
                                              className="m-0"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              <b>District:</b>
                                            </p>
                                            {/* </td> */}
                                            {/* <td className="text-end"> */}
                                            <p
                                              className="m-0 pe-2"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {srDetail
                                                ? srDetail?.district
                                                : ""}
                                            </p>
                                            {/* </td> */}
                                          </tr>
                                          <tr style={{ height: "35px" }}>
                                            {/* <td className="text-start"> */}
                                            <p
                                              className="m-0"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              <b>State:</b>
                                            </p>
                                            {/* </td> */}
                                            {/* <td className="text-end"> */}
                                            <p
                                              className="m-0 pe-2"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {srDetail ? srDetail?.state : ""}
                                            </p>
                                            {/* </td> */}
                                          </tr>
                                          <tr style={{ height: "35px" }}>
                                            {/* <td className="text-start"> */}
                                            <p
                                              className="m-0"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              <b>Postal Code:</b>
                                            </p>
                                            {/* </td> */}
                                            {/* <td className="text-end"> */}
                                            <p
                                              className="m-0 pe-2"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {srDetail
                                                ? srDetail?.postalcode
                                                : ""}
                                            </p>
                                            {/* </td> */}
                                          </tr>
                                          <tr style={{ height: "35px" }}>
                                            {/* <td className="text-start"> */}
                                            <p
                                              className="m-0"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              <b>Phone Number:</b>
                                            </p>
                                            {/* </td> */}
                                            {/* <td className="text-end"> */}
                                            <p
                                              className="m-0 pe-2"
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {srDetail
                                                ? srDetail?.customer_mobile_no
                                                : ""}
                                            </p>
                                            {/* </td> */}
                                          </tr>
                                        </tbody>
                                      ))
                                    ) : (
                                      <p>No data</p>
                                    )}
                                  </table>
                                </div>
                              </div>
                              {SrDetailArray?.find(
                                (val) => val?.id === serviceRequestId
                              )?.problem_desc && (
                                  <div className="row p-2 pb-0 ps-0">
                                    <div className="col-1 text-end p-0">
                                      <DoneIcon
                                        style={{
                                          color: "#36A93F",
                                          fontSize: "12px",
                                          marginRight: "6px",
                                        }}
                                      />
                                    </div>
                                    <div className="col p-0 text-start">
                                      <p
                                        className="m-0 p-0 ms-2"
                                        style={{
                                          fontSize: "14px",
                                          color: "#36A93F",
                                        }}
                                      >
                                        Complaint Details
                                      </p>
                                    </div>
                                    {/* <div className="col p-0 text-end">
                                                                <p className="m-0 p-0 ms-2" style={{ fontSize: '12px', color: '#6B6D70' }}>15 mins ago</p>
                                                            </div> */}
                                  </div>
                                )}
                              {SrDetailArray?.find(
                                (val) => val?.id === serviceRequestId
                              )?.problem_desc && (
                                  <div className="row p-2 pb-0 ps-0 mb-2">
                                    <div className="col-1 text-center p-0 with-vertical-line"></div>
                                    <div className="col p-0 text-start">
                                      <table className="w-100 border-0">
                                        {SrDetailArray.map((srDetail, index) => (
                                          <tbody>
                                            <tr style={{ height: "35px" }}>
                                              {/* <td className="text-start"> */}
                                              <p
                                                className="m-0"
                                                style={{
                                                  color: "black",
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                <b>Description:</b>
                                              </p>
                                              {/* </td> */}
                                              {/* <td className="text-end"> */}
                                              <p
                                                className="m-0 pe-2"
                                                style={{
                                                  color: "black",
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                {srDetail.problem_desc
                                                  ? srDetail.problem_desc
                                                  : ""}
                                              </p>
                                              {/* </td> */}
                                            </tr>
                                            <tr style={{ height: "35px" }}>
                                              <p
                                                className="m-0"
                                                style={{
                                                  color: "black",
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                <b>Attachments:</b>
                                              </p>
                                              <button
                                                onClick={() =>
                                                  setImageModal(true)
                                                }
                                                className="m-0 pe-2 items-btn"
                                                style={{
                                                  color: "black",
                                                  fontSize: "12px",
                                                  fontWeight: "400",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                {srDetail.attachments
                                                  ? srDetail.attachments.length
                                                  : ""}{" "}
                                                <IoMdPhotos className="img-icon" />{" "}
                                                Item(s)
                                              </button>
                                              {/* <p
                                                onClick={() =>
                                                  setImageModal(true)
                                                }
                                                className="m-0 pe-2"
                                                style={{
                                                  color: "black",
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                  cursor: "pointer",
                                                  textDecoration: "underline",
                                                }}
                                              >
                                                {srDetail.attachments
                                                  ? srDetail.attachments.length
                                                  : ""}{" "}
                                                Item(s)
                                              </p> */}
                                            </tr>
                                          </tbody>
                                        ))}
                                      </table>
                                    </div>
                                  </div>
                                )}
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        height: "200px",
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                        top: "150px",
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "#ffa5005c",
                      }}
                    >
                      This Service Request has been cancelled!
                    </div>
                  </div>
                ) : (
                  <div className="col-7 col-12 service_details">
                    <div className="row mt-3 p-3">
                      <div className="col text-start">
                        {serviceRequestId ? (
                          <div>
                            <p
                              className="m-0"
                              style={{
                                fontSize: "16px",
                                fontWeight: "600",
                                color: "#1E232C",
                              }}
                            >
                              <div className="d-flex align-items-center">
                                <div>SR #:</div>
                                <div className="d-flex align-items-center">
                                  <div
                                    style={{
                                      marginTop: "-3px",
                                      marginLeft: "3px",
                                    }}
                                  ></div>
                                  <div>{serviceRequestId?.slice(-6)}</div>
                                </div>
                              </div>
                            </p>
                            <p
                              className="m-0"
                              style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "gray",
                              }}
                            >
                              Created On:{" "}
                              <b style={{ color: "#000" }}>
                                {serviceCreatedTime}
                              </b>
                            </p>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div
                      style={{
                        height: "200px",
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                        top: "150px",
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "#ffa5005c",
                      }}
                    >
                      Please select an SR to view
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {imageModal && (
        <Modal
          className="imageModal"
          show={imageModal}
          onHide={() => setImageModal(false)}
        >
          <div
            style={{
              backgroundColor: "white",
              width: "100%",
              maxWidth: "600px",
              overflow: "hidden",
              margin: "auto",
              borderRadius: "15px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "20px",
            }}
          >
            <div className="p-3">
              <div className="d-flex align-items-center justify-content-between">
                <div style={{ fontSize: "20px" }}>
                  {/* <div>Attached Image</div> */}
                </div>
                <div onClick={() => setImageModal(false)}>
                  <MdClose
                    style={{
                      cursor: "pointer",
                      color: "rgb(235, 129, 40)",
                      position: "relative",
                      right: "20px",
                    }}
                  />
                </div>
              </div>
              <div
                className="image-container"
                style={{
                  overflowX: "hidden",
                  maxWidth: "80vw",
                  margin: "auto",
                  whiteSpace: "nowrap",
                  position: "relative",
                }}
              >
                {SrDetailArray[0]?.attachments.length > 0 &&
                  SrDetailArray[0]?.attachments.map((imageSrc, index) => (
                    <img
                      key={index}
                      src={imageSrc}
                      alt={`Attachment ${index}`}
                      style={{
                        cursor: "pointer",
                        width: "300px",
                        height: "300px",
                        display: currentIndex === index ? "block" : "none",
                      }}
                      onClick={() => handleImageClick(index)}
                    />
                  ))}
                <Button
                  variant="link"
                  className="arrow-button left"
                  onClick={showPreviousImage}
                  style={{
                    position: "absolute",
                    top: "40%",
                    left: "10px",
                    color: "rgb(235, 129, 40)",
                    fontSize: "30px",
                  }}
                >
                  <MdKeyboardArrowLeft />
                </Button>
                <Button
                  variant="link"
                  className="arrow-button right"
                  onClick={showNextImage}
                  style={{
                    position: "absolute",
                    top: "40%",
                    right: "10px",
                    color: "rgb(235, 129, 40)",
                    fontSize: "30px",
                  }}
                >
                  <MdKeyboardArrowRight />
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
export default ServiceListManagement;
