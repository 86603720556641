import React from "react";
import banner from "../../Assets/Home/service-banner.svg";
import banner_mob from "../../Assets/Home/service-banner-mob.svg";
import bottom_wave from "../../../src/Assets/Home/about-wave.svg";
export default function ServiceBanner() {
  return (
    <div className="services-container ">
      <div className="flex-banner">
        <div className="flex-content  res-576px-d-none">
          <div style={{ fontSize: "36px", color: "black", fontWeight: 1000 }}>
            Consumer Electronics & Appliances Industry
          </div>
          <div
            className="text-about-banner"
            style={{
              color: "rgba(26, 26, 26, 0.7)",
              fontSize: "14px",
              marginTop: "20px",
            }}
          >
            {" "}
            Our technicians’ skills are brand agnostic, they excel by experience
            in the field. Whether home-grown or foreign make including Godrej,
            Croma, Sony, Philips, Samsung, LG, IB, Toshiba, Micromax, Panasonic
            or Bosch – we have well-versed technicians who can pull it off
            together.
          </div>

          <button
            style={{
              cursor: "pointer",
              zIndex: 100,
              position: "relative",
              padding: "12px 40px",
              color: "white",
              background: "rgba(235, 129, 40, 1)",
              border: "1px solid transparent",
              borderRadius: "8px",
              marginTop: "30px",
            }}
          >
            Get S3 App
          </button>
        </div>
        <div className="phone-img " style={{ scale: "0.8" }}>
          <img
            className=" res-576px-d-none"
            style={{ marginTop: "-43px", zIndex: 100 }}
            src={banner}
            alt="banner"
          />
        </div>
      </div>
      <div className="vector  res-576px-d-none">
        <img src={bottom_wave} alt="bottom_wave" />
      </div>
      <div className="phone-img res-576px-w-fill res-577px-d-none">
        <img
          className="res-576px-w-fill"
          style={{ marginTop: "-43px", zIndex: 100 }}
          src={banner_mob}
          alt="banner"
        />
      </div>
      <div className=" res-577px-d-none " style={{ padding: "20px" }}>
        <div
          style={{
            fontSize: "25px",
            color: "black",
            fontWeight: 1000,
            textAlign: "center",
          }}
        >
          Consumer Electronics & Appliances Industry
        </div>
        <div
          className="text-about-banner"
          style={{
            color: "rgba(26, 26, 26, 0.7)",
            fontSize: "14px",
            marginTop: "20px",
            textAlign: "center",
          }}
        >
          {" "}
          Our technicians’ skills are brand agnostic, they excel by experience
          in the field. Whether home-grown or foreign make including Godrej,
          Croma, Sony, Philips, Samsung, LG, IB, Toshiba, Micromax, Panasonic or
          Bosch – we have well-versed technicians who can pull it off together.
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            style={{
              cursor: "pointer",
              zIndex: 100,
              position: "relative",
              padding: "12px 40px",
              color: "white",
              background: "rgba(235, 129, 40, 1)",
              border: "1px solid transparent",
              borderRadius: "8px",
              marginTop: "30px",
            }}
          >
            Get S3 App
          </button>
        </div>
      </div>
    </div>
  );
}
