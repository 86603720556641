import React, { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import {
  Paper,
  InputBase,
  Card,
  Avatar,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material/";
import { Box } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import Api from "../../../service/services";
import SpinnerLoader2 from "../../../plugins/SpinnerLoader2";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchableDropdown from "../../common/searchableDropDown";
import "../../../styles/dropdown.css";
import MultiSelectDropDown from "../../../components/common/multiSelectDropDown";
import "../SuperAdminManagement/poductManagement.css";
import FilterModule from "../../../plugins/FilterModule";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const AllZone = () => {
  const [allCustomersDetails, setAllCustomersDetails] = useState([]);
  const [role, setRole] = useState("");
  const [userid, setUserId] = useState("");
  const [loading, setLoading] = useState(false);
  const [filteredDate, setFilteredDate] = useState([]);
  const [showAllDistricts, setShowAllDistricts] = useState(false);
  const [showAllAdmin, setShowAllAdmin] = useState(true);
  const [zonalname, setZonalname] = useState("");
  const [selectedAdmin, setSelectedAdmin] = useState([]);
  const [adminList, setAdminList] = useState([]);
  const [unmappedDistricts, setUnmappedDistricts] = useState([]);
  const [zonalnamechange, setZonalnamechange] = useState(true);
  const [selectedItem, setSelectedItem] = useState([]);
  const [isCreate, setIsCreate] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [searchInput, setSearchInput] = useState("");
  const [filterData, setFilterData] = useState({});
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    const Role = localStorage.getItem("role");
    setRole(Role);
  }, []);
  const getAllZone = () => {
    setLoading(true);
    /* setCancelEnable(false); */
    if (role === "SuperAdmin") {
      Api.getAllZones().then((result) => {
        setAllCustomersDetails(result);
        setFilteredDate(result);
        if (allCustomersDetails.length > 0) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    }
  };
  const handleFilterByName = (event) => {
    let name = event.target.value;
    if (String(name) === "") {
      setFilteredDate(allCustomersDetails);
      setZonalname("");
      setSelectedAdmin([])
      setSelectedItem([]) // Assuming setZoneName is the state update function for zonename
    } else {
      const filteredTests = allCustomersDetails?.filter((service) => {
        const datas = String(service?.zone_name).toLowerCase();
        return datas?.includes(name?.toLowerCase());
      });
      setFilteredDate(filteredTests);
      // Assuming you want to set zonename to the first result's zone_name
      if (filteredTests.length > 0) {
        setZonalname(filteredTests[0]?.zone_name);
      } else {
        setZonalname(""); // or set to some default value
        setSelectedAdmin([])
        setSelectedItem([])
      }
    }
  };


  useEffect(() => {
    getAllZone();
  }, [role]);
  /*  */
  function stringToColor(string) {
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: name ? `${name.split(" ")[0][0]}` : "",
    };
  }

  const handleAdminList = (e) => {
    setSelectedAdmin(e);
  };

  const getDistrictWiseAdmin = async () => {
    await Api.getDistrictWiseTechnician([]).then((result) => {
      result?.length > 0 ? setAdminList(result) : setAdminList([]);
      /* setAdminList(result); */
    });
  };


  const getParticularZone = async (id, user) => {
    if (isSmallScreen) {
      if (user?.id) {
        window.scrollTo({
          top: document.documentElement.scrollHeight, // Scroll to the bottom of the page
          behavior: "smooth", // Smooth scrolling behavior
        });
      }
    }
    setZonalnamechange(false);
    setShowAllDistricts(true);
    setUserId(id);
    setZonalname("");
    await Api.getZoneById(id).then(async (result) => {
      let array = [];
      const citys = result?.mapingDistrict?.map((districtlist) => {
        array.push(districtlist);
        return districtlist?.city_id;
      });
      setZonalname(result?.zone_name);
      setSelectedItem(array);
      getDistrictWiseAdmin(citys);
      console.log("result", array);
      setSelectedDistricts(array); // change this
      setSelectedAdmin({
        id: result?.userValue?.id,
        fullname: `${result?.userValue?.fullname} (${result?.userValue?.district_name})`,
      });
    });
  };

  const HandelClear = () => {
    setCancelEnable(true);
    setShowAllAdmin(false);
    setShowAllDistricts(false);
    setZonalnamechange(true);
    setUserId("");
    setZonalname("");
    setSelectedItem([]);
    setSelectedAdmin([]);
    setAdminList([]);
    setUnmappedDistricts([]);
    createNewZone();
    setSelectedDistricts([]);
  };
  const createNewZone = () => {
    Api.getAllDistricts([]).then((result) => {
      setShowAllDistricts(false);
      setUnmappedDistricts(result);
    });
    getDistrictWiseAdmin();
  };
  const hanldeMappedList = () => {
    setShowAllDistricts(!showAllDistricts);
    Api.getAllDistricts([]).then((result) => {
      setUnmappedDistricts(result);
    });
  };

  const handleZonalName = (e) => {
    setZonalname(e.target.value);
  };

  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(!checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let admin_id;
    const districtIds =
      selectedDistricts &&
      selectedDistricts.map((ele) => ele?.id || ele?.city_id);
    if (selectedAdmin?.id !== null && selectedAdmin?.id !== undefined)
      admin_id = String(selectedAdmin?.id);
    let obj = {
      zone_name: zonalname,
      district_ids: districtIds,
      admin_id,
    };

    if (
      obj.zone_name !== "" &&
      obj.district_ids.length > 0 &&
      obj.admin_id !== ""
    ) {
      Api.AreaManagementCreateZone(obj, checked).then((result) => {
        if (result?.error) {
          Swal.fire({
            text: result?.error,
            showConfirmButton: false,
            timer: 3000,
            iconSize: "1.5em",
          });
        } else {
          Swal.fire({
            text: result,
            showConfirmButton: false,
            timer: 3000,
            iconSize: "1.5em",
          });
          setChecked(false);
          setIsCreate(false)
          setEdit(false)
          setShowAllAdmin(true)
          setCancelEnable(false)
          getAllZone();
        }
      });
    } else {
      Swal.fire({
        text: "All fields are required",
        confirmButtonText: "OK",
      });
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const districtIds =
      selectedDistricts &&
      selectedDistricts.map((ele) => ele?.id || ele?.city_id);

    if (selectedAdmin?.id !== null && selectedAdmin?.id !== undefined) {
      let admin_id;
      admin_id = String(selectedAdmin?.id);
      let obj = {
        zone_name: zonalname,
        district_ids: districtIds,
        admin_id,
      };
      if (
        obj.zone_name !== "" &&
        districtIds.length > 0 &&
        obj.admin_id !== "" &&
        userid !== ""
      ) {
        Api.AreaManagementUpdateZone(obj, userid, checked).then((result) => {
          if (!result?.error) {
            Swal.fire({
              text: result,
              confirmButtonText: "OK",
            });
            getAllZone();
            setAllCustomersDetails(result);
            setFilteredDate(result);
            setShowAllAdmin(true)
            setIsCreate(false)
            setEdit(false)
            setCancelEnable(false)
            setChecked(false)
          } else {
            Swal.fire({
              text: result?.error,
              showConfirmButton: false,
              timer: 3000,
              iconSize: "0.5em",
            });
            // setShowAllAdmin(true)
          }
        });
      } else {
        Swal.fire({
          text: "All fields are required",
          confirmButtonText: "OK",
        });
      }
    }
  };

  useEffect(() => {
    getAllZone();
  }, [role]);
  useEffect(() => {
    filteredDate[0]?.id && getParticularZone(filteredDate[0]?.id);
  }, [role, filteredDate]);

  const [selectedDistricts, setSelectedDistricts] = useState([]);

  const handleDistrictSelection = (selectedValues) => {
    console.log("selectedValues", selectedValues);
    console.log("item", selectedItem);
    setSelectedDistricts(selectedValues);
  };

  const handleDelete = async (id, zonename) => {
    setUserId(id);
    Swal.fire({
      text: `Are you sure, you want to delete ${zonename} Zone?`,
      confirmButtonText: "OK",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Api.DeleteZone(result.isConfirmed, id).then(() => {
          getAllZone();
        });
      }
    });
    /*   */
  };
  const [cancelEnable, setCancelEnable] = useState(false);
  const buttonCancelEnable = () => {
    setCancelEnable(true);
    setIsCreate(true)
  };
  const handleDissable = () => {
    getAllZone();
    setIsCreate(false);
    setCancelEnable(false);
    setShowAllDistricts(false);
    setShowAllAdmin(true);
    setEdit(false)
  }
  const handleSearchInput = useCallback((e) => {
    setSearchInput(e);
    // if (role === "SuperAdmin") {
    Api.getAllZones(e, filterData).then((result) => {
      setAllCustomersDetails(result);
      setFilteredDate(result);
    });
    // }
  }, [setSearchInput, setAllCustomersDetails, setFilteredDate, filterData]);


  // const handleSearchInput = (e) => {
  //   setSearchInput(e);

  //   if (role === "SuperAdmin") {
  //     Api.getAllZones(e, filterData).then((result) => {
  //       setAllCustomersDetails(result);
  //       setFilteredDate(result);
  //     });
  //   }
  // };
  const handleFilterData = (e) => {
    setFilterData(e);
    if (role === "SuperAdmin") {
      Api.getAllZones(e, filterData).then((result) => {
        setAllCustomersDetails(result);
        setFilteredDate(result);
      });
    }
  };



  const handleDistrictOperations = async () => {
    setShowAllDistricts(!showAllDistricts);
    setShowAllAdmin(!showAllAdmin);

    // Get all districts
    const unmappedDistrictsResult = await Api.getAllDistricts([]);
    setUnmappedDistricts(unmappedDistrictsResult);

    // Get district-wise admin
    const adminResult = await Api.getDistrictWiseTechnician([]);
    adminResult?.length > 0 ? setAdminList(adminResult) : setAdminList([]);
  };
  return (
    <>
      {loading ? (
        <SpinnerLoader2 />
      ) : (
        <>
          <div className="filter-container"></div>
          <div className="row" style={{ overflow: "hidden" }}>
            <div className="col-lg-4">
              <FilterModule
                onSearchInputChange={handleSearchInput}
                onChangeFilterData={handleFilterData}
                inputPlaceholder={"Search by Zone Name"}
              />
              {filteredDate?.length !== 0 ? (
                <div
                  className="custom-scrollbar"
                  style={{ width: "100%", height: "70vh", overflowY: "scroll" }}
                >
                  {filteredDate &&
                    filteredDate?.map((data) => (
                      <Card
                        onClick={() => { getParticularZone(data?.id, data); setCancelEnable(false); setIsCreate(false); setEdit(false) }}
                        style={{
                          background:
                            data.id === userid
                              ? "linear-gradient(to right,#FFE1CA , #FFF6EF00)"
                              : "white",
                          border: data.id === userid ? "rgb(235, 129, 40) solid 1px" : "",
                          margin: "2px",
                          padding: "0px",
                          cursor: "pointer",
                          borderRadius: "10px",
                          marginTop: "0.5rem",
                        }}
                      >
                        <Stack
                          direction="row"
                          spacing={1}
                          className="mt-1 mb-2 p-2 me-1 w-100"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <Avatar
                              className=""
                              {...stringAvatar(
                                data?.zone_name ? data?.zone_name : ""
                              )}
                              style={{ display: isSmallScreen ? "none" : "" }}
                            />
                            <div className={`ms-3 ${isSmallScreen ? 'mt-2' : ''}`}>
                              {data?.zone_name && data?.zone_name.length > 15 ? (
                                <Tooltip title={data?.zone_name}>
                                  <text style={{ color: "#1E232C", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    {data?.zone_name.substring(0, 15)}...
                                  </text>
                                </Tooltip>
                              ) : (
                                <text style={{ color: "#1E232C" }}>
                                  {data?.zone_name}
                                </text>
                              )}
                              <div style={{ fontSize: "14px" }}>
                                {data?.district_ids ? (
                                  <text style={{ color: "#1E232C" }}>
                                    {data?.district_ids.length} District{data?.district_ids.length > 1 ? "s" : ""}
                                  </text>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                          <Tooltip title="Delete Zone">
                            <DeleteIcon
                              className=" custom-delete-icon"
                              style={{
                                color: "rgb(235, 129, 40)",
                                cursor: "pointer",
                                backgroundColor: "none",
                                position: "relative", // Add this line
                                top: "1rem",
                                right: "0.6rem",
                              }}
                              onClick={() =>
                                handleDelete(data?.id, data?.zone_name)
                              }
                            />
                          </Tooltip>
                        </Stack>
                      </Card>
                    ))}
                </div>
              ) : (
                <div>
                  <p>No Records Found</p>
                </div>
              )}
            </div>

            <div className="col-lg-8 w-70">
              <div class="d-flex flex-row-reverse align-items-end area-management-align">
                {(filteredDate?.length === 0 || !isCreate) && (filteredDate?.length === 0 || !edit) && (
                  <div className="mt-4">
                    <Button
                      style={{
                        height: "35px", // Set the same height as the first button
                        width: "100px", // Set your desired width
                        color: showAllAdmin ? "rgb(235, 129, 40)" : "white",
                        border: "1px solid rgb(235, 129, 40)",
                        background: showAllAdmin ? "white" : "rgb(235, 129, 40)",
                        textTransform: "none",
                      }}
                      onClick={() => { handleDistrictOperations(); buttonCancelEnable(); }}
                      className="ms-2"
                      id="edit-button"
                    >
                      Edit
                    </Button>
                  </div>
                )}
                {!isCreate && !edit && (
                  <Button
                    className="btn mt-3"
                    variant="contained"
                    type="submit"
                    style={{
                      marginLeft: "25px",
                      height: "35px",
                      width: "100px", // Set your desired width
                      color: "white",
                      backgroundColor: "#EB8128",
                      textTransform: "none",
                      float: "right",
                    }}
                    onClick={() => {
                      HandelClear();
                      buttonCancelEnable();
                    }}
                  >
                    + Create
                  </Button>
                )}

              </div>


              <Box
                component="form"
                autoComplete="off"
                className="mt-5"
                style={{ width: "100%" }}
              >
                {/* {zonalnamechange ? (
                  <TextField
                    className="mt-2"
                    onChange={handleZonalName}
                    label={`Zone Name (${zonalname.length}/32)`}
                    sx={{ minWidth: "140px", width: "50%" }}
                    value={zonalname}

                    inputProps={{ maxLength: 32 }}
                  />
                ) : (
                  <TextField
                    className="mt-2"
                    label={`Zone Name (${zonalname.length}/32)`}
                    onChange={handleZonalName}
                    sx={{ minWidth: "140px", width: "50%" }}
                    value={zonalname}

                    inputProps={{ maxLength: 32 }}
                  />
                )} */}
                <TextField
                  className="mt-2"
                  onChange={handleZonalName}
                  label={`Zone Name (${zonalname.length}/32)`}
                  sx={{ minWidth: "140px", width: "50%" }}
                  value={zonalname}
                  inputProps={{ maxLength: 32 }}
                  InputProps={!isCreate && !edit ? { readOnly: true } : {}}
                />
                <>
                  <div>
                    <div style={{ display: "flex", direction: "row", justifyContent: isSmallScreen ? "space-between" : "", alignItems: isSmallScreen ? "center" : "" }}>
                      {showAllDistricts ? (
                        <FormControl
                          className="mt-3"
                          sx={{
                            minWidth: "140px",
                            width: "50%",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <InputLabel id="admin-list-label">
                            District
                          </InputLabel>
                          <Select
                            renderValue={(selected) => {
                              const data =
                                selected &&
                                selected.map((ele) => {
                                  if (
                                    ele?.city_name !== undefined ||
                                    ele?.name !== undefined
                                  ) {
                                    return `${ele?.city_name
                                      ? ele?.city_name
                                      : ele?.name
                                      }`;
                                  }
                                });
                              return data.join(", ");
                            }}
                            multiple
                            value={selectedItem}
                            ITEM_HEIGHT="48"
                            ITEM_PADDING_TOP="8"
                            className="text-start"
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 200,
                                },
                              },
                            }}
                            label="District"
                            labelId="admin-list-label"
                          >
                            {selectedItem &&
                              selectedItem?.map((name) => {
                                return (
                                  <MenuItem
                                    value={name}
                                    style={{ position: "relative" }}
                                  >
                                    {name.city_name || name.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      ) : (
                        <>
                          <div className="App">
                            <MultiSelectDropDown
                              options={unmappedDistricts}
                              label="name"
                              handleValue={handleDistrictSelection}
                              selectedItem={selectedDistricts}
                            />
                          </div>
                          {/*   <p>{selectedDistricts.city_name}</p> */}
                        </>
                      )}
                      {/* {!isCreate ? <div className="mt-4">
                        {showAllDistricts ? (
                          <Button
                            style={{
                              color: "orange",
                              border: "1px solid orange",
                              background: "white",
                              textTransform: "none",
                            }}
                            onClick={hanldeMappedList}
                            className="ms-2"
                            id="edit-button"
                          >
                            Edit
                          </Button>
                        ) : (
                          <Button
                            style={{
                              color: "white",
                              border: "1px solid orange",
                              background: "orange",
                              textTransform: "none",
                            }}
                            onClick={hanldeMappedList}
                            className="ms-2"
                            id="edit-button"
                          >
                            Edit
                          </Button>
                        )}
                      </div> : <></>} */}
                    </div>
                  </div>
                  <div>
                    <div
                      className=""
                      style={{ display: "flex", direction: "row", justifyContent: isSmallScreen ? "space-between" : "", alignItems: isSmallScreen ? "center" : "" }}
                    >
                      {showAllAdmin ? (
                        <TextField
                          className="selected-admin"
                          sx={{
                            minWidth: "140px",
                            width: "50%",
                            mt: 2,
                          }}
                          label="Zonal Admin"
                          value={selectedAdmin?.fullname || ""}
                          InputProps={{
                            readOnly: true,
                          }}
                          style={{
                            height: "48px",
                          }}
                        />
                      ) : (
                        <div className="App" style={{ minWidth: "140px" }}>
                          <SearchableDropdown
                            options={adminList}
                            label="fullname"
                            id="admin-list"
                            selectedVal={selectedAdmin?.fullname}
                            handleChange={handleAdminList}
                            type="Zonal Admin"
                          />
                        </div>
                      )}

                    </div>
                  </div>
                </>
                {(edit || isCreate) &&
                  <div>
                    <FormControlLabel
                      style={{ marginLeft: "2px" }}
                      control={
                        <Checkbox checked={checked} onChange={handleChange} />
                      }
                    />
                    <text>Force Update</text></div>}

                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: "flex-end" }}>
                  {!zonalnamechange ? (
                    !showAllDistricts ? <Button
                      className="btn mt-3"
                      variant="contained"
                      type="submit"
                      style={{
                        maxHeight: "45px",
                        color: "white",
                        backgroundColor: "#EB8128",
                        textTransform: "none",
                      }}
                      onClick={handleUpdate}
                    >
                      Update
                    </Button> : <></>
                  ) : (
                    <button
                      className="btn ms-2 mt-3"
                      variant="contained"
                      type="submit"
                      style={{
                        maxHeight: "45px",
                        color: "white",
                        backgroundColor: "#EB8128",
                        textTransform: "none",
                      }}
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  )}
                  {cancelEnable || !showAllDistricts ? (
                    <Button
                      className="btn ms-2 mt-3"
                      variant="contained"
                      type="submit"
                      style={{
                        maxHeight: "45px",
                        color: "white",
                        backgroundColor: "gray",
                        textTransform: "none",
                      }}
                      onClick={() => {
                        handleDissable()
                      }}
                    >
                      Cancel
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
              </Box>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default AllZone;
