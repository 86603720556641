import React from "react";

const Promotion = () => {
  return (
    <div className="promotion-container">
      <div>
        <div style={{ fontSize: "40px", fontWeight: 600 }}>15+</div>
        <div
          style={{
            fontSize: "14px",
            color: "rgb(26 26 26 / 45%)",
            marginTop: "10px",
            textAlign: "center",
          }}
        >
          Appliances
        </div>
      </div>
      <div className="border-right"></div>
      <div>
        <div style={{ fontSize: "40px", fontWeight: 1000 }}>3-in-1</div>
        <div
          style={{
            fontSize: "14px",
            color: "rgb(26 26 26 / 45%)",
            marginTop: "10px",
            textAlign: "center",
          }}
        >
          S3 App
        </div>
      </div>
      <div className="border-right"></div>

      <div>
        <div style={{ fontSize: "40px", fontWeight: 600 }}>281+</div>
        <div
          style={{
            fontSize: "14px",

            color: "rgb(26 26 26 / 45%)",
            marginTop: "10px",
            textAlign: "center",
          }}
        >
          Cities
        </div>
      </div>
      <div className="border-right"></div>

      <div>
        <div style={{ fontSize: "40px", fontWeight: 600 }}>450+</div>
        <div
          style={{
            fontSize: "14px",
            color: "rgb(26 26 26 / 45%)",
            marginTop: "10px",
            textAlign: "center",
          }}
        >
          Technicians
        </div>
      </div>
    </div>
  );
};

export default Promotion;
