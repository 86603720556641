import React, { useEffect, useState } from "react";
import Api from "../../service/services";
import Topbar from "../topbar";
import Sidebar from "../Sidebar";
import { useNavigate } from "react-router";
import Card from "@mui/material/Card";
const AllAppliences = () => {
  const [ApplianceData, setApplianceData] = useState([]);
  const navigate = useNavigate();
  const [selectedApplianceId, setSelectedApplianceId] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    Api.getAppliances().then((res) => {
      setApplianceData(res.data);
    });
  }, []);

  const handleImageClick = (image, appliance_id) => {
    setSelectedImage(image);
    setSelectedApplianceId(appliance_id);
  };

  const createNewService = () => {
    navigate("/createService", {
      state: {
        data: {
          applience_id: selectedApplianceId,
        },
      },
    });
  };
  return (
    <>
      <div>
        <Topbar />
        <div
          className="row container-fluid"
          style={{ paddingRight: "0px", overflow: "hidden" }}
        >
          <div className="col-2 col-12 p-0 border border-right">
            <Sidebar />
          </div>
          <div
            className="col-12 service_list Main_menu "
            style={{ marginTop: "80px" }}
          >
            <div
              className="row"
              style={{ paddingRight: "0px", marginTop: "30px" }}
            >
              <div style={{ fontSize: "21px", fontWeight: "600" }}>
                <p
                  className="p-0 res-font"
                  style={{
                    fontWeight: "600",
                    color: "#1E232C",
                    fontSize: "21px",
                    width: "208px",
                    height: "32px",
                  }}
                >
                  All Appliances
                </p>
              </div>
              <div className="col-lg text-end">
                <button
                  className="btn btn-primary mt-3"
                  onClick={createNewService}
                  style={{ backgroundColor: "#EB8128", borderColor: "#EB8128" }}
                >
                  Service Request
                </button>
              </div>
            </div>

            <div className="row">
              {ApplianceData.length > 0
                ? ApplianceData.map((appliance, index) => (
                    <div className="col-lg-3">
                      <Card elevation={3} className="mt-3 p-3">
                        <div
                          style={{
                            outline:
                              selectedImage === appliance.appliance_name
                                ? "2px solid rgb(235, 129, 40)"
                                : "none",
                          }}
                        >
                          <img
                            className="dash_img"
                            src={appliance.appliance_image}
                            alt="demo"
                            style={{
                              width: "160px",
                              height: "180px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleImageClick(
                                appliance.appliance_name,
                                appliance.appliance_id
                              )
                            }
                          />
                          <p
                            className="ms-4 mt-4"
                            //  style={{ color: selectedImage === appliance.appliance_name ? 'rgb(235, 129, 40)' : 'black' }}
                          >
                            {appliance.appliance_name}
                          </p>
                        </div>
                      </Card>
                    </div>
                  ))
                : ""}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AllAppliences;
