import React, { useEffect } from "react";
import AboutBanner from "./AboutBanner";
import Navbar from "../common/Navbar";
import Footer from "../Footer";
import AboutVision from "./AboutVision";
import AboutMission from "./AboutMission";
import AboutServices from "./AboutServices";
import Expertise from "./Expertise";

export default function UpdatedAbout() {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    const body = document.querySelector("body");
    body.classList.remove("res-nav-body-constructor");
  }, []);
  return (
    <div>
      <Navbar />
      <div className="shade-provider"></div>
      <AboutBanner />
      <AboutServices />
      <AboutVision />
      <Expertise />
      <AboutMission />
      <Footer />
    </div>
  );
}
