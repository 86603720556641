import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  InputLabel,
  OutlinedInput,
  TextField,
  useMediaQuery,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import Api from "../../../service/services";
import Swal from "sweetalert2";

const ConfigureService = () => {
  const [IsAuto, setIsAuto] = useState(true);
  const [autoAssignConfigList, setAutoAssignConfigList] = useState({});
  const [maxResponseTime, setMaxResponseTime] = useState("");
  const [autoAssignLimit, setAutoAssignLimit] = useState("");
  const [maxRadius, setMaxRadius] = useState("");
  const mobileView = useMediaQuery("(min-width: 200px) and (max-width: 799px)");
  useEffect(() => {
    Api.autoAssignConfig().then((res) => {
      setAutoAssignConfigList(res);
      setMaxResponseTime(res?.max_response_time);
      setAutoAssignLimit(res?.auto_assign_limit);
      setMaxRadius(res?.max_radius);
    });
  }, []);
  const onsubmit = (id) => {
    const payload = {
      max_response_time: maxResponseTime,
      auto_assign_limit: autoAssignLimit,
      max_radius: maxRadius,
    };
    Api.updateAutoAssign(id, payload)
      .then((res) => {
        Swal.fire({
          text: res,
        });
        console.log("UpdateautoAssignConfig", res);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  return (
    <>
      <div className="container-fluid w-100 font-family-Arial">
        {/*  <p className="m-0" style={{ color: "#6A707C", fontSize: "16px" }}>
          Service Request Configuration
        </p> */}
        {/* 
        <RadioGroup row>
          <FormControlLabel
            value="auto"
            onChange={() => setIsAuto(true)}
            control={<Radio checked={IsAuto} />}
            label="Auto-Assign"
          />
        </RadioGroup> */}
        {/* <FormControlLabel value="manual" onChange={()=>setIsAuto(false)} control={<Radio checked={!IsAuto} />} label="Manual Assign" /> */}
        {/* Auto-Assign */}
        {IsAuto ? (
          <div /* className="custom-scrollbar" */>
            <div className="row w-100">
              <div className="col-lg-8 col-12">
                <div className="row">
                  <div
                    className="col-9"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <>
                      <p
                        className="m-0"
                        style={{
                          color: "rgb(235, 129, 40)",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        Waiting Time
                      </p>
                      <p
                        className="m-0 mt-2"
                        style={{
                          color: "black",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Set the frequency of waiting time / re-assign of each SR
                      </p>
                    </>
                    <div className="col-3 mt-3 mb-3 w-100">
                      <TextField
                        id="outlined-adornment-weight"
                        label="Time"
                        aria-describedby="outlined-weight-helper-text"
                        InputProps={{
                          "aria-label": "weight",
                          endAdornment: (
                            <InputAdornment position="start">
                              Minutes
                            </InputAdornment>
                          ),
                        }}
                        onChange={(val) => setMaxResponseTime(val.target.value)}
                        value={maxResponseTime}
                        style={{ width: mobileView ? "10rem" : "200px" }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="mt-2 mb-2"
                  style={{ border: "1px dotted #000", borderWidth: "1px" }}
                ></div>

                <p
                  className="m-0 mt-4"
                  style={{
                    color: "rgb(235, 129, 40)",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  Boundary Setting
                </p>
                <p
                  className="m-0 mt-2"
                  style={{
                    color: "black",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Set the search boundry for finding the technicians. S3 will
                  look for the technicians within the given radius from the SR
                  location.
                </p>

                {/* 
                            <RadioGroup row>
                                <FormControlLabel value="kms" control={<Radio />} label="Kms" />
                                <FormControlLabel value="m" control={<Radio />} label="m" />
                            </RadioGroup> */}

                <div className="row mt-3 mb-4 w-100">
                  <div className="col-3 mt-3 mb-3 w-100">
                    <TextField
                      label="Radius"
                      id="outlined-adornment-weight"
                      size="sm"
                      value={maxRadius}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">Kms</InputAdornment>
                        ),
                      }}
                      onChange={(event) => {
                        const value = event.target.value;
                        if (/^[0-9]*$/.test(value)) {
                          // Check if it's a valid whole number
                          setMaxRadius(value); // Set the state to the input value (if it's a whole number)
                        }
                      }}
                      style={{ width: mobileView ? "10rem" : "200px" }}
                    />
                    {/* <div className="ml-4 ms-2">Kms</div> */}
                  </div>
                  {/* <div className="col-lg-3 col-6">
                                    <TextField
                                        label='Boundry 2'
                                        size="sm"
                                    />
                                </div>
                                <div className="col-lg-3 col-6">
                                    <TextField
                                        label='Boundry 3'
                                        size="sm"
                                    />
                                </div>
                                <div className="col-lg-3 col-6">
                                    <TextField
                                        label='Boundry 4'
                                        size="sm"
                                    />
                                </div> */}
                </div>
                <div
                  className="mt-2 mb-2"
                  style={{ border: "1px dotted #000", borderWidth: "1px" }}
                ></div>
                <p
                  className="m-0 mt-4"
                  style={{
                    color: "rgb(235, 129, 40)",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                >
                  Hierarchy Setting
                </p>
                <div className="row mt-3 mb-4 w-100">
                  <div className="col-3 mt-3 mb-3 w-100">
                    {/*  <TextField
                      label="Assign"
                      size="sm"
                      value={autoAssignLimit}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            Peoples
                          </InputAdornment>
                        ),
                      }}
                      onChange={(val) => setAutoAssignLimit(val.target.value)}
                      style={{ width: "200px" }}
                    /> */}
                    <TextField
                      label="Assign"
                      size="sm"
                      value={autoAssignLimit}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            Peoples
                          </InputAdornment>
                        ),
                      }}
                      onChange={(event) => {
                        const value = event.target.value;
                        if (/^[1-9]*$/.test(value)) {
                          // Check if it consists of digits from 1 to 9
                          setAutoAssignLimit(value); // Set the state to the input value (if it's in the specified range)
                        }
                      }}
                      style={{ width: mobileView ? "10rem" : "200px" }}
                    />
                    {/* <div className="ml-2 ms-2">Times</div> */}
                  </div>
                  {/* <div className="col-lg-3 col-6">
                                    <TextField
                                        label='Repeat Limit '
                                        size="sm"
                                        className="w-100"
                                        select
                                    >
                                        <option>one</option>
                                    </TextField>
                                </div>
                                <div className="col-lg-3 col-6">
                                    <TextField
                                        label='Then Assign to'
                                        size="sm"
                                        className="w-100"
                                        select
                                    >
                                        <option>one</option>
                                    </TextField>
                                </div>
                                <div className="col-lg-3 col-6">
                                    <TextField
                                        label='Repeat Limit '
                                        size="sm"
                                        className="w-100"
                                        select
                                    >
                                        <option>one</option>
                                    </TextField>
                                </div> */}
                </div>
              </div>
            </div>
            <button
              onClick={() => onsubmit(autoAssignConfigList?.id)}
              className="mt-3 mb-3"
              style={{
                background: "rgb(235, 129, 40)",
                color: "#fff",
                border: "none",
                outline: "none",
                borderRadius: "5px",
                height: "40px",
                width: "120px",
              }}
            >
              Save
            </button>
          </div>
        ) : null}

        {/* Manual Assign */}
      </div>
    </>
  );
};
export default ConfigureService;
