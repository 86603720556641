import React from "react";
import Success from "../../Assets/Cust/Success Icon.svg";
import Logo from "../../Assets/Cust/pass-changed.svg";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../Footer";
import S3NewLogo from "../../Assets/s3_new_logo/S3NewLogo";
const Passworddone = () => {
  const navigate = useNavigate();
  return (
    <div className="">
      <div className="container-fluid">
        <div
          // className={`col-lg-5 col-md-6 col-sm-12 ${isZoomed ? "d-none" : ""}`}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
          }}
          className="res-576px-d-unset mt--50px"
        >
          <div
            className="p-0 res-576px-d-none"
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              width: "43.5%",
              padding: "60px",
              justifyContent: "center",
            }}
          >
            <div
              className="logo_container"
              style={{
                // position: "fixed",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "20px",
                marginTop: "100px",
              }}
            >
              <div onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
                <S3NewLogo
                  color={"rgba(235, 129, 40, 1)"}
                  // style={{ scale: "1.2" }}
                />
              </div>
              <img alt="demo" src={Logo} className="logo" />
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                  textAlign: "center",
                  width: "76%",
                }}
              >
                Secure Sales Service
              </div>
              <div
                style={{
                  fontSize: "10px",
                  textAlign: "center",
                  width: "76%",
                  color: "rgba(0, 0, 0, 1)",
                }}
                className="mt--10px"
              >
                A friendly neighbourhood handyman and customers satisfied with
                instant service
              </div>
            </div>
          </div>
          <div className="col-lg-1 border-register-right res-576px-d-none"></div>
          <div className="res-577px-d-none mb-4" onClick={() => navigate("/")}>
            <S3NewLogo
              color={"rgba(235, 129, 40, 1)"}
              // style={{ scale: "1.2" }}
            />
          </div>
          <div
            style={{
              width: "56.5%",
            }}
            className="res-576px-w-fill"
          >
            <div className="p-lg-5 p-md-3 p-2" style={{}}>
              <div className="text-center">
                <img alt="demo" src={Success} className="w-40 mt-lg-5 mt-3" />
              </div>

              <div className="d-flex align-items-center">
                <div>
                  {/* <div
                    onClick={() => navigate(-1)}
                    className="text-dark text-decoration-none"
                  >
                    <i className="bi bi-chevron-left me-1 fs-6"></i>
                    <span className="font-weight-bold fs-6">Login</span>
                  </div> */}
                </div>
              </div>
              <form className="p-lg-4 p-2">
                <div className="text-center mt-lg-4">
                  <p
                    style={{
                      fontWeight: "700",
                      fontSize: "21px",
                      lineHeight: "26px",
                    }}
                  >
                    Password Changed!
                  </p>
                  <p
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#6A707C",
                    }}
                  >
                    Your password has been changed successfully.
                  </p>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="d-grid gap-2 mt-3">
                      <Link to="/login">
                        {" "}
                        <button
                          className="btn"
                          type="button"
                          style={{
                            height: "56px",
                            width: "100%",
                            color: "white",
                            backgroundColor: "#EB8128",
                            textTransform: "capitalize",
                          }}
                        >
                          Back to Login
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "100px" }} className="res-576px-mt-30px">
        <Footer topscroller />
      </div>
    </div>
  );
};
export default Passworddone;
