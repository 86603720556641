import React from "react";
import banner from "../../Assets/Home/about-banner.svg";
import banner_mob from "../../Assets/Home/about-banner-mob.svg";
import bottom_wave from "../../../src/Assets/Home/about-wave.svg";
import { useNavigate } from "react-router-dom";

export default function AboutBanner() {
  const navigate = useNavigate();
  return (
    <div className="about-container ">
      <div style={{ height: 0, width: 0 }}>
        <div className="circle_vector">
          <div className="circle_1">
            <div className="circle_2">
              <div className="circle_3"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-banner res-576px-d-none">
        <div className="flex-content ">
          <div style={{ fontSize: "36px", color: "black", fontWeight: 1000 }}>
            Welcome to S3 - Secure, Sales, Service
          </div>
          <div
            className="text-about-banner"
            style={{
              color: "rgba(26, 26, 26, 0.7)",
              fontSize: "14px",
              marginTop: "20px",
            }}
          >
            {" "}
            A pioneering platform crafted by the Electronics, Technicians
            Association to redefine your experience with electronics devices
            care.
          </div>
          <div
            className="text-about-banner"
            style={{
              color: "rgba(26, 26, 26, 0.7)",
              fontSize: "14px",
              marginTop: "10px",
            }}
          >
            We are committed to excellence, a testament to innovation, and
            assure you of delivering expertise at your doorstep.
          </div>
          <button
            style={{
              cursor: "pointer",
              zIndex: 100,
              position: "relative",
              padding: "12px 40px",
              color: "white",
              background: "rgba(235, 129, 40, 1)",
              border: "1px solid transparent",
              borderRadius: "8px",
              marginTop: "30px",
            }}
          onClick={() => navigate("/login")}

          >
            Get Started
          </button>
        </div>
        <div className="phone-img" style={{ scale: "0.8" }}>
          <img
            style={{ marginTop: "-43px", zIndex: 100 }}
            src={banner}
            alt="banner"
          />
        </div>
      </div>
      <div className="vector res-576px-d-none">
        <img src={bottom_wave} alt="bottom_wave" />
      </div>
      <div className="res-577px-d-none ">
        <img
          src={banner_mob}
          alt="banner_mob"
          style={{ width: " -webkit-fill-available" }}
        />
      </div>
      <div className="flex-content res-577px-d-none">
        <div
          style={{ fontSize: "36px", color: "black", fontWeight: 1000 }}
          className="res-576px-font-25px"
        >
          Welcome to S3 - Secure, Sales, Service
        </div>
        <div
          className="text-about-banner"
          style={{
            color: "rgba(26, 26, 26, 0.7)",
            fontSize: "14px",
            marginTop: "20px",
          }}
        >
          {" "}
          A pioneering platform crafted by the Electronics, Technicians
          Association to redefine your experience with electronics devices care.
        </div>
        <div
          className="text-about-banner"
          style={{
            color: "rgba(26, 26, 26, 0.7)",
            fontSize: "14px",
            marginTop: "10px",
          }}
        >
          We are committed to excellence, a testament to innovation, and assure
          you of delivering expertise at your doorstep.
        </div>
        <button
          style={{
            cursor: "pointer",
            zIndex: 40,
            position: "relative",
            padding: "12px 40px",
            color: "white",
            background: "rgba(235, 129, 40, 1)",
            border: "1px solid transparent",
            borderRadius: "8px",
            marginTop: "30px",
          }}
          onClick={() => navigate("/login")}
        >
          Get Started
        </button>
      </div>
    </div>
  );
}
