import React from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const SuperAdminChart1 = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { districtAdminDashboardData } = useSelector(
    (state) => state.districtAdminDashboardState
  );
  const { zonalAdminDashboardData } = useSelector(
    (state) => state.zonalAdminDashboardState
  );
  const { superAdminDashboardData } = useSelector(
    (state) => state.superAdminDashboardState
  );
  const Role = localStorage.getItem("role");

  const adminData = () => {
    if (Role === "DistrictAdmin") {
      return districtAdminDashboardData;
    } else if (Role === "ZonalAdmin") {
      return zonalAdminDashboardData;
    } else if (Role === "SuperAdmin") {
      return superAdminDashboardData;
    }
  };

  const data_1 = adminData()?.srCount?.unassigned;
  const data_2 = adminData()?.srCount?.assigned;
  const data_3 = adminData()?.srCount?.accepted;
  const data_4 = adminData()?.srCount?.notified;
  const data_5 = adminData()?.srCount?.closed;
  const data_6 = adminData()?.srCount?.reopened;
  const data_7 = adminData()?.srCount?.cancelled;

  // Calculate percentages based on the values
  const total = data_1 + data_2 + data_3 + data_4 + data_5 + data_6 + data_7;
  const percentage_1 = parseFloat(((data_1 / total) * 100).toFixed(2));
  const percentage_2 = parseFloat(((data_2 / total) * 100).toFixed(2));
  const percentage_3 = parseFloat(((data_3 / total) * 100).toFixed(2));
  const percentage_4 = parseFloat(((data_4 / total) * 100).toFixed(2));
  const percentage_5 = parseFloat(((data_5 / total) * 100).toFixed(2));
  const percentage_6 = parseFloat(((data_6 / total) * 100).toFixed(2));
  const percentage_7 = parseFloat(((data_7 / total) * 100).toFixed(2));

  const series = [
    percentage_1,
    percentage_2,
    percentage_3,
    percentage_4,
    percentage_5,
    percentage_6,
    percentage_7,
  ];
  console.log(series);
  const options = {
    chart: {
      type: "donut",
      // Adjust the donut size
      width: 380,
      plotOptions: {
        pie: {
          size: "80%", // Increase or decrease the size as needed (in percentage)
        },
      },
    },
    labels: [
      `Unassigned - ${data_1}`,
      `Assigned - ${data_2}`,
      `Accepted - ${data_3}`,
      `Notified - ${data_4}`,
      `Closed - ${data_5}`,
      `Reopened - ${data_6}`,
      `Cancelled - ${data_7}`,
    ],
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val) {
          return ""; // Hide the series value
        },
      },
      x: {
        show: false, // Hide x value
      },
      marker: {
        show: false, // Hide marker
      },
      item: {
        show: false, // Hide item
      },
      fixed: {
        enabled: true,
        position: "topRight", // Position the tooltip
      },
    },
  };

  return (
    <div>
      <div
        // className="d-flex align-items-center"
        id="chart"
        style={{ height: isMobile ? 180 : 320, overflow: "scroll" }}
      >
        <ReactApexChart
          options={options}
          series={series}
          type="donut"
          height={270}
        />
      </div>
    </div>
  );
};

export default SuperAdminChart1;
