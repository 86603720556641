import React from "react";
import { useNavigate } from "react-router-dom";
import Topbar from "../topbar";
import Sidebar from "../Sidebar";
import img1 from "../../Images/wallet-image.png";
import { Stack, Card, Avatar, Paper, InputBase } from "@mui/material";
import InputMask from "react-input-mask";
import visa from "../../Images/visa.png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import img2 from "../../Images/googlepay.png";
import img3 from "../../Images/paytm.png";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

const Wallet = () => {
  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <div className="container-fluid">
        <Topbar />
        <div className="row">
          <div className="col-lg-2 col-12 p-0 border border-right">
            <Sidebar open1={true} />
          </div>
          <div className="col-lg-10 col-12">
            <div className="container-fluid">
              <div className="row">
                <div
                  className="mt-lg-2 sticky-top bg-white"
                  style={{ position: "sticky", zIndex: "2" }}
                >
                  <i
                    className="bi bi-chevron-left me-2"
                    style={{
                      fontWeight: "800",
                      fontSize: "21px",
                      width: "20px",
                      height: "20px",
                    }}
                    onClick={() => navigate(-1)}
                  ></i>
                  <text
                    className="p-0"
                    style={{
                      fontWeight: "600",
                      color: "#1E232C",
                      fontSize: "21px",
                      width: "208px",
                      height: "32px",
                    }}
                  >
                    Wallet
                  </text>
                </div>
                <div className="col-lg-6 col-12 mt-lg-3 ms-lg-4">
                  <img alt="demo" style={{ width: "500px" }} src={img1} />
                  <p
                    className="mt-lg-2"
                    style={{ fontSize: "17px", color: "#6A707C" }}
                  >
                    Please select or enter the amount you would like to add in
                    your wallet
                  </p>
                  <Stack direction="row" spacing={1}>
                    <button type="button" className="btn btn-outline btn-lg">
                      100
                    </button>
                    <button type="button" className="btn btn-outline btn-lg">
                      250
                    </button>
                    <button type="button" className="btn btn-outline btn-lg">
                      500
                    </button>
                    <button type="button" className="btn btn-outline btn-lg">
                      750
                    </button>
                    <button type="button" className="btn btn-outline btn-lg">
                      Enter Amount
                    </button>
                  </Stack>

                  <Card className="mt-lg-3">
                    <div className="container-fluid mt-lg-3 mb-lg-4 ">
                      <Accordion
                        expanded={expanded === "panel1"}
                        onChange={handleChange("panel1")}
                      >
                        <AccordionSummary
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography>
                            <div>
                              <input
                                style={{ borderRadius: "10px" }}
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                                checked={expanded === "panel1"}
                              />
                              <text
                                className="ms-2"
                                style={{ fontSize: "18px", fontWeight: "400" }}
                              >
                                UPI
                              </text>
                            </div>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <div>
                              <ul
                                className="m-0 p-0"
                                style={{ display: "inline-flex" }}
                              >
                                <li style={{ listStyle: "none" }}>
                                  <div
                                    className="border border rounded"
                                    style={{ width: "123px", height: "48px" }}
                                  >
                                    <img
                                      alt="demo"
                                      className="ms-4 mt-3"
                                      src={img2}
                                      style={{ width: "55px" }}
                                    />
                                  </div>
                                </li>
                                <li
                                  className="ms-3"
                                  style={{ listStyle: "none" }}
                                >
                                  <div
                                    className="border border rounded "
                                    style={{ width: "123px", height: "48px" }}
                                  >
                                    <img
                                      alt="demo"
                                      className="ms-4 mt-3"
                                      src={img3}
                                      style={{ width: "55px" }}
                                    />
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="mt-2">
                              <TextField
                                className="paymetinput"
                                style={{ width: "380px" }}
                                fullWidth
                                label="Enter UPI ID"
                                placeholder="9500000095@upiid"
                                id="fullWidth"
                              >
                                <i
                                  style={{ color: "#EB8128" }}
                                  class="bi bi-check-circle"
                                ></i>
                              </TextField>
                            </div>
                            <button
                              class="btn mt-2 btn-lg"
                              type="button"
                              style={{
                                width: "185px",
                                height: "47px",
                                color: "white",
                                backgroundColor: "#EB8128",
                                borderColor: "#EB8128",
                              }}
                            >
                              Pay 500{" "}
                            </button>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        expanded={expanded === "panel3"}
                        onChange={handleChange("panel3")}
                      >
                        <AccordionSummary
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography>
                            <div>
                              <input
                                style={{ borderRadius: "10px" }}
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                                checked={expanded === "panel3"}
                                readOnly
                              />
                              <text
                                className="ms-2"
                                style={{ fontSize: "18px", fontWeight: "400" }}
                              >
                                Debit / Credit Card
                              </text>
                            </div>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <InputMask
                              mask="9999 - 9999 - 9999 - 9999"
                              maskChar="X"
                              formatChars={{ 9: "[0-9]" }}
                            >
                              {() => (
                                <TextField
                                  style={{ width: "445px" }}
                                  className=" mt-lg-1"
                                  id="outlined-basic"
                                  defaultValue="4731 - "
                                  label="Card Number"
                                  variant="outlined"
                                  placeholder="XXXX - XXXX - XXXX - XXXX"
                                  // error={Iscard ? true : false}
                                  // helperText={Iscard ? "Please Type Your Card Details" : null}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        style={{ color: "red" }}
                                        position="start"
                                        className="text-primary"
                                      >
                                        <img alt="demo" src={visa} />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                            </InputMask>

                            <div className="row mt-3">
                              <div className="col-5">
                                <TextField
                                  fullWidth
                                  label="Expiry Date"
                                  id="fullWidth"
                                  placeholder="MM / YYYY"
                                />
                              </div>
                              <div className="col-5">
                                <TextField
                                  fullWidth
                                  label="CVV Number"
                                  id="fullWidth"
                                  placeholder="XXX"
                                />
                              </div>
                              <div className="col-3"></div>
                            </div>

                            <TextField
                              className="mt-3"
                              style={{ width: "445px" }}
                              label="Card Holder Name"
                              placeholder="Enter card holder name"
                            />

                            <div className="mt-3">
                              <input
                                style={{ borderRadius: "10px" }}
                                class="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                value="option1"
                              />
                              <text
                                className="ms-3"
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "400",
                                  color: "#6A707C",
                                }}
                              >
                                Accept to save card as per the RBI Regulations
                              </text>
                            </div>

                            <button
                              class="btn btn-lg mt-2"
                              type="button"
                              style={{
                                width: "185px",
                                height: "47px",
                                color: "white",
                                backgroundColor: "#EB8128",
                                borderColor: "#EB8128",
                              }}
                            >
                              Pay 500
                            </button>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </Card>
                </div>

                <div className="col-lg-5 col-12">
                  <text
                    className="p-0 fa fa-bold mb-lg-5"
                    style={{
                      fontWeight: "600",
                      color: "#292929",
                      fontSize: "16px",
                    }}
                  >
                    Latest Transaction
                  </text>

                  <ul className="p-0 mt-3" style={{ display: "inline-flex" }}>
                    <li className="ms-2" style={{ listStyle: "none" }}>
                      <Paper
                        elevation={0}
                        className="w-100 p-lg-3 border rounded-0"
                        component="form"
                        sx={{ display: "flex", alignItems: "center" }}
                        style={{ width: "100%", height: "42px" }}
                      >
                        <IconButton
                          type="button"
                          sx={{ p: "10px" }}
                          aria-label="search"
                        >
                          <SearchIcon />
                        </IconButton>
                        <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          style={{
                            fontFamily: "Arial",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "14px",
                          }}
                          placeholder="Search by Order ID"
                          inputProps={{ "aria-label": "search google maps" }}
                        />
                      </Paper>
                    </li>
                    <li className="ms-2" style={{ listStyle: "none" }}>
                      <IconButton
                        style={{ width: "42px", height: "42px" }}
                        className="border rounded-0"
                        aria-label="delete"
                      >
                        <FilterListIcon
                          style={{ color: "rgb(235, 129, 40)" }}
                        />
                      </IconButton>
                    </li>
                    <li className="ms-2" style={{ listStyle: "none" }}>
                      <IconButton
                        style={{ width: "42px", height: "42px" }}
                        className="border rounded-0"
                        aria-label="delete"
                      >
                        <ArrowCircleDownIcon
                          style={{ color: "rgb(235, 129, 40)" }}
                        />
                      </IconButton>
                    </li>
                  </ul>
                  <div className="custom-scrollbar">
                    <Card className="me-lg-2">
                      <div className="row w-100 mt-3 ms-2">
                        <div className="col-2 p-0 text-center">
                          <Avatar
                            style={{
                              color: "#EB8128",
                              backgroundColor: "#FFE5CC",
                            }}
                          >
                            SR
                          </Avatar>
                        </div>
                        <div className="col p-0 ">
                          <p
                            className="mb-3"
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#1E232C",
                            }}
                          >
                            Order Spare for #SR562C
                          </p>
                          <p
                            className="mb-3"
                            style={{
                              fontSize: "13px",
                              fontWeight: "300",
                              color: "#6A707C",
                            }}
                          >
                            Today, 12 Jul at 10.20 | UPI
                          </p>
                          <p
                            className="mb-3"
                            style={{
                              fontSize: "13px",
                              fontWeight: "300",
                              color: "#6A707C",
                            }}
                          >
                            Transaction ID: 236656568
                          </p>
                        </div>
                        <div className="col-3 p-0">
                          <p
                            className="ms-lg-4"
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            ₹2,034.72
                          </p>
                        </div>
                      </div>
                      <div className="border border-bottom w-100"></div>
                    </Card>

                    <Card className="mt-lg-2 me-lg-2">
                      <div className="row w-100 mt-3 ms-2">
                        <div className="col-2 p-0 text-center">
                          <Avatar
                            style={{
                              color: "#EB8128",
                              backgroundColor: "#FFE5CC",
                            }}
                          >
                            SR
                          </Avatar>
                        </div>
                        <div className="col p-0 ">
                          <p
                            className="mb-3"
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#1E232C",
                            }}
                          >
                            Order Spare for #SR562C
                          </p>
                          <p
                            className="mb-3"
                            style={{
                              fontSize: "13px",
                              fontWeight: "300",
                              color: "#6A707C",
                            }}
                          >
                            Today, 12 Jul at 10.20 | Cash
                          </p>
                        </div>
                        <div className="col-3 p-0">
                          <p
                            className="ms-lg-4"
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            ₹2,400.32
                          </p>
                        </div>
                      </div>
                      <div className="border border-bottom w-100"></div>
                    </Card>

                    <Card className="mt-lg-2 me-lg-2">
                      <div className="row w-100 mt-3 ms-2">
                        <div className="col-2 p-0 text-center">
                          <Avatar
                            style={{
                              color: "#EB8128",
                              backgroundColor: "#FFE5CC",
                            }}
                          >
                            SR
                          </Avatar>
                        </div>
                        <div className="col p-0 ">
                          <p
                            className="mb-3"
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#1E232C",
                            }}
                          >
                            Added to wallet
                          </p>
                          <p
                            className="mb-3"
                            style={{
                              fontSize: "13px",
                              fontWeight: "300",
                              color: "#6A707C",
                            }}
                          >
                            Today, 12 Jul at 10.20 | UPI
                          </p>
                          <p
                            className="mb-3"
                            style={{
                              fontSize: "13px",
                              fontWeight: "300",
                              color: "#6A707C",
                            }}
                          >
                            Transaction ID: 236656568
                          </p>
                        </div>
                        <div className="col-3 ms-lg-3 p-0">
                          <p
                            className="ms-lg-4"
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#36A93F",
                            }}
                          >
                            ₹500
                          </p>
                        </div>
                      </div>
                      <div className="border border-bottom w-100"></div>
                    </Card>

                    <Card className="mt-lg-2 me-lg-2">
                      <div className="row w-100 mt-3 ms-2">
                        <div className="col-2 p-0 text-center">
                          <Avatar
                            style={{
                              color: "#EB8128",
                              backgroundColor: "#FFE5CC",
                            }}
                          >
                            SR
                          </Avatar>
                        </div>
                        <div className="col p-0 ">
                          <p
                            className="mb-3"
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#1E232C",
                            }}
                          >
                            Order Spare for #SR562C
                          </p>
                          <p
                            className="mb-3"
                            style={{
                              fontSize: "12px",
                              fontWeight: "300",
                              color: "#6A707C",
                            }}
                          >
                            Today, 12 Jul at 10.20 | Debit/Credit Card
                          </p>
                          <p
                            className="mb-3"
                            style={{
                              fontSize: "13px",
                              fontWeight: "300",
                              color: "#6A707C",
                            }}
                          >
                            Transaction ID: 236656568
                          </p>
                        </div>
                        <div className="col-3 p-0">
                          <p
                            className="ms-lg-4 me-lg-1"
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            ₹2,400.02
                          </p>
                        </div>
                      </div>
                      <div className="border border-bottom w-100"></div>
                    </Card>
                    <Card className="me-lg-2">
                      <div className="row w-100 mt-3 ms-2">
                        <div className="col-2 p-0 text-center">
                          <Avatar
                            style={{
                              color: "#EB8128",
                              backgroundColor: "#FFE5CC",
                            }}
                          >
                            SR
                          </Avatar>
                        </div>
                        <div className="col p-0 ">
                          <p
                            className="mb-3"
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#1E232C",
                            }}
                          >
                            Order Spare for #SR562C
                          </p>
                          <p
                            className="mb-3"
                            style={{
                              fontSize: "13px",
                              fontWeight: "300",
                              color: "#6A707C",
                            }}
                          >
                            Today, 12 Jul at 10.20 | UPI
                          </p>
                          <p
                            className="mb-3"
                            style={{
                              fontSize: "13px",
                              fontWeight: "300",
                              color: "#6A707C",
                            }}
                          >
                            Transaction ID: 236656568
                          </p>
                        </div>
                        <div className="col-3 p-0">
                          <p
                            className="ms-lg-4"
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            ₹2,034.72
                          </p>
                        </div>
                      </div>
                      <div className="border border-bottom w-100"></div>
                    </Card>

                    <Card className="mt-lg-2 me-lg-2">
                      <div className="row w-100 mt-3 ms-2">
                        <div className="col-2 p-0 text-center">
                          <Avatar
                            style={{
                              color: "#EB8128",
                              backgroundColor: "#FFE5CC",
                            }}
                          >
                            SR
                          </Avatar>
                        </div>
                        <div className="col p-0 ">
                          <p
                            className="mb-3"
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#1E232C",
                            }}
                          >
                            Order Spare for #SR562C
                          </p>
                          <p
                            className="mb-3"
                            style={{
                              fontSize: "13px",
                              fontWeight: "300",
                              color: "#6A707C",
                            }}
                          >
                            Today, 12 Jul at 10.20 | Cash
                          </p>
                        </div>
                        <div className="col-3 p-0">
                          <p
                            className="ms-lg-4"
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            ₹2,400.32
                          </p>
                        </div>
                      </div>
                      <div className="border border-bottom w-100"></div>
                    </Card>

                    <Card className="mt-lg-2 me-lg-2">
                      <div className="row w-100 mt-3 ms-2">
                        <div className="col-2 p-0 text-center">
                          <Avatar
                            style={{
                              color: "#EB8128",
                              backgroundColor: "#FFE5CC",
                            }}
                          >
                            SR
                          </Avatar>
                        </div>
                        <div className="col p-0 ">
                          <p
                            className="mb-3"
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#1E232C",
                            }}
                          >
                            Added to wallet
                          </p>
                          <p
                            className="mb-3"
                            style={{
                              fontSize: "13px",
                              fontWeight: "300",
                              color: "#6A707C",
                            }}
                          >
                            Today, 12 Jul at 10.20 | UPI
                          </p>
                          <p
                            className="mb-3"
                            style={{
                              fontSize: "13px",
                              fontWeight: "300",
                              color: "#6A707C",
                            }}
                          >
                            Transaction ID: 236656568
                          </p>
                        </div>
                        <div className="col-3 ms-lg-3 p-0">
                          <p
                            className="ms-lg-4"
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#36A93F",
                            }}
                          >
                            ₹500
                          </p>
                        </div>
                      </div>
                      <div className="border border-bottom w-100"></div>
                    </Card>

                    <Card className="mt-lg-2 me-lg-2">
                      <div className="row w-100 mt-3 ms-2">
                        <div className="col-2 p-0 text-center">
                          <Avatar
                            style={{
                              color: "#EB8128",
                              backgroundColor: "#FFE5CC",
                            }}
                          >
                            SR
                          </Avatar>
                        </div>
                        <div className="col p-0 ">
                          <p
                            className="mb-3"
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#1E232C",
                            }}
                          >
                            Order Spare for #SR562C
                          </p>
                          <p
                            className="mb-3"
                            style={{
                              fontSize: "12px",
                              fontWeight: "300",
                              color: "#6A707C",
                            }}
                          >
                            Today, 12 Jul at 10.20 | Debit/Credit Card
                          </p>
                          <p
                            className="mb-3"
                            style={{
                              fontSize: "13px",
                              fontWeight: "300",
                              color: "#6A707C",
                            }}
                          >
                            Transaction ID: 236656568
                          </p>
                        </div>
                        <div className="col-3 p-0">
                          <p
                            className="ms-lg-4 me-lg-1"
                            style={{ fontSize: "13px", fontWeight: "500" }}
                          >
                            ₹2,400.02
                          </p>
                        </div>
                      </div>
                      <div className="border border-bottom w-100"></div>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wallet;
