import React, { useState, useCallback } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";

const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "600px",
};

const center = {
  lat: 37.7749, // Default latitude (you can change this to your desired default location)
  lng: -122.4194, // Default longitude (you can change this to your desired default location)
};

const WeatherApp = () => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [currentLocation, setCurrentLocation] = useState(null);
  const [currentAddress, setCurrentAddress] = useState("");

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyBhL5rK2MXq8piHqNLxRDBEhE2gkGmkfjs",
    libraries,
  });


  const onMapClick = useCallback((event) => {
    const newLocation = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setSelectedLocation(newLocation);

    // Get address of the location using Geocoder API
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: newLocation }, (results, status) => {
      if (status === "OK" && results[0]) {
        setSelectedAddress(results[0].formatted_address);

        // Extracting individual address components
        const addressComponents = results[0].address_components;
        let postalCode = "";
        let state = "";
        let city = "";

        for (const component of addressComponents) {
          const types = component.types;

          if (types.includes("postal_code")) {
            postalCode = component.long_name;
          } else if (types.includes("administrative_area_level_1")) {
            state = component.long_name;
          } else if (types.includes("locality") || types.includes("administrative_area_level_2")) {
            city = component.long_name;
          }
        }
      } else {
        setSelectedAddress("");
      }
    });
  }, []);



  const options = {
    enableHighAccuracy: true,
    timeout: 5000, // Set a timeout (in milliseconds) for the geolocation request
  };
  const handleGetCurrentLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentLocation = {
            // lat: position.coords.latitude,
            // lng: position.coords.longitude,
            lat: 9.906862,
            lng: 78.0984409
          };
          setCurrentLocation(currentLocation);

          // Get address of the current location using Geocoder API
          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode({ location: currentLocation }, (results, status) => {
            if (status === "OK" && results[0]) {
              setCurrentAddress(results[0].formatted_address);

              // Extract postal code, state, and city from the address components
              let postalCode = "";
              let state = "";
              let city = "";

              for (const component of results[0].address_components) {
                if (component.types.includes("postal_code")) {
                  postalCode = component.long_name;
                } else if (component.types.includes("administrative_area_level_1")) {
                  state = component.long_name;
                } else if (component.types.includes("locality")) {
                  city = component.long_name;
                }
              }

            } else {
              setCurrentAddress("");
            }
          });
        },
        (error) => {
          console.error("Error getting current location:", error);
        },
        options
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, [])


  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading maps";

  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={14}
        center={center}
        onClick={onMapClick}
      >
        {selectedLocation && <Marker position={selectedLocation} />}
        {currentLocation && <Marker position={currentLocation} />}
      </GoogleMap>
      {selectedAddress && <p>Selected Address: {selectedAddress}</p>}
      {currentAddress && <p>Current Address: {currentAddress}</p>}
      <button onClick={handleGetCurrentLocation}>Get Current Location</button>
    </div>
  );
};

export default WeatherApp;