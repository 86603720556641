import React from "react";
import phone_img from "../../Assets/Home/services-phone-img.svg";
const HomeServices = () => {
  return (
    <div className="home-services-container">
      <div className="first-flex-service">
        <div
          style={{ fontWeight: 600, fontSize: "34px" }}
          className="res-576px-text-align-center res-576px-font-25px res-576px-pl-pr-20px"
        >
          Fix your Appliances in a Flash!
        </div>
        <div style={{ fontWeight: 600, fontSize: "19px", marginTop: "40px" }}>
          Repairs made easy
        </div>
        <div
          style={{
            fontSize: "14px",
            color: "rgba(26, 26, 26, 0.7)",
            marginTop: "10px",
          }}
        >
          TV, Washing Machine, AC, Refrigerator, RO, DTH, GPS, Microwave,
          Dishwasher, Geyser, Hob, CCTVs and more. We will be there on time for
          just about any appliance service and just about anywhere.
        </div>
        <div style={{ fontWeight: 600, fontSize: "19px", marginTop: "40px" }}>
          On-demand services
        </div>
        <div
          style={{
            fontSize: "14px",
            color: "rgba(26, 26, 26, 0.7)",
            marginTop: "10px",
          }}
        >
          Across Tamil Nadu spanning from Chennai, Coimbatore, Madurai, Salem,
          Trichy, Tirunelveli to Kanyakumari! At home, shop, or office.
        </div>
      </div>
      <div className="second-flex-img">
        <img src={phone_img} alt="phone_img" />
      </div>
    </div>
  );
};

export default HomeServices;
