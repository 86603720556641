import { useState, useEffect, Fragment } from "react";
import {
  TextField,
  Select,
  Checkbox,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { Modal, Button, Stack } from "react-bootstrap";
import "./style.css";
import Api from "../../../../service/services";
import { useNavigate } from "react-router-dom";

const ProductModel = ({ showModal, modelData, onComplete, onCancel }) => {
  const [data, setData] = useState({});
  const [errData, setErrData] = useState({ isValid: false, isClicked: false });

  const { isClicked } = errData;

  useEffect(() => {
    if (modelData) {
      setData(modelData);
    }
  }, [modelData]);

  useEffect(() => {
    if (isClicked) setErrData({ ...onValidate() });
  }, [data]);

  const {
    appliance_model,
    appliance_from_size,
    appliance_to_size,
    appliance_basic_charge,
    appliance_commission_charge,
    status,
    productModels = [],
    disabled,
    reload,
    modelIndex,
  } = data;

  const onChange = (event) => {
    const { value, name } = event.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const FORM_GROUP = [
    {
      name: "appliance_model",
      disabled: productModels.length <= 1,
      onChange,
    },
    {
      label: "From Value (Size)",
      name: "appliance_from_size",
      disabled,
      onChange,
    },
    {
      label: "To Value (Size)",
      name: "appliance_to_size",
      disabled,
      onChange,
    },
    {
      label: "Visiting Charge",
      type: "number",
      name: "appliance_basic_charge",
      onChange,
    },
    {
      label: "Commission Charge",
      type: "number",
      name: "appliance_commission_charge",
      onChange,
    },
  ];

  const onToggleBrand = (event) =>
    setData((prev) => ({ ...prev, status: event.target.checked }));

  const onValidate = () => {
    let errData = { isClicked: true, isValid: true };

    if (!appliance_model) {
      errData = {
        ...errData,
        ["appliance_model"]: "Please enter valid Appliance model",
        isValid: false,
      };
    }

    if (!appliance_from_size) {
      errData = {
        ...errData,
        ["appliance_from_size"]: "Please enter valid Size of product",
        isValid: false,
      };
    }

    if (!appliance_to_size) {
      errData = {
        ...errData,
        ["appliance_to_size"]: "Please enter valid Size of product",
        isValid: false,
      };
    }

    if (!appliance_basic_charge) {
      errData = {
        ...errData,
        ["appliance_basic_charge"]: "Please enter visiting charge",
        isValid: false,
      };
    }

    if (!appliance_commission_charge) {
      errData = {
        ...errData,
        [`appliance_commission_charge`]: "Please enter commission charge",
        isValid: false,
      };
    }
    if (Number(appliance_basic_charge) < Number(appliance_commission_charge)) {
      errData = {
        ...errData,
        [`appliance_commission_charge`]: "Commission charge should not be greater then visiting charge",
        isValid: false,
      };
    }
    setErrData(errData);

    return errData;
  };

  const onSubmit = () => {
    const { isValid } = onValidate();

    if (isValid) {
      onComplete({
        appliance_model,
        appliance_from_size,
        appliance_to_size,
        appliance_basic_charge: Number(appliance_basic_charge),
        appliance_commission_charge: Number(appliance_commission_charge),
        status,
        modelIndex,
      });
    }
  };

  const renderMenuItem = (text, index) => (
    <MenuItem key={index} value={text}>
      {text}
    </MenuItem>
  );

  const renderFormElement = (prop, index) => {
    const { name } = prop;
    const valueProp = {
      value: data[name],
      helperText: errData[name],
      error: errData[name],
    };

    return name === "appliance_model" ? (
      <Fragment key={index}>
        <InputLabel id="appliance_model_spinner">Product Model</InputLabel>
        <Select
          labelId="appliance_model_spinner"
          {...prop}
          disabled={productModels.length <= 0}
          defaultValue={
            productModels.length > 0 ? productModels[0]?.value : "Default"
          }
          /*    defaultValue={valueProp.value} */
          value={
            valueProp.value == "" || undefined ? "Default" : valueProp.value
          }
        >
          {productModels?.map(renderMenuItem)}
        </Select>
      </Fragment>
    ) : (
      <TextField {...prop} {...valueProp} key={index} />
    );
  };
  const reloadAppliance = () => {
    window.location.reload();
  };
  return (
    <Modal show={showModal} size="xl" onHide={onCancel} centered>
      <FormControl className="col p-1 m-5">
        <section className="form-group">
          {FORM_GROUP.map(renderFormElement)}
        </section>
        <Stack gap={2}>
          {/*  <label htmlFor="model-status">
            <Checkbox
              id="model-status"
              checked={status}
              disabled={disabled}
              onChange={onToggleBrand}
            />
            Model is Active
          </label> */}
          <Button
            className="mt-4"
            style={{ background: "rgb(235, 129, 40)", border: "none" }}
            onClick={onSubmit}
            type="submit"
          >
            Submit
          </Button>
          <Button
            variant="outline-secondary"
            onClick={() => {
              onCancel();
              reload && reloadAppliance();
            }}
          >
            Cancel
          </Button>
        </Stack>
      </FormControl>
    </Modal>
  );
};

export default ProductModel;
