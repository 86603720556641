import React, { createContext, useState } from "react";

const CountContext = createContext();

export const CountProvider = ({ children }) => {
  const [customerCountContext, setCustomerCountContext] = useState({});
  const [adminCountContext, setAdminCountContext] = useState({});
  const [techCountContext, setTechCountContext] = useState({});
  const [zonalCountContext, setZonalCountContext] = useState({});
  const [districtCountContext, setDistrictCountContext] = useState({});
  const [search, setSearch] = useState("")
  return (
    <CountContext.Provider
      value={{
        customerCountContext,
        setCustomerCountContext,
        adminCountContext,
        setAdminCountContext,
        techCountContext,
        setTechCountContext,
        zonalCountContext,
        setZonalCountContext,
        districtCountContext,
        setDistrictCountContext,
        setSearch,
        search
      }}
    >
      {children}
    </CountContext.Provider>
  );
};

export default CountContext;
