import axios from "axios";
import { BASE_URL } from "../../api/ApiPath";
import {
  initiatePaymentFail,
  initiatePaymentRequest,
  initiatePaymentSuccess,
} from "../slices/initiatePaymentSlice";
import {
  transactionDetailsFail,
  transactionDetailsRequest,
  transactionDetailsSuccess,
} from "../slices/transactionDetailsSlice";
import {
  walletDetailsFail,
  walletDetailsRequest,
  walletDetailsSuccess,
} from "../slices/walletDetailsSlice";
import { getNotificationsCount } from "./notificationsAction";
import {
  paymentOrderErrorClear,
  paymentOrderFail,
  paymentOrderRequest,
  paymentOrderSuccess,
} from "../slices/paymentOrderSlice";

const handleUnAuthorizedError = (err) => {
  if (err.response.status === 401) {
    console.log("working");
    localStorage.clear();
    window.location.href = "/";
    window.location.reload();
  }
};

export const initiatePayment = (payload) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const payloadData = {
    amount: payload.amount,
    isMobile: false,
  };

  try {
    dispatch(initiatePaymentRequest());
    const response = await axios.post(`${BASE_URL}/payment/send`, payloadData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch(initiatePaymentSuccess(response?.data));
    dispatch(getNotificationsCount(token));
  } catch (error) {
    handleUnAuthorizedError(error);
    dispatch(initiatePaymentFail(error));
  }
};

export const getWalletDetails = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch(walletDetailsRequest());
    const response = await axios.get(`${BASE_URL}/wallet/details`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch(walletDetailsSuccess(response?.data));
  } catch (error) {
    handleUnAuthorizedError(error);
    dispatch(walletDetailsFail(error));
  }
};
export const paymentErrorClear = () => async (dispatch) => {
  dispatch(paymentOrderErrorClear());
};

export const getTransactionDetails = (payload) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch(transactionDetailsRequest());
    const response = await axios.get(`${BASE_URL}/get/transaction/details`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch(getWalletDetails());
    dispatch(transactionDetailsSuccess(response?.data));
    dispatch(getNotificationsCount(token));
  } catch (error) {
    handleUnAuthorizedError(error);
    dispatch(transactionDetailsFail(error));
  }
};
export const PaymentOrder = (payload) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch(paymentOrderRequest());
    const response = await axios.post(`${BASE_URL}/order/payment`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch(paymentOrderSuccess(response?.data));
    dispatch(getNotificationsCount(token));
  } catch (error) {
    console.error("Payment Order Error:", error?.response?.data);
    handleUnAuthorizedError(error);
    dispatch(paymentOrderFail(error?.response?.data));
  }
};
