import React from "react";
import "./CmacCardFollower.css";

const CmacCardFollower = ({ children, id, className, style }) => {
  return (
    <div
      className={`${className ? className : "_"} cards-container`}
      id={id}
      style={style}
    >
      {children}
    </div>
  );
};

export default CmacCardFollower;
