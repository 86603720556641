import React from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export default function FridgeServiceBanner() {
  const navigate = useNavigate();
  return (
    <div className="appliances-service-banner-con">
      <div style={{ fontSize: "11px", color: "rgba(139, 139, 139, 1)" }}>
        <span
          style={{ color: "black", cursor: "pointer" }}
          onClick={() => navigate("/")}
          className="res-576px-d-none"
        >
          <b>Home</b>
        </span>
        <MdKeyboardArrowRight className="res-576px-d-none" size={22} />
        <span
          className="res-576px-d-none"
          onClick={() => navigate("/services")}
          style={{ cursor: "pointer" }}
        >
          Services
        </span>
        <MdKeyboardArrowRight className="res-576px-d-none" size={22} />
        <span className="res-576px-d-none">Refrigerator</span>
      </div>
      <div className="banner-content">
        <div className="heading">Refrigerator</div>

        <div className="con-text">
          All you need to do is Chill, and we will get your Chiller ready in no
          time! We assure quick and quality services at your doorstep, with no
          middlemen!
        </div>
        <div className="con-text">
          Your search for Fridge Repair Services in Chennai ends at S3! We cater
          to all areas in Tamil Nadu by segmenting the geography into zones.
          This enables us to improve service quality and availability based on
          local inputs. Log in now!
        </div>
        <button className="download-button" onClick={() => navigate("/login")}>
          Log in
        </button>
      </div>
    </div>
  );
}
