import { createSlice } from "@reduxjs/toolkit";

const superAdminDashboardSlice = createSlice({
  name: "superAdminDashboard",
  initialState: {
    loading: false,
  },
  reducers: {
    superAdminDashboardRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    superAdminDashboardSuccess(state, action) {
      return {
        ...state,
        loading: false,
        superAdminDashboardData: action.payload,
      };
    },
    superAdminDashboardFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
  },
});

const { actions, reducer } = superAdminDashboardSlice;

export const {
  superAdminDashboardRequest,
  superAdminDashboardFail,
  superAdminDashboardSuccess,
} = actions;

export default reducer;
