import React from "react";
import phone_img from "../../Assets/Home/key-points.svg";

export default function KeyPoints() {
  return (
    <div className="home-services-container">
      <div className="first-flex-service">
        <div
          style={{
            fontWeight: 600,
            fontSize: "36px",
            width: "501px",
            letterSpacing: "1px",
          }}
          className="res-576-w-fill res-576px-font-25px res-576-text-align-center"
        >
          Well-versed in installation, regular service, and fixing.
        </div>
        <div
          style={{
            fontSize: "14px",
            color: "rgba(26, 26, 26, 0.7)",
            marginTop: "20px",
          }}
        >
          <ul style={{ fontWeight: 600 }}>
            <li>Unavailable or non-booting appliances</li>
            <li className="mt-3">Automatic switch off/on controls</li>
            <li className="mt-3">Port Issues</li>
            <li className="mt-3">Specific part fixes or replacement</li>
            <li className="mt-3">
              Cleaning and servicing to increase performance
            </li>
            <li className="mt-3">
              Issues in running the appliance or the outcome of the appliance
            </li>
            <li className="mt-3">Demos and Optimum configuration settings</li>
          </ul>
        </div>
      </div>
      <div className="second-flex-img">
        <img src={phone_img} alt="phone_img" />
      </div>
    </div>
  );
}
