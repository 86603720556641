import React from "react";
import tv_service from "../../Assets/Home/fridge-service.svg";
import tv_service_1 from "../../Assets/Home/fridge-service-1.svg";

export default function FridgeServiceOffered() {
  return (
    <div>
      <div>
        <div className="appliances-services-container-vision">
          <div className="first-flex-service">
            <div
              style={{ fontWeight: 600, fontSize: "34px" }}
              className="res-576px-text-align-center res-576px-font-25px"
            >
              Services offered:{" "}
            </div>
            <div
              style={{
                fontSize: "15px",
                color: "rgba(26, 26, 26, 0.7)",
                marginTop: "30px",
                lineHeight: "26px",
              }}
            >
              <ul style={{ color: "black" }}>
                <li>
                  <b> Repair: </b>{" "}
                  <span style={{ color: "rgba(26, 26, 26, 0.7)" }}>
                    To book a repair service, log in to our website or mobile
                    application and search for refrigerator service. You can
                    enter the specifications including brand names, model type
                    and the nature of the issue faced. Be it a double-door,
                    side-by-side door, convertible as well as a top or bottom
                    mount, or mini fridge, our technicians can handle all
                    issues,
                  </span>{" "}
                  <b>including:</b>
                </li>
                <div
                  style={{
                    fontSize: "12px",
                    marginLeft: "40px",
                    marginTop: "10px",
                  }}
                  className="res-576px-ml-0px"
                >
                  {" "}
                  1. Temperature control not working or inconsistent
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    marginLeft: "40px",
                    marginTop: "4px",
                  }}
                  className="res-576px-ml-0px"
                >
                  {" "}
                  2. Fridge does not switch on
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    marginLeft: "40px",
                    marginTop: "4px",
                  }}
                  className="res-576px-ml-0px"
                >
                  3. Leaks or loud noise 
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    marginLeft: "40px",
                    marginTop: "4px",
                  }}
                  className="res-576px-ml-0px"
                >
                  4. Water dispenser or ice maker issues
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    marginLeft: "40px",
                    marginTop: "4px",
                  }}
                  className="res-576px-ml-0px"
                >
                  5. Food over-freezing or moisture collection inside the
                  appliance 
                </div>
                <li className="mt-3">
                  <b>Installation/Uninstallation:</b>{" "}
                  <span style={{ color: "rgba(26, 26, 26, 0.7)" }}>
                    During installation or movement of the refrigerator, it is
                    important to install/re-install with caution – hence, leave
                    it to the pros and relax! This includes setting the optimum
                    temperature, stabilizer setup, demo, and tips on regular
                    care for the refrigerator. 
                  </span>
                </li>
                <li className="mt-3">
                  <b> Maintenance Service: </b>
                  <span style={{ color: "rgba(26, 26, 26, 0.7)" }}>
                    To ensure the long-lasting life of any appliance, it is
                    important to invest in annual maintenance and cleaning. This
                    includes freezer and vegetable container check-ups,
                    recommendations for better arrangements, and more. Book your
                    service now at minimal cost to ensure huge savings later. 
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div
            className="second-flex-img res-576px-w-fill res-576px-scale-unset"
            style={{ scale: "0.8" }}
          >
            <img
              src={tv_service_1}
              alt="phone_img"
              className="res-576px-w-fill"
            />
          </div>
        </div>
      </div>
      <div className="appliances-services-container-mission">
        <div
          className="second-flex-img res-576px-w-fill"
          style={{ scale: "0.8", marginTop: "-80px" }}
        >
          <img src={tv_service} alt="tv_service" className="res-576px-w-fill" />
        </div>
        <div className="first-flex-service">
          <div
            style={{ fontWeight: 600, fontSize: "34px" }}
            className="res-576px-text-align-center res-576px-font-25px"
          >
            Why S3 for Refrigerator Repair and Service? 
          </div>
          <div
            style={{
              fontSize: "15px",
              color: "rgba(26, 26, 26, 0.7)",
              marginTop: "30px",
              lineHeight: "26px",
            }}
          >
            <ul style={{ color: "black", textAlign: "justify" }}>
              <li>
                <span style={{ fontWeight: 600 }}>
                  Trained and experienced technicians:
                </span>{" "}
                <span style={{ color: "rgba(26, 26, 26, 0.7)" }}>
                  Our administrators and supervisors guarantee that each S3
                  technician is trained in professional and personal etiquette.
                  We set a high level of quality as our standard and ensure it
                  only increases year on year!{" "}
                </span>
              </li>
              <li className="mt-2">
                <span style={{ fontWeight: 600 }}> One-stop solution:</span>{" "}
                <span style={{ color: "rgba(26, 26, 26, 0.7)" }}>
                  With S3, you get a one-stop solution to avail the best
                  Refrigerator repair service in Chennai or elsewhere in Tamil
                  Nadu or for any brand – Samsung, LG, Whirlpool, Haier, etc.
                </span>{" "}
              </li>
              <li className="mt-2">
                <span style={{ fontWeight: 600 }}> Convenient Time Slots:</span>{" "}
                <span style={{ color: "rgba(26, 26, 26, 0.7)" }}>
                  There are multiple time slots available based on your choice.
                  Until the technician is assigned, you also have the option to
                  reschedule the appointment. Our administrators are on the
                  constant lookout to ensure the best match technician is
                  assigned to any refrigerator repair, service, or installation.
                </span>{" "}
              </li>
              <li className="mt-2">
                <span style={{ fontWeight: 600 }}>30-day-warranty:</span>{" "}
                <span style={{ color: "rgba(26, 26, 26, 0.7)" }}>
                  We guarantee to fix any issue that recurs post-service
                  delivery within 30 days!{" "}
                </span>{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
