import React from "react";

export default function FindUs() {
  const northZone = [
    {
      id: 1,
      name: "Chengalpattu",
    },
    {
      id: 2,
      name: "Kanchipuram",
    },
    {
      id: 3,
      name: "Ranipet",
    },
    {
      id: 4,
      name: "Tiruvallur",
    },
    {
      id: 5,
      name: "Tiruvannamalai",
    },
    {
      id: 6,
      name: "Vellore",
    },
    {
      id: 7,
      name: "Dharmapuri",
    },
    {
      id: 8,
      name: "Kallakurichi",
    },
    {
      id: 9,
      name: "Krishnagiri",
    },
    {
      id: 10,
      name: "Namakkal",
    },
    {
      id: 11,
      name: "Salem",
    },
  ];

  const southZone = [
    {
      id: 1,
      name: "Madurai",
    },
    {
      id: 2,
      name: "Ramanathapuram",
    },
    {
      id: 3,
      name: "Sivagangai",
    },
    {
      id: 4,
      name: "Pudukottai",
    },
    {
      id: 5,
      name: "Theni",
    },
    {
      id: 6,
      name: "Virudhunagar",
    },
  ];
  const westZone = [
    {
      id: 1,
      name: "Coimbatore",
    },
    {
      id: 2,
      name: "Karur",
    },
    {
      id: 3,
      name: "Nilgiris",
    },
    {
      id: 4,
      name: "Tirupur",
    },
    {
      id: 5,
      name: "Dindigul",
    },
    {
      id: 6,
      name: "Erode",
    },
  ];
  const eastZone = [
    {
      id: 1,
      name: "Nagapattinam",
    },
    {
      id: 2,
      name: "Thanjavur",
    },
    {
      id: 3,
      name: "Trichy",
    },
    {
      id: 4,
      name: "Ariyalur",
    },
    {
      id: 5,
      name: "Perambalur",
    },
    {
      id: 6,
      name: "Tiruvarur",
    },
    {
      id: 7,
      name: "Mayiladuthurai",
    },
    {
      id: 8,
      name: "Karaikal",
    },
  ];
  return (
    <div className="find-us-container">
      <div>
        <div style={{ fontWeight: 600, fontSize: "19px", textAlign: "center" }}>
          Where can you find us?
        </div>
        <div className="text-box">
          <div
            style={{
              color: "rgba(26, 26, 26, 0.7)",
              fontSize: "15px",
              marginTop: "1rem",
              lineHeight: "26px",
              textAlign: "center",
            }}
            className="text"
          >
            We have divided Tamil Nadu into the below zones and districts. Our
            administration and service delivery are in tune with the local
            demands of each of the below locations:
          </div>
        </div>
      </div>
      <div className="city-flex-con">
        <div className="city-flex">
          <div>
            <div
              style={{
                color: "rgba(235, 129, 40, 1)",
                fontSize: "16px",
                marginBottom: "10px",
                fontWeight: 600,
                marginTop: "32px",
                letterSpacing: "1px",
              }}
            >
              NORTH ZONE
            </div>
            <div className="res-576px-d-flex res-576px-flex-wrap res-576px-gap-1 res-576px-line-height-1 ">
              {northZone.map((item, i) => {
                return (
                  <div
                    key={i}
                    style={{ color: "rgba(26, 26, 26, 0.7)", marginTop: "8px" }}
                  >
                    {" "}
                    {item.id}. {item.name}
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <div
              style={{
                color: "rgba(235, 129, 40, 1)",
                fontSize: "16px",
                marginBottom: "10px",
                fontWeight: 600,
                marginTop: "32px",
                letterSpacing: "1px",
              }}
            >
              SOUTH ZONE
            </div>
            <div className="res-576px-d-flex res-576px-flex-wrap res-576px-gap-1 res-576px-line-height-1 ">
              {southZone.map((item, i) => {
                return (
                  <div
                    key={i}
                    style={{ color: "rgba(26, 26, 26, 0.7)", marginTop: "8px" }}
                  >
                    {" "}
                    {item.id}. {item.name}
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <div
              style={{
                color: "rgba(235, 129, 40, 1)",
                fontSize: "16px",
                marginBottom: "10px",
                fontWeight: 600,
                marginTop: "32px",
                letterSpacing: "1px",
              }}
            >
              WEST ZONE
            </div>
            <div className="res-576px-d-flex res-576px-flex-wrap res-576px-gap-1 res-576px-line-height-1 ">
              {westZone.map((item, i) => {
                return (
                  <div
                    key={i}
                    style={{ color: "rgba(26, 26, 26, 0.7)", marginTop: "8px" }}
                  >
                    {" "}
                    {item.id}. {item.name}
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <div
              style={{
                color: "rgba(235, 129, 40, 1)",
                fontSize: "16px",
                marginBottom: "10px",
                fontWeight: 600,
                marginTop: "32px",
                letterSpacing: "1px",
              }}
            >
              EAST ZONE
            </div>
            <div className="res-576px-d-flex res-576px-flex-wrap res-576px-gap-1 res-576px-line-height-1 ">
              {eastZone.map((item, i) => {
                return (
                  <div
                    key={i}
                    style={{ color: "rgba(26, 26, 26, 0.7)", marginTop: "8px" }}
                  >
                    {" "}
                    {item.id}. {item.name}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
