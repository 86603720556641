import React, { useEffect, useState } from "react";
import { IoMdStarOutline, IoMdStar } from "react-icons/io";

export default function Ratings({
  rating,
  onChangeRating,
  fontSize,
  isEditable,
}) {
  const [recivedRating, setRecivedRating] = useState(0);

  const handleStarClick = (index) => {
    if (isEditable) {
      const newRating = index + 1;
      setRecivedRating(newRating);
      onChangeRating(newRating);
    }
  };

  useEffect(() => {
    setRecivedRating(rating);
  }, [rating]);

  const stars = Array.from({ length: 5 }, (_, index) => {
    const isFilled = index < recivedRating;
    return (
      <span
        key={index}
        onClick={() => handleStarClick(index)}
        style={{
          color: isFilled ? "rgb(235, 129, 40)" : "gray",
          cursor: isEditable ? "pointer" : "default",
        }}
      >
        {isFilled ? <IoMdStar /> : <IoMdStarOutline />}
      </span>
    );
  });

  return (
    <div style={{ fontSize }} className="d-flex align-items-center gap-1">
      {stars}
    </div>
  );
}
