import axios from "axios";
// import { DRAW_SERVICE_BASE_URL } from "../../api/apiBaseUrl";
import { BASE_URL } from "../../api/ApiPath";
import { rewardLoginListRequest, rewardLoginListFail, rewardLoginListSuccess } from "../slices/rewardLoginTecnician";
export const getAllrewardLoginList = (startDate, endDate, status, search, filter) => async (dispatch) => {
    const token = localStorage.getItem("token");
    // let payload = id ? `draw_id=${id}&startingNumber=0000&limit=9999&onlySoldOut=false` : '';
    let payload = search ? `search=${search}&` : '';
    payload += filter?.zone_id ? `zone_id=${filter?.zone_id}&` : '';
    payload += filter?.district ? `district=${filter?.district}&` : '';
    payload += filter?.winningStatus ? `winning_status=${filter?.winningStatus}&` : '';

    try {
        dispatch(rewardLoginListRequest())
        const response = await axios.get(`${BASE_URL}/technician/reward?startDate=${startDate}&endDate=${endDate}&status=${status}&${payload}`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        dispatch(rewardLoginListSuccess(response?.data))
        console.log("rewardLoginListLists", response?.data);
    } catch (error) {
        console.log(error);
        dispatch(rewardLoginListFail(error?.response?.data))
    }
}
