import React, { useEffect, useState } from "react";
import Topbar from "../../topbar";
import Sidebar from "../../Sidebar";
import { BsCurrencyRupee } from "react-icons/bs";
import { useSelector } from "react-redux";
import DatePickerDashboard from "../../Technicians/DatePickerDashboard";
import AdminChart from "../SuperAdminChart1";
import SRHighlightedIcon from "../../../Assets/icons/SRHighlightedIcon";
import ApexChart from "../../Technicians/DashboardChart2";
import Dashboardchart1 from "../../Technicians/DashboardChart1";
import { Tab, Tabs } from "@mui/material";
import { Paper } from "@mui/material";
import DashboardDataTable from "../DashboardDataTable";
import HomeFooter from "../../HomeFooter";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const SuperAdminDashboard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { technicianDashboardData } = useSelector(
    (state) => state.technicianDashboardState
  );
  const { superAdminDashboardData } = useSelector(
    (state) => state.superAdminDashboardState
  );
  const [totalTechnicians, setTotalTechnicians] = useState(0);
  const [totalSRs, setTotalSRs] = useState(0);
  const [closedSRs, setClosedSRs] = useState(0);
  const [dashboardValue, setDashboardValue] = useState(0);
  const [techDashboard, setTechDashboard] = useState(true);
  const [adminDashboard, setAdminDashboard] = useState(false);
  const [zoneId, setZoneId] = useState(0);
  useEffect(() => {
    const totalTechniciansData =
      superAdminDashboardData?.userCount?.technician +
      superAdminDashboardData?.userCount?.district_admin +
      superAdminDashboardData?.userCount?.zonal_admin +
      superAdminDashboardData?.userCount?.super_admin;
    setTotalTechnicians(totalTechniciansData);
    const totalSRsData =
      superAdminDashboardData?.srCount?.unassigned +
      superAdminDashboardData?.srCount?.assigned +
      superAdminDashboardData?.srCount?.accepted +
      superAdminDashboardData?.srCount?.notified +
      superAdminDashboardData?.srCount?.closed +
      superAdminDashboardData?.srCount?.reopened +
      superAdminDashboardData?.srCount?.cancelled;
    setTotalSRs(totalSRsData);
    setClosedSRs(superAdminDashboardData?.closedSrCount);
  }, [superAdminDashboardData]);
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleChangeDashboard = (event, newValue) => {
    setDashboardValue(newValue);
  };
  const handleZoneId = (id) => {
    setZoneId(id);
  };
  const username = localStorage.getItem("name");

  return (
    <div className="wallet-option" style={{ overflow: "hidden" }}>
      <Topbar />
      <div className="row">
        <div className="col-2 col-12">
          <Sidebar />
        </div>
        <div className="col-10 p-4 pb-0 wallet">
          <div
            className="d-flex align-items-center"
            style={{
              justifyContent: "space-between",
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            <div>
              <div
                className="mt-2 mb-2"
                style={{ fontWeight: "600", fontSize: "21px" }}
              >
                Welcome {username}!
              </div>
            </div>
            <div>
              <DatePickerDashboard />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12">
              <Tabs
                className="w-100 bg-white scroll-tabs"
                value={dashboardValue}
                onChange={handleChangeDashboard}
                // aria-label="basic tabs example"
                variant="fullWidth"
              >
                <Tab
                  style={{
                    fontSize: "15px",
                    fontWeight: "400",
                    fontStyle: "none",
                    color: techDashboard ? "rgb(235, 129, 40)" : "#6A707C",
                    textTransform: "none",
                  }}
                  label={`Personal`}
                  {...a11yProps(0)}
                  onClick={() => {
                    setTechDashboard(true);
                    setAdminDashboard(false);
                  }}
                />
                <Tab
                  className=""
                  style={{
                    fontSize: "15px",
                    fontWeight: "400",
                    color: adminDashboard ? "rgb(235, 129, 40)" : "#6A707C",
                    textTransform: "none",
                  }}
                  label={`My Admin`}
                  {...a11yProps(1)}
                  onClick={() => {
                    setTechDashboard(false);
                    setAdminDashboard(true);
                  }}
                />
              </Tabs>
            </div>
          </div>
          {techDashboard ? (
            <div>
              <div className="mt-3 mb-3 chart-container">
                <div className="total-summary">
                  <div className="dashboard-tech">
                    <div>
                      <div style={{ fontWeight: "600", fontSize: "14px" }}>
                        Closed Service Requests
                      </div>
                      <div style={{ fontWeight: "600", fontSize: "20px" }}>
                        {technicianDashboardData?.totalServiceRequest}
                      </div>
                    </div>
                    {/* <div>
                      <img
                        src={total_service_request}
                        alt="total_service_req"
                      />
                    </div> */}
                  </div>
                  <div className="dashboard-Myadmin">
                    {/* <div>
                      <div style={{ fontWeight: "600", fontSize: "14px" }}>
                        Total Spare Orders
                      </div>
                      <div style={{ fontWeight: "600", fontSize: "20px" }}>
                        0
                      </div>
                    </div> */}
                    {/* <div>
                      <img src={total_spare_orders} alt="total_service_req" />
                    </div> */}
                  </div>
                  <div
                    className="d-flex align-items-center"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>
                      <div style={{ fontWeight: "600", fontSize: "14px" }}>
                        Total Revenue(
                        <BsCurrencyRupee />)
                      </div>
                      <div style={{ fontWeight: "600", fontSize: "20px" }}>
                        {technicianDashboardData?.totalRevenue}
                      </div>
                    </div>
                    {/* <div>
                      <img src={total_revenue} alt="total_service_req" />
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="dashboard-charts d-flex align-items-center gap-3 mt-3 mb-3">
                <div className="chart-container w-50">
                  <div
                    className="d-flex align-items-center"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>
                      <div style={{ fontSize: "12px" }}>Service Request</div>
                      <div style={{ fontSize: "14px", fontWeight: "600" }}>
                        Appliances
                      </div>
                    </div>
                  </div>
                  <div>
                    <ApexChart />
                  </div>
                </div>
                <div className="chart-container w-50">
                  <div
                    className="d-flex align-items-center"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>
                      <div style={{ fontSize: "12px" }}>Service Request</div>
                      <div style={{ fontSize: "14px", fontWeight: "600" }}>
                        Revenue
                      </div>
                    </div>
                  </div>
                  <div>
                    <Dashboardchart1 />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            adminDashboard && (
              <div>
                {/* <div className=" mb-3 chart-container">
                  <div className="total-summary-super-admin">
                    <div
                      className="d-flex align-items-center"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>
                        <div style={{ fontWeight: "600", fontSize: "14px" }}>
                          New Technicians
                        </div>
                        <div style={{ fontWeight: "600", fontSize: "20px" }}>
                          {totalTechnicians}
                        </div>
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>
                        <div style={{ fontWeight: "600", fontSize: "14px" }}>
                          New Customers
                        </div>
                        <div style={{ fontWeight: "600", fontSize: "20px" }}>
                          {superAdminDashboardData?.userCount?.customer}
                        </div>
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>
                        <div style={{ fontWeight: "600", fontSize: "14px" }}>
                          SRs
                        </div>
                        <div style={{ fontWeight: "600", fontSize: "20px" }}>
                          {closedSRs}
                        </div>
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>
                        <div style={{ fontWeight: "600", fontSize: "14px" }}>
                          Total Revenue
                        </div>
                        <div style={{ fontWeight: "600", fontSize: "20px" }}>
                          {superAdminDashboardData?.totalRevenue}
                        </div>
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>
                        <div style={{ fontWeight: "600", fontSize: "14px" }}>
                          Association Share
                        </div>
                        <div style={{ fontWeight: "600", fontSize: "20px" }}>
                          {superAdminDashboardData?.associationShare}
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="row mt-3">
                  {isMobile ? (
                    <p className="disclaimer">
                      *Use Desktop for better viewing experience
                    </p>
                  ) : (
                    <></>
                  )}
                  <div className="col-lg-6 col-12 sr-overview">
                    <Paper
                      className="mt-1"
                      elevation={2}
                      style={{ borderRadius: "10px" }}
                    >
                      <div className="p-3">
                        <div
                          className="d-flex align-items-center"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div>
                            <div
                              style={{ fontSize: "14px", fontWeight: "600" }}
                            >
                              SR's Overview
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-2 mt-4 mb-4">
                          <div className="d-flex align-items-center gap-2">
                            <div
                              className="d-flex align-items-center"
                              style={{
                                height: "40px",
                                width: "40px",
                                borderRadius: "8px",
                                backgroundColor: "#ffa50036",
                                justifyContent: "center",
                              }}
                            >
                              <SRHighlightedIcon />
                            </div>
                            <div>
                              <div
                                style={{
                                  fontSize: "12px",
                                  color: "rgb(0 0 0 / 43%)",
                                }}
                              >
                                Total SRs
                              </div>
                              <div style={{ fontWeight: "600" }}>
                                {totalSRs}
                              </div>
                            </div>
                          </div>
                          {/* <div className="d-flex align-items-center gap-2">
                            <div
                              className="d-flex align-items-center"
                              style={{
                                height: "40px",
                                width: "40px",
                                borderRadius: "8px",
                                backgroundColor: "#ffa50036",
                                justifyContent: "center",
                              }}
                            >
                              <PaymentIconHighlighted />
                            </div>
                            <div>
                              <div
                                style={{
                                  fontSize: "12px",
                                  color: "rgb(0 0 0 / 43%)",
                                }}
                              >
                                Revenue Sharing
                              </div>
                              <div style={{ fontWeight: "600" }}>
                                {superAdminDashboardData?.totalRevenue}
                              </div>
                            </div>
                          </div> */}
                        </div>
                        <div>
                          <AdminChart />
                        </div>
                      </div>
                    </Paper>
                  </div>
                  <div className="col-lg-6 col-12">
                    <Paper
                      className="mt-1"
                      elevation={2}
                      style={{ borderRadius: "15px", height: "456px" }}
                    >
                      <div className="p-4">
                        <div
                          style={{
                            // color: "rgb(235, 129, 40)",
                            fontWeight: "600",
                            fontSize: "14px",
                          }}
                        >
                          Administration Overview
                        </div>
                        <div
                          className="mt-3"
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          <div
                            className="d-flex align-items-center mt-2"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div style={{ fontWeight: "400" }}>
                              New Technicians{" "}
                            </div>
                            <div>{totalTechnicians}</div>
                          </div>

                          <div
                            className="d-flex align-items-center mt-2"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div style={{ fontWeight: "400" }}>
                              New Customers{" "}
                            </div>
                            <div>
                              {superAdminDashboardData?.userCount?.customer}
                            </div>
                          </div>

                          <div
                            className="d-flex align-items-center mt-2"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div style={{ fontWeight: "400" }}>
                              Closed Service Requests{" "}
                            </div>
                            <div>{closedSRs}</div>
                          </div>

                          <div
                            className="d-flex align-items-center mt-2"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div style={{ fontWeight: "400" }}>
                              Total Revenue(₹){" "}
                            </div>
                            <div>{superAdminDashboardData?.totalRevenue}</div>
                          </div>
                          <div
                            className="d-flex align-items-center mt-2"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div style={{ fontWeight: "400" }}>
                              Technician Profit(₹){" "}
                            </div>
                            <div>
                              {superAdminDashboardData?.technicianProfit}
                            </div>
                          </div>
                          <div
                            className="d-flex align-items-center mt-2"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div style={{ fontWeight: "400" }}>
                              Commission Charge(₹){" "}
                            </div>
                            <div>
                              {superAdminDashboardData?.commission_charge}
                            </div>
                          </div>
                          <div
                            className="d-flex align-items-center mt-2"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div style={{ fontWeight: "400" }}>
                              District Admin Share(₹){" "}
                            </div>
                            <div>{superAdminDashboardData?.distAdminShare}</div>
                          </div>
                          <div
                            className="d-flex align-items-center mt-2"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div style={{ fontWeight: "400" }}>
                              Zonal Admin Share(₹){" "}
                            </div>
                            <div>
                              {superAdminDashboardData?.zonalAdminShare}
                            </div>
                          </div>
                          <div
                            className="d-flex align-items-center mt-2"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div style={{ fontWeight: "400" }}>
                              Super Admin Share(₹){" "}
                            </div>
                            <div>
                              {superAdminDashboardData?.superAdminShare}
                            </div>
                          </div>
                          <div
                            className="d-flex align-items-center mt-2"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div style={{ fontWeight: "400" }}>
                              Association Share(₹){" "}
                            </div>
                            <div>
                              {superAdminDashboardData?.associationShare}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Paper>
                  </div>
                </div>
                <div className="row mt-3 mb-3">
                  <div className="col-12">
                    <Paper
                      className="mt-1"
                      elevation={2}
                      style={{ borderRadius: "15px" }}
                    >
                      <div className="p-2">
                        <DashboardDataTable
                          isData={"zone"}
                          handleZoneId={handleZoneId}
                        />
                      </div>
                    </Paper>
                  </div>
                </div>
                <div className="row mt-3 mb-3">
                  <div className="col-12">
                    <Paper
                      className="mt-1"
                      elevation={2}
                      style={{ borderRadius: "15px" }}
                    >
                      <div className="p-2">
                        <DashboardDataTable
                          isData={"district"}
                          zoneId={zoneId}
                        />
                      </div>
                    </Paper>
                  </div>
                </div>
              </div>
            )
          )}
          {/* <HomeFooter /> */}
        </div>
      </div>
    </div>
  );
};

export default SuperAdminDashboard;
