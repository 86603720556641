import { createSlice } from "@reduxjs/toolkit";
const rewardWinningListSlice = createSlice({
    name: 'rewardWinningList',
    initialState: {
        loading: false,
        rewardWinningListData: [],
        error: null,
        statusCode: null,
        successMsg: null,
        errorMsg: null,
        totalCount: 0,

    },
    reducers: {
        rewardWinningListRequest(state, action) {
            return {
                ...state,
                loading: true
            }
        },
        rewardWinningListSuccess(state, action) {
            return {
                ...state,
                loading: false,
                rewardWinningListData: action?.payload,
                // successMsg: action.payload.data.message,
                // totalCount: action.payload.data?.totalCount,
                errorMsg: null,
                statusCode: 'success'
            }
        },
        rewardWinningListFail(state, action) {
            return {
                ...state,
                loading: false,
                error: action.payload,
                statusCode: 'error',
                errorMsg: action?.payload?.error?.message,
                // totalCount: 0,
                rewardWinningListData: [],
                successMsg: null
            }
        },
        clearStatusCode(state, action) {
            return {
                ...state,
                statusCode: null
            }
        }

    }
});

const { actions, reducer } = rewardWinningListSlice;

export const { rewardWinningListRequest, rewardWinningListFail, rewardWinningListSuccess, clearStatusCode } = actions

export default reducer;
