import { combineReducers, configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import initiatePaymentReducer from "../slices/initiatePaymentSlice";
import transactionDetailsReducer from "../slices/transactionDetailsSlice";
import walletDetailsReducer from "../slices/walletDetailsSlice";
import serviceRequestReducer from "../slices/serviceRequestSlice";
import appliancesReducer from "../slices/appliancesSlice";
import applianceDetailsReducer from "../slices/applianceDetailsSlice";
import basicChargeReducer from "../slices/basicChargeSlice";
import notificationsReducer from "../slices/notificationsSlice";
import readNotificationReducer from "../slices/readNotificationSlice";
import readAllNotificationsReducer from "../slices/readAllNotificationsSlice";
import notificationsCountReducer from "../slices/notificationsCountSlice";
import revenueShareListReducer from "../slices/revenueShareListSlice";
import revenueShareDetailReducer from "../slices/revenueShareDetailSlice";
import initiateRevenueShareReducer from "../slices/initiateRevenueShareSlice";
import revenueTransactionsReducer from "../slices/revenueTransactionsSlice";
import technicianDashboardReducer from "../slices/technicianDashboardSlice";
import customerDashboardReducer from "../slices/customerDashboardSlice";
import districtAdminDashboardReducer from "../slices/districtAdminDashboardSlice";
import zonalAdminDashboardReducer from "../slices/zonalAdminDashboardSlice";
import superAdminDashboardReducer from "../slices/superAdminDashboardSlice";
import revenueConfigReducer from "../slices/revenueConfigSlice";
import productListReducer from "../slices/productListSlice";
import productSelectedReducer from "../slices/productSelectedSlice";
import productReducer from "../slices/productSlice";
import paymentOrderReducer from "../slices/paymentOrderSlice";
import rewardLoginListReducer from "../slices/rewardLoginTecnician";
import todayParticipantsReducer from "../slices/todayParticipantsSlice ";
import updateRewardReducer from "../slices/updateRewardSlice";
import WinningTechnicianReducer from "../slices/winningTechnicianSlice";
import RewardDistrictListReducer from "../slices/rewardDistrictListSlice";
import ParticipationTechCountReducer from "../slices/ParticipationTechCountSlice";
import rewardWinningListReducer from "../slices/rewardWinningTecnicianSlice";
import RewardZoneListReducer from "../slices/rewardZoneListSlice";
import createRewardReducer from "../slices/createRewardSlice";
import rewardLoginCustListReducer from "../slices/rewardLoginCustomer";
import WinningCustReducer from "../slices/winningCustSlice";
import expandReducer from "../slices/expandSlice";
import referralManagementListReducer from "../slices/referralManagementSlice";
import referralChartListReducer from "../slices/referralChatSlice"
import rewardDateRangeReducer from "../slices/rewardDateRangeSlice"


const reducer = combineReducers({
  initiatePaymentState: initiatePaymentReducer,
  transactionDetailsState: transactionDetailsReducer,
  walletDetailsState: walletDetailsReducer,
  serviceRequestState: serviceRequestReducer,
  appliancesState: appliancesReducer,
  applianceDetailsState: applianceDetailsReducer,
  basicChargeState: basicChargeReducer,
  notificationsState: notificationsReducer,
  readNotificationState: readNotificationReducer,
  readAllNotificationState: readAllNotificationsReducer,
  notificationsCountState: notificationsCountReducer,
  revenueShareListState: revenueShareListReducer,
  revenueShareDetailState: revenueShareDetailReducer,
  initiateRevenueShareState: initiateRevenueShareReducer,
  revenueTransactionsState: revenueTransactionsReducer,
  technicianDashboardState: technicianDashboardReducer,
  revenueConfigState: revenueConfigReducer,
  customerDashboardState: customerDashboardReducer,
  districtAdminDashboardState: districtAdminDashboardReducer,
  zonalAdminDashboardState: zonalAdminDashboardReducer,
  superAdminDashboardState: superAdminDashboardReducer,
  productListState: productListReducer,
  productSelectedState: productSelectedReducer,
  productState: productReducer,
  paymentOrderState: paymentOrderReducer,
  rewardLoginListState: rewardLoginListReducer,
  ParticipationTechCountState: ParticipationTechCountReducer,
  RewardZoneListState: RewardZoneListReducer,
  rewardWinningListState: rewardWinningListReducer,
  createRewardState: createRewardReducer,
  RewardDistrictListState: RewardDistrictListReducer,
  WinningTechnicianState: WinningTechnicianReducer,
  updateRewardState: updateRewardReducer,
  todayParticipantsState: todayParticipantsReducer,
  rewardLoginCustListState: rewardLoginCustListReducer,
  WinningCustState: WinningCustReducer,
  expandState: expandReducer,
  referralManagementState: referralManagementListReducer,
  referralChartState: referralChartListReducer,
  rewardDateRangeState: rewardDateRangeReducer,
});

const store = configureStore({
  reducer,
  middleware: [thunk],
});

export default store;
