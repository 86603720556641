import { createSlice } from "@reduxjs/toolkit";


const UpdateRewardSlice = createSlice({
    name: 'UpdateReward',
    initialState: {
        loading: false,
        UpdateRewardData: [],
        error: null,
        statusCode: null,
        successMsg: null,
        errorMsg: null,
        totalCount: 0,

    },
    reducers: {
        UpdateRewardRequest(state, action) {
            return {
                ...state,
                loading: true
            }
        },
        UpdateRewardSuccess(state, action) {
            console.log(action.payload, "UpdateReward........................")
            return {
                ...state,
                loading: true,
                UpdateRewardData: action?.payload,
                successMsg: action.payload?.message,
                // totalCount: action.payload.data?.totalCount,
                errorMsg: null,
                statusCode: 'success'
            }
        },
        UpdateRewardFail(state, action) {
            return {
                ...state,
                loading: false,
                error: action.payload,
                statusCode: 'error',
                errorMsg: action?.payload?.message,
                // totalCount: 0,
                UpdateRewardData: [],
                successMsg: null
            }
        },
        clearStatusCode(state, action) {
            return {
                ...state,
                statusCode: null,
                successMsg: null,
                errorMsg: null,
                UpdateRewardData: [],
            }
        }

    }
});

const { actions, reducer } = UpdateRewardSlice;

export const { UpdateRewardRequest, UpdateRewardFail, UpdateRewardSuccess, clearStatusCode } = actions

export default reducer;
