import { createSlice } from "@reduxjs/toolkit";

const zonalAdminDashboardSlice = createSlice({
  name: "zonalAdminDashboard",
  initialState: {
    loading: false,
  },
  reducers: {
    zonalAdminDashboardRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    zonalAdminDashboardSuccess(state, action) {
      return {
        ...state,
        loading: false,
        zonalAdminDashboardData: action.payload,
      };
    },
    zonalAdminDashboardFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
  },
});

const { actions, reducer } = zonalAdminDashboardSlice;

export const {
  zonalAdminDashboardRequest,
  zonalAdminDashboardFail,
  zonalAdminDashboardSuccess,
} = actions;

export default reducer;
