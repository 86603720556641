import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import TrashIcon from "../../../../Assets/icons/TrashIcon";
import { TextField, MenuItem, Button } from "@mui/material";
import Api from "../../../../service/services";
import { MdClose } from "react-icons/md";

const OrderAddress = () => {
  const [addressModal, setAddressModal] = useState(false);
  const [newAddressModal, setNewAddressModal] = useState(false);
  const [address, setAddress] = useState("");
  const [addName, setAddName] = useState("");
  const [addNameError, setAddNameError] = useState("");
  const [addAddress, setAddAddress] = useState("");
  const [addAddressError, setAddAddressError] = useState("");
  const [addDistrict, setAddDistrict] = useState("");
  const [addDistrictError, setAddDistrictError] = useState("");
  const [addPhoneno, setAddPhoneno] = useState("");
  const [addPhonenoError, setAddPhonenoError] = useState("");
  const [addPostalCode, setAddPostalCode] = useState("");
  const [addPostalCodeError, setAddPostalCodeError] = useState("");
  const [districtData, setDistrictData] = useState([]);
  const [deleteAddressId, setDeleteAddressId] = useState("");
  const [deleteAddressModal, setDeleteAddressModal] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [addressId, setAddressId] = useState("");
  const [addressValue, setAddressValue] = useState("");
  const handleAddress = () => {
    setAddressModal(true);
    Api.getUserAddress("").then((res) => {
      setAddressList(res?.data);
    });
  };
  const handleConfirmAddress = () => {
    setAddressModal(false);
    setAddress(addressValue);
  };
  const validateForm = () => {
    let isValid = true;

    if (!addName) {
      setAddNameError("Please enter the Name of the Contact Person.");
      isValid = false;
    } else {
      setAddNameError("");
    }

    if (!addPhoneno) {
      setAddPhonenoError(
        "Please enter the Mobile Number of the Contact Person."
      );
      isValid = false;
    } else if (addPhoneno.length !== 10) {
      setAddPhonenoError("Please enter a valid mobile number.");
      isValid = false;
    } else {
      setAddPhonenoError("");
    }

    if (!addAddress) {
      setAddAddressError("Please enter the Door No., Street, City/Town.");
      isValid = false;
    } else {
      setAddAddressError("");
    }

    if (!addDistrict) {
      setAddDistrictError("Please select a District");
      isValid = false;
    } else {
      setAddDistrictError("");
    }

    if (!addPostalCode) {
      setAddPostalCodeError("Please enter the Postal Code.");
      isValid = false;
    } else {
      setAddPostalCodeError("");
    }

    return isValid;
  };
  const handleAddAddress = () => {
    const isValid = validateForm();
    const payload = {
      name: addName,
      postalcode: addPostalCode,
      address: addAddress,
      mobile_no: addPhoneno,
      district_id: addDistrict,
    };
    if (isValid) {
      Api.adduserAddress(payload).then((res) => {
        setAddName("");
        setAddAddress("");
        setAddPhoneno("");
        setAddDistrict("");
        setAddPostalCode("");
        setAddAddressError("");
        setAddNameError("");
        setAddAddressError("");
        setAddPhonenoError("");
        setAddDistrictError("");
        setAddPostalCodeError("");
        Api.getUserAddress("").then((res) => {
          setNewAddressModal(false);
          setAddressModal(true);
          setAddressList(res?.data);
        });
      });
    }
  };
  const handleDeleteAddress = (id) => {
    setDeleteAddressId("");
    setDeleteAddressModal(false);
    setAddressModal(true);
    setAddress("");
    Api.deleteuserAddress(id).then((res) => {
      Api.getUserAddress("").then((res) => {
        setAddressList(res?.data);
      });
    });
  };
  useEffect(() => {
    console.log("addressValue", addressValue);
  }, []);
  return (
    <div>
      <div
        onClick={() => handleAddress()}
        className=""
        // style={{
        //   display: "flex",
        //   justifyContent: "space-between",
        // }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ fontSize: 13 }}>
            <b>Deliver to: </b>
            {addressValue?.name}
          </div>
          <div
            variant="outlined"
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              color: "rgb(235, 129, 40)",
              fontSize: 13,
              // float: "right",
            }}
          >
            {addressValue === "" ? "Select Address" : "Change Address"}
          </div>
        </div>
        <div style={{ fontSize: 13 }}>
          {addressValue?.address &&
            addressValue?.city_name &&
            addressValue?.postalcode && (
              <text>
                <b>Address:</b> {addressValue?.address},{" "}
                {addressValue?.city_name}, {addressValue?.postalcode}
              </text>
            )}
        </div>

        {addressValue?.mobile_no && (
          <div style={{ fontSize: 13 }}>
            <b>Mobile: </b>
            {addressValue?.mobile_no}
          </div>
        )}
      </div>

      <Modal
        show={addressModal}
        size="md"
        onHide={() => setAddressModal(false)}
        centered
      >
        <div className="p-4">
          {addressList?.length > 0 && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div variant="outlined" style={{ cursor: "pointer" }}>
                Select Address
              </div>
              <div
                onClick={() => setAddressModal(false)}
                style={{ cursor: "pointer" }}
              >
                <MdClose />
              </div>
            </div>
          )}
          <div className="p-2 address-scroll">
            {addressList?.length > 0 ? (
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={addressId}
                onChange={(e) => {
                  console.log("Displaying", e.target.value);
                  setAddressId(e.target.value);
                }}
              >
                {addressList?.map((address, i) => {
                  return (
                    <div className="mt-2" key={i}>
                      <div className="d-flex align-items-center justify-content-space-between">
                        <div className="d-flex align-items-center">
                          <div>
                            <FormControlLabel
                              value={address.id.toString()}
                              control={<Radio />}
                              onClick={() => setAddressValue(address)}
                            />
                          </div>
                          <div
                            style={{
                              color:
                                addressId === address.id.toString() &&
                                "rgb(235, 129, 40)",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                          >
                            {address?.name}
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-3">
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              setDeleteAddressId(address.id);
                              setDeleteAddressModal(true);
                              setAddressModal(false);
                            }}
                          >
                            <TrashIcon />
                          </div>
                        </div>
                      </div>
                      <div className="pe-4 ps-4">
                        <div className="mt-0" style={{ fontSize: "12px" }}>
                          <span>{address?.address},</span>
                        </div>
                        <div className="mt-0" style={{ fontSize: "12px" }}>
                          <span>{address?.city_name},</span>
                          <span> </span>
                          <span>{address?.state_name},</span>
                        </div>
                        <div style={{ fontSize: "12px" }}>
                          <span>{address?.postalcode}</span>
                        </div>
                        <div style={{ fontSize: "12px", marginTop: "5px" }}>
                          <span>
                            {address?.mobile_no && "+91"} {address?.mobile_no}
                          </span>
                        </div>
                      </div>
                      <hr />
                    </div>
                  );
                })}
              </RadioGroup>
            ) : (
              <div className="mt-5 mb-5">
                <div style={{ textAlign: "center" }}>No Address Found,</div>
                <div style={{ textAlign: "center" }}>
                  Please Add an Address to Continue
                </div>
              </div>
            )}
          </div>
          <div className="d-flex align-items-center justify-content-center gap-3">
            <button
              onClick={() => {
                setNewAddressModal(true);
                setAddressModal(false);
                Api.getCityByStateId(23).then((res) => {
                  setDistrictData(res.data);
                });
              }}
              className="add-new-address-btn"
            >
              Add New Address
            </button>
            <button
              className="confirm-address-btn"
              style={{
                backgroundColor:
                  addressList?.length > 0 ? "#EB8128" : "#F7CD8B",
              }}
              onClick={() => handleConfirmAddress()}
              disabled={addressList?.length === 0}
            >
              Confirm Address
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        show={newAddressModal}
        size="md"
        onHide={() => {
          setAddName("");
          setAddAddress("");
          setAddPhoneno("");
          setAddDistrict("");
          setAddPostalCode("");
          setAddAddressError("");
          setAddNameError("");
          setAddAddressError("");
          setAddPhonenoError("");
          setAddDistrictError("");
          setAddPostalCodeError("");
          setNewAddressModal(false);
        }}
        centered
      >
        <div className="p-3">
          <div
            className="pe-3 ps-3"
            style={{
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Add Address
          </div>
          <div className="mt-4">
            <TextField
              label="Name of the Contact Person"
              className="w-100"
              onChange={(e) => setAddName(e.target.value)}
              value={addName}
              helperText={addNameError}
              error={!!addNameError}
              onKeyUp={() => {
                if (!addName) {
                  setAddNameError(
                    "Please enter the Name of the Contact Person."
                  );
                } else {
                  setAddNameError("");
                }
              }}
            />
          </div>
          <div className="mt-2">
            <TextField
              value={addAddress}
              label="Door No., Street, City/Town"
              className="w-100"
              onChange={(e) => setAddAddress(e.target.value)}
              helperText={addAddressError}
              error={!!addAddressError}
              onKeyUp={() => {
                if (!addAddress) {
                  setAddAddressError(
                    "Please enter the Door No., Street, City/Town."
                  );
                } else {
                  setAddAddressError("");
                }
              }}
            />
          </div>
          <div className="mt-2">
            <TextField
              fullWidth
              label="Select your District"
              select
              value={addDistrict}
              helperText={addDistrictError}
              error={!!addDistrictError}
              onKeyUp={() => {
                if (!addDistrict) {
                  setAddDistrictError("Please select a District.");
                } else {
                  setAddDistrictError("");
                }
              }}
              onChange={(e) => {
                setAddDistrictError("");
                setAddDistrict(e.target.value);
              }}
            >
              {districtData?.length > 0 ? (
                districtData.map((district, index) => (
                  <MenuItem key={district.city_id} value={district.city_id}>
                    {district.city_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value=""></MenuItem>
              )}
            </TextField>
          </div>
          <div className="mt-2">
            <TextField
              id="mobile-number"
              value={addPhoneno}
              label="Mobile Number of the Contact Person"
              className="w-100"
              onChange={(e) => {
                const value = e.target.value;
                const numericValue = value.replace(/\D/g, "");
                const formattedValue = numericValue.slice(0, 10);
                setAddPhoneno(formattedValue);
              }}
              type="number"
              helperText={addPhonenoError}
              error={!!addPhonenoError}
              onKeyUp={() => {
                if (!addPhoneno) {
                  setAddPhonenoError(
                    "Please enter the Mobile Number of the Contact Person."
                  );
                } else if (addPhoneno.length !== 10) {
                  setAddPhonenoError("Please enter valid Mobile Number");
                } else {
                  setAddPhonenoError("");
                }
              }}
            />
          </div>
          <div className="mt-3">
            <TextField
              value={addPostalCode}
              label="Postal Code"
              className="w-100"
              onChange={(e) => {
                const postalCode = e.target.value;
                if (postalCode.length <= 6) setAddPostalCode(postalCode);
              }}
              helperText={addPostalCodeError}
              error={!!addPostalCodeError}
              onKeyUp={() => {
                if (!addPostalCode) {
                  setAddPostalCodeError("Please enter the Postal Code.");
                } else {
                  setAddPostalCodeError("");
                }
              }}
            />
          </div>
          <div className="mt-3 d-flex align-items-center justify-content-center gap-3">
            <button
              onClick={() => {
                setNewAddressModal(false);
                setAddressModal(true);
                setAddName("");
                setAddAddress("");
                setAddPhoneno("");
                setAddDistrict("");
                setAddPostalCode("");
                setAddAddressError("");
                setAddNameError("");
                setAddAddressError("");
                setAddPhonenoError("");
                setAddDistrictError("");
                setAddPostalCodeError("");
              }}
              className="add-new-address-btn"
            >
              Cancel
            </button>
            <button
              onClick={() => handleAddAddress()}
              className="confirm-address-btn"
              style={{
                backgroundColor: "#EB8128",
              }}
            >
              Add Address
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        show={deleteAddressModal}
        size="md"
        onHide={() => {
          setDeleteAddressId("");
          setDeleteAddressModal(false);
        }}
        centered
      >
        <div className="p-3">
          <div
            className="pe-3 ps-3"
            style={{
              fontSize: "18px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Delete Address
          </div>
          <div className="mt-4" style={{ textAlign: "center" }}>
            Are you sure want to delete this address?
          </div>
          <div className="mt-4 d-flex align-items-center justify-content-center gap-3">
            <button
              onClick={() => {
                handleDeleteAddress(deleteAddressId);
              }}
              className="delete-address-yes-btn"
              style={{
                height: "40px",
                width: "100px",
                backgroundColor: "#EB8128",
                border: "none",
                outline: "none",
                color: "#fff",
              }}
            >
              Yes
            </button>
            <button
              onClick={() => {
                setDeleteAddressModal(false);
                setDeleteAddressId("");
                setAddressModal(true);
              }}
              className="delete-address-no-btn"
              style={{
                height: "40px",
                width: "100px",
                backgroundColor: "transparent",
                border: "2px solid #EB8128",
                outline: "none",
                color: "#000",
              }}
            >
              No
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default OrderAddress;
