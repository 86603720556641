import { createSlice } from "@reduxjs/toolkit";


const productSelectedSlice = createSlice({
    name: 'productSelected',
    initialState: {
        loading: false,
    },
    reducers: {
        productSelectedRequest(state, action) {
            return {
                ...state,
                loading: true
            }
        },
        productSelectedSuccess(state, action) {
            return {
                ...state,
                loading: false,
                productSelected: action.payload,
            }
        },
        productSelectedFail(state, action) {
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        },
        productSelectedErrorClear(state, action) {
            return {
                ...state,
                loading: false,
                error:false,
            }
        }
    }
});

const { actions, reducer } = productSelectedSlice;

export const { productSelectedRequest, productSelectedFail, productSelectedSuccess, productSelectedClear } = actions

export default reducer;