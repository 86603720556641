import React from "react";
import { Navigate } from "react-router-dom";
import ErrorPage from "../components/Error";

const ProtectedRouteTechnician = ({ children }) => {
  const Role = localStorage.getItem("role");

  if (!Role) {
    return <Navigate to={"/login"} />;
  }

  if (
    Role !== "Technician" &&
    Role !== "DistrictAdmin" &&
    Role !== "ZonalAdmin" &&
    Role !== "SuperAdmin"
  ) {
    return <ErrorPage />;
  }

  if (
    Role === "Technician" ||
    Role === "DistrictAdmin" ||
    Role === "ZonalAdmin" ||
    Role === "SuperAdmin"
  ) {
    return children;
  }
};

export default ProtectedRouteTechnician;
