import React, { useEffect, useState } from "react";
import { MdKeyboardDoubleArrowUp } from "react-icons/md";
import { useLocation } from "react-router-dom";

const TopScroller = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    });
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        position: "relative",
        top: "-62px",
        height: 0,
        bottom: 0,
      }}
    >
      <button
        className={`top-scroller ${isVisible ? "visible" : "visible"}`}
        onClick={scrollToTop}
      >
        <MdKeyboardDoubleArrowUp />
      </button>
    </div>
  );
};

export default TopScroller;
