import React, { useContext, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  FormControlLabel,
  FormLabel,
  InputBase,
  Paper,
  Radio,
  RadioGroup,
  Slider,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Form, Modal } from "react-bootstrap";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { FaCalendarDays } from "react-icons/fa6";
import moment from "moment";
import Api from "../service/services";
import SearchableDropdownDistrict from "../components/common/searchableDropDownDistrict";
import SearchableDropdownZone from "../components/common/searchableDropDownZone";
import { useLocation } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import CountContext from "../components/countContext/CountContext";
import { useSelector } from "react-redux";
import WinningStatus from "../components/common/winningStatus";
// import WinningStatus from "../components/common/winningStatusDropDown";

const FilterModule = ({
  inputPlaceholder,
  onSearchInputChange,
  onChangeFilterData,
  pageRole,
  showDateMethod,
  showDatePicker,
  tabValue,
  clearValues,
  showWinningStatus,
}) => {
  console.log(tabValue, "tabValue");
  const ROLE = localStorage.getItem("role");
  const { pathname: PATH } = useLocation();
  const [filterModal, setFilterModal] = useState(false);
  const [districtList, setDistrictList] = useState([]);
  const [winningStatus, setWinningStatus] = useState([
    { id: 1, name: "Winners" },
    { id: 2, name: "Non-Winners" },
  ]);
  const [zoneList, setZoneList] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedWinningStatus, setSelectedWinningStatus] = useState("");
  const [selectedZone, setSelectedZone] = useState("");
  const [viewDatePickerPopover, setViewDatePickerPopover] = useState(false);
  const [maxAmount, setMaxAmount] = useState("");
  const [minAmount, setMinAmount] = useState("");
  const [filterApplied, setFilterApplied] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const [radiovalue, setRadioValue] = useState("createdDate");
  const [statusRadioValue, setStatusRadioValue] = useState();
  const [applianceList, setApplianceList] = useState([]);
  const [result, setResult] = useState([]);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [max, setMax] = useState(0);

  const [applianceValue, setApplianceValue] = useState("Select Appliance");
  const [errorApplianceValue, setErrorApplianceValue] = useState("");

  const { statusCode } = useSelector((state) => state.rewardLoginCustListState);
  const handleSelectAppliance = () => {
    Api.getApplianceDropdown().then((result) => {
      setApplianceList(result);
      setResult(result);
    });
  };

  const [newTabChange, setNewTabChange] = useState(tabValue);
  useEffect(() => {
    Api.getTechnicianProducts()
      .then((res) => {
        const amountsArray = res?.list.map((item) => item?.sale_price);
        const sortedAmounts = amountsArray.sort((a, b) => a - b);
        const maxAmount = sortedAmounts[sortedAmounts?.length - 1];
        setMax(Math.round(maxAmount));
        setMaxValue(Math.round(maxAmount));
        console.log("Max Amount:", sortedAmounts, maxAmount);
      })
      .catch((err) => console.log("Error", err));
  }, []);
  useEffect(() => {
    setSearch("");
    setFilterApplied(false);
    setNewTabChange(tabValue);
    clearData();
  }, [clearValues]);
  //slider
  function valuetext(value) {
    return `$${value}`;
  }
  const handleMinChange = (event) => {
    setMinValue(Number(event.target.value));
  };

  const handleMaxChange = (event) => {
    setMaxValue(Number(event.target.value));
  };
  const handleChange = (event, newValue) => {
    setMinValue(newValue[0]);
    setMaxValue(newValue[1]);
  };
  const currentDate = new Date();
  const defaultDate = [
    {
      startDate: currentDate,
      endDate: currentDate,
      key: "selection",
    },
  ];
  const [dateRange, setDateRange] = useState(defaultDate);
  const handleDistrictSelection = (e) => {
    setSelectedDistrict(e);
  };
  const handleWinningStatusSelection = (e) => {
    setSelectedWinningStatus(e);
  };
  const handleZoneList = (e) => {
    setSelectedDistrict("");
    setSelectedZone(e);
    Api.getDistrictsByZone(e.id).then((result) => {
      setDistrictList(result);
    });
  };
  const initialApiRequests = () => {
    Api.getAllZones().then((result) => {
      setZoneList(result);
    });
    Api.getDistrictsByZone().then((result) => {
      setDistrictList(result);
    });
  };
  const handleFilterCancel = () => {
    // setSelectedDistrict("");
    // setSelectedZone("");
    setFilterModal(false);
    // Api.getDistrictsByZone().then((result) => {
    //   setDistrictList(result);
    // });
  };
  const [clickTrue, setClickTrue] = useState(false);
  useEffect(() => { }, [statusRadioValue, radiovalue]);
  const handleApplyFilter = () => {
    if (clickTrue) {
      onChangeFilterData({
        startDate: clearFilter
          ? ""
          : moment(dateRange[0]?.startDate).format("YYYY-MM-DD"),
        endDate: clearFilter
          ? ""
          : moment(dateRange[0]?.endDate).format("YYYY-MM-DD"),
        maxWallet: maxAmount,
        winningStatus: selectedWinningStatus ? (selectedWinningStatus?.name === "Winners") ? "true" : "false" : "",
        minWallet: minAmount,
        filterBy: radiovalue,
        status: statusRadioValue,
        district: selectedDistrict ? selectedDistrict?.name : "",
        zone_id: selectedZone ? selectedZone?.id : "",
        min_amount: minValue,
        max_amount: maxValue,
        product: applianceValue == "Select Appliance" ? "" : applianceValue,
      });
    } else {
      onChangeFilterData({
        winningStatus: selectedWinningStatus ? (selectedWinningStatus?.name === "Winners") ? "true" : "false" : "",
        maxWallet: maxAmount,
        minWallet: minAmount,
        filterBy: radiovalue,
        status: statusRadioValue,
        district: selectedDistrict ? selectedDistrict?.name : "",
        zone_id: selectedZone ? selectedZone?.id : "",
        min_amount: minValue,
        max_amount: maxValue,
        product: applianceValue == "Select Appliance" ? "" : applianceValue,
      });
    }

    setFilterModal(false);
    setClearFilter(false);
    if (clearFilter) {
      setFilterApplied(false);
    } else {
      setFilterApplied(true);
    }
  };

  useEffect(() => {
    initialApiRequests();
  }, []);
  const allowModule = (data) => {
    const allowedRoles = data?.roles;
    const allowedPaths = data?.paths;
    if (
      allowedRoles.some((role) => role === ROLE) &&
      allowedPaths.some((path) => path === PATH)
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (newTabChange !== tabValue) {
      setSearch("");
      setFilterApplied(false);
      setNewTabChange(tabValue);
      clearData();
    }
  }, [tabValue]);
  const clearData = () => {
    setSelectedDistrict("");
    setSelectedWinningStatus("");
    setSelectedZone("");
    setMaxAmount("");
    setMinAmount("");
    setClearFilter(true);
    // setDateRange(defaultDate);
    setClickTrue(false);
    initialApiRequests();
    setStatusRadioValue();
    setRadioValue("createdDate");
    setFilterApplied(false);
    setApplianceValue("Select Appliance");
    setMaxValue(0);
    setMinValue(0);
    // setFilterModal(false);
  };
  const DatePicker = () => {
    const [dateRangeSelected, setDateRangeSelected] = useState(defaultDate);
    const handleSelectDate = (ranges) => {
      setDateRangeSelected([ranges.selection]);
    };
    const handleCancelDate = () => {
      // setDateRange(defaultDate);
      setClickTrue(false);
      setViewDatePickerPopover(false);
      setDateRangeSelected(defaultDate);
    };
    const handleSetSelectedDate = () => {
      setClickTrue(true);
      setDateRange(dateRangeSelected);
      setViewDatePickerPopover(false);
      setDateRangeSelected(defaultDate);
      setClearFilter(false);
    };
    return (
      <div>
        <div className="custom-label">
          <div className="label">
            {PATH === "/usermanagement"
              ? "Technician On-boarded Date"
              : "Service Request Date"}
          </div>
        </div>
        <div
          onClick={() => {
            setViewDatePickerPopover(!viewDatePickerPopover);
            setDateRangeSelected(dateRange);
          }}
          className="selected-date-filter"
        >
          {clickTrue ? (
            <div className="d-flex align-items-center gap-3">
              <div>{moment(dateRange[0].startDate).format("DD-MM-YYYY")}</div>
              {moment(dateRange[0].startDate).format("DD-MM-YYYY") !==
                moment(dateRange[0].endDate).format("DD-MM-YYYY") && (
                  <div>-</div>
                )}
              {moment(dateRange[0].startDate).format("DD-MM-YYYY") !==
                moment(dateRange[0].endDate).format("DD-MM-YYYY") && (
                  <div>{moment(dateRange[0].endDate).format("DD-MM-YYYY")}</div>
                )}
            </div>
          ) : (
            <div
              className="d-flex align-items-center gap-3"
              style={{ color: "gray" }}
            >
              DD-MM-YYYY
            </div>
          )}
          <FaCalendarDays style={{ color: "rgb(235, 129, 40)" }} />
        </div>
        <Modal
          show={viewDatePickerPopover}
          onHide={() => setViewDatePickerPopover(false)}
          className="date-picker-modal"
        >
          <div
            className="filter-module-date-picker"
            style={{
              justifyContent: "center",
              //   paddingTop: "40px",
              //   paddingBottom: "40px",
            }}
          >
            <div
            // style={{
            //   border: "1px solid #00000052",
            //   boxShadow: "0 0 10px 1px #00000012",
            // }}
            >
              <DateRangePicker
                ranges={dateRangeSelected}
                onChange={handleSelectDate}
                maxDate={currentDate}
                rangeColors={"rgb(235, 129, 40)"}
              />
            </div>
            <div className="date-set-btns">
              <button
                className="filter-apply-btn"
                onClick={() => handleSetSelectedDate()}
              >
                Set
              </button>
              <button
                className="filter-cancel-btn  mt-2"
                onClick={() => handleCancelDate()}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  };

  const { setSearch, search } = useContext(CountContext);
  const [previousPath, setPreviousPath] = useState("");
  useEffect(() => {
    // Get the current path
    const currentPath = window.location.pathname;
    if (previousPath !== currentPath) {
      setPreviousPath(currentPath);
      setSearch("");
    }
  }, []);
  useEffect(() => {
    onSearchInputChange(search);
  }, [search]); // Run the effect only when the 'search' value changes
  return (
    <div>
      <div
        className="p-0 mt-2"
        style={{
          display: "flex",
          width: "-webkit-fill-available",
        }}
      >
        <div
          style={{
            listStyle: "none",
            width: "-webkit-fill-available",
            display: "flex",
          }}
        >
          <Paper
            elevation={0}
            className="w-100 p-lg-3 border rounded-0"
            component="form"
            sx={{ display: "flex", alignItems: "center" }}
            style={{ width: "100%", height: "42px" }}
          >
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              value={search}
              sx={{ ml: 1, flex: 1 }}
              style={{
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "14px",
              }}
              placeholder={inputPlaceholder}
              //   onKeyUp={searchFilter}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Paper>
          {allowModule({
            roles: [
              "SuperAdmin",
              "ZonalAdmin",
              "DistrictAdmin",
              "Technician",
              "Consumer",
            ],
            paths: [
              "/usermanagement",
              "/service-list-management",
              "/service-list-technician",
              "/serviceList",
              "/approve-pending-technicians",
              "/productManagement",
              "/super-admin-reward-dashboard-technician",
              "/super-admin-reward-dashboard-customer",
              "/referral-management",
              // "/areamanagement",
            ],
          }) && (
              <div className="ms-2" style={{ listStyle: "none" }}>
                <IconButton
                  style={{ width: "42px", height: "42px" }}
                  className="border rounded-0"
                  aria-label="delete"
                  onClick={() => {
                    setFilterModal(true);
                    setClearFilter(false);
                  }}
                >
                  {filterApplied && (
                    <Tooltip title="Filter applied" arrow>
                      <div className="filter-indicator">
                        <div className="ic" />
                      </div>
                    </Tooltip>
                  )}
                  <FilterListIcon style={{ color: "rgb(235, 129, 40)" }} />
                </IconButton>
              </div>
            )}
        </div>
      </div>
      <Modal
        show={filterModal}
        onHide={() => setFilterModal(false)}
        centered
        size="lg"
        className="filter-modal"
      >
        <div className="p-3">
          <div
            style={{
              fontWeight: "600",
              fontSize: "25px",
            }}
          >
            Filter
          </div>
          <div className="mt-3">
            <div>
              <div className="row">
                {allowModule({
                  roles: [
                    "SuperAdmin",
                    "ZonalAdmin",
                    "DistrictAdmin",
                    "Technician",
                    "Consumer",
                  ],
                  paths: [
                    "/service-list-management",
                    "/service-list-technician",
                    "/serviceList",
                    "/approve-pending-technicians",
                  ],
                }) && showDateMethod === true ? (
                  <div
                    style={{
                      marginTop: "-10px",
                      marginBottom: "0px",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      style={{ color: "gray" }}
                    >
                      Filter By:
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      style={{
                        gap: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      value={radiovalue}
                      onChange={(e) => {
                        console.log("Selected value:", e.target.value);
                        setRadioValue(e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="createdDate"
                        control={<Radio />}
                        label="Created Date"
                      />
                      <FormControlLabel
                        value="closedDate"
                        control={<Radio />}
                        label="Closed Date"
                      />
                    </RadioGroup>
                  </div>
                ) : (
                  ""
                )}
                {PATH === "/usermanagement" && (
                  <div
                    style={{
                      marginTop: "-10px",
                      marginBottom: "0px",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      style={{ color: "gray" }}
                    >
                      Status:
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      style={{
                        gap: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      value={statusRadioValue}
                      onChange={(e) => {
                        console.log("Selected value:", e.target.value);
                        setStatusRadioValue(e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="Active"
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="Inactive"
                      />
                    </RadioGroup>
                  </div>
                )}
                {PATH === "/productManagement" && (
                  <div
                    style={{
                      marginTop: "-10px",
                      marginBottom: "0px",
                      padding: "10px",
                    }}
                  >
                    <Form.Select
                      placeholder="Select Appliance"
                      aria-label="Select Appliance"
                      className="mb-2"
                      style={{
                        color: "gray",
                        height: "50px",
                        cursor: "pointer",
                      }}
                      id="custom-select"
                      value={applianceValue}
                      onChange={(e) => {
                        const selectedApplianceValue = e.target.value;
                        console.log(
                          "selectedApplianceValue",
                          selectedApplianceValue,
                          e.target
                        );
                        setApplianceValue(selectedApplianceValue);
                      }}
                      onClick={handleSelectAppliance}
                    >
                      <option
                        value="Select Appliance"
                        style={{ color: "gray" }}
                      >
                        Select Appliance
                      </option>
                      {applianceList?.map((data) => (
                        <option
                          key={data?.id}
                          value={data?.name}
                          style={{
                            width: "100px",
                            wordWrap: "break-word",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {data?.appliance_name}
                        </option>
                      ))}
                    </Form.Select>
                    {/* <div className="col-lg-12 mt-3 mb-2 d-flex gap-1">
                      {" "}
                      <TextField
                        className="col-lg-6"
                        value={minValue}
                        label="Min Amount"
                        onChange={handleMinChange}
                      />
                      <TextField
                        className="col-lg-6"
                        value={maxValue}
                        label="Max Amount"
                        onChange={handleMaxChange}
                      />
                    </div>
                    <Slider
                      getAriaLabel={() => "Price range"}
                      value={[minValue, maxValue]}
                      onChange={handleChange}
                      valueLabelDisplay="auto"
                      getAriaValueText={valuetext}
                      min={0}
                      max={max}
                      disableSwap
                    /> */}
                  </div>
                )}
              </div>
              <div className="row">
                {(allowModule({
                  roles: [
                    "SuperAdmin",
                    "ZonalAdmin",
                    "DistrictAdmin",
                    "Technician",
                    "Consumer",
                  ],
                  paths: [
                    "/usermanagement",
                    "/service-list-management",
                    "/service-list-technician",
                    "/serviceList",
                    "/approve-pending-technicians",
                  ],
                }) ||
                  showDatePicker) && (
                    <div className="col-12 mt-3  col-lg-6">
                      <DatePicker />
                    </div>
                  )}
                {showWinningStatus && (
                  <div className="col-12  col-lg-6">
                    <WinningStatus
                      options={winningStatus}
                      label="name"
                      id="admin-list"
                      selectedVal={selectedWinningStatus?.name}
                      handleChange={handleWinningStatusSelection}
                      type="Winner Status"
                    />
                  </div>
                )}
                {allowModule({
                  roles: ["SuperAdmin", "ZonalAdmin", "DistrictAdmin"],
                  paths: ["/usermanagement"],
                }) &&
                  pageRole !== "customer" && (
                    <div className="col-12 col-lg-6">
                      <div className="row">
                        <div className="col-12 mt-3  mt-sm-3 mt-md-3  col-lg-6">
                          <div className="custom-label">
                            <div className="label">Min Wallet Balance</div>
                          </div>
                          <input
                            pattern="[0-9]*"
                            placeholder="0"
                            className="min-wallet-input"
                            value={minAmount}
                            type="text"
                            onChange={(e) =>
                              setMinAmount(
                                e.target.value.replace(/[^0-9]/g, "")
                              )
                            }
                          />
                        </div>
                        <div className="col-12 mt-3 mt-sm-3 mt-md-3 col-lg-6">
                          <div className="custom-label">
                            <div className="label">Max Wallet Balance</div>
                          </div>
                          <input
                            placeholder="0"
                            className="max-wallet-input"
                            value={maxAmount}
                            type="text"
                            pattern="[0-9]*"
                            onChange={(e) =>
                              setMaxAmount(
                                e.target.value.replace(/[^0-9]/g, "")
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
              </div>
              <div className="row">
                {allowModule({
                  roles: ["SuperAdmin"],
                  paths: [
                    "/usermanagement",
                    "/service-list-management",
                    "/approve-pending-technicians",
                    "/super-admin-reward-dashboard-technician",
                    "/super-admin-reward-dashboard-customer",
                    "/referral-management",
                  ],
                }) && (
                    <div className="col-12  col-lg-6">
                      <SearchableDropdownZone
                        options={zoneList}
                        label="zone_name"
                        id="admin-list"
                        selectedVal={selectedZone?.zone_name}
                        handleChange={handleZoneList}
                        type="Zone"
                      />
                    </div>
                  )}
                {allowModule({
                  roles: ["SuperAdmin", "ZonalAdmin"],
                  paths: [
                    "/usermanagement",
                    "/service-list-management",
                    "/approve-pending-technicians",
                    "/super-admin-reward-dashboard-technician",
                    "/super-admin-reward-dashboard-customer",
                    "/referral-management",
                  ],
                }) && (
                    <div className="col-12  col-lg-6">
                      <SearchableDropdownDistrict
                        options={districtList}
                        label="name"
                        id="admin-list"
                        selectedVal={selectedDistrict?.name}
                        handleChange={handleDistrictSelection}
                        type="District"
                      />
                    </div>
                  )}
              </div>
              <div
                className="d-flex align-items-center mt-3 gap-2"
                style={{ justifyContent: "flex-end", width: "100%" }}
              >
                <button
                  className="filter-clear-btn"
                  onClick={() => clearData()}
                >
                  Clear
                </button>
                <button
                  className="filter-cancel-btn"
                  onClick={() => handleFilterCancel()}
                >
                  Cancel
                </button>
                <button
                  className="filter-apply-btn"
                  onClick={() => handleApplyFilter()}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default FilterModule;
