import React, { useEffect, useState } from "react";
import Topbar from "../../../topbar";
import Sidebar from "../../../Sidebar";
import SpinnerLoader from "../../../../plugins/SpinnerLoader";
import { useNavigate, useParams } from "react-router-dom";
import Image from "../../../../Images/AboutUs-3.png";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  TextField,
  Typography,
} from "@mui/material";
import { AiOutlineDelete } from "react-icons/ai";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import Api from "../../../../service/services";
import { useDispatch } from "react-redux";
import {
  PaymentOrder,
  initiatePayment,
} from "../../../../redux/actions/paymentAction";
import OrderAddress from "./OrderAddress";
import Swal from "sweetalert2";

export default function BuyNow() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [loadingData, setLoadingData] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [focused, setFocused] = useState(false);
  const [buyNowById, setBuyNowById] = useState({});
  const [deliveryCharge, setDeliveryCharge] = useState({});
  const [count, setCount] = useState(1);

  useEffect(() => {
    setLoadingData(true);
    setTimeout(() => {
      setLoadingData(false);
    }, 1000);
    Api.getTechnicianProductsById(id)
      .then((res) => {
        console.log("getTechnicianProductsById", res);
        setBuyNowById(res);
        setCount(res?.minimum_quantity || 1);
      })
      .catch((err) => console.log(err));
    Api.getdeliveryCharge(id, 23)
      .then((res) => {
        console.log("deliveryCharge", res);
        setDeliveryCharge(res);
      })
      .catch((err) => console.log(err));
    Api.getUserAddress()
      .then((res) => {
        console.log("userAddress", res);
      })
      .catch((err) => console.log(err));
  }, []);
  const removeCart = (id) => {
    Api.RemoveFromCart(id)
      .then((res) => {
        console.log("removeCart", res);
        Swal.fire({
          text: res?.message,
          confirmButtonText: "OK",
        });
      })
      .catch((err) => console.log(err));
  };
  const handlePayment = () => {
    const payload = {
      cart_ids: [id],
      address_id: "23",
    };
    dispatch(PaymentOrder(payload));
  };
  return (
    <div style={{ overflow: "hidden" }}>
      <Topbar />
      <div className="row">
        <div className="col-lg-2 col-12 border border-right">
          <Sidebar />
        </div>
        <div className="col-12 user_management" style={{ marginTop: "80px" }}>
          {loadingData ? (
            <div
              className="d-flex align-items-center"
              style={{ justifyContent: "center", height: "70vh" }}
            >
              <SpinnerLoader />
            </div>
          ) : (
            <div className="container-fluid" style={{ overflow: "hidden" }}>
              <div className="col-lg-12 ">
                <div
                  className="m-0 sticky-top bg-white"
                  style={{ position: "sticky", zIndex: "2" }}
                >
                  <i
                    className="bi bi-chevron-left me-2"
                    onClick={() => navigate(-1)}
                    style={{
                      fontWeight: "600",
                      fontSize: "21px",
                      width: "20px",
                      height: "20px",
                      cursor: "pointer",
                    }}
                  ></i>
                  <text
                    className="p-0"
                    style={{
                      fontWeight: "600",
                      color: "#1E232C",
                      fontSize: "21px",
                      width: "208px",
                      height: "32px",
                    }}
                  >
                    Cart{" "}
                  </text>
                  <div style={{ marginTop: "20px" }}>
                    Total Quantity({count})
                  </div>
                  <div className="addtocart">
                    <div
                      className="custom-scrollbar addtocartwidth"
                      style={{
                        height: "400px",
                        marginLeft: "10px",
                      }}
                    >
                      <Card
                        sx={{ maxWidth: 350 }}
                        style={{
                          marginTop: "20px",
                          marginBottom: "12px",
                          marginLeft: 1,
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <div style={{ padding: 6 }}>
                            <CardMedia
                              component="img"
                              image={buyNowById?.product_img}
                              alt={buyNowById?.name}
                              style={{ height: "120px", width: "100px" }}
                            />
                            <div style={{ marginTop: "9px" }}>
                              <text>Qty: </text>
                              <text
                                onClick={() => {
                                  if (count !== buyNowById?.minimum_quantity) {
                                    setCount(count - 1);
                                  }
                                }}
                                style={{ color: "red", cursor: "pointer" }}
                              >
                                <FaMinus />
                              </text>
                              <text style={{ marginLeft: "6px" }}>{count}</text>
                              <text
                                onClick={() => {
                                  setCount(count + 1);

                                  const payload = {
                                    cart_id: buyNowById?.id,
                                    quantity: count + 1, // Use the updated count value
                                  };
                                  console.log("payloaddd", payload);

                                  Api.updateCart(payload)
                                    .then((res) => console.log("ressss-", res))
                                    .catch((err) => console.log(err));

                                  setCount(count); // Reset the count to 0 or any desired value
                                }}
                                style={{
                                  marginLeft: "6px",
                                  color: "green",
                                  cursor: "pointer",
                                }}
                              >
                                <FaPlus />
                              </text>
                            </div>
                          </div>

                          <CardContent>
                            <Typography gutterBottom variant="h6" component="p">
                              {buyNowById?.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{
                                display: "-webkit-box",
                                WebkitLineClamp: 3,
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {buyNowById?.description}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              style={{ color: "black" }}
                            >
                              ₹{buyNowById?.total_amount}{" "}
                              {/* <span style={{ color: "gray" }}>/Unit </span> */}
                            </Typography>
                            <div style={{ float: "right" }}>
                              {/* <Typography
                                variant="body2"
                                color="text.secondary"
                                style={{ color: "black" }}
                              >
                                Delivery by Today, Tue
                              </Typography> */}
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                <AiOutlineDelete
                                  onClick={() => removeCart(buyNowById?.id)}
                                  size={20}
                                  style={{
                                    color: "rgb(235, 129, 40)",
                                    cursor: "pointer",
                                  }}
                                />
                              </Typography>
                            </div>
                          </CardContent>
                        </div>
                      </Card>
                    </div>
                    <div style={{ width: "84%" }}>
                      <div style={{ display: "flex" }}>
                        <div>
                          <OrderAddress />
                        </div>
                        {/* <Button>Select Address</Button> */}
                      </div>
                      <Card
                        sx={{ maxWidth: "100%", marginTop: "8px" }}
                        style={{
                          height: "380px",
                        }}
                      >
                        <CardContent>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <TextField
                              id="standard-basic"
                              label="Enter Coupon"
                              variant="standard"
                              InputProps={{ disableUnderline: true }}
                              InputLabelProps={{
                                style: {
                                  color: focused ? "rgb(235, 129, 40)" : "gray",
                                  transition: "color 0.3s ease-in-out",
                                },
                              }}
                              onFocus={() => setFocused(true)}
                              onBlur={() => setFocused(false)}
                            />
                            <Button
                              variant="outlined"
                              style={{
                                color: "rgb(235, 129, 40)",
                                border: "1px solid transparent",
                              }}
                            >
                              Apply
                            </Button>
                          </div>
                          <hr style={{ color: "gray" }} />
                          <Typography
                            gutterBottom
                            variant="div"
                            component="div"
                            style={{ fontWeight: "bold" }}
                          >
                            Payment Details
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p style={{ color: "gray", marginTop: "10px" }}>
                              Total Order (₹):
                            </p>
                            <p style={{ color: "black" }}>
                              {buyNowById?.total_amount * count}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p style={{ color: "gray" }}>
                              Delivery Charges (₹):
                            </p>
                            <p style={{ color: "black" }}>
                              {deliveryCharge?.delivery_charge
                                ? deliveryCharge?.delivery_charge
                                : 0}
                            </p>
                          </div>{" "}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p style={{ color: "gray" }}>Discount (₹):</p>
                            <p style={{ color: "black" }}>
                              {buyNowById?.discounted_price
                                ? buyNowById?.discounted_price
                                : "-"}
                            </p>
                          </div>
                          <hr style={{ color: "gray" }} />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p style={{ color: "gray" }}> Total Payable (₹)</p>
                            <p style={{ color: "black" }}>
                              {Number(buyNowById?.discounted_price) +
                                Number(buyNowById?.total_amount * count) +
                                Number(deliveryCharge?.delivery_charge)}
                            </p>
                          </div>
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: "rgb(235, 129, 40)",
                              width: "100%",
                            }}
                            onClick={handlePayment}
                          >
                            Confirm Order
                          </Button>
                        </CardContent>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
