import axios from "axios";
// import { DRAW_SERVICE_BASE_URL } from "../../api/apiBaseUrl";
import { BASE_URL } from "../../api/ApiPath";
import { WinningTechnicianRequest, WinningTechnicianFail, WinningTechnicianSuccess } from "../slices/winningTechnicianSlice";
export const getAllWinningTechnician = (startDate, endDate) => async (dispatch) => {
    const token = localStorage.getItem("token");
    try {
        dispatch(WinningTechnicianRequest())
        const response = await axios.get(`${BASE_URL}/technician/reward?startDate=${startDate}&endDate=${endDate}`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        dispatch(WinningTechnicianSuccess(response?.data))
        console.log("WinningTechnicianLists", response?.data);
    } catch (error) {
        console.log(error);
        dispatch(WinningTechnicianFail(error?.response?.data))
    }
}
