import React from "react";
import vector from "../../Assets/Home/history-vector.svg";
import vector_img from "../../Assets/Home/vector-img-mob.svg";
import vector_1 from "../../Assets/Home/vector-1.svg";
import vector_2 from "../../Assets/Home/vector-2.svg";
import vector_3 from "../../Assets/Home/vector-3.svg";
import vector_4 from "../../Assets/Home/vector-4.svg";
import vector_5 from "../../Assets/Home/vector-5.svg";
import vector_6 from "../../Assets/Home/vector-6.svg";
import vector_7 from "../../Assets/Home/vector-7.svg";
import vector_8 from "../../Assets/Home/vector-8.svg";
import vector_9 from "../../Assets/Home/vector-9.svg";

export default function ServiceHistory() {
  const documentDimensions = () => {
    return { width: window.innerWidth };
  };
  const alphaItems = [
    {
      id: 1,
      svgData: () => <img src={vector_1} alt="1" />,
      postPos: documentDimensions().width - 1180,
      height: "40px",
    },
    {
      id: 2,
      svgData: () => <img src={vector_6} alt="1" />,
      postPos: documentDimensions().width - 585,
      height: "40px",
    },
    {
      id: 3,
      svgData: () => <img src={vector_2} alt="1" />,
      postPos: documentDimensions().width - 1050,
      height: "10px",
    },
    {
      id: 4,
      svgData: () => <img src={vector_7} alt="1" />,
      postPos: documentDimensions().width - 440,
      height: "30px",
    },
    {
      id: 5,
      svgData: () => <img src={vector_3} alt="1" />,
      postPos: documentDimensions().width - 1350,
      height: "45px",
    },
    {
      id: 6,
      svgData: () => <img src={vector_8} alt="1" />,
      postPos: documentDimensions().width - 540,
      height: "60px",
    },
    {
      id: 7,
      svgData: () => <img src={vector_4} alt="1" />,
      postPos: documentDimensions().width - 1150,
      height: "50px",
    },
    {
      id: 8,
      svgData: () => <img src={vector_9} alt="1" />,
      postPos: documentDimensions().width - 480,
      height: "30px",
    },
    {
      id: 9,
      svgData: () => <img src={vector_5} alt="1" />,
      postPos: documentDimensions().width - 1280,
      height: "30px",
    },
  ];
  return (
    <div>
      <div className="service-history-container res-576px-d-none">
        <div className="service-history ">
          <div
            style={{ textAlign: "center", color: "white", fontSize: "18px" }}
          >
            Below are the appliances for which we support installation,
            uninstallation, service, and repair.
          </div>
          <div style={{ height: 0, position: "relative", zIndex: 3, top: 115 }}>
            {alphaItems
              .map((item) => {
                return (
                  <div
                    className="post-pos"
                    style={{
                      height: item.height,
                      display: "block",
                      width: "fit-content",
                      position: "relative",
                      left: item.postPos,
                    }}
                    key={item.id}
                  >
                    {item.svgData()}
                  </div>
                );
              })
              .sort((a, b) => a.id - b.id)}
          </div>
          <div className="vector-image">
            <img src={vector} alt="vector" />
          </div>
        </div>
      </div>
      <div className="vector-image res-576px-w-fill">
        <img
          src={vector_img}
          alt="vector"
          className="res-577px-d-none res-576px-w-fill"
        />
      </div>
    </div>
  );
}
