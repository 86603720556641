import React, { useEffect, useState } from "react";
import Logo from "../../Assets/Cust/register.svg";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Api from "../../service/services";
import { Link, useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import "bootstrap";
import Swal from "sweetalert2";
import MenuItem from "@mui/material/MenuItem";
import "../../styles/multipleSelect.css";
import {
  OutlinedInput,
  InputLabel,
  Select,
  FormControl,
  FormHelperText,
  Stack,
  Chip,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextareaAutosize,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import google from "../../Images/google_ic.png";
import facebook from "../../Images/facebook_ic.jpg";
import femaleclor from "../../Images/Femaleclor.jpg";
import maleclor from "../../Images/Maleclor.jpg";
import female from "../../Images/Female.jpg";
import male from "../../Images/Male.jpg";
import ClearIcon from "@mui/icons-material/Clear";
import S3NewLogo from "../../Assets/s3_new_logo/S3NewLogo";
import Footer from "../Footer";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import Multiselect from "multiselect-react-dropdown";
import "../../styles/multipleSelect.css";

const TechnicianRegister = () => {
  const [name, setName] = useState("");
  const [referralDescription, setReferralDescription] = useState("");
  const [referralName, setreferralName] = useState("");
  const [mobile, setMobile] = useState("");
  const [referralId, setReferralId] = useState("");
  const [email, setEmail] = useState("");
  const [password1, setPassword1] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [postalcode, setPostalcode] = useState("");
  const [address, setAddress] = useState("");
  const [cityData, setCityData] = useState([]);
  const [referralSkipped, setReferralSkipped] = useState(false);

  const [appliancesData, setApplianceData] = useState([
    { appliance_id: 1, appliance_name: "Television - LED" },
    { appliance_id: 63, appliance_name: "Television - LCD" },
    { appliance_id: 64, appliance_name: "Television - CRT" },
    { appliance_id: 2, appliance_name: "Refrigerator" },
    { appliance_id: 3, appliance_name: "Washing Machine" },
    { appliance_id: 4, appliance_name: "Air Conditioner" },
    { appliance_id: 65, appliance_name: "DTH" },
    { appliance_id: 66, appliance_name: "RO" },
    { appliance_id: 67, appliance_name: "Inverter" },
    { appliance_id: 68, appliance_name: "CCTV" },
  ]);
  const [selectedAppliances, setSelectedAppliances] = useState([]);
  const [applianceDetails, setApplianceDetails] = useState([]);
  const [Ismale, setIsMale] = useState(false);
  const [IsFemale, setIsFemale] = useState(false);

  const navigate = useNavigate();

  const [nameError, setNameError] = useState("");
  const [referralDescriptionError, setReferralDescriptionError] = useState("");
  const [referralnameError, setreferralnameError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [referralIdError, setReferralIdError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password1Error, setPassword1Error] = useState("");
  const [confirmpasswordError, setConfirmpasswordError] = useState("");
  const [dobError, setDobError] = useState("");
  const [GenderError, setGenderError] = useState("");
  const [CountryError, setCountryError] = useState("");
  const [StateError, setStateError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [cityError, setCityError] = useState("");
  const [postalError, setPostalError] = useState("");
  const [applianceError, setApplianceError] = useState(false);

  const [showPassword1, setShowPassword1] = useState(false);
  const [IsEmpty, setIsEmpty] = useState(false);
  const [IsReferralEmpty, setIsReferralEmpty] = useState(false);

  const passwordPattern =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
  const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.(in|com)$/;
  const [Image, setImage] = useState(null);
  const [Images, setImages] = useState([]);
  const [selectedImageName, setSelectedImageName] = useState("");
  const [allowAddAddress, setAllowAddAddress] = useState(false);
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [shopImage, setShopImage] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [checkbocError, setCheckbocError] = useState("");
  const [addressStepper, setAddressStepper] = useState(false);
  const [referralStepper, setReferralStepper] = useState(false);
  const [passwordStepper, setPasswordStepper] = useState(false);
  const [basicDetailsStepper, setbasicDetailsStepper] = useState(true);
  const [addressActive, setAddressActive] = useState(false);
  const [referralActive, setReferralActive] = useState(false);
  const [passwordActive, setpasswordActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [refferalNameDisabled, setRefferalNameDisabled] = useState(false);

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setIsChecked(isChecked);
    if (!isChecked) {
      setCheckbocError(true);
    } else {
      setCheckbocError("");
    }
  };
  const onImageChange = (event) => {
    const files = event.target.files;
    const imageNames = [];
    const selectedImages = [];

    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileType = file.type.split("/")[0];

        if (fileType === "image") {
          imageNames.push(file.name);
          selectedImages.push(file);
        }
      }

      setImages(selectedImages);

      setSelectedImageName(imageNames.join(", "));
    }
  };
  // const getMaxDate = () => {
  //   const today = new Date();
  //   const maxYear = today.getFullYear() - 18;
  //   const maxMonth = today.getMonth() + 1;
  //   const maxDay = today.getDate();
  //   return `${maxYear}-${maxMonth < 10 ? "0" + maxMonth : maxMonth}-${maxDay}`;
  // };
  const getMaxDate = () => {
    const today = new Date();
    const eighteenYearsAgo = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    );
    return eighteenYearsAgo.toISOString().split("T")[0]; // Format the date as YYYY-MM-DD
  };
  useEffect(() => {
    // appliance
    // Api.getAppliances().then((res) => {
    //     setAppliancesData(res.data)
    // })

    //country
    getCityDetails(23);
  }, []);

  // const onShopImageChange = (event) => {
  //   const files = Array.from(event.target.files || []);

  //   const imageFiles = files.filter((file) => file.type.startsWith("image"));

  //   const imageNames = imageFiles.map((file) => file.name);

  //   setImages(imageFiles);
  //   setSelectedImageName(imageNames.join(", "));

  //   if (imageFiles.length > 0) {
  //     setShopImage(imageFiles[0]);
  //   }
  // };

  // const onShopImageChange = (event) => {
  //   const files = Array.from(event.target.files || []);
  //   const imageFiles = files.filter((file) => file.type.startsWith("image"));
  //   setImages(imageFiles);
  //   if (imageFiles.length > 0) {
  //     setShopImage(files);
  //   }
  // };

  const onShopImageChange = (event) => {
    const maxSizeBytes = 2 * 1024 * 1024; // 2MB
    const allowedMimetypes = ["image/jpeg", "image/jpg", "image/png"];
    const maxAllowedImages = 2;

    const files = Array.from(event.target.files || []);
    const imageFiles = files.filter((file) =>
      allowedMimetypes.includes(file.type)
    );
    const validImageFiles = imageFiles.filter(
      (file) => file.size <= maxSizeBytes
    );
    if (
      imageFiles.length > maxAllowedImages ||
      validImageFiles.length !== files.length
    ) {
      const message =
        imageFiles.length > maxAllowedImages
          ? `You selected ${imageFiles.length} images, but only ${maxAllowedImages} images (each under 2MB) can be uploaded. Only the first ${maxAllowedImages} images will be considered.`
          : "Some images exceed the 2MB size limit and will be ignored.";

      Swal.fire({
        text: message,
        confirmButtonText: "OK",
      });
    }
    setImages(validImageFiles.slice(0, maxAllowedImages));
    if (validImageFiles.length > 0) {
      setShopImage(validImageFiles);
    }
  };

  const validateForm = () => {
    let isValid = true;

    if (!name) {
      setNameError("Please enter your full name.");
      isValid = false;
    } else {
      setNameError("");
    }

    if (!mobile) {
      setMobileError("Please enter your phone number.");
      isValid = false;
    } else if (mobile.length !== 10) {
      setMobileError("Please enter a valid phone number.");
      isValid = false;
    } else {
      setMobileError("");
    }
    if (!isChecked) {
      setCheckbocError(
        "You must agree to the Terms of Use and Privacy Policy."
      );
      isValid = false;
    } else {
      setCheckbocError("");
    }

    if (address?.length > 255) {
      setAddressError("maximum 255 number of characters allowed");
      isValid = false;
    } else {
      setAddressError("");
    }

    if (!email) {
      setEmailError("Please enter your email address.");
      isValid = false;
    } else if (!emailPattern.test(email)) {
      setEmailError("Please Insert Correct Email Format...");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!password1) {
      setPassword1Error("Please enter your password.");
      isValid = false;
    } else if (!passwordPattern.test(password1)) {
      setPassword1Error(
        "Password must contain at least 8 characters, one digit, and one special character."
      );
      isValid = false;
    } else {
      setPassword1Error("");
    }

    if (!confirmpassword) {
      setConfirmpasswordError("Please confirm your password.");
      isValid = false;
    } else if (confirmpassword !== password1) {
      setConfirmpasswordError("Passwords do not match.");
      isValid = false;
    } else {
      setConfirmpasswordError("");
    }

    if (!dob) {
      setDobError("Please enter your DOB");
      isValid = false;
    } else {
      setDobError("");
    }

    if (!gender) {
      setGenderError("Please choose your Gender");
      isValid = false;
    } else {
      setGenderError("");
    }

    if (!country) {
      setCountryError("Please select Country");
      isValid = false;
    } else {
      setCountryError("");
    }

    if (!state) {
      setStateError("Please select State");
      isValid = false;
    } else {
      setStateError("");
    }

    if (!address) {
      setAddressError("Please enter your Address");
      isValid = false;
    } else {
      setAddressError("");
    }

    if (!city) {
      setCityError("Please select City");
      isValid = false;
    } else {
      setCityError("");
    }

    if (!postalcode) {
      setPostalError("Please enter your Postal Code");
      isValid = false;
    } else {
      setPostalError("");
    }
    return isValid;
  };

  const register = (e) => {
    e.preventDefault();
    if (selectedAppliances?.length === 0) {
      setApplianceError(true);
    } else {
      setApplianceError(false);
    }
    const isValid = validateForm();

    var registerJson = {
      role: 2,
      name: name,
      email: email,
      password: password1,
      cpassword: confirmpassword,
      mob: mobile,
      dob: dob,
      address: address,
      gender: gender,
      country: country,
      state: state,
      city: city,
      postal: postalcode,
      service_lists: selectedAppliances,
    };

    if (isValid) {
      const formData = new FormData();
      formData.append("role", 2);
      formData.append("name", name);
      formData.append("username", email);
      formData.append("password", password1);
      formData.append("confirm_password", confirmpassword);
      formData.append("mobile_no", mobile);
      formData.append("address", address);
      formData.append("dob", dob);
      formData.append("gender", gender);
      formData.append("country", country);
      formData.append("state", state);
      formData.append("city", city);
      formData.append("postalcode", postalcode);
      formData.append("latitude", latitude);
      formData.append("longitude", longitude);
      if (referralDescription) {
        formData.append("additional_info", referralDescription);
      } else if (selectedOption === "Friend/Referral") {
        {
          referralId && formData.append("referrer_id", referralId);
        }
        formData.append("referrer_name", referralName);
      }

      formData.append("skip_refferal", referralSkipped);

      // Append source field based on selectedOption
      let source;
      switch (selectedOption) {
        case "Social Media (Facebook, Twitter, Instagram, etc.)":
          source = "Social Media";
          break;
        case "Search Engine (Google, Bing, etc.)":
          source = "Search Engine";
          break;
        case "Advertisement (Online, Print, TV, Radio)":
          source = "Advertisement";
          break;
        case "Friend/Referral":
          source = "Referral";
          break;
        case "Others":
          source = "Others";
          break;
        default:
          source = null;
          break;
      }
      {
        !referralSkipped && formData.append("source", source);
      }

      // formData.append("files", shopImage);

      // formData.append("images", Images);
      /* applianceDetails
        .filter((model) => selectedAppliances.includes(model.id))
        .map((val) => {
          return {
            appliance_id: val.appliance_id,
            model_id: val.id,
          };
        }); */
      const filteredApplianceDetails = selectedAppliances;
      formData.append("applianceMap", JSON.stringify(filteredApplianceDetails));
      if (shopImage && shopImage.length > 0) {
        for (let i = 0; i < shopImage.length; i++) {
          formData.append("files", shopImage[i]);
        }
      }
      Api.technicianRegister(formData)
        .then((response) => {
          if (response.status === 200) {
            Swal.fire({
              text: response.message,
              confirmButtonText: "OK",
            }).then((result) => {
              navigate("/Login");
            });
          } else if (
            response.message === "Registered Successfully..!" &&
            response.data.length === 0
          ) {
            Swal.fire({
              text: `
                            Congratulations! Welcome to S3!!
                             Your registration is successfully completed. The District Admin will contact you at the earliest possible.`,
              confirmButtonText: "OK",
            }).then(() => {
              navigate("/");
            });
          } else {
            Swal.fire({
              text: response.response?.data.message,
              confirmButtonText: "OK",
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            text: err.message,
            confirmButtonText: "OK",
          });
        });
    }
  };

  //get city details
  const getCityDetails = (state_id) => {
    Api.getCityByStateId(state_id).then((res) => {
      setCityData(res.data);
    });
  };

  const handleSelect = (selectedList, selectedItem) => {
    // let result = selectedList.map((appliance) => ({
    //   appliance_id: appliance.appliance_id,
    //   model_id: appliance.model_id,
    // }));
    // setSelectedAppliances((prevSelectedAppliances) => [
    //   ...prevSelectedAppliances,
    //   ...result,
    // ]);
    setSelectedAppliances(selectedList);
  };

  const handleRemove = (selectedList, removedItem) => {
    const result = selectedList
      ?.map((select) => {
        if (
          select?.appliance_id === removedItem?.appliance_id &&
          select?.model_id === removedItem?.model_id
        )
          return null;
        return select;
      })
      .filter(Boolean);
    setSelectedAppliances(result);
  };

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
    if (new Date(selectedDate) > eighteenYearsAgo) {
      setDobError("DOB must indicate an age above 18 years");
    } else {
      setDobError("");
      setDob(selectedDate);
    }
  };

  useEffect(() => {
    if (dob) {
      const selectedDate = dob;
      const eighteenYearsAgo = new Date();
      eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
      const validDate = selectedDate.split("-");
      console.log("eighteenYearsAgo.getFullYear()", validDate[0], 1900);
      if (new Date(dob) > eighteenYearsAgo) {
        setDobError("DOB must indicate an age above 18 years");
      } else if (validDate[0] < 1920) {
        setDobError("years must be greater than 1920");
      } else {
        setDobError("");
        setDob(selectedDate);
      }
    }
  }, [dob]);

  const getLocation = () => {
    setAllowAddAddress(true);
    navigator.geolocation.getCurrentPosition(showPosition, (error) => {
      if (error.code === error.PERMISSION_DENIED) {
        alert(
          "Geolocation is blocked. Please allow access to add your address."
        );
        setAllowAddAddress(false);
      } else if (error.code === error.POSITION_UNAVAILABLE) {
        alert("Geolocation is not supported by this browser.");
        setAllowAddAddress(false);
      } else {
        setAllowAddAddress(true);
      }
    });
  };

  const showPosition = (position) => {
    setLatitude(position.coords.latitude);
    setLongitude(position.coords.longitude);
  };

  // useEffect(() => {
  //   getLocation();
  // }, []);
  const [applianceList, setApplianceList] = useState([]);

  useEffect(() => {
    Api.ActiveProducts("active").then((res) => {
      if (res) {
        // let applianceList = res?.map((appliance) => {
        //   const { id, appliance_name, model } = appliance;
        //   if (model?.length > 0) {
        //     const result = [];
        //     for (const modelValue of model) {
        //       result.push({
        //         appliance_id: id,
        //         appliance_name,
        //         appliance_model: modelValue.appliance_model,
        //         model_id: modelValue.id,
        //       });
        //     }
        //     return result.flat();
        //   } else
        //     return {
        //       appliance_id: id,
        //       // appliance_name,
        //       appliance_model: appliance_name,
        //       // model_id: undefined,
        //     };
        // });
        let applianceList = res
          .map((appliance) =>
            appliance?.model?.length > 0
              ? appliance.model?.map((model) => ({
                  appliance_id: appliance.id,
                  appliance_name: appliance.appliance_name,
                  appliance_model: model.appliance_model,
                  model_id: model.id,
                }))
              : [
                  {
                    appliance_id: appliance.id,
                    appliance_name: "*Appliances (Without model)",
                    appliance_model: appliance.appliance_name,
                    model_id: null,
                  },
                ]
          )
          .flat();
        applianceList = applianceList.sort((a, b) =>
          a.appliance_name.localeCompare(b.appliance_name)
        );
        // applianceList = models?.map((model) => {
        //   const { id, appliance_model, appliance_id } = model;
        //   const { appliance_name } =
        //     appliances?.find(({ id }) => id === appliance_id) || {};
        //   return {
        //     appliance_id: id,
        //     appliance_name,
        //     appliance_model,
        //     model_id: id,
        //   };
        // });
        console.log("applianceList", applianceList.flat());
        // applianceList = applianceList.sort((a, b) => {
        //   if (a.appliance_name < b.appliance_name) return 1;
        //   if (a.appliance_name > b.appliance_name) return -1;
        //   return 0;
        // });
        console.log("Filtered applianceList", applianceList);

        setApplianceList(applianceList);

        // setApplianceDetails(
        //   models?.map((model) => {
        //     const { appliance_model, appliance_id } = model;
        //     let label = appliance_model;
        //     const { appliance_name } =
        //       appliances?.find(({ id }) => id === appliance_id) || {};

        //     if (appliance_name) {
        //       label = `${appliance_name} - ${appliance_model}`;
        //     }

        //     return { ...model, label };
        //   })
        // );
      }
    });
  }, []);

  const array1 = [
    {
      id: 1,
      appliance: "TV",
    },
    {
      id: 2,
      appliance: "Washing Machine",
    },
    {
      id: 3,
      appliance: "Fridge",
    },
  ];
  const array2 = [
    {
      appliance_id: 1,
      id: 1,
      model: "LCD",
    },
    {
      appliance_id: 1,
      id: 2,
      model: "LED",
    },
    {
      appliance_id: 2,
      id: 3,
      model: "Washing Tub",
    },
    {
      appliance_id: 1,
      id: 4,
      model: "CRT",
    },
  ];

  const [citys, setCitys] = useState([]);
  const [district, setDistrict] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [citysError, setCitysError] = useState("");
  const [invalidPostalCode, setInvalidPostalCode] = useState(false);
  const [isPostalCodeOnChange, setIsPostalCodeOnChange] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [response, setResponse] = useState([]);

  useEffect(() => {
    if (isPostalCodeOnChange === true) {
      setTimeout(() => {
        setIsPostalCodeOnChange(false);
      }, 1000);
      if (postalcode?.length !== 6) setPostalError("Invalid Postal Code");
    }
  }, [isPostalCodeOnChange]);

  useEffect(() => {
    if (postalcode?.length === 6) {
      setPostalError("");
      handleCityData();
    } else {
      setState("");
      setCountry("");
      setCitys("");
      setDistrict("");
      setSelectedCity("");
      setInvalidPostalCode(true);
      // setPostalError('Invalid Postal Code');
    }
  }, [postalcode]);

  const handleCityData = async () => {
    try {
      const response = await Api.getLocationByPincode(postalcode);
      setResponse(response);
      console.log("response", response);
      if (response?.error) {
        setPostalError(response?.error);
        setCitys("");
        setDistrict("");
        setSelectedCity("");
        setState("");
        setCountry("");
        setInvalidPostalCode(true);
        return; // Add return here to exit function early
      }

      let districtName = "",
        city_id = "";
      const cityData = response.map((item) => {
        districtName = item.district_name;
        city_id = item?.city_id;

        return item.location_name;
      });

      setCitys(cityData);
      setDistricts([...new Set(response.map((item) => item.district_name))]);
      setDistrict(districtName);
      setState("Tamil Nadu");
      setCountry("India");
      setCity(city_id);
      setInvalidPostalCode(false);
    } catch (error) {
      setPostalError("Invalid Postal Code");
      console.log("error:", error);
    }
  };

  const handleDistrictChange = (e) => {
    const selectedDistrict = e.target.value;
    setDistrict(selectedDistrict);

    // Find the first city_id associated with the selected district
    const selectedCityId =
      response.find((item) => item.district_name === selectedDistrict)
        ?.city_id || "";

    // Set the city to the found city_id
    setCity(selectedCityId);
  };

  const handleCityChange = (e) => {
    const value = e.target.value;
    setSelectedCity(value);
    setCitysError(value ? "" : "City is required");
  };
  const handleRemoveImage = (index) => {
    const newImages = [...Images];
    newImages.splice(index, 1);
    if (!newImages.length) {
      setImages([]);
    } else {
      setImages(newImages);
    }
  };
  useEffect(() => {
    if (selectedAppliances.length > 0) {
      setApplianceError(false);
    }
    // if (selectedAppliances.length === 0) {
    //   setApplianceError(true);
    // }
  }, [applianceError, selectedAppliances]);

  const addressStepperClick = () => {
    if (name && email && mobile && dob && gender && selectedAppliances) {
      setbasicDetailsStepper(false);
      setPasswordStepper(false);
      setAddressStepper(false);
      setAddressActive(false);
      setReferralStepper(true);
      setReferralActive(true);
    } else {
      setbasicDetailsStepper(true);
      setAddressStepper(false);
      setPasswordStepper(false);
      setReferralStepper(false);
    }
  };

  const passwordStepperClick = () => {
    if (address && country && state && city && postalcode) {
      setAddressStepper(false);
      setPasswordStepper(true);
      setbasicDetailsStepper(false);
      setpasswordActive(true);
      setAddressActive(true);
    } else {
      setbasicDetailsStepper(false);
      setAddressStepper(true);
      setPasswordStepper(false);
      setReferralStepper(false);
    }
  };
  const referralStepperClick = () => {
    if (selectedOption === "") {
      setAddressStepper(false);
      setPasswordStepper(false);
      setbasicDetailsStepper(false);
      setpasswordActive(false);
      setAddressActive(false);
      setReferralStepper(true);
      setReferralActive(true);
    }
    // else if (
    //   selectedOption !== "Others" &&
    //   selectedOption !== "Friend/Referral"
    // ) {
    //   setAddressStepper(true);
    //   setPasswordStepper(false);
    //   setbasicDetailsStepper(false);
    //   setpasswordActive(false);
    //   setAddressActive(true);
    //   setReferralStepper(false);
    //   setReferralActive(true);
    // }
    else {
      // Stay on the referral step if the required fields are not filled
      setAddressStepper(true);
      setPasswordStepper(false);
      setbasicDetailsStepper(false);
      setpasswordActive(false);
      setAddressActive(true);
      setReferralStepper(false);
      setReferralActive(true);
    }
  };

  const isReferralButtonDisabled = () => {
    if (selectedOption === "") {
      return true;
    }

    if (selectedOption === "Friend/Referral") {
      if ((referralId && !referralName) || referralIdError) {
        return true;
      }
      if (!referralName) {
        return true;
      }
      if (referralName) {
        return false;
      } else {
        return false;
      }
    }

    return false;
  };

  const isButtonDisabled = () => {
    if (
      !name ||
      !email ||
      !mobile ||
      !dob ||
      !gender ||
      selectedAppliances.length === 0
    ) {
      return true;
    } else if (emailError || mobileError || dobError) {
      return true;
    } else {
      return false;
    }
  };

  const isAddressButtonDisabled = () => {
    if (address && country && state && city && postalcode && selectedCity) {
      return false;
    } else {
      return true;
    }
  };

  const topAddressStepper = () => {
    if (!referralSkipped) {
      if (selectedOption === "") return;
      if (selectedOption === "Friend/Referral" && referralName === "") return;
    }
    setbasicDetailsStepper(false);
    setAddressStepper(true);
    setReferralStepper(false);
    setPasswordStepper(false);
    setpasswordActive(false);
    setReferralActive(true);
    setAddressActive(true);
  };

  const topReferralStepper = () => {
    if (
      name &&
      !mobileError &&
      !emailError &&
      email &&
      mobile &&
      !dobError &&
      dob &&
      gender &&
      selectedAppliances.length > 0
    ) {
      setbasicDetailsStepper(false);
      setReferralStepper(true);
      setPasswordStepper(false);
      setpasswordActive(false);
      setReferralActive(true);
      setAddressStepper(false);
      setAddressActive(false);
    }
  };

  const toppasswordStepper = () => {
    if (
      name &&
      email &&
      mobile &&
      dob &&
      gender &&
      selectedAppliances.length > 0 &&
      address &&
      country &&
      state &&
      city &&
      postalcode &&
      selectedCity
    ) {
      setbasicDetailsStepper(false);
      setAddressStepper(false);
      setPasswordStepper(true);
      setpasswordActive(true);
      setAddressActive(true);
      setReferralActive(true);
      setReferralStepper(false);
    }
  };

  useEffect(() => {
    setbasicDetailsStepper(true);
    setAddressStepper(false);
    setPasswordStepper(false);
  }, []);

  /*  useEffect(() => {
    if (address.length > 255) {
      setAddressError("maximum 255 number of characters allowed");
    } else {
      setAddressError("");
    }
  }, [address]);
 */
  const handleAddress = (e) => {
    if (e.target.value.length > 255) {
      /*  setAddressError("maximum 255 number of characters allowed"); */
    } else {
      setAddressError("");
      setAddress(e.target.value);
    }
  };
  return (
    <>
      <div className="container-fluid ">
        <div
          className="res-576px-d-unset mt--10px"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            marginTop: "10px",
          }}
        >
          <div
            className="pt-0 res-576px-d-none"
            style={{
              // height: "100vh",
              display: "flex",
              alignItems: "center",
              width: "43.5%",
              padding: "60px",
              justifyContent: "center",
            }}
          >
            <div
              className="logo_container res-576px-d-none"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "20px",
                marginTop: "-162px",
              }}
            >
              <div onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
                <S3NewLogo
                  color={"rgba(235, 129, 40, 1)"}
                  // style={{ scale: "1.2" }}
                />
              </div>
              <img alt="demo" src={Logo} className="logo" />
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                  textAlign: "center",
                  width: "80%",
                }}
              >
                Receive Service Requests and Buy Spare Parts at Low Cost!
              </div>
              <div
                style={{
                  fontSize: "10px",
                  textAlign: "center",
                  width: "84%",
                  color: "rgba(0, 0, 0, 1)",
                }}
                className="mt--10px"
              >
                Sign up to expand your customer base and grow your business.
              </div>
            </div>
          </div>
          <div className="res-576px-d-none col-lg-1 border-register-right-reg"></div>
          <div
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
            className="res-577px-d-none mb-4 "
          >
            <S3NewLogo
              color={"rgba(235, 129, 40, 1)"}
              // style={{ scale: "1.2" }}
            />
          </div>
          <div
            style={{
              height: "800px",
              width: "56.5%",
              display: "flex",
              justifyContent: "center",
            }}
            className="res-576px-w-fill"
          >
            <div
              className="p-lg-5 p-md-3 p-2"
              style={{ width: "-webkit-fill-available" }}
            >
              <div className="text-start">
                <p
                  className="p-0"
                  style={{
                    fontWeight: "700",
                    color: "#1E232C",
                    fontSize: "21px",
                  }}
                >
                  Register with us!
                </p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      color: "#6A707C",
                      display: "flex",
                      gap: "6px",
                    }}
                  >
                    <Link to="/commonRegister">
                      <i
                        className="bi bi-chevron-left me-1"
                        style={{
                          fontWeight: "500",
                          fontSize: "12px",
                          color: "rgba(106, 112, 124, 1)",
                        }}
                      ></i>
                    </Link>
                    <div
                      style={{ display: "flex", gap: "1rem" }}
                      className="res-576px-d-none"
                    >
                      <div
                        style={{
                          color: "rgba(235, 129, 40, 1)",
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setbasicDetailsStepper(true);
                          setAddressStepper(false);
                          setReferralStepper(false);
                          setPasswordStepper(false);
                          setAddressActive(false);
                          setpasswordActive(false);
                          setReferralActive(false);
                        }}
                      >
                        Basic Details
                      </div>
                      <div>--------</div>
                      <div
                        style={{
                          color:
                            name &&
                            email &&
                            mobile &&
                            dob &&
                            gender &&
                            selectedAppliances.length > 0 &&
                            referralActive &&
                            "rgba(235, 129, 40, 1)",
                          fontSize: "14px",
                          cursor:
                            name &&
                            email &&
                            mobile &&
                            dob &&
                            gender &&
                            selectedAppliances.length > 0 &&
                            "pointer",
                        }}
                        onClick={topReferralStepper}
                      >
                        Referral
                      </div>
                      <div>--------</div>

                      <div
                        style={{
                          color:
                            name &&
                            email &&
                            mobile &&
                            dob &&
                            gender &&
                            selectedAppliances.length > 0 &&
                            addressActive &&
                            "rgba(235, 129, 40, 1)",
                          fontSize: "14px",
                          cursor:
                            (referralSkipped ||
                              (selectedOption !== "" &&
                                (selectedOption !== "Friend/Referral" ||
                                  referralName !== ""))) &&
                            "pointer",
                        }}
                        onClick={() => {
                          topAddressStepper();
                        }}
                      >
                        Address
                      </div>
                      <div>--------</div>

                      <div
                        style={{
                          color:
                            name &&
                            email &&
                            mobile &&
                            dob &&
                            gender &&
                            selectedAppliances.length > 0 &&
                            country &&
                            state &&
                            city &&
                            postalcode &&
                            selectedCity &&
                            address &&
                            passwordActive &&
                            "rgba(235, 129, 40, 1)",
                          fontSize: "14px",
                          cursor:
                            name &&
                            email &&
                            mobile &&
                            dob &&
                            gender &&
                            selectedAppliances.length > 0 &&
                            country &&
                            state &&
                            city &&
                            postalcode &&
                            selectedCity &&
                            address &&
                            "pointer",
                        }}
                        onClick={toppasswordStepper}
                      >
                        Password
                      </div>
                    </div>
                    <div
                      style={{ display: "flex", gap: "2px" }}
                      className="res-577px-d-none"
                    >
                      <div
                        style={{
                          color: "rgba(235, 129, 40, 1)",
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setbasicDetailsStepper(true);
                          setAddressStepper(false);
                          setPasswordStepper(false);
                          setAddressActive(false);
                          setpasswordActive(false);
                          setReferralActive(false);
                          setReferralStepper(false);
                        }}
                      >
                        Basic Details
                      </div>
                      <div>-</div>
                      <div
                        style={{
                          color:
                            name &&
                            email &&
                            mobile &&
                            dob &&
                            gender &&
                            selectedAppliances.length > 0 &&
                            referralActive &&
                            "rgba(235, 129, 40, 1)",
                          fontSize: "14px",
                          cursor:
                            name &&
                            email &&
                            mobile &&
                            dob &&
                            gender &&
                            selectedAppliances.length > 0 &&
                            "pointer",
                        }}
                        onClick={topReferralStepper}
                      >
                        Referral
                      </div>
                      <div>-</div>
                      <div
                        style={{
                          color:
                            name &&
                            email &&
                            mobile &&
                            dob &&
                            gender &&
                            selectedAppliances.length > 0 &&
                            addressActive &&
                            "rgba(235, 129, 40, 1)",
                          fontSize: "14px",
                          cursor:
                            name &&
                            email &&
                            mobile &&
                            dob &&
                            gender &&
                            selectedAppliances.length > 0 &&
                            "pointer",
                        }}
                        onClick={() => {
                          topAddressStepper();
                          if (
                            referralSkipped &&
                            selectedOption !== "Others" &&
                            selectedOption !== "Friend/Referral"
                          ) {
                            setAddressActive(true);
                            setAddressStepper(true);
                            setReferralStepper(false);
                            setReferralActive(true);
                          }
                        }}
                      >
                        Address
                      </div>
                      <div>-</div>

                      <div
                        style={{
                          color:
                            name &&
                            email &&
                            mobile &&
                            dob &&
                            gender &&
                            selectedAppliances.length > 0 &&
                            country &&
                            state &&
                            city &&
                            postalcode &&
                            selectedCity &&
                            address &&
                            passwordActive &&
                            "rgba(235, 129, 40, 1)",
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                        onClick={toppasswordStepper}
                      >
                        Password
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <form
                onSubmit={register}
                // style={{ width: "86%" }}
                className="res-576px-w-fill"
              >
                {basicDetailsStepper && (
                  <div>
                    <TextField
                      className="w-100 mt-4 mt-lg-4"
                      style={{ height: "56px" }}
                      id="full-name"
                      value={name}
                      // onChange={(e) => setName(e.target.value)}
                      onChange={(e) => {
                        let inputValue = e.target.value;

                        // Remove extra spaces and allow only alphabets (no numbers or special characters)
                        var onlyText = inputValue
                          .replace(/[^A-Za-z ]/g, "")
                          .replace(/  +/g, " ");

                        // Remove leading space if present
                        if (onlyText.startsWith(" ")) {
                          onlyText = onlyText.slice(1);
                        }

                        // Limit the input length to 20 characters
                        const truncatedText = onlyText.slice(0, 32);
                        setName(truncatedText);
                      }}
                      label={`Full Name (${name.length}/32)`}
                      type="text"
                      placeholder="Enter your full name"
                      // error={!!nameError}
                      // helperText={nameError}
                      FormHelperTextProps={{
                        style: {
                          position: "absolute",
                          bottom: "-20px",
                          whiteSpace: "nowrap",
                        },
                      }}
                      // onKeyUp={() => {
                      //   if (!name) {
                      //     setNameError("Please enter your full name.");
                      //     setIsEmpty(false);
                      //   } else {
                      //     setNameError("");
                      //     setIsEmpty(true);
                      //   }
                      // }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      className="w-100 mt-4 mt-lg-4"
                      style={{ height: "56px" }}
                      id="mobile-number"
                      value={mobile}
                      // onChange={(e) => setMobile(e.target.value)}
                      label="Enter your phone number"
                      type="number"
                      placeholder="e.g., 1234567890"
                      error={!!mobileError}
                      helperText={mobileError}
                      FormHelperTextProps={{
                        style: {
                          position: "absolute",
                          bottom: "-20px",
                          whiteSpace: "nowrap",
                        },
                      }}
                      onKeyUp={() => {
                        if (!mobile) {
                          setMobileError("Please enter your phone number.");
                          setIsEmpty(false);
                        } else if (mobile.length !== 10) {
                          setMobileError("please enter valid phone number");
                          setIsEmpty(false);
                        } else {
                          setMobileError("");
                          setIsEmpty(true);
                        }
                      }}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Remove any non-digit characters
                        const numericValue = value.replace(/\D/g, "");
                        // Enforce length of 6
                        const formattedValue = numericValue.slice(0, 10);
                        setMobile(formattedValue);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      className="w-100 mt-4 mt-lg-4"
                      style={{ height: "56px" }}
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      label="Enter your email"
                      type="email"
                      placeholder="Enter your email address"
                      error={!!emailError}
                      helperText={emailError}
                      FormHelperTextProps={{
                        style: {
                          position: "absolute",
                          bottom: "-20px",
                          whiteSpace: "nowrap",
                        },
                      }}
                      onKeyUp={() => {
                        const trimmedEmail = email.trim();
                        setEmail(trimmedEmail);

                        if (!trimmedEmail) {
                          setEmailError("Please enter your email address.");
                        } else if (!emailPattern.test(trimmedEmail)) {
                          setEmailError("Please enter a valid email address.");
                        } else {
                          setEmailError("");
                        }
                      }}
                      inputProps={{
                        autoComplete: "off",
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {/* dob and gender */}
                    <div className="row mt-4">
                      <div className="col-6">
                        <TextField
                          className="w-100"
                          id="datePicker"
                          label="Date Of Birth"
                          type="date"
                          error={!!dobError}
                          helperText={dobError}
                          onChange={handleDateChange}
                          value={dob}
                          inputProps={{
                            max: getMaxDate(),
                            // Set the max date for the input
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end"></InputAdornment>
                            ),
                          }}
                          style={{ height: "47px" }}
                        />
                      </div>
                      <div className="col-6">
                        <div
                          className="row m-0 p-0 border border rounded-3"
                          style={{ height: "54px" }}
                        >
                          <div
                            className="col-6 border-right p-1"
                            style={{
                              borderRight: "1px solid grey ",
                              height: "52px",
                              color: Ismale ? "rgb(235, 129, 40)" : "black",
                            }}
                          >
                            <label
                              htmlFor="maleRadio"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                justifyContent: "center",
                              }}
                            >
                              <IconButton color="primary" component="span">
                                <img
                                  alt="demo"
                                  src={Ismale ? maleclor : male}
                                />
                              </IconButton>
                              <input
                                id="maleRadio"
                                checked={Ismale}
                                onChange={() => {
                                  setGender("Male");
                                  setGenderError("");
                                  setIsMale(true);
                                  setIsFemale(false);
                                }}
                                type="radio"
                                style={{ display: "none" }}
                              />
                              <span className="">Male</span>
                            </label>
                          </div>
                          <div
                            className="col-6 border-right p-1"
                            style={{
                              borderRight: "1px solid grey ",
                              height: "52px",
                              color: IsFemale ? "rgb(235, 129, 40)" : "black",
                            }}
                          >
                            <label
                              htmlFor="femaleRadio"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                justifyContent: "center",
                              }}
                            >
                              <IconButton color="primary" component="span">
                                <img
                                  alt="demo"
                                  src={IsFemale ? femaleclor : female}
                                />
                              </IconButton>
                              <input
                                id="femaleRadio"
                                checked={IsFemale}
                                onChange={() => {
                                  setGender("Female");
                                  setGenderError("");
                                  setIsFemale(true);
                                  setIsMale(false);
                                }}
                                type="radio"
                                style={{ display: "none" }}
                              />
                              <span className="">Female</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <Multiselect
                      label="Select the appliances you can fix"
                      placeholder="Select the appliances you can fix"
                      displayValue="appliance_model"
                      groupBy="appliance_name"
                      onKeyPressFn={function noRefCheck() {}}
                      onSearch={function noRefCheck() {}}
                      options={applianceList}
                      selectedValues={selectedAppliances}
                      showCheckbox
                      onSelect={handleSelect}
                      onRemove={handleRemove}
                      className="multiselect-option"
                    />
                    <div
                      className="col-6 w-100 mt-4 mt-lg-4"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <label
                        className="w-100"
                        htmlFor="file-input"
                        style={{
                          // width: 'auto',
                          // minWidth: '300px',
                          textAlign: "center",
                          minHeight: "10px",
                          padding: "13px",
                          border: "2px solid #ddd",
                          borderRadius: "10px",
                          backgroundColor: "#f9f9f9",
                          cursor: "pointer",
                          display: "inline-block",
                          marginRight: "5px",
                        }}
                      >
                        {/* <span className="d-md-none">Upload</span>
                  <span className="d-none d-md-inline">Upload Shop Image</span> */}
                        Upload Shop Images
                      </label>
                      <p
                        style={{
                          margin: "5px 0",
                          fontSize: "8px",
                          color: "#666",
                          textAlign: "start",
                          width: "-webkit-fill-available",
                        }}
                      >
                        Up to 2 images, max 2MP, JPG/JPEG/PNG only
                      </p>
                      {/* Hidden file input */}
                      <input
                        type="file"
                        id="file-input"
                        accept="image/*"
                        onChange={onShopImageChange}
                        style={{ display: "none" }}
                        multiple
                        disabled={Images.length >= 2 ? true : false}
                      />

                      {/* Display selected image */}
                      {Images.length > 0 && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "5px",
                            marginTop: "5px",
                          }}
                        >
                          {Images.map((image, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                position: "relative",
                              }}
                            >
                              <img
                                src={URL.createObjectURL(image)}
                                alt={`Selected ${index + 1}`}
                                style={{
                                  maxWidth: "55px",
                                  height: "55px",
                                  marginRight: "5px",
                                  objectFit: "cover",
                                }}
                              />
                              <button
                                onClick={() => handleRemoveImage(index)}
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  background: "red",
                                  border: "none",
                                  color: "white",
                                  cursor: "pointer",
                                  padding: "2px",
                                  paddingBottom: 0,
                                  paddingTop: 0,
                                  borderRadius: "50%",
                                }}
                              >
                                <ClearIcon />
                              </button>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <Button
                      className="w-100 mt-3 mt-lg-3 rounded-3"
                      // type="submit"
                      style={{
                        height: "56px",
                        backgroundColor: isButtonDisabled()
                          ? "rgb(250 155 9 / 37%)"
                          : "rgba(235, 129, 40, 1)",
                        color: "white",
                        fontWeight: "600",
                        fontSize: "15px",
                        textTransform: "capitalize",
                      }}
                      variant="contained"
                      onClick={addressStepperClick}
                      disabled={isButtonDisabled()}
                    >
                      Continue
                    </Button>
                  </div>
                )}

                {/* //////////////////////////////////////////////////////////////// */}
                {addressStepper && (
                  <div>
                    <TextField
                      className="w-100 mt-4 mt-lg-4"
                      label={`Address (${address.length}/255)`}
                      // style={{ height: '56px' }}
                      value={address}
                      onChange={handleAddress}
                      placeholder="Enter your address"
                      /*    label="Address" */
                      type="text"
                      multiline
                      error={!!addressError}
                      helperText={addressError}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <div className="row mt-4 mt-lg-4">
                      <div className="col">
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100 "
                          id="outlined-helperText"
                          label="Postal Code"
                          value={postalcode}
                          error={!!postalError}
                          helperText={postalError}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Remove any non-digit characters
                            const numericValue = value.replace(/\D/g, "");
                            // Enforce length of 6
                            const formattedValue = numericValue.slice(0, 6);
                            setPostalcode(formattedValue);
                            // setPostalError('');
                            setIsPostalCodeOnChange(true);
                          }}
                        />
                      </div>
                      <div className="col">
                        <TextField
                          fullWidth
                          label="City/Town/Village"
                          select
                          value={selectedCity}
                          // onChange={(e) => setSelectedCity(e.target.value)}
                          onChange={handleCityChange}
                          error={!!citysError}
                          helperText={citysError}
                          disabled={
                            postalcode.length !== 6 || invalidPostalCode
                          }
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                        >
                          {citys?.length > 0 ? (
                            citys.map((city, index) => (
                              <MenuItem key={city} value={city}>
                                {city}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem value=""></MenuItem>
                          )}
                        </TextField>
                      </div>
                      <div className="col">
                        <TextField
                          className="w-100"
                          label="District"
                          select
                          value={district}
                          onChange={handleDistrictChange}
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                        >
                          {districts.map((district, index) => (
                            <MenuItem key={index} value={district}>
                              {district}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                    {/* city and postal */}
                    <div className="row mt-4 mt-lg-4">
                      <div className="col">
                        <TextField
                          className="w-100"
                          label="State"
                          value={state}
                          disabled
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      <div className="col">
                        <TextField
                          className="w-100"
                          label="Country"
                          value={country}
                          aria-readonly={true}
                          disabled
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                    </div>
                    <Button
                      className="w-100 mt-4 mt-lg-4 rounded-3"
                      // type="submit"
                      style={{
                        height: "56px",
                        backgroundColor: isAddressButtonDisabled()
                          ? " rgb(250 155 9 / 37%)"
                          : "rgba(235, 129, 40, 1)",
                        color: "white",
                        fontWeight: "600",
                        fontSize: "15px",
                        textTransform: "capitalize",
                      }}
                      variant="contained"
                      onClick={passwordStepperClick}
                      disabled={isAddressButtonDisabled()}
                    >
                      Continue
                    </Button>
                  </div>
                )}

                {/* //////////////////////////////////////////////////////////////// */}
                {referralStepper && (
                  <div>
                    <div style={{ fontWeight: 600, paddingTop: "20px" }}>
                      How did you hear about S3-App?
                    </div>

                    <div
                      style={{
                        color: "rgba(131, 145, 161, 1)",
                        marginTop: "10px",
                      }}
                    >
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                          value={selectedOption}
                          onChange={(e) => {
                            // setpasswordActive(false);
                            setReferralSkipped(false);
                            setSelectedOption(e.target.value);
                          }}
                        >
                          <FormControlLabel
                            value="Social Media (Facebook, Twitter, Instagram, etc.)"
                            control={<Radio />}
                            label="Social Media (Facebook, Twitter, Instagram, etc.)"
                          />
                          <FormControlLabel
                            value="Search Engine (Google, Bing, etc.)"
                            control={<Radio />}
                            label="Search Engine (Google, Bing, etc.)"
                          />
                          <FormControlLabel
                            value="Advertisement (Online, Print, TV, Radio)"
                            control={<Radio />}
                            label="Advertisement (Online, Print, TV, Radio)"
                          />
                          <FormControlLabel
                            value="Friend/Referral"
                            control={<Radio />}
                            label="Friend/Referral"
                          />
                          <FormControlLabel
                            value="Others"
                            control={<Radio />}
                            label="Others"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    {selectedOption === "Friend/Referral" && (
                      <>
                        <div style={{ fontWeight: 600, paddingTop: "20px" }}>
                          Can you please provide the ID of the person who
                          referred you?
                        </div>
                        <div className="col-12" style={{ paddingTop: "20px" }}>
                          <TextField
                            className="w-100"
                            style={{ height: "56px" }}
                            id="mobile-number"
                            value={referralId}
                            label="Referrer ID"
                            placeholder="Enter the Customer/ Technician ID"
                            type="number"
                            // error={!!referralIdError}
                            // helperText={referralIdError}
                            FormHelperTextProps={{
                              style: {
                                position: "absolute",
                                bottom: "-20px",
                                whiteSpace: "nowrap",
                              },
                            }}
                            // onKeyUp={() => {
                            //   if (!referralId) {
                            //     setReferralIdError(
                            //       "Please enter the Referral ID ."
                            //     );
                            //     setIsEmpty(false);
                            //   }
                            //   else if (referralId.length !== 10) {
                            //     setReferralIdError(
                            //       "Please enter a valid phone number."
                            //     );
                            //     setIsEmpty(false);
                            //   }
                            //   else {
                            //     setReferralIdError("");
                            //     setIsEmpty(true);
                            //   }
                            // }}
                            onChange={(e) => {
                              const value = e.target.value;
                              // Remove any non-digit characters
                              const numericValue = value.replace(/\D/g, "");
                              // Enforce length of 10 digits
                              const formattedValue = numericValue.slice(0, 32);
                              setReferralId(formattedValue);
                              Api.getReferrerName(formattedValue).then(
                                (res) => {
                                  if (res) {
                                    setreferralName(res?.fullname);
                                    if (res?.fullname) {
                                      setRefferalNameDisabled(true);
                                    } else {
                                      setRefferalNameDisabled(false);
                                      setreferralName("");
                                    }
                                  }
                                }
                              );
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <TextField
                            disabled
                            // disabled={refferalNameDisabled}
                            className="w-100 mt-4 mt-lg-4"
                            style={{ height: "56px" }}
                            id="full-name"
                            value={referralName}
                            onChange={(e) => {
                              let inputValue = e.target.value;

                              // Remove extra spaces and allow only alphabets (no numbers or special characters)
                              var onlyText = inputValue
                                .replace(/[^A-Za-z ]/g, "")
                                .replace(/  +/g, " ");

                              // Remove leading space if present
                              if (onlyText.startsWith(" ")) {
                                onlyText = onlyText.slice(1);
                              }

                              // Limit the input length to 32 characters
                              const truncatedText = onlyText.slice(0, 32);
                              setreferralName(truncatedText);
                            }}
                            label={`Referrer Name (${referralName?.length}/32)`}
                            type="text"
                            placeholder="Enter full name"
                            error={!!referralnameError}
                            helperText={referralnameError}
                            FormHelperTextProps={{
                              style: {
                                position: "absolute",
                                bottom: "-20px",
                                whiteSpace: "nowrap",
                              },
                            }}
                            onKeyUp={() => {
                              if (!referralName) {
                                setreferralnameError(
                                  "Please enter the full name."
                                );
                                setIsEmpty(false);
                              } else {
                                setreferralnameError("");
                                setIsEmpty(true);
                              }
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </div>
                      </>
                    )}

                    {selectedOption === "Others" && (
                      <>
                        <div
                          style={{ fontWeight: 600, paddingTop: "20px" }}
                        ></div>
                        <div className="col-12" style={{ paddingTop: "20px" }}>
                          <TextField
                            multiline
                            rows={3}
                            className="w-100"
                            id="referred-by"
                            value={referralDescription}
                            onChange={(e) => {
                              let inputValue = e.target.value;

                              var onlyText = inputValue
                                .replace(/[^A-Za-z ]/g, "")
                                .replace(/  +/g, " ");

                              if (onlyText.startsWith(" ")) {
                                onlyText = onlyText.slice(1);
                              }

                              const truncatedText = onlyText.slice(0, 300);
                              setReferralDescription(truncatedText);
                            }}
                            label={`Description (${referralDescription?.length}/300)`}
                            type="text"
                            placeholder="Enter max 300 words.."
                            error={!!referralDescriptionError}
                            helperText={referralDescriptionError}
                            FormHelperTextProps={{
                              style: {
                                position: "absolute",
                                bottom: "-20px",
                                whiteSpace: "nowrap",
                              },
                            }}
                            style={{
                              paddingBottom: "4px",
                            }}
                            // onKeyUp={() => {
                            //   if (!referralDescription) {
                            //     setReferralDescriptionError(
                            //       "Please enter the description."
                            //     );
                            //     setIsEmpty(false);
                            //   } else {
                            //     setReferralDescriptionError("");
                            //     setIsEmpty(true);
                            //   }
                            // }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </div>
                      </>
                    )}
                    <div
                      style={{
                        color: "rgb(131, 145, 161)",
                        display: "flex",
                        justifyContent: "flex-end",
                        height: "10px",
                        fontSize: "14px",
                      }}
                      onClick={() => {
                        setAddressActive(true);
                        setAddressStepper(true);
                        setReferralSkipped(true);
                        setReferralActive(true);
                        setReferralStepper(false);
                        setbasicDetailsStepper(false);
                        setPasswordStepper(false);
                        setSelectedOption("");
                      }}
                    >
                      <div
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        Skip
                      </div>
                    </div>
                    <Button
                      className="w-100 mt-4 mt-lg-4 rounded-3"
                      type="submit"
                      style={{
                        height: "56px",
                        backgroundColor: isReferralButtonDisabled()
                          ? "rgb(250 155 9 / 37%)"
                          : "rgba(235, 129, 40, 1)",
                        color: "white",
                        fontWeight: "600",
                        fontSize: "15px",
                        textTransform: "capitalize",
                      }}
                      variant="contained"
                      onClick={referralStepperClick}
                      disabled={isReferralButtonDisabled()}
                    >
                      Continue
                    </Button>
                  </div>
                )}

                {/* //////////////////////////////////////////////////////////////// */}
                {passwordStepper && (
                  <div>
                    <TextField
                      autoComplete="off"
                      className="w-100 mt-4 mt-lg-4"
                      style={{ height: "56px" }}
                      id="password"
                      value={password1}
                      onChange={(e) => setPassword1(e.target.value)}
                      label="Enter your password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter your password"
                      error={!!password1Error}
                      helperText={password1Error}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      FormHelperTextProps={{
                        style: {
                          position: "absolute",
                          bottom: "-20px",
                          whiteSpace: "nowrap",
                        },
                      }}
                      onKeyUp={() => {
                        if (!password1) {
                          setPassword1Error("Please enter your password.");
                          setIsEmpty(false);
                        } else if (!passwordPattern.test(password1)) {
                          setPassword1Error(
                            "Password must contain at least 8 characters, one digit, one uppercase, and one special character"
                          );
                          setIsEmpty(false);
                        } else {
                          setPassword1Error("");
                          setIsEmpty(true);
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                              tabIndex={-1}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <div className="col-6 w-100 mt-4 mt-lg-4">
                      <TextField
                        className="w-100"
                        style={{ height: "56px", marginRight: "40px" }}
                        id="confirm-password"
                        value={confirmpassword}
                        onChange={(e) => {
                          setConfirmpassword(e.target.value);
                          setConfirmpasswordError("");
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Confirm your password"
                        type={showPassword1 ? "text" : "password"}
                        placeholder="Confirm your password"
                        error={!!confirmpasswordError}
                        helperText={confirmpasswordError}
                        FormHelperTextProps={{
                          style: {
                            position: "absolute",
                            bottom: "-20px",
                            whiteSpace: "nowrap",
                          },
                        }}
                        onKeyUp={() => {
                          if (!confirmpassword) {
                            setConfirmpasswordError(
                              "Please confirm your password."
                            );
                            setIsEmpty(false);
                          } else if (confirmpassword !== password1) {
                            setConfirmpasswordError("Passwords do not match.");
                            setIsEmpty(false);
                          } else if (!passwordPattern.test(confirmpassword)) {
                            setPassword1Error(
                              "Password must contain at least 8 characters, one digit, and one special character."
                            );
                            setIsEmpty(false);
                          } else {
                            setConfirmpasswordError("");
                            setIsEmpty(true);
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword1(!showPassword1)}
                                edge="end"
                                tabIndex={-1}
                              >
                                {showPassword1 ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div
                      style={{
                        marginTop: "20px",
                      }}
                    >
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                        className="res-576px-w-90per "
                      >
                        <input
                          type="checkbox"
                          id="terms-checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          style={{
                            accentColor: "rgba(235, 129, 40, 1)",
                            position: "relative",
                            cursor: "pointer",
                          }}
                        />
                        <label
                          htmlFor="terms-checkbox"
                          style={{
                            marginLeft: "10px",
                            fontSize: "12px",
                            color: "gray",
                          }}
                        >
                          By signing up, I confirm that I have read and agree to
                          the{" "}
                          <a
                            href="/terms-of-use"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "blue",
                              textDecoration: "underline",
                            }}
                          >
                            Terms of Use
                          </a>{" "}
                          and{" "}
                          <a
                            href="/privacy-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "blue",
                              textDecoration: "underline",
                            }}
                          >
                            Privacy Policy
                          </a>
                          , and I declare that I am 18 years of age or older.
                        </label>
                      </div>
                    </div>
                    {checkbocError && (
                      <div
                        style={{
                          fontSize: "10px",
                          color: "#d32f2f",
                          height: 0,
                        }}
                      >
                        {checkbocError}
                      </div>
                    )}
                    <Button
                      className="w-100 mt-4 mt-lg-4 rounded-3"
                      type="submit"
                      style={{
                        height: "56px",
                        backgroundColor:
                          !checkbocError &&
                          password1 &&
                          confirmpassword &&
                          !confirmpasswordError &&
                          isChecked
                            ? "rgba(235, 129, 40, 1)"
                            : "rgb(250 155 9 / 37%)",
                        color: "white",
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                      variant="contained"
                      // disabled={isDisabled}
                    >
                      Register
                    </Button>
                  </div>
                )}

                {/* 
                            <TextField
                                className="w-100 mt-lg-4 mt-2"
                                select
                                label="Select Appliances"
                                error={!!applianceError}
                                helperText={applianceError}
                                value={appliance}
                                onChange={(e) => { setAppliance(e.target.value); setApplianceError('') }}
                            >
                                {appliancesData.map((item) =>
                                    <MenuItem value={item.appliance_id}>{item.appliance_name}</MenuItem>
                                )}
                            </TextField> */}

                {/* <TextField
                                className="w-100 mt-4 mt-lg-4"
                                style={{ height: '56px' }}
                                id="Take photo of your shop"
                                label="Take photo of your shop"
                                type={'text'}
                                value={selectedImageName}
                                placeholder="Take photo of your shop"
                                FormHelperTextProps={{
                                    style: {
                                        position: 'absolute',
                                        bottom: '-20px',
                                        whiteSpace: 'nowrap',
                                    },
                                }}
                                InputLabelProps={{
                                    shrink: true, // Ensure the label floats instead of collapsing
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton component="label" edge="end">
                                                <CameraAltOutlinedIcon className="rounded-circle" style={{ color: 'rgb(235, 129, 40)', fontSize: '22px' }} />
                                                <input type="file" multiple accept="image/*,video/*" style={{ display: 'none' }} onChange={onImageChange} disabled/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                disabled
                            /> */}

                {/* <div className="text-center">
                <div className="line"></div>
                <p
                  className="m-0 mt-2 mt-lg-3"
                  style={{
                    color: "#6A707C",
                    fontWeight: "400",
                    fontSize: "14px",
                    display: "inline-block",
                  }}
                >
                  Or Register with
                </p>
                <div className="line"></div> */}
                {/* </div>
              <div className="text-center mt-2 mt-lg-3">
                <ul className="m-0 p-0" style={{ display: "inline-flex" }}>
                  <li style={{ listStyle: "none" }}>
                    <div
                      className="border border rounded p-3"
                      style={{
                        width: "120px",
                        height: "56px",
                        opacity: 0.5,
                        cursor: "not-allowed",
                      }}
                    >
                      <img alt="demo" src={facebook} />
                    </div>
                  </li>
                  <li className="ms-lg-5 ms-5" style={{ listStyle: "none" }}>
                    <div
                      className="border border rounded p-3"
                      style={{
                        width: "120px",
                        height: "56px",
                        opacity: 0.5,
                        cursor: "not-allowed",
                      }}
                    >
                      <img alt="demo" src={google} />
                    </div>
                  </li>
                </ul>
              </div> */}
                <div className="text-center">
                  {/* <p className="m-0 mt-2 mt-lg-3" style={{ color: '#1E232C', fontWeight: '500', fontSize: '15px' }} >
                                    Already have an account?<span style={{ color: '#EB8128', fontWeight: '500', fontSize: '15px' }} > Login Now</span></p> */}
                  <p className="m-0 mt-2 mt-lg-3">
                    Already have an account?
                    <Link
                      to="/login"
                      style={{
                        color: "#EB8128",
                        fontWeight: "500",
                        fontSize: "15px",
                        textDecoration: "none",
                      }}
                    >
                      {" "}
                      Login Now
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "10px" }}>
        <Footer topscroller />
      </div>
    </>
  );
};
export default TechnicianRegister;
