// import React, { useEffect, useState } from "react";
// import Button from "@mui/material/Button";
// import { Paper } from "@mui/material";
// import Television from "../Images/television.png";
// import Fridge from "../Images/refrigerator.png";
// import Wash from "../Images/wash.png";
// import Ac from "../Images/cooler.png";
// import Logo from "../Images/newLogo.png";
// import Mobile from "../Images/mobile.png";
// import Play from "../Images/playstore.png";
// import App from "../Images/appstore.png";
// import TechLogo from "../Images/techlogo.png";
// import SRcard from "../Images/srcard.png";
// import Header from "./Header";
// import Suit from "../../src/Images/suitcase.png";
// import Book from "../../src/Images/book.png";
// import Crown from "../../src/Images/crown.png";
// import Footer from "./Footer";
// import "../landingpage.css";
// import Logoin from "../Images/s3Logo.png";
// import { Link, useNavigate } from "react-router-dom";
// import ApplianceCarousel from "./ApplianceCarousel";

// const LandingPage = () => {
//   const [selectedImage, setSelectedImage] = useState(null);
//   const navigate = useNavigate();

//   const handleImageClick = (image) => {
//     setSelectedImage(image);
//   };
//   useEffect(() => {
//     const user_token = localStorage.getItem("token");
//     const user_role = localStorage.getItem("role");
//     if (user_token) {
//       if (user_role === "Technician") {
//         navigate("/technicianDashboard");
//       } else if (user_role === "SuperAdmin") {
//         navigate("/super-admin-dashboard");
//       } else if (user_role === "ZonalAdmin") {
//         navigate("/zonal-admin-dashboard");
//       } else if (user_role === "DistrictAdmin") {
//         navigate("/district-admin-dashboard");
//       } else {
//         navigate("/customerDashboard");
//       }
//     }
//   }, []);
//   return (
//     <>
//       <div>
//         <div className="sticky-top ">
//           <Header />
//         </div>
//         <div className="container-fluid pt-0 p-2">
//           <div className="bg-white">
//             <div>
//               {/* <span className="badge badge-primary topbadge rounded-5" style={{ background: '#FFE5CC', width: '216px', height: '46px', color: '#EB8128', fontSize: '16px', fontWeight: '500', lineHeight: '35px' }}>100% Secure</span> */}
//               <ApplianceCarousel>
//                 {/* <Button
//                   onClick={() => navigate("/login")}
//                   className="mt-lg-4 mt-2 rounded-3 request-btn"
//                   // data-bs-toggle="modal"
//                   data-bs-target="#staticBackdrop"
//                   variant="contained"
//                   style={{
//                     height: "40px",
//                     width: "210px",
//                     backgroundColor: "#EB8128",
//                     fontSize: "14px",
//                     fontWeight: "500",
//                     textTransform: "none",
//                   }}
//                 >
//                   Raise Request
//                 </Button> */}
//               </ApplianceCarousel>
//             </div>
//           </div>
//           <div className="row mt-lg-5">
//             <div className="col text-center">
//               <p
//                 className="p-0 m-0 mt-2 applience_title"
//                 style={{
//                   fontSize: "40px",
//                   fontWeight: "700px",
//                   color: "#2E2E2E",
//                 }}
//               >
//                 Problem in Electronics Appliances?
//                 <br />
//                 We got you!
//               </p>
//             </div>
//           </div>
//           <div
//             className="row mt-lg-5 mt-3"
//             style={{ justifyContent: "center" }}
//           >
//             <div className="col-lg-3 col-sm-6 col-12">
//               <div
//                 style={{
//                   display: "grid",
//                   justifyContent: "center",
//                 }}
//               >
//                 <img
//                   alt="demo"
//                   src={Television}
//                   style={{
//                     height: "200px",
//                     cursor: "pointer",
//                   }}
//                   // onClick={() => handleImageClick("tv")}
//                   onClick={() => navigate("/login")}
//                 />
//                 <p
//                   className=" text-center mt-4"
//                   style={{
//                     color: selectedImage === "tv" ? "rgb(235, 129, 40)" : "black",
//                     cursor: "pointer",
//                   }}
//                   onClick={() => navigate("/login")}
//                 >
//                   Television
//                 </p>
//               </div>
//             </div>
//             {/* <div className="col-lg-3 col-sm-6 col-12 ">
//               <div
//                 style={{
//                   display: "grid",
//                   justifyContent: "center",
//                 }}
//               >
//                 <img
//                   alt="demo"
//                   src={Fridge}
//                   style={{
//                     height: "200px",
//                     cursor: "pointer",
//                   }}
//                   onClick={() => handleImageClick("fridge")}
//                 />
//                 <p
//                   className="text-center mt-4"
//                   style={{
//                     color: selectedImage === "fridge" ? "rgb(235, 129, 40)" : "black",
//                   }}
//                 >
//                   Refrigerator
//                 </p>
//               </div>
//             </div>
//             <div className="col-lg-3 col-sm-6 col-12">
//               <div
//                 style={{
//                   display: "grid",
//                   justifyContent: "center",
//                 }}
//               >
//                 <img
//                   alt="demo"
//                   className="w-70"
//                   src={Wash}
//                   style={{
//                     height: "200px",
//                     cursor: "pointer",
//                   }}
//                   onClick={() => handleImageClick("washing")}
//                 />
//                 <p
//                   className="text-center mt-4"
//                   style={{
//                     color: selectedImage === "washing" ? "rgb(235, 129, 40)" : "black",
//                   }}
//                 >
//                   Washing Machine
//                 </p>
//               </div>
//             </div>
//             <div className="col-lg-3 col-sm-6 col-12">
//               <div
//                 style={{
//                   display: "grid",
//                   justifyContent: "center",
//                 }}
//               >
//                 <img
//                   alt="demo"
//                   className="w-70"
//                   src={Ac}
//                   style={{
//                     height: "200px",
//                     cursor: "pointer",
//                   }}
//                   onClick={() => handleImageClick("ac")}
//                 />
//                 <p
//                   className="text-center mt-4"
//                   style={{ color: selectedImage === "ac" ? "rgb(235, 129, 40)" : "black" }}
//                 >
//                   Air Conditioner
//                 </p>
//               </div>
//             </div> */}
//           </div>
//           <div className="text-center mt-lg-5 mt-3">
//             <Button
//               onClick={() => navigate("/login")}
//               className="mt-lg-4 mt-2 rounded-3 explore_appliances_btn"
//               // data-bs-toggle="modal"
//               data-bs-target="#staticBackdrop"
//               variant="contained"
//               style={{
//                 height: "70px",
//                 width: "410px",
//                 backgroundColor: "#EB8128",
//                 fontSize: "18px",
//                 fontWeight: "500",
//                 textTransform: "none",
//               }}
//             >
//               Create a New SR{" "}
//             </Button>
//           </div>
//         </div>
//         <div className="container-fluid repair_bg">
//           <div className="row look ">
//             <div className="col text-center">
//               {/* <p className="p-0 m-0 mt-2 look" style={{ fontSize: '18px', fontWeight: '500', color: '#5493FB' }}>
//                 How do we repair?         </p> */}
//               <p
//                 className="m-0 mt-2 electronic_text res-font-high"
//                 style={{
//                   fontSize: "40px",
//                   fontWeight: "700px",
//                   color: "#2E2E2E",
//                   paddingTop: 20,
//                 }}
//               >
//                 Get Your Electronic Appliances
//                 <br /> Repaired Just In 3 Step!
//               </p>
//             </div>
//           </div>

//           <div className="row srcard">
//             <div className="col-lg-4 col-md-6 col-12 mt-3 d-flex justify-content-center">
//               <Paper
//                 elevation={2}
//                 style={{
//                   width: "330px",
//                   height: "320px",
//                   borderRadius: "13px",
//                 }}
//               >
//                 <div style={{ textAlign: "center" }}>
//                   <img alt="demo" className="mt-5" src={Suit}></img>
//                   <p className="cardtext mt-3">Register With Us</p>
//                   <text className="mt-0" style={{ fontSize: "12px" }}>
//                     Unlock the Future of Appliance Care:
//                     <br />
//                     Join Us Today and Elevate Your Electronics Repairing
//                     Experience!
//                   </text>
//                 </div>
//               </Paper>
//             </div>
//             <div className="col-lg-4 p-2 col-md-6 col-12 bluebck mt-3 d-flex justify-content-center">
//               <Paper
//                 elevation={3}
//                 style={{
//                   width: "330px",
//                   height: "320px",
//                   borderRadius: "13px",
//                 }}
//               >
//                 <div style={{ textAlign: "center" }}>
//                   <img alt="demo" className="mt-5" src={Book}></img>
//                   <p className="cardtext mt-3 ">Book Your Complaint</p>
//                   <text className="mt-0" style={{ fontSize: "12px" }}>
//                     Seamlessly create your service requests through our
//                     innovative mobile application.
//                   </text>
//                 </div>
//               </Paper>
//             </div>
//             <div className="col-lg-4 col-12  mt-3 d-flex justify-content-center">
//               <Paper
//                 elevation={3}
//                 style={{
//                   width: "330px",
//                   height: "320px",
//                   borderRadius: "13px",
//                 }}
//               >
//                 <div style={{ textAlign: "center" }}>
//                   <img alt="demo" className="mt-5" src={Crown}></img>
//                   <p className="cardtext mt-3">Sit Back and Relax!</p>
//                   <text className="mt-0" style={{ fontSize: "12px" }}>
//                     With our team of expert Technicians and exceptional mobile
//                     application, sit back and relax while we restore your
//                     appliances to perfection.
//                   </text>
//                 </div>
//               </Paper>
//             </div>
//           </div>
//         </div>
//         <div className="row p-lg-5 p-2">
//           <div className="col-lg-6 col-12 remove-img-res">
//             <img alt="demo" className="w-100" src={Logo} />
//           </div>
//           <div className="col-lg-6 col-12 explore-container-res">
//             <p
//               className="m-0 mt-2 mt-lg-5 look"
//               style={{ fontSize: "18px", fontWeight: "500", color: "#EB8128" }}
//             >
//               Why S3?
//             </p>
//             <p
//               className="m-0 mt-2 electronic_text res-font-high"
//               style={{ fontSize: "40px", fontWeight: "700", color: "#2E2E2E" }}
//             >
//               Your Electronics Appliances.
//               <br />
//               In Safe Hands.{" "}
//             </p>
//             <p
//               className="m-0 mt-2"
//               style={{
//                 fontSize: "16px",
//                 fontWeight: "400",
//                 color: "#676767",
//                 width: "100%",
//               }}
//             >
//               Our app is a first-of-its-kind in India, developed by the
//               Electronics Technicians Association to serve the community by
//               offering trustworthy and value-added services. It gives a peace of
//               mind to the consumers that their devices are at the safe hands.
//             </p>
//             <Button
//               onClick={() => navigate("/login")}
//               className="mt-lg-5 mt-2 rounded-3 explore_btn"
//               // data-bs-toggle="modal"
//               data-bs-target="#staticBackdrop"
//               variant="contained"
//               style={{
//                 textTransform: "none",
//                 height: "70px",
//                 width: "260px",
//                 backgroundColor: "#EB8128",
//                 fontSize: "18px",
//                 fontWeight: "500",
//               }}
//             >
//               Explore Now
//             </Button>
//           </div>
//         </div>
//         <div className="row m-0 p-lg-5 p-2 download_bg">
//           <div className="col-lg-6 col-12 text-center round">
//             <img
//               alt="demo"
//               style={{ height: "550px", width: "290px" }}
//               src={Mobile}
//             />
//           </div>
//           <div className="col-lg-6 col-12">
//             {/* <p
//               className="m-0 look"
//               style={{
//                 fontSize: "18px",
//                 fontWeight: "500px",
//                 color: "#EB8128",
//               }}
//             >
//               Join Us Today !
//             </p> */}
//             <p
//               className="m-0 mt-2 electronic_text"
//               style={{
//                 fontSize: "40px",
//                 fontWeight: "700px",
//                 color: "#2E2E2E",
//               }}
//             >
//               {/* Download S3 App to Get Our Services Easily! */}
//               Your S3 App is Coming Soon!
//             </p>
//             {/* <p className="m-0 mt-lg-4 mt-3" style={{ fontSize: '18px', fontWeight: '400', color: '#676767' }}>
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.</p> */}
//             <ul
//               className="p-0  mt-lg-4 mt-3 play"
//               style={{ display: "inline-flex" }}
//             >
//               <li className="mt-lg-2" style={{ listStyle: "none" }}>
//                 <img
//                   className="mt-3 ms-5"
//                   alt="demo"
//                   src={Play}
//                   style={{ width: "230px", height: "76px" }}
//                 />
//                 <img
//                   className="ms-lg-4 mt-3 ms-5"
//                   alt="demo"
//                   src={App}
//                   style={{ width: "230px", height: "76px" }}
//                 />
//               </li>
//               {/* <li className="ms-lg-2 mt-2" style={{ listStyle: 'none' }}>
//               </li> */}
//             </ul>
//           </div>
//           <div
//             class="modal fade"
//             id="staticBackdrop"
//             data-bs-backdrop="static"
//             data-bs-keyboard="false"
//             tabindex="-1"
//             aria-labelledby="staticBackdropLabel"
//             aria-hidden="true"
//           >
//             <div class="modal-dialog modal-dialog-centered">
//               <div class="modal-content w-75 ms-lg-5 mb-lg-5 ">
//                 <div class="modal-body p-lg-4">
//                   <div className="row">
//                     <div className="col-10">
//                       <img
//                         alt=""
//                         src={Logoin}
//                         className="ms-2"
//                         style={{ width: "88%" }}
//                       />
//                     </div>
//                     <div className="col-2">
//                       <button
//                         type="button"
//                         class="btn-close mt-3"
//                         data-bs-dismiss="modal"
//                         aria-label="Close"
//                       ></button>
//                     </div>
//                   </div>
//                   <div className="row mt-lg-4">
//                     <div
//                       className="mt-lg-3"
//                       style={{ fontSize: "15px", textAlign: "center" }}
//                     >
//                       <text>For more details Please</text>
//                       <a className="ms-2" href="/login">
//                         Login here!
//                       </a>
//                       <br />
//                       <br />
//                       <text>New User for S3 Please</text>
//                       <a className="ms-2" href="/commonRegister">
//                         Signup here!
//                       </a>
//                     </div>
//                   </div>
//                   <div className="row">
//                     <div
//                       className="mt-3"
//                       style={{
//                         display: "flex",
//                         fontSize: "19px",
//                         justifyContent: "center",
//                       }}
//                     ></div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <Footer />
//         {/* <div className="row bg-white p-4">
//           <div className="col-lg-7 col-6">
//             <p className="m-0" style={{ fontSize: '14px', fontWeight: "400", color: '#1E232C' }}>All Rights Reserved @ S3</p>
//           </div>
//           <div className="col-lg-5 col-6 text-end">
//             <ul className="p-0" style={{ display: 'inline-flex' }}>
//               <li style={{ listStyle: 'none' }}>
//                 <p className="m-0" style={{ fontSize: '14px', fontWeight: "400", color: '#1E232C' }}>Terms & Conditions</p>
//               </li>
//               <li className="ms-lg-3 ms-2" style={{ listStyle: 'none' }}>
//                 <p className="m-0" style={{ fontSize: '14px', fontWeight: "400", color: '#1E232C' }}>Privacy & Policy</p>
//               </li>

//             </ul>
//           </div>
//         </div> */}
//       </div>
//     </>
//   );
// };
// export default LandingPage;

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Paper } from "@mui/material";
import Television from "../Images/television.png";
import Fridge from "../Images/refrigerator.png";
import Wash from "../Images/wash.png";
import Ac from "../Images/cooler.png";
import Logo from "../Images/newLogo.png";
import Mobile from "../Images/mobile.png";
import Play from "../Images/playstore.png";
import App from "../Images/appstore.png";
import TechLogo from "../Images/techlogo.png";
import SRcard from "../Images/srcard.png";
import Header from "./Header";
import Suit from "../../src/Images/suitcase.png";
import Book from "../../src/Images/book.png";
import Crown from "../../src/Images/crown.png";
import Footer from "./Footer";
import "../landingpage.css";
import Logoin from "../Images/s3Logo.png";
import { Link, useNavigate } from "react-router-dom";
import ApplianceCarousel from "./ApplianceCarousel";
import TopBanner from "./home/TopBanner";
import Promotion from "./home/Promotion";
import HomeServices from "./home/HomeServices";
import MoreFeatures from "./home/MoreFeatures";
import HelpBanner from "./home/HelpBanner";
import Reviews from "./home/Reviews";
import Navbar from "./common/Navbar";
import Guarantee from "./home/Guarantee";

const LandingPage = ({ innerWidth }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  // useEffect(() => {
  //   const body = document.querySelector("body");
  //   body.classList.remove("res-nav-body-constructor");
  //   const user_token = localStorage.getItem("token");
  //   const user_role = localStorage.getItem("role");
  //   if (user_token) {
  //     if (user_role === "Technician") {
  //       navigate("/technicianDashboard");
  //     } else if (user_role === "SuperAdmin") {
  //       navigate("/super-admin-dashboard");
  //     } else if (user_role === "ZonalAdmin") {
  //       navigate("/zonal-admin-dashboard");
  //     } else if (user_role === "DistrictAdmin") {
  //       navigate("/district-admin-dashboard");
  //     } else {
  //       navigate("/customerDashboard");
  //     }
  //   }
  // }, []);
  return (
    <>
      <div>
        <Navbar innerWidth={innerWidth} />
        <div className="shade-provider"></div>
        <TopBanner />
        <Promotion />
        <HomeServices />
        <MoreFeatures />
        <Guarantee />
        <HelpBanner />
        <Reviews />
        <Footer />
      </div>
    </>
  );
};
export default LandingPage;
