import React from "react";
import tv_service from "../../Assets/Home/dth-service.svg";
import tv_service_1 from "../../Assets/Home/dth-service-1.svg";

export default function DTHServiceOffered() {
  return (
    <div>
      <div>
        <div className="appliances-services-container-wa-vision">
          <div className="first-flex-service">
            <div
              style={{ fontWeight: 600, fontSize: "34px" }}
              className="res-576px-text-align-center res-576px-font-25px"
            >
              Services offered:{" "}
            </div>
            <div
              style={{
                fontSize: "15px",
                color: "rgba(26, 26, 26, 0.7)",
                marginTop: "30px",
                lineHeight: "26px",
              }}
            >
              <ul style={{ color: "black" }}>
                <li>
                  <b> Repair: </b>{" "}
                  <span style={{ color: "rgba(26, 26, 26, 0.7)" }}>
                    To book a repair service, log in to our website or mobile
                    application and search for DTH repair service. You can enter
                    the specifications including brand names, model type and the
                    nature of the issue faced. Be it an in-built or external
                    set-top box, our technicians can handle it all,
                  </span>{" "}
                  <b>including:</b>
                </li>
                <div
                  style={{
                    fontSize: "12px",
                    marginLeft: "40px",
                    marginTop: "10px",
                  }}
                  className="res-576px-ml-0px"
                >
                  {" "}
                  1. Issues in booting the set-top box
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    marginLeft: "40px",
                    marginTop: "4px",
                  }}
                  className="res-576px-ml-0px"
                >
                  {" "}
                  2. Unclear transmission
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    marginLeft: "40px",
                    marginTop: "4px",
                  }}
                  className="res-576px-ml-0px"
                >
                  3. No signal received error  
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    marginLeft: "40px",
                    marginTop: "4px",
                  }}
                  className="res-576px-ml-0px"
                >
                  4. Worn-out parts such as drum bearings
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    marginLeft: "40px",
                    marginTop: "4px",
                  }}
                  className="res-576px-ml-0px"
                >
                  5. Wear and tear in DTH external dish, exposure to harsh
                  weather conditions{" "}
                </div>
                <li className="mt-3">
                  <b>Installation/Uninstallation:</b>{" "}
                  <span style={{ color: "rgba(26, 26, 26, 0.7)" }}>
                    During installation or movement of the DTH, it is important
                    to install/re-install with precision to ensure the best
                    signal reception. Once that is set up, installation of smart
                    card in the set-top box, channel configuration, demo, and
                    tips on regular care are provided.
                  </span>
                </li>
                <li className="mt-3">
                  <b> Maintenance Service: </b>
                  <span style={{ color: "rgba(26, 26, 26, 0.7)" }}>
                    To ensure the long-lasting life of any appliance, it is
                    important to invest in annual maintenance and cleaning. Book
                    your service now at minimal cost to ensure huge savings
                    later.
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div
            className="second-flex-img res-576px-w-fill"
            style={{ scale: "0.8" }}
          >
            <img
              src={tv_service_1}
              alt="phone_img"
              className="res-576px-w-fill"
            />
          </div>
        </div>
      </div>
      <div className="appliances-services-container-mission">
        <div
          className="second-flex-img"
          style={{ scale: "0.8", marginTop: "-80px" }}
        >
          <img src={tv_service} alt="tv_service" />
        </div>
        <div className="first-flex-service">
          <div
            style={{ fontWeight: 600, fontSize: "34px" }}
            className="res-576px-text-align-center res-576px-font-25px"
          >
            Why S3 for DTH Repair and Service?
          </div>
          <div
            style={{
              fontSize: "15px",
              color: "rgba(26, 26, 26, 0.7)",
              marginTop: "30px",
              lineHeight: "26px",
            }}
          >
            <ul style={{ color: "black", textAlign: "justify" }}>
              <li>
                <span style={{ fontWeight: 600 }}>
                  Trained and experienced technicians:
                </span>{" "}
                <span style={{ color: "rgba(26, 26, 26, 0.7)" }}>
                  Our administrators and supervisors guarantee that each S3
                  technician is trained in professional and personal etiquette.
                  We set a high level of quality as our standard and ensure it
                  only increases year on year!
                </span>
              </li>
              <li className="mt-2">
                <span style={{ fontWeight: 600 }}> One-stop solution:</span>{" "}
                <span style={{ color: "rgba(26, 26, 26, 0.7)" }}>
                  With S3, you get a one-stop solution to service any DTH brand
                  – Airtel, Sun Direct, d2h, etc.
                </span>{" "}
              </li>
              <li className="mt-2">
                <span style={{ fontWeight: 600 }}> Convenient Time Slots:</span>{" "}
                <span style={{ color: "rgba(26, 26, 26, 0.7)" }}>
                  There are multiple time slots available based on your choice.
                  Until the technician is assigned, you also have the option to
                  reschedule the appointment. Our administrators are on the
                  constant lookout to ensure the best match technician is
                  assigned to any DTH repair, service, or installation.
                </span>{" "}
              </li>
              <li className="mt-2">
                <span style={{ fontWeight: 600 }}>30-day-warranty:</span>{" "}
                <span style={{ color: "rgba(26, 26, 26, 0.7)" }}>
                  We guarantee to fix any issue that recurs post-service
                  delivery within 30 days!
                </span>{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
