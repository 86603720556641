import { createSlice } from "@reduxjs/toolkit";


const rewardLoginListSlice = createSlice({
    name: 'rewardLoginList',
    initialState: {
        loading: false,
        rewardLoginListData: [],
        error: null,
        statusCode: null,
        successMsg: null,
        errorMsg: null,
        totalCount: 0,

    },
    reducers: {
        rewardLoginListRequest(state, action) {
            return {
                ...state,
                loading: true
            }
        },
        rewardLoginListSuccess(state, action) {
            return {
                ...state,
                loading: true,
                rewardLoginListData: action?.payload,
                // successMsg: action.payload.data.message,
                // totalCount: action.payload.data?.totalCount,
                errorMsg: null,
                statusCode: 'success'
            }
        },
        rewardLoginListFail(state, action) {
            return {
                ...state,
                loading: false,
                error: action.payload,
                statusCode: 'error',
                errorMsg: action?.payload?.error?.message,
                // totalCount: 0,
                rewardLoginListData: [],
                successMsg: null
            }
        },
        clearStatusCode(state, action) {
            return {
                ...state,
                statusCode: null
            }
        }

    }
});

const { actions, reducer } = rewardLoginListSlice;

export const { rewardLoginListRequest, rewardLoginListFail, rewardLoginListSuccess, clearStatusCode } = actions

export default reducer;
