import React from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";

const ApexChart = () => {
  const { technicianDashboardData } = useSelector(
    (state) => state.technicianDashboardState
  );

  const sr_count = technicianDashboardData?.applianceSRCount?.map((val) => {
    return val?.sr_count?.toString() || "";
  }) || [];

  const appliance_name = technicianDashboardData?.applianceSRCount?.map((val) => {
    return val?.appliance_name || "";
  }) || [];

  const state = {
    series: [
      {
        data: sr_count,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 310,
      },
      plotOptions: {
        bar: {
          barHeight: "37%",
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: "bottom",
          },
        },
      },
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        style: {
          colors: ["transparent"],
        },
        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
        },
        offsetX: 0,
        dropShadow: {
          enabled: false,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: appliance_name,
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      tooltip: {
        theme: "light",
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return "";
            },
          },
        },
      },
    },
  };

  return (
    <div id="chart" style={{ overflowY: "auto" }}>
      {sr_count.length > 0 && appliance_name.length > 0 ? (
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="bar"
          height={310}
        />
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default ApexChart;
