import React from "react";
import play_store from "../../Assets/Home/Play-store.svg";

export default function UpcomingService() {
  return (
    <div className="upcoming-services-container">
      <div className="upcoming-services-content">
        <div className="heading">
          Our upcoming services based on popular demand:
        </div>
        <div className="content-flex-upcoming">
          <div className="upcoming-text">
            Air Conditioner, RO Water Storage and Dispenser, GPS, CCTV or
            audio-video player systems at home or public places, including
            transport systems such as bus.
          </div>
          <div>
            <div className="mail">
              <div className="mail-res-flex">
                <div className="mail-heading">Download Our App</div>

                <div
                  style={{
                    display: "flex",
                    // marginTop: "16px",
                    flexDirection: "column",
                  }}
                  className="app-play-store"
                >
                  {/* <img
                alt="app_store"
                src={app_store}
                style={{ height: "50px", width: "130px" }}
              /> */}
                  <img
                    alt="play_store"
                    src={play_store}
                    style={{ height: "50px", width: "130px" }}
                    className="res-576-w-unset"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
