import React from "react";
import medal_star from "../../Assets/Home/medal-star.svg";
import personal_card from "../../Assets/Home/personalcard.svg";
import setting from "../../Assets/Home/setting-about.svg";
export default function AboutServices() {
  return (
    <div className="about-services-container">
      <div className="ser-con">
        <img
          src={medal_star}
          alt="medal_star"
          style={{ scale: "0.8", position: "relative", left: "-14px" }}
          className="res-576px-l-0"
        />
        <div style={{ fontSize: "18px", fontWeight: 1000 }} className="mt-3">
          End-to-end support
        </div>
        <div
          style={{
            fontSize: "15px",
            color: "rgba(26, 26, 26, 0.7)",
            marginTop: "10px",
            textAlign: "start",
          }}
          className="ser-cont"
        >
          Rely on us for any home, shop, or office appliance, our technicians
          will help with repair, installation, and uninstallation.
        </div>
      </div>
      <div className="border-right"></div>
      <div className="ser-con">
        <img
          src={personal_card}
          alt="medal_star"
          style={{ scale: "0.8", position: "relative", left: "-14px" }}
          className="res-576px-l-0"
        />
        <div style={{ fontSize: "18px", fontWeight: 1000 }} className="mt-3">
          Open and Transparent
        </div>
        <div
          style={{
            fontSize: "15px",
            color: "rgba(26, 26, 26, 0.7)",
            marginTop: "10px",
            textAlign: "start",
          }}
          className="ser-cont"
        >
          That is our policy. Whether it is service reviews, service rates, or
          assigned technicians, we display it all fair and square.
        </div>
      </div>
      <div className="border-right"></div>

      <div className="ser-con">
        <img
          src={setting}
          alt="medal_star"
          style={{ scale: "0.8", position: "relative", left: "-14px" }}
          className="res-576px-l-0"
        />
        <div style={{ fontSize: "18px", fontWeight: 1000 }} className="mt-3">
          Unified Thinking
        </div>
        <div
          style={{
            fontSize: "15px",
            color: "rgba(26, 26, 26, 0.7)",
            marginTop: "10px",
            textAlign: "start",
          }}
          className="ser-cont"
        >
          We are a crowdsourced group of Technicians, led by Technicians!
        </div>
      </div>
    </div>
  );
}
