import React from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export default function DTHServiceBanner() {
  const navigate = useNavigate();

  return (
    <div className="appliances-service-banner-con">
      <div style={{ fontSize: "11px", color: "rgba(139, 139, 139, 1)" }}>
        <span
          style={{ color: "black", cursor: "pointer" }}
          onClick={() => navigate("/")}
          className="res-576px-d-none"
        >
          <b>Home</b>
        </span>
        <MdKeyboardArrowRight className="res-576px-d-none" size={22} />
        <span
          onClick={() => navigate("/services")}
          style={{ cursor: "pointer" }}
          className="res-576px-d-none"
        >
          Services
        </span>
        <MdKeyboardArrowRight className="res-576px-d-none" size={22} />
        <span className="res-576px-d-none">DTH</span>
      </div>
      <div className="banner-content">
        <div className="heading">DTH</div>

        <div className="con-text">
          Direct to Home fixed directly at home! Enjoy an uninterrupted amazing
          viewing experience with S3! We assure quick and quality services at
          your doorstep, with no middlemen!
        </div>
        <div className="con-text">
          Searching for reliable DTH Installation or Repair Service? We have
          experienced technicians within your neighbourhood, across Tamil Nadu,
          be it a hill station, metro or towns! Log in now to experience the S3
          difference!
        </div>
        <button className="download-button" onClick={() => navigate("/login")}>
          Log in
        </button>
      </div>
    </div>
  );
}
