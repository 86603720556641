import axios from "axios";
import { BASE_URL } from "../../api/ApiPath";
import {
  productListFail,
  productListRequest,
  productListSuccess,
} from "../slices/productListSlice";
import {
  productCreateFail,
  productCreateRequest,
  productCreateSuccess,
  productUpdateFail,
  productUpdateRequest,
  productUpdateSuccess,
} from "../slices/productSlice";
import {
  productSelectedFail,
  productSelectedRequest,
  productSelectedSuccess,
} from "../slices/productSelectedSlice";
import Swal from "sweetalert2";
const handleUnAuthorizedError = (err) => {
  if (err.response.status === 401) {
    console.log("working");
    localStorage.clear();
    window.location.href = "/";
    window.location.reload();
  }
};
export const getProductList =
  (payload, searchInput, filterData) => async (dispatch) => {
    console.log("filterData: ", filterData);
    const token = localStorage.getItem("token");
    const filterDataPayload = `${searchInput ? `&search=${searchInput}` : ""}${
      filterData?.startDate ? `&startDate=${filterData?.startDate}` : ""
    }${filterData?.endDate ? `&endDate=${filterData?.endDate}` : ""}${
      filterData?.maxWallet ? `&max_wallet=${filterData?.maxWallet}` : ""
    }${filterData?.minWallet ? `&min_wallet=${filterData?.minWallet}` : ""}${
      filterData?.max_amount
        ? `&max_amount=${JSON.stringify(filterData?.max_amount)}`
        : ""
    }${
      filterData?.min_amount
        ? `&min_amount=${JSON.stringify(filterData?.min_amount)}`
        : ""
    }${filterData?.product ? `&appliance=${filterData?.product}` : ""}`;
    try {
      dispatch(productListRequest());
      const response = await axios.get(
        `${BASE_URL}/product?status=${payload?.status}&page=1&limit=10${filterDataPayload}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      dispatch(productListSuccess(response?.data));
    } catch (error) {
      handleUnAuthorizedError(error);
      dispatch(productListFail(error));
    }
  };
export const getProduct = (payload) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch(productSelectedRequest());
    const response = await axios.get(`${BASE_URL}/product/${payload.id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch(productSelectedSuccess(response?.data));
  } catch (error) {
    handleUnAuthorizedError(error);
    dispatch(productSelectedFail(error));
  }
};
export const createProduct = (payload) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch(productCreateRequest());
    const response = await axios.post(`${BASE_URL}/product`, payload.data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    Swal.fire({
      text: response?.data,
      showConfirmButton: false,
      timer: 3000,
      iconSize: "1.5em",
    });
    dispatch(productCreateSuccess(response?.data));
    const searchPayload = {
      search: payload.search,
      status: payload.status,
    };
    dispatch(getProductList(searchPayload));
  } catch (error) {
    handleUnAuthorizedError(error);
    Swal.fire({
      text: error?.response?.data,
      showConfirmButton: false,
      timer: 3000,
      iconSize: "1.5em",
    });
    dispatch(productCreateFail(error));
  }
};
export const updateProduct = (payload) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch(productUpdateRequest());
    const response = await axios.put(
      `${BASE_URL}/product/${payload.id}`,
      payload.data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log("response", response);
    Swal.fire({
      text: response?.data,
      showConfirmButton: false,
      timer: 3000,
      iconSize: "1.5em",
    });
    dispatch(productUpdateSuccess(response?.data));
    const searchPayload = {
      search: payload.search,
      status: payload.status,
    };
    dispatch(getProductList(searchPayload));
  } catch (error) {
    handleUnAuthorizedError(error);
    Swal.fire({
      text: error?.response.data,
      showConfirmButton: false,
      timer: 3000,
      iconSize: "1.5em",
    });
    dispatch(productUpdateFail(error));
  }
};
