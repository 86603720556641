import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import {
  Card,
  Avatar,
  Stack,
  TextField,
  Popover,
  Tooltip,
} from "@mui/material/";
import { Rating } from "@mui/material";
import { Box } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import profile from "../../../Images/sample_avatar.jpg";
import femaleclor from "../../../Images/Femaleclor.jpg";
import maleclor from "../../../Images/Maleclor.jpg";
import female from "../../../Images/Female.jpg";
import male from "../../../Images/Male.jpg";
import Api from "../../../service/services";
import SpinnerLoader2 from "../../../plugins/SpinnerLoader2";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import "../../../index.css";
import moment from "moment";

import {
  MdClose,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from "react-icons/md";
import { Modal } from "react-bootstrap";
import UserFilterModule from "../../../plugins/userFilter";
import HalfRatings from "../../../plugins/HalfRatings";
import CountContext from "../../countContext/CountContext";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
const AllCustomers = (/* { handleUpdateCount } */) => {
  // const mobileMatches = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const mobileMatches = useMediaQuery(theme.breakpoints.down("sm"));
  // const mobileMatches = useMediaQuery("(min-width:600px)");
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setdob] = useState("");
  const [address, setAddress] = useState("");
  const [address1, setAddress1] = useState("");
  const [country, setCountry] = useState("India");
  const [state, setState] = useState("Tamil Nadu");
  const [city, setCity] = useState("");
  const [postal_code, setPostalcode] = useState("");
  const [image, setImage] = useState(null);
  const [images, setImages] = useState({});
  const [gender, setGender] = useState(null);
  const [cityData, setCityData] = useState([]);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [fullnameError, setFullnameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [genderError, setGenderError] = useState("");

  const [IsFullNameEmpty, setIsFullEmpty] = useState(false);
  const [IsPhoneEmpty, setIsPhoneEmpty] = useState(false);
  const [IsDobEmpty, setIsDobEmpty] = useState(false);
  const [IsCityEmpty, setIsCityEmpty] = useState(false);
  const [IsPostalEmpty, setIsPostalEmpty] = useState(false);

  const [IsEmpty, setIsEmpty] = useState(false);
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const [Ismale, setIsMale] = useState(false);
  const [IsFemale, setIsFemale] = useState(false);

  const [checked, setChecked] = React.useState(true);
  const [loading, setLoading] = useState(false);
  const [userRatings, setUserRatings] = useState({});
  const [ratingsModal, setRatingsModal] = useState(false);
  const [showFullReviews, setShowFullReviews] = useState(
    Array(userRatings?.user_ratings?.length).fill(false)
  );
  const handleCardClick = (index) => {
    setSelectedCardIndex(index === selectedCardIndex ? null : index);
  };
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files?.length > 0) {
      const fileList = Array.from(event.target.files); // Convert FileList to an array
      const imageUrls = fileList.map((file) => URL.createObjectURL(file));

      setImage(imageUrls);
      setImages(fileList);
    }
  };
  /* ----------------------------------------------------------------------------------------------------- */
  const [allTechDetails, setAllTechsDetails] = useState([]);
  const [avatar, setAvatar] = useState("");
  const [name, setName] = useState("");
  const [phoneno, setPhoneno] = useState("");
  const [role, setRole] = useState("");
  const [filteredDate, setFilteredDate] = useState([]);
  const [userid, setUserId] = useState("");
  const [availableAppliances, setAvailableAppliances] = useState([]);
  const [userSelectedAppliances, setUserSelectedAppliances] = useState([]);
  const [
    labelUserSelectedAppliances,
    setLabelUserSelectedAppliances,
  ] = useState([]);
  const [selectedApplianceData, setSelectedApplianceData] = useState([]);
  const [selectedApplianceDataInput, setSelectedApplianceDataInput] = useState(
    []
  );
  const [labelUserSelectedModels, setLabelUserSelectedModels] = useState([]);

  const [imageModal, setImageModal] = useState(false);
  const [storeImage, setStoreImage] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [clickedRole, setClickedRole] = useState("");

  const handleImageClick = (index) => {
    setCurrentIndex(index);
  };

  const showPreviousImage = () => {
    const previousIndex =
      (currentIndex - 1 + storeImage?.length) % storeImage?.length;
    setCurrentIndex(previousIndex);
  };

  const showNextImage = () => {
    const nextIndex = (currentIndex + 1) % storeImage?.length;
    setCurrentIndex(nextIndex);
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    const Role = localStorage.getItem("role");
    setRole(Role);
  }, []);

  const handlePopoverOpen = (e) => {
    setAnchorEl(e.currentTarget);
    setPopoverOpen(true);
  };

  const getTechnician = () => {
    setLoading(true);
    if (role == "DistrictAdmin") {
      Api.DistrictUserManagementAllTech().then((result) => {
        setAllTechsDetails(result?.data);
        setFilteredDate(result?.data);
        result?.data?.length > 0 && handelSingleUser(result?.data[0]?.id);
        if (allTechDetails.length > 0) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    }
    if (role == "ZonalAdmin") {
      Api.ZonalUserManagementAllTech().then((result) => {
        setAllTechsDetails(result?.data);
        setFilteredDate(result?.data);
        result?.data?.length > 0 && handelSingleUser(result?.data[0]?.id);
        if (allTechDetails?.length > 0) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    }
    if (role == "SuperAdmin") {
      Api.SuperAdminUsermanagementGetAllTech().then((result) => {
        setAllTechsDetails(result.data);
        setFilteredDate(result.data);
        result?.data?.length > 0 && handelSingleUser(result?.data[0]?.id);
        if (allTechDetails?.length > 0) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    }
  };

  const clear = () => {
    setName("");
    setEmail("");
    setPhoneno("");
    setState("");
    setdob("");
    setPostalcode("");
    setCity("");
    setAddress("");
  };
  const [clickedUser, setClickedUser] = useState("");
  const handelSingleUser = (id, user) => {
    if (mobileMatches) {
      if (user?.id) {
        window.scrollTo({
          top: document.documentElement.scrollHeight, // Scroll to the bottom of the page
          behavior: "smooth", // Smooth scrolling behavior
        });
      }
    }
    clear();
    setClickedRole(user?.role);
    setUserId(id);
    Api.reviewsAndRatingsList(id)
      .then((res) => {
        setUserRatings(res);
      })
      .catch((err) => console.log(err));
    if (
      role == "DistrictAdmin" ||
      role == "ZonalAdmin" ||
      role == "SuperAdmin"
    ) {
      Api.DistrictUserManagementGetOneUser(id).then((res) => {
        const payload = {
          user_id: id,
        };
        setClickedRole(res?.role);

        Api.getApplianceRelatedList(payload).then((res) => {
          if (res) {
            setUserSelectedAppliances(res?.userSelectedAppliances);
            setAvailableAppliances(res?.availableAppliances);
            setLabelUserSelectedAppliances(
              res?.userSelectedAppliances?.selectedAppliance
            );
            setLabelUserSelectedModels(
              res?.userSelectedAppliances?.selectedModels
            );
            getUniqueAppliance(
              res?.userSelectedAppliances?.selectedAppliance,
              res?.userSelectedAppliances?.selectedModels
            );
          }
        });
        {
          res?.avatar ? setAvatar(res?.avatar) : setAvatar("");
        }
        {
          res?.fullname ? setName(res?.fullname) : setName("");
        }
        {
          setStoreImage(res?.shop_images);
        }
        {
          res?.emailid ? setEmail(res?.emailid) : setEmail("");
        }
        {
          res?.mobile_no ? setPhoneno(res?.mobile_no) : setPhoneno("");
        }
        {
          res.userDetails?.state
            ? setState(res.userDetails?.state)
            : setState("");
        }
        if (res?.userDetails?.dob) {
          const dobMoment = moment(
            res.userDetails.dob,
            ["YYYY-MM-DD", "DD-MM-YYYY"],
            true
          );
          if (dobMoment.isValid()) {
            setdob(dobMoment.format("YYYY-MM-DD"));
          } else {
            // Handle invalid date here
            setdob(""); // Or any other appropriate action
          }
        } else {
          setdob("");
        }

        {
          res?.userDetails?.postalcode
            ? setPostalcode(res?.userDetails?.postalcode)
            : setPostalcode("");
        }
        {
          res?.userDetails?.district
            ? setCity(res?.userDetails?.district)
            : setCity("");
        }
        {
          res?.userDetails?.address
            ? setAddress(res.userDetails?.address)
            : setAddress("");
        }
        {
          res?.is_active == 1 ? setChecked(false) : setChecked(true);
        }

        if (
          res?.userDetails?.gender == null ||
          res?.userDetails?.gender == undefined
        ) {
          setIsMale(false);
          setIsFemale(false);
        }
        if (res?.userDetails?.gender?.toLowerCase() === "male") {
          setIsMale(true);
          setIsFemale(false);
        } else if (res?.userDetails?.gender?.toLowerCase() === "female") {
          setIsMale(false);
          setIsFemale(true);
        } else {
          setIsMale(false);
          setIsFemale(false);
        }
      });
    }
  };
  const handlePopoverClose = () => {
    setPopoverOpen(false);
    setAnchorEl(null);
  };
  useEffect(() => {
    getTechnician();
  }, [role]);
  const handleBlock = () => {
    let formdata = new FormData();
    formdata.append("user_id", userid);
    {
      checked ? formdata.append("is_block", 0) : formdata.append("is_block", 1);
    }
    formdata.append("is_delete", 0);
    Api.ParticularUserDelete(formdata).then((res) => {
      if ((res.message = "Profile Updated Successfully")) {
        getTechnician();
      }
    });
  };
  const handleDelete = (tech_id) => {
    const deleteData = filteredDate?.find((val) => val?.id === tech_id);
    Swal.fire({
      text: `Are you sure, you want to remove ${deleteData?.fullname} ?`,
      confirmButtonText: "OK",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let formdata = new FormData();
        formdata.append("user_id", tech_id);
        formdata.append("is_block", 0);
        formdata.append("is_delete", 1);
        if (
          role == "DistrictAdmin" ||
          role == "ZonalAdmin" ||
          role == "SuperAdmin"
        ) {
          Api.ParticularUserDelete(formdata).then((res) => {
            /*  handleUpdateCount(true); */
            if ((res.message = "Profile Updated Successfully")) {
              getTechnician();
            }
          });
        }
      }
    });
  };
  const handleUpdate = () => { };
  const { setSearch, search } = useContext(CountContext);
  const handleApprove = () => {
    setSearch("");
    const formData = new FormData();
    formData.append("role", 2);
    formData.append("fullname", name);
    formData.append("username", email);
    formData.append("mobile_no", phoneno);
    formData.append("address", address);
    formData.append("dob", dob);
    formData.append("gender", Ismale ? "MALE" : IsFemale && "FEMALE");
    formData.append("country", country);
    formData.append("state", state);
    formData.append("city", city);
    formData.append("pincode", postal_code);
    formData.append("applianceMap", JSON.stringify(selectedApplianceData));
    Swal.fire({
      text: `Do you want to save the profile changes?`,
      confirmButtonText: "OK",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Api.updateTechnician(userid, formData).then((res) => {
          // if (res.message == "User Approved Successfully") {
          //   Swal.fire({
          //     text: `Great, You onboarded a technician! 🎉`,
          //     confirmButtonText: "OK",
          //   });
          // }
          handleBlock();
          handelSingleUser(userid);
        });
      }
    });
  };

  useEffect(() => {
    const formattedLabelUserSelectedAppliances = labelUserSelectedAppliances?.map(
      (appliance) => {
        return {
          appliance_id: appliance?.id,
        };
      }
    );
    const formattedLabelUserSelectedModels = labelUserSelectedModels?.map(
      (selectedModels) => {
        return {
          appliance_id: selectedModels?.appliance_id,
          model_id: selectedModels?.id,
        };
      }
    );
    const formattedLabelUserSelectedModelsView = labelUserSelectedModels?.map(
      (selectedModels) => {
        if (
          labelUserSelectedAppliances?.some(
            (appliance) => appliance?.id === selectedModels?.appliance_id
          )
        ) {
          return;
        } else {
          return {
            appliance_id: selectedModels?.appliance_id,
            model_id: selectedModels?.id,
          };
        }
      }
    );
    console.log(
      "formattedLabelUserSelectedModelsView: ",
      formattedLabelUserSelectedModelsView
    );
    const formattedPayload =
      formattedLabelUserSelectedAppliances && formattedLabelUserSelectedModels
        ? [
          ...formattedLabelUserSelectedAppliances,
          ...formattedLabelUserSelectedModels,
        ]
        : [];
    const uniqueIds = new Set();
    const uniqueAppliances = [];
    formattedLabelUserSelectedModelsView.forEach((obj) => {
      if (!uniqueIds.has(obj?.appliance_id)) {
        uniqueIds.add(obj?.appliance_id);
        uniqueAppliances.push(obj);
      }
    });
    const formattedPayloadView =
      formattedLabelUserSelectedAppliances &&
        formattedLabelUserSelectedModelsView
        ? [...formattedLabelUserSelectedAppliances, ...uniqueAppliances]
        : [];
    setSelectedApplianceData(formattedPayload);
    setSelectedApplianceDataInput(formattedPayloadView);
  }, [labelUserSelectedAppliances, labelUserSelectedModels]);
  // const getUniqueAppliance = (selected_appliance_data, model_data) => {
  //   let updated_appliance;
  //   updated_appliance = model_data.map((selectedModel) => {
  //     const data = selected_appliance_data.find(
  //       (selectedAppliance) =>
  //         selectedAppliance?.id === selectedModel?.appliance_id
  //     );
  //     return data;
  //   });
  //   const uniqueIds = new Set();
  //   const uniqueObjects = [];
  //   updated_appliance.forEach((obj) => {
  //     if (!uniqueIds.has(obj?.id)) {
  //       uniqueIds.add(obj?.id);
  //       uniqueObjects.push(obj);
  //     }
  //   });
  //   console.log("uniqueObjects: ", uniqueObjects);
  //   setLabelUserSelectedAppliances(uniqueObjects);
  // };
  const getUniqueAppliance = async (selected_appliance_data, model_data, uncheck = false) => {
    let updated_appliance;

    // Mapping through model_data to find matching appliances from selected_appliance_data
    updated_appliance = model_data.map((selectedModel) => {
      const data = selected_appliance_data.find(
        (selectedAppliance) => selectedAppliance.id === selectedModel.appliance_id
      );
      return data;
    });

    // Filter out undefined values (appliances without models)
    let filtered_appliance = updated_appliance.filter(Boolean);

    // Concatenate the remaining selected appliances that are not already in updated_appliance
    let concatenated_appliance = [
      ...filtered_appliance,
      ...selected_appliance_data.filter(
        (appliance) => !filtered_appliance.some((model) => model?.id === appliance.id)
      ),
    ];

    // Ensure uniqueness by using a Set
    const uniqueIds = new Set();
    let uniqueObjects = [];
    concatenated_appliance.forEach((obj) => {
      if (obj && !uniqueIds.has(obj.id)) {
        uniqueIds.add(obj.id);
        uniqueObjects.push(obj);
      }
    });

    // If uncheck is true, filter out the unchecked appliance or model
    if (uncheck) {
      const { applianceId, modelId } = uncheck;
      if (applianceId) {
        uniqueObjects = uniqueObjects.filter((obj) => obj.id !== applianceId);
      } else if (modelId) {
        const model = model_data.find((m) => m.id === modelId);
        if (model) {
          uniqueObjects = uniqueObjects.filter((obj) => obj.id !== model.appliance_id);
        }
      }
    }

    console.log("uniqueObjects: ", uniqueObjects);
    setLabelUserSelectedAppliances(uniqueObjects);
  };

  const handleApplianceChange = (applianceValue, isChecked) => {
    if (!isChecked) {
      // Uncheck appliance and all its models
      const updatedModels = labelUserSelectedModels.filter(
        (model) => model.appliance_id !== applianceValue.id
      );
      setLabelUserSelectedModels(updatedModels);
      getUniqueAppliance(
        labelUserSelectedAppliances.filter(
          (appliance) => appliance.id !== applianceValue.id
        ),
        updatedModels,
        { applianceId: applianceValue.id }
      );
    } else {
      // Check appliance
      const updatedAppliance = availableAppliances.appliances.find(
        (item) => item.id === applianceValue.id
      );
      setLabelUserSelectedAppliances([
        ...labelUserSelectedAppliances,
        updatedAppliance,
      ]);
    }
  };

  const handleModelChange = (modelValue, isChecked) => {
    if (isChecked) {
      // Check model and corresponding appliance
      const updatedUserSelectedModel = availableAppliances.models.find(
        (item) => item.id === modelValue.id
      );
      const updatedUserSelectedModels = [
        ...labelUserSelectedModels,
        updatedUserSelectedModel,
      ];
      setLabelUserSelectedModels(updatedUserSelectedModels);

      const correspondingAppliance = availableAppliances.appliances.find(
        (appliance) => appliance.id === modelValue.appliance_id
      );
      if (!labelUserSelectedAppliances.some((appliance) => appliance.id === correspondingAppliance.id)) {
        setLabelUserSelectedAppliances([
          ...labelUserSelectedAppliances,
          correspondingAppliance,
        ]);
      }
    } else {
      // Uncheck model
      const updatedModels = labelUserSelectedModels.filter(
        (model) => model.id !== modelValue.id
      );
      setLabelUserSelectedModels(updatedModels);

      // Uncheck corresponding appliance if no models remain checked
      const remainingModels = updatedModels.filter(
        (model) => model.appliance_id === modelValue.appliance_id
      );
      if (remainingModels.length === 0) {
        getUniqueAppliance(labelUserSelectedAppliances, updatedModels, { applianceId: modelValue.appliance_id });
      }
    }
  };
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string?.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}`,
    };
  }
  const handleFilterData = (filterData) => {
    if (role == "DistrictAdmin") {
      Api.DistrictUserManagementAllTech(searchInput, filterData).then(
        (result) => {
          setAllTechsDetails(result?.data);
          setFilteredDate(result?.data);
          result && handelSingleUser(result?.data[0]?.id);
          if (allTechDetails?.length > 0) {
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      );
    }
    if (role == "ZonalAdmin") {
      Api.ZonalUserManagementAllTech(searchInput, filterData).then((result) => {
        setAllTechsDetails(result?.data);
        setFilteredDate(result?.data);
        result && handelSingleUser(result?.data[0]?.id);
        if (allTechDetails?.length > 0) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    }

    if (role == "SuperAdmin") {
      Api.SuperAdminUsermanagementGetAllTech(searchInput, filterData).then(
        (result) => {
          setAllTechsDetails(result?.data);
          setFilteredDate(result?.data);
          result && handelSingleUser(result?.data[0]?.id);
          if (allTechDetails?.length > 0) {
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      );
    }
  };
  const handleSearchInput = (e) => {
    setSearchInput(e);
  };
  useEffect(() => {
    if (role == "DistrictAdmin") {
      Api.DistrictUserManagementAllTech(searchInput).then((result) => {
        setAllTechsDetails(result?.data);
        setFilteredDate(result?.data);
        result && handelSingleUser(result?.data[0]?.id);
        result && setClickedRole(result?.data[0]?.role);

        if (allTechDetails?.length > 0) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    }
    if (role == "ZonalAdmin") {
      Api.ZonalUserManagementAllTech(searchInput).then((result) => {
        setAllTechsDetails(result?.data);
        setFilteredDate(result?.data);
        result && handelSingleUser(result?.data[0]?.id);
        result && setClickedRole(result?.data[0]?.role);
        if (allTechDetails?.length > 0) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    }

    if (role == "SuperAdmin") {
      Api.SuperAdminUsermanagementGetAllTech(searchInput).then((result) => {
        console.log("SuperAdmin", result?.data[0]?.role);
        setAllTechsDetails(result.data);
        setFilteredDate(result.data);
        result && handelSingleUser(result?.data[0]?.id);
        result && setClickedRole(result?.data[0]?.role);

        if (allTechDetails?.length > 0) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    }
  }, [searchInput]);

  const mobileView = useMediaQuery("(min-width: 200px) and (max-width: 799px)");
  return (
    <>
      {loading ? (
        <SpinnerLoader2 />
      ) : (
        <div className="row" style={{ overflow: "hidden" }}>
          <div className="col-lg-4">
            <UserFilterModule
              inputPlaceholder={"Search by ID or Name"}
              onSearchInputChange={handleSearchInput}
              onChangeFilterData={handleFilterData}
            />
            {/* <div
              className="p-0 mt-2 ms-2"
              style={{ display: "flex", width: "-webkit-fill-available" }}
            >
              <Paper
                elevation={0}
                className="w-100 p-lg-3 border rounded-0"
                component="form"
                sx={{ display: "flex", alignItems: "center" }}
                style={{ height: "42px" }}
              >
                <IconButton type="button" sx={{ p: "2px" }} aria-label="search">
                  <SearchIcon />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  style={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "14px",
                  }}
                  placeholder="Search by Name or Wallet Balance"
                  inputProps={{ "aria-label": "search google maps" }}
                  onChange={handleFilterByName}
                />
              </Paper>
              <div className="ms-2" style={{ listStyle: "none" }}>
                <IconButton
                  style={{
                    width: "42px",
                    height: "42px",
                    borderRadius: "100px",
                  }}
                  className="border rounded-0"
                  aria-label="delete"
                >
                  <FilterListIcon style={{ color: "rgb(235, 129, 40)" }} />
                </IconButton>
              </div>
            </div> */}
            {filteredDate?.length > 0 ? (
              <div
                className="custom-scrollbar-appliance mt-2"
                style={{ width: "100%", height: "86vh", overflowY: "scroll" }}
              >
                {filteredDate &&
                  filteredDate?.map((tech) => (
                    <Card
                      onClick={() => handelSingleUser(tech?.id, tech)}
                      style={{
                        background:
                          tech.id === userid
                            ? "linear-gradient(to right,#FFE1CA , #FFF6EF00)"
                            : "white",
                        border:
                          tech.id === userid
                            ? "rgb(235, 129, 40) solid 1px"
                            : "",
                        marginBottom: "10px",
                        padding: "0px",
                        cursor: "pointer",
                        borderRadius: "10px",
                      }}
                    >
                      <Stack
                        direction="row p-0 m-0"
                        spacing={1}
                        className="center-container"
                        alignItems="baseline"
                      >
                        {
                          !mobileView &&
                          (tech?.avatar ? (
                            <Avatar
                              className="centered-text mt-4 ms-2"
                              src={tech?.avatar}
                              style={{
                                margin: "auto",
                              }}
                            />
                          ) : (
                            <Avatar
                              className="centered-text mt-4 ms-2"
                              {...stringAvatar(tech?.fullname)}
                              style={{
                                margin: "auto",
                              }}
                            />
                          ))
                          // <Avatar
                          //   className="centered-text mt-3 ms-2"
                          //   src={
                          //     tech?.avatar
                          //       ? tech?.avatar
                          //       : stringAvatar(tech?.fullname)
                          //   }
                          //   {...stringAvatar(tech?.fullname)}
                          // style={{
                          //   margin: "auto",
                          // }}
                          // />
                        }
                        <p
                          className="centered-text ms-2 mt-3 "
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          {/*     {tech.fullname} */}
                          {tech.fullname.length > 12
                            ? `${tech.fullname.slice(0, 12)}...`
                            : tech.fullname}
                          <span
                            style={{
                              color: tech.wallet_balance >= 0 ? "green" : "red",
                              float: "right",
                              marginRight: "5px",
                            }}
                          >
                            {tech.wallet_balance}
                          </span>
                          <span style={{ float: "right" }}>
                            <CurrencyRupeeIcon />
                          </span>
                          <br />
                          <Tooltip title={tech?.email}>
                            <span>
                              {tech?.email?.length <= 20
                                ? tech?.email
                                : `${tech?.email?.slice(0, 20)}...`}
                            </span>
                          </Tooltip>
                        </p>
                        <Tooltip title={`Remove ${tech.fullname}`}>
                          {tech.role == "2" ? (
                            <DeleteIcon
                              className="mt-4 me-4 custom-delete-icon"
                              style={{
                                color: "rgb(235, 129, 40)",
                                cursor: "pointer",
                                backgroundColor: "none",
                              }}
                              onClick={() => handleDelete(tech.id)}
                            />
                          ) : (
                            <div
                              className="mt-4"
                              style={{ marginRight: "35px" }}
                            ></div>
                          )}
                        </Tooltip>
                      </Stack>
                      <span
                        className="ms-5 mt-0 p-1"
                        style={{ fontSize: "13px", fontWeight: "500" }}
                      ></span>
                    </Card>
                  ))}
              </div>
            ) : (
              <div>
                <p className="text-center">No Records Found!</p>
              </div>
            )}
          </div>
          {userid ? (
            <>
              <div className="col-lg-8 custom-appliance-input-scrollbar">
                <div className={mobileMatches ? "column" : "row"}>
                  <div className="col-lg-2 mt-3">
                    <div
                      className="ms-lg-4"
                      style={{
                        margin: mobileMatches ? "0rem auto" : "0.1rem auto",
                        position: mobileMatches && "relative",
                        right: mobileMatches && "2rem",
                      }}
                    >
                      <img
                        alt="demo"
                        src={avatar ? avatar : profile}
                        className="rounded-circle img"
                        style={{ width: 100, height: 100 }}
                      />
                      {/*   <AddCircleOutlineIcon
                      fontSize="large"
                      className="rounded-circle circlebtn "
                      style={{ display: "none" }}
                    />
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={onImageChange}
                    /> */}
                    </div>
                  </div>
                  <div className={mobileMatches ? "column" : "col-lg-2"}>
                    {/*  <p style={{ fontSize: '11px', color: '#6A707C' }}> Username</p>
                    <p>C56897D</p> */}
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{
                        display: "flex",
                        flexDirection: mobileMatches && "column",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          margin: mobileMatches
                            ? "1rem auto 0rem auto"
                            : "4rem auto 0rem auto",
                        }}
                      >
                        <FormControlLabel
                          sx={{
                            display: "block",
                          }}
                          control={
                            <Switch
                              checked={checked}
                              style={{
                                cursor:
                                  clickedRole === "2"
                                    ? "pointer"
                                    : "not-allowed",
                              }}
                              onChange={clickedRole === "2" ? handleChange : ""}
                              color="warning"
                              {...label}
                            />
                          }
                        />

                        {checked ? (
                          <div>
                            {" "}
                            <p
                              className="mt-2"
                              style={{ color: "rgb(235, 129, 40)" }}
                            >
                              ACTIVE
                            </p>
                          </div>
                        ) : (
                          <p
                            className="mt-2"
                            style={{ color: "rgb(235, 129, 40)" }}
                          >
                            INACTIVE
                          </p>
                        )}
                      </Box>
                      <div
                        onClick={() =>
                          setRatingsModal(
                            userRatings?.user_ratings?.length > 0 && true
                          )
                        }
                        className="viewreview"
                        style={{
                          margin: mobileView ? "0px auto" : "66px 0px 0px 30px",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                      >
                        <HalfRatings
                          rating={userRatings?.average_rating}
                          fontSize={"20px"}
                          isEditable
                        />
                      </div>
                      {/* {userRatings?.user_ratings && (
                        <button
                          className="res-open"
                          style={{
                            cursor: "pointer",
                            color: "rgb(235, 129, 40)",
                            backgroundColor: "transparent",
                            border: "1px solid transparent",

                          }}
                          onClick={() =>
                            setRatingsModal(
                              userRatings?.user_ratings?.length > 0 && true
                            )
                          }
                        >
                          <FaStar
                            size={20}
                            style={{
                              color: "rgb(235, 129, 40)",
                            }}
                          />
                          <span>
                            {userRatings?.average_rating
                              ? userRatings?.average_rating
                              : 0}
                          </span>
                        </button>
                      )} */}
                    </Stack>
                  </div>
                </div>
                <div className="row ms-lg-3 mt-3">
                  <div className="col-lg-6 mt-lg-0">
                    <div>
                      <TextField
                        fullWidth
                        label="Full Name"
                        id="full-name"
                        value={name}
                        type="text"
                        placeholder="Customer Name"
                        error={IsFullNameEmpty ? true : false}
                        helperText={
                          IsFullNameEmpty ? "please fill fullname" : ""
                        }
                        FormHelperTextProps={{}}
                        InputProps={{
                          readOnly: true,
                        }}
                        disabled
                      />
                    </div>

                    <div className="mt-lg-4 mt-3">
                      <TextField
                        fullWidth
                        style={{ height: "56px" }}
                        id="email"
                        value={email}
                        aria-readonly={true}
                        label="Enter your email"
                        type="email"
                        InputProps={{
                          readOnly: true,
                        }}
                        disabled
                      />
                    </div>

                    <div>
                      <TextField
                        className="w-100 mt-3 mt-lg-3"
                        style={{ height: "47px" }}
                        id="mobile-number"
                        value={phoneno}
                        label="Enter your phone number"
                        type="number"
                        placeholder=" 90000 00009"
                        error={!!mobileError}
                        helperText={mobileError}
                        FormHelperTextProps={{
                          style: {
                            position: "absolute",
                            bottom: "-20px",
                            whiteSpace: "nowrap",
                          },
                        }}
                        InputProps={{
                          readOnly: true,
                        }}
                        disabled
                      />
                    </div>
                    <div>
                      <div className="row mt-lg-4 mt-3">
                        <div
                          className="col-12 col-md-6"
                          style={{ marginTop: mobileView && "1rem" }}
                        >
                          <TextField
                            InputLabelProps={{
                              shrink: true,
                            }}
                            className="res-font"
                            fullWidth
                            id="datePicker"
                            label="Date of birth"
                            type="date"
                            value={dob}
                            error={IsDobEmpty ? true : false}
                            helperText={IsDobEmpty ? "please select Dob" : ""}
                            InputProps={{
                              readOnly: true,
                            }}
                            disabled
                            style={{
                              height: "47px",
                              margin: mobileMatches ? "auto" : "0",
                            }}
                          />
                        </div>
                        <div
                          className="col-12 col-md-6"
                          style={{ marginTop: mobileView && "1rem" }}
                        >
                          <Button
                            // variant="outlined"
                            style={{
                              height: "45px",
                              color: "black",
                              backgroundColor:
                                storeImage == null || storeImage?.length === 0
                                  ? "lightgray"
                                  : "",
                              textTransform: "none",
                              width: "100%",
                              border: "1px solid gray",
                            }}
                            // className="cancel-btn"
                            onClick={() => setImageModal(true)}
                            disabled={
                              storeImage == null || storeImage?.length === 0
                            }
                          >
                            {/* <div className="d-flex align-items-center gap-1"> */}
                            {/* <div className="d-flex align-items-center font-size-2"><ImCancelCircle /></div> */}
                            {/* <div className="d-flex align-items-center font-weight-2"> */}
                            Shop Images
                            {/* </div> */}
                            {/* </div> */}
                          </Button>
                          {imageModal && (
                            <Modal
                              className="imageModal"
                              show={imageModal}
                              onHide={() => setImageModal(false)}
                            >
                              <div
                                style={{
                                  backgroundColor: "white",
                                  width: "100%",
                                  maxWidth: "600px",
                                  overflow: "hidden",
                                  margin: "auto",
                                  borderRadius: "15px",
                                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                  padding: "20px",
                                }}
                              >
                                <div className="p-3">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div style={{ fontSize: "20px" }}>
                                      {/* <div>Attached Image</div> */}
                                    </div>
                                    <div onClick={() => setImageModal(false)}>
                                      <MdClose
                                        style={{
                                          cursor: "pointer",
                                          color: "rgb(235, 129, 40)",
                                          position: "relative",
                                          left: "20px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="image-container"
                                    style={{
                                      overflowX: "hidden",
                                      maxWidth: "80vw",
                                      margin: "auto",
                                      whiteSpace: "nowrap",
                                      position: "relative",
                                    }}
                                  >
                                    {storeImage &&
                                      storeImage.map((imageSrc, index) => (
                                        <img
                                          key={index}
                                          src={imageSrc}
                                          alt="https://images.unsplash.com/photo-1704165873660-884ac0494138?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw0fHx8ZW58MHx8fHx8"
                                          style={{
                                            cursor: "pointer",
                                            height: "300px",
                                            width: "100%",
                                            // marginLeft: '67px',
                                            objectFit: 'contain',
                                            display:
                                              currentIndex === index
                                                ? "block"
                                                : "none",
                                          }}
                                          onClick={() =>
                                            handleImageClick(index)
                                          }
                                        />
                                      ))}
                                    <Button
                                      variant="link"
                                      className="arrow-button left"
                                      onClick={showPreviousImage}
                                      style={{
                                        position: "absolute",
                                        top: "40%",
                                        left: "10px",
                                        color: "rgb(235, 129, 40)",
                                        fontSize: "30px",
                                      }}
                                    >
                                      <MdKeyboardArrowLeft />
                                    </Button>
                                    <Button
                                      variant="link"
                                      className="arrow-button right"
                                      onClick={showNextImage}
                                      style={{
                                        position: "absolute",
                                        top: "40%",
                                        right: "10px",
                                        color: "rgb(235, 129, 40)",
                                        fontSize: "30px",
                                      }}
                                    >
                                      <MdKeyboardArrowRight />
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </Modal>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className="row m-0 p-0 border border rounded-3 mt-3 mt-lg-3"
                      style={{ height: "54px" }}
                    >
                      <div
                        className="col border-right p-lg-2"
                        style={{
                          borderRight: "1px solid grey ",
                          height: "52px",
                          color: Ismale ? "rgb(235, 129, 40)" : "black",
                          cursor: "not-allowed",
                        }}
                      >
                        <IconButton
                          color="primary"
                          component="label"
                          style={{
                            cursor: "not-allowed",
                          }}
                        >
                          <img alt="demo" src={Ismale ? maleclor : male} />
                          <input
                            checked={Ismale}
                            type="radio"
                            InputProps={{
                              readOnly: true,
                            }}
                            style={{ display: "none" }}
                          />
                        </IconButton>
                        Male
                      </div>
                      <div
                        className="col p-lg-2"
                        style={{
                          color: IsFemale ? "rgb(235, 129, 40)" : "black",

                          cursor: "not-allowed",
                        }}
                      >
                        <IconButton
                          color="primary"
                          component="label"
                          style={{
                            cursor: "not-allowed",
                          }}
                        >
                          <img
                            alt="demo"
                            src={IsFemale ? femaleclor : female}
                          />
                          <input
                            checked={IsFemale}
                            InputProps={{
                              readOnly: true,
                            }}
                            type="radio"
                            style={{ display: "none" }}
                          />
                        </IconButton>
                        Female
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-12 mt-lg-0 mt-3">
                    <div className="mt-lg-0 mt-3">
                      <TextField
                        fullWidth
                        label="Country"
                        value={country}
                        InputProps={{
                          readOnly: true,
                        }}
                        aria-readonly={true}
                        disabled
                      />
                    </div>

                    <div className="mt-lg-4 mt-3">
                      <TextField
                        fullWidth
                        label="State"
                        value={state}
                        InputProps={{
                          readOnly: true,
                        }}
                        disabled
                        aria-readonly={true}
                      />
                    </div>

                    <div className="mt-lg-4 mt-3">
                      <TextField
                        fullWidth
                        label="Address Line 1"
                        type="text"
                        InputProps={{
                          readOnly: true,
                        }}
                        disabled
                        value={address}
                        placeholder="Type your door no, street name"
                      />
                    </div>

                    {/*  <div className='mt-lg-4 mt-3'>
                    <TextField
                        fullWidth
                        label="Address Line 2"
                        value={city}
                      
                        type='text'
                        placeholder='Nearest Landmark'
                    />
                </div> */}

                    <div className="row mt-lg-4 mt-3">
                      <div className="col-6">
                        <TextField
                          fullWidth
                          label="City"
                          value={city}
                          type="text"
                          InputProps={{
                            readOnly: true,
                          }}
                          disabled
                          placeholder="Nearest Landmark"
                        />
                      </div>

                      <div className="col-6">
                        <div>
                          <TextField
                            fullWidth
                            label="Postal Code"
                            value={postal_code}
                            type="text"
                            error={IsPostalEmpty ? true : false}
                            helperText={
                              IsPostalEmpty ? "please enter postal" : ""
                            }
                            InputProps={{
                              readOnly: true,
                            }}
                            disabled
                            FormHelperTextProps={{}}
                          />
                        </div>
                      </div>
                      <div className="mt-lg-4 mt-3">
                        <div>
                          <div className="label-popover">
                            <div className="content">
                              Appliance(s) the technician can repair
                            </div>
                          </div>
                          <div
                            onClick={(e) => handlePopoverOpen(e)}
                            className="d-flex align-items-center gap-2 popover-input w-full ps-2"
                          >
                            {selectedApplianceData?.length === 0 && (
                              <div className="text-3">
                                Validate the appliance(s)
                              </div>
                            )}
                            {selectedApplianceDataInput.map((applianceData) => {
                              const returnBadge = availableAppliances?.appliances?.find(
                                (appliance) =>
                                  appliance?.id === applianceData?.appliance_id
                              );
                              return (
                                <div className="badge">
                                  {applianceData?.model_id
                                    ? ""
                                    : returnBadge?.appliance_name}
                                  {applianceData?.model_id &&
                                    returnBadge?.appliance_name}
                                </div>
                              );
                            })}
                          </div>
                          <Popover
                            open={popoverOpen}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            className="appliances-popover"
                          >
                            {availableAppliances?.appliances?.length > 0 &&
                              availableAppliances?.appliances?.map(
                                (applianceValue) => {
                                  const findModels =
                                    availableAppliances?.models?.length > 0
                                      ? availableAppliances?.models?.some(
                                        (modalValue) =>
                                          modalValue.appliance_id ===
                                          applianceValue.id
                                      )
                                      : false;
                                  const getCheckAppliance = () => {
                                    if (
                                      labelUserSelectedAppliances?.some(
                                        (selectedAppliancesValue) =>
                                          selectedAppliancesValue?.id ===
                                          applianceValue?.id
                                      )
                                    ) {
                                      return true;
                                    } else if (
                                      labelUserSelectedModels?.some(
                                        (selectedModelsValue) =>
                                          selectedModelsValue?.appliance_id ===
                                          applianceValue?.id
                                      )
                                    ) {
                                      return true;
                                    } else {
                                      return false;
                                    }
                                  };
                                  return (
                                    <div>
                                      <div className="d-flex align-items-center gap-1">
                                        {/* <Checkbox
                                          checked={getCheckAppliance()}
                                          onChange={() => {
                                            if (
                                              labelUserSelectedAppliances?.some(
                                                (appliance) =>
                                                  appliance?.id ===
                                                  applianceValue?.id
                                              )
                                            ) {
                                              const updatedUserSelectedAppliances = labelUserSelectedAppliances.filter(
                                                (item) =>
                                                  item?.id !==
                                                  applianceValue?.id
                                              );
                                              setLabelUserSelectedAppliances(
                                                updatedUserSelectedAppliances
                                              );
                                            } else if (
                                              labelUserSelectedAppliances?.some(
                                                (appliance) =>
                                                  appliance?.id !==
                                                  applianceValue?.id
                                              )
                                            ) {
                                              const updatedUserSelectedAppliance = availableAppliances.appliances.find(
                                                (item) =>
                                                  item?.id ===
                                                  applianceValue?.id
                                              );
                                              setLabelUserSelectedAppliances([
                                                ...labelUserSelectedAppliances,
                                                updatedUserSelectedAppliance,
                                              ]);
                                            } else {
                                              const updatedUserSelectedAppliance = availableAppliances.appliances.find(
                                                (item) =>
                                                  item?.id ===
                                                  applianceValue?.id
                                              );
                                              setLabelUserSelectedAppliances([
                                                updatedUserSelectedAppliance,
                                              ]);
                                            }
                                          }}
                                          disabled={findModels ? true : false}
                                        /> */}
                                        <Checkbox
                                          checked={getCheckAppliance()}
                                          onChange={(event) => handleApplianceChange(applianceValue, event.target.checked)}
                                          disabled={findModels ? true : false}
                                        />
                                        <div>
                                          {applianceValue.appliance_name}
                                        </div>
                                      </div>
                                      <div className="d-flex align-items-center gap-2 ps-4 pe-4">
                                        {availableAppliances?.models?.length >
                                          0 &&
                                          availableAppliances?.models?.map(
                                            (modelValue) => {
                                              const getCheckModel = () => {
                                                const isUserSelected = labelUserSelectedModels.some(
                                                  (userSelectedModel) =>
                                                    userSelectedModel?.id ===
                                                    modelValue.id
                                                );
                                                return isUserSelected;
                                              };
                                              if (
                                                modelValue.appliance_id ===
                                                applianceValue.id
                                              ) {
                                                return (
                                                  <div className="d-flex align-items-center gap-1">
                                                    {/* <Checkbox
                                                      checked={getCheckModel()}
                                                      onChange={() => {
                                                        if (
                                                          labelUserSelectedModels?.some(
                                                            (model) =>
                                                              model?.id ===
                                                              modelValue?.id
                                                          )
                                                        ) {
                                                          const updatedUserSelectedModels = labelUserSelectedModels.filter(
                                                            (item) =>
                                                              item?.id !==
                                                              modelValue?.id
                                                          );
                                                          getUniqueAppliance(
                                                            labelUserSelectedAppliances,
                                                            updatedUserSelectedModels
                                                          );
                                                          setLabelUserSelectedModels(
                                                            updatedUserSelectedModels
                                                          );
                                                        } else if (
                                                          labelUserSelectedModels?.some(
                                                            (model) =>
                                                              model?.id !==
                                                              modelValue?.id
                                                          )
                                                        ) {
                                                          const updatedUserSelectedModel = availableAppliances.models.find(
                                                            (item) =>
                                                              item?.id ===
                                                              modelValue?.id
                                                          );
                                                          const updatedUserSelectedModels = [
                                                            ...labelUserSelectedModels,
                                                            updatedUserSelectedModel,
                                                          ];
                                                          setLabelUserSelectedModels(
                                                            [
                                                              ...labelUserSelectedModels,
                                                              updatedUserSelectedModel,
                                                            ]
                                                          );
                                                          getUniqueAppliance(
                                                            labelUserSelectedAppliances,
                                                            updatedUserSelectedModels
                                                          );
                                                        } else {
                                                          const updatedUserSelectedModel = availableAppliances.models.find(
                                                            (item) =>
                                                              item?.id ===
                                                              modelValue?.id
                                                          );
                                                          setLabelUserSelectedModels(
                                                            [
                                                              updatedUserSelectedModel,
                                                            ]
                                                          );
                                                        }
                                                      }}
                                                    /> */}
                                                    <Checkbox
                                                      checked={getCheckModel()}
                                                      onChange={(event) => handleModelChange(modelValue, event.target.checked)}
                                                    />
                                                    <div>
                                                      {
                                                        modelValue.appliance_model
                                                      }
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            }
                                          )}
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                          </Popover>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-1"></div>
                </div>
                <div className="border border-bottom mt-3"></div>
                <div className="row mt-lg-4 mt-3">
                  <div className="col"></div>
                  <div className="col text-end">
                    {userid ? (
                      <ul className="p-0" style={{ display: "inline-flex" }}>
                        <li style={{ listStyle: "none" }}></li>
                        {/* <li className='me-2' style={{ listStyle: 'none' }}>
                      <Button variant="outlined" style={{ height: '45px', width: '120px', color: 'rgb(235, 129, 40)', border: '1px soid rgb(235, 129, 40)', textTransform: 'none' }}>Cancel</Button>
                  </li> */}
                        <li className="me-2" style={{ listStyle: "none" }}>
                          <Button
                            onClick={handleApprove}
                            variant="contained"
                            type="submit"
                            style={{
                              height: "45px",
                              color: "white",
                              backgroundColor: "#EB8128",
                              textTransform: "none",
                            }}
                          >
                            Save Profile
                          </Button>
                        </li>
                      </ul>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <Modal
                show={ratingsModal}
                size="md"
                onHide={() => setRatingsModal(false)}
                centered
                className="filter-modal"
                dialogClassName="modal-scroll"
              >
                <div className="p-2">
                  <div>
                    <b>Ratings & Reviews</b>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <div>
                          <HalfRatings
                            rating={userRatings?.average_rating}
                            fontSize={"26px"}
                          />
                        </div>
                        <div style={{ marginTop: "5px" }}>
                          {userRatings?.average_rating} out of 5
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      maxHeight: "300px",
                    }}
                  >
                    {userRatings?.user_ratings?.map((item, index) => {
                      return (
                        <div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            key={index}
                          >
                            <Avatar
                              className="centered-text mt-3 ms-2"
                              src={
                                item?.reviewer_image
                                  ? item?.reviewer_image
                                  : stringAvatar(item?.reviewer_name)
                              }
                              {...stringAvatar(item?.reviewer_name)}
                            />
                            <div
                              style={{
                                marginTop: "10px",
                                marginLeft: "2px",
                              }}
                            >
                              <div>
                                <span style={{}}>
                                  <b>{item?.reviewer_name}</b>
                                </span>
                                <span
                                  style={{
                                    fontSize: "11px",
                                    marginLeft: "6px",
                                  }}
                                >
                                  on{" "}
                                  {moment(item?.created_at).format("MMM, YYYY")}
                                </span>
                              </div>
                              <Rating
                                name="read-only"
                                value={item?.rating_value}
                                precision={0.5}
                                readOnly
                              />
                            </div>
                          </div>
                          <div style={{ marginLeft: "50px" }}>
                            <text style={{ fontSize: "13px" }}>
                              {showFullReviews[index]
                                ? item?.comment
                                : `${item?.comment.slice(0, 177)}`}
                            </text>
                            {item?.comment?.length > 177 && (
                              <text
                                style={{
                                  color: "rgb(235, 129, 40)",
                                  cursor: "pointer",
                                  fontSize: "12px",
                                }}
                                onClick={() => {
                                  const updatedShowFullReviews = [
                                    ...showFullReviews,
                                  ];
                                  updatedShowFullReviews[
                                    index
                                  ] = !updatedShowFullReviews[index];
                                  setShowFullReviews(updatedShowFullReviews);
                                }}
                              >
                                {showFullReviews[index]
                                  ? " Show Less"
                                  : " ...Show More"}
                              </text>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Modal>
            </>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};
export default AllCustomers;
