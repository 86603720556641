import React, { useEffect, useState } from "react";
import reviews from "../../Assets/Home/reviews.svg";
// import reviewer from "../../Assets/Home/reviewer.svg";
import CmacCardFollower from "../../plugins/cmac-card-follower/CmacCardFollower";
import CmacCardContainer from "../../plugins/cmac-card-follower/CmacCardContainer";
import { Avatar } from "@mui/material";

const Reviews = () => {
  const [btnSelected, setBtnSelected] = useState(false);
  const [fadeIndex, setFadeIndex] = useState(null);
  const [cards, setCards] = useState([
    {
      id: 0,
      bg: "white",
      posLeft: 0,
      posTop: 0,
      zIndex: 4,
      content:
        "“I highly recommend S3 service to anyone in need of CCTV servicing.  The payment process was straightforward and hassle-free.”",
      name: "Arumugam,",
      location: "Batlagundu.",
    },
    {
      id: 1,
      bg: "white",
      posLeft: 20,
      posTop: 20,
      zIndex: 3,
      content:
        "“I'm writing to express my satisfaction and gratitude to S3 with the repair service I received for my 10-year-old LED TV. ”",
      name: "Rajendiran,",
      location: "Chennai.",
    },
    {
      id: 2,
      bg: "white",
      posLeft: 40,
      posTop: 40,
      zIndex: 2,
      content:
        "“I booked service request in S3 to reinstall my TV. The process was incredibly easy. Trust me, you won't regret trying this!”",
      name: "Karthi,",
      location: "Pazhani.",
    },
    {
      id: 3,
      bg: "white",
      posLeft: 60,
      posTop: 60,
      zIndex: 1,
      content:
        "“The technician was friendly and professional. He also provided me with useful tips on how to handle the TV to increase its lifetime.”",
      name: "Boopesh,",
      location: "Karur.",
    },
    {
      id: 4,
      bg: "white",
      posLeft: 80,
      posTop: 80,
      zIndex: 0,
      content:
        "“S3 technician arrived on time, assessed the issue promptly, and efficiently resolved it. Thanks to the seamless service.”",
      name: "Aravinth,",
      location: "Dindigul.",
    },
  ]);
  const handleChangeCardPos = () => {
    const updatedCards = cards.map((card) => {
      if (card.posTop === 0) {
        return { ...card, posLeft: 80, posTop: 80, zIndex: 1, fade: false };
      } else if (card.posTop === 80) {
        return { ...card, posLeft: 60, posTop: 60, zIndex: 2, fade: false };
      } else if (card.posTop === 60) {
        return { ...card, posLeft: 40, posTop: 40, zIndex: 3, fade: false };
      } else if (card.posTop === 40) {
        return { ...card, posLeft: 20, posTop: 20, zIndex: 4, fade: false };
      } else if (card.posTop === 20) {
        return { ...card, posLeft: 0, posTop: 0, zIndex: 5, fade: false };
      } else {
        return card;
      }
    });
    setTimeout(() => {
      setBtnSelected(false);
    }, 500);
    setCards(updatedCards);
  };
  const handleCard = (method) => {
    setBtnSelected(true);
    if (!btnSelected) {
      if (method === "n") {
        const updatedCards = cards.map((card, index) => {
          if (card.posTop === 0) {
            setFadeIndex(index);
            return {
              ...card,
              posTop: -100,
              posLeft: 0,
              zIndex: 5,
              fade: false,
            };
          } else if (card.posTop === 80) {
            return { ...card, posLeft: 80, posTop: 80, zIndex: 1, fade: false };
          } else if (card.posTop === 60) {
            return { ...card, posLeft: 60, posTop: 60, zIndex: 2, fade: false };
          } else if (card.posTop === 40) {
            return { ...card, posLeft: 40, posTop: 40, zIndex: 3, fade: false };
          } else if (card.posTop === 20) {
            return { ...card, posLeft: 20, posTop: 20, zIndex: 4, fade: false };
          } else {
            return card;
          }
        });
        setCards(updatedCards);
        setTimeout(() => {
          handleChangeCardPos();
        }, 500);
      }
    }
  };
  function stringToColor(string) {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: name ? `${name.split(" ")[0][0]}` : "",
    };
  }
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!btnSelected) {
        handleCard("n");
      }
    }, 2500);

    return () => clearInterval(intervalId);
  }, [btnSelected]);

  return (
    <div className="reviews-container" style={{ position: "relative" }}>
      <div>
        <div
          style={{ color: "black", fontSize: "34px", fontWeight: 600 }}
          className="res-576px-font-25px res-576px-text-align-center"
        >
          What people say about Us.
        </div>
        <CmacCardFollower
          className="reviews-card-container  res-576px-w-fill"
          style={{ position: "relative" }}
        >
          {cards.map((card, index) => {
            return (
              <CmacCardContainer
                bg={card.bg}
                position={{ left: card.posLeft, top: card.posTop }}
                zIndex={card.zIndex}
                className={`${fadeIndex === index ? "card-item-fade" : ""}`}
              >
                <Avatar
                  style={{
                    position: "absolute",
                    top: "-20px",
                    left: "-20px",
                    height: "50px",
                    width: "50px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "22px",
                  }}
                  className=""
                  {...stringAvatar(card.name)}
                />
                <div>
                  <div className="mb-3" style={{ fontSize: "11px" }}>
                    {card.content}
                  </div>
                  <div
                    style={{ fontSize: "12px", fontWeight: 600 }}
                    className="mb-1"
                  >
                    {card.name}
                  </div>
                  <div style={{ fontSize: "11px" }}>{card.location}</div>
                </div>
              </CmacCardContainer>
            );
          })}
        </CmacCardFollower>
      </div>

      <img src={reviews} alt="reviews" />
    </div>
  );
};

export default Reviews;
