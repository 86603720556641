import React, { useEffect, useState } from "react";
import Logo from "../../Images/Logo.png";
import { Paper } from "@mui/material";
import "../../common.css";
import { Link, useNavigate } from "react-router-dom";
import S3NewLogo from "../../Assets/s3_new_logo/S3NewLogo";
const CommonLogin = () => {
  const [hovered, setHovered] = useState(false);
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Login With us';
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        'content',
        'To book a service request for a repair shop...'
      );
  }, []);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  const handleMousein = () => {
    setHover(true);
  };

  const handleMouseaway = () => {
    setHover(false);
  };
  const handleClick = () => {
    // if (hovered) {
    //   // Redirect to customerLogin page
    //   // window.location.replace('/customerLogin');
    navigate("/Login", {
      state: {
        type: "Customer",
      },
    });
    // }
  };

  const handleClickTech = () => {
    // if (hover) {
    // Redirect to technicianLogin page
    // window.location.replace('/technicianLogin');
    navigate("/Login", {
      state: {
        type: "Technician",
      },
    });
    // }
  };
  return (
    <>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-lg-5 col-12 p-0">
            <div className="logo_container" style={{ position: "fixed" }}>
              <img
                alt="demo"
                src={Logo}
                className="w-100 logo"
                style={{ height: "100vh" }}
              />
            </div>
          </div>
          <div className="col-lg-7 col-12 p-4">
            <div className="container p-lg-5">
              <div onClick={() => navigate("/")}>
                <S3NewLogo
                  color={"rgba(235, 129, 40, 1)"}
                  style={{ position: "relative", left: "38px", scale: "1.4" }}
                />
              </div>

              <p
                className="m-0 mt-lg-5 mt-2"
                style={{
                  fontSize: "30px",
                  fontWeight: "700",
                  color: "#1E232C",
                }}
              >
                Login With us
              </p>
              <p
                className="m-0 mt-lg-3 mt-2"
                style={{
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "#6A707C",
                }}
              >
                To Login with us, tell us what role you'd be opening.
              </p>
              <Paper
                className="mt-lg-4 mt-3"
                elevation={2}
                style={{
                  height: "108px",
                  width: "100%",
                  cursor: "pointer",
                  backgroundColor: hovered ? "#FFE5CC" : undefined,
                  // opacity: 0.5,
                  cursor: "pointer",
                }}
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <div className="row" style={{ height: "108px", width: "100%" }}>
                  <div className="col-10">
                    <ul
                      className="p-0 mt-lg-3 mt-2"
                      style={{ display: "inline-flex" }}
                    >
                      <li
                        className="ms-lg-3 ms-2"
                        style={{
                          listStyle: "none",
                          color: "rgb(235, 129, 40)",
                        }}
                      >
                        <i className="bi bi-person fs-1"></i>
                      </li>
                      <li
                        className="ms-lg-4 ms-2"
                        style={{ listStyle: "none" }}
                      >
                        <text style={{ fontWeight: "500" }}>As Customer</text>
                        <br />
                        <text style={{ color: "#6A707C" }}>
                          To book a service request for a repair shop
                        </text>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="col-2"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "rgb(235, 129, 40)",
                    }}
                  >
                    <div>
                      {hovered && <i className="bi bi-arrow-right fs-6"></i>}
                    </div>
                  </div>
                </div>
              </Paper>

              <Paper
                className="mt-lg-4 mt-3"
                elevation={2}
                style={{
                  height: "108px",
                  width: "100%",
                  cursor: "pointer",
                  backgroundColor: hover ? "#FFE5CC" : undefined,
                }}
                onMouseEnter={handleMousein}
                onMouseLeave={handleMouseaway}
                onClick={handleClickTech}
              >
                <div className="row" style={{ height: "108px", width: "100%" }}>
                  <div className="col-10">
                    <ul
                      className="p-0 mt-lg-3 mt-2"
                      style={{ display: "inline-flex" }}
                    >
                      <li
                        className="ms-lg-3 ms-2"
                        style={{
                          listStyle: "none",
                          color: "rgb(235, 129, 40)",
                        }}
                      >
                        <i className="bi bi-person fs-1"></i>
                      </li>
                      <li
                        className="ms-lg-4 ms-2"
                        style={{ listStyle: "none" }}
                      >
                        <text style={{ fontWeight: "500" }}>As Technician</text>
                        <br />
                        <text style={{ color: "#6A707C" }}>
                          To repair all the electronic appliances
                        </text>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="col-2"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "rgb(235, 129, 40)",
                    }}
                  >
                    <div>
                      {hover && <i className="bi bi-arrow-right fs-6"></i>}
                    </div>
                  </div>
                </div>
              </Paper>
              <p className="m-0 mt-lg-5 mt-3">
                Don't have an account?
                <Link
                  to="/commonRegister"
                  style={{
                    color: "#EB8128",
                    fontWeight: "500",
                    fontSize: "15px",
                    textDecoration: "none",
                  }}
                >
                  {" "}
                  Register Now
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonLogin;
