import React, { useState } from "react";
import Topbar from "../../topbar";
import Sidebar from "../../Sidebar";
import { AppBar, Tabs, Tab, Box, useMediaQuery } from "@mui/material";
import "../../../layout.css";
import PendingTechniciansList from "./PendingTechniciansList";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import "../../../styles/techverify.css";
import { useNavigate } from "react-router-dom";

const PendingTechniciansHome = () => {
  const [selectedTab1, setSelectedTab1] = React.useState(0);
  const [hovered, setHovered] = useState(null);
  const [serviceRequestId, setServiceRequestId] = useState("");
  const [IsOpen, setIsOpen] = useState(true);
  const [SRList, setSRList] = useState([]);
  const [IsOpenEdit, setIsOpenEdit] = useState(false);
  const [SrDetailArray, setSRDetailArray] = useState([]);
  const [serviceCreatedTime, setServiceCreatedTime] = useState("");
  const [serviceStatus, setServiceStatus] = useState("");
  const [IsSLA, setIsSLA] = useState(false);
  const [IsClosureDetails, setIsClosureDetails] = useState(false);
  const [IsPaymentDetails, setIsPaymentDetails] = useState(false);
  const [IsService, setIsService] = useState(false);
  const [IsVisit, setIsVisit] = useState(false);
  const navigate = useNavigate();

  const TabPanel = ({ value, index, children }) => {
    return (
      <div role="tabpanel" hidden={value !== index}>
        {value === index && <Box className="ps-2 pb-2">{children}</Box>}
      </div>
    );
  };
  const mobileView = useMediaQuery("(min-width: 200px) and (max-width: 799px)");
  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <Topbar />
        <div
          className="row container-fluid"
          style={{ paddingRight: "0px", overflow: "hidden" }}
        >
          <div className="col-2 col-12 p-0 border border-right">
            <Sidebar />
          </div>
          <div
            className="col-12 user_management"
            style={{ paddingRight: "0px", marginTop: "80px" }}
          >
            <div className="row">
              <div className="mt-lg-2">
                <i
                  className="bi bi-chevron-left"
                  onClick={() => navigate(-1)}
                  style={{
                    fontWeight: "600",
                    fontSize: "21px",
                    width: "20px",
                    height: "20px",
                    cursor: "pointer",
                  }}
                ></i>
                <text
                  className="p-0"
                  style={{
                    fontWeight: "600",
                    color: "#1E232C",
                    fontSize: "21px",
                    width: "208px",
                    height: "32px",
                  }}
                >
                  Verify Technicians
                </text>
              </div>
              <div className="container-fluid" style={{ overflow: "hidden" }}>
                <div className="row">
                  <div
                    className="col-lg-12 p-0 technicians_list_home"
                    style={{ paddingTop: "15px" }}
                  >
                    <div className="container-fluid" style={{ padding: "1px" }}>
                      <div className="mt-lg-12">
                        <AppBar
                          className="w-100"
                          style={{ boxShadow: "none" }}
                          position="static"
                        >
                          <Tabs value={selectedTab1} variant="fullWidth">
                            <Tab
                              style={{
                                fontSize: "14px",
                                backgroundColor: "white",
                                textTransform: "none",
                              }}
                              label=""
                            />
                          </Tabs>
                        </AppBar>
                        <TabPanel value={selectedTab1} index={0}>
                          <PendingTechniciansList />
                        </TabPanel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PendingTechniciansHome;
