import React, { useEffect } from "react";
import Footer from "../Footer";
import Navbar from "../common/Navbar";
import GetInTouch from "./GetInTouch";

export default function Contact() {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    const body = document.querySelector("body");
    body.classList.remove("res-nav-body-constructor");
  }, []);
  return (
    <>
      <Navbar />
      <div className="shade-provider"></div>
      <GetInTouch />
      <Footer />
    </>
  );
}
