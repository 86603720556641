import React, { useState, useRef } from "react";
import { Card, Paper } from "@mui/material";
import { Stack } from "@mui/material";
import { Box } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Rating, Link } from "@mui/material";
import { IconButton, Button } from "@mui/material";
import img1 from "../../../Images/t-img-1.png";
import { borderColor } from "@mui/system";
import { makeStyles } from "@material-ui/core/styles";
import { Popper, ClickAwayListener } from "@material-ui/core";
import Topbar from "../../topbar";
import Sidebar from "../../Sidebar";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    position: "relative",
  },
}));

const Techcart = () => {
  const classes = useStyles();
  const anchorRef1 = useRef(null);

  const [quantity, setQuantity] = useState(1);
  const [quantity1, setQuantity1] = useState(1);
  const [open1, setOpen1] = React.useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleClick1 = () => {
    setOpen1((prevOpen) => !prevOpen);
  };

  const handleClose1 = (event) => {
    if (anchorRef1.current && anchorRef1.current.contains(event.target)) {
      return;
    }

    setOpen1(false);
  };

  const handleQuantityChange1 = (amount) => {
    setQuantity1((prevQuantity) => Math.max(1, prevQuantity + amount));
  };

  const handleQuantityChange = (amount) => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity + amount));
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleOpen1 = () => {
    setOpen1(true);
  };

  const handleCancelClick = () => {
    setOpen1(false); // Close the Popper
    setIsChecked(false); // Reset checkbox state
  };

  return (
    <div className="container-fluid" style={{ overflow: "hidden" }}>
      <Topbar />
      <div className="row">
        {/* Sidebar */}
        <div
          className="col-lg-2 col-12 p-0 border border-left fixed-sidebar"
          style={{ height: "100vh" }}
        >
          <Sidebar open={true} />
        </div>

        {/* Main Content */}
        <div className="col-lg-10 p-0 Main_menu" style={{ paddingTop: "15px" }}>
          <div className="row">
            <div className="col-lg-6 col-12">
              <div className="mt-lg-3 ">
                <b>
                  <i
                    className="bi bi-chevron-left me-lg-1 fa fa-bold"
                    style={{ fontWeight: "500", fontSize: "21px" }}
                  ></i>
                </b>
                <text
                  className="p-0 fa fa-bold"
                  style={{
                    fontWeight: "500",
                    color: "#292929",
                    fontSize: "23px",
                  }}
                >
                  <b>Cart</b>
                </text>
              </div>

              <div className="ms-lg-2 mt-lg-4">
                <text
                  className="p-0 fa fa-bold "
                  style={{
                    fontWeight: "500",
                    color: "#292929",
                    fontSize: "17px",
                  }}
                >
                  <b>Total items added (6)</b>
                </text>
                <Paper className="w-100 h-50 mt-lg-3">
                  <div className="row ms-lg-1">
                    <div className="col-lg-6  col-12">
                      <img
                        className="ms-lg-3 w-75 h-50"
                        alt="demo"
                        src={img1}
                      />

                      {/* Quantity selection  */}
                      <div className="row align-items-center mt-lg-1">
                        <div className="col-lg-2 col-12">
                          <Stack direction="row" spacing={1}>
                            <p style={{ fontWeight: "500", fontSize: "15px" }}>
                              Qty
                            </p>
                            <p style={{ fontWeight: "500", fontSize: "15px" }}>
                              {quantity1}
                            </p>
                          </Stack>
                        </div>
                        <div className="col-lg-1 col-12 ">
                          <IconButton
                            onClick={() => handleQuantityChange1(1)}
                            style={{ width: 7, height: 7 }}
                          >
                            <i class="bi bi-chevron-up"></i>
                          </IconButton>

                          <IconButton
                            style={{ width: 7, height: 7 }}
                            onClick={() => handleQuantityChange1(-1)}
                            disabled={quantity1 === 1}
                          >
                            <i class="bi bi-chevron-down "></i>
                          </IconButton>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <p style={{ color: "#6A707C", fontSize: "12px" }}>
                        SKU: CA266
                      </p>
                      <p style={{ fontSize: "16px" }}>
                        CA-266 Input-12V-28V, 22-65
                        <br /> inch LED TV Backlight Driver...
                      </p>
                      <div className="row">
                        <div className="col-lg-2 col-12">
                          <Box sx={{ fontSize: 25 }}>
                            {/* <Starrating/> */}
                            <Rating name="size-lager" defaultValue={4} />
                          </Box>
                        </div>
                      </div>

                      <Stack spacing={0} direction="row">
                        <p style={{ fontWeight: "500", fontSize: "15px" }}>
                          ₹1017.36
                        </p>
                        <p style={{ color: "#6A707C", fontSize: "15px" }}>
                          /Unit
                        </p>
                      </Stack>

                      <Stack spacing={6} direction="row">
                        <p style={{ fontWeight: "500", fontSize: "12px" }}>
                          Delivery by Tomorrow, Tue
                        </p>
                        <i class="bi bi-trash" style={{ color: "#DC5353" }}></i>
                      </Stack>
                    </div>
                  </div>
                </Paper>
              </div>

              <div className="ms-lg-2 mt-lg-4">
                <Paper className="w-100">
                  <div className="row ms-lg-1">
                    <div className="col-lg-6 col-12">
                      <img
                        className="ms-lg-3 w-75 h-50"
                        alt="demo"
                        src={img1}
                      />

                      {/* Quantity selection  */}
                      <div className="row align-items-center mt-1">
                        <div className="col-lg-2 col-12 ">
                          <Stack direction="row" spacing={1}>
                            <p style={{ fontWeight: "500", fontSize: "15px" }}>
                              Qty
                            </p>
                            <p style={{ fontWeight: "500", fontSize: "15px" }}>
                              {quantity}
                            </p>
                          </Stack>
                        </div>
                        <div className="col-lg-1 col-12">
                          <IconButton
                            onClick={() => handleQuantityChange(1)}
                            style={{ width: 7, height: 7 }}
                          >
                            <i class="bi bi-chevron-up"></i>
                          </IconButton>

                          <IconButton
                            style={{ width: 7, height: 7 }}
                            onClick={() => handleQuantityChange(-1)}
                            disabled={quantity === 1}
                          >
                            <i class="bi bi-chevron-down "></i>
                          </IconButton>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <p style={{ color: "#6A707C", fontSize: "12px" }}>
                        SKU: CA266
                      </p>
                      <p style={{ fontSize: "16px" }}>
                        CA-266 Input-12V-28V, 22-65
                        <br /> inch LED TV Backlight Driver...
                      </p>
                      <div className="row">
                        <div className="col-lg-2 col-12">
                          <Box sx={{ fontSize: 25 }}>
                            {/* <Starrating/> */}
                            <Rating name="size-lager" defaultValue={4} />
                          </Box>
                        </div>
                      </div>

                      <Stack spacing={0} direction="row">
                        <p style={{ fontWeight: "500", fontSize: "15px" }}>
                          ₹1017.36
                        </p>
                        <p style={{ color: "#6A707C", fontSize: "15px" }}>
                          /Unit
                        </p>
                      </Stack>

                      <Stack spacing={6} direction="row">
                        <p style={{ fontWeight: "500", fontSize: "12px" }}>
                          Delivery by Tomorrow, Tue
                        </p>
                        <i class="bi bi-trash" style={{ color: "#DC5353" }}></i>
                      </Stack>
                    </div>
                  </div>
                </Paper>
              </div>
            </div>

            <div className="col-lg-5 ms-lg-4 col-12">
              <p
                className="mt-lg-4"
                style={{
                  fontWeight: "500",
                  color: "#292929",
                  fontSize: "15px",
                }}
              >
                <b>Deliver to: Home Address</b>
              </p>
              <div>
                <Stack className="mt-1 mb-4" direction="row" spacing={3}>
                  <p
                    className="mt-lg-3"
                    style={{ color: "#6A707C", fontSize: "13px" }}
                  >
                    Copy9, Angu nagar, Housing unit, Karur - 600026
                  </p>
                  <Button
                    ref={anchorRef1}
                    className=""
                    variant="outlined"
                    style={{
                      color: "black",
                      borderColor: "#EB8128",
                      textTransform: "none",
                    }}
                    onClick={handleOpen1}
                  >
                    Change
                  </Button>
                  <Popper
                    open={open1}
                    anchorEl={anchorRef1.current}
                    placement="bottom-end"
                    transition
                    disablePortal
                    style={{
                      zIndex: "1",
                      marginTop: "10px",
                      marginLeft: "940px",
                    }}
                  >
                    {({ TransitionProps }) => (
                      <ClickAwayListener onClickAway={handleClose1}>
                        <Paper
                          className="custom-scrollbar"
                          elevation={6}
                          style={{
                            width: "385px",
                            height: "425px",
                            overflow: "auto",
                          }} // Add overflow: 'auto' for scrolling
                        >
                          <div className="popper text-start">
                            <div className="row ">
                              <div className="col-lg-7 col-10 text-start">
                                <p
                                  className="m-0 mt-2 technicianDashbofont"
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "400",
                                  }}
                                >
                                  Notifications
                                </p>
                              </div>
                              <div className="col-lg-5 col-2 mt-lg-2 mt-3">
                                <div className="text-end ">
                                  <button
                                    type="button"
                                    style={{
                                      outline: "none",
                                      border: "none",
                                      backgroundColor: "white",
                                    }}
                                    onClick={handleCancelClick}
                                  >
                                    <CloseIcon style={{ cursor: "pointer" }} />
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div className="triangle"></div>
                            <div className="notification-content">
                              <div
                                className="notification-details border-bottom mt-lg-2 mt-1"
                                style={{ width: "315px" }}
                              >
                                <div className="checkbox-container">
                                  <div className="checkbox-container">
                                    <input
                                      type="checkbox"
                                      id="roundCheckbox"
                                      className="roundCheckboxInput"
                                      checked={isChecked}
                                      onChange={handleCheckboxChange}
                                    />
                                    <label
                                      htmlFor="roundCheckbox"
                                      className={`roundCheckboxLabel ${
                                        isChecked ? "checked" : ""
                                      }`}
                                    ></label>{" "}
                                  </div>
                                  <text>Home Address</text>
                                </div>
                                <p className="notification-text mt-lg-3 mt-2">
                                  H9, Angu nagar, Housing unit, Karur, Tamilnadu
                                  - 600 026
                                </p>
                              </div>
                              <div
                                className="notification-details border-bottom mt-lg-2 mt-1"
                                style={{ width: "315px" }}
                              >
                                <div className="checkbox-container">
                                  <div className="checkbox-container">
                                    <input
                                      type="checkbox"
                                      id="roundCheckbox"
                                      className="roundCheckboxInput"
                                      checked={isChecked}
                                      onChange={handleCheckboxChange}
                                    />
                                    <label
                                      htmlFor="roundCheckbox"
                                      className={`roundCheckboxLabel ${
                                        isChecked ? "checked" : ""
                                      }`}
                                    ></label>{" "}
                                  </div>
                                  <text>Home Address</text>
                                </div>
                                <p className="notification-text mt-lg-3 mt-2">
                                  H9, Angu nagar, Housing unit, Karur, Tamilnadu
                                  - 600 026
                                </p>
                              </div>
                              <div
                                className="notification-details border-bottom mt-lg-2 mt-1"
                                style={{ width: "315px" }}
                              >
                                <div className="checkbox-container">
                                  <div className="checkbox-container">
                                    <input
                                      type="checkbox"
                                      id="roundCheckbox"
                                      className="roundCheckboxInput"
                                      checked={isChecked}
                                      onChange={handleCheckboxChange}
                                    />
                                    <label
                                      htmlFor="roundCheckbox"
                                      className={`roundCheckboxLabel ${
                                        isChecked ? "checked" : ""
                                      }`}
                                    ></label>{" "}
                                  </div>
                                  <text>Home Address</text>
                                </div>
                                <p className="notification-text mt-lg-3 mt-2">
                                  H9, Angu nagar, Housing unit, Karur, Tamilnadu
                                  - 600 026
                                </p>
                              </div>

                              {/* <Paper elevation={4} className="notification-details mt-lg-4 mt-2" style={{ width: "315px" }}>
                                                <div className="checkbox-container">
                                                    <div className="checkbox-container">
                                                        <input
                                                            type="checkbox"
                                                            id="roundCheckbox"
                                                            className="roundCheckboxInput"
                                                            checked={isChecked}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                        <label htmlFor="roundCheckbox" className={`roundCheckboxLabel ${isChecked ? 'checked' : ''}`}></label>                                                    </div>
                                                    <text>Home Address</text>
                                                </div>
                                                <p className="notification-text mt-lg-3 mt-2">H9, Angu nagar, Housing unit,
                                                    Karur, Tamilnadu - 600 026</p>
                                            </Paper>
                                            <Paper elevation={4} className="notification-details mt-lg-4 mt-2" style={{ width: "315px" }}>
                                                <div className="checkbox-container">
                                                    <div className="checkbox-container">
                                                        <input
                                                            type="checkbox"
                                                            id="roundCheckbox"
                                                            className="roundCheckboxInput"
                                                            checked={isChecked}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                        <label htmlFor="roundCheckbox" className={`roundCheckboxLabel ${isChecked ? 'checked' : ''}`}></label>                                                    </div>
                                                    <text>Home Address</text>
                                                </div>
                                                <p className="notification-text mt-lg-3 mt-2">H9, Angu nagar, Housing unit,
                                                    Karur, Tamilnadu - 600 026</p>
                                            </Paper> */}
                            </div>
                          </div>
                          {/* </div> */}

                          {/* <TextField className="poppertext" size="small" label="Group_Name" defaultValue="Group_Name" variant="outlined" /> */}
                          {/* <FormControlLabel style={{ marginRight: "102px", marginTop: "5px" }} required control={<Checkbox />} label="Assign To Group" /> */}
                          {/* <Button style={{ marginRight: "60px", width: "90px" }} variant="outlined" onClick={handleClose1}>Close</Button>
                                                    <Button variant="contained">Add Group</Button> */}
                        </Paper>
                      </ClickAwayListener>
                    )}
                  </Popper>
                </Stack>
              </div>

              <div className="card w-100 ">
                <div className="mt-lg-4 ms-lg-4 mb-lg-4 me-lg-4">
                  <Stack
                    className="mb-lg-2 me-lg-2"
                    direction="row"
                    spacing={34}
                  >
                    <p>OFFER30</p>
                    <a href="#" style={{ color: "#EB8128" }}>
                      Apply
                    </a>
                  </Stack>
                  <div className="border border-bottom w-100  mb-lg-3"></div>
                  <p
                    className="mt-lg-3"
                    style={{
                      fontWeight: "500",
                      color: "#292929",
                      fontSize: "14px",
                    }}
                  >
                    <b>Payment Details</b>
                  </p>
                  <Stack className="mb-lg-4" direction="row" spacing={25}>
                    <p>Total Order (₹):</p>
                    <p>
                      <b>2034.72</b>
                    </p>
                  </Stack>
                  <Stack className="mb-lg-4" direction="row" spacing={22}>
                    <p>Delivery charges (₹):</p>
                    <p>
                      <b>30.00</b>
                    </p>
                  </Stack>
                  <Stack className="mb-lg-4" direction="row" spacing={28}>
                    <p>Discount (₹):</p>
                    <p style={{ color: "#36A93F" }}>
                      <b>-30.00</b>
                    </p>
                  </Stack>
                  <div className="border border-bottom w-100  mb-lg-5"></div>
                  <Stack
                    className="mb-lg-3 mt-lg-5"
                    direction="row"
                    spacing={20}
                  >
                    <p
                      style={{
                        fontWeight: "500",
                        color: "#292929",
                        fontSize: "18px",
                      }}
                    >
                      <b>Total Payable (₹)</b>
                    </p>
                    <p>
                      <b>2034.72</b>
                    </p>
                  </Stack>

                  <div class="d-grid gap-2 mt-lg-3 mb-lg-3">
                    <button
                      class="btn btn-lg"
                      type="button"
                      style={{
                        color: "white",
                        backgroundColor: "#EB8128",
                        borderColor: "#EB8128",
                      }}
                    >
                      Confirm Order
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-lg-1">
              <div className="col-lg-3 col-12"></div>
              <div className="col-lg-9 col-12 ">
                {/* carousel */}
                <section className="pt-lg-5 pb-lg-5 text-end">
                  <div className="container">
                    <Stack className="mb-lg-3" direction="row" spacing={45}>
                      <text
                        className="p-0 fa fa-bold"
                        style={{
                          fontWeight: "500",
                          color: "#292929",
                          fontSize: "18px",
                        }}
                      >
                        Similar Products (4)
                      </text>
                      <div className=" text-end">
                        <Stack direction="row" spacing={1}>
                          <IconButton
                            aria-label="fingerprint"
                            href="#carouselExampleIndicators2"
                            role="button"
                            backgroundColor="#EB8128"
                            data-bs-slide="prev"
                          >
                            <ChevronLeftIcon />
                          </IconButton>
                          <IconButton
                            aria-label="fingerprint"
                            color="#EB8128"
                            href="#carouselExampleIndicators2"
                            role="button"
                            data-bs-slide="next"
                          >
                            <ChevronRightIcon />
                          </IconButton>
                        </Stack>
                      </div>
                    </Stack>
                    <div className="row">
                      {/* <div className="col-lg-7 col-12"> */}
                      <div
                        id="carouselExampleIndicators2"
                        className="carousel slide"
                      >
                        <div className="carousel-inner mb-lg-4">
                          <div className="carousel-item active">
                            <div className="card">
                              <div className="row">
                                <div className="col-lg-4 col-12 h-100">
                                  <img
                                    className=" h-75 w-100"
                                    alt="demo"
                                    src={img1}
                                  />
                                </div>
                                <div className="col-lg-8 col-12">
                                  <text
                                    style={{
                                      fontSize: "14px",
                                      color: "#6A707C",
                                    }}
                                  >
                                    SKU: CA266
                                  </text>
                                  <br />
                                  <text>
                                    CA-266 Input-12V-28V, 22-65 inch LED TV
                                    Backlight Driver...
                                  </text>
                                  <br />
                                  <p style={{ fontSize: "14px" }}>
                                    ₹1017.36 /Unit{" "}
                                    <a href="" style={{ fontSize: "14px" }}>
                                      {" "}
                                      Add to Card
                                    </a>{" "}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="carousel-item">
                            <div className="card">
                              <div className="row">
                                <div className="col-lg-4 col-12 h-75">
                                  <img
                                    className=" h-75 w-100"
                                    alt="demo"
                                    src={img1}
                                  />
                                </div>
                                <div className="col-lg-8 col-12">
                                  <text
                                    style={{
                                      fontSize: "14px",
                                      color: "#6A707C",
                                    }}
                                  >
                                    SKU: CA266
                                  </text>
                                  <br />
                                  <text>
                                    CA-266 Input-12V-28V, 22-65 inch LED TV
                                    Backlight Driver...
                                  </text>
                                  <br />
                                  <p style={{ fontSize: "14px" }}>
                                    ₹1017.36 /Unit{" "}
                                    <a href="" style={{ fontSize: "14px" }}>
                                      {" "}
                                      Add to Card
                                    </a>{" "}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Techcart;
