import { createSlice } from "@reduxjs/toolkit";

const paymentOrderSlice = createSlice({
  name: "paymentOrder",
  initialState: {
    loading: false,
  },
  reducers: {
    paymentOrderRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    paymentOrderSuccess(state, action) {
      return {
        ...state,
        loading: false,
        paymentOrder: action.payload,
      };
    },
    paymentOrderFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    paymentOrderErrorClear(state, action) {
      return {
        ...state,
        loading: false,
        error: false,
      };
    },
  },
});

const { actions, reducer } = paymentOrderSlice;

export const {
  paymentOrderRequest,
  paymentOrderFail,
  paymentOrderSuccess,
  paymentOrderErrorClear,
} = actions;

export default reducer;
