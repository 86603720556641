import axios from "axios";
// import { DRAW_SERVICE_BASE_URL } from "../../api/apiBaseUrl";
import { BASE_URL } from "../../api/ApiPath";
import { CreateRewardRequest, CreateRewardFail, CreateRewardSuccess } from "../slices/createRewardSlice";
export const CreateReward = (id) => async (dispatch) => {
    const token = localStorage.getItem("token");
    let payload = id ? `draw_id=${id}&startingNumber=0000&limit=9999&onlySoldOut=false` : '';

    try {
        dispatch(CreateRewardRequest())
        const response = await axios.post(`${BASE_URL}/reward`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        dispatch(CreateRewardSuccess(response?.data))
        console.log("CreateRewardLists", response?.data);
    } catch (error) {
        console.log(error);
        dispatch(CreateRewardFail(error?.response?.data))
    }
}
