import React, { useState, useEffect } from "react";
import Logo from "../../Assets/Cust/register.svg";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Api from "../../service/services";
import { Link, useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "bootstrap";
import Swal from "sweetalert2";
import google from "../../Images/google_ic.png";
import facebook from "../../Images/facebook_ic.jpg";
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";
import S3NewLogo from "../../Assets/s3_new_logo/S3NewLogo";
import Footer from "../Footer";

const CommonRegister = () => {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password1, setPassword1] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [postalcode, setPostalcode] = useState("");

  const navigate = useNavigate();

  const [nameError, setNameError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password1Error, setPassword1Error] = useState("");
  const [confirmpasswordError, setConfirmpasswordError] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [IsEmpty, setIsEmpty] = useState(false);
  const [postalcodeError, setpostalcodeError] = useState("");
  const [allowAddAddress, setAllowAddAddress] = useState(false);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [postalError, setPostalError] = useState("");
  const [isPostalCodeOnChange, setIsPostalCodeOnChange] = useState(false);
  const [selectedCity, setSelectedCity] = useState("");
  const [citysError, setCitysError] = useState("");
  const [district, setDistrict] = useState("");
  const [invalidPostalCode, setInvalidPostalCode] = useState(false);
  const [citys, setCitys] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [districts, setDistricts] = useState([]);
  const [response, setResponse] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [checkbocError, setCheckbocError] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [referralStepper, setReferralStepper] = useState(false);
  const [referralActive, setReferralActive] = useState(false);
  const [referralDescription, setReferralDescription] = useState("");
  const [referralName, setreferralName] = useState("");
  const [referralMobile, setReferralMobile] = useState("");
  const [referralDescriptionError, setReferralDescriptionError] = useState("");
  const [referralnameError, setreferralnameError] = useState("");
  const [referralMobileError, setReferralMobileError] = useState("");
  const [basicDetailsStepper, setbasicDetailsStepper] = useState(true);
  const [passwordActive, setpasswordActive] = useState(false);
  const [passwordStepper, setPasswordStepper] = useState(false);
  const [referralSkipped, setReferralSkipped] = useState(false);
  const [tempSelectedOption, setTempSelectedOption] = useState("");
  const [refferalNameDisabled, setRefferalNameDisabled] = useState(false);

  const passwordStepperClick = () => {
    setReferralSkipped(false);
    if (tempSelectedOption === "") {
      setPasswordStepper(false);
      setbasicDetailsStepper(false);
      setpasswordActive(false);
      setReferralStepper(true);
      setReferralActive(true);
    } else if (tempSelectedOption === "Friend/Referral" && !referralName) {
      setPasswordStepper(false);
      setbasicDetailsStepper(false);
      setpasswordActive(false);
      setReferralStepper(true);
      setReferralActive(true);
    } else if (
      tempSelectedOption === "Friend/Referral" &&
      referralMobile &&
      !referralName
    ) {
      setPasswordStepper(false);
      setbasicDetailsStepper(false);
      setpasswordActive(false);
      setReferralStepper(true);
      setReferralActive(true);
    } else {
      setPasswordStepper(true);
      setbasicDetailsStepper(false);
      setpasswordActive(true);
      setReferralStepper(false);
      setReferralActive(true);
      setSelectedOption(tempSelectedOption);
    }
    //  else {
    //   console.log("working here!3");
    //   setbasicDetailsStepper(false);
    //   setPasswordStepper(false);
    //   setReferralStepper(true);
    //   setReferralActive(false);
    // }
  };

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setIsChecked(isChecked);
    if (!isChecked) {
      setCheckbocError(true);
    } else {
      setCheckbocError("");
    }
  };
  const getCityDetails = (state_id) => {
    Api.getCityByStateId(state_id).then((res) => {
      setCityData(res.data);
    });
  };
  useEffect(() => {
    if (isPostalCodeOnChange === true) {
      setTimeout(() => {
        setIsPostalCodeOnChange(false);
      }, 1000);
      if (postalcode?.length !== 6) setPostalError("Invalid Postal Code");
    }
  }, [isPostalCodeOnChange]);
  useEffect(() => {
    console.log("postalcode", postalcode);
    if (postalcode?.length === 6) {
      setPostalError("");
      handleCityData();
    } else {
      setState("");
      setCountry("");
      setCitys("");
      setDistrict("");
      setSelectedCity("");
      setInvalidPostalCode(true);
      // setPostalError('Invalid Postal Code');
    }
  }, [postalcode]);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const handleCityData = async () => {
    try {
      const response = await Api.getLocationByPincode(postalcode);
      setResponse(response);
      console.log("response", response);
      if (response?.error) {
        setPostalError(response?.error);
        setCitys("");
        setDistrict("");
        setSelectedCity("");
        setState("");
        setCountry("");
        setInvalidPostalCode(true);
        return; // Add return here to exit function early
      }

      let districtName = "",
        city_id = "";
      const cityData = response.map((item) => {
        districtName = item.district_name;
        city_id = item?.city_id;

        return item.location_name;
      });

      setCitys(cityData);
      setDistricts([...new Set(response.map((item) => item.district_name))]);
      setDistrict(districtName);
      setState("Tamil Nadu");
      setCountry("India");
      setCity(city_id);
      setInvalidPostalCode(false);
    } catch (error) {
      setPostalError("Invalid Postal Code");
      console.log("error:", error);
    }
  };

  const handleDistrictChange = (e) => {
    const selectedDistrict = e.target.value;
    setDistrict(selectedDistrict);

    // Find the first city_id associated with the selected district
    const selectedCityId =
      response.find((item) => item.district_name === selectedDistrict)
        ?.city_id || "";

    // Set the city to the found city_id
    setCity(selectedCityId);
  };

  useEffect(() => {
    getCityDetails(23);
  }, []);
  const handleCityChange = (e) => {
    const value = e.target.value;
    setSelectedCity(value);
    setCitysError(value ? "" : "City is required");
  };
  const passwordPattern =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!#%*?&]{8,}$/;
  const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.(in|com)$/;

  const validateForm = () => {
    let isValid = true;

    if (!name) {
      setNameError("Please enter your Full Name.");
      isValid = false;
    } else {
      setNameError("");
    }

    if (!mobile) {
      setMobileError("Please enter your Phone Number.");
      isValid = false;
    } else if (mobile.length !== 10) {
      setMobileError("Please enter a valid Phone Number.");
      isValid = false;
    } else {
      setMobileError("");
    }
    if (!isChecked) {
      setCheckbocError(
        "You must agree to the Terms of Use and Privacy Policy."
      );
      isValid = false;
    } else {
      setCheckbocError("");
    }
    if (!email) {
      setEmailError("Please enter your Email Address.");
      isValid = false;
    } else if (!emailPattern.test(email)) {
      setEmailError("Please Insert Correct Email Format...");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!password1) {
      setPassword1Error("Please enter your Password.");
      isValid = false;
    } else if (!passwordPattern.test(password1)) {
      setPassword1Error(
        "Password must contain at least 8 characters, one digit, one uppercase and one special character"
      );
      isValid = false;
    } else {
      setPassword1Error("");
    }

    if (!confirmpassword) {
      setConfirmpasswordError("Please confirm your Password.");
      isValid = false;
    } else if (confirmpassword !== password1) {
      setConfirmpasswordError("Passwords do not match.");
      isValid = false;
    }
    if (
      selectedOption === "Friend/Referral" &&
      !referralName &&
      !referralMobile &&
      referralMobileError
    ) {
      isValid = false;
    } else {
      setConfirmpasswordError("");
    }

    return isValid;
  };

  const register = (e) => {
    e.preventDefault();
    const isValid = validateForm();

    if (isValid) {
      // Define the base data object
      const baseData = {
        role: "1",
        name: name,
        username: email,
        password: password1,
        confirm_password: confirmpassword,
        mobile_no: mobile,
        postalcode: postalcode,
        city: city,
        latitude: latitude,
        longitude: longitude,
        ...(referralDescription && {
          additional_info: referralDescription,
        }),
        ...(selectedOption === "Friend/Referral" && {
          referrer_id: referralMobile,
          referrer_name: referralName,
        }),
        skip_refferal: referralSkipped.toString(),
      };
      // Conditionally add the source property
      const data = referralSkipped
        ? baseData
        : {
            ...baseData,
            source:
              selectedOption ===
              "Social Media (Facebook, Twitter, Instagram, etc.)"
                ? "Social Media"
                : selectedOption === "Search Engine (Google, Bing, etc.)"
                ? "Search Engine"
                : selectedOption === "Advertisement (Online, Print, TV, Radio)"
                ? "Advertisement"
                : selectedOption === "Friend/Referral"
                ? "Referral"
                : "Others",
          };

      Api.customerRegister(data).then((response) => {
        if (response.success === true) {
          Swal.fire({
            text: response.message,
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/Login");
            }
          });
        } else {
          Swal.fire({
            text: response?.response?.data?.message,
            confirmButtonText: "OK",
          });
        }
      });
    }
  };
  const getLocation = () => {
    setAllowAddAddress(true);
    navigator.geolocation.getCurrentPosition(showPosition, (error) => {
      if (error.code === error.PERMISSION_DENIED) {
        alert(
          "Geolocation is blocked. Please allow access to add your address."
        );
        setAllowAddAddress(false);
      } else if (error.code === error.POSITION_UNAVAILABLE) {
        alert("Geolocation is not supported by this browser.");
        setAllowAddAddress(false);
      } else {
        setAllowAddAddress(true);
      }
    });
  };

  const showPosition = (position) => {
    setLatitude(position.coords.latitude);
    setLongitude(position.coords.longitude);
  };

  const topReferralStepper = () => {
    if (selectedOption) {
      setTempSelectedOption(selectedOption);
    } else if (!selectedOption && tempSelectedOption) {
      setTempSelectedOption(tempSelectedOption);
    }
    setSelectedOption("");
    if (
      name &&
      mobile &&
      email &&
      postalcode &&
      !mobileError &&
      !emailError &&
      district &&
      selectedCity
    ) {
      setbasicDetailsStepper(false);
      setReferralStepper(true);
      setReferralActive(true);
      setPasswordStepper(false);
      setpasswordActive(false);
    } else {
      setPasswordStepper(false);
      setpasswordActive(false);
      setReferralActive(false);
    }
  };
  const topPasswordStepper = () => {
    if (
      name &&
      mobile &&
      email &&
      postalcode &&
      !mobileError &&
      !emailError &&
      district &&
      selectedCity &&
      (referralSkipped || tempSelectedOption !== "")
    ) {
      setbasicDetailsStepper(false);
      setReferralStepper(false);
      setReferralActive(true);
      setPasswordStepper(true);
      setpasswordActive(true);
    } else {
      setPasswordStepper(false);
      setpasswordActive(false);
      setReferralActive(false);
    }
  };
  const referralStepperClick = () => {
    if (
      name &&
      mobile &&
      email &&
      postalcode &&
      !mobileError &&
      !emailError &&
      !nameError &&
      selectedCity &&
      district
    ) {
      setbasicDetailsStepper(false);
      setReferralStepper(true);
      setReferralActive(true);
      setpasswordActive(false);
      setPasswordStepper(false);
    } else {
      setbasicDetailsStepper(true);
      setReferralStepper(false);
      setReferralActive(false);
      setpasswordActive(false);
      setPasswordStepper(false);
    }
  };

  const isReferralButtonDisabled = () => {
    if (
      name &&
      mobile &&
      email &&
      postalcode &&
      !mobileError &&
      !emailError &&
      district &&
      selectedCity &&
      city
    ) {
      return false;
    }
    return true;
  };

  // useEffect(() => {
  //   getLocation();
  // }, []);

  return (
    <>
      <div className="container-fluid ">
        <div
          className="res-576px-d-unset mt--40px"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            marginTop: "10px",
          }}
        >
          <div
            className="pt-0 res-576px-d-none"
            style={{
              // height: "100vh",
              display: "flex",
              alignItems: "center",
              width: "43.5%",
              justifyContent: "center",
            }}
          >
            <div
              className="logo_container res-576px-d-none"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "20px",
                marginTop: "-20px",
              }}
            >
              <div onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
                <S3NewLogo
                  color={"rgba(235, 129, 40, 1)"}
                  // style={{ scale: "1.2" }}
                />
              </div>
              <img alt="demo" src={Logo} className="logo" />
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                  textAlign: "center",
                  width: "76%",
                }}
              >
                Fix Your Electronics Appliances with Ease!
              </div>
              <div
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                  width: "76%",
                  color: "rgba(0, 0, 0, 1)",
                }}
                className="mt--10px"
              >
                Sign up today to book a Service Request for your appliance
                repair needs.
              </div>
            </div>
          </div>
          <div className="res-576px-d-none col-lg-1 border-register-right-reg"></div>
          <div
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
            className="res-577px-d-none mb-4 "
          >
            <S3NewLogo
              color={"rgba(235, 129, 40, 1)"}
              // style={{ scale: "1.2" }}
            />
          </div>
          <div
            style={{
              width: "56.5%",
              height: window.innerWidth > 576 ? "660px" : "auto",
            }}
            className="res-576px-w-fill"
          >
            <div className="p-lg-5 p-md-3 p-2" style={{}}>
              <div className="text-start">
                <p
                  className="p-0 mt-lg-3 mt-0"
                  style={{
                    fontWeight: "700",
                    color: "#1E232C",
                    fontSize: "21px",
                  }}
                >
                  Register with us!
                </p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      color: "#6A707C",
                    }}
                  >
                    <Link to="/commonRegister">
                      <i
                        className="bi bi-chevron-left me-1"
                        style={{
                          fontWeight: "500",
                          fontSize: "12px",
                          color: "rgba(106, 112, 124, 1)",
                        }}
                      ></i>
                      <text
                        className="p-0"
                        style={{
                          fontWeight: "500",
                          fontSize: "12px",
                          color: "rgba(106, 112, 124, 1)",
                        }}
                      >
                        As Customer
                      </text>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                style={{ display: "flex", gap: "1rem", paddingTop: "10px" }}
                className="res-576px-d-none"
              >
                <div
                  style={{
                    color: "rgba(235, 129, 40, 1)",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (selectedOption) {
                      setTempSelectedOption(selectedOption);
                    } else if (!selectedOption && tempSelectedOption) {
                      setTempSelectedOption(tempSelectedOption);
                    }
                    setSelectedOption("");
                    setbasicDetailsStepper(true);
                    setReferralActive(false);
                    setReferralStepper(false);
                    setPasswordStepper(false);
                    setpasswordActive(false);
                  }}
                >
                  Basic Details
                </div>
                <div>--------</div>
                <div
                  style={{
                    color:
                      name &&
                      email &&
                      mobile &&
                      referralActive &&
                      "rgba(235, 129, 40, 1)",
                    fontSize: "14px",
                    cursor:
                      name &&
                      mobile &&
                      email &&
                      postalcode &&
                      !mobileError &&
                      !emailError &&
                      district &&
                      selectedCity
                        ? "pointer"
                        : "",
                  }}
                  onClick={topReferralStepper}
                >
                  Referral
                </div>
                <div>--------</div>
                <div
                  style={{
                    color: passwordActive ? "rgba(235, 129, 40, 1)" : "initial", // Fallback color when conditions are not met
                    fontSize: "14px",
                    cursor:
                      (tempSelectedOption !== "" || referralSkipped) &&
                      "pointer",
                  }}
                  onClick={
                    tempSelectedOption !== "" || referralSkipped
                      ? topPasswordStepper
                      : undefined
                  }
                >
                  Password
                </div>
              </div>

              <div
                style={{ display: "flex", gap: "2px", marginTop: "10px" }}
                className="res-577px-d-none"
              >
                <div
                  style={{
                    color: "rgba(235, 129, 40, 1)",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (selectedOption) {
                      setTempSelectedOption(selectedOption);
                    } else if (!selectedOption && tempSelectedOption) {
                      setTempSelectedOption(tempSelectedOption);
                    }
                    setSelectedOption("");
                    setbasicDetailsStepper(true);
                    setReferralActive(false);
                    setReferralStepper(false);
                    setPasswordStepper(false);
                    setpasswordActive(false);
                  }}
                >
                  Basic Details
                </div>
                <div>-----</div>
                <div
                  style={{
                    color:
                      name &&
                      email &&
                      mobile &&
                      referralActive &&
                      "rgba(235, 129, 40, 1)",
                    fontSize: "14px",
                    cursor: name && email && mobile && "pointer",
                  }}
                  onClick={topReferralStepper}
                >
                  Referral
                </div>
                <div>-----</div>
                <div
                  style={{
                    color: passwordActive
                      ? // selectedOption !== "" &&
                        // ((selectedOption === "Friend/Referral" &&
                        //   referralName &&
                        //   referralMobile &&
                        //   !referralMobileError) ||
                        //   (selectedOption === "Others" && referralDescription) ||
                        //   (selectedOption !== "Friend/Referral" &&
                        //     selectedOption !== "Others"))
                        "rgba(235, 129, 40, 1)"
                      : "initial", // Fallback color when conditions are not met
                    fontSize: "14px",
                    cursor:
                      name && email && mobile
                        ? // selectedOption !== "" &&
                          // ((selectedOption === "Friend/Referral" &&
                          //   referralName &&
                          //   referralMobile &&
                          //   !referralMobileError) ||
                          //   (selectedOption === "Others" && referralDescription) ||
                          //   (selectedOption !== "Friend/Referral" &&
                          //     selectedOption !== "Others"))
                          "pointer"
                        : "",
                  }}
                  onClick={topPasswordStepper}
                >
                  Password
                </div>
              </div>

              <form onSubmit={register}>
                {basicDetailsStepper && (
                  <div>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mt-4 mt-lg-4"
                      style={{ height: "56px" }}
                      id="full-name"
                      value={name}
                      onChange={(e) => {
                        let inputValue = e.target.value;
                        var onlyText = inputValue
                          .replace(/[^A-Za-z ]/g, "")
                          .replace(/  +/g, " ");
                        if (onlyText.startsWith(" ")) {
                          onlyText = onlyText.slice(1);
                        }
                        const truncatedText = onlyText.slice(0, 32);
                        setName(truncatedText);
                      }}
                      label={`Full Name (${name.length}/32)`}
                      type="text"
                      placeholder="Enter your full name"
                      error={!!nameError}
                      helperText={nameError}
                      FormHelperTextProps={{
                        style: {
                          position: "absolute",
                          bottom: "-20px",
                          whiteSpace: "nowrap",
                        },
                      }}
                      onKeyUp={() => {
                        if (!name) {
                          setNameError("Please enter your Full Name.");
                          setIsEmpty(false);
                        } else {
                          setNameError("");
                          setIsEmpty(true);
                        }
                      }}
                    />

                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mt-4 mt-lg-4"
                      style={{ height: "56px" }}
                      id="mobile-number"
                      value={mobile}
                      // onChange={(e) => setMobile(e.target.value)}
                      label="Enter Your Phone Number"
                      type="number"
                      placeholder="e.g., 9876543210"
                      error={!!mobileError}
                      helperText={mobileError}
                      FormHelperTextProps={{
                        style: {
                          position: "absolute",
                          bottom: "-20px",
                          whiteSpace: "nowrap",
                        },
                      }}
                      onKeyUp={() => {
                        if (!mobile) {
                          setMobileError("Please Enter your Phone Number.");
                          setIsEmpty(false);
                        } else if (mobile.length !== 10) {
                          setMobileError("please Enter valid Phone Number.");
                          setIsEmpty(false);
                        } else {
                          setMobileError("");
                          setIsEmpty(true);
                        }
                      }}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Remove any non-digit characters
                        const numericValue = value.replace(/\D/g, "");
                        // Enforce length of 6
                        const formattedValue = numericValue.slice(0, 10);
                        setMobile(formattedValue);
                      }}
                    />

                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mt-4 mt-lg-4"
                      style={{ height: "56px" }}
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      label="Enter Your Email"
                      type="email"
                      placeholder="Enter your email address"
                      error={!!emailError}
                      helperText={emailError}
                      FormHelperTextProps={{
                        style: {
                          position: "absolute",
                          bottom: "-20px",
                          whiteSpace: "nowrap",
                        },
                      }}
                      onKeyUp={() => {
                        const trimmedEmail = email.trim();
                        setEmail(trimmedEmail);

                        if (!trimmedEmail) {
                          setEmailError("Please enter your Email Address.");
                        } else if (!emailPattern.test(trimmedEmail)) {
                          setEmailError("Please enter a valid Email Address.");
                        } else {
                          setEmailError("");
                        }
                      }}
                    />
                    <div className="row mt-4 mt-lg-4">
                      <div className="col">
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100 "
                          id="outlined-helperText"
                          label="Postal Code"
                          value={postalcode}
                          error={!!postalError}
                          helperText={postalError}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Remove any non-digit characters
                            const numericValue = value.replace(/\D/g, "");
                            // Enforce length of 6
                            const formattedValue = numericValue.slice(0, 6);
                            setPostalcode(formattedValue);
                            // setPostalError('');
                            setIsPostalCodeOnChange(true);
                          }}
                        />
                      </div>
                      <div className="col">
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          label="City/Town/Village"
                          select
                          value={selectedCity}
                          // onChange={(e) => setSelectedCity(e.target.value)}
                          onChange={handleCityChange}
                          error={!!citysError}
                          helperText={citysError}
                          disabled={
                            postalcode.length !== 6 || invalidPostalCode
                          }
                          required
                        >
                          {citys?.length > 0 ? (
                            citys.map((city, index) => (
                              <MenuItem key={city} value={city}>
                                {city}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem value=""></MenuItem>
                          )}
                        </TextField>
                      </div>
                      <div className="col">
                        <TextField
                          className="w-100"
                          label="District"
                          select
                          value={district}
                          onChange={handleDistrictChange}
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                        >
                          {districts.map((district, index) => (
                            <MenuItem key={index} value={district}>
                              {district}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>

                    <Button
                      className="w-100 mt-4 mt-lg-4 rounded-3"
                      // type="submit"
                      style={{
                        height: "56px",
                        backgroundColor: isReferralButtonDisabled()
                          ? "rgb(250 155 9 / 37%)"
                          : "rgba(235, 129, 40, 1)",
                        color: "white",
                        fontWeight: "600",
                        fontSize: "15px",
                        textTransform: "capitalize",
                      }}
                      variant="contained"
                      onClick={referralStepperClick}
                      disabled={isReferralButtonDisabled()}
                    >
                      Continue
                    </Button>
                  </div>
                )}
                {referralStepper && (
                  <div>
                    <div style={{ fontWeight: 600, paddingTop: "20px" }}>
                      How did you hear about S3-App?
                    </div>

                    <div
                      style={{
                        color: "rgba(131, 145, 161, 1)",
                        marginTop: "10px",
                      }}
                    >
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                          value={tempSelectedOption}
                          onChange={(e) => {
                            setpasswordActive(false);
                            setReferralSkipped(false);
                            setTempSelectedOption(e.target.value);
                          }}
                        >
                          <FormControlLabel
                            value="Social Media (Facebook, Twitter, Instagram, etc.)"
                            control={<Radio />}
                            label="Social Media (Facebook, Twitter, Instagram, etc.)"
                          />
                          <FormControlLabel
                            value="Search Engine (Google, Bing, etc.)"
                            control={<Radio />}
                            label="Search Engine (Google, Bing, etc.)"
                          />
                          <FormControlLabel
                            value="Advertisement (Online, Print, TV, Radio)"
                            control={<Radio />}
                            label="Advertisement (Online, Print, TV, Radio)"
                          />
                          <FormControlLabel
                            value="Friend/Referral"
                            control={<Radio />}
                            label="Friend/Referral"
                          />
                          <FormControlLabel
                            value="Others"
                            control={<Radio />}
                            label="Others"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    {(tempSelectedOption === "Friend/Referral" ||
                      selectedOption === "Friend/Referral") && (
                      <>
                        <div style={{ fontWeight: 600, paddingTop: "10px" }}>
                          Can you please provide the ID of the person who
                          referred you?
                        </div>
                        <div className="col-12" style={{ paddingTop: "20px" }}>
                          <TextField
                            className="w-100"
                            style={{ height: "56px" }}
                            id="Referral ID"
                            value={referralMobile}
                            label="Referrer ID"
                            type="number"
                            placeholder="Enter the Customer/ Technician ID"
                            error={!!referralMobileError}
                            helperText={referralMobileError}
                            FormHelperTextProps={{
                              style: {
                                position: "absolute",
                                bottom: "-20px",
                                whiteSpace: "nowrap",
                              },
                            }}
                            onKeyUp={() => {
                              if (!referralMobile) {
                                setReferralMobileError(
                                  "Please enter the Referral ID."
                                );
                                setIsEmpty(false);
                              }
                              // else if (referralMobile.length !== 32) {
                              //   setReferralMobileError(
                              //     "Please enter a valid Referral ID."
                              //   );
                              //   setIsEmpty(false);
                              // }
                              else {
                                setReferralMobileError("");
                                setIsEmpty(true);
                              }
                            }}
                            onChange={(e) => {
                              const value = e.target.value;
                              // Remove any non-digit characters
                              const numericValue = value.replace(/\D/g, "");
                              if (numericValue?.length < 0) {
                                setRefferalNameDisabled(false);
                              }
                              // Enforce length of 10 digits
                              const formattedValue = numericValue.slice(0, 32);
                              setReferralMobile(formattedValue);
                              Api.getReferrerName(formattedValue).then(
                                (res) => {
                                  if (res) {
                                    setreferralName(res?.fullname);
                                    if (res?.fullname) {
                                      setRefferalNameDisabled(true);
                                    } else {
                                      setRefferalNameDisabled(false);
                                      setreferralName("");
                                    }
                                    // setApplianceData(res);
                                  }
                                }
                              );
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />

                          <TextField
                            disabled
                            // disabled={refferalNameDisabled}
                            className="w-100 mt-3 mt-lg-4"
                            style={{ height: "56px" }}
                            id="full-name"
                            value={referralName}
                            onChange={(e) => {
                              let inputValue = e.target.value;

                              // Remove extra spaces and allow only alphabets (no numbers or special characters)
                              var onlyText = inputValue
                                .replace(/[^A-Za-z ]/g, "")
                                .replace(/  +/g, " ");

                              // Remove leading space if present
                              if (onlyText.startsWith(" ")) {
                                onlyText = onlyText.slice(1);
                              }

                              // Limit the input length to 32 characters
                              const truncatedText = onlyText.slice(0, 32);
                              setreferralName(truncatedText);
                            }}
                            label={`Referrer Name (${referralName.length}/32)`}
                            type="text"
                            placeholder="Referrer Name"
                            // error={!!referralnameError}
                            // helperText={referralnameError}
                            FormHelperTextProps={{
                              style: {
                                position: "absolute",
                                bottom: "-20px",
                                whiteSpace: "nowrap",
                              },
                            }}
                            // onKeyUp={() => {
                            //   if (!referralName) {
                            //     setreferralnameError(
                            //       "Please enter the full name."
                            //     );
                            //     setIsEmpty(false);
                            //   } else {
                            //     setreferralnameError("");
                            //     setIsEmpty(true);
                            //   }
                            // }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </div>
                      </>
                    )}

                    {(tempSelectedOption === "Others" ||
                      selectedOption === "Others") && (
                      <>
                        <div
                          style={{ fontWeight: 600, paddingTop: "10px" }}
                        ></div>
                        <div className="col-12" style={{ paddingTop: "20px" }}>
                          <TextField
                            multiline
                            rows={3}
                            className="w-100"
                            id="referred-by"
                            value={referralDescription}
                            onChange={(e) => {
                              let inputValue = e.target.value;

                              var onlyText = inputValue
                                .replace(/[^A-Za-z ]/g, "")
                                .replace(/  +/g, " ");

                              if (onlyText.startsWith(" ")) {
                                onlyText = onlyText.slice(1);
                              }

                              const truncatedText = onlyText.slice(0, 300);
                              setReferralDescription(truncatedText);
                            }}
                            label={`Please specify (${referralDescription?.length}/300)`}
                            type="text"
                            placeholder="Enter max 300 words.."
                            error={!!referralDescriptionError}
                            helperText={referralDescriptionError}
                            FormHelperTextProps={{
                              style: {
                                position: "absolute",
                                bottom: "-20px",
                                whiteSpace: "nowrap",
                              },
                            }}
                            style={{
                              paddingBottom: "4px",
                            }}
                            onKeyUp={() => {
                              if (!referralDescription) {
                                setReferralDescriptionError(
                                  "Please enter the description."
                                );
                                setIsEmpty(false);
                              } else {
                                setReferralDescriptionError("");
                                setIsEmpty(true);
                              }
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </div>
                      </>
                    )}
                    <div
                      style={{
                        color: "rgb(131, 145, 161)",
                        display: "flex",
                        justifyContent: "flex-end",
                        height: "10px",
                        fontSize: "14px",
                      }}
                      onClick={() => {
                        setpasswordActive(true);
                        setPasswordStepper(true);
                        setReferralSkipped(true);
                        setReferralActive(true);
                        setReferralStepper(false);
                        setbasicDetailsStepper(false);
                        setSelectedOption("");
                        setTempSelectedOption("");
                        setreferralName("");
                        setReferralMobile("");
                        setReferralDescription("");
                      }}
                    >
                      <div
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        Skip
                      </div>
                    </div>
                    <Button
                      className="w-100 mt-4 mt-lg-4 rounded-3"
                      // type="submit"
                      style={{
                        marginTop: "-10px",
                        height: "56px",
                        backgroundColor:
                          tempSelectedOption === ""
                            ? "rgb(250 155 9 / 37%)"
                            : tempSelectedOption === "Friend/Referral" &&
                              ((referralMobile && !referralName) ||
                                referralMobileError)
                            ? "rgb(250 155 9 / 37%)"
                            : tempSelectedOption === "Friend/Referral" &&
                              !referralName
                            ? "rgb(250 155 9 / 37%)"
                            : tempSelectedOption === "Others"
                            ? "rgba(235, 129, 40, 1)"
                            : "rgba(235, 129, 40, 1)",
                        color: "white",
                        fontWeight: "600",
                        fontSize: "15px",
                        textTransform: "capitalize",
                        cursor:
                          tempSelectedOption === ""
                            ? "default"
                            : tempSelectedOption === "Friend/Referral" &&
                              (!referralMobile || referralMobileError)
                            ? "default"
                            : "pointer",
                      }}
                      variant="contained"
                      onClick={passwordStepperClick}
                    >
                      Continue
                    </Button>
                  </div>
                )}
                {passwordStepper && (
                  <div>
                    <TextField
                      className="w-100 mt-4 mt-lg-4"
                      style={{ height: "56px" }}
                      id="password"
                      value={password1}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setPassword1(e.target.value)}
                      label="Enter Your Password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter your password"
                      error={!!password1Error}
                      helperText={password1Error}
                      FormHelperTextProps={{
                        style: {
                          position: "absolute",
                          bottom: "-20px",
                          whiteSpace: "nowrap",
                        },
                      }}
                      onKeyUp={() => {
                        if (!password1) {
                          setPassword1Error("Please enter your Password.");
                          setIsEmpty(false);
                        } else if (!passwordPattern.test(password1)) {
                          setPassword1Error(
                            "Password must contain at least 8 characters, one digit, one uppercase and one special character"
                          );
                          setIsEmpty(false);
                        } else {
                          setPassword1Error("");
                          setIsEmpty(true);
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                              tabIndex={-1}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="w-100 mt-4 mt-lg-4"
                      style={{ height: "56px" }}
                      id="confirm-password"
                      value={confirmpassword}
                      onChange={(e) => {
                        setConfirmpassword(e.target.value);
                        setConfirmpasswordError("");
                      }}
                      label="Confirm Your Password"
                      type={showPassword1 ? "text" : "password"}
                      placeholder="Confirm your password"
                      error={!!confirmpasswordError}
                      helperText={confirmpasswordError}
                      FormHelperTextProps={{
                        style: {
                          position: "absolute",
                          bottom: "-20px",
                          whiteSpace: "nowrap",
                        },
                      }}
                      onKeyUp={() => {
                        if (!confirmpassword) {
                          setConfirmpasswordError(
                            "Please confirm your password."
                          );
                          setIsEmpty(false);
                        } else if (confirmpassword !== password1) {
                          setConfirmpasswordError("Passwords do not match.");
                          setIsEmpty(false);
                        } else if (!passwordPattern.test(confirmpassword)) {
                          setPassword1Error(
                            "Password must contain at least 8 characters, one digit, one uppercase and one special character"
                          );
                          setIsEmpty(false);
                        } else {
                          setConfirmpasswordError("");
                          setIsEmpty(true);
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword1(!showPassword1)}
                              edge="end"
                              tabIndex={-1}
                            >
                              {showPassword1 ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <div
                      style={{
                        marginTop: "20px",
                      }}
                    >
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                        className="res-576px-w-90per "
                      >
                        <input
                          type="checkbox"
                          id="terms-checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          style={{
                            accentColor: "rgba(235, 129, 40, 1)",
                            position: "relative",
                            cursor: "pointer",
                          }}
                        />
                        <label
                          htmlFor="terms-checkbox"
                          style={{
                            marginLeft: "10px",
                            fontSize: "12px",
                            color: "gray",
                          }}
                        >
                          By signing up, I confirm that I have read and agree to
                          the{" "}
                          <a
                            href="/terms-of-use"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "blue",
                              textDecoration: "underline",
                            }}
                          >
                            Terms of Use
                          </a>{" "}
                          and{" "}
                          <a
                            href="/privacy-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "blue",
                              textDecoration: "underline",
                            }}
                          >
                            Privacy Policy
                          </a>
                          , and I declare that I am 18 years of age or older.
                        </label>
                      </div>
                    </div>
                    {checkbocError && (
                      <div
                        style={{
                          fontSize: "10px",
                          color: "#d32f2f",
                          height: 0,
                        }}
                      >
                        {checkbocError}
                      </div>
                    )}
                    <Button
                      className="w-100 mt-4 mt-lg-4 rounded-3"
                      type="submit"
                      style={{
                        height: "56px",
                        backgroundColor:
                          !checkbocError &&
                          password1 &&
                          confirmpassword &&
                          !confirmpasswordError &&
                          isChecked
                            ? "rgba(235, 129, 40, 1)"
                            : "rgb(250 155 9 / 37%)",
                        color: "white",
                        fontWeight: "600",
                        textTransform: "capitalize",
                        fontSize: "15px",
                      }}
                      variant="contained"
                      // disabled={allowAddAddress ? false : true}
                    >
                      Register
                    </Button>
                  </div>
                )}

                <div className="text-center">
                  <p
                    className={`m-0 mt-2 ${
                      tempSelectedOption === "Friend/Referral"
                        ? "mt-lg-1"
                        : "mt-lg-3"
                    }`}
                  >
                    Already have an account?
                    <Link
                      to="/Login"
                      style={{
                        color: "rgb(235, 129, 40)",
                        fontWeight: "500",
                        fontSize: "15px",
                        textDecoration: "none",
                      }}
                    >
                      {" "}
                      Login Now
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "100px" }}>
        <Footer topscroller />
      </div>
    </>
  );
};
export default CommonRegister;
