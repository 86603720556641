import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

export default function FAQ({
  appliance,
  appliance_abbreviation,
  last_answer,
}) {
  const [expanded, setExpanded] = useState(null);

  const qa = [
    {
      id: 1,
      question: `Do you offer service for any ${appliance}, and anywhere in Tamil Nadu?`,
      answer: `"Yes, our technicians will support service for any ${appliance} make and design across Tamil Nadu. While we assure a vast availability of technicians, there can be a shortage at the time of booking. Our administrators will ensure service delivery by all means – they are hard-core technicians and will wear the handyman hat to render your service on time! We have classified Tamil Nadu into 4 zones, and ${appliance} repair services in Chennai, Madurai, Coimbatore, or Karaikal will be treated with utmost importance. "`,
    },
    {
      id: 2,
      question: "What is the warranty for the service you offer?  ",
      answer: `We provide a 30-day warranty for all appliance repair and service. `,
    },
    {
      id: 3,
      question: "What is the base charge for a visit? ",
      answer: `The base charge for a visit, where the technician inspects and identifies the problem is just Rs. 200. In case additional service is delivered, including repair or installation, the charges will be applied based on the level of work and spare parts required.  `,
    },
    {
      id: 4,
      question: "Does the app support online payment?  ",
      answer: `Yes, you can pay for the service online via card, or UPI as well as by cash. This can be directly sent or handed over to the technician.  `,
    },
    {
      id: 5,
      question: `Do you provide additional services related to ${appliance_abbreviation} care? `,
      answer: `${last_answer}`,
    },
  ];
  const handleClick = (index) => {
    setExpanded(expanded === index ? null : index);
  };
  return (
    <div className="faq-container">
      <div className="heading">Frequently asked questions</div>
      <div className="faq-content res-576px-d-none">
        We have listed out the commonly asked queries on electronic appliance
        services and how S3 helps.
      </div>
      <div className="faq-content res-576px-d-none">
        Do share any additional queries or feedback on our contact us page.
      </div>
      <div className="faq-content res-577px-d-none">
        We have listed out the commonly asked queries on electronic appliance Do
        share any additional queries or feedback on our contact us page.
        services and how S3 helps.
      </div>
      <div className="qa-container">
        {qa.map((item, index) => (
          <div key={index} className="qa-item">
            <div className="qa-flex" onClick={() => handleClick(index)}>
              <div
                style={{ fontWeight: 600 }}
                className="res-576px-text-align-start res-576px-w-9 "
              >
                {item.question}
              </div>
              {expanded === index ? (
                <IoIosArrowUp size={20} style={{ cursor: "pointer" }} />
              ) : (
                <IoIosArrowDown size={20} style={{ cursor: "pointer" }} />
              )}
            </div>
            <div
              // style={{
              //   borderBottom:
              //     index === qa.length - 1 && "1px solid rgba(221, 221, 221, 1)",
              // }}
              className={`qa-answer ${
                expanded === index ? "expanded" : "collapsed"
              }`}
            >
              {item.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
