import { createSlice } from "@reduxjs/toolkit";


const initiatePaymentSlice = createSlice({
    name: 'initiatePayment',
    initialState:{
        loading: false,
    },
    reducers:{
        initiatePaymentRequest(state, action){
            return{
                ...state,
                loading: true
            }
        },
        initiatePaymentSuccess(state, action){
            return{
                ...state,
                loading: true,
                initiatePaymentData: action.payload
            }
        },
        initiatePaymentFail(state, action){
            return{
                ...state,
                loading: false,
                error: action.payload
            }
        }
    }
});

const {actions, reducer} = initiatePaymentSlice;

export const {initiatePaymentRequest,initiatePaymentFail,initiatePaymentSuccess} = actions

export default reducer;