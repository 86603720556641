import { createSlice } from "@reduxjs/toolkit";

const initiateRevenueShareSlice = createSlice({
  name: "initiateRevenueShare",
  initialState: {
    loading: false,
  },
  reducers: {
    initiateRevenueShareRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    initiateRevenueShareSuccess(state, action) {
      return {
        ...state,
        loading: false,
        initiateRevenueShareData: action.payload.data,
        revenueShareCount: action.payload.count,
      };
    },
    initiateRevenueShareFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
  },
});

const { actions, reducer } = initiateRevenueShareSlice;

export const {
  initiateRevenueShareRequest,
  initiateRevenueShareFail,
  initiateRevenueShareSuccess,
} = actions;

export default reducer;
