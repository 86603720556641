import axios from "axios";
import { BASE_URL } from "../../api/ApiPath";
import { referralManagementListRequest, referralManagementListFail, referralManagementListSuccess } from "../slices/referralManagementSlice";
export const getAllreferralManagementList = (search, filter) => async (dispatch) => {
    let payload = search ? `search=${search}&` : '';
    payload += filter?.zone_id ? `zone_id=${filter?.zone_id}&` : '';
    payload += filter?.district ? `district=${filter?.district}&` : '';
    payload += filter?.startDate ? `startDate=${filter?.startDate}&` : '';
    payload += filter?.endDate ? `endDate=${filter?.endDate}&` : '';
    const token = localStorage.getItem("token");
    try {
        dispatch(referralManagementListRequest())
        const response = await axios.get(`${BASE_URL}/all-referral-details?${payload}`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        dispatch(referralManagementListSuccess(response?.data))
        console.log("referralManagementListLists", response?.data);
    } catch (error) {
        console.log(error);
        dispatch(referralManagementListFail(error?.response?.data))
    }
}
