import React, { useEffect, useState } from "react";
import medal_star from "../../Assets/Home/medal-star.svg";
import personal_card from "../../Assets/Home/personalcard.svg";
import Api from "../../service/services";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function GetInTouch() {
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [message, setmessage] = useState("");
  const [toastmessage, setToastmessage] = useState("");
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const handleValidation = () => {
    let isValid = true;
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    const isValidEmail = emailRegex.test(email);
    if (firstName.length <= 3) {
      isValid = false;
    }
    if (!email) {
      isValid = false;
    } else if (!isValidEmail) {
      isValid = false;
    }
    if (mobile.length < 10) {
      isValid = false;
    }

    return isValid;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      mobile: mobile,
      message: message,
    };
    const isValid = handleValidation();
    if (true) {
      await Api.formEnquiry(payload)
        .then((res) => {
          if (res.message) {
            if (res.status === true) {
              setfirstName("");
              setlastName("");
              setmobile("");
              setmessage("");
              setemail("");
              toast.success(res?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
                type: "success",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                hideProgressBar: false,
              });
            } else
              toast.error(res.response.data.message || res.response.data, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
                type: "error",
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                draggable: true,
                hideProgressBar: false,
              });
            setToastmessage(res.message);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };
  useEffect(() => {
    if (toastmessage) {
      setTimeout(() => {
        setToastmessage("");
      }, 5000);
    }
  }, [toastmessage]);
  return (
    <div className="get-in-touch-container">
      {/* <ToastContainer style={{ marginTop: 70 }} /> */}
      <div className="first-flex-contact">
        <div>
          <div
            style={{ fontWeight: 600, fontSize: "36.5px" }}
            className="es-576px-font-25px"
          >
            Contact Us
          </div>
          <div
            className="mt-4 mb-4 contact-text"
            style={{ color: "rgba(26, 26, 26, 0.7)" }}
          >
            Email, Call, or Complete the from to Learn how S3 can solve your
            message problem.
          </div>
          <div style={{ fontWeight: 600, marginBottom: "14px" }}>
            support@s3-app.com
          </div>
          <div style={{ fontWeight: 600 }}>+91 9600300459</div>
        </div>

        <div className="contact-services-container">
          <div className="ser-con">
            <img
              src={medal_star}
              alt="medal_star"
              style={{ scale: "0.8", position: "relative", left: "-10px" }}
            />
            <div
              style={{ fontSize: "18px", fontWeight: 1000 }}
              className="mt-3"
            >
              Customer Support
            </div>
            <div
              style={{
                fontSize: "15px",
                color: "rgba(26, 26, 26, 0.7)",
                marginTop: "10px",
                textAlign: "start",
              }}
              className="medal-con"
            >
              Be it Madurai, Coimbatore, Nagapattinam or Chennai, we know the
              locality, the weather, and the people. Your appliances are in the
              safe hands of local technicians, just a call away!
            </div>
          </div>
          <div className="border-right"></div>
          <div className="ser-con">
            <img
              src={personal_card}
              alt="medal_star"
              style={{ scale: "0.8", position: "relative", left: "-10px" }}
            />
            <div
              style={{ fontSize: "18px", fontWeight: 1000 }}
              className="mt-3"
            >
              Feedback and Suggestion
            </div>
            <div
              style={{
                fontSize: "15px",
                color: "rgba(26, 26, 26, 0.7)",
                marginTop: "10px",
                textAlign: "start",
              }}
              className="medal-con"
            >
              We value your feedback and are continuously working to improve S3.
              Your input is crucial in shaping the future of S3.
            </div>
          </div>
        </div>
      </div>
      <div className="get-in-touch-box">
        <div
          style={{ fontWeight: 600, fontSize: "27.5px" }}
          className="res-576px-font-25px"
        >
          Get in touch!
        </div>
        <form onSubmit={handleSubmit}>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              marginTop: "1.6rem",
            }}
          >
            <input
              type="text"
              placeholder="First name"
              className="input-flex-contact"
              value={firstName}
              onChange={(e) => setfirstName(e.target.value)}
            />
            <input
              type="text"
              className="input-flex-contact"
              placeholder="Last name"
              value={lastName}
              onChange={(e) => setlastName(e.target.value)}
            />
          </div>
          <input
            type="text"
            className="input-flex-contact"
            placeholder="Your email"
            style={{ width: "100%", marginTop: "16px" }}
            value={email}
            onChange={(e) => setemail(e.target.value)}
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="input-phone-code">
              +91 <div className="custom-input-hr"></div>
            </div>
            <input
              type="text"
              className="input-flex-contact input-phone-input"
              placeholder="Phone number"
              style={{ width: "100%", marginTop: "16px" }}
              value={mobile}
              onChange={(e) => {
                const value = e.target.value;
                const numericValue = value.replace(/\D/g, "");
                setmobile(numericValue);
              }}
              maxLength={10}
            />
          </div>
          <textarea
            placeholder="How can we help?"
            className="input-flex-contact"
            style={{ width: "100%", marginTop: "16px" }}
            rows={4}
            value={message}
            maxLength={150}
            onChange={(e) => {
              setCount(e.target.value?.length);
              setmessage(e.target.value);
            }}
          />
          <div
            style={{
              color: "rgba(26, 26, 26, 0.4)",
              fontSize: "10px",
              float: "right",
              height: 0,
              marginBottom: "4px",
            }}
          >
            {count}/150
          </div>
          <button type="submit">Submit</button>
        </form>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              color: "rgba(26, 26, 26, 0.4)",
              width: "80%",
              textAlign: "center",
              fontSize: "12px",
            }}
          >
            By contacting us, you agree to our{" "}
            <span
              style={{
                fontWeight: 600,
                color: "rgba(26, 26, 26, 0.7)",
                cursor: "pointer",
              }}
              onClick={() => navigate("/terms-of-use")}
            >
              Terms of Use
            </span>{" "}
            and{" "}
            <span
              style={{
                fontWeight: 600,
                color: "rgba(26, 26, 26, 0.7)",
                cursor: "pointer",
              }}
              onClick={() => navigate("/privacy-policy")}
            >
              Privacy Policy
            </span>
          </div>
        </div>
      </div>
      <ToastContainer
        theme={"light"}
        hideProgressBar={false}
        style={{ width: "500px" }}
      />
    </div>
  );
}
