import axios from "axios";
// import { DRAW_SERVICE_BASE_URL } from "../../api/apiBaseUrl";
import { BASE_URL } from "../../api/ApiPath";
import { RewardZoneListRequest, RewardZoneListFail, RewardZoneListSuccess } from "../slices/rewardZoneListSlice";
export const getAllRewardZoneList = (startDate, endDate, status) => async (dispatch) => {
    const token = localStorage.getItem("token");
    // let payload = id ? `draw_id=${id}&startingNumber=0000&limit=9999&onlySoldOut=false` : '';

    try {
        dispatch(RewardZoneListRequest())
        const response = await axios.get(`${BASE_URL}/technician/reward/zone-count?startDate=${startDate}&endDate=${endDate}&status=${status}`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        dispatch(RewardZoneListSuccess(response?.data))
        console.log("RewardZoneListLists", response?.data);
    } catch (error) {
        console.log(error);
        dispatch(RewardZoneListFail(error?.response?.data))
    }
}
