import React from "react";
import netflix from "../../Assets/Home/netflix.svg";
import fridge from "../../Assets/Home/fridge.svg";
import washing_machine from "../../Assets/Home/washing-machine.svg";
import dth from "../../Assets/Home/dth.svg";
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

export default function Expertise() {
  const navigate = useNavigate();
  return (
    <div className="exp-main-con">
      <div className="expertise-container">
        <div
          style={{ color: "white" }}
          className="expertise-heading res-576px-font-25px "
        >
          <b>Our expertise focus areas include:</b>
        </div>
        <div className="mt-3 expertise-texts">
          <div style={{ color: "white", fontWeight: 600, fontSize: "18px" }}>
            Television:
          </div>
          <div className="items-flex mt-2">
            <div
              style={{ color: "rgba(255, 255, 255, 0.7)", fontSize: "15px" }}
              className="res-576px-font-11px "
            >
              Click to know more on how we care for your Television.
            </div>
            <div
              className="oval-item-container"
              onClick={() => navigate("/tv-service")}
            >
              <img src={netflix} alt="netflix" />
              <div style={{ fontSize: "12px" }}>Television/TV</div>
              <FaArrowRightLong
                style={{ color: "rgba(250, 155, 9, 1)" }}
                size={20}
              />
            </div>
          </div>
        </div>
        <div className="mt-3  expertise-texts">
          <div style={{ color: "white", fontWeight: 600, fontSize: "18px" }}>
            Refrigerator:
          </div>
          <div className="items-flex mt-2">
            <div
              style={{ color: "rgba(255, 255, 255, 0.7)", fontSize: "15px" }}
              className="res-576px-font-11px "
            >
              Persistent issues in your fridge? Discover how S3 can help.
            </div>
            <div
              className="oval-item-container"
              onClick={() => navigate("/refrigerator-service")}
            >
              <img src={fridge} alt="netflix" />
              <div style={{ fontSize: "12px" }}>Refrigerator</div>
              <FaArrowRightLong
                style={{ color: "rgba(250, 155, 9, 1)" }}
                size={20}
              />
            </div>
          </div>
        </div>
        <div className="mt-3  expertise-texts">
          <div style={{ color: "white", fontWeight: 600, fontSize: "18px" }}>
            Washing Machine:
          </div>
          <div className="items-flex mt-2">
            <div
              style={{ color: "rgba(255, 255, 255, 0.7)", fontSize: "15px" }}
              className="res-576px-font-11px "
            >
              Learn more on why customers love our washing machine service.
            </div>
            <div
              className="oval-item-container"
              onClick={() => navigate("/washing-machine-service")}
            >
              <img src={washing_machine} alt="netflix" />
              <div style={{ fontSize: "12px" }}>Washing Machine</div>
              <FaArrowRightLong
                style={{ color: "rgba(250, 155, 9, 1)" }}
                size={20}
              />
            </div>
          </div>
        </div>
        <div className="mt-3  expertise-texts">
          <div style={{ color: "white", fontWeight: 600, fontSize: "18px" }}>
            DTH:
          </div>
          <div className="items-flex mt-2">
            <div
              style={{ color: "rgba(255, 255, 255, 0.7)", fontSize: "15px" }}
              className="res-576px-font-11px "
            >
              Check out our DTH services to believe we care for all appliances,
              small or big!
            </div>
            <div
              className="oval-item-container"
              onClick={() => navigate("/dth-service")}
            >
              <img src={dth} alt="netflix" />
              <div style={{ fontSize: "12px" }}>DTH</div>
              <FaArrowRightLong
                style={{ color: "rgba(250, 155, 9, 1)" }}
                size={20}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
