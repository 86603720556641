import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./common/Navbar";

export default function TermsAndConditions() {
  const [showButton, setShowButton] = useState(false);
  const currentRef = useRef(null);
  const location = useLocation();

  const handleClick = (index) => {
    const section = document.getElementById(`section-${index}`);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      console.error(`Section with ID 'section-${index}' not found.`);
    }
  };
  const buttonStyle = {
    position: "fixed",
    bottom: "20px",
    right: "14px",
    fontSize: "14px",
    padding: "20px 0px",
    color: "blue",
    textDecoration: "underline",
    cursor: "pointer",
    animation: "moveUpDown 2s infinite",
    height: "60px",
  };
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const isMobileView = window.innerWidth <= 768;
      const offsetThreshold = isMobileView ? 700 : 300;
      const offset = documentHeight - windowHeight - scrollTop;

      if (scrollTop > 0 && offset > offsetThreshold) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);
  return (
    <div ref={currentRef}>
      {showButton && (
        <div style={buttonStyle} onClick={() => handleClick(25)}>
          ToC
        </div>
      )}
      <div className="sticky-top">
        <Navbar />
      </div>
      <div
        style={{
          width: "100%",
          backgroundColor: "#F8F8F8",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "30px",
          paddingBottom: "30px",
          marginTop: "60px",
        }}
      >
        <div
          style={{
            padding: 50,
            width: "90%",
            backgroundColor: "white",
            boxShadow: "0 0 10px 1px #00000036",
            fontSize: "14px",
          }}
          className="res-576px-p-20px"
        >
          <h5 style={{ marginTop: 0, textAlign: "center", marginBottom: 20 }}>
            <b>TERMS OF USE</b>
          </h5>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div style={{ fontSize: 11, display: "flex", gap: "3px" }}>
              <span>Last Updated: </span>
              <span className="date">
                18<span className="superscript">th</span> June, 2024
              </span>
            </div>
          </div>
          <div style={{ fontSize: 17, marginTop: 40 }}>
            <b>AGREEMENT TO OUR LEGAL TERMS</b>
          </div>
          <div style={{ fontSize: 14, marginTop: 40 }}>
            <div>
              We are Secure Sales Service (S3) App, doing business as S3-app
              ('Company', 'we', 'us', or 'our'), based out of Karur, Tamil Nadu.
              These terms govern your use of our services, including our website
              https://www.s3-app.com (the 'Site'), the mobile application S3-app
              (the 'App'), as well as any other related products and services
              that refer or link to these legal terms of use (collectively, the
              'Services').
            </div>
            <br />
            <div>
              S3-app endeavours to make services and products accessible to all.
              Our first venture is a crowd-sourced technician community that
              renders electronics appliance repair, installation, and service.
            </div>
            <br />
            <div>
              You can contact us by phone at +91 9600300459, email at
              support@s3-app.com, or by mail to Vinayaka Electronics and GPS,
              20/2, Vaiyapuri Nagar, Second Cross, Karur, Tamil Nadu, 639002,
              Karur, Tamil Nadu 639002, India.
            </div>
            <br />
            <div>
              These Legal Terms constitute a legally binding agreement made
              between you, whether personally or on behalf of an entity ('you'),
              and Secure Sales Service (S3) App, concerning your access to and
              use of the Services. You agree that by accessing the Services, you
              have read, understood, and agreed to be bound by all of these
              Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS,
              THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU
              MUST DISCONTINUE USING THEM IMMEDIATELY.
            </div>
            <br />
            <div>
              Any change to the terms is notified to users on the website or
              mobile application.
            </div>
            <br />
            <div id={`section-${25}`} data-index={25}>
              The Services are intended for users who are at least 18 years old.
              Persons under the age of 18 are not permitted to use or register
              for the Services.
            </div>
            <br />
            <div>
              We recommend that you print a copy of these Legal Terms for your
              records.
            </div>
          </div>
          <div>
            <h6 style={{ marginTop: "30px" }}>
              <b>TABLE OF CONTENTS (ToC) </b>
            </h6>
            <div style={{ color: "blue" }}>
              <div
                className="mt-4"
                style={{ fontSize: "14px", cursor: "pointer" }}
                onClick={() => handleClick(1)}
              >
                <em>1. OUR SERVICES </em>
              </div>
              <div
                className="mt-2"
                onClick={() => {
                  handleClick(2);
                }}
                style={{ fontSize: "14px", cursor: "pointer" }}
              >
                <em>2. INTELLECTUAL PROPERTY RIGHTS</em>
              </div>
              <div
                className="mt-2"
                onClick={() => {
                  handleClick(3);
                }}
                style={{ fontSize: "14px", cursor: "pointer" }}
              >
                <em> 3. USER REPRESENTATIONS </em>
              </div>
              <div
                onClick={() => handleClick(4)}
                className="mt-2"
                style={{ fontSize: "14px", cursor: "pointer" }}
              >
                <em>4. USER REGISTRATION</em>
              </div>
              <div
                className="mt-2"
                style={{ fontSize: "14px", cursor: "pointer" }}
                onClick={() => handleClick(5)}
              >
                <em> 5. PURCHASES AND PAYMENT</em>
              </div>
              <div
                style={{ fontSize: "14px", cursor: "pointer" }}
                className="mt-2"
                onClick={() => handleClick(6)}
              >
                <em>6. POLICY</em>
              </div>
              <div
                style={{ fontSize: "14px", cursor: "pointer" }}
                className="mt-2"
                onClick={() => handleClick(7)}
              >
                <em>7. PROHIBITED ACTIVITIES</em>
              </div>
              <div
                style={{ fontSize: "14px", cursor: "pointer" }}
                className="mt-2"
                onClick={() => handleClick(8)}
              >
                <em>8. GUIDELINES FOR REVIEWS</em>
              </div>
              <div
                style={{ fontSize: "14px", cursor: "pointer" }}
                className="mt-2"
                onClick={() => handleClick(9)}
              >
                <em>9. MOBILE APPLICATION LICENCE</em>
              </div>
              <div
                style={{ fontSize: "14px", cursor: "pointer" }}
                className="mt-2"
                onClick={() => handleClick(10)}
              >
                <em>10. SERVICES MANAGEMENT</em>
              </div>
              <div
                style={{ fontSize: "14px", cursor: "pointer" }}
                className="mt-2"
                onClick={() => handleClick(11)}
              >
                <em>11. PRIVACY POLICY</em>
              </div>
              <div
                style={{ fontSize: "14px", cursor: "pointer" }}
                className="mt-2"
                onClick={() => handleClick(12)}
              >
                <em>12. TERM AND TERMINATION</em>
              </div>
              <div
                style={{ fontSize: "14px", cursor: "pointer" }}
                className="mt-2"
                onClick={() => handleClick(13)}
              >
                <em>13. MODIFICATIONS AND INTERRUPTIONS</em>
              </div>
              <div
                style={{ fontSize: "14px", cursor: "pointer" }}
                className="mt-2"
                onClick={() => handleClick(14)}
              >
                <em>14. GOVERNING LAW</em>
              </div>
              <div
                style={{ fontSize: "14px", cursor: "pointer" }}
                className="mt-2"
                onClick={() => handleClick(15)}
              >
                <em>15. DISPUTE RESOLUTION</em>
              </div>
              <div
                style={{ fontSize: "14px", cursor: "pointer" }}
                className="mt-2"
                onClick={() => handleClick(16)}
              >
                <em>16. CORRECTIONS</em>
              </div>
              <div
                style={{ fontSize: "14px", cursor: "pointer" }}
                className="mt-2"
                onClick={() => handleClick(17)}
              >
                <em>17. DISCLAIMER</em>
              </div>
              <div
                style={{ fontSize: "14px", cursor: "pointer" }}
                className="mt-2"
                onClick={() => handleClick(18)}
              >
                <em>18. LIMITATIONS OF LIABILITY </em>
              </div>
              <div
                style={{ fontSize: "14px", cursor: "pointer" }}
                className="mt-2"
                onClick={() => handleClick(19)}
              >
                <em>19. INDEMNIFICATION </em>
              </div>
              <div
                style={{ fontSize: "14px", cursor: "pointer" }}
                className="mt-2"
                onClick={() => handleClick(20)}
              >
                <em>20. USER DATA</em>
              </div>
              <div
                style={{ fontSize: "14px", cursor: "pointer" }}
                className="mt-2"
                onClick={() => handleClick(21)}
              >
                <em>
                  21. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
                </em>
              </div>
              <div
                style={{ fontSize: "14px", cursor: "pointer" }}
                className="mt-2"
                onClick={() => handleClick(22)}
              >
                <em>22. MISCELLANEOUS</em>
              </div>
              <div
                id={`section-${1}`}
                data-index={1}
                style={{ fontSize: "14px", cursor: "pointer" }}
                className="mt-2"
                onClick={() => handleClick(23)}
              >
                <em>23. COMPLIANCE UNDER INFORMATION TECHNOLOGY ACT, 2000</em>
              </div>
              <div
                style={{ fontSize: "14px", cursor: "pointer" }}
                className="mt-2"
                onClick={() => handleClick(24)}
              >
                <em>24. CONTACT US</em>
              </div>
            </div>
          </div>
          <br />
          <div style={{ marginTop: "10px" }}>
            <h6>
              <b>1. OUR SERVICES </b>
            </h6>
            <div id={`section-${2}`} data-index={2}>
              The information provided when using the Services is not intended
              for distribution to or use by any person or entity in any
              jurisdiction or country where such distribution or use would be
              contrary to law or regulation or which would subject us to any
              registration requirement within such jurisdiction or country.
              Accordingly, those persons who choose to access the Services from
              other locations do so on their own initiative and are solely
              responsible for compliance with local laws, if and to the extent
              local laws are applicable.
            </div>

            <h6 style={{ marginTop: "30px" }}>
              <b>2. INTELLECTUAL PROPERTY RIGHTS </b>
            </h6>
            <div
              style={{ fontSize: "14px", fontWeight: 600, marginTop: "20px" }}
            >
              Our intellectual property{" "}
            </div>
            <div className="mt-3">
              We are the owner or the licensee of all intellectual property
              rights in our Services, including all source code, databases,
              functionality, software, website designs, audio, video, text,
              photographs, and graphics in the Services (collectively, the
              'Content'), as well as the trademarks, service marks, and logos
              contained therein (the 'Marks').
            </div>
            <div className="mt-3">
              The Content and Marks are provided in or through the Services 'AS
              IS' for your personal, non-commercial use only.
            </div>
            <div
              style={{ fontSize: "14px", fontWeight: 600, marginTop: "20px" }}
            >
              Your use of our Services
            </div>
            <div className="mt-3">
              Subject to your compliance with these Legal Terms, including the
              <span
                onClick={() => handleClick(7)}
                style={{
                  color: "blue",
                  cursor: "pointer",
                  textDecoration: "underLine",
                }}
              >
                {" "}
                'PROHIBITED ACTIVITIES'{" "}
              </span>{" "}
              section below, we grant you a non-exclusive, non-transferable,
              revocable licence to:
            </div>
            <div className="mt-3">
              <ul>
                <li>Access the Services; and</li>
                <li className="mt-3">
                  Download or print a copy of any portion of the Content to
                  which you have properly gained access solely for your
                  personal, non-commercial use.
                </li>
              </ul>
            </div>
            <div className="mt-3">
              Except as set out in this section or elsewhere in our Legal Terms,
              no part of the Services and no Content or Marks may be copied,
              reproduced, aggregated, republished, uploaded, posted, publicly
              displayed, encoded, translated, transmitted, distributed, sold,
              licensed, or otherwise exploited for any commercial purpose
              whatsoever, without our express prior written permission.
            </div>
            <div className="mt-3">
              If you wish to make any use of the Services, Content, or Marks
              other than as set out in this section or elsewhere in our Legal
              Terms, please address your request to: support@s3 app.com. If we
              ever grant you the permission to post, reproduce, or publicly
              display any part of our Services or Content, you must identify us
              as the owners or licensors of the Services, Content, or Marks and
              ensure that any copyright or proprietary notice appears or is
              visible on posting, reproducing, or displaying our Content.
            </div>
            <div className="mt-3">
              We reserve all rights not expressly granted to you in and to the
              Services, Content, and Marks.
            </div>
            <div className="mt-3">
              Any breach of these Intellectual Property Rights will constitute a
              material breach of our Legal Terms and your right to use our
              Services will terminate immediately.
            </div>
            <div
              style={{ fontSize: "14px", fontWeight: 600, marginTop: "20px" }}
            >
              Your submissions
            </div>
            <div className="mt-3">
              Please review this section and the{" "}
              <span
                onClick={() => handleClick(7)}
                style={{
                  color: "blue",
                  cursor: "pointer",
                  textDecoration: "underLine",
                }}
              >
                {" "}
                'PROHIBITED ACTIVITIES'{" "}
              </span>{" "}
              section carefully prior to using our Services to understand the
              (a) rights you give us and (b) obligations you have when you
              submit requests or use the Services.
            </div>
            <div className="mt-3">
              Submissions: By directly sending us any question, comment,
              suggestion, idea, feedback, or other information about the
              Services ('Submissions'), you agree to assign to us all
              intellectual property rights in such Submission. You agree that we
              shall own this Submission and be entitled to its unrestricted use
              and dissemination for any lawful purpose, commercial or otherwise,
              without acknowledgment or compensation to you.
            </div>
            <div className="mt-3">
              You are responsible for what you comment, review or add as inputs
              during request creation: By sending us Submissions through any
              part of the Services you:
            </div>
            <ul>
              <li className="mt-3">
                Confirm that you have read and agree with our
                <span
                  onClick={() => handleClick(7)}
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    textDecoration: "underLine",
                  }}
                >
                  {" "}
                  'PROHIBITED ACTIVITIES'{" "}
                </span>{" "}
                and will not post, send, publish, upload, or transmit through
                the Services any Submission that is illegal, harassing, hateful,
                harmful, defamatory, obscene, bullying, abusive, discriminatory,
                threatening to any person or group, sexually explicit, false,
                inaccurate, deceitful, or misleading;{" "}
              </li>
              <li className="mt-3">
                To the extent permissible by applicable law, waive any and all
                moral rights to any such Submission;{" "}
              </li>
              <li className="mt-3">
                Warrant that any such Submission are original to you or that you
                have the necessary rights and licences to submit such
                Submissions and that you have full authority to grant us the
                above-mentioned rights in relation to your Submissions; and
              </li>
              <li className="mt-3" id={`section-${3}`} data-index={3}>
                Warrant and represent that your Submissions do not constitute
                confidential information.
              </li>
            </ul>
            <div className="mt-3">
              You are solely responsible for your Submissions and you expressly
              agree to reimburse us for any and all losses that we may suffer
              because of your breach of (a) this section, (b) any third party’s
              intellectual property rights, or (c) applicable law.
            </div>
          </div>
          <h6 style={{ marginTop: "30px" }}>
            <b>3. USER REPRESENTATIONS</b>
          </h6>
          <div className="mt-3">
            By using the Services, you represent and warrant that: (1) all
            registration information you submit will be true, accurate, current,
            and complete; (2) you will maintain the accuracy of such information
            and promptly update such registration information as necessary; (3)
            you have the legal capacity and you agree to comply with these Legal
            Terms; (4) you are not a minor in the jurisdiction in which you
            reside; (5) you will not access the Services through automated or
            non-human means, whether through a bot, script or otherwise; (6) you
            will not use the Services for any illegal or unauthorised purpose;
            and (7) your use of the Services will not violate any applicable law
            or regulation.
          </div>
          <div className="mt-3" id={`section-${4}`} data-index={4}>
            If you provide any information that is untrue, inaccurate, not
            current, or incomplete, we have the right to suspend or terminate
            your account and refuse any and all current or future use of the
            Services (or any portion thereof).
          </div>
          <h6 style={{ marginTop: "30px" }}>
            <b>4. USER REGISTRATION</b>
          </h6>
          <div className="mt-3" id={`section-${5}`} data-index={5}>
            You may be required to register to use the Services. You agree to
            keep your password confidential and will be responsible for all use
            of your account and password. We reserve the right to remove,
            reclaim, or change a username you select if we determine, in our
            sole discretion, that such username is inappropriate, obscene, or
            otherwise objectionable.
          </div>
          <h6 style={{ marginTop: "30px" }}>
            <b>5. PURCHASES AND PAYMENT </b>
          </h6>
          <div className="mt-3">
            We accept the following forms of payment: UPI, Credit Card, Net
            Banking, Debit Card.
          </div>
          <div className="mt-3">
            You agree to provide current, complete, and accurate purchase and
            account information for all purchases made via the Services. Based
            on our payment gateway partners’ terms and requirements, you further
            agree to provide any details to complete any pending transactions
            and contact you as needed. Sales tax will be added to the price of
            purchases as deemed required by us. We may change prices at any
            time. All payments shall be in Indian Rupee (INR).
          </div>
          <div className="mt-3">
            You agree to pay all charges at the prices then in effect for your
            purchases and any applicable shipping fees, and you authorise us to
            charge your chosen payment provider for any such amounts upon
            placing your order. We reserve the right to correct any errors or
            mistakes in pricing, even if we have already requested or received
            payment.
          </div>
          <div className="mt-3" id={`section-${6}`} data-index={6}>
            We reserve the right to refuse any order placed through the
            Services. We may, in our sole discretion, limit or cancel quantities
            purchased per person, per household, or per order. These
            restrictions may include orders placed by or under the same customer
            account, the same payment method, and/or orders that use the same
            billing or shipping address. We reserve the right to limit or
            prohibit orders that, in our sole judgement, appear to be placed by
            dealers, resellers, or distributors.
          </div>
          <h6 style={{ marginTop: "30px" }} id={`section-${7}`} data-index={7}>
            <b>6. POLICY</b>
          </h6>
          <div className="mt-3">
            All sales are final and no refund will be issued.
          </div>
          <h6 style={{ marginTop: "30px" }}>
            <b>7. PROHIBITED ACTIVITIES</b>
          </h6>
          <div className="mt-3">
            You may not access or use the Services for any purpose other than
            that for which we make the Services available. The Services may not
            be used in connection with any commercial endeavours except those
            that are specifically endorsed or approved by us.{" "}
          </div>
          <div className="mt-3">
            As a user of the Services, you agree not to:{" "}
          </div>
          <ul style={{ fontSize: 14, marginTop: "20px" }}>
            <li className="mt-3" style={{ marginTop: 5 }}>
              Systematically retrieve data or other content from the Services to
              create or compile, directly or indirectly, a collection,
              compilation, database, or directory without written permission
              from us.
            </li>
            <li className="mt-3">
              Trick, defraud, or mislead us and other users, especially in
              trying to learn sensitive account information like user passwords.
            </li>
            <li className="mt-3">
              Circumvent, disable, or otherwise interfere with security-related
              features of the Services, including features that prevent or
              restrict the use or copying of any Content or enforce limitations
              on the use of the Services and/or the Content contained therein.
            </li>
            <li className="mt-3">
              Disparage, tarnish, or otherwise harm, in our opinion, us and/or
              the Services. Use any information obtained from the Services to
              harass, abuse, or harm another person.
            </li>
            <li className="mt-3">
              Make improper use of our support services or submit false reports
              of abuse or misconduct.
            </li>
            <li className="mt-3">
              Use the Services inconsistent with any applicable laws or
              regulations.
            </li>
            <li className="mt-3">
              Engage in unauthorised framing of or linking to the Services.
            </li>
            <li className="mt-3">
              Upload or transmit (or attempt to upload or to transmit) viruses,
              Trojan horses, or other material, including excessive use of
              capital letters and spamming (continuous posting of repetitive
              text), that interferes with any party’s uninterrupted use and
              enjoyment of the Services or modifies, impairs, disrupts, alters,
              or interferes with the use, features, functions, operation, or
              maintenance of the Services.
            </li>
            <li className="mt-3">
              Engage in any automated use of the system, such as using scripts
              to send comments or messages, or using any data mining, robots, or
              similar data gathering and extraction tools.{" "}
            </li>
            <li className="mt-3">
              Delete the copyright or other proprietary rights notice from any
              Content. Attempt to impersonate another user or person or use the
              username of another user.{" "}
            </li>
            <li className="mt-3">
              Upload or transmit (or attempt to upload or to transmit) any
              material that acts as a passive or active information collection
              or transmission mechanism,
            </li>
            <li className="mt-3">
              including without limitation, clear graphics interchange formats
              ('gifs'), 1×1 pixels, web bugs, cookies, or other similar devices
              (sometimes referred to as 'spyware' or 'passive collection
              mechanisms' or 'pcms').
            </li>
            <li className="mt-3">
              Interfere with, disrupt, or create an undue burden on the Services
              or the networks or services connected to the Services.
            </li>
            <li className="mt-3">
              Harass, annoy, intimidate, or threaten any of our employees or
              agents engaged in providing any portion of the Services to you.
            </li>
            <li className="mt-3">
              Attempt to bypass any measures of the Services designed to prevent
              or restrict access to the Services, or any portion of the
              Services.{" "}
            </li>
            <li className="mt-3">
              Copy or adapt the Services' software, including Flash, PHP, HTML,
              JavaScript, or other code.{" "}
            </li>
            <li className="mt-3">
              Except as permitted by applicable law, decipher, decompile,
              disassemble, or reverse engineer any of the software comprising or
              making up a part of the Services.{" "}
            </li>
            <li className="mt-3">
              Except as may be the result of standard search engine or Internet
              browser usage, use, launch, develop, or distribute any automated
              system, including without limitation, any spider, robot, cheat
              utility, scraper, or offline reader that accesses the Services, or
              use or launch any unauthorised script or other software.{" "}
            </li>
            <li className="mt-3" id={`section-${8}`} data-index={8}>
              Use a buying agent or purchasing agent to make purchases on the
              Services. Make any unauthorised use of the Services, including
              collecting usernames and/or email addresses of users by electronic
              or other means for the purpose of sending unsolicited email, or
              creating user accounts by automated means or under false
              pretences.{" "}
            </li>
            <li className="mt-3">
              Use the Services as part of any effort to compete with us or
              otherwise use the Services and/or the Content for any
              revenue-generating endeavour or commercial enterprise.{" "}
            </li>
            <li className="mt-3">Sell or otherwise transfer your profile.</li>
          </ul>
          <h6 style={{ marginTop: "30px" }}>
            <b>8. GUIDELINES FOR REVIEWS</b>
          </h6>
          <div className="mt-3">
            We may provide you areas on the Services to leave reviews or
            ratings. When posting a review, you must comply with the following
            criteria: (1) you should have firsthand experience with the
            person/entity being reviewed; (2) your reviews should not contain
            offensive profanity, or abusive, racist, offensive, or hateful
            language; (3) your reviews should not contain discriminatory
            references based on religion, race, gender, national origin, age,
            marital status, sexual orientation, or disability; (4) your reviews
            should not contain references to illegal activity; (5) you should
            not be affiliated with competitors if posting negative reviews; (6)
            you should not make any conclusions as to the legality of conduct;
            (7) you may not post any false or misleading statements; and (8) you
            may not organise a campaign encouraging others to post reviews,
            whether positive or negative.
          </div>
          <div className="mt-3" id={`section-${9}`} data-index={9}>
            We may accept, reject, or remove reviews in our sole discretion. We
            have absolutely no obligation to screen reviews or to delete
            reviews, even if anyone considers reviews objectionable or
            inaccurate. Reviews are not endorsed by us, and do not necessarily
            represent our opinions or the views of any of our affiliates or
            partners. We do not assume liability for any review or for any
            claims, liabilities, or losses resulting from any review. By posting
            a review, you hereby grant to us a perpetual, non- exclusive,
            worldwide, royalty-free, fully paid, assignable, and sublicensable
            right and licence to reproduce, modify, translate, transmit by any
            means, display, perform, and/or distribute all content relating to
            review.
          </div>
          <h6 style={{ marginTop: "30px" }}>
            <b>9. MOBILE APPLICATION LICENCE </b>
          </h6>
          <div style={{ fontSize: "14px", fontWeight: 600, marginTop: "20px" }}>
            Use Licence
          </div>
          <div className="mt-3">
            If you access the Services via the App, then we grant you a
            revocable, non-exclusive, non transferable, limited right to install
            and use the App on wireless electronic devices owned or controlled
            by you, and to access and use the App on such devices strictly in
            accordance with the terms and conditions of this mobile application
            licence contained in these Legal Terms. You shall not: (1) except as
            permitted by applicable law, decompile, reverse engineer,
            disassemble, attempt to derive the source code of, or decrypt the
            App; (2) make any modification, adaptation, improvement,
            enhancement, translation, or derivative work from the App; (3)
            violate any applicable laws, rules, or regulations in connection
            with your access or use of the App; (4) remove, alter, or obscure
            any proprietary notice (including any notice of copyright or
            trademark) posted by us or the licensors of the App; (5) use the App
            for any revenue- generating endeavour, commercial enterprise, or
            other purpose for which it is not designed or intended; (6) make the
            App available over a network or other environment permitting access
            or use by multiple devices or users at the same time; (7) use the
            App for creating a product, service, or software that is, directly
            or indirectly, competitive with or in any way a substitute for the
            App; (8) use the App to send automated queries to any website or to
            send any unsolicited commercial email; or (9) use any proprietary
            information or any of our interfaces or our other intellectual
            property in the design, development, manufacture, licensing, or
            distribution of any applications, accessories, or devices for use
            with the App.
          </div>
          <div style={{ fontSize: "14px", fontWeight: 600, marginTop: "20px" }}>
            Apple and Android Devices
          </div>
          <div className="mt-3">
            The following terms apply when you use the App obtained from either
            the Apple Store or Google Play (each an 'App Distributor') to access
            the Services: (1) the licence granted to you for our App is limited
            to a non-transferable licence to use the application on a device
            that utilises the Apple iOS or Android operating systems, as
            applicable, and in accordance with the usage rules set forth in the
            applicable App Distributor’s terms of service; (2) we are
            responsible for providing any maintenance and support services with
            respect to the App as specified in the terms and conditions of this
            mobile application licence contained in these Legal Terms or as
            otherwise required under applicable law, and you acknowledge that
            each App Distributor has no obligation whatsoever to furnish any
            maintenance and support services with respect to the App; (3) in the
            event of any failure of the App to conform to any applicable
            warranty, you may notify the applicable App Distributor, and the App
            Distributor, in accordance with its terms and policies, may refund
            the purchase price, if any, paid for the App, and to the maximum
            extent permitted by applicable law, the App Distributor will have no
            other warranty obligation whatsoever with respect to the App; (4)
            you represent and warrant that (i) you are not located in a country
            that is subject to any government embargo, or that has been
            designated by the any government as a 'terrorist supporting' country
            and (ii) you are not listed on any government list of prohibited or
            restricted parties; (5) you must comply with applicable third-party
            terms of agreement when using the App, e.g. if you have a VoIP
            application,<span id={`section-${10}`} data-index={10}></span> then
            you must not be in violation of their wireless data service
            agreement when using the App; and (6) you acknowledge and agree that
            the App Distributors are third party beneficiaries of the terms and
            conditions in this mobile application licence contained in these
            Legal Terms, and that each App Distributor will have the right (and
            will be deemed to have accepted the right) to enforce the terms and
            conditions in this mobile application licence contained in these
            Legal Terms against you as a third-party beneficiary thereof.
          </div>
          <h6 style={{ marginTop: "30px" }}>
            <b>10. SERVICES MANAGEMENT </b>
          </h6>
          <div className="mt-3" id={`section-${11}`} data-index={11}>
            We reserve the right, but not the obligation, to (1) monitor the
            Services for violations of these Legal Terms; (2) take appropriate
            legal action against anyone who, in our sole discretion, violates
            the law or these Legal Terms, including without limitation,
            reporting such user to law enforcement authorities; (3) in our sole
            discretion and without limitation, refuse, restrict access to, limit
            the availability of, or disable (to the extent technologically
            feasible) any of your submissions or any portion thereof; (4) in our
            sole discretion and without limitation, notice, or liability, to
            remove from the Services or otherwise disable all files and content
            that are excessive in size or are in any way burdensome to our
            systems, and (5) otherwise manage the Services in a manner designed
            to protect our rights and property and to facilitate the proper
            functioning of the Services.
          </div>
          <h6 style={{ marginTop: "30px" }}>
            <b>11. PRIVACY POLICY </b>
          </h6>
          <div className="mt-3" id={`section-${12}`} data-index={12}>
            We care about data privacy and security. Please review our Privacy
            Policy available on our website and mobile application. By using the
            Services, you agree to be bound by our Privacy Policy, which is
            incorporated into these Legal Terms. Please be advised the Services
            are hosted in India. If you access the Services from any other
            region of the world with laws or other requirements governing
            personal data collection, use, or disclosure that differ from
            applicable laws in India, then through your continued use of the
            Services, you are transferring your data to India, and you expressly
            consent to have your data transferred to and processed in India.
          </div>
          <h6 style={{ marginTop: "30px" }}>
            <b>12. TERM AND TERMINATION </b>
          </h6>
          <div className="mt-3">
            These Legal Terms shall remain in full force and effect while you
            use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE
            LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND
            WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES
            (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY
            REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF
            ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL
            TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR
            USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY
            CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING,
            IN OUR SOLE DISCRETION.
          </div>
          <div className="mt-3" id={`section-${13}`} data-index={13}>
            If we terminate or suspend your account for any reason, you are
            prohibited from registering and creating a new account under your
            name, a fake or borrowed name, or the name of any third party, even
            if you may be acting on behalf of the third party. In addition to
            terminating or suspending your account, we reserve the right to take
            appropriate legal action, including without limitation pursuing
            civil, criminal, and injunctive redress.
          </div>
          <h6 style={{ marginTop: "30px" }}>
            <b>13. MODIFICATIONS AND INTERRUPTIONS </b>
          </h6>
          <div className="mt-3">
            We reserve the right to change, modify, or remove the contents of
            the Services at any time or for any reason at our sole discretion
            without notice. However, we have no obligation to update any
            information on our Services. We also reserve the right to modify or
            discontinue all or part of the Services without notice at any time.
            We will not be liable to you or any third party for any
            modification, price change, suspension, or discontinuance of the
            Services.
          </div>
          <div className="mt-3" id={`section-${14}`} data-index={14}>
            We cannot guarantee the Services will be available at all times. We
            may experience hardware, software, or other problems or need to
            perform maintenance related to the Services, resulting in
            interruptions, delays, or errors. We reserve the right to change,
            revise, update, suspend, discontinue, or otherwise modify the
            Services at any time or for any reason without notice to you. You
            agree that we have no liability whatsoever for any loss, damage, or
            inconvenience caused by your inability to access or use the Services
            during any downtime or discontinuance of the Services. Nothing in
            these Legal Terms will be construed to obligate us to maintain and
            support the Services or to supply any corrections, updates, or
            releases in connection therewith.
          </div>
          <h6 style={{ marginTop: "30px" }}>
            <b>14. GOVERNING LAW </b>
          </h6>
          <div className="mt-3" id={`section-${15}`} data-index={15}>
            These Legal Terms shall be governed by and defined following the
            laws of India. Secure Sales Service (S3) App and yourself
            irrevocably consent that the courts of India shall have exclusive
            jurisdiction to resolve any dispute which may arise in connection
            with these Legal Terms.
          </div>
          <h6 style={{ marginTop: "30px" }}>
            <b>15. DISPUTE RESOLUTION </b>
          </h6>
          <div style={{ fontSize: "14px", fontWeight: 600, marginTop: "20px" }}>
            Informal Negotiations
          </div>
          <div className="mt-3">
            To expedite resolution and control the cost of any dispute,
            controversy, or claim related to these Legal Terms (each a 'Dispute'
            and collectively, the 'Disputes') brought by either you or us
            (individually, a 'Party' and collectively, the 'Parties'), the
            Parties agree to first attempt to negotiate any Dispute (except
            those Disputes expressly provided below) informally for at least
            ninety (90) days before initiating arbitration. Such informal
            negotiations commence upon written notice from one Party to the
            other Party.
          </div>
          <div style={{ fontSize: "14px", fontWeight: 600, marginTop: "20px" }}>
            Binding Arbitration
          </div>
          <div className="mt-3">
            Any dispute arising out of or in connection with these Legal Terms,
            including any question regarding its existence, validity, or
            termination, shall be referred to and finally settled under the
            Arbitration and Conciliation Act, 1996 (India), which, as a result
            of referring to it, is considered as the part of this clause. The
            number of arbitrators shall be three (3). The seat, or legal place,
            or arbitration shall be Karur, Tamil Nadu, India. The language of
            the proceedings shall be Tamil (preferable), English. The governing
            law of these Legal Terms shall be substantive law of India.
          </div>
          <div style={{ fontSize: "14px", fontWeight: 600, marginTop: "20px" }}>
            Restrictions
          </div>
          <div className="mt-3">
            The Parties agree that any arbitration shall be limited to the
            Dispute between the Parties individually. To the full extent
            permitted by law, (a) no arbitration shall be joined with any other
            proceeding; (b) there is no right or authority for any Dispute to be
            arbitrated on a class action basis or to utilise class action
            procedures; and (c) there is no right or authority for any Dispute
            to be brought in a purported representative capacity on behalf of
            the general public or any other persons.
          </div>
          <div style={{ fontSize: "14px", fontWeight: 600, marginTop: "20px" }}>
            Exceptions to Informal Negotiations and Arbitration
          </div>
          <div className="mt-3" id={`section-${16}`} data-index={16}>
            The Parties agree that the following Disputes are not subject to the
            above provisions concerning informal negotiations binding
            arbitration: (a) any Disputes seeking to enforce or protect, or
            concerning the validity of, any of the intellectual property rights
            of a Party; (b) any Dispute related to, or arising from, allegations
            of theft, piracy, invasion of privacy, or unauthorised use; and (c)
            any claim for injunctive relief. If this provision is found to be
            illegal or unenforceable, then neither Party will elect to arbitrate
            any Dispute falling within that portion of this provision found to
            be illegal or unenforceable and such Dispute shall be decided by a
            court of competent jurisdiction within the courts listed for
            jurisdiction above, and the Parties agree to submit to the personal
            jurisdiction of that court.
          </div>
          <h6 style={{ marginTop: "30px" }}>
            <b>16. CORRECTIONS</b>
          </h6>
          <div className="mt-3" id={`section-${17}`} data-index={17}>
            There may be information on the Services that contains typographical
            errors, inaccuracies, or omissions, including descriptions, pricing,
            availability, and various other information. We reserve the right to
            correct any errors, inaccuracies, or omissions and to change or
            update the information on the Services at any time, without prior
            notice.{" "}
          </div>
          <h6 style={{ marginTop: "30px" }}>
            <b>17. DISCLAIMER</b>
          </h6>
          <div className="mt-3">
            THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
            AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO
            THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
            EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE
            THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-
            INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
            ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF
            ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE
            WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS,
            MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL
            INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM
            YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORISED ACCESS
            TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
            INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY
            INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES,
            (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
            TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR
            (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY
            LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY
            CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE
            SERVICES. <span id={`section-${18}`} data-index={18}></span> WE DO
            NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
            PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH
            THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE
            APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL
            NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
            TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR
            SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY
            MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGEMENT AND
            EXERCISE CAUTION WHERE APPROPRIATE.
          </div>
          <h6 style={{ marginTop: "30px" }}>
            <b>18. LIMITATIONS OF LIABILITY </b>
          </h6>
          <div className="mt-3">
            IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
            TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
            EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
            PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM
            YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES.{" "}
          </div>
          <div className="mt-3" id={`section-${19}`} data-index={19}>
            NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR
            LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM
            OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF
            ANY, BY YOU TO US DURING THE THREE (3) MONTH PERIOD PRIOR TO ANY
            CAUSE OF ACTION ARISING. IF ANY WAIVERS BASED ON ANY US STATE LAWS
            AND INTERNATIONAL LAWS ARE APPLICABLE TO YOUON IMPLIED WARRANTIES OR
            THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, YOU ARE REQUIRED TO
            INFORM US BEFORE SERVICE DELIVERY. ELSE, LAWS APPLICABLE TO ALL WITH
            NO ADDITIONAL RIGHTS WILL BE EFFECTIVE.
          </div>
          <h6 style={{ marginTop: "30px" }}>
            <b>19. INDEMNIFICATION</b>
          </h6>
          <div className="mt-3">
            You agree to defend, indemnify, and hold us harmless, including our
            subsidiaries, affiliates, and all of our respective officers,
            agents, partners, and employees, from and against any loss, damage,
            liability, claim, or demand, including reasonable attorneys’ fees
            and expenses, made by any third party due to or arising out of: (1)
            use of the Services; (2) breach of these Legal Terms; (3) any breach
            of your representations and warranties set forth in these Legal
            Terms; (4) your violation of the rights of a third party, including
            but not limited to intellectual property rights; or (5) any overt
            harmful act toward any other user of the Services with whom you
            connected via the Services. Notwithstanding the foregoing, we
            reserve the right,{" "}
            <span id={`section-${20}`} data-index={20}></span> at your expense,
            to assume the exclusive defence and control of any matter for which
            you are required to indemnify us, and you agree to cooperate, at
            your expense, with our defence of such claims. We will use
            reasonable efforts to notify you of any such claim, action, or
            proceeding which is subject to this indemnification upon becoming
            aware of it.{" "}
          </div>
          <h6 style={{ marginTop: "30px" }}>
            <b>20. USER DATA </b>
          </h6>
          <div className="mt-3" id={`section-${21}`} data-index={21}>
            We will maintain certain data that you transmit to the Services for
            the purpose of managing the performance of the Services, as well as
            data relating to your use of the Services. Although we perform
            regular routine backups of data, you are solely responsible for all
            data that you transmit or that relates to any activity you have
            undertaken using the Services. You agree that we shall have no
            liability to you for any loss or corruption of any such data, and
            you hereby waive any right of action against us arising from any
            such loss or corruption of such data.
          </div>
          <h6 style={{ marginTop: "30px" }}>
            <b>21. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES </b>
          </h6>
          <div className="mt-3" id={`section-${22}`} data-index={22}>
            Visiting the Services, sending us emails, and completing online
            forms constitute electronic communications. You consent to receive
            electronic communications, and you agree that all agreements,
            notices, disclosures, and other communications we provide to you
            electronically, via email and on the Services, satisfy any legal
            requirement that such communication be in writing. YOU HEREBY AGREE
            TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
            RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND
            RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE
            SERVICES. You hereby waive any rights or requirements under any
            statutes, regulations, rules, ordinances, or other laws in any
            jurisdiction which require an original signature or delivery or
            retention of non-electronic records, or to payments or the granting
            of credits by any means other than electronic means.
          </div>
          <h6 style={{ marginTop: "30px" }}>
            <b>22. MISCELLANEOUS </b>
          </h6>
          <div className="mt-3">
            These Legal Terms and any policies or operating rules posted by us
            on the Services or in respect to the Services constitute the entire
            agreement and understanding between you and us. Our failure to
            exercise or enforce any right or provision of these Legal Terms
            shall not operate as a waiver of such right or provision. These
            Legal Terms operate to the fullest extent permissible by law. We may
            assign any or all of our rights and obligations to others at any
            time. We shall not be responsible or liable for any loss, damage,
            delay, or failure to act caused by any cause beyond our reasonable
            control. If any provision or part of a provision of these Legal
            Terms is determined to be unlawful, void, or unenforceable, that
            provision or part of the provision is deemed severable from these
            Legal Terms and does not affect the validity and enforceability of
            any remaining provisions. There is no joint venture, partnership,
            employment or agency relationship created between you and us as a
            result of these Legal Terms or use of the Services. You agree that
            these Legal Terms will not be construed against us by virtue of
            <div id={`section-${23}`} data-index={23}></div>
            having drafted them. You hereby waive any and all defences you may
            have based on the electronic form of these Legal Terms and the lack
            of signing by the parties hereto to execute these Legal Terms.
          </div>
          <div id={`section-${23}`} data-index={23}></div>
          <h6 style={{ marginTop: "30px" }}>
            <b>23. COMPLIANCE UNDER INFORMATION TECHNOLOGY ACT, 2000 </b>
          </h6>
          <p style={{ fontSize: 14, marginTop: "20px" }}>
            In accordance with the Information Technology Act, 2000 and the
            rules made thereunder, the Grievance Officer for complaints and
            grievances can be contacted at:
          </p>
          <p style={{ fontSize: 14, marginTop: "20px" }}>
            Grievance-cum-Data Protection Officer,
          </p>
          <p style={{ fontSize: 14, marginTop: "10px" }}>
            S3 – Secure Sales Service,
          </p>
          <p style={{ fontSize: 14, marginTop: "10px" }}>
            Vinayaka Electronics and GPS,{" "}
          </p>
          <p style={{ fontSize: 14, marginTop: "10px" }}>
            20/2, VAIYAPURI NAGAR,{" "}
          </p>
          <p style={{ fontSize: 14, marginTop: "10px" }}>
            SECOND CROSS, KARUR,{" "}
          </p>
          <p style={{ fontSize: 14, marginTop: "10px" }}>Tamil Nadu, 639002 </p>
          <p
            id={`section-${24}`}
            data-index={24}
            style={{ fontSize: 14, marginTop: "10px" }}
          >
            <b>Ph:</b> <a href="tel:+919600300459">+91 9600300459</a>
          </p>{" "}
          <p style={{ fontSize: 14 }}>
            <b>Email:</b>{" "}
            <a href="mailto:support@s3-app.com">support@s3-app.com</a>
          </p>
          <h6 style={{ marginTop: "30px" }}>
            <b>24. CONTACT US </b>
          </h6>
          <p style={{ fontSize: 14, marginTop: "20px" }}>
            In order to resolve a complaint regarding the Services or to receive
            further information regarding use of the Services, please contact us
            at:
          </p>
          <p style={{ fontSize: 14, marginTop: "10px" }}>
            Secure Sales Service (S3) App
          </p>
          <p style={{ fontSize: 14, marginTop: "10px" }}>
            Vinayaka Electronics and GPS,{" "}
          </p>
          <p style={{ fontSize: 14, marginTop: "10px" }}>
            20/2, VAIYAPURI NAGAR,{" "}
          </p>
          <p style={{ fontSize: 14, marginTop: "10px" }}>
            SECOND CROSS, KARUR,{" "}
          </p>
          <p style={{ fontSize: 14, marginTop: "10px" }}>Tamil Nadu, 639002 </p>
          <p style={{ fontSize: 14, marginTop: "10px" }}>
            <b>Ph:</b> <a href="tel:+919600300459">+91 9600300459</a>
          </p>{" "}
          <p style={{ fontSize: 14 }}>
            <b>Email:</b>{" "}
            <a href="mailto:support@s3-app.com">support@s3-app.com</a>
          </p>
        </div>
      </div>
      {!localStorage.getItem("token") && <Footer />}
    </div>
  );
}
