import React from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Dashboardchart1 = () => {
  const { technicianDashboardData } = useSelector(
    (state) => state.technicianDashboardState
  );

  const revenue = technicianDashboardData?.applianceSRCount?.map((val) => val?.revenue) || [];

  const appliance_name = technicianDashboardData?.applianceSRCount?.map((val) => {
    const name = val?.appliance_name || "";
    const revenue = val?.revenue || 0;
    // Check if the name is longer than 15 letters
    if (name.length > 15) {
      return name.slice(0, 10) + "..." + ": ₹" + revenue; // Slice the name and add ellipsis
    } else {
      return name + ": ₹" + revenue; // Return the original name and revenue
    }
  }) || [];

  const chartOptions = {
    series: revenue,
    labels: appliance_name,
    chart: {
      type: "pie",
    },
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className="d-flex align-items-center" id="chart" style={{ height: 320 }}>
      {revenue.length > 0 && appliance_name.length > 0 ? (
        <div style={{ overflowY: "auto", width: "100%" }}>
          <Chart
            options={chartOptions}
            series={chartOptions.series}
            type="pie"
            width={isSmallScreen ? "470px" : "470px"}
          />
        </div>
      ) : (
        <div
          className="d-flex align-items-center"
          style={{ width: "100%", justifyContent: "center" }}
        >
          No data found
        </div>
      )}
    </div>
  );
};

export default Dashboardchart1;
