import React, { useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import googlepay from "../../Images/googlepay.png";
import paytm from "../../Images/paytm.png";
import visa from "../../Images/visa.png";
import InputMask from "react-input-mask";
import Topbar from "../topbar";
import { Card } from "@mui/material";

const Checkout = () => {
  const [expanded, setExpanded] = React.useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [isOrderAccordionOpen, setIsOrderAccordionOpen] = useState(false);

  const handleAccordionToggle = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const handleOrderAccordionToggle = () => {
    setIsOrderAccordionOpen(!isOrderAccordionOpen);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <div className="row">
        <Topbar />
      </div>

      <div className="container top">
        <div className="">
          <text style={{ fontWeight: "600", fontSize: "21px" }}>Checkout</text>
          <div className="row mt-lg-3 mt-2">
            <div className="col-lg-7 col-12">
              <Accordion
                expanded={isAccordionOpen}
                onChange={handleAccordionToggle}
              >
                <AccordionSummary>
                  <Typography>
                    <text style={{ fontSize: "18px", fontWeight: "500" }}>
                      1 Delivery Address{" "}
                    </text>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="row">
                      <div className="col-6">
                        <TextField
                          fullWidth
                          label=" Name"
                          id="full-name"
                          type="text"
                          placeholder="Home Address"
                        />

                        <TextField
                          style={{ height: "47px" }}
                          className="w-100 mt-3"
                          // style={{height:'52px'}}
                          id="mobile-number"
                          label="Enter your phone number"
                          type="number"
                          placeholder=" 90000 00009"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="end">
                                +91 |
                              </InputAdornment>
                            ),
                          }}
                        />

                        <TextField
                          className="mt-3"
                          fullWidth
                          label="City"
                          select
                        />
                      </div>

                      <div className="col-6">
                        <TextField
                          fullWidth
                          label="Address Line 1"
                          id="full-name"
                          type="text"
                          placeholder="Type your door no, street name"
                        />

                        <select
                          class="form-select form-select-lg mt-lg-3"
                          aria-label="Default select example"
                        >
                          <option selected>State</option>
                          <option value="1"></option>
                          <option value="2"></option>
                          <option value="3"></option>
                        </select>

                        <TextField
                          className="mt-3"
                          fullWidth
                          label="Postal Code"
                        />
                      </div>
                    </div>

                    <div className="mt-3">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox1"
                        value="option1"
                      />
                      <text
                        className="ms-2"
                        style={{
                          fontSize: "15px",
                          fontWeight: "400",
                          color: "#6A707C",
                        }}
                      >
                        Set as default
                      </text>
                    </div>

                    <button
                      class="btn mt-2"
                      type="button"
                      style={{
                        width: "240px",
                        height: "47px",
                        color: "white",
                        backgroundColor: "#EB8128",
                        borderColor: "#EB8128",
                      }}
                    >
                      Confirm Address
                    </button>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                className="mt-2"
                expanded={isOrderAccordionOpen}
                onChange={handleOrderAccordionToggle}
              >
                <AccordionSummary>
                  <Typography>
                    <text style={{ fontSize: "18px", fontWeight: "500" }}>
                      2 Order Details{" "}
                    </text>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                className="mt-2"
                //   expanded={expanded === 'panel3'} onChange={handleChange('panel3')}
              >
                <AccordionSummary
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>
                    <text style={{ fontSize: "18px", fontWeight: "500" }}>
                      {" "}
                      3 Make Payment{" "}
                    </text>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Accordion
                      expanded={expanded === "panel1"}
                      onChange={handleChange("panel1")}
                    >
                      <AccordionSummary
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>
                          <div>
                            <input
                              style={{ borderRadius: "10px" }}
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              checked={expanded === "panel1"}
                              readOnly
                            />
                            <text
                              className="ms-2"
                              style={{ fontSize: "18px", fontWeight: "400" }}
                            >
                              UPI
                            </text>
                          </div>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <div>
                            <ul
                              className="m-0 p-0"
                              style={{ display: "inline-flex" }}
                            >
                              <li style={{ listStyle: "none" }}>
                                <div
                                  className="border border rounded"
                                  style={{ width: "123px", height: "48px" }}
                                >
                                  <img
                                    alt="demo"
                                    className="ms-4 mt-3"
                                    src={googlepay}
                                    style={{ width: "55px" }}
                                  />
                                </div>
                              </li>
                              <li
                                className="ms-3"
                                style={{ listStyle: "none" }}
                              >
                                <div
                                  className="border border rounded "
                                  style={{ width: "123px", height: "48px" }}
                                >
                                  <img
                                    alt="demo"
                                    className="ms-4 mt-3"
                                    src={paytm}
                                    style={{ width: "55px" }}
                                  />
                                </div>
                              </li>
                            </ul>
                          </div>

                          <div className="mt-2">
                            <TextField
                              style={{ width: "475px" }}
                              fullWidth
                              label="Enter UPI ID"
                              id="fullWidth"
                            />
                          </div>
                          <button
                            class="btn mt-2"
                            type="button"
                            style={{
                              width: "185px",
                              height: "47px",
                              color: "white",
                              backgroundColor: "#EB8128",
                              borderColor: "#EB8128",
                            }}
                          >
                            Pay 2034.72
                          </button>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      expanded={expanded === "panel2"}
                      onChange={handleChange("panel2")}
                    >
                      <AccordionSummary
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>
                          <div>
                            <input
                              style={{ borderRadius: "10px" }}
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              checked={expanded === "panel2"}
                            />
                            <text
                              className="ms-2"
                              style={{ fontSize: "18px", fontWeight: "400" }}
                            >
                              SBI Credit Card ****4726
                            </text>
                          </div>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <h1>welcome all back</h1>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      expanded={expanded === "panel3"}
                      onChange={handleChange("panel3")}
                    >
                      <AccordionSummary
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>
                          <div>
                            <input
                              style={{ borderRadius: "10px" }}
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              checked={expanded === "panel3"}
                              readOnly
                            />
                            <text
                              className="ms-2"
                              style={{ fontSize: "18px", fontWeight: "400" }}
                            >
                              Debit / Credit Card
                            </text>
                          </div>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <InputMask
                            mask="9999 - 9999 - 9999 - 9999"
                            maskChar="X"
                            formatChars={{ "9": "[0-9]" }}
                          >
                            {() => (
                              <TextField
                                style={{ width: "475px" }}
                                className=" mt-lg-1"
                                id="outlined-basic"
                                defaultValue="4731 - "
                                label="Card Number"
                                variant="outlined"
                                placeholder="XXXX - XXXX - XXXX - XXXX"
                                // error={Iscard ? true : false}
                                // helperText={Iscard ? "Please Type Your Card Details" : null}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      style={{ color: "red" }}
                                      position="start"
                                      className="text-primary"
                                    >
                                      <img src={visa} alt="Visa Card" />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          </InputMask>

                          <div className="row mt-3">
                            <div className="col-5">
                              <TextField
                                fullWidth
                                label="Expiry Date"
                                id="fullWidth"
                                placeholder="MM / YYYY"
                              />
                            </div>
                            <div className="col-5">
                              <TextField
                                fullWidth
                                label="CVV Number"
                                id="fullWidth"
                                placeholder="XXX"
                              />
                            </div>
                            <div className="col-3"></div>
                          </div>

                          <TextField
                            className="mt-3"
                            style={{ width: "475px" }}
                            label="Card Holder Name"
                            placeholder="Enter card holder name"
                          />

                          <div className="mt-3">
                            <input
                              style={{ borderRadius: "10px" }}
                              class="form-check-input"
                              type="checkbox"
                              id="inlineCheckbox1"
                              value="option1"
                            />
                            <text
                              className="ms-3"
                              style={{
                                fontSize: "15px",
                                fontWeight: "400",
                                color: "#6A707C",
                              }}
                            >
                              Accept to save card as per the RBI Regulations
                            </text>
                          </div>

                          <button
                            class="btn mt-2"
                            type="button"
                            style={{
                              width: "185px",
                              height: "47px",
                              color: "white",
                              backgroundColor: "#EB8128",
                              borderColor: "#EB8128",
                            }}
                          >
                            Pay 2034.72
                          </button>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      expanded={expanded === "panel4"}
                      onChange={handleChange("panel4")}
                    >
                      <AccordionSummary
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>
                          <div>
                            <input
                              style={{ borderRadius: "10px" }}
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              checked={expanded === "panel4"}
                            />
                            <text
                              className="ms-2"
                              style={{ fontSize: "18px", fontWeight: "400" }}
                            >
                              Cash on Delivery
                            </text>
                          </div>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <h1>welcome all</h1>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>

            <div className="col-lg-5 col-12">
              <Card>
                <div className="p-3">
                  <text style={{ fontSize: "18px", fontWeight: "500" }}>
                    Payment Details
                  </text>
                  <div className="row mt-3">
                    <div className="col-6 text-start">
                      <text style={{ fontWeight: "300", fontSize: "18px" }}>
                        Total Order (₹):
                      </text>
                    </div>
                    <div className="col-6 text-end">
                      <text style={{ fontWeight: "600", fontSize: "18px" }}>
                        2034.72
                      </text>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-6 text-start">
                      <text style={{ fontWeight: "300", fontSize: "18px" }}>
                        Delivery charges (₹):
                      </text>
                    </div>
                    <div className="col-6 text-end">
                      <text style={{ fontWeight: "600", fontSize: "18px" }}>
                        30.00
                      </text>
                    </div>
                  </div>

                  <div className="row mt-3 mb-lg-4">
                    <div className="col-6 text-start">
                      <text style={{ fontWeight: "300", fontSize: "18px" }}>
                        Discount (₹):
                      </text>
                    </div>
                    <div className="col-6 text-end">
                      <text
                        style={{
                          fontWeight: "500",
                          fontSize: "18px",
                          color: "green",
                        }}
                      >
                        -30.00
                      </text>
                    </div>
                  </div>
                  <hr />

                  <div className="row">
                    <div className="col-6 text-start">
                      <text style={{ fontWeight: "600", fontSize: "21px" }}>
                        Total Payable (₹)
                      </text>
                    </div>
                    <div className="col-6 text-end">
                      <text style={{ fontWeight: "600", fontSize: "21px" }}>
                        2034.72
                      </text>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Checkout;
