import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  InputBase,
  Paper,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Modal } from "react-bootstrap";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { FaCalendarDays } from "react-icons/fa6";
import moment from "moment";
import Api from "../service/services";
import SearchableDropdownDistrict from "../components/common/searchableDropDownDistrict";
import SearchableDropdownZone from "../components/common/searchableDropDownZone";
import { useLocation } from "react-router-dom";

const CustomerFilter = ({
  inputPlaceholder,
  onSearchInputChange,
  onChangeFilterData,
}) => {
  const ROLE = localStorage.getItem("role");
  const { pathname: PATH } = useLocation();
  const [filterModal, setFilterModal] = useState(false);
  const [districtList, setDistrictList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedZone, setSelectedZone] = useState("");
  const [viewDatePickerPopover, setViewDatePickerPopover] = useState(false);
  const [maxAmount, setMaxAmount] = useState("");
  const [minAmount, setMinAmount] = useState("");
  const [clearFilter, setClearFilter] = useState(false);
  const currentDate = new Date();
  const [filterApplied, setFilterApplied] = useState(false);
  const [clickTrue, setClickTrue] = useState(false);

  const defaultDate = [
    {
      startDate: currentDate,
      endDate: currentDate,
      key: "selection",
    },
  ];
  const [dateRange, setDateRange] = useState(defaultDate);
  const handleDistrictSelection = (e) => {
    setSelectedDistrict(e);
  };
  const handleZoneList = (e) => {
    setSelectedDistrict("");
    setSelectedZone(e);
    Api.getDistrictsByZone(e.id).then((result) => {
      setDistrictList(result);
    });
  };
  const initialApiRequests = () => {
    Api.getAllZones().then((result) => {
      setZoneList(result);
    });
    Api.getDistrictsByZone().then((result) => {
      setDistrictList(result);
    });
  };
  const handleFilterCancel = () => {
    setSelectedDistrict("");
    setSelectedZone("");
    setFilterModal(false);
    Api.getDistrictsByZone().then((result) => {
      setDistrictList(result);
    });
  };
  const handleApplyFilter = () => {
    if (clickTrue) {
      onChangeFilterData({
        startDate: clearFilter
          ? ""
          : moment(dateRange[0]?.startDate).format("YYYY-MM-DD"),
        endDate: clearFilter
          ? ""
          : moment(dateRange[0]?.endDate).format("YYYY-MM-DD"),
        maxWallet: maxAmount,
        minWallet: minAmount,
        district: selectedDistrict ? selectedDistrict?.name : "",
        zone_id: selectedZone ? selectedZone?.id : "",
      });
    } else {
      onChangeFilterData({
        minWallet: minAmount,
        district: selectedDistrict ? selectedDistrict?.name : "",
        zone_id: selectedZone ? selectedZone?.id : "",
      });
    }
    setFilterModal(false);
    setClearFilter(false);
    if (clearFilter) {
      setFilterApplied(false);
    } else {
      setFilterApplied(true);
    }
  };
  useEffect(() => {
    initialApiRequests();
  }, []);
  const allowModule = (data) => {
    const allowedRoles = data?.roles;
    const allowedPaths = data?.paths;
    if (
      allowedRoles.some((role) => role === ROLE) &&
      allowedPaths.some((path) => path === PATH)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const clearData = () => {
    setSelectedZone("");
    setClearFilter(true);
    setMaxAmount();
    setMinAmount();
    setSelectedDistrict('');
    // setDateRange(defaultDate);
    setClickTrue(false);
    Api.getDistrictsByZone().then((result) => {
      setDistrictList(result);
    });
  };
  const DatePicker = () => {
    const [dateRangeSelected, setDateRangeSelected] = useState(defaultDate);
    const handleSelectDate = (ranges) => {
      setDateRangeSelected([ranges.selection]);
    };
    const handleCancelDate = () => {
      // setDateRange(defaultDate);
      setClickTrue(false);
      setViewDatePickerPopover(false);
      setDateRangeSelected(defaultDate);
    };
    const handleSetSelectedDate = () => {
      setClickTrue(true);
      setDateRange(dateRangeSelected);
      setViewDatePickerPopover(false);
      setDateRangeSelected(defaultDate);
      setClearFilter(false);
    };
    const mobileView = useMediaQuery(
      "(min-width: 200px) and (max-width: 799px)"
    );
    return <div></div>;
  };
  return (
    <div>
      <div
        className="p-0 mt-2"
        style={{
          display: "flex",
          width: "-webkit-fill-available",
        }}
      >
        <div
          style={{
            listStyle: "none",
            width: "-webkit-fill-available",
            display: "flex",
          }}
        >
          <Paper
            elevation={0}
            className="w-100 p-lg-3 border rounded-0"
            component="form"
            sx={{ display: "flex", alignItems: "center" }}
            style={{ width: "100%", height: "42px" }}
          >
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              style={{
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "14px",
              }}
              placeholder={inputPlaceholder}
              //   onKeyUp={searchFilter}
              onChange={(e) => onSearchInputChange(e.target.value)}
            />
          </Paper>
          {allowModule({
            roles: [
              "SuperAdmin",
              "ZonalAdmin",
              "Technician",
              "Consumer",
            ],
            paths: [
              "/usermanagement",
              "/service-list-management",
              "/service-list-technician",
              "/serviceList",
            ],
          }) && (
              <div className="ms-2" style={{ listStyle: "none" }}>
                <IconButton
                  style={{ width: "42px", height: "42px" }}
                  className="border rounded-0"
                  aria-label="delete"
                  onClick={() => {
                    setFilterModal(true);
                    setClearFilter(false);
                  }}
                >
                  {filterApplied && (
                    <Tooltip title="Filter applied" arrow>
                      <div className="filter-indicator">
                        <div className="ic" />
                      </div>
                    </Tooltip>
                  )}
                  <FilterListIcon style={{ color: "rgb(235, 129, 40)" }} />
                </IconButton>
              </div>
            )}
        </div>
      </div>
      <Modal
        show={filterModal}
        onHide={() => setFilterModal(false)}
        centered
        size="lg"
        className="filter-modal"
      >
        <div className="p-3">
          <div
            style={{
              fontWeight: "600",
              fontSize: "25px",
            }}
          >
            Filter
          </div>
          <div className="mt-3">
            <div>
              <div className="row">
                {allowModule({
                  roles: [
                    "SuperAdmin",
                    "ZonalAdmin",
                    "DistrictAdmin",
                    "Technician",
                    "Consumer",
                  ],
                  paths: [
                    "/usermanagement",
                    "/service-list-management",
                    "/service-list-technician",
                    "/serviceList",
                  ],
                }) && (
                    <div className="col-12 mt-3  col-lg-6">
                      {/* <DatePicker /> */}
                    </div>
                  )}
                {allowModule({
                  roles: ["SuperAdmin", "ZonalAdmin", "DistrictAdmin"],
                  paths: ["/usermanagement"],
                }) && <div className="col-12 col-lg-6"></div>}
              </div>
              <div className="row">
                {allowModule({
                  roles: ["SuperAdmin"],
                  paths: ["/usermanagement", "/service-list-management"],
                }) && (
                    <div className="col-12  col-lg-6">
                      <SearchableDropdownZone
                        options={zoneList}
                        label="zone_name"
                        id="admin-list"
                        selectedVal={selectedZone?.zone_name}
                        handleChange={handleZoneList}
                        type="Zone"
                      />
                    </div>
                  )}
                {allowModule({
                  roles: ["SuperAdmin", "ZonalAdmin"],
                  paths: ["/usermanagement", "/service-list-management"],
                }) && (
                    <div className="col-12  col-lg-6">
                      <SearchableDropdownDistrict
                        options={districtList}
                        label="name"
                        id="admin-list"
                        selectedVal={selectedDistrict?.name}
                        handleChange={handleDistrictSelection}
                        type="District"
                      />
                    </div>
                  )}
              </div>
              <div
                className="d-flex align-items-center mt-3 gap-2"
                style={{ justifyContent: "flex-end", width: "100%" }}
              >
                <button
                  className="filter-clear-btn"
                  onClick={() => clearData()}
                >
                  Clear
                </button>
                <button
                  className="filter-cancel-btn"
                  onClick={() => handleFilterCancel()}
                >
                  Cancel
                </button>
                <button
                  className="filter-apply-btn"
                  onClick={() => handleApplyFilter()}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CustomerFilter;
