import { createSlice } from "@reduxjs/toolkit";


const walletDetailsSlice = createSlice({
    name: 'walletDetails',
    initialState:{
        loading: false,
    },
    reducers:{
        walletDetailsRequest(state, action){
            return{
                ...state,
                loading: true
            }
        },
        walletDetailsSuccess(state, action){
            return{
                ...state,
                loading: false,
                walletDetailsData: action.payload
            }
        },
        walletDetailsFail(state, action){
            return{
                ...state,
                loading: false,
                error: action.payload
            }
        }
    }
});

const {actions, reducer} = walletDetailsSlice;

export const {walletDetailsRequest,walletDetailsFail,walletDetailsSuccess} = actions

export default reducer;