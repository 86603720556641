import { createSlice } from "@reduxjs/toolkit";


const RewardZoneListSlice = createSlice({
    name: 'RewardZoneList',
    initialState: {
        loading: false,
        RewardZoneListData: [],
        error: null,
        statusCode: null,
        successMsg: null,
        errorMsg: null,
        totalCount: 0,

    },
    reducers: {
        RewardZoneListRequest(state, action) {
            return {
                ...state,
                loading: true
            }
        },
        RewardZoneListSuccess(state, action) {
            console.log(action.payload, "RewardZoneList........................")
            return {
                ...state,
                loading: true,
                RewardZoneListData: action?.payload,
                // successMsg: action.payload.data.message,
                // totalCount: action.payload.data?.totalCount,
                errorMsg: null,
                statusCode: 'success'
            }
        },
        RewardZoneListFail(state, action) {
            return {
                ...state,
                loading: false,
                error: action.payload,
                statusCode: 'error',
                errorMsg: action?.payload?.error?.message,
                // totalCount: 0,
                RewardZoneListData: [],
                successMsg: null
            }
        },
        clearStatusCode(state, action) {
            return {
                ...state,
                statusCode: null
            }
        }

    }
});

const { actions, reducer } = RewardZoneListSlice;

export const { RewardZoneListRequest, RewardZoneListFail, RewardZoneListSuccess, clearStatusCode } = actions

export default reducer;
