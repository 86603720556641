import React from "react";
import about_vision from "../../Assets/Home/about-vision.svg";
import about_vision_mob from "../../Assets/Home/vision-mob.svg";
export default function AboutVision() {
  return (
    <div className="about-services-container-vision res-576px-mt-60px ">
      <div className="first-flex-service">
        <div
          style={{ fontWeight: 600, fontSize: "34px" }}
          className="our-vision-heading res-576px-font-25px"
        >
          Our Vision
        </div>
        <div
          style={{
            fontSize: "15px",
            color: "rgba(26, 26, 26, 0.7)",
            marginTop: "30px",
            lineHeight: "24px",
          }}
        >
          We are steadily working towards realizing our vision as a closely-knit
          unit, the Karur Electronics Technicians Welfare Association (KETWA).
          Born out of a desire to unite technicians across Tamil Nadu, KETWA is
          dedicated to self-improvement, training, and nurturing a supportive
          community.
        </div>
        <div
          style={{
            fontSize: "14px",
            color: "rgba(26, 26, 26, 0.7)",
            marginTop: "10px",
          }}
        >
          S3-app is the first beacon of this vision, a platform that is built
          for the people, of the technical service, and by the technicians!
        </div>
      </div>
      <div
        className="second-flex-img res-576px-d-none "
        style={{ scale: "0.8" }}
      >
        <img src={about_vision} alt="phone_img" />
        <img
          className="res-577px-d-none "
          src={about_vision_mob}
          alt="phone_img"
        />
      </div>
      <div
        className="second-flex-img res-577px-d-none "
        style={{ scale: "1.2" }}
      >
        <img src={about_vision_mob} alt="phone_img" />
      </div>
    </div>
  );
}
