import React, { useState, useEffect } from "react";
import TechLogo from "../Images/technician.png";
import trashIcon from "../Images/trash.svg";
import settingIcon from "../Images/setting-icon.png";
import { useLocation, useNavigate } from "react-router-dom";
import "../layout.css";
import { IoSettingsOutline } from "react-icons/io5";
import { GoTrash } from "react-icons/go";
import { RiLockPasswordLine, RiDashboardLine } from "react-icons/ri";
import { BsFillPersonFill, BsReceiptCutoff, BsShop } from "react-icons/bs";
import { FaWallet, FaBoxOpen, FaHardHat } from "react-icons/fa";
import { PiSuitcaseSimple } from "react-icons/pi";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { SiShopify } from "react-icons/si";
import { BiLogOut, BiCategory } from "react-icons/bi";
import UserManagementHighlightedIcon from "../Assets/icons/UserManagementHighlightedtIcon";
import UserManagementDefaultIcon from "../Assets/icons/UserManagementDefaultIcon";
import SRHighlightedIcon from "../Assets/icons/SRHighlightedIcon";
import { IoIosPeople } from "react-icons/io";
import SRDefaultIcon from "../Assets/icons/SRDefaultIcon";
import { Modal, Nav, NavDropdown } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { FiPackage } from "react-icons/fi";
import { FaShoppingCart } from "react-icons/fa";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FiGift } from "react-icons/fi";
import { clearSelectedDate } from "../redux/slices/todayParticipantsSlice ";
import { useDispatch, useSelector } from "react-redux";
import { toggleExpand } from "../redux/slices/expandSlice";
import Api from "../service/services";
import Swal from "sweetalert2";

const Sidebar = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const mobileMatches = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [Isopen, setIsopen] = useState(false);
  // const [logoutModal, setLogoutModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const Role = localStorage.getItem("role");
  const location = useLocation();

  const [isOpenProfileSetting, setIsOpenProfileSetting] = useState(() => {
    if (
      location.pathname === "/resetpassword" ||
      location.pathname === "/profile" ||
      location.pathname === "/account-delete-confirmation"
    ) {
      return true;
    } else {
      return false;
    }
  });

  const [paymentDropOpen, setPaymentDropOpen] = useState(() => {
    if (location.pathname === "/payment-wallet") {
      return true;
    } else if (
      location.pathname === "/revenue-management" &&
      (Role === "DistrictAdmin" || Role === "ZonalAdmin")
    ) {
      return true;
    } else {
      return false;
    }
  });

  const isExpanded = useSelector((state) => state.expandState.isExpanded);

  const handleDelete = () => {
    Api.accountDeleteConfirmationApi()?.then((response) => {
      if (response?.success === true) {
        navigate("/account-delete-confirmation", {
          state: { payload: response },
        });
      } else {
        Swal?.fire({
          text: response.message,
          confirmButtonText: "OK",
        });
        navigate(1);
      }
    });
  };
  // const [isExpanded, setIsExpanded] = useState(false);

  // const toggleExpand = () => {
  //   setIsExpanded(!isExpanded);
  // };

  const handleToggle = () => {
    dispatch(toggleExpand());
  };

  const onClickDashboard = () => {
    if (localStorage.getItem("role") === "Technician") {
      navigate("/technicianDashboard");
    } else if (localStorage.getItem("role") === "DistrictAdmin") {
      navigate("/district-admin-dashboard");
    } else if (localStorage.getItem("role") === "ZonalAdmin") {
      navigate("/zonal-admin-dashboard");
    } else if (localStorage.getItem("role") === "SuperAdmin") {
      navigate("/super-admin-dashboard");
    } else if (localStorage.getItem("role") === "Consumer") {
      navigate("/customerDashboard");
    } else {
      navigate("/");
    }
  };
  useEffect(() => {
    if (props.open) {
      setIsopen(true);
    } else if (props.open1) {
      setIsopen(false);
    } else if (props.open2) {
      setIsopen(false);
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    setShowLogoutModal(false);
    dispatch(toggleExpand());
    // window.location.reload();
    navigate("/");
  };

  const onClickProfileSettings = () => {
    setIsOpenProfileSetting(!isOpenProfileSetting);
  };

  const onClickPayment = () => {
    setPaymentDropOpen(!paymentDropOpen);
  };

  const onClickResetPassword = () => {
    setIsopen(false);
    navigate("/resetpassword");
  };

  const onClickDeleteAccount = () => {
    setIsopen(false);
    handleDelete();
  };

  const onClickCustomerProfile = () => {
    setIsopen(false);
    navigate("/profile");
  };

  const onClickTechReward = () => {
    // setIsopen(false);
    dispatch(clearSelectedDate());
    navigate("/super-admin-reward-dashboard-technician");
  };

  const onClickCustReward = () => {
    // setIsopen(false);
    dispatch(clearSelectedDate());
    navigate("/super-admin-reward-dashboard-customer");
  };
  const onClickCustomerSaveCard = () => {
    // setIsopen(false);
    navigate("/customerSaveCard");
  };

  const onClickSetting = () => {
    setIsopen((prevState) => !prevState);
  };

  return (
    <>
      {/* web side bar  */}
      <div className="sideBar" style={{ height: "auto" }}>
        <nav>
          <div style={{ fontSize: "13px" }}>
            <div className="w-100 text-start h-auto d-inline-block">
              <div
                className="nav flex-column nav-pills "
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                {/* consumer menu */}

                {Role === "Consumer" ? (
                  <>
                    <button
                      onClick={onClickDashboard}
                      className={`mt-3 nav-link fw-bold ${
                        window.location.pathname === "/customerDashboard" ||
                        window.location.pathname === "/cust-appliances"
                          ? "active"
                          : ""
                      }`}
                      id="v-pills-existing-clients-tab sidebar-link-active"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-existing"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-existing"
                    >
                      <RiDashboardLine
                        className="center-align-dashboard"
                        style={{ fontSize: "18px" }}
                      />
                      <span
                        className="res-none"
                        style={{
                          marginLeft: "13px",
                        }}
                      >
                        Dashboard
                      </span>
                    </button>
                    <button
                      onClick={() => navigate("/serviceList")}
                      className={`nav-link  fw-bold ${
                        window.location.pathname === "/serviceList"
                          ? "active"
                          : ""
                      }`}
                      id="v-pills-billing"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-bill"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-bill"
                      aria-selected="false"
                    >
                      <span
                        // className="me-3"
                        className="pos-fix-left"
                        style={{
                          // marginLeft: "-5px",
                          position: "relative",
                          top: "-2px",
                        }}
                      >
                        {location.pathname === "/serviceList" ? (
                          <SRHighlightedIcon className="center-align-dashboard" />
                        ) : (
                          <SRDefaultIcon className="center-align-dashboard" />
                        )}
                      </span>
                      <span className="res-none ms-2">My SRs</span>
                    </button>
                    <div className="reward-management">
                      <button
                        onClick={onClickProfileSettings}
                        className={`nav-link fw-bold ${
                          window.location.pathname ===
                            "/account-delete-confirmation" ||
                          window.location.pathname === "/profile" ||
                          window.location.pathname === "/resetpassword"
                            ? "active"
                            : ""
                        }`}
                        id="v-pills-reward"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-reward"
                        aria-selected={isOpenProfileSetting}
                      >
                        <div className="d-flex align-items-center gap-2">
                          <IoSettingsOutline
                            size={20}
                            className="center-align-dashboard"
                          />
                          <span className="res-none ms-2">Settings</span>{" "}
                          <span>
                            {!isOpenProfileSetting ? (
                              <i className="bi bi-caret-down-fill ms-2"></i>
                            ) : (
                              <i className="bi bi-caret-up-fill ms-2"></i>
                            )}
                          </span>
                        </div>
                      </button>
                      {isOpenProfileSetting && (
                        <div
                          className={`${
                            mobileMatches ? "profile-container" : "ms-4"
                          }`}
                        >
                          <button
                            onClick={onClickCustomerProfile}
                            className={`nav-link-claim fw-bold ${
                              window.location.pathname === "/profile"
                                ? "active"
                                : ""
                            }`}
                            id="v-pills-technician"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-technician"
                            aria-selected="false"
                            style={{
                              backgroundColor: "white",
                              border: "0px solid black",
                            }}
                          >
                            <div className="res-none d-flex align-items-center gap-2">
                              <div className="d-flex align-items-center">
                                <BsFillPersonFill
                                  className="center-align-dashboard"
                                  style={{ height: "20px" }}
                                  size={22}
                                />
                              </div>
                              <div className="d-flex align-items-center ms-2">
                                Profile
                              </div>
                            </div>
                            <span
                              className="res-open"
                              style={{ fontSize: "18px" }}
                            >
                              <BsFillPersonFill className="center-align-dashboard" />
                            </span>
                          </button>
                          <button
                            onClick={onClickResetPassword}
                            className={`nav-link-claim fw-bold ${
                              window.location.pathname === "/resetpassword"
                                ? "active"
                                : ""
                            }`}
                            id="v-pills-technician"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-technician"
                            aria-selected="false"
                            style={{
                              backgroundColor: "white",
                              border: "0px solid black",
                            }}
                          >
                            <div className="res-none  d-flex align-items-center gap-2">
                              <div className="d-flex align-items-center">
                                <RiLockPasswordLine
                                  size={20}
                                  className="center-align-dashboard"
                                />
                              </div>
                              <div className="d-flex align-items-center ms-2">
                                Reset Password
                              </div>
                            </div>
                            <span
                              className="res-open"
                              style={{ fontSize: "18px" }}
                            >
                              <RiLockPasswordLine className="center-align-dashboard" />
                            </span>
                          </button>
                          <button
                            onClick={onClickDeleteAccount}
                            className={`nav-link-claim fw-bold ${
                              window.location.pathname ===
                                "/account-delete-confirmation" ||
                              window.location.pathname === "/account-delete-otp"
                                ? "active"
                                : ""
                            }`}
                            id="v-pills-technician"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-technician"
                            aria-selected="false"
                            style={{
                              backgroundColor: "white",
                              border: "0px solid black",
                            }}
                          >
                            <div className="res-none  d-flex align-items-center gap-2">
                              <div className="d-flex align-items-center">
                                <GoTrash
                                  size={20}
                                  className="center-align-dashboard"
                                />
                              </div>
                              <div className="d-flex align-items-center ms-2">
                                Delete Account
                              </div>
                            </div>
                            <span
                              className="res-open"
                              style={{ fontSize: "18px" }}
                            >
                              <img
                                src={trashIcon}
                                style={{ height: "22px" }}
                                className="center-align-dashboard"
                                alt="trash-icon"
                              />{" "}
                            </span>
                          </button>
                          <button
                            onClick={() => setShowLogoutModal(true)}
                            className={`nav-link-claim fw-bold`}
                            id="v-pills-technician"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-technician"
                            aria-selected="false"
                            style={{
                              backgroundColor: "white",
                              border: "0px solid black",
                            }}
                          >
                            <div className="res-none  d-flex align-items-center gap-2">
                              <div className="d-flex align-items-center">
                                <BiLogOut
                                  className="center-align-dashboard"
                                  size={20}
                                />
                              </div>
                              <div className="d-flex align-items-center ms-2">
                                Logout
                              </div>
                            </div>
                            <span
                              className="res-open"
                              style={{ fontSize: "18px" }}
                            >
                              <BiLogOut className="center-align-dashboard" />
                            </span>
                          </button>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {/* technician */}
                {Role === "Technician" ? (
                  <>
                    <button
                      onClick={onClickDashboard}
                      className={`mt-3 nav-link  fw-bold ${
                        window.location.pathname === "/technicianDashboard"
                          ? "active"
                          : ""
                      }`}
                      id="v-pills-existing-clients-tab sidebar-link-active"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-existing"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-existing"
                    >
                      <RiDashboardLine
                        className="center-align-dashboard"
                        style={{ fontSize: "18px" }}
                      />
                      <span
                        className="res-none"
                        style={{
                          marginLeft: "13px",
                        }}
                      >
                        Dashboard
                      </span>
                    </button>
                    <button
                      onClick={() => navigate("/service-list-technician")}
                      className={`nav-link  fw-bold ${
                        window.location.pathname === "/service-list-technician"
                          ? "active"
                          : ""
                      }`}
                      id="v-pills-billing"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-bill"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-bill"
                      aria-selected="false"
                    >
                      <div className="d-flex align-items-center gap-2">
                        <PiSuitcaseSimple
                          className="center-align-dashboard"
                          style={{
                            marginLeft: "-2px",
                            marginTop: "-2px",
                            fontSize: "18px",
                          }}
                        />
                        <span className="res-none ms-2">My SRs</span>{" "}
                      </div>
                    </button>
                    <button
                      onClick={() => {
                        navigate("/techniciancategory");
                        console.log(
                          window.location.pathname,
                          "window.location.pathname"
                        );
                      }}
                      className={`nav-link  fw-bold ${
                        window.location.pathname === "/techniciancategory" ||
                        window.location.pathname === "/addToCart" ||
                        location.pathname === `/technicianCategoryById`
                          ? "active"
                          : ""
                      }`}
                      id="v-pills-billing"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-bill"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-bill"
                      aria-selected="false"
                    >
                      <span
                        // className="me-3"
                        style={{
                          // marginLeft: "-2px",
                          position: "relative",
                          top: "-2px",
                        }}
                      >
                        {location.pathname === "/techniciancategory" ? (
                          <BsShop
                            className="center-align-dashboard"
                            size={18}
                          />
                        ) : (
                          <BsShop
                            className="center-align-dashboard"
                            size={18}
                          />
                        )}
                      </span>
                      <span
                        className="res-none"
                        style={{
                          marginLeft: "12.5px",
                        }}
                      >
                        Shop
                      </span>
                    </button>
                    <button
                      onClick={() => navigate("/myorders")}
                      className={`nav-link  fw-bold ${
                        window.location.pathname === "/myorders" ? "active" : ""
                      }`}
                      id="v-pills-billing"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-bill"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-bill"
                      aria-selected="false"
                    >
                      <span
                        // className="me-3"
                        style={{
                          // marginLeft: "-2px",
                          position: "relative",
                          top: "-2px",
                        }}
                      >
                        {location.pathname === "/myorders" ? (
                          <SiShopify
                            className="center-align-dashboard"
                            size={18}
                          />
                        ) : (
                          <SiShopify
                            className="center-align-dashboard"
                            size={18}
                          />
                        )}
                      </span>
                      <span
                        className="res-none"
                        style={{
                          marginLeft: "12px",
                        }}
                      >
                        Orders
                      </span>
                    </button>
                    <button
                      onClick={() => navigate("/payment-wallet")}
                      className={`nav-link fw-bold ${
                        window.location.pathname === "/payment-wallet"
                          ? "active"
                          : ""
                      }`}
                      id="v-pills-billing"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-bill"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-bill"
                      aria-selected="false"
                      // style={{ padding: "unset" }}
                    >
                      <div className="res-none d-flex align-items-center">
                        <div
                          className="d-flex align-items-center"
                          style={{ marginTop: "-1px" }}
                        >
                          <FaWallet className="center-align-dashboard" />
                        </div>
                        <div
                          className="d-flex align-items-center ms-3"
                          // style={{ marginLeft: "13px" }}
                        >
                          Wallet
                        </div>
                      </div>
                      <span
                        className="res-open"
                        style={{
                          marginTop: "-4px",
                          // marginLeft: "15px",
                          fontSize: "18px",
                        }}
                      >
                        <FaWallet className="center-align-dashboard" />
                      </span>
                    </button>
                    {/*                     <button
                      onClick={() => navigate("/payment-wallet")}
                      className={`nav-link fw-bold ${
                        window.location.pathname === "/payment-wallet"
                          ? "active"
                          : ""
                      }`}
                      id="v-pills-billing"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-bill"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-bill"
                      aria-selected="false"
                      // style={{ padding: "unset" }}
                    >
                      <div className="res-none d-flex align-items-center">
                        <div
                          className="d-flex align-items-center"
                          style={{ marginTop: "-1px" }}
                        >
                          <FaWallet className="center-align-dashboard" />
                        </div>
                        <div
                          className="d-flex align-items-center ms-3"
                          // style={{ marginLeft: "13px" }}
                        >
                          Wallet
                        </div>
                      </div>
                      <span
                        className="res-open"
                        style={{
                          marginTop: "-4px",
                          // marginLeft: "15px",
                          fontSize: "18px",
                        }}
                      >
                        <FaWallet className="center-align-dashboard" />
                      </span>
                    </button>
                    <button
                      onClick={onClickCustomerProfile}
                      className={`nav-link fw-bold ${
                        window.location.pathname === "/profile" ? "active" : ""
                      }`}
                      id="v-pills-billing"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-bill"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-bill"
                      aria-selected="false"
                    >
                      <div className="res-none d-flex align-items-center gap-2">
                        <div className="d-flex align-items-center">
                          <BsFillPersonFill className="center-align-dashboard" />
                        </div>
                        <div className="d-flex align-items-center ms-2">
                          Profile
                        </div>
                      </div>
                      <span className="res-open" style={{ fontSize: "18px" }}>
                        <BsFillPersonFill className="center-align-dashboard" />
                      </span>
                    </button>
                    <button
                      onClick={onClickResetPassword}
                      className={`nav-link fw-bold ${
                        window.location.pathname === "/resetpassword"
                          ? "active"
                          : ""
                      }`}
                      id="v-pills-billing"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-bill"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-bill"
                      aria-selected="false"
                    >
                      <div className="res-none  d-flex align-items-center gap-2">
                        <div className="d-flex align-items-center">
                          <RiLockPasswordLine className="center-align-dashboard" />
                        </div>
                        <div className="d-flex align-items-center ms-2">
                          Reset Password
                        </div>
                      </div>
                      <span className="res-open" style={{ fontSize: "18px" }}>
                        <RiLockPasswordLine className="center-align-dashboard" />
                      </span>
                    </button>
                    <button
                      onClick={() => {
                        setShowLogoutModal(true);
                      }}
                      className={`nav-link fw-bold`}
                      id="v-pills-billing"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-bill"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-bill"
                      aria-selected="false"
                    >
                      <div className="res-none  d-flex align-items-center gap-2">
                        <div className="d-flex align-items-center">
                          <BiLogOut className="center-align-dashboard" />
                        </div>
                        <div className="d-flex align-items-center ms-2">
                          Logout
                        </div>
                      </div>
                      <span className="res-open" style={{ fontSize: "18px" }}>
                        <BiLogOut className="center-align-dashboard" />
                      </span>
                    </button> */}
                    {/* settings */}
                    <div className="reward-management">
                      <button
                        onClick={onClickProfileSettings}
                        className={`nav-link fw-bold ${
                          window.location.pathname ===
                            "/account-delete-confirmation" ||
                          window.location.pathname === "/profile" ||
                          window.location.pathname === "/resetpassword"
                            ? "active"
                            : ""
                        }`}
                        id="v-pills-reward"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-reward"
                        aria-selected={isOpenProfileSetting}
                      >
                        <div className="d-flex align-items-center gap-2">
                          <IoSettingsOutline
                            size={20}
                            className="center-align-dashboard"
                          />
                          <span className="res-none ms-2">Settings</span>{" "}
                          <span>
                            {!isOpenProfileSetting ? (
                              <i className="bi bi-caret-down-fill ms-2"></i>
                            ) : (
                              <i className="bi bi-caret-up-fill ms-2"></i>
                            )}
                          </span>
                        </div>
                      </button>
                      {isOpenProfileSetting && (
                        <div
                          className={`${
                            mobileMatches ? "profile-container" : "ms-4"
                          }`}
                        >
                          <button
                            onClick={onClickCustomerProfile}
                            className={`nav-link-claim fw-bold ${
                              window.location.pathname === "/profile"
                                ? "active"
                                : ""
                            }`}
                            id="v-pills-technician"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-technician"
                            aria-selected="false"
                            style={{
                              backgroundColor: "white",
                              border: "0px solid black",
                            }}
                          >
                            <div className="res-none d-flex align-items-center gap-2">
                              <div className="d-flex align-items-center">
                                <BsFillPersonFill
                                  className="center-align-dashboard"
                                  style={{ height: "20px" }}
                                  size={22}
                                />
                              </div>
                              <div className="d-flex align-items-center ms-2">
                                Profile
                              </div>
                            </div>
                            <span
                              className="res-open"
                              style={{ fontSize: "18px" }}
                            >
                              <BsFillPersonFill className="center-align-dashboard" />
                            </span>
                          </button>
                          <button
                            onClick={onClickResetPassword}
                            className={`nav-link-claim fw-bold ${
                              window.location.pathname === "/resetpassword"
                                ? "active"
                                : ""
                            }`}
                            id="v-pills-technician"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-technician"
                            aria-selected="false"
                            style={{
                              backgroundColor: "white",
                              border: "0px solid black",
                            }}
                          >
                            <div className="res-none  d-flex align-items-center gap-2">
                              <div className="d-flex align-items-center">
                                <RiLockPasswordLine
                                  size={20}
                                  className="center-align-dashboard"
                                />
                              </div>
                              <div className="d-flex align-items-center ms-2">
                                Reset Password
                              </div>
                            </div>
                            <span
                              className="res-open"
                              style={{ fontSize: "18px" }}
                            >
                              <RiLockPasswordLine className="center-align-dashboard" />
                            </span>
                          </button>
                          <button
                            onClick={onClickDeleteAccount}
                            className={`nav-link-claim fw-bold ${
                              window.location.pathname ===
                                "/account-delete-confirmation" ||
                              window.location.pathname === "/account-delete-otp"
                                ? "active"
                                : ""
                            }`}
                            id="v-pills-technician"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-technician"
                            aria-selected="false"
                            style={{
                              backgroundColor: "white",
                              border: "0px solid black",
                            }}
                          >
                            <div className="res-none  d-flex align-items-center gap-2">
                              <div className="d-flex align-items-center">
                                <GoTrash
                                  size={20}
                                  className="center-align-dashboard"
                                />
                              </div>
                              <div className="d-flex align-items-center ms-2">
                                Delete Account
                              </div>
                            </div>
                            <span
                              className="res-open"
                              style={{ fontSize: "18px" }}
                            >
                              <img
                                src={trashIcon}
                                style={{ height: "22px" }}
                                className="center-align-dashboard"
                                alt="trash-icon"
                              />{" "}
                            </span>
                          </button>
                          <button
                            onClick={() => setShowLogoutModal(true)}
                            className={`nav-link-claim fw-bold`}
                            id="v-pills-technician"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-technician"
                            aria-selected="false"
                            style={{
                              backgroundColor: "white",
                              border: "0px solid black",
                            }}
                          >
                            <div className="res-none  d-flex align-items-center gap-2">
                              <div className="d-flex align-items-center">
                                <BiLogOut
                                  className="center-align-dashboard"
                                  size={20}
                                />
                              </div>
                              <div className="d-flex align-items-center ms-2">
                                Logout
                              </div>
                            </div>
                            <span
                              className="res-open"
                              style={{ fontSize: "18px" }}
                            >
                              <BiLogOut className="center-align-dashboard" />
                            </span>
                          </button>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {/* District Admin, Zonal Admin and Super Admin   */}
                {Role === "DistrictAdmin" || Role === "ZonalAdmin" ? (
                  <>
                    <button
                      onClick={onClickDashboard}
                      className={`mt-1 nav-link  fw-bold ${
                        window.location.pathname ===
                          "/district-admin-dashboard" ||
                        window.location.pathname === "/zonal-admin-dashboard" ||
                        window.location.pathname === "/super-admin-dashboard"
                          ? "active"
                          : ""
                      }`}
                      id="v-pills-existing-clients-tab sidebar-link-active"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-existing"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-existing"
                    >
                      <RiDashboardLine
                        className="center-align-dashboard"
                        style={{ fontSize: "18px" }}
                      />
                      <span
                        className="res-none"
                        style={{
                          marginLeft: "13px",
                        }}
                      >
                        Dashboard
                      </span>
                    </button>
                    <button
                      onClick={() => navigate("/approve-pending-technicians")}
                      className={`nav-link  fw-bold ${
                        window.location.pathname ===
                        "/approve-pending-technicians"
                          ? "active"
                          : ""
                      }`}
                      id="v-pills-billing"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-bill"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-bill"
                      aria-selected="false"
                      style={
                        mobileMatches
                          ? {
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }
                          : null
                      }
                    >
                      <i class="bi bi-ui-checks pos-fix-right"></i>
                      <span className="res-none ms-3">Verify Technicians</span>
                    </button>
                    <button
                      onClick={() => navigate("/usermanagement")}
                      className={`nav-link  fw-bold ${
                        window.location.pathname === "/usermanagement"
                          ? "active"
                          : ""
                      }`}
                      id="v-pills-onboarding"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-onboard"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-onboard"
                      aria-selected="false"
                      style={
                        mobileMatches
                          ? {
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }
                          : null
                      }
                    >
                      <span
                        className="pos-fix-left"
                        style={{
                          // marginLeft: "-5px",
                          position: "relative",
                          top: "-1px",
                        }}
                      >
                        {location.pathname === "/usermanagement" ? (
                          <UserManagementHighlightedIcon className="center-align-dashboard" />
                        ) : (
                          <UserManagementDefaultIcon className="center-align-dashboard" />
                        )}
                      </span>
                      <span className="res-none ms-2">User Management</span>
                    </button>
                    <button
                      onClick={() => navigate("/service-list-technician")}
                      className={`nav-link  fw-bold ${
                        window.location.pathname === "/service-list-technician"
                          ? "active"
                          : ""
                      }`}
                      id="v-pills-billing"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-bill"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-bill"
                      aria-selected="false"
                      // style={mobileMatches ? { display: "flex", alignItems: "center", justifyContent: "flex-end" } : null}
                    >
                      <div className="d-flex align-items-center gap-2">
                        <PiSuitcaseSimple
                          className="center-align-dashboard"
                          style={{
                            // marginLeft: "-2px",
                            marginTop: "-2px",
                            fontSize: "18px",
                          }}
                        />
                        <span
                          className="res-none ms-2"
                          // style={{ marginLeft: "6.5px" }}
                        >
                          My SRs
                        </span>{" "}
                      </div>
                    </button>
                    <button
                      onClick={() => navigate("/service-list-management")}
                      className={`nav-link  fw-bold ${
                        window.location.pathname === "/service-list-management"
                          ? "active"
                          : ""
                      }`}
                      id="v-pills-billing"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-bill"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-bill"
                      aria-selected="false"
                      style={
                        mobileMatches
                          ? {
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }
                          : null
                      }
                    >
                      <span
                        className="pos-fix-left"
                        style={{
                          // marginLeft: "-5px",
                          position: "relative",
                          top: "-2px",
                        }}
                      >
                        {location.pathname === "/service-list-management" ? (
                          <SRHighlightedIcon className="center-align-dashboard" />
                        ) : (
                          <SRDefaultIcon className="center-align-dashboard" />
                        )}
                      </span>
                      <span className="res-none ms-2">SR Management</span>
                    </button>
                    {Role === "ZonalAdmin" && (
                      <>
                        <button
                          onClick={() => {
                            navigate("/techniciancategory");
                            console.log(
                              window.location.pathname,
                              "window.location.pathname"
                            );
                          }}
                          className={`nav-link  fw-bold ${
                            window.location.pathname ===
                              "/techniciancategory" ||
                            window.location.pathname === "/addToCart" ||
                            location.pathname === `/technicianCategoryById`
                              ? "active"
                              : ""
                          }`}
                          id="v-pills-billing"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-bill"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-bill"
                          aria-selected="false"
                          style={
                            mobileMatches
                              ? {
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                }
                              : null
                          }
                        >
                          <span
                            className=""
                            style={{
                              // marginLeft: "-2px",
                              position: "relative",
                              top: "-2px",
                            }}
                          >
                            {location.pathname === "/techniciancategory" ? (
                              <BsShop
                                className="center-align-dashboard"
                                size={18}
                              />
                            ) : (
                              <BsShop
                                className="center-align-dashboard"
                                size={18}
                              />
                            )}
                          </span>
                          <span
                            className="res-none"
                            style={{
                              marginLeft: "12.5px",
                            }}
                          >
                            Shop
                          </span>
                        </button>
                        <button
                          onClick={() => navigate("/myorders")}
                          className={`nav-link  fw-bold ${
                            window.location.pathname === "/myorders"
                              ? "active"
                              : ""
                          }`}
                          id="v-pills-billing"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-bill"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-bill"
                          aria-selected="false"
                          style={
                            mobileMatches
                              ? {
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                }
                              : null
                          }
                        >
                          <span
                            className=""
                            style={{
                              // marginLeft: "-2px",
                              position: "relative",
                              top: "-2px",
                            }}
                          >
                            {location.pathname === "/myorders" ? (
                              <SiShopify
                                className="center-align-dashboard"
                                size={18}
                              />
                            ) : (
                              <SiShopify
                                className="center-align-dashboard"
                                size={18}
                              />
                            )}
                          </span>
                          <span
                            className="res-none"
                            style={{
                              marginLeft: "12px",
                            }}
                          >
                            Orders
                          </span>
                        </button>
                      </>
                    )}
                    {Role === "DistrictAdmin" && (
                      <>
                        <button
                          onClick={() => {
                            navigate("/techniciancategory");
                            console.log(
                              window.location.pathname,
                              "window.location.pathname"
                            );
                          }}
                          className={`nav-link  fw-bold ${
                            window.location.pathname ===
                              "/techniciancategory" ||
                            window.location.pathname === "/addToCart" ||
                            location.pathname === `/technicianCategoryById`
                              ? "active"
                              : ""
                          }`}
                          id="v-pills-billing"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-bill"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-bill"
                          aria-selected="false"
                        >
                          <span
                            // className="me-3"
                            style={{
                              // marginLeft: "-2px",
                              position: "relative",
                              top: "-2px",
                            }}
                          >
                            {location.pathname === "/techniciancategory" ? (
                              <BsShop
                                className="center-align-dashboard"
                                size={18}
                              />
                            ) : (
                              <BsShop
                                className="center-align-dashboard"
                                size={18}
                              />
                            )}
                          </span>
                          <span
                            className="res-none"
                            style={{
                              marginLeft: "12.5px",
                            }}
                          >
                            Shop
                          </span>
                        </button>
                        <button
                          onClick={() => navigate("/myorders")}
                          className={`nav-link  fw-bold ${
                            window.location.pathname === "/myorders"
                              ? "active"
                              : ""
                          }`}
                          id="v-pills-billing"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-bill"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-bill"
                          aria-selected="false"
                        >
                          <span
                            // className="me-3"
                            style={{
                              // marginLeft: "-2px",
                              position: "relative",
                              top: "-2px",
                            }}
                          >
                            {location.pathname === "/myorders" ? (
                              <SiShopify
                                className="center-align-dashboard"
                                size={18}
                              />
                            ) : (
                              <SiShopify
                                className="center-align-dashboard"
                                size={18}
                              />
                            )}
                          </span>
                          <span
                            className="res-none"
                            style={{
                              marginLeft: "12px",
                            }}
                          >
                            Orders
                          </span>
                        </button>
                      </>
                    )}
                    <button
                      onClick={onClickPayment}
                      className={`nav-link fw-bold bg-none`}
                      id="v-pills-billing"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-bill"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-bill"
                      aria-selected="false"
                      style={{ padding: "unset", paddingLeft: "15px" }}
                    >
                      <div className="d-flex align-items-center gap-2">
                        <BsReceiptCutoff
                          className="center-align-dashboard pos-fix-drop"
                          style={{ marginTop: "-2px" }}
                        />
                        <span className="res-none ms-2">Payment</span>{" "}
                        <span>
                          {!paymentDropOpen ? (
                            <i className="bi bi-caret-down-fill ms-2"></i>
                          ) : (
                            <i className="bi bi-caret-up-fill ms-2"></i>
                          )}
                        </span>
                      </div>
                    </button>
                    {paymentDropOpen ? (
                      <button
                        onClick={() => navigate("/payment-wallet")}
                        className={`nav-link fw-bold ${
                          window.location.pathname === "/payment-wallet"
                            ? "active"
                            : ""
                        }`}
                        id="v-pills-billing"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-bill"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-bill"
                        aria-selected="false"
                        style={{ paddingLeft: "20px" }}
                      >
                        <div className="res-none ms-4 d-flex align-items-center gap-2">
                          <div
                            className="d-flex align-items-center"
                            style={{ marginTop: "-1px" }}
                          >
                            <FaWallet className="center-align-dashboard" />
                          </div>
                          <div className="d-flex align-items-center ms-2">
                            Wallet
                          </div>
                        </div>
                        <span
                          className="res-open"
                          style={{
                            marginTop: "-4px",
                            marginLeft: "15px",
                            fontSize: "18px",
                          }}
                        >
                          <FaWallet className="center-align-dashboard" />
                        </span>
                      </button>
                    ) : (
                      <></>
                    )}{" "}
                    {paymentDropOpen &&
                    (Role === "DistrictAdmin" || Role === "ZonalAdmin") ? (
                      <button
                        onClick={() => navigate("/revenue-management")}
                        className={`nav-link  fw-bold ${
                          window.location.pathname === "/revenue-management"
                            ? "active"
                            : ""
                        }`}
                        id="v-pills-billing"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-bill"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-bill"
                        aria-selected="false"
                        style={{ paddingLeft: "43px" }}
                      >
                        <div className="d-flex align-items-center gap-2">
                          <HiOutlineClipboardDocumentList
                            className="center-align-dashboard"
                            style={{
                              // marginLeft: "-2px",
                              marginTop: "-2px",
                              fontSize: "18px",
                            }}
                          />
                          <span className="res-none ms-2">Revenue</span>{" "}
                        </div>
                      </button>
                    ) : (
                      <></>
                    )}
                    {/* <button
                      onClick={onClickCustomerProfile}
                      className={`nav-link fw-bold ${
                        window.location.pathname === "/profile" ? "active" : ""
                      }`}
                      id="v-pills-billing"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-bill"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-bill"
                      aria-selected="false"
                    >
                      <div className="res-none d-flex align-items-center gap-2">
                        <div className="d-flex align-items-center">
                          <BsFillPersonFill className="center-align-dashboard" />
                        </div>
                        <div className="d-flex align-items-center ms-2">
                          Profile
                        </div>
                      </div>
                      <span className="res-open" style={{ fontSize: "18px" }}>
                        <BsFillPersonFill className="center-align-dashboard" />
                      </span>
                    </button>
                    <button
                      onClick={onClickResetPassword}
                      className={`nav-link fw-bold ${
                        window.location.pathname === "/resetpassword"
                          ? "active"
                          : ""
                      }`}
                      id="v-pills-billing"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-bill"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-bill"
                      aria-selected="false"
                    >
                      <div className="res-none  d-flex align-items-center gap-2">
                        <div className="d-flex align-items-center">
                          <RiLockPasswordLine className="center-align-dashboard" />
                        </div>
                        <div className="d-flex align-items-center ms-2">
                          Reset Password
                        </div>
                      </div>
                      <span className="res-open" style={{ fontSize: "18px" }}>
                        <RiLockPasswordLine className="center-align-dashboard" />
                      </span>
                    </button>
                    <button
                      onClick={() => {
                        setShowLogoutModal(true);
                      }}
                      className={`nav-link fw-bold`}
                      id="v-pills-billing"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-bill"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-bill"
                      aria-selected="false"
                    >
                      <div className="res-none  d-flex align-items-center gap-2">
                        <div className="d-flex align-items-center">
                          <BiLogOut className="center-align-dashboard" />
                        </div>
                        <div className="d-flex align-items-center ms-2">
                          Logout
                        </div>
                      </div>
                      <span className="res-open" style={{ fontSize: "18px" }}>
                        <BiLogOut className="center-align-dashboard" />
                      </span>
                    </button> */}
                    <div className="reward-management">
                      <button
                        onClick={onClickProfileSettings}
                        className={`nav-link fw-bold ${
                          window.location.pathname ===
                            "/account-delete-confirmation" ||
                          window.location.pathname === "/profile" ||
                          window.location.pathname === "/resetpassword"
                            ? "active"
                            : ""
                        }`}
                        id="v-pills-reward"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-reward"
                        aria-selected={isOpenProfileSetting}
                      >
                        <div className="d-flex align-items-center gap-2">
                          <IoSettingsOutline
                            size={20}
                            className="center-align-dashboard"
                          />
                          <span className="res-none ms-2">Settings</span>{" "}
                          <span>
                            {!isOpenProfileSetting ? (
                              <i className="bi bi-caret-down-fill ms-2"></i>
                            ) : (
                              <i className="bi bi-caret-up-fill ms-2"></i>
                            )}
                          </span>
                        </div>
                      </button>
                      {isOpenProfileSetting && (
                        <div
                          className={`${
                            mobileMatches ? "profile-container" : "ms-4"
                          }`}
                        >
                          <button
                            onClick={onClickCustomerProfile}
                            className={`nav-link-claim fw-bold ${
                              window.location.pathname === "/profile"
                                ? "active"
                                : ""
                            }`}
                            id="v-pills-technician"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-technician"
                            aria-selected="false"
                            style={{
                              backgroundColor: "white",
                              border: "0px solid black",
                            }}
                          >
                            <div className="res-none d-flex align-items-center gap-2">
                              <div className="d-flex align-items-center">
                                <BsFillPersonFill
                                  className="center-align-dashboard"
                                  style={{ height: "20px" }}
                                  size={22}
                                />
                              </div>
                              <div className="d-flex align-items-center ms-2">
                                Profile
                              </div>
                            </div>
                            <span
                              className="res-open"
                              style={{ fontSize: "18px" }}
                            >
                              <BsFillPersonFill className="center-align-dashboard" />
                            </span>
                          </button>
                          <button
                            onClick={onClickResetPassword}
                            className={`nav-link-claim fw-bold ${
                              window.location.pathname === "/resetpassword"
                                ? "active"
                                : ""
                            }`}
                            id="v-pills-technician"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-technician"
                            aria-selected="false"
                            style={{
                              backgroundColor: "white",
                              border: "0px solid black",
                            }}
                          >
                            <div className="res-none  d-flex align-items-center gap-2">
                              <div className="d-flex align-items-center">
                                <RiLockPasswordLine
                                  size={20}
                                  className="center-align-dashboard"
                                />
                              </div>
                              <div className="d-flex align-items-center ms-2">
                                Reset Password
                              </div>
                            </div>
                            <span
                              className="res-open"
                              style={{ fontSize: "18px" }}
                            >
                              <RiLockPasswordLine className="center-align-dashboard" />
                            </span>
                          </button>
                          <button
                            onClick={onClickDeleteAccount}
                            className={`nav-link-claim fw-bold ${
                              window.location.pathname ===
                                "/account-delete-confirmation" ||
                              window.location.pathname === "/account-delete-otp"
                                ? "active"
                                : ""
                            }`}
                            id="v-pills-technician"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-technician"
                            aria-selected="false"
                            style={{
                              backgroundColor: "white",
                              border: "0px solid black",
                            }}
                          >
                            <div className="res-none  d-flex align-items-center gap-2">
                              <div className="d-flex align-items-center">
                                <GoTrash
                                  size={20}
                                  className="center-align-dashboard"
                                />
                              </div>
                              <div className="d-flex align-items-center ms-2">
                                Delete Account
                              </div>
                            </div>
                            <span
                              className="res-open"
                              style={{ fontSize: "18px" }}
                            >
                              <img
                                src={trashIcon}
                                style={{ height: "22px" }}
                                className="center-align-dashboard"
                                alt="trash-icon"
                              />{" "}
                            </span>
                          </button>
                          <button
                            onClick={() => setShowLogoutModal(true)}
                            className={`nav-link-claim fw-bold`}
                            id="v-pills-technician"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-technician"
                            aria-selected="false"
                            style={{
                              backgroundColor: "white",
                              border: "0px solid black",
                            }}
                          >
                            <div className="res-none  d-flex align-items-center gap-2">
                              <div className="d-flex align-items-center">
                                <BiLogOut
                                  className="center-align-dashboard"
                                  size={20}
                                />
                              </div>
                              <div className="d-flex align-items-center ms-2">
                                Logout
                              </div>
                            </div>
                            <span
                              className="res-open"
                              style={{ fontSize: "18px" }}
                            >
                              <BiLogOut className="center-align-dashboard" />
                            </span>
                          </button>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {Role === "SuperAdmin" ? (
                  <div style={{ paddingBottom: "100px" }}>
                    <button
                      onClick={onClickDashboard}
                      className={`mt-1 nav-link  fw-bold ${
                        window.location.pathname ===
                          "/district-admin-dashboard" ||
                        window.location.pathname === "/zonal-admin-dashboard" ||
                        window.location.pathname === "/super-admin-dashboard"
                          ? "active"
                          : ""
                      }`}
                      id="v-pills-existing-clients-tab sidebar-link-active"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-existing"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-existing"
                    >
                      <RiDashboardLine
                        className="center-align-dashboard"
                        style={{ fontSize: "18px" }}
                      />
                      <span
                        className="res-none"
                        style={{
                          marginLeft: "13px",
                        }}
                      >
                        Dashboard
                      </span>
                    </button>
                    <div className="reward-management">
                      <button
                        onClick={handleToggle}
                        className={`nav-link fw-bold ${
                          window.location.pathname ===
                            "/super-admin-reward-dashboard-technician" ||
                          window.location.pathname ===
                            "/super-admin-reward-dashboard-customer"
                            ? "active"
                            : ""
                        }`}
                        id="v-pills-reward"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-reward"
                        aria-selected={isExpanded}
                      >
                        <div className="res-none d-flex align-items-center gap-2">
                          <div className="d-flex align-items-center">
                            <FiGift className="center-align-dashboard" />
                          </div>
                          <div className="d-flex align-items-center ms-2">
                            Reward Management
                            <span>
                              {!isExpanded ? (
                                <i className="bi bi-caret-down-fill ms-2"></i>
                              ) : (
                                <i className="bi bi-caret-up-fill ms-2"></i>
                              )}
                            </span>
                          </div>
                        </div>
                        <span className="res-open" style={{ fontSize: "18px" }}>
                          <FiGift className="center-align-dashboard" />
                        </span>
                      </button>
                      {isExpanded && (
                        <div className="ms-4">
                          <button
                            onClick={onClickTechReward}
                            className={`nav-link-claim fw-bold ${
                              window.location.pathname ===
                              "/super-admin-reward-dashboard-technician"
                                ? "active"
                                : ""
                            }`}
                            id="v-pills-technician"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-technician"
                            aria-selected="false"
                            style={{
                              backgroundColor: "white",
                              border: "0px solid black",
                            }}
                          >
                            <div className="res-none d-flex align-items-center gap-2">
                              <div className="d-flex align-items-center">
                                <FaHardHat className="center-align-dashboard" />
                              </div>
                              <div className="d-flex align-items-center ms-2">
                                Technician
                              </div>
                            </div>
                            <span
                              className="res-open"
                              style={{ fontSize: "18px" }}
                            >
                              <FaHardHat className="center-align-dashboard" />
                            </span>
                          </button>

                          <button
                            onClick={onClickCustReward}
                            className={`nav-link-claim fw-bold ${
                              window.location.pathname ===
                              "/super-admin-reward-dashboard-customer"
                                ? "active"
                                : ""
                            }`}
                            id="v-pills-customer"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-customer"
                            aria-selected="false"
                            style={{
                              backgroundColor: "white",
                              border: "0px solid black",
                            }}
                          >
                            <div className="res-none d-flex align-items-center gap-2">
                              <div className="d-flex align-items-center">
                                <BsFillPersonFill className="center-align-dashboard" />
                              </div>
                              <div className="d-flex align-items-center ms-2">
                                Customer
                              </div>
                            </div>
                            <span
                              className="res-open"
                              style={{ fontSize: "18px" }}
                            >
                              <BsFillPersonFill className="center-align-dashboard" />
                            </span>
                          </button>
                        </div>
                      )}
                    </div>
                    <button
                      onClick={() => navigate("/referral-management")}
                      className={`nav-link  fw-bold ${
                        window.location.pathname === "/referral-management"
                          ? "active"
                          : ""
                      }`}
                      id="v-pills-billing"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-bill"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-bill"
                      aria-selected="false"
                    >
                      <IoIosPeople size={18} style={{ marginRight: "-4px" }} />
                      <span className="res-none ms-3">Referral Management</span>
                    </button>
                    <button
                      onClick={() => navigate("/approve-pending-technicians")}
                      className={`nav-link  fw-bold ${
                        window.location.pathname ===
                        "/approve-pending-technicians"
                          ? "active"
                          : ""
                      }`}
                      id="v-pills-billing"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-bill"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-bill"
                      aria-selected="false"
                    >
                      <i class="bi bi-ui-checks pos-fix-right"></i>
                      <span className="res-none ms-3">Verify Technicians</span>
                    </button>
                    <button
                      onClick={() => navigate("/usermanagement")}
                      className={`nav-link  fw-bold ${
                        window.location.pathname === "/usermanagement"
                          ? "active"
                          : ""
                      }`}
                      id="v-pills-onboarding"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-onboard"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-onboard"
                      aria-selected="false"
                    >
                      <span
                        className="pos-fix-left"
                        style={{
                          // marginLeft: "-5px",
                          position: "relative",
                          top: "-1px",
                        }}
                      >
                        {location.pathname === "/usermanagement" ? (
                          <UserManagementHighlightedIcon className="center-align-dashboard" />
                        ) : (
                          <UserManagementDefaultIcon className="center-align-dashboard" />
                        )}
                      </span>
                      <span className="res-none ms-2">User Management</span>
                    </button>
                    <button
                      onClick={() => navigate("/service-list-technician")}
                      className={`nav-link  fw-bold ${
                        window.location.pathname === "/service-list-technician"
                          ? "active"
                          : ""
                      }`}
                      id="v-pills-billing"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-bill"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-bill"
                      aria-selected="false"
                    >
                      <div className="d-flex align-items-center gap-2">
                        <PiSuitcaseSimple
                          className="center-align-dashboard"
                          style={{
                            // marginLeft: "-2px",
                            marginTop: "-2px",
                            fontSize: "18px",
                          }}
                        />
                        <span
                          className="res-none ms-2"
                          // style={{ marginLeft: "6.5px" }}
                        >
                          My SRs
                        </span>{" "}
                      </div>
                    </button>
                    <button
                      onClick={() => navigate("/service-list-management")}
                      className={`nav-link  fw-bold ${
                        window.location.pathname === "/service-list-management"
                          ? "active"
                          : ""
                      }`}
                      id="v-pills-billing"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-bill"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-bill"
                      aria-selected="false"
                    >
                      <span
                        className="pos-fix-left"
                        style={{
                          // marginLeft: "-5px",
                          position: "relative",
                          top: "-2px",
                        }}
                      >
                        {location.pathname === "/service-list-management" ? (
                          <SRHighlightedIcon className="center-align-dashboard" />
                        ) : (
                          <SRDefaultIcon className="center-align-dashboard" />
                        )}
                      </span>
                      <span className="res-none ms-2">SR Management</span>
                    </button>

                    {Role === "SuperAdmin" && (
                      <>
                        <button
                          onClick={() => navigate("/revenue-management")}
                          className={`nav-link  fw-bold ${
                            window.location.pathname === "/revenue-management"
                              ? "active"
                              : ""
                          }`}
                          id="v-pills-billing"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-bill"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-bill"
                          aria-selected="false"
                        >
                          <div className="d-flex align-items-center gap-2">
                            <HiOutlineClipboardDocumentList
                              className="center-align-dashboard"
                              style={{
                                // marginLeft: "-2px",
                                marginTop: "-2px",
                                fontSize: "18px",
                              }}
                            />
                            <span className="res-none ms-2">
                              Revenue Management
                            </span>{" "}
                          </div>
                        </button>
                        {/* appliancemanagement */}
                        <button
                          onClick={() => navigate("/appliancemanagement")}
                          className={`nav-link  fw-bold ${
                            window.location.pathname === "/appliancemanagement"
                              ? "active"
                              : ""
                          }`}
                          id="v-pills-billing"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-bill"
                          type="button"
                          role="tab"
                          // disabled={true}
                          aria-controls="v-pills-bill"
                          aria-selected="false"
                        >
                          <div className="d-flex align-items-center gap-2">
                            <i
                              class="bi bi-phone"
                              style={{
                                // marginLeft: "-2px",
                                marginTop: "-2px",
                                fontSize: "18px",
                              }}
                            />
                            <span
                              className="res-none ms-2"
                              style={{
                                fontSize: "13px",
                              }}
                            >
                              Appliance Management
                            </span>{" "}
                          </div>
                        </button>

                        {/* configurations */}
                        <button
                          onClick={() => navigate("/configurations")}
                          className={`nav-link  fw-bold ${
                            window.location.pathname === "/configurations"
                              ? "active"
                              : ""
                          }`}
                          id="v-pills-billing"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-bill"
                          type="button"
                          role="tab"
                          /* disabled={true} */
                          aria-controls="v-pills-bill"
                          aria-selected="false"
                        >
                          <div className="d-flex align-items-center gap-2">
                            <i
                              class="bi bi-tools"
                              style={{
                                // marginLeft: "-2px",
                                marginTop: "-2px",
                                fontSize: "18px",
                              }}
                            />
                            <span className="res-none ms-2">
                              Configurations
                            </span>{" "}
                          </div>
                        </button>
                        {/*  */}
                        <button
                          onClick={() => navigate("/productManagement")}
                          className={`nav-link  fw-bold ${
                            window.location.pathname === "/productManagement"
                              ? "active"
                              : ""
                          }`}
                          id="v-pills-billing"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-bill"
                          type="button"
                          role="tab"
                          // disabled={true}
                          aria-controls="v-pills-bill"
                          aria-selected="false"
                        >
                          <div className="d-flex align-items-center gap-2">
                            <FaBoxOpen
                              className="center-align-dashboard"
                              style={{
                                fontSize: "18px",
                                // marginLeft: "-1px"
                              }}
                            />
                            <span className="res-none ms-2">
                              Product Management
                            </span>{" "}
                          </div>
                        </button>
                        <button
                          onClick={() => navigate("/OrderManagement")}
                          className={`nav-link  fw-bold ${
                            window.location.pathname === "/OrderManagement"
                              ? "active"
                              : ""
                          }`}
                          id="v-pills-billing"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-bill"
                          type="button"
                          role="tab"
                          // disabled={true}
                          aria-controls="v-pills-bill"
                          aria-selected="false"
                        >
                          <div className="d-flex align-items-center gap-2">
                            <FaShoppingCart
                              className="center-align-dashboard"
                              style={{
                                fontSize: "18px",
                                //  marginLeft: "-1px"
                              }}
                            />
                            <span className="res-none ms-2">
                              Order Management
                            </span>{" "}
                          </div>
                        </button>
                      </>
                    )}
                    {Role === "SuperAdmin" && (
                      <>
                        {/*areamanagement */}
                        <button
                          onClick={() => navigate("/areamanagement")}
                          className={`nav-link  fw-bold ${
                            window.location.pathname === "/areamanagement"
                              ? "active"
                              : ""
                          }`}
                          id="v-pills-billing"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-bill"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-bill"
                          aria-selected="false"
                        >
                          <div className="d-flex align-items-center gap-2">
                            <i class="bi bi-grid"></i>
                            <span className="res-none ms-2">
                              Area Management
                            </span>{" "}
                          </div>
                        </button>
                      </>
                    )}
                    <button
                      onClick={() => navigate("/payment-wallet")}
                      className={`nav-link fw-bold ${
                        window.location.pathname === "/payment-wallet"
                          ? "active"
                          : ""
                      }`}
                      id="v-pills-billing"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-bill"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-bill"
                      aria-selected="false"
                    >
                      <div className="d-flex align-items-center  gap-2">
                        <FaWallet className="center-align-dashboard" />
                        <span className="res-none ms-2">Wallet</span>{" "}
                      </div>
                    </button>
                    {/*  <button
                      onClick={() => setShowLogoutModal(true)}
                      className={`nav-link fw-bold`}
                      id="v-pills-billing"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-bill"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-bill"
                      aria-selected="false"
                    >
                      <div className="res-none  d-flex align-items-center gap-2">
                        <div className="d-flex align-items-center">
                          <BiLogOut className="center-align-dashboard" />
                        </div>
                        <div className="d-flex align-items-center ms-2">
                          Logout
                        </div>
                      </div>
                      <span className="res-open" style={{ fontSize: "18px" }}>
                        <BiLogOut className="center-align-dashboard" />
                      </span>
                    </button> */}
                    <div className="reward-management">
                      <button
                        onClick={onClickProfileSettings}
                        className={`nav-link fw-bold ${
                          window.location.pathname ===
                            "/account-delete-confirmation" ||
                          window.location.pathname === "/profile" ||
                          window.location.pathname === "/resetpassword"
                            ? "active"
                            : ""
                        }`}
                        id="v-pills-reward"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-reward"
                        aria-selected={isOpenProfileSetting}
                      >
                        <div className="d-flex align-items-center gap-2">
                          <IoSettingsOutline
                            size={20}
                            className="center-align-dashboard"
                          />
                          <span className="res-none ms-2">Settings</span>{" "}
                          <span>
                            {!isOpenProfileSetting ? (
                              <i className="bi bi-caret-down-fill ms-2"></i>
                            ) : (
                              <i className="bi bi-caret-up-fill ms-2"></i>
                            )}
                          </span>
                        </div>
                      </button>
                      {isOpenProfileSetting && (
                        <div
                          className={`${
                            mobileMatches ? "profile-container" : "ms-4"
                          }`}
                        >
                          <button
                            onClick={onClickCustomerProfile}
                            className={`nav-link-claim fw-bold ${
                              window.location.pathname === "/profile"
                                ? "active"
                                : ""
                            }`}
                            id="v-pills-technician"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-technician"
                            aria-selected="false"
                            style={{
                              backgroundColor: "white",
                              border: "0px solid black",
                            }}
                          >
                            <div className="res-none d-flex align-items-center gap-2">
                              <div className="d-flex align-items-center">
                                <BsFillPersonFill
                                  className="center-align-dashboard"
                                  style={{ height: "20px" }}
                                  size={22}
                                />
                              </div>
                              <div className="d-flex align-items-center ms-2">
                                Profile
                              </div>
                            </div>
                            <span
                              className="res-open"
                              style={{ fontSize: "18px" }}
                            >
                              <BsFillPersonFill className="center-align-dashboard" />
                            </span>
                          </button>
                          <button
                            onClick={onClickResetPassword}
                            className={`nav-link-claim fw-bold ${
                              window.location.pathname === "/resetpassword"
                                ? "active"
                                : ""
                            }`}
                            id="v-pills-technician"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-technician"
                            aria-selected="false"
                            style={{
                              backgroundColor: "white",
                              border: "0px solid black",
                            }}
                          >
                            <div className="res-none  d-flex align-items-center gap-2">
                              <div className="d-flex align-items-center">
                                <RiLockPasswordLine
                                  size={20}
                                  className="center-align-dashboard"
                                />
                              </div>
                              <div className="d-flex align-items-center ms-2">
                                Reset Password
                              </div>
                            </div>
                            <span
                              className="res-open"
                              style={{ fontSize: "18px" }}
                            >
                              <RiLockPasswordLine className="center-align-dashboard" />
                            </span>
                          </button>
                          <button
                            onClick={() => setShowLogoutModal(true)}
                            className={`nav-link-claim fw-bold`}
                            id="v-pills-technician"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-technician"
                            aria-selected="false"
                            style={{
                              backgroundColor: "white",
                              border: "0px solid black",
                            }}
                          >
                            <div className="res-none  d-flex align-items-center gap-2">
                              <div className="d-flex align-items-center">
                                <BiLogOut
                                  className="center-align-dashboard"
                                  size={20}
                                />
                              </div>
                              <div className="d-flex align-items-center ms-2">
                                Logout
                              </div>
                            </div>
                            <span
                              className="res-open"
                              style={{ fontSize: "18px" }}
                            >
                              <BiLogOut className="center-align-dashboard" />
                            </span>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </nav>
        <div>
          <img
            alt="demo"
            src={TechLogo}
            className="w-100 mt-3 mt-lg-5 Side_menu"
          />
        </div>
      </div>

      {/* mobile response sidebar */}

      <div
        className="offcanvas offcanvas-start w-100"
        tabindex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          {/* <h5 className="offcanvas-title" id="offcanvasExampleLabel">Offcanvas</h5> */}
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="w-100 text-start h-auto d-inline-block">
            <div
              className="nav flex-column nav-pills "
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <p
                className="mt-2 ms-lg-3"
                style={{
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "#1E232C",
                }}
              >
                Menu
              </p>
              {/* consumer mobile menu*/}
              {Role === "Consumer" ? (
                <>
                  <button
                    onClick={() => {
                      window.location.replace("/customerDashboard");
                      document.getElementById("");
                    }}
                    className={`nav-link  fw-bold ${
                      window.location.pathname === "/customerDashboard" ||
                      window.location.pathname === "/technicianDashboard"
                        ? "active"
                        : ""
                    }`}
                    id="v-pills-existing-clients-tab sidebar-link-active"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-existing"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-existing"
                  >
                    <i className="bi bi-box me-3"></i>Dashboard
                  </button>

                  <button
                    onClick={() => window.location.replace("/serviceList")}
                    className={`nav-link  fw-bold ${
                      window.location.pathname === "/createService" ||
                      window.location.pathname === "/serviceList"
                        ? "active"
                        : ""
                    }`}
                    id="v-pills-onboarding"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-onboard"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-onboard"
                    aria-selected="false"
                  >
                    <i className="bi bi-envelope me-3"></i>My SRs
                  </button>

                  <button
                    onClick={onClickPayment}
                    className={`nav-link  fw-bold ${
                      window.location.pathname === "/payment" ? "active" : ""
                    }`}
                    id="v-pills-billing"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-bill"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-bill"
                    aria-selected="false"
                  >
                    <i className="bi bi-wallet me-3"></i>Payments
                  </button>

                  <button
                    onClick={onClickSetting}
                    className={`nav-link fw-bold bg-none`}
                    id="v-pills-billing"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-bill"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-bill"
                    aria-selected="false"
                  >
                    <i className="bi bi-gear me-3"></i>Settings{" "}
                    {!Isopen ? (
                      <i className="bi bi-caret-down-fill ms-2"></i>
                    ) : (
                      <i className="bi bi-caret-up-fill ms-2"></i>
                    )}
                  </button>

                  <button
                    onClick={onClickDeleteAccount}
                    className={`nav-link fw-bold ${
                      window.location.pathname ===
                        "/account-delete-confirmation" ||
                      window.location.pathname === "/account-delete-otp"
                        ? "active"
                        : ""
                    }`}
                    id="v-pills-billing"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-bill"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-bill"
                    aria-selected="false"
                  >
                    <div className="res-none  d-flex align-items-center gap-2">
                      <div className="d-flex align-items-center">
                        <GoTrash size={20} className="center-align-dashboard" />
                      </div>
                      <div className="d-flex align-items-center ms-2">
                        Delete Account
                      </div>
                    </div>
                  </button>
                  {Isopen ? (
                    <button
                      onClick={onClickResetPassword}
                      className={`nav-link fw-bold ${
                        window.location.pathname === "/resetpassword"
                          ? "active"
                          : ""
                      }`}
                      id="v-pills-billing"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-bill"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-bill"
                      aria-selected="false"
                    >
                      <i className="bi bi me-3 ms-lg-3"></i>Reset password
                    </button>
                  ) : (
                    <></>
                  )}
                  {Isopen ? (
                    <button
                      onClick={onClickCustomerProfile}
                      className={`nav-link fw-bold ${
                        window.location.pathname === "/profile" ? "active" : ""
                      }`}
                      id="v-pills-billing"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-bill"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-bill"
                      aria-selected="false"
                    >
                      <i className="bi bi me-3 ms-lg-2"></i>Profile
                    </button>
                  ) : (
                    <></>
                  )}
                  {Isopen ? (
                    <button
                      onClick={onClickCustomerSaveCard}
                      className={`nav-link fw-bold ${
                        window.location.pathname === "/customerSaveCard"
                          ? "active"
                          : ""
                      }`}
                      id="v-pills-billing"
                      data-bs-toggle="pill"
                      data-bs-target="#v-pills-bill"
                      type="button"
                      role="tab"
                      aria-controls="v-pills-bill"
                      aria-selected="false"
                    >
                      <i className="bi bi me-3 ms-lg-3"></i>Saved Cards
                    </button>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}

              {/* technician */}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showLogoutModal}
        onHide={() => setShowLogoutModal(false)}
        centered
      >
        <div className="p-3">
          {/* <div
                      className="d-flex align-items-center "
                      style={{
                        justifyContent: "space-between",
                        width: "100%",
                        fontSize: "20px",
                      }}
                    >
                      <div>Confirm Logout</div>
                      <div onClick={() => setShowLogoutModal(false)}>
                        <MdClose style={{ cursor: "pointer" }} />
                      </div>
                    </div> */}
          <div className="pt-3 pb-4" style={{ textAlign: "center" }}>
            Are you sure you want to log out?
          </div>
          <div
            className="d-flex align-items-center pb-2"
            style={{ justifyContent: "center", gap: "10px" }}
          >
            <Button
              variant="secondary"
              onClick={() => setShowLogoutModal(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={handleLogout}
              style={{
                backgroundColor: "rgb(235, 129, 40)",
                color: "white",
                border: "1px solid rgb(235, 129, 40)",
              }}
            >
              Logout
            </Button>
          </div>
        </div>
      </Modal>

      {/* <Modal show={logoutModal} onHide={() => setLogoutModal(false)} centered>
        <div
          className="d-flex align-items-center"
          style={{ justifyContent: "center" }}
        >
          <div className="p-3">
            <div
              className="mt-3 mb-3"
              style={{ fontSize: "16px", fontWeight: "600" }}
            >
              Are you sure want to logout?
            </div>
            <div
              className="d-flex align-items-center gap-3 mt-3 mb-3"
              style={{
                justifyContent: "center",
              }}
            >
              <button
                className="logout-yes"
                onClick={() => {
                  localStorage.clear();
                  navigate("/login");
                  setLogoutModal(false);
                }}
              >
                Yes
              </button>
              <button
                className="logout-no"
                onClick={() => setLogoutModal(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </Modal> */}
    </>
  );
};
export default Sidebar;
