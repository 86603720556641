import React, { useEffect } from "react";
import Header from "./Header";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";

export default function TurnAroundTime() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);
  return (
    <div>
      <div className="sticky-top">
        <Header />
      </div>
      <div
        style={{
          width: "100%",
          backgroundColor: "#F8F8F8",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "30px",
          paddingBottom: "30px",
        }}
      >
        <div
          style={{
            padding: 50,
            width: "90%",
            backgroundColor: "white",
            boxShadow: "0 0 10px 1px #00000036",
          }}
        >
          <h5
            style={{
              marginTop: 0,
              textAlign: "center",
              marginBottom: 20,
              textTransform: "uppercase",
            }}
          >
            <b> Service Availability and Response Time Guidelines</b>
          </h5>
          <div style={{ marginTop: "30px" }}>
            At S3, we offer expert technician services right at your doorstep to
            take care of your valuable electronic devices. To provide you with
            transparency regarding our delivery and shipping policies, as well
            as our turnaround time (TAT), we've outlined the details below:
          </div>
          <div style={{ fontSize: 19, marginTop: 20 }}>
            <b>Service Delivery and Shipping</b>
          </div>
          <div style={{ fontSize: 17, marginTop: 20 }}>
            <b>Technician Home Visit</b>
          </div>
          <div style={{ marginTop: "20px" }}>
            When you raise a service request for a specific time slot, our
            technician will be sent to your home to attend to the complaint. We
            aim to provide prompt and reliable service to ensure the timely
            resolution of your electronic device issues.
          </div>
          <div style={{ fontSize: 17, marginTop: 20 }}>
            <b>Basic Visit Charge</b>
          </div>
          <div style={{ marginTop: "20px" }}>
            A basic visit charge will be applied for the technician's visit to
            your location. The charge may vary depending on the size and type of
            the appliance being serviced. You will be informed of this charge
            when you schedule your service request.
          </div>
          <div style={{ fontSize: 17, marginTop: 20 }}>
            <b>Spare Parts and Service Charges</b>
          </div>
          <div style={{ marginTop: "20px" }}>
            If our technician identifies the need to replace spare parts or
            perform additional efforts to fix your electronic device, you will
            receive a detailed quote for these services. The cost will be
            determined based on the complexity of the task and the cost of the
            required parts.
          </div>
          <div style={{ fontSize: 19, marginTop: 20 }}>
            <b>Turn Around Time (TAT)</b>
          </div>
          <div style={{ fontSize: 17, marginTop: 20 }}>
            <b>Service Timeframe</b>
          </div>
          <div style={{ marginTop: "20px" }}>
            We strive to complete most service requests during the technician's
            visit to your location. However, if spare parts need to be ordered
            or additional efforts are required, the turnaround time (TAT) may
            vary.
          </div>
          <div style={{ fontSize: 17, marginTop: 20 }}>
            <b>TAT Communication</b>
          </div>
          <div style={{ marginTop: "20px" }}>
            Our technician will provide you with an estimated TAT when
            presenting the quote for spare parts or additional efforts. We will
            keep you informed of the progress and any changes to the TAT
            throughout the service process.
          </div>
          <div style={{ fontSize: 19, marginTop: 20 }}>
            <b>Logistic Partner Details</b>
          </div>
          <div style={{ marginTop: "20px" }}>
            We collaborate with trusted logistics partners to ensure the
            efficient delivery of spare parts and other necessary components.
            Our logistics partners are chosen for their reliability and
            commitment to timely deliveries.
          </div>
          <div style={{ marginTop: "10px" }}>
            For specific logistic partner details or to track your spare parts
            delivery, please contact our customer support team at{" "}
            <a href="mailto:support@s3-app.com">support@s3-app.com.</a>
          </div>{" "}
          <div style={{ marginTop: "10px" }}>
            We understand the importance of your electronics devices, and we are
            dedicated to providing you with a hassle-free and efficient service
            experience. If you have any questions or concerns regarding our
            delivery, shipping policies, or TAT, please don't hesitate to reach
            out to us. Your satisfaction is our priority, and we are here to
            assist you in every way possible.
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
