import { createSlice } from "@reduxjs/toolkit";

const revenueShareDetailSlice = createSlice({
  name: "revenueShareDetail",
  initialState: {
    loading: false,
  },
  reducers: {
    revenueShareDetailRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    revenueShareDetailSuccess(state, action) {
      return {
        ...state,
        loading: false,
        revenueShareDetail: action.payload,
      };
    },
    revenueShareDetailFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
  },
});

const { actions, reducer } = revenueShareDetailSlice;

export const {
  revenueShareDetailRequest,
  revenueShareDetailFail,
  revenueShareDetailSuccess,
} = actions;

export default reducer;
