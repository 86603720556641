import React, { useEffect } from "react";
import Navbar from "../common/Navbar";
import Footer from "../Footer";
import ServiceBanner from "./ServiceBanner";
import WhyUs from "./WhyUs";
import Exclusive from "./Exclusive";
import ServiceHistory from "./ServiceHistory";
import KeyPoints from "./KeyPoints";
import UpcomingService from "./UpcomingService";
import TellMeAbout from "./TellMeAbout";
import FindUs from "./FindUs";
import HowToBook from "./HowToBook";

export default function Services() {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    const body = document.querySelector("body");
    body.classList.remove("res-nav-body-constructor");
  }, []);
  return (
    <div>
      <Navbar />
      <div className="shade-provider"></div>
      <ServiceBanner />
      <WhyUs />
      <Exclusive />
      <ServiceHistory />
      <KeyPoints />
      <UpcomingService />
      <TellMeAbout />
      <FindUs />
      <HowToBook />
      <Footer />
    </div>
  );
}
