import React from "react";

function Starrating({ rating }) {
  const stars = [];

  // add checked stars for each whole number rating
  for (let i = 0; i < Math.floor(rating); i++) {
    stars.push(
      <span key={i} style={{ color: "#FFB118" }} className="fa fa-star checked"></span>
    );
  }

  // add half star if applicable
  if (rating % 1 !== 0) {
    stars.push(
      <span key={stars.length} style={{ color: "#FFB118" }} className="fa fa-star-half checked"></span>
    );
  }

  // add unchecked stars for any remaining whole stars
  for (let i = stars.length; i < 5; i++) {
    stars.push(
      <span key={i} style={{ color: "#FFB118" }} className="fa-regular fa-star"></span>
    );
  }

  return <>{stars}</>;
}

// usage example:
// <Starrating rating={3.5} />

export default Starrating;
